import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import { PageTitle, PageErrorBoundary, ErroConexao } from 'features/common';
import EmptyStateIcon from 'assets/icons/empty-state';

import colors from 'assets/styles/colors';
import AcompanhamentoButton from './views/acompanhamentoButton';
import ProductCards from './views/productCards';

import './produtoPage.scss';

const ProdutoPage = ({
  resetSelections, getProducts, isLoading, isError,
}) => {
  useEffect(() => {
    resetSelections();
    getProducts();
  }, [resetSelections, getProducts]);

  return (
    <PageErrorBoundary>
      {isError ? (
        <ErroConexao
          title="Sem resultados."
          message="Algo deu errado ao carregar os produtos, tente novamente mais tarde"
        >
          <EmptyStateIcon className="erro-conexao-image" />
        </ErroConexao>
      ) : (
        <div className="product-form-page">
          <div className="product-form-page-header">
            <PageTitle
              className="product-form-page-title"
              title="Selecione um tipo de veículo"
            />
            <div className="product-form-page-subtitle">
              A disponibilidade das ofertas está sujeita a alterações mediante a
              divulgação das DVEs pela montadora.
            </div>
          </div>
          <div className="product-form-page-content">
            <div className="product-cards-container">
              {isLoading ? (
                <CircularProgress
                  color={colors.primary_color_500}
                  size="40px"
                />
              ) : <ProductCards /> }
            </div>
          </div>
          <div
            className="product-form-page-button"
            data-cy="ProductFormPageButton"
          >
            <AcompanhamentoButton />
          </div>
        </div>
      )}
    </PageErrorBoundary>
  );
};

ProdutoPage.propTypes = {
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,

  getProducts: PropTypes.func.isRequired,
  resetSelections: PropTypes.func.isRequired,
};

ProdutoPage.defaultProps = {
  isError: false,
  isLoading: false,
};

export default ProdutoPage;
