/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';

function QuoteRequestIcon(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path 
			fillRule="evenodd" 
			clipRule="evenodd" 
			d="M12.94 4.94004L9.71999 1.72004C9.47332 1.47337 9.13332 1.33337 8.77999 1.33337H3.99999C3.26666 1.33337 2.67332 1.93337 2.67332 2.66671L2.66666 13.3334C2.66666 14.0667 3.25999 14.6667 3.99332 14.6667H12C12.7333 14.6667 13.3333 14.0667 13.3333 13.3334V5.88671C13.3333 5.53337 13.1933 5.19337 12.94 4.94004ZM9.33332 8.66671C9.69999 8.66671 9.99999 8.96671 9.99999 9.33337V11.3334C9.99999 11.7 9.69999 12 9.33332 12H8.66666C8.66666 12.3667 8.36666 12.6667 7.99999 12.6667C7.63332 12.6667 7.33332 12.3667 7.33332 12H6.66666C6.29999 12 5.99999 11.7 5.99999 11.3334C5.99999 10.9667 6.29999 10.6667 6.66666 10.6667H8.66666V10H6.66666C6.29999 10 5.99999 9.70004 5.99999 9.33337V7.33337C5.99999 6.96671 6.29999 6.66671 6.66666 6.66671H7.33332C7.33332 6.30004 7.63332 6.00004 7.99999 6.00004C8.36666 6.00004 8.66666 6.30004 8.66666 6.66671H9.33332C9.69999 6.66671 9.99999 6.96671 9.99999 7.33337C9.99999 7.70004 9.69999 8.00004 9.33332 8.00004H7.33332V8.66671H9.33332ZM8.66666 4.66671C8.66666 5.03337 8.96666 5.33337 9.33332 5.33337H11.6667L8.66666 2.33337V4.66671Z" 
			fill="#8F9BB3"/>

    </svg>
  );
}

export default QuoteRequestIcon;
