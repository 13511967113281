import * as React from "react";

function CardIcon(props) {
	return (
		<svg
			width="1em"
			height="1em"
			viewBox="0 0 20 14"
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M17.917 0H2.083A2.086 2.086 0 000 2.083v9.167c0 1.148.935 2.083 2.083 2.083h15.834A2.086 2.086 0 0020 11.25V2.083A2.086 2.086 0 0017.917 0zm1.25 11.25c0 .69-.561 1.25-1.25 1.25H2.083c-.689 0-1.25-.56-1.25-1.25V2.083c0-.689.561-1.25 1.25-1.25h15.834c.689 0 1.25.561 1.25 1.25v9.167z"
				
			/>
			<path
				d="M19.583 2.5H.417A.417.417 0 000 2.917v2.5c0 .23.187.416.417.416h19.166c.23 0 .417-.186.417-.416v-2.5a.417.417 0 00-.417-.417zM19.167 5H.833V3.333h18.334V5zM7.917 8.333h-5a.417.417 0 000 .834h5a.417.417 0 000-.834zM7.917 10h-5a.417.417 0 000 .833h5a.417.417 0 000-.833zM16.25 7.5h-.833c-.69 0-1.25.56-1.25 1.25v.833c0 .69.56 1.25 1.25 1.25h.833c.69 0 1.25-.56 1.25-1.25V8.75c0-.69-.56-1.25-1.25-1.25zm.417 2.083c0 .23-.187.417-.417.417h-.833A.417.417 0 0115 9.583V8.75c0-.23.187-.417.417-.417h.833c.23 0 .417.187.417.417v.833z"
				
			/>
		</svg>
	);
}

export default CardIcon;

