import React from 'react';
import PropTypes from 'prop-types';
import { camelFormat } from 'utils/format';

import Select from 'features/common/select';

const defaultValue = [{
  value: new Date().getMonth() + 1,
  text: camelFormat(new Date().toLocaleString('pt-BR', { month: 'long' })),
}];
const MesSelector = ({
  mes, setMes, mesesList, isLoading, isBrandSelected,
}) => (
  <Select
    items={mesesList.length > 0 ? mesesList : defaultValue}
    label="Mês"
    value={mes}
    onSelect={(m) => {
      setMes(m);
    }}
    disabled={isLoading || !isBrandSelected}
  />
);

MesSelector.propTypes = {
  mes: PropTypes.number,
  setMes: PropTypes.func,
  mesesList: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  isBrandSelected: PropTypes.bool,
};

MesSelector.defaultProps = {
  mes: new Date().getMonth() + 1,
  setMes: () => {},
  mesesList: null,
  isLoading: false,
  isBrandSelected: false,
};

export default MesSelector;
