import { connect } from 'react-redux';

import { PaymentOperations } from '../../redux';
import PaymentReceipt from './paymentReceipt';

const mapDispatchToProps = (dispatch) => ({
  resetPaymentStore: () => dispatch(PaymentOperations.resetPaymentStore()),
  downloadPaymentReport: (id) => dispatch(PaymentOperations.downloadPaymentReport(id)),
});

const mapStateToProps = ({ paymentStore }) => ({
  isTransactionError: paymentStore.paymentReceipt.isTransactionError,
  paymentId: paymentStore.paymentId,
  downloading: paymentStore.downloading,
  downloadError: paymentStore.downloadError,
});
export default connect(mapStateToProps, mapDispatchToProps)(PaymentReceipt);
