import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'utils/format';
import TooltipMensagem from 'features/common/tooltipMensagem';
import Checkbox from 'features/common/checkbox/checkbox';
import Concessionaria from './concessionaria';
import './relatorioListRow.scss';

const RelatorioListRow = ({ documento, onCheck }) => (
  <div
    className={`relatorios-page__list-row__container__outer 
${documento?.selected ? 'relatorios-page__list-row__selected' : ''}`}
    data-cy="RelatoriosListRow"
  >
    <div className="relatorios-page__list-row__container">
      <div className="relatorios-page__list-row__item relatorios-page__list-row__checkbox">
        <Checkbox
          onChange={() => onCheck(documento)}
          checked={documento?.selected ?? false}
        />
      </div>
      <div className="relatorios-page__list-row__item relatorios-page__list-row__concessionaria">
        <Concessionaria
          name={documento?.concessionariaNome.trim()}
          buc={documento?.dealerCorr}
        />
      </div>
      <div
        className="relatorios-page__list-row__item relatorios-page__list-row__tipo-documento "
        title={documento?.tipoDocumento}
      >
        <TooltipMensagem title={documento?.tipoDocumento} maxWidth="400px">
          <span className="relatorios-page__list-row__tipo-documento__text">
            {documento?.tipoDocumento}
          </span>
        </TooltipMensagem>
      </div>
      <div
        className="relatorios-page__list-row__item relatorios-page__list-row__nome-documento "
        title={documento?.nomeDocumento}
      >
        <TooltipMensagem title={documento?.nomeDocumento} maxWidth="400px">
          <span className="relatorios-page__list-row__nome-documento__text">
            {documento?.nomeDocumento}
          </span>
        </TooltipMensagem>
      </div>
      <div className="relatorios-page__list-row__item relatorios-page__list-row__data ">
        {formatDate(documento?.data, 'DD MMM YYYY')}
      </div>
    </div>
  </div>
);
RelatorioListRow.propTypes = {
  onCheck: PropTypes.func,
  documento: PropTypes.object,
};
RelatorioListRow.defaultProps = {
  onCheck: () => {},
  documento: null,
};
export default RelatorioListRow;
