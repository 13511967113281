import { connect } from 'react-redux';
import * as operations from '../../redux/operations';
import selectors from '../../redux/selectors';

import DadosDestino from './dadosDestino';

const mapStateToProps = ({ limiteCreditoStore, dealerStore }) => ({
  codigoSap: limiteCreditoStore.transferenciaLimite.dadosDestino.codigoSap,
  error: selectors.isValidSapDestino(limiteCreditoStore),
  brand: limiteCreditoStore.transferenciaLimite.dadosDestino.brand,
  produtos: limiteCreditoStore.transferenciaLimite.produtos?.produtos,
  isLoading: limiteCreditoStore.transferenciaLimite.produtos.isLoading,
  isError: limiteCreditoStore.transferenciaLimite.produtos.isError,
  selectedProduct: limiteCreditoStore.transferenciaLimite.dadosDestino.produto,
  concessionarias: dealerStore.dealers,
  selectedDealerId: limiteCreditoStore.transferenciaLimite.dadosDestino.selectedDealerId,
});

const mapDispatchToProps = (dispatch) => ({
  setCodigoSap: (valor) => dispatch(operations.setCodigoSapDestino(valor)),
  setConcessionariaSelecionada: (concessionaria) => dispatch(
    operations.setSelectedDealerDestino(concessionaria),
  ),
  setProduto: (produto) => dispatch(operations.setProdutoDestino(produto)),
  setBrand: (brand) => dispatch(operations.setBrandDestino(brand)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DadosDestino);
