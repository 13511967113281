import { connect } from 'react-redux';

import TimelineSolicitacoes from './timelineSolicitacoes';

import selector from '../../../redux/selector';

const mapStateToProps = ({ limiteCreditoStore }) => ({
  solicitacaoComentarios: selector.comentariosSolicitacao(limiteCreditoStore),
  isLoading: limiteCreditoStore.acompanhamento.detalhes.isLoading,
});

export default connect(mapStateToProps, null)(TimelineSolicitacoes);
