import React from 'react';
import PropTypes from 'prop-types';

import { formatDate, formatCurrency } from 'utils/format';
import Identificador from './identificador';
import EditButton from './editButton';
import InputPagamento from './inputPagamento';

import './pagamentoListRow.scss';

const PagamentoListRow = ({ invoice }) => (
  <div className="pagamento-page__list-row__container__outer">
    <div
      className="pagamento-page__list-row__container"
    >
      <div className="pagamento-page__list-row__item pagamento-page__list-row__identificador">
        <Identificador
          product={invoice.descricaoProduto}
          status={invoice.duplicataStatusDescricao}
          invoiceNumber={invoice.duplicataNumero}
          tipoDoc={invoice.tipoDocumento}
        />
      </div>
      <div className="pagamento-page__list-row__item pagamento-page__list-row__vencimento">
        {formatDate(invoice.dataVencimento, 'DD MMM YYYY')}
      </div>
      <div
        className="pagamento-page__list-row__item pagamento-page__list-row__saldo"
      >
        {formatCurrency(invoice.saldoDevedor)}
      </div>
      <div
        className="pagamento-page__list-row__item pagamento-page__list-row__pagamento"
      >
        <InputPagamento
          value={invoice.toPay}
          disabled={invoice.selected}
          invoiceId={invoice.duplicataId}
        />
      </div>
      <div
        className="pagamento-page__list-row__item pagamento-page__list-row__icon"
      >
        <EditButton
          state={invoice.selected}
          invoiceId={invoice.duplicataId}
        />
      </div>
    </div>
  </div>
);
PagamentoListRow.propTypes = {
  invoice: PropTypes.object,
};

PagamentoListRow.defaultProps = {
  invoice: null,
};

export default PagamentoListRow;
