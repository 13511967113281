import { connect } from 'react-redux';

import { AuthSelectors } from '../../redux';
import RenderIfNotFidis from './renderIfNotFidis';

const mapStateToProps = ({ authStore }) => ({
  isFidis: AuthSelectors.isFidis(authStore),
});

export default connect(mapStateToProps)(RenderIfNotFidis);
