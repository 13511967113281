import { applyProperty } from 'utils/object';
import { applyFilter } from './reducerUtil';
import actions from './actions';
import { DateRange } from './enum';

const INITIAL_STATE = {
  filters: {
    startDate: null,
    endDate: null,
    selectedPonto: [],
    selectedProduto: [],
    filterType: DateRange.all,
    isEnable: false,
    atualizadoOntem: false,
  },
  isUpdated: true,

  filtersSelector: {
    produto: {
      data: [],
      isLoading: false,
      isError: false,
    },
  },

  cardsResumo: {
    isLoading: false,
    isError: false,
    noData: false,
    data: {},
  },

  valoresDuplicatas: {
    isLoading: false,
    isError: false,
    data: [],
  },

  quantidadeDuplicatas: {
    isLoading: false,
    isError: false,
    data: [],
  },

  cardsVencimento: {
    isLoading: false,
    isError: false,
    data: [],
  },

  carteiraProduto: {
    isLoading: false,
    isError: false,
    data: [],
  },

  carteiraBrand: {
    isLoading: false,
    isError: false,
    data: [],
  },

};

const reducerGetFilterProduto = (state, action) => {
  switch (action.type) {
    case actions.types.GET_PRODUTOS_START:
      return {
        ...state,
        filtersSelector: {
          ...state.filtersSelector,
          produto: {
            ...state.filtersSelector.produto,
            isLoading: true,
            isError: false,
          },
        },
      };
    case actions.types.GET_PRODUTOS_ERROR:
      return {
        ...state,
        filtersSelector: {
          ...state.filtersSelector,
          produto: {
            data: [],
            isLoading: false,
            isError: true,
          },
        },
      };
    case actions.types.GET_PRODUTOS_SUCCESS:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedProduto: action.payload.produtos,
        },
        filtersSelector: {
          produto: {
            data: action.payload.produtos,
            isLoading: false,
            isError: false,
          },
        },
      };
    default:
      return state;
  }
};

const reducerGetCardsResumo = (state, action) => {
  switch (action.type) {
    case actions.types.GET_CARDS_RESUMO_START:
      return {
        ...state,
        cardsResumo: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    case actions.types.GET_CARDS_RESUMO_ERROR:
      return {
        ...state,
        cardsResumo: {
          isLoading: false,
          isError: true,
          data: {},
        },
      };
    case actions.types.GET_CARDS_RESUMO_SUCCESS:
      return {
        ...state,
        cardsResumo: {
          isLoading: false,
          isError: false,
          data: action.payload.resumo,
        },
      };
    default:
      return state;
  }
};

const reducerGetCardsVencimento = (state, action) => {
  switch (action.type) {
    case actions.types.GET_CARDS_VENCIMENTO_START:
      return {
        ...state,
        cardsVencimento: {
          isLoading: true,
          isError: false,
          data: [],
        },
      };
    case actions.types.GET_CARDS_VENCIMENTO_ERROR:
      return {
        ...state,
        cardsVencimento: {
          isLoading: false,
          isError: true,
          data: [],
        },
      };
    case actions.types.GET_CARDS_VENCIMENTO_SUCCESS:
      return {
        ...state,
        cardsVencimento: {
          isLoading: false,
          isError: false,
          data: action.payload.cards,
        },
      };
    default:
      return state;
  }
};

const reducerGetValoresChart = (state, action) => {
  switch (action.type) {
    case actions.types.GET_VALORES_CHART_START:
      return {
        ...state,
        valoresDuplicatas: {
          isLoading: true,
          isError: false,
          data: [],
        },
      };
    case actions.types.GET_VALORES_CHART_SUCCESS:
      return {
        ...state,
        valoresDuplicatas: {
          isLoading: false,
          isError: false,
          data: action.payload.data,
        },
      };
    case actions.types.GET_VALORES_CHART_ERROR:
      return {
        ...state,
        valoresDuplicatas: {
          isLoading: false,
          isError: true,
          data: [],
        },
      };
    default:
      return state;
  }
};

const reducerGetDuplicatasChart = (state, action) => {
  switch (action.type) {
    case actions.types.GET_DUPLICATAS_CHART_START:
      return {
        ...state,
        quantidadeDuplicatas: {
          isLoading: true,
          isError: false,
          data: [],
        },
      };
    case actions.types.GET_DUPLICATAS_CHART_SUCCESS:
      return {
        ...state,
        quantidadeDuplicatas: {
          isError: false,
          isLoading: false,
          data: action.payload.data,
        },
      };
    case actions.types.GET_DUPLICATAS_CHART_ERROR:
      return {
        ...state,
        quantidadeDuplicatas: {
          isLoading: false,
          isError: true,
          data: [],
        },
      };
    default:
      return state;
  }
};

const reduceGetCarteiraProduto = (state, action) => {
  switch (action.type) {
    case actions.types.GET_CARTEIRA_PRODUTO_START:
      return {
        ...state,
        carteiraProduto: {
          isLoading: true,
          isError: false,
          data: [],
        },
      };
    case actions.types.GET_CARTEIRA_PRODUTO_ERROR:
      return {
        ...state,
        carteiraProduto: {
          isLoading: false,
          isError: true,
          data: [],
        },
      };
    case actions.types.GET_CARTEIRA_PRODUTO_SUCCESS:
      return {
        ...state,
        carteiraProduto: {
          isLoading: false,
          isError: false,
          data: action.payload.data,
        },
      };
    default:
      return state;
  }
};

const reduceGetCarteiraBrand = (state, action) => {
  switch (action.type) {
    case actions.types.GET_CARTEIRA_BRAND_START:
      return {
        ...state,
        carteiraBrand: {
          isLoading: true,
          isError: false,
          data: [],
        },
      };
    case actions.types.GET_CARTEIRA_BRAND_ERROR:
      return {
        ...state,
        carteiraBrand: {
          isLoading: false,
          isError: true,
          data: [],
        },
      };
    case actions.types.GET_CARTEIRA_BRAND_SUCCESS:
      return {
        ...state,
        carteiraBrand: {
          isLoading: false,
          isError: false,
          data: action.payload.data,
        },
      };
    default:
      return state;
  }
};

export default (state = INITIAL_STATE, action = { type: '@@painelFloorPlan/INIT' }) => {
  if (action.type.startsWith(actions.types.PREFIX_GET_PRODUTOS)) {
    return reducerGetFilterProduto(state, action);
  }

  if (action.type.startsWith(actions.types.PREFIX_GET_VALORES_CHART)) {
    return reducerGetValoresChart(state, action);
  }

  if (action.type.startsWith(actions.types.PREFIX_GET_DUPLICATAS_CHART)) {
    return reducerGetDuplicatasChart(state, action);
  }

  if (action.type.startsWith(actions.types.PREFIX_GET_CARDS_RESUMO)) {
    return reducerGetCardsResumo(state, action);
  }

  if (action.type.startsWith(actions.types.PREFIX_GET_CARDS_VENCIMENTOS)) {
    return reducerGetCardsVencimento(state, action);
  }

  if (action.type.startsWith(actions.types.PREFIX_GET_CARTEIRA_PRODUTO)) {
    return reduceGetCarteiraProduto(state, action);
  }

  if (action.type.startsWith(actions.types.PREFIX_GET_CARTEIRA_BRAND)) {
    return reduceGetCarteiraBrand(state, action);
  }

  switch (action.type) {
    case actions.types.SET_IS_UPDATED:
      return {
        ...state,
        isUpdated: action.payload.data,
      };
    case actions.types.SET_IS_LOADING:
      return {
        ...state,
        cardsResumo: {
          ...state.cardsResumo,
          isLoading: true,
        },
        valoresDuplicatas: {
          ...state.valoresDuplicatas,
          isLoading: true,
        },

        quantidadeDuplicatas: {
          ...state.quantidadeDuplicatas,
          isLoading: true,
        },

        cardsVencimento: {
          ...state.cardsVencimento,
          isLoading: true,
        },

        carteiraProduto: {
          ...state.carteiraProduto,
          isLoading: true,
        },

        carteiraBrand: {
          ...state.carteiraBrand,
          isLoading: true,
        },
      };
    case actions.types.SET_FILTER:
      return {
        ...state,
        filters: {
          ...applyProperty(
            state.filters,
            action.payload.paramName,
            action.payload.value,
          ),
          isEnable: true,
        },
      };
    case actions.types.SET_FILTER_TYPE:
      return {
        ...state,
        filters: {
          ...applyFilter(state.filters, action.payload.type),
          isEnable: true,
        },
      };
    case actions.types.DISABLE_FILTER_BUTTON:
      return {
        ...state,
        filters: {
          ...state.filters,
          isEnable: false,
        },
      };
    case actions.types.RESET_STORE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
