import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import SearchInput from 'features/common-staff/controls/input/textFilterDebounce';
import { Tracking } from '../../../../modules';
import DateRangePicker from '../../../common-staff/controls/dateRangePicker';
import { DateRange } from '../../redux/enums';
import TipoTransacao from './views/tipoTransacao';
import FilterButton from './views/filterButton';

import './filters.scss';

const useStyles = makeStyles(() => ({
  input: {
    '&::placeholder': {
      opacity: 1,
    },
  },
}));

const Filters = ({
  setTextFilter, setStartDate, setEndDate,
  textValue, startDate, endDate, isLoading,
  setFilterType, isModalOpen,
}) => {
  const classes = useStyles();

  const setEndDateAndFilter = (date) => {
    setEndDate(date);
    setFilterType(DateRange.custom);
    Tracking.Mixpanel.movimentacoes.trackFilters.trackTypeDateFilter();
  };

  return (
    <div className="statement-page__filters">
      <div
        className="statement-page__filters__text"
        data-cy="StatementPageFiltersText"
      >
        <SearchInput
          label=""
          placeholder="Buscar duplicata ou chassi"
          value={textValue}
          setValue={setTextFilter}
          onChange={() => {
            Tracking.Mixpanel.movimentacoes.trackFilters.trackTypeTextFilter();
          }}
          InputProps={{ classes: { input: classes.input } }}
          showSearchIcon
          disabled={isLoading}
        />
      </div>
      {!isModalOpen ? (
        <div
          className="statement-page__filters__tipo"
          data-cy="TransactionType"
        >
          <TipoTransacao />
        </div>
      ) : null }
      <div className="statement-page__filters__data">
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDateAndFilter}
          disabled={isLoading}
          numberOfMonths={1}
        />
      </div>
      <div
        className="statement-page__filters__aplicar"
        data-cy="StatementPageFiltersAplicar"
      >
        <FilterButton />
      </div>
    </div>
  );
};

Filters.propTypes = {
  setTextFilter: PropTypes.func,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  textValue: PropTypes.string,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  isLoading: PropTypes.bool,
  setFilterType: PropTypes.func,
  isModalOpen: PropTypes.bool,
};

Filters.defaultProps = {
  setTextFilter: () => {},
  setFilterType: () => {},
  setStartDate: () => {},
  setEndDate: () => {},
  textValue: '',
  startDate: null,
  endDate: null,
  isLoading: false,
  isModalOpen: false,
};

export default Filters;
