import React from 'react';
import PropTypes from 'prop-types';

import { camelFormat } from 'utils/format';
import InvoiceIcon from './invoiceIcon';

import './identificador.scss';

const Identificador = ({
  product, status, invoiceNumber, tipoDoc,
}) => (
  <div className="pagamento-page__identificador__container">
    <div className="pagamento-page__identificador__container__icon">
      <InvoiceIcon
        product={product}
        status={status}
      />
    </div>
    <div className="pagamento-page__identificador__container__dados">
      {product}
      <span className="pagamento-page__identificador__container__dados__dup">
        {`${camelFormat(tipoDoc)}. ${invoiceNumber}`}
      </span>
    </div>
  </div>
);

Identificador.propTypes = {
  product: PropTypes.string,
  status: PropTypes.string,
  invoiceNumber: PropTypes.number,
  tipoDoc: PropTypes.string,
};

Identificador.defaultProps = {
  product: null,
  status: null,
  invoiceNumber: null,
  tipoDoc: null,
};

export default Identificador;
