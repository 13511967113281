import _ from 'lodash';
import Auth from '..';

import {
  INIT_LOGIN, FINISH_LOGIN, SET_USER,
  SET_PERMISSIONS, SET_SESSION_EXPIRED, INIT_LOGIN_REDIRECT,
  SET_ERROR_MESSAGE,
} from './authActions';

export const INITIAL_STATE = {
  isLoading: false,
  isLoginError: false,
  isLoadingRedirect: false,
  user: Auth.getCurrentUser(),
  permissions: Auth.getPermissions(),
  sessionExpired: false,
  errorMessage: '',
};

export default (state = INITIAL_STATE, action = { type: 'default' }) => {
  switch (action.type) {
    case INIT_LOGIN:
      return {
        ...state,
        isLoading: true,
        isLoadingRedirect: false,
        isLoginError: false,
      };
    case FINISH_LOGIN:
      return {
        ...state,
        user: action.payload.user,
        permissions: action.payload.permissions,
        isLoading: false,
        isLoginError: action.payload.isLoginError,
        isLoadingRedirect: false,
      };
    case INIT_LOGIN_REDIRECT:
      return {
        ...state,
        isLoading: false,
        isLoadingRedirect: true,
        isLoginError: false,
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload.user,
      };
    case SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload.permissions,
      };
    case SET_SESSION_EXPIRED:
      return {
        ...state,
        sessionExpired: action.payload.expired,
      };
    case SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: _.isNull(action.payload.message) || _.isEmpty(action.payload.message) ? 'Login inválido'
          : action.payload.message,
      };
    default:
      return state;
  }
};
