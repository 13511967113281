import React from 'react';
import PropTypes from 'prop-types';
import {
  createTheme as createThemeV4,
  MuiThemeProvider as MuiThemeProviderV4,
  createGenerateClassName,
  StylesProvider,
} from '@material-ui/core/styles';
import { createTheme as createThemeV5, ThemeProvider as MuiThemeProviderV5 } from '@mui/material/styles';
import colors from 'assets/styles/colors';

const generateClassName = createGenerateClassName({
  // By enabling this option, if you have non-MUI elements (e.g. `<div />`)
  // using MUI classes (e.g. `.MuiButton`) they will lose styles.
  // Make sure to convert them to use `styled()` or `<Box />` first.
  disableGlobal: true,
  // Class names will receive this seed to avoid name collisions.
  // seed: 'mui-4-jss',
});

export const StylesProviderV4 = ({ children }) => (
  <StylesProvider generateClassName={generateClassName}>
    { children }
  </StylesProvider>
);

StylesProviderV4.propTypes = {
  children: PropTypes.node.isRequired,
};

const themeV4 = createThemeV4({
  typography: {
    fontFamily: ['CircularStd'],
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '12px',
      },
    },
  },
});

export const ThemeProviderV4 = ({ children }) => (
  <MuiThemeProviderV4 theme={themeV4}>
    { children }
  </MuiThemeProviderV4>
);

ThemeProviderV4.propTypes = {
  children: PropTypes.node.isRequired,
};

const themeV5 = createThemeV5({
  typography: {
    fontFamily: ['CircularStd'],
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '12px',
      },
    },
  },
  palette: {
    primary500: {
      main: colors.primary_color_500,
      light: colors.primary_color_400,
      dark: colors.primary_color_600,
      active: colors.primary_color_800,
      contrastText: 'white',
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          '&:active': {
            backgroundColor: theme.palette[ownerState?.color]?.active,
          },
        }),
      },
    },
  },
});

export const ThemeProviderV5 = ({ children }) => (
  <MuiThemeProviderV5 theme={themeV5}>
    { children }
  </MuiThemeProviderV5>
);

ThemeProviderV5.propTypes = {
  children: PropTypes.node.isRequired,
};
