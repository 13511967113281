import styled from 'styled-components';
import colors from 'assets/styles/colors';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Text = styled.div`
  margin-left: 17px;
  color:${colors.secundary_color_700};
  font-size: 14px;
  line-height: 24px;
  font-weight: 450;
`;
