import React from 'react';
import PropTypes from 'prop-types';
import {
  Switch, Route, Redirect, useRouteMatch,
} from 'react-router-dom';

import { permissoes } from 'modules/auth/permissoes';
import { hasPermission } from 'modules/auth/auth';
import PermissionRoute from 'modules/auth/routes/permissionRoute';
import MenuSolicitacaoLimite from './menuSolicitacaoLimite';
import AlteracaoLimitePage from './views/alteracaoLimitePage';
import TransferenciaLimitePage from './views/transferenciaLimitePage';
import AcompanhamentoPage from './views/acompanhamentoPage';
import './solicitacaoLimite.scss';
import LimitesAprovadosPage from './views/aprovacoes/limitesAprovadosPage';
import LimitesAprovadosCadastro from './views/aprovacoes/limitesAprovadosCadastro';

const SolicitacaoLimite = ({ user }) => {
  const { path } = useRouteMatch();

  const hasPermissionAlteracao = hasPermission(user, permissoes.limite.alteracaoCredito);
  const hasPermissionTransferencia = hasPermission(user, permissoes.limite.transferenciaCredito);
  const hasPermissionLimitesAprovados = hasPermission(user, permissoes.liberacaoLimite);

  let defaultPath = '';
  if (hasPermissionAlteracao) defaultPath = 'alteracao-limite';
  else if (hasPermissionTransferencia) defaultPath = 'transferencia-limite';
  else if (hasPermissionLimitesAprovados) defaultPath = 'limites-aprovados';
  else defaultPath = 'listagem';

  return (
    <div className="solicitacao-limite-page__page">
      <div className="solicitacao-limite-page__menu">
        <MenuSolicitacaoLimite />
      </div>
      <div className="solicitacao-limite-page__content">
        <Switch>
          <PermissionRoute
            path={`${path}/alteracao-limite`}
            requireAll={[permissoes.limite.alteracaoCredito]}
          >
            <AlteracaoLimitePage />
          </PermissionRoute>
          <PermissionRoute
            path={`${path}/transferencia-limite`}
            requireAll={[permissoes.limite.transferenciaCredito]}
          >
            <TransferenciaLimitePage />
          </PermissionRoute>
          <PermissionRoute
            path={`${path}/listagem`}
            requireAll={[permissoes.limite.transferenciaCredito]}
          >
            <AcompanhamentoPage />
          </PermissionRoute>
          <PermissionRoute
            path={`${path}/aprovacoes/limites-aprovados`}
            requireAll={[permissoes.liberacaoLimite]}
          >
            <LimitesAprovadosPage />
          </PermissionRoute>
          <PermissionRoute
            path={`${path}/aprovacoes/lista-documentos`}
            requireAll={[permissoes.liberacaoLimite]}
          >
            <LimitesAprovadosCadastro />
          </PermissionRoute>
          <Route path="*">
            <Redirect to={`${path}/${defaultPath}`} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

SolicitacaoLimite.propTypes = {
  user: PropTypes.object.isRequired,
};

export default SolicitacaoLimite;
