import React from 'react';

import BreadCrumb from 'features/common-staff/layout/breadCrumb';

const BreadCrumbAlteracao = () => (
  <BreadCrumb
    labels={[{
      label: 'Limites',
      path: ' ',
    },
    {
      label: 'Solicitação de Aumento ou Redução de Limite',
      path: '/alteracao-limite',
    }]}
  />
);

export default BreadCrumbAlteracao;
