import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import { PAYMENT_STEP_EDIT, PAYMENT_STEP_CONFIRM } from '../../redux/paymentActions';
import { Tracking } from '../../../../modules';

import { LoadMoreButton, Button } from '../../../common';
import PaymentListRow from '../paymentListRow';
import InvoicesCount from '../invoicesCount';
import PaymentListingHeader from './paymentListingHeader';
import InvoicesTotalValue from '../invoicesTotalValue';

import './paymentListing.scss';

class PaymentListing extends React.Component {
  componentDidMount() {
    const { loadNewPage } = this.props;
    const {
      getBalance,
    } = this.props;
    getBalance();

    loadNewPage(0);
  }

  handleLoadNewPage = () => {
    const {
      invoices, page, loadNewPage, endDate, startDate, searchText,
    } = this.props;

    Tracking.Mixpanel.trackPaymentListLoadMore(invoices.length);
    loadNewPage(page + 1, 15, startDate, endDate, searchText);
  };

  handleRowClick = (invoice, isActive) => {
    const {
      selectedInvoices, selectInvoice, deselectInvoice,
    } = this.props;

    if (!isActive) {
      selectInvoice(invoice, selectedInvoices);
    } else {
      deselectInvoice(invoice, selectedInvoices);
    }
  };

  isPaymentEnabled = () => {
    const { totalValue, remainingBalance } = this.props;

    return (
      Boolean(totalValue)
      && Boolean(remainingBalance)
      && remainingBalance >= totalValue
    );
  };

  handlePayClick = () => {
    const {
      selectedInvoices, setPaymentStep, updateSelectedInvoices, totalValue, remainingBalance,
    } = this.props;

    const fullValueInvoices = selectedInvoices.map(
      (i) => ({
        ...i,
        selectedForPaymentValue: i.remainingValue,
      }),
    );

    Tracking.Mixpanel.trackPaymentListPayClicked(
      selectedInvoices.length,
      totalValue,
      remainingBalance,
    );
    updateSelectedInvoices(fullValueInvoices);
    setPaymentStep(PAYMENT_STEP_CONFIRM);
  };

  handleEditClick = () => {
    const {
      selectedInvoices, totalValue, remainingBalance, setPaymentStep,
    } = this.props;

    Tracking.Mixpanel.trackPaymentListEditClicked(
      selectedInvoices.length,
      totalValue,
      remainingBalance,
    );
    setPaymentStep(PAYMENT_STEP_EDIT);
  };

  render() {
    const {
      invoices, hasMoreItens, className, t,
      selectedInvoices, totalValue, remainingBalance, loading, getInvoices,
      setFilterSearchText, startDate, endDate, isSearchActive, setSearchTextValue,
      searchTextValue, setEndDate, setStartDate,
    } = this.props;

    return (
      <div className="payment-content">
        <div className={`payment-list ${className}`}>
          <div className="payment-list-container">
            <PaymentListingHeader
              getInvoices={getInvoices}
              setFilterSearchText={setFilterSearchText}
              startDate={startDate}
              endDate={endDate}
              isSearchActive={isSearchActive}
              setSearchTextValue={setSearchTextValue}
              searchTextValue={searchTextValue}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
            />
            {
            invoices.map((invoice, index) => {
              const isActive = selectedInvoices.some(
                (i) => (i.invoiceNumber === invoice.invoiceNumber),
              );

              return (
                <PaymentListRow
                  isActive={isActive}
                  onClick={
                    () => { this.handleRowClick(invoice, isActive); }
                  }
                  invoice={invoice}
                  key={index}
                />
              );
            })
          }

            <LoadMoreButton
              hasMoreItens={hasMoreItens}
              loading={loading}
              onClick={this.handleLoadNewPage}
              noItemsMessage="Nenhuma duplicata encontrada"
            />
          </div>

          <div className="payment-list-footer">
            <InvoicesCount
              className="payment-footer-count"
              invoices={selectedInvoices}
            />

            <InvoicesTotalValue
              className="payment-footer-total"
              loading={loading}
              totalValue={totalValue || 0}
              remainingBalance={remainingBalance || 0}
            />

            <Button
              className="payment-footer-edit"
              disabled={selectedInvoices.length <= 0}
              onClick={this.handleEditClick}
            >
              {t('Editar Valor')}
            </Button>

            <Button
              className="payment-footer-pay"
              disabled={!this.isPaymentEnabled()}
              onClick={this.handlePayClick}
            >
              {t('Pagar')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

PaymentListing.propTypes = {
  className: PropTypes.string,

  invoices: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  hasMoreItens: PropTypes.bool.isRequired,
  selectedInvoices: PropTypes.array.isRequired,
  totalValue: PropTypes.number,
  remainingBalance: PropTypes.number,

  getInvoices: PropTypes.func.isRequired,
  setFilterSearchText: PropTypes.func.isRequired,

  getBalance: PropTypes.func.isRequired,

  searchText: PropTypes.string,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  isSearchActive: PropTypes.bool,
  searchTextValue: PropTypes.string,
  setSearchTextValue: PropTypes.func.isRequired,
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,

  setPaymentStep: PropTypes.func.isRequired,
  selectInvoice: PropTypes.func.isRequired,
  deselectInvoice: PropTypes.func.isRequired,
  updateSelectedInvoices: PropTypes.func.isRequired,
  loadNewPage: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

PaymentListing.defaultProps = {
  className: '',
  totalValue: undefined,
  remainingBalance: undefined,

  searchTextValue: '',
  searchText: '',
  startDate: null,
  endDate: null,
  isSearchActive: false,
};

export default withNamespaces('paymentPage')(PaymentListing);
