import { connect } from 'react-redux';
import BotaoFiltrar from 'features/common-staff/controls/buttonFilter';
import operations from '../../../redux/operations';

const mapStateToProps = ({ relatoriosIof }) => ({
  isFilterSelected: relatoriosIof.isRelatorioSelected && relatoriosIof.isDateRangeSelected,
  isLoading: relatoriosIof.requestStatus.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  onClick: () => { dispatch(operations.setPageParams('page', 0)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(BotaoFiltrar);
