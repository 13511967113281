import colors from 'assets/styles/colors';

export const textField = (props) => ({
  root: {
    '& .MuiInputBase-root': {
      marginTop: '19px',
      borderRadius: '4px',
      fontSize: '14px',
      lineHeight: '1.71',
      color: '#8f9bb3',
      backgroundColor: 'rgba(228, 233, 242, 0.24)',
      height: '40px',
      padding: props.smallerRoot ? '8px 8px' : '12px 8px',
    },
    '& .MuiInputBase-input': {
      fontSize: '14px',
      lineHeight: '1.71',
      color: '#8f9bb3',
      padding: '0px',
      '&::placeholder': {
        opacity: 1,
      },
    },
    '& .MuiFormLabel-root': {
      fontSize: '14px',
      lineHeight: '1.33',
      color: '#8f9bb3',
      marginLeft: '12px',
    },
    '& .MuiInput-underline:after': {
      display: 'none',
      borderBottom: 'none',
    },
    '& .MuiInput-underline:before': {
      display: 'none',
      borderBottom: 'none',
      transition: 'none',
    },
    '& .MuiInput-underline': {
      borderBottom: 'none',
    },
    '& .Mui-error': {
      border: '1px solid red',
    },
    '& .Mui-focused': {
      border: `1px solid ${colors.primary_color_600}`,
    },
  },
  container: {
    fontSize: '14px',
    margin: '1px 0px 0px 8px',
    color: '#555770',
    whiteSpace: 'pre-wrap',
  },
  numberFormat: {
    marginTop: '-30px',
  },
});
