import React, { useEffect, useState, useRef } from 'react';
import ButtonFilter from 'features/common-staff/controls/buttonFilter';
import TextFilterDebounce from 'features/common-staff/controls/textFilterDebounce/textFilterDebounce';
import { useDuplicataContext } from 'features/carteiraFidc/contexts/duplicataContext';
import NewDatepicker from 'features/common/newDatePicker/newDatePicker';
import { loadDealers, loadSelectedFPlanDealerId } from 'modules/dealers/dealers';
import NewBasicSelect from 'features/common/newBasicSelect/newBasicSelect';
import { arrayNotaFiscal, arrayStatusDuplicata } from 'utils/array';
import useToggleEffect from 'hooks/useToggleEffect';
import { Tracking } from 'modules';
import { formatCodigoConcessionaria, formatNomeConcessionaria } from 'utils/format';
import ExportButton from './exportButton/exportButton';
import {
  getDate, compareFilters,
} from './helper';
import {
  Container,
  FilterRow,
  Search,
  Apply,
  ContainerSelector,
  DateContainer,
  ButtonContainer,
  ClearButton,
} from './duplicataFilters.style';

const compareValor = (valor) => (valor === 'all'
  ? {
    valorMaximo: null,
    valorMinimo: null,
  } : {
    valorMaximo: valor.valorMaximo,
    valorMinimo: valor.valorMinimo,
  });

const DuplicataFilters = () => {
  const duplicataContext = useDuplicataContext();
  const {
    state, getDuplicatas, setFilters, setPagination, closeDrawer, resetFilters,
  } = duplicataContext;
  const {
    duplicataChassi, valorNotaFiscal,
    dataInicioEntradaFidc, dataFinalEntradaFidc,
    dataInicioVencimento, dataFimVencimento,
    status, concessionaria,
  } = state.filters;

  const { loading } = state.requestStatus;
  const { isOpen } = state.duplicataDrawer;
  const [localFilter, setLocalFilter] = useState(duplicataChassi);
  const [localDataInicioEntrada, setLocalDataInicioEntrada] = useState(dataInicioEntradaFidc);
  const [localDataFimEntrada, setLocalDataFimEntrada] = useState(dataFinalEntradaFidc);
  const [localDataInicioVencimento, setLocalDataInicioVencimento] = useState(dataInicioVencimento);
  const [localDataFimVencimento, setLocalDataFimVencimento] = useState(dataFimVencimento);
  const [invalidEntradaDate, setInvalidEntradaDate] = useState(false);
  const [invalidVencimentoDate, setInvalidVencimentoDate] = useState(false);
  const [localNotaFiscal, setValorNotaFiscal] = useState(valorNotaFiscal);
  const [localStatus, setLocalStatus] = useState(status);
  const [isTouched, setIsTouched] = useState(false);
  const [localConcessionaria, setLocalConcessionaria] = useState(concessionaria);

  const concessionariaList = loadDealers();
  const selectedDealerId = loadSelectedFPlanDealerId();

  const [toggleFetch] = useToggleEffect(() => {
    getDuplicatas();
  }, [
    duplicataChassi, valorNotaFiscal,
    dataInicioEntradaFidc, dataFinalEntradaFidc, dataInicioVencimento, dataFimVencimento, status,
    concessionaria,
  ]);

  const compare = () => {
    const { valorMinimo, valorMaximo } = compareValor(valorNotaFiscal);
    const { valorMinimo: valorMinimoLocal, valorMaximo: valorMaximoLocal } = compareValor(
      localNotaFiscal,
    );
    return compareFilters(
      [
        localFilter, valorMinimoLocal, valorMaximoLocal,
        getDate(localDataInicioEntrada), getDate(localDataFimEntrada),
        getDate(localDataInicioVencimento), getDate(localDataFimVencimento), localStatus === 'all' ? null : localStatus,
        localConcessionaria === 'all' ? null : localConcessionaria,
      ],
      [
        duplicataChassi, valorMinimo, valorMaximo,
        dataInicioEntradaFidc, dataFinalEntradaFidc, dataInicioVencimento, dataFimVencimento, status === 'all' ? null : status,
        concessionaria === 'all' ? null : concessionaria,
      ],
    );
  };

  const { filled, diff, localFilled } = compare();
  const setFilter = () => {
    let valorMinimo = 0;
    let valorMaximo = 0;
    if (localNotaFiscal === 'all') {
      valorMaximo = null;
      valorMinimo = null;
    } else {
      valorMaximo = localNotaFiscal.valorMaximo;
      valorMinimo = localNotaFiscal.valorMinimo;
    }
    setPagination('page', 0);
    setFilters(
      [
        'duplicataChassi', 'valorNotaFiscal',
        'dataInicioEntradaFidc', 'dataFinalEntradaFidc',
        'dataInicioVencimento', 'dataFimVencimento', 'status', 'concessionaria',
      ],
      [
        localFilter, { valorMinimo, valorMaximo },
        getDate(localDataInicioEntrada), getDate(localDataFimEntrada),
        getDate(localDataInicioVencimento), getDate(localDataFimVencimento), localStatus,
        localConcessionaria,
      ],
    );
    toggleFetch();
    setIsTouched(false);
  };

  const entradaRef = useRef({});
  const vencimentoRef = useRef({});

  const resetLocal = () => {
    setLocalFilter(null);
    setValorNotaFiscal('all');
    setLocalStatus('all');
    setLocalDataInicioEntrada(null);
    setLocalDataFimEntrada(null);
    setLocalDataInicioVencimento(null);
    setLocalDataFimVencimento(null);
    setInvalidEntradaDate(false);
    setInvalidVencimentoDate(false);
    entradaRef.current.resetDate();
    vencimentoRef.current.resetDate();
    setIsTouched(false);
    setLocalConcessionaria('all');
  };

  const handleLimpar = () => {
    Tracking.Mixpanel.fidc.trackButtons('fidc_duplicatas_botao_limpar_filtros');
    resetLocal();
    if (filled) {
      resetFilters();
      toggleFetch();
    }
  };

  useEffect(() => {
    if (filled && diff && !isTouched) {
      setIsTouched(true);
    }
  }, [diff]);

  useEffect(() => {
    setLocalConcessionaria(null);
  }, [selectedDealerId]);

  const selectedDealer = concessionariaList.find((c) => c.dealerId === selectedDealerId);
  const raizCnpj = selectedDealer.cnpj.substring(0, 8);
  const filtedConcessionarias = concessionariaList
    .filter((c) => c.cnpj.substring(0, 8) === raizCnpj);
  const options = filtedConcessionarias
    .map((c) => ({
      value: c.cnpj,
      label: `${formatNomeConcessionaria(c.dealerName.trim())} - ${formatCodigoConcessionaria(c.dealerId)}`,
    }));

  return (
    <Container>
      <FilterRow isOpen={isOpen}>
        <Search className="duplicataChassi">
          <TextFilterDebounce
            dataCy="FilterDuplicataChassi"
            label="Duplicata"
            placeholder="Buscar duplicata ou chassi"
            value={localFilter}
            setValue={setLocalFilter}
            disabled={loading}
            tracking={() => Tracking.Mixpanel.fidc.trackButtons('fidc_duplicatas_filtro_duplicata_chassi')}
            showSearchIcon
          />
        </Search>
        <ContainerSelector className="duplicataConcessionaria">
          <NewBasicSelect
            dataCy="FilterDuplicatasConcessionaria"
            nameLabel="Concessionária"
            labelAll="Todas as concessionárias"
            options={options}
            selectedOption={localConcessionaria}
            setOption={setLocalConcessionaria}
            isLoading={loading}
            tracking={() => Tracking
              .Mixpanel.fidc.trackButtons('fidc_duplicatas_filtro_duplicata_concessionaria')}
          />
        </ContainerSelector>
        <DateContainer className="dataEntrada">
          <NewDatepicker
            dataCy="FilterDataEntrada"
            title="Data de entrada no FIDC"
            startDate={localDataInicioEntrada}
            endDate={localDataFimEntrada}
            setStartDate={setLocalDataInicioEntrada}
            setEndDate={setLocalDataFimEntrada}
            numberOfMonths={2}
            setInvalidDate={setInvalidEntradaDate}
            invalidDateProp={invalidEntradaDate}
            disabled={loading}
            closeDrawer={closeDrawer}
            ref={entradaRef}
            tracking={() => Tracking.Mixpanel.fidc.trackButtons('fidc_duplicatas_filtro_data_entrada_fidc')}
          />
        </DateContainer>
        <DateContainer className="dataVencimento">
          <NewDatepicker
            dataCy="FilterDataVencimento"
            title="Data de vencimento"
            startDate={localDataInicioVencimento}
            endDate={localDataFimVencimento}
            setStartDate={setLocalDataInicioVencimento}
            setEndDate={setLocalDataFimVencimento}
            numberOfMonths={2}
            disabled={loading}
            setInvalidDate={setInvalidVencimentoDate}
            invalidDateProp={invalidVencimentoDate}
            closeDrawer={closeDrawer}
            ref={vencimentoRef}
            tracking={() => Tracking.Mixpanel.fidc.trackButtons('fidc_duplicatas_filtro_data_vencimento')}
          />
        </DateContainer>

        <ContainerSelector className="valorNotaFiscal">
          <NewBasicSelect
            dataCy="FilterNotaFiscal"
            nameLabel="Valor da nota fiscal"
            options={arrayNotaFiscal}
            selectedOption={localNotaFiscal}
            setOption={setValorNotaFiscal}
            isLoading={loading}
            tracking={() => Tracking.Mixpanel.fidc.trackButtons('fidc_duplicatas_filtro_valor_nota_fiscal')}
          />
        </ContainerSelector>

        <ContainerSelector className="duplicataStatus">
          <NewBasicSelect
            dataCy="duplicataStatus"
            nameLabel="Status"
            labelAll="Todos os status"
            options={arrayStatusDuplicata}
            selectedOption={localStatus}
            setOption={setLocalStatus}
            isLoading={loading}
            tracking={() => Tracking
              .Mixpanel.fidc.trackButtons('fidc_duplicatas_filtro_status_duplicata')}
          />
        </ContainerSelector>

        <ButtonContainer className="buttonContainer">
          <Apply>
            {
              !filled || isTouched
                ? (
                  <ButtonFilter
                    dataCy="FilterApplyButton"
                    isFilterSelected={diff || isTouched}
                    error={invalidEntradaDate || invalidVencimentoDate}
                    textApply="Filtrar"
                    textSelect="Filtrar"
                    onClick={() => {
                      setFilter();
                      Tracking.Mixpanel.fidc.trackButtons('fidc_duplicatas_botao_filtrar');
                    }}
                    isLoading={loading}
                  />
                )
                : (
                  <ClearButton
                    disabled={!localFilled || loading}
                    onClick={handleLimpar}
                    dataCy="FilterClearButton"
                  >
                    Limpar filtros
                  </ClearButton>
                )
            }
          </Apply>
          <Apply>
            <ExportButton />
          </Apply>
        </ButtonContainer>
      </FilterRow>
    </Container>
  );
};

export default DuplicataFilters;
