import { connect } from 'react-redux';
import { compose } from 'redux';
import { push } from 'react-router-redux';
import { withRouter } from 'react-router-dom';

import Footer from './footer';
import selector from '../../../redux/selector';
import pagamentosPageOpertaions from '../../../../../pagamento/redux/operations';
import {
  PATH_PAGAMENTO,
} from '../../../../../../../../routes/paths';

const mapStateToProps = ({ gestaoPagamentos }) => ({
  totalValue: selector.getTotalValue(gestaoPagamentos),
});

const mapDispatchToProps = (dispatch) => ({
  navTo: () => dispatch(push(PATH_PAGAMENTO)),
  setDocs: (invoices) => dispatch(pagamentosPageOpertaions.setDocumentosPagemento(invoices)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Footer);
