import axios from 'axios';
import qs from 'qs';

class TestDriveService {
  constructor() {
    this.URL_API_TD = `${window.env.REACT_APP_API_URL}/testdrive`;
  }

  async getOffers(marcas, produto, dealers) {
    const response = await axios.get(`${this.URL_API_TD}/descontos/cards`, {
      params: {
        marca: (marcas || []).map((m) => m[0].toUpperCase() + m.slice(1)),
        produto,
        dealersId: (dealers || []).map((d) => d.dealerId),
      },
      paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
    });

    if (response.status !== 200 || !response.data || !Array.isArray(response.data)) {
      throw new Error('Can\'t get desconto cards');
    }

    const offerDtos = response.data.map((card) => ({
      id: card.descontoVeiculoId,
      dveNumber: card.dveMkt,
      price: null,
      startDate: card.vigenciaInicio,
      endDate: card.vigenciaFim,
      brand: card.marca,
      model: card.modelo,
      modelDescription: card.modeloDescricao,
      version: card.versao,
      modelYear: card.modelYear,
      versionDescription: card.versaoDescricao,
      serie: card.serie,
      serieDescription: card.serieDescricao,
      marketAi: null,
      observation: null,
      imageUrl: card.urlImagemVersao ?? card.urlImagemModelo,

      percentualFinanciado: card.percentualFinanciado,
      percentualAVista: card.PercentualAVista,
      concessionariasPermitidas: card.concessionariasPermitidas,
    }));

    return offerDtos;
  }

  async getCommercialConditions(modelo, versao, serie, modelYear, marca, produto) {
    const config = {
      params: {
        modelo,
        versao,
        serie,
        modelYear,
        marca,
        produto,
      },
    };
    const response = await axios.get(`${this.URL_API_TD}/condicoes-comerciais`, config);

    if (response.status !== 200 || !response.data) {
      throw new Error('Can\'t get commercial conditions');
    }

    return response.data;
  }

  async postForm(form) {
    const response = await axios.post(
      `${this.URL_API_TD}/pedidos/`,
      form,
    );

    if (response.status !== 200) {
      throw new Error('Error submitting solicitacao produto');
    }
  }

  async getMotivosTestDriveExcecao() {
    const response = await axios.get(`${this.URL_API_TD}/pedidos/motivos`);

    if (response.status !== 200 || !response.data || !Array.isArray(response.data)) {
      throw new Error('Can\'t get desconto cards');
    }
    return response.data;
  }

  async getUrlUploadFileTdExcecao(fileName, fileType) {
    const response = await axios.get(`${this.URL_API_TD}/pedidos/urlUpload`, { params: { fileName, fileType } });
    if (!response.data || response.status !== 200) {
      throw new Error('Falha ao tentar buscar a url de upload do arquivo!');
    }

    return response.data;
  }

  async deleteUrlUploadFileTdExcecao(key) {
    const response = await axios.delete(`${this.URL_API_TD}/pedidos/urlUpload`, { params: { key } });
    if (!response.data || response.status !== 200) {
      throw new Error('Falha ao tentar remover a url do Arquivo!');
    }

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async uploadFileTdExcecao(file) {
    const instance = axios.create();
    const config = {
      headers: {
        'Content-Type': file.type,
        'x-amz-acl': 'public-read',
        'Content-Disposition': `attachment; filename=${file.name};`,
      },
    };
    const response = await instance.put(file.urlUpload, file, config);
    if (response.status !== 200) {
      throw new Error('Falha ao tentar fazer upload do Arquivo!');
    }
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async uploadFileTdExcecao2(file, urlUpload) {
    const instance = axios.create();
    const config = {
      headers: {
        'Content-Type': file.type,
        'x-amz-acl': 'public-read',
        'Content-Disposition': `attachment; filename=${file.name};`,
      },
    };
    const response = await instance.put(urlUpload, file, config);
    if (response.status !== 200) {
      throw new Error('Falha ao tentar fazer upload do Arquivo!');
    }
    return response.data;
  }
}

export default new TestDriveService();
