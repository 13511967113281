import React from 'react';
import PropTypes from 'prop-types';

import { Tracking } from '../../../../modules';

import Chat from '../../../common/chat';
import ChatInput from '../../../common/chat/chatInput';

import PopoverHeader from '../popoverHeader';
import SendingAnswersBar from './sendingAnswerBar';

import { content } from '../../redux/content';
import {
  SEND_ANSWER_STATUS_SUCCESS,
} from './redux/reducer';
import { chatStates } from './redux/chat/chatStates';

import './userFeedback.scss';

class UserFeedback extends React.Component {
  componentDidMount() {
    const { permissions } = this.props;

    if (
      permissions.solicitarTestDrive !== true
      && permissions.efetuarPagamento !== true
    ) {
      this.resetPopoverContent();
    }

    this.componentDidUpdate();
  }

  componentDidUpdate() {
    const {
      user, selectedDealer, chatState, permissions,
      forceNextChatState, nextChatState, sendAnswerStatus,
    } = this.props;

    if (chatState === chatStates.get_permission_info) {
      nextChatState({
        solicitarTestDrive: permissions.solicitarTestDrive,
        efetuarPagamento: permissions.efetuarPagamento,
      });

      Tracking.Mixpanel.feedback.user.trackBegin();
    }

    if (chatState === chatStates.get_user_info) {
      const userInfo = {
        usuarioId: user.id,
        usuarioEmail: user.email,
        usuarioNome: user.name,
        concessionariaId: selectedDealer.dealerId,
        concessionariaNome: selectedDealer.dealerName,
        regional: selectedDealer.region,
        regionalCodigo: selectedDealer.regionId,
      };

      nextChatState(userInfo);
    }

    if (
      chatState === chatStates.envio_resposta
      && sendAnswerStatus === SEND_ANSWER_STATUS_SUCCESS
    ) {
      nextChatState();
    }

    if (forceNextChatState) {
      nextChatState();
    }
  }

  resetPopoverContent = () => {
    const { setPopoverContent, resetStore } = this.props;
    resetStore();
    setPopoverContent(content.main);
  };

  render() {
    const {
      className, messages, isInputAvailable, nextChatState,
      sendAnswerStatus, sendAnswer,
    } = this.props;

    return (
      <div className={`feedback-user__container ${className}`}>
        <PopoverHeader
          onClick={() => { this.resetPopoverContent(); }}
          className="feedback-user__header"
          title="Pesquisa de satisfação"
        />
        <div className="feedback-user__chat">
          <Chat mensagens={messages} />
        </div>
        <div className="feedback-user__input">
          { isInputAvailable ? (
            <ChatInput
              onSendClicked={(value, clearValue) => {
                nextChatState(value);
                clearValue();
              }}
            />
          ) : null }

          <SendingAnswersBar
            status={sendAnswerStatus}
            onRetryClicked={() => sendAnswer()}
          />
        </div>
      </div>
    );
  }
}

UserFeedback.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
  selectedDealer: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
  chatState: PropTypes.string.isRequired,
  forceNextChatState: PropTypes.bool.isRequired,
  isInputAvailable: PropTypes.bool.isRequired,
  sendAnswerStatus: PropTypes.string,

  setPopoverContent: PropTypes.func.isRequired,
  nextChatState: PropTypes.func.isRequired,
  resetStore: PropTypes.func.isRequired,
  sendAnswer: PropTypes.func.isRequired,
};

UserFeedback.defaultProps = {
  className: '',
  sendAnswerStatus: null,
};

export default UserFeedback;
