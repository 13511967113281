import React from 'react';

import ProdutoSelector from './produtoSelector';
import PontoSelector from './pontoSelector';
import FilterButton from './filterButton';
import DataSelector from './dataSelector';

import './filters.scss';

const Filters = () => (
  <div className="dashboard-floor-plan__filter__content">
    <div className="dashboard-floor-plan__filter__content_ponto">
      <PontoSelector />
    </div>
    <div className="dashboard-floor-plan__filter__content_produto">
      <ProdutoSelector />
    </div>
    <div className="dashboard-floor-plan__filter__content_data">
      <DataSelector />
    </div>
    <div className="dashboard-floor-plan__filter__content_botao">
      <FilterButton />
    </div>
  </div>
);

export default Filters;
