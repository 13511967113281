import { connect } from 'react-redux';

import InputValor from './inputValor';

const mapStateToProps = ({ limiteCreditoStore }) => ({
  valor: limiteCreditoStore.transferenciaLimite.valor,
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(InputValor);
