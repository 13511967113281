import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import { RadioGroup } from 'features/common';

const useStyles = makeStyles({
  input: {
    height: '80px',
  },
});

const SelectProduto = ({
  produtos,
  selectedProduct,
  setProduto,
  isError,
  isLoading,
  required,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.input}>
      <RadioGroup
        label="Produto"
        items={produtos}
        setItem={setProduto}
        selectedItem={selectedProduct}
        isError={isError}
        isLoading={isLoading}
        required={required}
      />
    </div>
  );
};

SelectProduto.propTypes = {
  setProduto: PropTypes.func,
  selectedProduct: PropTypes.string,
  produtos: PropTypes.array,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  required: PropTypes.bool,
};

SelectProduto.defaultProps = {
  setProduto: () => {},
  selectedProduct: null,
  produtos: null,
  isLoading: null,
  isError: null,
  required: false,
};

export default SelectProduto;
