import { connect } from 'react-redux';

import CarteiraList from './carteiraList';

const mapStateToProps = ({ gestaoPagamentos }) => ({
  isLoading: gestaoPagamentos.carteira.page.requestStatus.isLoading,
  isError: gestaoPagamentos.carteira.page.requestStatus.isError,
  invoices: gestaoPagamentos.carteira.page.invoices,
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CarteiraList);
