import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import {
  PAYMENT_STEP_LIST, PAYMENT_STEP_EDIT, PAYMENT_STEP_CONFIRM, PAYMENT_STEP_RECEIPT,
} from '../../redux/paymentActions';

import MenuInternetBanking from '../../../menu/view/menuInternetBanking';

import {
  PATH_PAYMENTS,
} from '../../../../routes/paths';

import { PageErrorBoundary } from '../../../common';

import PaymentHeader from '../paymentHeader';
import PaymentListing from '../paymentListing';
import PaymentEditor from '../paymentEditor';
import PaymentConfirmation from '../paymentConfirmation';
import { PaymentReducer } from '../../redux';
import PaymentReceipt from '../paymentReceipt';

import './paymentPage.scss';

const { INITIAL_PAGE } = PaymentReducer;

class PaymentPage extends React.Component {
  componentDidUpdate(prevProps) {
    const {
      dealer, getInvoices, resetPaymentStore,
    } = this.props;
    if (dealer !== prevProps.dealer) {
      resetPaymentStore();
      getInvoices(INITIAL_PAGE, null, null);
    }
  }

  componentWillUnmount() {
    const { resetPaymentStore } = this.props;
    resetPaymentStore();
  }

  contentForStep(step) {
    switch (step) {
      case PAYMENT_STEP_CONFIRM:
        return (
          <PaymentConfirmation
            className="payment-confirmation"
          />
        );
      case PAYMENT_STEP_EDIT:
        return (
          <PaymentEditor
            className="payment-editor"
          />
        );
      case PAYMENT_STEP_RECEIPT:
        return (
          <PaymentReceipt
            className="payment-receipt"
          />
        );
      default:
        return (
          <PaymentListing
            className="payment-list"
            handlePayment={this.handlePayment}
          />
        );
    }
  }

  render() {
    const { step } = this.props;

    return (
      <PageErrorBoundary>
        <div className="internet-banking-page">
          {step !== PAYMENT_STEP_RECEIPT
            ? (
              <section className="internet-banking-menu-container">
                <MenuInternetBanking
                  pathname={PATH_PAYMENTS}
                />
              </section>
            )
            : null}
          <PageErrorBoundary>
            <section className="payment-page-content">
              {step === PAYMENT_STEP_CONFIRM ? (<PaymentHeader />) : (null)}
              { this.contentForStep(step) }
            </section>
          </PageErrorBoundary>
        </div>
      </PageErrorBoundary>
    );
  }
}

PaymentPage.propTypes = {
  step: PropTypes.string,
  resetPaymentStore: PropTypes.func.isRequired,
  dealer: PropTypes.object.isRequired,
  getInvoices: PropTypes.func.isRequired,
};

PaymentPage.defaultProps = {
  step: PAYMENT_STEP_LIST,
};

export default withNamespaces('paymentPage')(PaymentPage);
