import styled from 'styled-components';
import { Checkbox, FormControlLabel, Radio } from '@mui/material';

export const CustomRadio = styled(Radio)`
  color: #555770 !important;
  &.Mui-checked {
    color: #1B2961 !important;
  };
  &.Mui-disabled {
    color: #7A7C9A !important;
  }
`;

export const CustomCheckbox = styled(Checkbox)`
  color: #A6A8BB !important;
  &.Mui-checked {
    color: #3C414E !important;
  };
`;

export const CustomFormLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    opacity: 1;
    color: #404154;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    letter-spacing: 0px;
  }
`;
