import mixpanel from 'mixpanel-browser';
import Auth from '../auth';

const MixpanelWrapper = {
  init: () => {
    mixpanel.init(window.env.REACT_APP_MIXPANEL_TOKEN || 'no_token');

    const userId = Auth.getCurrentUserId();
    MixpanelWrapper.identifyUser(userId);
  },

  identifyUser: (userId) => {
    if (userId) {
      mixpanel.identify(userId);
    }
  },

  trackPageNavigation: (path) => {
    mixpanel.track('page_navigation', { path });
  },

  trackMenuClick: (target) => {
    mixpanel.track(
      'menu_click',
      {
        target,
      },
    );
  },

  trackLoginAttempt: (email, success) => {
    mixpanel.track(
      'login_attempt',
      {
        email_field: email,
        result: success ? 'success' : 'fail',
      },
    );
  },

  trackLoginRedirectAttempt: (email, success) => {
    mixpanel.track(
      'login_redirect_attempt',
      {
        email_field: email,
        result: success ? 'success' : 'fail',
      },
    );
  },

  trackHideBalance: (visible) => {
    mixpanel.track(
      'balance_hide_show',
      {
        target_state: visible ? 'visible' : 'hidden',
      },
    );
  },

  trackLogout: () => {
    mixpanel.track('user_logout');
  },

  movimentacoes: {
    trackFilters: {
      trackTypeTransactionFilter: (value) => {
        mixpanel.track('transactions_type_filter', { transactionTypeFilter: value });
      },
      trackTypeDateFilter: () => {
        mixpanel.track('transactions_date_filter');
      },
      trackTypeTextFilter: () => {
        mixpanel.track('transactions_text_filter');
      },
    },

    trackTransactionSelected: () => {
      mixpanel.track('transactions_selected');
    },
    trackProofOfPaymentClick: () => {
      mixpanel.track('transactions_proof_of_payment_click');
    },
    trackPaymentExport: () => {
      mixpanel.track('transactions_payment_export');
    },
    trackPaymentPrint: () => {
      mixpanel.track('transactions_payment_print');
    },
    trackInvoiceOrigin: () => {
      mixpanel.track('transactions_invoice_origin_redirect');
    },
    trackInvoiceDestination: () => {
      mixpanel.track('transactions_invoice_destination_redirect');
    },
  },

  carteira: {
    carteiraFilters: {
      trackTypeStatusFilter: (value) => {
        mixpanel.track('status_type_filter', { statusFilter: value });
      },
      trackTypeProductFilter: (value) => {
        mixpanel.track('product_type_filter', { productFilter: value });
      },
      trackTypeDealerFilter: (value) => {
        mixpanel.track('dealer_type_filter', { dealerTypeFilter: value });
      },
      trackTypeDocumentFilter: (value) => {
        mixpanel.track('document_type_filter', { documentTypeFilter: value });
      },
      trackTypePriceRangeFilter: (value) => {
        mixpanel.track('price_range_type_filter', { priceRangeFilter: value });
      },
      trackTaxas: () => {
        mixpanel.track('visualizacaoTaxasBanco');
      },
      trackHistoricoDuplicata: () => {
        mixpanel.track('acessoHistoricoDetalhamentoDuplicata');
      },
    },
    pagamento: {
      trackTypeExportCSVButton: () => {
        mixpanel.track('export_CSV_button');
      },
      trackTypeExportXLSXButton: () => {
        mixpanel.track('export_XLSX_button');
      },
      trackTypeConfirmPaymentButton: () => {
        mixpanel.track('confirm_payment_button');
      },
      trackTypeCancelPaymentButton: () => {
        mixpanel.track('cancel_payment_button');
      },
    },
  },

  testDrive: {

    trackProductSelected: () => {
      mixpanel.track('td_product_selected');
    },
    trackArrow: (currentValue, nextValue) => {
      if (currentValue < nextValue) mixpanel.track('td_offer_next');
      if (currentValue > nextValue) mixpanel.track('td_offer_previous');
    },

    trackOfferSelected: () => {
      mixpanel.track('td_offer_selected');
    },

    trackDealerSelected: () => {
      mixpanel.track('td_dealer_selected');
    },

    trackMotivoSelected: () => {
      mixpanel.track('td_motivo_selected');
    },

    trackConditionSelected: () => {
      mixpanel.track('td_condition_selected');
    },

    trackPrevious: () => {
      mixpanel.track('td_previous');
    },

    trackSubmit: (payload) => {
      mixpanel.track('td_submitted', payload);
    },

    trackSubmitError: () => {
      mixpanel.track('td_submit_error');
    },
  },
  acompanhamentoTd: {
    trackAcompanhamento: () => {
      mixpanel.track('td_acompanhamento');
    },
    trackFiltroStatus: (payload) => {
      mixpanel.track('td_acompanhamento_filtro_status', { status: payload });
    },
    trackFiltroTexto: () => {
      mixpanel.track('td_acompanhamento_filtro_texto');
    },
    trackClickDetalhe: () => {
      mixpanel.track('td_acompanhamento_detalhe');
    },
    trackCloseDetalhe: () => {
      mixpanel.track('td_acompanhamento_detalhe_close');
    },
    trackNewOrder: () => {
      mixpanel.track('td_acompanhamento_nova_solicitacao');
    },
    trackCancel: () => {
      mixpanel.track('td_acompanhamento_cancelar');
    },
    trackNotaFiscal: () => {
      mixpanel.track('td_acompanhamento_nota_fiscal');
    },
  },

  feedback: {
    popover: {
      trackOpen: () => mixpanel.track('feedback_popover_open'),
    },
    error: {
      trackBegin: () => mixpanel.track('feedback_error_begin'),
      trackSend: () => mixpanel.track('feedback_error_send'),
    },
    user: {
      trackBegin: () => mixpanel.track('feedback_user_begin'),
      trackSend: () => mixpanel.track('feedback_user_send'),
      trackSendError: () => mixpanel.track('feedback_user_send_error'),
      trackState: (state) => mixpanel.track('feedback_user_enter_state', { state }),
    },
  },

  alerta: {
    trackArrow: (currentValue, nextValue) => {
      if (currentValue < nextValue) mixpanel.track('alerta_proximo');
      if (currentValue > nextValue) mixpanel.track('alerta_voltar');
    },
    trackClose: () => {
      mixpanel.track('alerta_fechar');
    },
  },
  alterarLimite: {
    trackType: (payload) => {
      mixpanel.track('lim_alterar_tipo', { tipo: payload });
    },
    trackProduto: (payload) => {
      mixpanel.track('lim_alterar_produto', { produto: payload });
    },
    trackConcessionaria: (payload) => {
      mixpanel.track('lim_alterar_concessionaria', { concessionaria: payload });
    },
    trackSubmit: () => {
      mixpanel.track('lim_alterar_enviar');
    },
    trackSubmitError: () => {
      mixpanel.track('lim_alterar_enviar_error');
    },
  },
  transferirLimite: {
    trackProdutoOrigem: (payload) => {
      mixpanel.track('lim_transferir_produto_origem', { produto: payload });
    },
    trackProdutoDestino: (payload) => {
      mixpanel.track('lim_transferir_produto_destino', { produto: payload });
    },
    trackConcessionariaOrigem: (payload) => {
      mixpanel.track('lim_transferir_concessionaria_origem', { concessionaria: payload });
    },
    trackConcessionariaDestino: (payload) => {
      mixpanel.track('lim_transferir_concessionaria_destino', { concessionaria: payload });
    },
    trackSubmit: () => {
      mixpanel.track('lim_transferir_enviar');
    },
    trackSubmitError: () => {
      mixpanel.track('lim_transferir_enviar_error');
    },
  },
  acompanhamentoLimite: {
    trackDetalheSolicitação: () => {
      mixpanel.track('solicitacao_limite_detalhes');
    },
  },
  dashboard: {
    filtros: {
      trackFiltroPontos: () => {
        mixpanel.track('dashboard_floor_plan_pontos_de_venda');
      },
      trackFiltrosProduto: () => {
        mixpanel.track('dashboard_floor_plan_produtos');
      },
      trackBotaoFiltro: () => {
        mixpanel.track('dashboard_floor_plan_bottao_filtro');
      },
      trackSelectData: () => {
        mixpanel.track('dashboard-floor-plan__dados__atualizados__ou__dia-anterior');
      },
    },
    duplicatasVencidas: {
      trackDuplicatasVencidas: () => {
        mixpanel.track('dashboard_floor_plan_duplicatas_vencidas');
      },
      trackDuplicatasVencimentoHoje: () => {
        mixpanel.track('dashboard_floor_plan_duplicatas_vencimento_hoje');
      },
      trackDuplicatasVencimentoDezDias: () => {
        mixpanel.track('dashboard_floor_plan_duplicatas_vencimento_dez_dias');
      },
      trackDuplicatasVencimentoVinteDias: () => {
        mixpanel.track('dashboard_floor_plan_duplicatas_vencimento_vinte_dias');
      },
    },
  },
  centralComunicados: {
    filtros: {
      trackFiltroData: () => {
        mixpanel.track('central_comunicados_filtro_data');
      },
      trackFiltrosBrand: () => {
        mixpanel.track('central_comunicados_filtro_brand');
      },
      trackFiltroBusca: () => {
        mixpanel.track('central_comunicados_filtro_busca');
      },
    },
    botoes: {
      trackBotaoVisualizacao: () => {
        mixpanel.track('comunicado_visualizado');
      },
      trackBotaoDownload: () => {
        mixpanel.track();
      },
    },
  },
  relatoriosIof: {
    filtros: {
      trackFiltroData: () => {
        mixpanel.track('relatorios_iof_data');
      },
      trackFiltroTipoRelatorio: (relatorio) => {
        mixpanel.track('relatorios_iof_tipo_relatorio', { relatorio });
      },
      trackFiltroConcessionaria: () => {
        mixpanel.track('relatorios_iof_filtro_concessionaria');
      },
    },
    botoes: {
      trackBotaoFiltrar: () => {
        mixpanel.track('relatorios_iof_botao_filtrar');
      },
      trackBotaoDownload: () => {
        mixpanel.track('relatorios_iof_botao_download');
      },
    },
  },

  listaLimitesAprovadosHub: {
    trackLinhaProdutoComAprovacao: (target) => {
      mixpanel.track(
        'linha_produto_com_aprovacao',
        {
          target,
        },
      );
    },
    trackBotaoPropostaAprovacao: (eventName, target) => {
      mixpanel.track(
        eventName,
        {
          target,
        },
      );
    },
  },

  fidc: {
    trackButtons: (eventName, payload, keyPayload) => {
      mixpanel.track(
        eventName,
        { [keyPayload]: payload },
      );
    },
  },
};

export default MixpanelWrapper;
