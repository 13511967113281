export const ModalTipo = {
  estorno: 'modal_estorno',
  pagamento: 'modal_pagamento',
};

export const DateRange = {
  today: 'hoje',
  yesterday: 'ontem',
  week: 'semanal',
  month: 'mensal',
  all: 'total',
  custom: 'custom',
};
