import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { useDuplicataContext } from 'features/carteiraFidc/contexts/duplicataContext';
import AlertaMensagem from 'features/common/alertaMensagem';
import List, { ListContent } from 'features/common-staff/layout/list';
import Loading from 'features/common-staff/layout/loading';
import { useOnboardingContext } from 'features/carteiraFidc/contexts/onboardingContext';
import DuplicataListHeader from './duplicataListHeader/duplicataListHeader';
import DuplicataListRow from './duplicataListRow/duplicataListRow';

import { Container, AlertContainer } from './duplicataList.style';

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'grid',
    columnGap: '0px',
    gridTemplateRows: 'auto',
    // Adicionar grid para status
    gridTemplateAreas: (isOpen) => (
      `'duplicata . valorPrincipal . valorAtualizado . juros ${isOpen ? '' : '. vencimento . status'}'`
    ),
    gridTemplateColumns: (isOpen) => (
      `minmax(12.5em, 1fr) 1fr minmax(8.5em, 1fr) 1fr minmax(9.5em, 1fr) 1fr minmax(7em, 1fr) ${isOpen ? '' : '1fr minmax(7em, 1fr) 1fr minmax(6em, 1fr)'}`
    ),
  },
});

const DuplicataList = () => {
  const duplicataContext = useDuplicataContext();
  const { state, openDrawer } = duplicataContext;
  const { duplicataList, requestStatus, duplicataDrawer } = state;
  const { duplicataIndex, isOpen, transition } = duplicataDrawer;
  const { loading, error } = requestStatus;

  const onboardingContext = useOnboardingContext();
  const { state: stateControle, activeControleUsuario, applyPropertyControle } = onboardingContext;
  const historicoDuplicatas = stateControle.onboarding.HISTORICO_DUPLICATA;
  const successOnboarding = stateControle.requestStatus.success;
  const duplicatasReady = stateControle.duplicatas;

  const gridTemplate = useStyles(isOpen);

  const [refresh, setRefresh] = useState(true);
  useEffect(() => {
    setRefresh(false);
  }, [transition]);

  useEffect(() => {
    if (!refresh) setRefresh(true);
  }, [refresh]);

  useEffect(() => {
    if (successOnboarding && (!!error || (!loading && _.isEmpty(duplicataList)))) {
      activeControleUsuario('MENU_RESGATE');
    }
    if (!duplicatasReady && !error && !loading && !_.isEmpty(duplicataList)) {
      applyPropertyControle('duplicatas');
    }
  }, [duplicatasReady, duplicataList, error, loading, successOnboarding, historicoDuplicatas]);

  return refresh && (
    <Container>
      <List
        isLoading={loading}
        isError={!!error}
        isEmpty={_.isEmpty(duplicataList)}
      >
        <ListContent>
          <DuplicataListHeader
            gridTemplate={gridTemplate}
            isDrawerOpen={isOpen}
          />
          {(Array.isArray(duplicataList) ? duplicataList : []).map((duplicata, i) => (
            <DuplicataListRow
              key={i}
              duplicata={duplicata}
              gridTemplate={gridTemplate}
              index={i}
              openDrawer={openDrawer}
              isDrawerOpen={isOpen}
              isSelected={i === duplicataIndex}
              selfClick={i === 0 && historicoDuplicatas?.active}
            />
          ))}
        </ListContent>

        <ListContent type="loading">
          <AlertContainer>
            <Loading
              delay
              mensagem="Estamos buscando as duplicatas. Aguarde um instante..."
            />
          </AlertContainer>
        </ListContent>

        <ListContent type="empty">
          <AlertContainer>
            <AlertaMensagem
              breakLine
              mensagem={'Não existem duplicatas disponíveis em sua carteira FIDC.\nFavor entrar em contato com seu analista do Banco Stellantis.'}
            />
          </AlertContainer>
        </ListContent>

        <ListContent type="error">
          <AlertContainer>
            <AlertaMensagem
              breakLine
              mensagem={'A carteira FIDC está indisponível no momento.\nPor favor, tente novamente mais tarde.'}
            />
          </AlertContainer>
        </ListContent>
      </List>
    </Container>
  );
};

export default DuplicataList;
