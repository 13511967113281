const SET_IS_OPEN = 'user-feedback/SET_IS_OPEN';
const SET_CONTENT = 'user-feedback/SET_CONTENT';
const REQUEST_OPEN_CONTENT = 'user-feedback/REQUEST_OPEN_CONTENT';
const FULFILL_OPEN_CONTENT_REQUEST = 'user-feedback/FULFILL_OPEN_CONTENT_REQUEST';

const requestOpenContent = (content) => ({
  type: REQUEST_OPEN_CONTENT,
  payload: {
    content,
  },
});

const fulfillOpenContentRequest = () => ({
  type: FULFILL_OPEN_CONTENT_REQUEST,
  payload: null,
});

const setIsOpen = (isOpen) => ({
  type: SET_IS_OPEN,
  payload: {
    isOpen,
  },
});

const setContent = (content) => ({
  type: SET_CONTENT,
  payload: {
    content,
  },
});

export const actions = {
  types: {
    REQUEST_OPEN_CONTENT,
    FULFILL_OPEN_CONTENT_REQUEST,
    SET_IS_OPEN,
    SET_CONTENT,
  },

  requestOpenContent,
  fulfillOpenContentRequest,
  setIsOpen,
  setContent,
};
