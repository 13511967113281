import { styled } from '@material-ui/styles';
import { TextField } from '@mui/material';
import colors from 'assets/styles/colors';

export const textField = () => ({
  root: {
    '& .MuiInputBase-root': {
      marginTop: '19px',
      borderRadius: '4px',
      fontSize: '14px',
      lineHeight: '1.71',
      color: colors.secundary_color_700,
      backgroundColor: 'rgba(228, 233, 242, 0.24)',
      padding: ({ smallerRoot }) => (smallerRoot ? '8px 8px' : '12px 8px'),
    },
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: '#FFFF',
    },
    '& .MuiInputBase-input': {
      fontSize: '14px',
      lineHeight: '1.71',
      color: colors.secundary_color_700,
      padding: '0px',
      textOverflow: 'ellipsis',
    },
    '& .MuiInputBase-input:disabled': {
      color: '#555770',
    },
    '& .MuiFormLabel-root': {
      fontSize: '14px',
      lineHeight: '1.33',
      color: colors.secundary_color_700,
      marginLeft: '12px',
    },
    '& .MuiInput-underline:after': {
      display: 'none',
      borderBottom: 'none',
    },
    '& .MuiInput-underline:before': {
      display: 'none',
      borderBottom: 'none',
      transition: 'none',
    },
    '& .MuiInput-underline': {
      borderBottom: 'none',
    },
    '& .MuiInputBase-adornedEnd': {
      backgroundColor: 'transparent',
    },
    '& .Mui-error': {
      border: `1px solid ${colors.error_color_300}`,
    },
  },
  container: {
    fontSize: '14px',
    marginLeft: '8px',
    color: '#555770',
    whiteSpace: 'pre-wrap',
  },
});

export const StyledTextField = styled(TextField)((props) => ({
  ...textField(props).root,
  '& .Mui-focused': {
    color: colors.secundary_color_700,
  },
}));

StyledTextField.defaultProps = {
  variant: 'standard',
};
