import * as React from "react";

function CheckMarkIcon(props) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M9.00002 0.666626C4.40002 0.666626 0.666687 4.39996 0.666687 8.99996C0.666687 13.6 4.40002 17.3333 9.00002 17.3333C13.6 17.3333 17.3334 13.6 17.3334 8.99996C17.3334 4.39996 13.6 0.666626 9.00002 0.666626ZM9.00002 15.6666C5.32502 15.6666 2.33335 12.675 2.33335 8.99996C2.33335 5.32496 5.32502 2.33329 9.00002 2.33329C12.675 2.33329 15.6667 5.32496 15.6667 8.99996C15.6667 12.675 12.675 15.6666 9.00002 15.6666ZM7.33335 10.8083L12.2334 5.90829C12.5584 5.58329 13.0917 5.58329 13.4167 5.90829C13.7417 6.23329 13.7417 6.75829 13.4167 7.08329L7.92502 12.575C7.60002 12.9 7.07502 12.9 6.75002 12.575L4.59169 10.4166C4.26669 10.0916 4.26669 9.56663 4.59169 9.24163C4.91669 8.91663 5.44169 8.91663 5.76669 9.24163L7.33335 10.8083Z" />
        </svg >
    );
}

export default CheckMarkIcon;

