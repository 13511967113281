export const chatStates = {
  get_permission_info: 'ETAPA_GET_PERMISSION_INFO',
  get_user_info: 'ETAPA_GET_USER_INFO',
  iniciar: 'ETAPA_INICIAR_AGORA',
  volte_depois: 'ETAPA_VOLTE_DEPOIS',
  solicitou_td: 'ETAPA_SOLICITOU_TD',

  td_nao_solicitou_inicio: 'ETAPA_TD_INICIO_N_SOLICITOU',
  td_pq_nao_solicitou: 'ETAPA_PORQUE_NAO_SOLICITOU_TD',
  td_pq_nao_solicitou_outros_qual: 'ETAPA_TD_PQ_N_SOLICITOU_OUTROS',
  td_nao_solicitou_fim: 'ETAPA_TD_FIM_N_SOLICITOU',

  td_solicitou_inicio: 'ETAPA_TD_SOLICITOU_INICIO',
  experiencia_hub: 'ETAPA_EXPERIENCIA_HUB',
  td_palavra_processo: 'ETAPA_TD_PALAVRA_PROCESSO',
  td_dificuldade: 'ETAPA_TD_DIFICULDADE_SIM_NAO',
  td_dificuldade_qual: 'ETAPA_TD_DIFICULDADE_QUAL',
  td_melhoria: 'ETAPA_TD_MELHORIA_SIM_NAO',
  td_melhoria_qual: 'ETAPA_TD_MELHORIA_QUAL',
  td_solicitou_fim: 'ETAPA_TD_SOLICITOU_FIM',

  pag_inicio: 'ETAPA_PAG_INICIO',
  pag_ja_utilizou: 'ETAPA_PAG_JA_UTILIZOU',
  pag_n_utilizou_motivo: 'ETAPA_PAG_N_UTILIZOU_MOTIVO',
  pag_experiencia: 'ETAPA_PAG_EXPERIENCIA',
  pag_palavra_hub: 'ETAPA_PAG_PALAVRA_REPRESENTA',
  pag_melhoria: 'ETAPA_PAG_MELHORIA_SIM_NAO',
  pag_melhoria_qual: 'ETAPA_PAG_MELHORIA_QUAL',
  pag_fim: 'ETAPA_PAG_FIM',

  melhorar_hub: 'ETAPA_MELHORAR_HUB',
  agradecemos: 'ETAPA_AGRADECEMOS',
  envio_resposta: 'ETAPA_ENVIO_RESPOSTA',
  fim: 'ETAPA_FIM',
};
