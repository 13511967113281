/* eslint-disable */

import React from "react";

const NewCalendarIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M11.08 1.99305C10.7534 1.66638 10.2267 1.66638 9.90004 1.99305L4.36004 7.53305C4.10004 7.79305 4.10004 8.21305 4.36004 8.47305L9.90004 14.013C10.2267 14.3397 10.7534 14.3397 11.08 14.013C11.4067 13.6864 11.4067 13.1597 11.08 12.833L6.25337 7.99971L11.0867 3.16638C11.4067 2.84638 11.4067 2.31305 11.08 1.99305Z" fill={props.fill}/>
  </svg>
);

export default NewCalendarIcon;

