import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Page, PageHeader, PageTitle, PageContent, PageSubTitle,
} from 'features/common-staff/layout/page';
import SplitView from 'features/common-staff/layout/splitView';

import { Tracking } from 'modules';
import BreadCrumbSolicitacoes from './views/breadCrumbSolicitacoes';
import AcompanhamentoList from './views/acompanhamentoList';

import AcompanhamentoDetalhe from './views/acompanhamentoDetalhe';

import './acompanhamentoPage.scss';
import Filters from './views/filters';

const AcompanhamentoPage = ({
  getPedidos, resetStore, getPedidosDealer, closeModal, isModalOpen,
}) => {
  useEffect(() => {
    getPedidosDealer();
    getPedidos();
    return () => { resetStore(); closeModal(); };
  }, [getPedidosDealer, getPedidos, resetStore, closeModal]);

  return (
    <SplitView
      open={isModalOpen}
      sideSheetProps={{
        onClickClose: () => {
          Tracking.Mixpanel.acompanhamentoTd.trackCloseDetalhe();
          closeModal();
        },
        width: 400,
      }}
      renderSideSheetContent={() => <AcompanhamentoDetalhe />}
    >
      <Page minWidth="unset" hideFooter>
        <PageHeader>
          <PageSubTitle><BreadCrumbSolicitacoes /></PageSubTitle>
          <div className="td-acompanhamento__page__header">
            <PageTitle>Minhas Solicitações</PageTitle>
          </div>
        </PageHeader>
        <PageContent>
          <div className="td-acompanhamento__page__root">
            <div className="td-acompanhamento__page__filters">
              <Filters />
            </div>
            <div className="td-acompanhamento__page__list">
              <AcompanhamentoList />
            </div>
          </div>
        </PageContent>
      </Page>
    </SplitView>
  );
};

AcompanhamentoPage.propTypes = {
  isModalOpen: PropTypes.bool,
  getPedidos: PropTypes.func,
  resetStore: PropTypes.func,
  getPedidosDealer: PropTypes.func,
  closeModal: PropTypes.func,
};

AcompanhamentoPage.defaultProps = {
  isModalOpen: false,
  getPedidos: () => {},
  resetStore: () => {},
  getPedidosDealer: () => {},
  closeModal: () => {},
};

export default AcompanhamentoPage;
