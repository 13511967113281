import React from "react";

const FidcIcon = () => (
	<svg width="18" height="14" viewBox="0 0 18 14" fill="none">
		<rect x="0.75" y="0.75" width="16.5" height="12.5" rx="1.25" stroke="white" strokeWidth="1.5"/>
		<path d="M5.12 6.51111C4.212 6.24889 3.92 5.97778 3.92 5.55556C3.92 5.07111 4.324 4.73333 5 4.73333C5.568 4.73333 5.852 4.97333 5.956 5.35556C6.004 5.53333 6.136 5.66667 6.304 5.66667H6.424C6.688 5.66667 6.876 5.37778 6.784 5.10222C6.616 4.57778 6.224 4.14222 5.6 3.97333V3.66667C5.6 3.29778 5.332 3 5 3C4.668 3 4.4 3.29778 4.4 3.66667V3.96C3.624 4.14667 3 4.70667 3 5.56444C3 6.59111 3.764 7.10222 4.88 7.4C5.88 7.66667 6.08 8.05778 6.08 8.47111C6.08 8.77778 5.884 9.26667 5 9.26667C4.34 9.26667 4 9.00444 3.868 8.63111C3.808 8.45778 3.672 8.33333 3.508 8.33333H3.396C3.128 8.33333 2.94 8.63556 3.04 8.91111C3.268 9.52889 3.8 9.89333 4.4 10.0356V10.3333C4.4 10.7022 4.668 11 5 11C5.332 11 5.6 10.7022 5.6 10.3333V10.0444C6.38 9.88 7 9.37778 7 8.46667C7 7.20444 6.028 6.77333 5.12 6.51111Z" fill="white"/>
		<rect x="9" y="5" width="9" height="4" rx="1" fill="white"/>
	</svg>
);

export default FidcIcon;
