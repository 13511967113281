import styled from 'styled-components';
import colors from 'assets/styles/colors';

const CardCondicoes = styled.div`
  .condicoes-footer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 12px;
    background: #FFFFFF;
    font-family: 'CircularStd', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 24px;
    color: ${colors.secundary_color_700};
    border: ${(props) => (props.showAlert ? `1px solid ${colors.alert_color_300}` : 'none')};
    border-radius: 4px;

    .text-footer{
      margin-left: 5px;
      margin-right: 10px;
    }
  }
  .MuiFormControlLabel-root{
    margin: 0;
    padding: 0;
  }
  #details__condicao {
    width: 100%;
    #details__summary-condicao::-webkit-details-marker {
        display: none
        }
    #details__summary-condicao {
      cursor: pointer;
      display: flex;
      align-items: center;
      flex-direction: row;
      border-bottom: 1px solid ${colors.primary_color_100};
      font-weight: 400;
      padding: 4px 10px;
      height: 30px;
      border-radius: 3px;
      color: ${colors.primary_color_800};
      background: ${colors.primary_color_100_36};
      &:after {
        content: "";
        color: black;
        width: 12px;
        height: 7px;
        background-repeat: no-repeat;
        background-image: url('../../../../../../../assets/icons/drop-down.svg');
        transform: rotate(180deg);
        align-self: center;
      }
    }
  }

  #details__condicao[open] #details__summary-condicao:after{
    transform: rotate(360deg);
  }

  #details__condicao[open] {
    background-color: #FFFFFF;
    border-radius: 4px;
  }

  .condicao-container{
    background: #FFF;
    max-height: 208px;
    overflow: auto;
    .condicao-text{
      padding: 12px 16px 12px 16px;
      font-family: 'CircularStd';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: ${colors.secundary_color_700};
    }
  }

  .condicao-title{
    font-size: 16px;
    color: ${(props) => (props.showTooltip && colors.alert_color_400)};
  }

  .circle-alert{
    margin-left: 4px;
    cursor: pointer;
}
`;

export default CardCondicoes;
