import { React } from 'react';
import PropTypes from 'prop-types';

import { Box, Stack } from '@mui/material';
import ReactQuill from 'react-quill';

import SendButton from './sendButton/sendButton';
import MultiplosAnexos from '../multiplosAnexos/multiplosAnexos';
import {
  formats, InputContent, InputFooter, modules,
} from './InputContainer.style';

const InputContainer = ({
  observacao, setObservacao, inputMaxLines, error, isSubmitting,
  uploadDocumento, downloadDocumento, deleteDocumento, documentos,
}) => (
  <Stack>
    <InputContent $error={error} $inputMaxLines={inputMaxLines}>
      <ReactQuill
        value={observacao}
        onChange={setObservacao}
        modules={modules}
        formats={formats}
        readOnly={isSubmitting}
        placeholder="Escreva uma observação ou adicione um arquivo"
      />
    </InputContent>
    <InputFooter width="100%" $error={error}>
      <Box display="flex" justifyContent="space-between" width="100%">
        <MultiplosAnexos
          uploadDocumento={uploadDocumento}
          downloadDocumento={downloadDocumento}
          deleteDocumento={deleteDocumento}
          documentos={documentos}
          error={error}
        />
        <SendButton loading={isSubmitting} />
      </Box>
    </InputFooter>
  </Stack>
);

InputContainer.propTypes = {
  inputMaxLines: PropTypes.number,
  observacao: PropTypes.string,
  setObservacao: PropTypes.func,
  error: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  uploadDocumento: PropTypes.func,
  downloadDocumento: PropTypes.func,
  deleteDocumento: PropTypes.func,
  documentos: PropTypes.array,
};

InputContainer.defaultProps = {
  inputMaxLines: 9,
  observacao: '',
  setObservacao: () => {},
  error: false,
  isSubmitting: false,
  uploadDocumento: () => {},
  downloadDocumento: () => {},
  deleteDocumento: () => {},
  documentos: [],
};

export default InputContainer;
