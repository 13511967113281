
import React from 'react';
import PropTypes from 'prop-types';

function VeiculoVendidoIcon({ color }) {
  return (
    <svg width="100%" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M23 0H3C1.6125 0 0.5125 1.1125 0.5125 2.5L0.5 17.5C0.5 18.8875 1.6125 20 3 20H23C24.3875 20 25.5 18.8875 25.5 17.5V2.5C25.5 1.1125 24.3875 0 23 0ZM23 16.25C23 16.9375 22.4375 17.5 21.75 17.5H4.25C3.5625 17.5 3 16.9375 3 16.25V3.75C3 3.0625 3.5625 2.5 4.25 2.5H21.75C22.4375 2.5 23 3.0625 23 3.75V16.25ZM16.75 6.25C16.75 6.9375 16.1875 7.5 15.5 7.5H11.75V8.75H15.5C16.1875 8.75 16.75 9.3125 16.75 10V13.75C16.75 14.4375 16.1875 15 15.5 15H14.25C14.25 15.6875 13.6875 16.25 13 16.25C12.3125 16.25 11.75 15.6875 11.75 15H10.5C9.8125 15 9.25 14.4375 9.25 13.75C9.25 13.0625 9.8125 12.5 10.5 12.5H14.25V11.25H10.5C9.8125 11.25 9.25 10.6875 9.25 10V6.25C9.25 5.5625 9.8125 5 10.5 5H11.75V4.9875C11.75 4.3 12.3125 3.7375 13 3.7375C13.6875 3.7375 14.25 4.3 14.25 4.9875V5H15.5C16.1875 5 16.75 5.5625 16.75 6.25Z" fill={color}/>
    </svg>
  );
}

VeiculoVendidoIcon.propTypes = {
  color: PropTypes.string,
};
  
VeiculoVendidoIcon.defaultProps = {
  color: "#555770",
};

export default VeiculoVendidoIcon;
