import logger from 'utils/logger';

import actions from './actions';
import service from './service';

import * as PageOperations from '../../acompanhamentoList/redux/operations';

export const openModal = (id) => async (dispatch) => {
  try {
    dispatch(actions.setOpen(true));
    dispatch(actions.getDetalhePedidoStart());

    const detalhePedido = await service.getDetalhePedido(id);

    dispatch(actions.getDetalhePedidoSuccess(detalhePedido));
  } catch (e) {
    logger.error(e);
    dispatch(actions.getDetalhePedidoError());
  }
};

export const closeModal = () => (dispatch) => {
  dispatch(actions.setOpen(false));
};

const cancelPedido = () => async (dispatch, getState) => {
  try {
    dispatch(actions.cancelPedidoStart());

    const {
      pedidoId, status,
    } = getState().testDrive.acompanhamento.detalhe.modal.detalhePedido;
    await service.cancelPedido(pedidoId, status);

    dispatch(actions.cancelPedidoSuccess());
    dispatch(PageOperations.getPedidos());
    dispatch(openModal(pedidoId));
  } catch (e) {
    logger.error(e);
    dispatch(actions.cancelPedidoError());
  }
};

export default {
  openModal,
  closeModal,
  cancelPedido,
};
