import actions from './actions';

const INITIAL_STATE = {
  isOpen: false,
  documento: false,
  requestStatus: {
    isLoading: false,
    isError: false,
  },
};

export default (state = INITIAL_STATE, action = { type: '@@comunicadosModal/INIT' }) => {
  switch (action.type) {
    case actions.types.SET_MODAL_OPEN:
      return {
        ...state,
        isOpen: action.payload.isOpen,
      };
    case actions.types.SET_DOCUMENTO:
      return {
        ...state,
        documento: action.payload.documento,
      };
    case actions.types.RESET_STORE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
