import { connect } from 'react-redux';

import Filters from './filters';

import operations from '../../redux/operations';

const mapStateToProps = ({ statementStore }) => ({
  textValue: statementStore.filters?.text,
  startDate: statementStore.filters?.startDate,
  endDate: statementStore.filters?.endDate,
  isLoading: statementStore.requestStatus?.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setTextFilter: (text) => dispatch(operations.setFilter('text', text)),
  setStartDate: (startDate) => dispatch(operations.setFilter('startDate', startDate)),
  setEndDate: (endDate) => dispatch(operations.setFilter('endDate', endDate)),
  getMovimentacoes: () => dispatch(operations.getMovimentacoes()),
  setFilterType: (type) => dispatch(operations.setFilterDateType(type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
