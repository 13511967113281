import React from 'react';
import PropTypes from 'prop-types';

import { ProdutoIcon } from '../../../common';

import './logo.scss';

const defineClassOperacao = (tipoOperacao) => {
  if (tipoOperacao.toUpperCase() === 'PAGAMENTO_EXTERNO'
|| tipoOperacao.toUpperCase() === 'PAGAMENTO_INTERNO') {
    return 'debito';
  }
  return 'credito';
};

const defineClassStatus = (status) => {
  if (status.toLowerCase() === 'pendente') {
    return 'borda_pendencia';
  }
  return null;
};

const defineClass = (isModal) => {
  if (isModal) {
    return 'statement_modal__logo';
  }
  return 'statement_page__logo';
};

const Logo = ({
  status, tipoOperacao, produto, isModal,
}) => (
  <div
    className={`${defineClass(isModal)} ${defineClassOperacao(tipoOperacao)} ${defineClassStatus(status)}`}
  >
    <ProdutoIcon
      product={produto}
      className="produto-icon"
      status={defineClassOperacao(tipoOperacao)}
    />
  </div>
);

Logo.propTypes = {
  status: PropTypes.string,
  tipoOperacao: PropTypes.string,
  produto: PropTypes.string,
  isModal: PropTypes.bool,
};

Logo.defaultProps = {
  status: '',
  tipoOperacao: '',
  produto: '',
  isModal: false,
};

export default Logo;
