/* eslint-disable max-len */
export const getTotalRemainingValue = (invoices) => {
  const soma = (invoices || []).reduce((sum, invoice) => (sum + invoice.remainingValue), 0).toFixed(2);
  return parseFloat(soma);
};

export const getTotalEditedValue = (invoices) => {
  const soma = (invoices || []).reduce((sum, invoice) => (sum + invoice.selectedForPaymentValue), 0).toFixed(2);
  return parseFloat(soma);
};
