import { connect } from 'react-redux';

import * as pageOperations from '../redux/operations';
import * as acompanhamentoOperations from './views/acompanhamentoList/redux/operations';
import * as acompanhementoDetalheOperations from './views/acompanhamentoDetalhe/redux/operations';

import AcompanhamentoPage from './acompanhamentoPage';

const mapStateToProps = ({ testDrive }) => ({
  isModalOpen: testDrive.acompanhamento.detalhe.isOpen,
});

const mapDispatchToProps = (dispatch) => ({
  getPedidos: () => dispatch(acompanhamentoOperations.getPedidos()),
  resetStore: () => dispatch(acompanhamentoOperations.resetStore()),
  getPedidosDealer: () => dispatch(acompanhamentoOperations.getDealerStatus()),
  closeModal: () => dispatch(acompanhementoDetalheOperations.closeModal()),
  navBack: () => dispatch(pageOperations.setPage(pageOperations.PAGE_PRODUTO)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AcompanhamentoPage);
