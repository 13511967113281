/* eslint-disable no-param-reassign */
import _ from 'lodash';
import logger from 'utils/logger';

export const applyProperty = (obj, propertyName, value) => {
  if (!_.isObject(obj)) return obj;

  if (!Object.prototype.hasOwnProperty.call(obj, propertyName)) {
    logger.error(`applyProperty: attempt to change property "${propertyName}", which does not exist in object`);
    return obj;
  }

  obj[propertyName] = value;

  return obj;
};

export const applyPropertyArray = (obj, propertyName, value) => {
  if (!_.isObject(obj)) return obj;

  if (!_.isArray(propertyName) || !_.isArray(value)) return obj;

  if (propertyName.length !== value.length) return obj;

  propertyName.forEach((property, index) => {
    obj[property] = value[index];
  });

  return obj;
};
