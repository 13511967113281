import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {
  MenuProps,
  SelectStyle,
  Placeholder,
  LabelContainer,
  SelectContainer,
  SpanColor,
} from './basicSelectStyle';

const BasicSelect = ({
  selectedOption,
  setOption,
  options,
  nameLabel,
  namePlaceholderFilter,
  namePlaceholder,
  isLoading,
  dataCy,
  tracking,
  renderAllOptions,
  required,
}) => {
  const renderPlaceHolder = (select) => {
    if (select !== null && select !== undefined && select !== '') return namePlaceholderFilter;
    return namePlaceholder;
  };

  const renderFirstOption = (value) => (
    <MenuItem
      value={value}
      sx={SelectStyle.menuItem}
      key={value}
    >
      {value}
    </MenuItem>
  );

  const renderItem = (value, index) => (
    <MenuItem
      value={value}
      sx={SelectStyle.menuItem}
      key={value}
      data-cy={dataCy ? `${dataCy}Item_${index}` : null}
    >
      {value}
    </MenuItem>
  );

  return (
    <SelectContainer>
      <LabelContainer>
        {required ? (
          <>
            {`${nameLabel} (obrigatório)`}
            <SpanColor color="red"> *</SpanColor>
          </>
        ) : (
          nameLabel
        )}
      </LabelContainer>
      <FormControl sx={SelectStyle.FormControl}>
        <Select
          value={selectedOption}
          onChange={(e) => {
            setOption(e.target.value);
            tracking();
          }}
          disabled={isLoading}
          renderValue={(value) => (
            <Placeholder color={value}>
              {renderPlaceHolder(value)}
            </Placeholder>
          )}
          label="selectedOption"
          MenuProps={MenuProps}
          IconComponent={KeyboardArrowDownIcon}
          sx={
            selectedOption
              ? SelectStyle.menuItemSelected
              : SelectStyle.selectOptions
          }
          displayEmpty
          inputProps={{ 'data-cy': dataCy }}
        >
          {renderAllOptions && renderFirstOption('Todos os valores')}
          {options.map((value, index) => renderItem(value, index))}
        </Select>
      </FormControl>
    </SelectContainer>
  );
};

BasicSelect.propTypes = {
  setOption: PropTypes.func,
  selectedOption: PropTypes.string,
  options: PropTypes.array,
  nameLabel: PropTypes.string.isRequired,
  namePlaceholderFilter: PropTypes.string,
  namePlaceholder: PropTypes.string,
  isLoading: PropTypes.bool,
  dataCy: PropTypes.string,
  tracking: PropTypes.func,
  renderAllOptions: PropTypes.bool,
  required: PropTypes.bool,
};

BasicSelect.defaultProps = {
  setOption: () => {},
  tracking: () => {},
  selectedOption: null,
  options: [],
  namePlaceholderFilter: null,
  namePlaceholder: null,
  isLoading: false,
  dataCy: null,
  renderAllOptions: true,
  required: false,
};

export default BasicSelect;
