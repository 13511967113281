import React from 'react';
import PropTypes from 'prop-types';

import {
  PageTitle, Breadcrumb, DatePicker, SearchInput,
} from '../../../common';
import { PaymentReducer } from '../../redux';

import './paymentListingHeader.scss';

const { INITIAL_PAGE } = PaymentReducer;

class PaymentListingHeader extends React.Component {
  handleOpenFieldFilter = (searchText) => {
    const {
      getInvoices, setFilterSearchText, startDate, endDate,
    } = this.props;
    setFilterSearchText(searchText);
    getInvoices(INITIAL_PAGE, startDate, endDate, searchText);
  };

  render() {
    const {
      className, isSearchActive, setSearchTextValue, searchTextValue,
      setStartDate, setEndDate, startDate, endDate,
    } = this.props;

    return (
      <div
        className={className}
      >
        <Breadcrumb />
        <div className="payment-header-listing-container">
          <PageTitle title="Pagar" className="payment-header-listing-title" />
          <div className="payment-header-listing-open-field-filter">
            <SearchInput
              isSearchActive={isSearchActive}
              onSearch={(search) => this.handleOpenFieldFilter(search)}
              onChange={setSearchTextValue}
              value={searchTextValue}
            />
          </div>
          <div className="payment-header-listing-date-filter">
            <DatePicker
              startDate={startDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
            />
          </div>
        </div>
      </div>

    );
  }
}

PaymentListingHeader.propTypes = {
  className: PropTypes.string,
  getInvoices: PropTypes.func.isRequired,
  setFilterSearchText: PropTypes.func.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  isSearchActive: PropTypes.bool.isRequired,
  searchTextValue: PropTypes.string,
  setSearchTextValue: PropTypes.func.isRequired,
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
};

PaymentListingHeader.defaultProps = {
  className: '',
  startDate: null,
  endDate: '',
  searchTextValue: '',
};

export default PaymentListingHeader;
