import * as React from "react";

const JeepIcon = (props) => {
	return (
		<svg focusable="false" aria-hidden="true" width="24" height="10" {...props}>
			<path d="M4.15385 0H6.11771C6.11771 0 6.1175 4.25953 6.1175 5.14412C6.11771 6.03149 5.73713 7.88582 3.01575 7.88582C0.295229 7.88582 0 6.15836 0 5.18861V4.2392H1.89883C1.89883 4.2392 1.89883 4.59625 1.89883 5.16743C1.89883 5.73649 2.2792 6.26404 2.99544 6.26404C3.71188 6.26404 4.15385 5.86334 4.15385 5.18861C4.15385 4.51303 4.15385 0 4.15385 0ZM9.15378 2.15169C10.6926 2.15169 12.0427 2.74192 12.0427 5.46245H8.39306C8.39306 6.3265 8.75288 6.68291 9.38397 6.68291C10.0181 6.68291 10.2298 6.17867 10.2298 6.17867H12.0224C12.0224 6.95974 10.9461 8.01269 9.32193 7.99236C7.46482 7.99236 6.5599 6.62108 6.5599 5.20894C6.5599 3.79381 7.36127 2.15169 9.15378 2.15169ZM10.2504 4.36585C10.2504 3.8383 9.95601 3.33129 9.27743 3.33129C8.62623 3.33129 8.39306 3.79381 8.39306 4.36585H10.2504ZM15.0148 2.15169C16.5566 2.15169 17.9037 2.74192 17.9037 5.46245H14.2571C14.2571 6.3265 14.6133 6.68291 15.2472 6.68291C15.8813 6.68291 16.0909 6.17867 16.0909 6.17867H17.8834C17.8834 6.95974 16.8071 8.01269 15.1854 7.99236C13.328 7.99236 12.4201 6.62108 12.4201 5.20894C12.4201 3.79381 13.2223 2.15169 15.0148 2.15169ZM16.1112 4.36585C16.1112 3.8383 15.8162 3.33129 15.1415 3.33129C14.4873 3.33129 14.2569 3.79381 14.2569 4.36585H16.1112ZM18.2425 9.63662V2.36155H20.0554C20.0554 2.36155 20.0552 2.46724 20.0552 2.65593C20.0554 3.09811 20.2883 2.8476 20.3089 2.78257C20.3301 2.72074 20.7722 2.21352 21.6802 2.21352C22.5881 2.21352 24 2.8476 24 5.08208C24.0002 6.80956 23.1571 8.07751 21.7005 8.07751C20.8991 8.07751 20.4355 7.76197 20.3301 7.63532C20.2237 7.50845 20.0763 7.48514 20.0763 7.63532C20.0763 7.77929 20.0763 9.63683 20.0763 9.63683L18.2425 9.63662ZM20.0351 5.14391C20.0147 6.22018 20.2438 6.78923 21.1314 6.78923C22.016 6.78923 22.1427 5.8195 22.1427 5.08208C22.1427 4.34552 21.9745 3.62566 21.0461 3.62566C20.4772 3.62566 20.0552 4.07084 20.0351 5.14391Z" fill="white">
			</path>
		</svg>
	);
}

export default JeepIcon;
