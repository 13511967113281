import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

import './button.scss';

const Button = ({
  className, onClick, white, disabled, children, isLoading, light, dataCy,
}) => (
  <button
    className={
      `fidis-btn 
      ${white ? 'fidis-btn-white' : ''}
      ${light ? 'fidis-btn-light' : ''}
      ${className}`
    }
    type="button"
    disabled={disabled || isLoading}
    data-cy={dataCy}
    onClick={onClick}
  >
    {isLoading
      ? <CircularProgress className="fidis-btn-loading" color="inherit" size="20px" />
      : <span>{children}</span>}
  </button>
);

Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  white: PropTypes.bool,
  light: PropTypes.bool,
  dataCy: PropTypes.string,
};

Button.defaultProps = {
  className: '',
  onClick: null,
  children: null,
  disabled: false,
  white: false,
  isLoading: false,
  light: false,
  dataCy: null,
};

export default Button;
