import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';

import { Tracking } from '../../../../modules';
import {
  PAYMENT_STEP_LIST, PAYMENT_STEP_EDIT, PAYMENT_STEP_RECEIPT,
} from '../../redux/paymentActions';
import { Button } from '../../../common';

import PaymentSummary from '../paymentSummary';
import PaymentConfirmationList from '../paymentConfirmationList';
import PaymentConfirmationModal from '../paymentConfirmationModal';

import './paymentConfirmation.scss';

class PaymentConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalErrorOpen: false,
    };
  }

  componentDidMount() {
    const {
      getBalance,
    } = this.props;
    getBalance();
  }

  hasEditedInvoices = (invoices) => (
    invoices.some((invoice) => (
      invoice.remainingValue !== invoice.selectedForPaymentValue
    ))
  );

  handleBackClicked = () => {
    const { invoices, setPaymentStep } = this.props;

    Tracking.Mixpanel.trackPaymentConfirmBackClicked();
    setPaymentStep(
      this.hasEditedInvoices(invoices) ? PAYMENT_STEP_EDIT : PAYMENT_STEP_LIST,
    );
  };

  TrackPayment = () => {
    const {
      invoices, totalValue, remainingBalance,
    } = this.props;

    Tracking.Mixpanel.trackPaymentConfirmPayClicked(
      invoices.length,
      totalValue,
      this.hasEditedInvoices(invoices),
      remainingBalance,
    );
  };

  onClickRefresh = () => {
    const { resetPaymentStore } = this.props;
    this.setState({ isModalErrorOpen: false });

    resetPaymentStore();
  };

  onClickErrorClose = () => {
    this.setState({ isModalErrorOpen: false });
  };

  submitPaymentCallback = (isSuccess) => {
    const { setPaymentStep } = this.props;

    if (isSuccess) {
      setPaymentStep(PAYMENT_STEP_RECEIPT);
    } else {
      this.setState(
        { isModalErrorOpen: true },
      );
    }
  };

  handleConfirmClicked = () => {
    const { submitPayment, invoices, user } = this.props;

    submitPayment(
      invoices,
      user,
      () => this.submitPaymentCallback(true),
      () => this.submitPaymentCallback(false),
    );
    this.TrackPayment();
  };

  render() {
    const {
      className, invoices, t, totalValue, paymentValue, isSubmitting, user,
    } = this.props;
    const { isModalErrorOpen } = this.state;

    return (
      <div className="payment-content-no-header">
        <div className={`payment-confirmation-comp ${className}`}>
          <PaymentSummary
            className="confirmation-summary"
            totalValue={totalValue}
            paymentValue={paymentValue}
            invoiceCount={invoices.length}
            userName={user.name || ''}
            paymentDate={moment().format()}
          />

          <PaymentConfirmationList
            className="confirmation-list"
            invoices={invoices}
          />

          <div className="confirmation-footer">
            <Button
              className="confirmation-btn-back"
              white={!isSubmitting}
              disabled={isSubmitting}
              onClick={this.handleBackClicked}
            >
              {t('Voltar')}
            </Button>

            <Button
              className="confirmation-btn-confirm"
              isLoading={isSubmitting}
              onClick={this.handleConfirmClicked}
            >
              {t('Confirmar pagamento')}
            </Button>
          </div>

          {isModalErrorOpen
            ? (
              <PaymentConfirmationModal
                open={isModalErrorOpen}
                onCloseClick={this.onClickErrorClose}
                onClickRefresh={this.onClickRefresh}
                type="error"
              />
            )
            : null}
        </div>
      </div>
    );
  }
}

PaymentConfirmation.propTypes = {
  className: PropTypes.string,
  t: PropTypes.func,

  invoices: PropTypes.array,
  totalValue: PropTypes.number.isRequired,
  paymentValue: PropTypes.number.isRequired,
  remainingBalance: PropTypes.number.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,

  setPaymentStep: PropTypes.func,
  getBalance: PropTypes.func.isRequired,
  submitPayment: PropTypes.func.isRequired,
  resetPaymentStore: PropTypes.func.isRequired,
};

PaymentConfirmation.defaultProps = {
  className: '',
  invoices: [],
  setPaymentStep: null,
  t: (i) => i,
};

export default withNamespaces('payment')(PaymentConfirmation);
