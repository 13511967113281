import { connect } from 'react-redux';

import * as pageOperations from '../../../redux/operations';

import AcompanhamentoButton from './acompanhamentoButton';

const mapDispatchToProps = (dispatch) => ({
  navToAcompanhamento: () => dispatch(pageOperations.setPage(pageOperations.PAGE_ACOMPANHAMENTO)),
});

export default connect(null, mapDispatchToProps)(AcompanhamentoButton);
