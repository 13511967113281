import { connect } from 'react-redux';

import GraficoBrands from './graficoBrands';
import selector from '../../redux/selectors';

const mapStateToProps = ({ dashboard }) => ({
  isLoading: dashboard.painelFloorPlan.carteiraBrand.isLoading,
  isError: dashboard.painelFloorPlan.carteiraBrand.isError,
  isEmpty: selector.isEmptyCarteiraBrand(dashboard),
});

export default connect(mapStateToProps, null)(GraficoBrands);
