import React from 'react';
import PropTypes from 'prop-types';

import DuplicataCurrency from '../duplicataCurrency';
import './duplicataValue.scss';

export const STATUS_PAID = 'paid';
export const STATUS_DELAYED = 'delayed';
export const STATUS_OPEN = 'opened';

export const getMainValue = (totalValue, remainingValue, processingValue, status) => {
  if (status === STATUS_OPEN || status === STATUS_DELAYED) {
    if (remainingValue === 0) {
      return processingValue;
    }

    return remainingValue;
  }

  return totalValue;
};

export const getSecondaryValue = (totalValue, processingValue, remainingValue) => {
  if (processingValue > 0 && remainingValue === 0) {
    return null;
  }

  if (processingValue <= 0 || processingValue >= totalValue) {
    return null;
  }

  return processingValue;
};

const DuplicataValue = (
  {
    status, processingValue, remainingValue, totalValue, className,
  },
) => {
  const secondaryValue = getSecondaryValue(totalValue, processingValue, remainingValue);

  return (
    <div className={`duplicataValue__container ${className}`}>
      <div className="duplicataValue__value-main">
        <DuplicataCurrency
          red={status === STATUS_DELAYED}
          clock={processingValue > 0 && remainingValue === 0}
          lineThrough={status === STATUS_PAID}
          value={getMainValue(totalValue, remainingValue, processingValue, status)}
        />
      </div>

      {
        (secondaryValue !== null && secondaryValue !== undefined) ? (
          <DuplicataCurrency
            className="duplicataValue__value-secondary"
            clock
            value={secondaryValue}
          />
        ) : null
      }
    </div>
  );
};

DuplicataValue.propTypes = {
  status: PropTypes.oneOf(['canceled', 'duplicated', STATUS_PAID, STATUS_OPEN, STATUS_DELAYED]).isRequired,
  totalValue: PropTypes.number,
  remainingValue: PropTypes.number,
  processingValue: PropTypes.number,
  className: PropTypes.string,
};

DuplicataValue.defaultProps = {
  className: '',
  totalValue: 0,
  remainingValue: 0,
  processingValue: 0,
};

export default DuplicataValue;
