import React from 'react';
import PropTypes from 'prop-types';
import { Tracking } from 'modules';
import MultipleSelect from 'features/common-staff/controls/multipleSelect';

const BrandsSelect = ({
  brand, setBrand, brandList, isLoading,
}) => (
  <MultipleSelect
    label="Brand"
    value={brand || []}
    onSelect={(b) => {
      if (b.length > 0) {
        setBrand(b);
        Tracking.Mixpanel.centralComunicados.filtros.trackFiltrosBrand();
      }
    }}
    items={brandList}
    allItemsText="Todas as brands"
    isActive={false}
    disabled={isLoading}
  />
);

BrandsSelect.propTypes = {
  brand: PropTypes.number,
  setBrand: PropTypes.func,
  brandList: PropTypes.array,
  isLoading: PropTypes.bool,
};

BrandsSelect.defaultProps = {
  brand: null,
  setBrand: () => {},
  brandList: null,
  isLoading: false,
};

export default BrandsSelect;
