import { connect } from 'react-redux';

import FilterButton from 'features/common-staff/controls/buttonFilter';
import operations from '../../../redux/operations';
import selectors from '../../../redux/selectors';

const mapStateToProps = ({ dashboard }) => ({
  isFilterSelected: dashboard.painelFloorPlan.filters.isEnable
&& !selectors.thereIsASelectedDealer(dashboard),
  isLoading: dashboard?.painelFloorPlan?.filtersSelector?.produto?.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  onClick: () => {
    dispatch(operations.forceReload());
    dispatch(operations.disableFilterButton());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterButton);
