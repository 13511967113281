export const DateRange = {
  today: 'hoje',
  yesterday: 'ontem',
  week: 'semanal',
  month: 'mensal',
  all: 'total',
  custom: 'custom',
};

export const CardResumoType = {
  duplicatas: 'Duplicatas',
  inadimplencia: 'Inadimplencia',
  maiorAtraso: 'Maior atraso',
};

export const CardVencimentoType = {
  vencidas: 'VENCIDAS',
  actual: 'ACTUAL',
  dez_dias: 'DEZ_DIAS',
  vinte_dias: 'VINTE_DIAS',
};
