import { combineReducers } from 'redux';

import chatPopoverReducer from './reducer';
import errorFeedbackReducer from '../views/errorFeedback/redux/reducer';
import userFeedbackReducer from '../views/userFeedback/redux/reducer';

const reducer = combineReducers({
  chatPopover: chatPopoverReducer,
  errorFeedback: errorFeedbackReducer,
  testDrive: userFeedbackReducer,
});

export { reducer };
