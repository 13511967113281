import React from 'react';
import InputSap from './inputSap';
import SelectConcessionaria from './selectConcessionaria';
import FormSection from '../formSection';
import InputBrand from './inputBrand';
import './dadosConcessionaria.scss';

const DadosConcessionaria = () => (
  <FormSection title="Dados da Concessionária">
    <div className="dados-concessionario__container">
      <InputSap required />
      <InputBrand required />
    </div>
    <SelectConcessionaria />
  </FormSection>
);

export default DadosConcessionaria;
