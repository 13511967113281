import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import List, { ListContent } from '../../../common-staff/layout/list';
import ListHeader from './views/listHeader';
import ListRow from './views/listRow';

import './statementList.scss';

const StatementList = ({
  movimentacoes, isLoading, isError, order,
}) => (
  <div className="statement__list__container">
    <ListHeader
      order={order}
    />
    <List
      isLoading={isLoading}
      isError={isError}
      isEmpty={_.isEmpty(movimentacoes)}
    >
      <ListContent>
        {(Array.isArray(movimentacoes) ? movimentacoes : []).map((m, index) => (
          <ListRow movimentacao={m} key={index} index={index} />
        ))}
      </ListContent>

      <ListContent
        type="empty"
        data-cy="MovimentacoesListContentEmpty"
      >
        Nenhuma movimentação foi encontrada.
      </ListContent>

      <ListContent type="error">
        Ocorreu um erro ao carregar as movimentações.
      </ListContent>
    </List>
  </div>
);

StatementList.propTypes = {
  movimentacoes: PropTypes.array,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  order: PropTypes.string.isRequired,
};

StatementList.defaultProps = {
  movimentacoes: null,
  isLoading: false,
  isError: false,
};

export default StatementList;
