import React from 'react';
import PropTypes from 'prop-types';
import {
  PieChart,
  Tooltip,
  Pie,
  Cell,
  ResponsiveContainer,
} from 'recharts';
import colors from 'assets/styles/colors';
import CustomTooltip from '../tooltipChart/customTooltip';
// eslint-disable-next-line import/no-duplicates

const COLORS = [
  { color: colors.error_color_300, brand: 'Fiat' },
  { color: '#555770', brand: 'Jeep' },
  { color: colors.primary_color_600, brand: 'Ram' },
  { color: colors.primary_color_300, brand: 'Chrysler' },
  { color: colors.primary_color_200, brand: 'Dodge' },
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central" fontSize="12">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const ChartCarteiraBrand = ({ data }) => (

  <ResponsiveContainer
    width="100%"
    height={364}
  >
    <PieChart>
      <Pie
        data={data}
        labelLine={false}
        label={renderCustomizedLabel}
        innerRadius={68}
        outerRadius={120}
        fill="#8884d8"
        blendStroke
        dataKey="total"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS.filter((item) => item.brand.toLowerCase() === data[index].brand.toLowerCase())[0].color} />))}
      </Pie>
      <Tooltip content={<CustomTooltip />} />
    </PieChart>
  </ResponsiveContainer>

);

ChartCarteiraBrand.propTypes = {
  data: PropTypes.array,
};

ChartCarteiraBrand.defaultProps = {
  data: null,
};

export default ChartCarteiraBrand;
