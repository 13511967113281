import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import Checkbox from 'features/common/checkbox/checkbox';
import colors from 'assets/styles/colors';

import { formatDate } from 'utils/format';
import { Status, Sistemas } from '../../../redux/enum';

import Identificador from './identificador';
import Concessionaria from './concessionaria';
import Valor from './valor';

import './carteiraListRow.scss';

const useStyles = makeStyles({
  display: {
    display: (modal) => (modal.isModalOpen ? 'none' : 'inherit'),
  },
  container: {
    gridTemplateAreas: (modal) => {
      if (modal.infoModalOpen) return "' checkbox documento  concessionaria  vencimento '";
      if (modal.isModalOpen && !modal.infoModalOpen) return "' checkbox documento  vencimento  saldo '";
      return "' checkbox documento  concessionaria  vencimento  saldo  status '";
    },
    gridTemplateColumns: (modal) => {
      if (modal.infoModalOpen) return '2.5em 17em auto 8em';
      if (modal.isModalOpen && !modal.infoModalOpen) return '2.5em auto 8em 8em';
      return '2.5em 17em auto 9em 8em 8em';
    },
    backgroundColor: (modal) => {
      if (modal.infoSelected || modal.selected) return colors.primary_color_100_36;
      return null;
    },
    borderRadius: (modal) => {
      if (modal.infoSelected || modal.selected) return '4px';
      return null;
    },
  },
});

const defineStatus = (status) => {
  if (status.toLowerCase().trim() === 'quitada') return Status.paid;
  if (status.toLowerCase().trim() === 'em aberto') return Status.open;
  if (status.toLowerCase().trim() === 'em atraso') return Status.delayed;
  if (status.toLowerCase().trim() === 'cancelada') return Status.canceled;
  return status.trim();
};

const dataVencimentoExibicao = (invoice) => {
  if (invoice.dataVencimentoVenda == null) {
    return formatDate(invoice.dataVencimento, 'DD MMM YYYY');
  }
  const dataVencimento = new Date(invoice.dataVencimento);
  const dataVencimentoVenda = new Date(invoice.dataVencimentoVenda);
  return (dataVencimento < dataVencimentoVenda ? formatDate(invoice.dataVencimento, 'DD MMM YYYY')
    : formatDate(invoice.dataVencimentoVenda, 'DD MMM YYYY'));
};

const defineTitle = (invoice, sistema, temPermissaoPagamento) => {
  if (!temPermissaoPagamento) {
    return 'Você não tem permissão para realizar o pagamento desta duplicata. Consulte o usuário Master da sua concessionária.';
  }
  if (!invoice.selectable) {
    return 'Essa duplicata não está disponível para pagamento no HUB, apenas visualização';
  }
  if (sistema !== invoice.codigoSistema && sistema === Sistemas.chr) {
    return 'Essa duplicata não está disponivel para pagamento pois não pertence ao grupo CJDR';
  }
  if (sistema !== invoice.codigoSistema && sistema === Sistemas.pla) {
    return 'Essa duplicata não está disponivel para pagamento pois não pertence ao grupo FIAT';
  }
  return null;
};

const CarteiraListRow = ({
  onCheck, infoModalOpen, isModalOpen, sistema,
  invoice, setEnableModal, selectedInfo, temPermissaoPagamento,
}) => {
  const { selected } = invoice;
  const classes = useStyles({
    isModalOpen, infoModalOpen, selected, infoSelected: (selectedInfo === invoice.duplicataId),
  });

  return (
    <div className="carteira-page__list-row__container__outer">
      <div
        className={`carteira-page__list-row__container ${classes.container}`}
        onClick={() => setEnableModal(invoice)}
        role="row"
        tabIndex={0}
      >
        <div
          className="carteira-page__list-row__item carteira-page__list-row__checkbox"
          onClick={(event) => event.stopPropagation()}
          role="row"
          tabIndex={0}
          title={defineTitle(invoice, sistema, temPermissaoPagamento)}
          style={{ cursor: !invoice.selectable ? 'not-allowed' : 'inherit' }}
        >
          {(invoice.duplicataStatusDescricao.toLowerCase() !== 'quitada') ? (
            <Checkbox
              onChange={() => onCheck(invoice)}
              checked={selected ?? false}
              disabled={!invoice.selectable || invoice.codigoSistema !== sistema
                || !temPermissaoPagamento}
              padding="5px 0px 5px 0px"
              size="medium"
              color={colors.text_midnight_500}
              checkedColor={colors.text_midnight_500}
            />
          ) : null}
        </div>
        <div className="carteira-page__list-row__item carteira-page__list-row__identificador">
          <Identificador
            product={invoice.descricaoProduto}
            status={invoice.duplicataStatusDescricao}
            invoiceNumber={invoice.duplicataNumero}
            tipoDoc={invoice.tipoDocumento}
          />
        </div>
        <div
          className={
`carteira-page__list-row__item carteira-page__list-row__concessionaria ${infoModalOpen ? null : classes.display}`
}
        >
          <Concessionaria
            name={invoice.dealerNome.trim()}
            buc={invoice.dealerCorr}
          />
        </div>
        <div className="carteira-page__list-row__item carteira-page__list-row__vencimento">
          {dataVencimentoExibicao(invoice)}
        </div>
        <div
          className="carteira-page__list-row__item carteira-page__list-row__saldo"
          style={infoModalOpen ? { display: 'none' } : null}
        >
          <Valor
            valor={invoice.saldoDevedor}
            status={invoice.duplicataStatusDescricao}
          />
        </div>
        <div
          className={`carteira-page__list-row__item carteira-page__list-row__status ${classes.display}`}
          style={infoModalOpen ? { display: 'none' } : null}
        >
          {defineStatus(invoice.duplicataStatusDescricao)}
        </div>
      </div>
    </div>
  );
};

CarteiraListRow.propTypes = {
  onCheck: PropTypes.func,
  invoice: PropTypes.object,
  infoModalOpen: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  setEnableModal: PropTypes.func,
  selectedInfo: PropTypes.number,
  sistema: PropTypes.string,
  temPermissaoPagamento: PropTypes.bool,
};

CarteiraListRow.defaultProps = {
  onCheck: () => {},
  invoice: null,
  infoModalOpen: false,
  isModalOpen: false,
  setEnableModal: () => {},
  selectedInfo: null,
  sistema: null,
  temPermissaoPagamento: false,
};

export default CarteiraListRow;
