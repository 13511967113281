import { connect } from 'react-redux';

import carteiraListHeader from './carteiraListHeader';

import operations from '../../../redux/operations';
import operationsModal from '../../../../carteiraModal/redux/operations';
import selectorModal from '../../../../carteiraModal/redux/selector';

const mapStateToProps = ({ gestaoPagamentos, authStore }) => ({
  infoModalOpen: gestaoPagamentos.carteira.modal.modalInfo.isOpen,
  isModalOpen: selectorModal.isModalPaymentOpen(gestaoPagamentos),
  selectedAll: gestaoPagamentos.carteira.page.selectAll,
  isLoading: gestaoPagamentos.carteira.page.requestStatus.isLoading,
  isError: gestaoPagamentos.carteira.page.requestStatus.isError,
  temPermissaoPagamento: authStore.permissions?.efetuarPagamentoB,
});

const mapDispatchToProps = (dispatch) => ({
  onCheck: (selectedAll) => {
    dispatch(operations.selectAll());
    dispatch(operationsModal.selectedAll(selectedAll));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(carteiraListHeader);
