import React from 'react';
import PropTypes from 'prop-types';

import CardResumoPagamento from './cardResumo';
import './detalhePagamento.scss';

const DetalhePagamento = ({ isLoading }) => (

  <div className="detalheMovimentacao__container">
    {!isLoading ? (
      <div className="detalheMovimentacao__modal__resumo_pagamento">
        <CardResumoPagamento />
      </div>
    ) : null }
  </div>
);

DetalhePagamento.propTypes = {
  isLoading: PropTypes.bool,
};

DetalhePagamento.defaultProps = {
  isLoading: null,
};

export default DetalhePagamento;
