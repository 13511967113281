import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';

import WarningImg from '../../../assets/imgs/warning.png';
import XIcon from '../../../assets/icons/x';
import './fakeInterfaceWarning.scss';

const styles = () => ({
  paper: { borderRadius: '11px' },
});

const FakeInterfaceWarning = (
  {
    className, classes, onClose, open,
  },
) => (
  <Dialog
    className={`feedback-dialog ${className}`}
    classes={classes}
    open={open}
    onClose={onClose}
  >
    <div id="feedback-dialog-close">
      <XIcon onClick={onClose} />
    </div>
    <div className="feedback-dialog-line">
      <img src={WarningImg} alt="imagem de aviso" />
    </div>

    <div className="feedback-dialog-line">
      <div>
        <p>Esta funcionalidade não</p>
        <p>está disponível no momento</p>
      </div>
    </div>
  </Dialog>
);

FakeInterfaceWarning.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

FakeInterfaceWarning.defaultProps = {
  className: '',
  open: false,
};

export default withStyles(styles)(FakeInterfaceWarning);
