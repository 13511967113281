import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'utils/format';
import { formatAsBRL } from 'features/carteiraFidc/helpers/format';
import Summary from 'features/common/summary';
import OnboardingPopper from 'features/common/onboardingPopper/onboardingPopper';
import { useOnboardingContext } from 'features/carteiraFidc/contexts/onboardingContext';
import { Tracking } from 'modules';
import { TextColor } from 'features/common/onboardingPopper/onboardingPopper.style';
import colors from 'assets/styles/colors';
import CardRow from './cardRow/cardRow';
import {
  Card, CardHeader, Title, Value,
} from './cardDetalheDuplicata.style';

const dateValue = (value) => (value ? formatDate(new Date(value), 'DD MMM YYYY') : '-');

const CardDetalheDuplicata = ({ duplicata, isOpen, closeDrawer }) => {
  const onboardingContext = useOnboardingContext();
  const { state: stateControle, updateControleUsuario } = onboardingContext;
  const historicoDuplicatas = stateControle.onboarding.HISTORICO_DUPLICATA;

  const [disableOnboarding, setDisableOnboarding] = useState(true);

  useEffect(() => {
    setDisableOnboarding(!isOpen || !(historicoDuplicatas?.active && historicoDuplicatas?.ready));
  }, [isOpen, stateControle]);

  return (
    <Card data-cy="CardDetalheDuplicataFidc">
      <CardHeader>
        <Title data-cy="NumeroDuplicataDrawer">
          {`Nº da Duplicata ${duplicata.numeroDuplicata}`}
        </Title>
        <Value data-cy="ValorAtualizadoDrawer">
          {formatAsBRL(duplicata.saldoAtualizado)}
        </Value>
      </CardHeader>
      <Summary title="Dados da duplicata" expanded>
        <CardRow label="Chassi" value={duplicata.chassi} />
        <CardRow label="Entrada FIDC" value={dateValue(duplicata?.entradaFidc)} />
        <CardRow label="Data da venda" value={dateValue(duplicata?.dataVenda)} />
        <CardRow label="Vencimento" value={dateValue(duplicata?.dataVencimentoProrrogada)} />
        <CardRow label="Status" value={duplicata?.dataPagamento ? 'Liquidada' : 'Em Aberto'} />
        <div style={{ height: '22px' }} />
        <CardRow label="Juros" value={formatAsBRL(duplicata.juros)} />
        <CardRow label="Multa" value={formatAsBRL(duplicata.multa)} />
        <CardRow label="Mora" value={formatAsBRL(duplicata.mora)} />
        <CardRow label="Valor principal" value={formatAsBRL(duplicata.valorPrincipal)} />
      </Summary>
      <OnboardingPopper
        placement="left"
        disabled={disableOnboarding}
        onClose={async () => {
          Tracking.Mixpanel.fidc.trackButtons('fidc_botao_onboarding_HISTORICO_DUPLICATA');
          updateControleUsuario();
          closeDrawer();
        }}
        title="Nova funcionalidade: Histórico de duplicatas"
        message={(
          <>
            <TextColor>Com um só clique, agora é </TextColor>
            <TextColor color={colors.primary_color_700} fontWeight="900">
              possível consultar a data de pagamento das suas duplicatas.
            </TextColor>
          </>
        )}
      >
        <Summary title="Histórico da duplicata" expanded={historicoDuplicatas?.active}>
          <CardRow label="Pagamento" value={dateValue(duplicata?.dataPagamento)} />
        </Summary>
      </OnboardingPopper>
    </Card>
  );
};

CardDetalheDuplicata.propTypes = {
  duplicata: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
};

export default CardDetalheDuplicata;
