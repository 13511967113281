import PropTypes from 'prop-types';

const RenderIfAuthorized = ({
  authorizations, requiredAuth, children,
}) => {
  // TODO: passar checagem de se tem permissão X para dentro do módulo auth
  // ex: Auth.checkPermission(permissions, requiredPerm)
  // ex2: state.authStore.hasPermissionX
  if (!Array.isArray(authorizations) || !requiredAuth) {
    return null;
  }

  if (authorizations.includes(requiredAuth)) {
    return (children);
  }

  return null;
};

RenderIfAuthorized.propTypes = {
  authorizations: PropTypes.array,
  requiredAuth: PropTypes.string,
  children: PropTypes.node,
};

RenderIfAuthorized.defaultProps = {
  authorizations: [],
  requiredAuth: null,
  children: null,
};

export default RenderIfAuthorized;
