import { connect } from 'react-redux';
import * as limiteCreditoOperations from '../../../redux/operations';

import InputMotivo from './inputMotivo';

const mapStateToProps = ({ limiteCreditoStore }) => ({
  motivo: limiteCreditoStore.alteracaoLimite.dados.motivo,
});

const mapDispatchToProps = (dispatch) => ({
  setMotivo: (motivo) => dispatch(
    limiteCreditoOperations.setMotivo(motivo),
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(InputMotivo);
