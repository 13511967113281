import axios from 'axios';

import { SaldoInfo } from './saldoInfo';

class SaldoService {
  constructor() {
    this.URL_API = window.env.REACT_APP_API_URL;
  }

  async getSaldoInfo() {
    const response = await axios.get(`${this.URL_API}/balance`);

    if (response.status !== 200 || !response.data) {
      throw new Error('Error getting saldo');
    }

    return new SaldoInfo(response.data);
  }
}

export default new SaldoService();
