import React from 'react';
import PropTypes from 'prop-types';

import ErrorIcon from '@material-ui/icons/Error';
import Avatar from '@material-ui/core/Avatar';

import { formatDate, camelFormat, formatCurrency } from 'utils/format';

import './resumoCard.scss';

const paymentsSameDealer = (dealerId, invoices) => {
  const filterPayment = invoices.filter((i) => i.dealerId === dealerId);
  return filterPayment.reduce((a, b) => a + b.toPay, 0);
};

const ResumoCard = ({
  dealerId, invoicesTotal, user,
  invoices, saldoRemanescente,
}) => {
  const sameDealer = paymentsSameDealer(dealerId, invoices);
  return (
    <div
      className="pagamento-page__resumo-card"
      data-cy="pagamento_resumo-card"
    >
      <div className="pagamento-page__resumo-card__header">
        Resumo da solicitação de pagamento
        <div className="pagamento-page__resumo-card__header__info">
          <div className="pagamento-page__resumo-card__row">
            <div className="pagamento-page__resumo-card__prop">
              Concessionária Pagante:
            </div>
            <div className="pagamento-page__resumo-card__value">
              {dealerId}
            </div>
          </div>
          <div className="pagamento-page__resumo-card__row">
            <div className="pagamento-page__resumo-card__prop">
              Duplicatas a serem pagas:
            </div>
            <div className="pagamento-page__resumo-card__value">
              {`${invoices.length} ${invoices.length > 1 ? 'duplicatas' : 'duplicata'}`}
            </div>
          </div>
          <div className="pagamento-page__resumo-card__row">
            <div className="pagamento-page__resumo-card__prop">
              Data do pagamento:
            </div>
            <div className="pagamento-page__resumo-card__value">
              {formatDate(new Date(), 'DD MMM YYYY')}
            </div>
          </div>
        </div>
      </div>
      <div className="pagamento-page__resumo-card__user">
        <div className="pagamento-page__resumo-card__avatar">
          <Avatar style={{ backgroundColor: '#0063F7' }}>
            {`${user.name.trim().split(' ')[0].substring(0, 1)
              .toUpperCase()}${user.name.trim().split(' ').at(-1).substring(0, 1)
              .toUpperCase()}`}
          </Avatar>
        </div>
        <div className="pagamento-page__resumo-card__user-name">
          {camelFormat(user.name.trim())}
          <span>{user.email.toLowerCase().trim()}</span>
        </div>
      </div>
      <div className="pagamento-page__resumo-card__info-dealers">
        <div className="pagamento-page__resumo-card__row">
          <div className="pagamento-page__resumo-card__prop">
            Pgto. para mesma concessionária:
          </div>
          <div className="pagamento-page__resumo-card__value">
            {formatCurrency(sameDealer)}
          </div>
        </div>
        <div className="pagamento-page__resumo-card__row">
          <div className="pagamento-page__resumo-card__prop">
            Pgto. para outras concessionárias:
          </div>
          <div className="pagamento-page__resumo-card__value">
            {formatCurrency(invoicesTotal - sameDealer)}
          </div>
        </div>
      </div>
      <div className="pagamento-page__resumo-card__footer">
        <div className="pagamento-page__resumo-card__row">
          <div className="pagamento-page__resumo-card__prop">
            Saldo Total:
          </div>
          <div className="pagamento-page__resumo-card__value">
            {formatCurrency(invoicesTotal)}
          </div>
        </div>
        <div className="pagamento-page__resumo-card__row">
          <div className="pagamento-page__resumo-card__prop">
            Saldo remanescente
            <div
              className={`pagamento-page__resumo-card__value ${saldoRemanescente - invoicesTotal < 0 ? 'error' : ''}`}
            >
              {saldoRemanescente - invoicesTotal < 0
                ? (
                  <ErrorIcon style={{
                    color: '#FFB600', marginLeft: '8px', width: 16, height: 16, marginRight: '2px',
                  }}
                  />
                )
                : null}
            </div>
            :
          </div>
          <div className="pagamento-page__resumo-card__value error">
            {formatCurrency(saldoRemanescente - invoicesTotal)}
          </div>
        </div>
        <div className="pagamento-page__resumo-card__row">
          <div className="pagamento-page__resumo-card__prop">
            <div className="pagamento-page__resumo-card__prop__total">
              Total:
            </div>
          </div>
          <div
            className={
`pagamento-page__resumo-card__value total ${(saldoRemanescente - invoicesTotal) < 0 ? 'negativo' : 'positivo'}`
}
          >
            {formatCurrency(saldoRemanescente - invoicesTotal)}
          </div>
        </div>
      </div>
    </div>

  );
};

ResumoCard.propTypes = {
  dealerId: PropTypes.number,
  invoicesTotal: PropTypes.number,
  user: PropTypes.object,
  invoices: PropTypes.array,
  saldoRemanescente: PropTypes.number,
};

ResumoCard.defaultProps = {
  dealerId: null,
  invoicesTotal: null,
  user: null,
  invoices: [],
  saldoRemanescente: null,
};

export default ResumoCard;
