import React from 'react';
import PropTypes from 'prop-types';
import TooltipMensagemStyle from './tooltipMensagem.styles';

const TooltipMensagem = ({
  title, children, maxWidth, placement,
}) => (
  <TooltipMensagemStyle maxWidth={maxWidth} title={title} arrow className="arrow" placement={placement}>
    {children}
  </TooltipMensagemStyle>
);

TooltipMensagem.propTypes = {
  children: PropTypes.element,
  title: PropTypes.string,
  maxWidth: PropTypes.string,
  placement: PropTypes.string,
};

TooltipMensagem.defaultProps = {
  children: {},
  title: '',
  maxWidth: '220px',
  placement: 'bottom',
};

export default TooltipMensagem;
