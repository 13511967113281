import React from "react";

const BarChartIcon = props => (
	<svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
		<path
			fill={props.fill ? `${props.fill}` : "currentColor"}
			fillRule="evenodd"
			d="M4.267 6.133H4.4c.513 0 .933.42.933.934v4.666c0 .514-.42.934-.933.934h-.133a.936.936 0 01-.934-.934V7.067c0-.514.42-.934.934-.934zM8 3.333c.513 0 .933.42.933.934v7.466c0 .514-.42.934-.933.934a.936.936 0 01-.933-.934V4.267c0-.514.42-.934.933-.934zm3.733 5.334c.514 0 .934.42.934.933v2.133c0 .514-.42.934-.934.934a.936.936 0 01-.933-.934V9.6c0-.513.42-.933.933-.933z"
		/>
	</svg>
);

export default BarChartIcon;

