import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';

import TextMessage from '../textMessage';
import OptionsMessage from '../optionsMessage';
import EmojisMessage from '../emojisMessage';

import './chat.scss';

const MessageList = ({ messageList }) => (messageList.map((message, i) => (
  <div key={`${message.type}_${i}`}>
    {(message.type === 'text') ? (
      <div className="chat__text-message">
        <TextMessage message={message} />
      </div>
    ) : null}

    {(message.type === 'select') ? (
      <div className="chat__choice-message">
        <OptionsMessage
          optionList={message.optionList}
          onClick={message.onClick || (() => {})}
          isAnswered={message.isAnswered}
        />
      </div>
    ) : null}

    {(message.type === 'emoji') ? (
      <div className="chat__emoji-message">
        <EmojisMessage
          value={message.value}
          onClick={message.onClick || (() => {})}
          isAnswered={message.isAnswered}
        />
      </div>
    ) : null}
  </div>
)));

class Chat extends React.Component {
  scrollbars = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      isChatMounted: false,
    };
  }

  componentDidMount() {
    this.setState({ isChatMounted: true });
  }

  componentDidUpdate() {
    const { isChatMounted } = this.state;
    if (isChatMounted && this.scrollbars.current) {
      this.scrollbars.current.scrollToBottom();
    }
  }

  componentWillUnmount() {
    this.setState({ isChatMounted: false });
  }

  render() {
    const { className, mensagens } = this.props;

    return (
      <div className={`chat__container ${className}`}>
        <Scrollbars
          ref={this.scrollbars}
          height="100%"
          renderThumbHorizontal={() => (<div style={{ display: 'none' }} />)}
        >
          <div className="chat__message-list">
            <MessageList messageList={mensagens} />
          </div>
        </Scrollbars>
      </div>
    );
  }
}

Chat.propTypes = {
  className: PropTypes.string,
  mensagens: PropTypes.array,
};

Chat.defaultProps = {
  className: '',
  mensagens: [],
};

export default Chat;
