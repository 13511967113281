import axios from 'axios';

class HistoricoService {
  constructor() {
    this.URL_API = window.env.REACT_APP_API_URL;
  }

  async getHistorico(duplicataNumero, dealerId) {
    const params = new URLSearchParams();
    params.append('duplicataNumero', duplicataNumero);
    params.append('dealerId', dealerId);
    const response = await axios.get(`${this.URL_API}/transactions/movimentacoes-mainframe`, {
      params,
    });

    if (!response || response.status !== 200) {
      throw new Error('Error on get historico duplicata');
    }

    return response.data;
  }
}

export default new HistoricoService();
