import * as React from "react";

function TransferenciaIcon(props) {
	return (
		<svg focusable="false" viewBox="3 3 25 25" aria-hidden="true">
			<path d="M15.0688 8.59835C11.7602 8.65297 9.114 11.388 9.16861 14.6966C9.22323 18.0051 11.9583 20.6513 15.2668 20.5967C18.5754 20.5421 21.2216 17.8071 21.167 14.4985C21.1124 11.19 18.3773 8.54374 15.0688 8.59835ZM15.0304 13.8497L15.2804 13.8456C16.3143 13.8285 17.169 14.6555 17.1861 15.6894C17.1995 16.5033 16.6869 17.1988 15.9653 17.4698L15.9713 17.8347C15.9782 18.2487 15.6478 18.5902 15.2338 18.597C14.8199 18.6038 14.4784 18.2734 14.4715 17.8595L14.4674 17.6095L13.9675 17.6178C13.5535 17.6246 13.212 17.2942 13.2052 16.8802C13.1984 16.4663 13.5288 16.1248 13.9427 16.118L15.3175 16.0953C15.5245 16.0919 15.6897 15.9211 15.6863 15.7141C15.6829 15.5072 15.5121 15.342 15.3052 15.3454L15.0552 15.3495C14.0213 15.3666 13.1666 14.5396 13.1495 13.5057C13.1361 12.6918 13.6487 11.9963 14.3703 11.7253L14.3643 11.3604C14.3574 10.9464 14.6878 10.6049 15.1018 10.5981C15.5157 10.5912 15.8572 10.9217 15.8641 11.3356L15.8682 11.5856L16.3681 11.5773C16.7821 11.5705 17.1236 11.9009 17.1304 12.3148C17.1372 12.7288 16.8068 13.0703 16.3929 13.0771L15.0181 13.0998C14.8111 13.1032 14.6459 13.274 14.6493 13.4809C14.6527 13.6879 14.8235 13.8531 15.0304 13.8497Z" />
			<path d="M23.5226 21.4844C23.2243 21.1973 22.7492 21.2062 22.4621 21.5045C19.3512 24.7352 14.4936 25.2789 10.7847 23.1014L11.4333 22.4279C11.8952 21.9481 11.5477 21.1456 10.8788 21.1582L8.11242 21.2098C7.69813 21.2176 7.36866 21.5598 7.37648 21.9741L7.42946 24.7405C7.44199 25.4036 8.25369 25.7299 8.71911 25.2465L9.71169 24.2157C14.0401 26.9551 19.8539 26.3757 23.5426 22.5449C23.8298 22.2467 23.8208 21.7716 23.5226 21.4844Z" />
			<path d="M23.2724 4.9253L21.9251 5.91183C18.9214 3.59922 14.8238 2.93106 11.1863 4.34339C8.84465 5.2526 6.94923 6.87462 5.70261 9.03212C5.49561 9.3914 5.61891 9.84986 5.97726 10.0572C6.33784 10.2648 6.795 10.1409 7.00235 9.78256C8.07603 7.92285 9.71012 6.52566 11.7293 5.74168C14.7328 4.57548 18.0972 5.05421 20.6537 6.84212L19.9236 7.3766C19.3859 7.77097 19.5934 8.62044 20.2522 8.72295L23.1523 9.17171C23.3497 9.20231 23.5484 9.15307 23.7098 9.03568C23.869 8.91807 23.9767 8.74219 24.0073 8.54472L24.457 5.6443C24.5574 4.98848 23.8161 4.5297 23.2724 4.9253Z" />
		</svg>
	);
}

export default TransferenciaIcon;
