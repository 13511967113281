const PREFIX_GET_SOLICITACOES = 'acompanhamentoLimitePage/GET_SOLICITACOES';
const GET_SOLICITACOES_START = `${PREFIX_GET_SOLICITACOES}/START`;
const GET_SOLICITACOES_SUCCESS = `${PREFIX_GET_SOLICITACOES}/SUCCESS`;
const GET_SOLICITACOES_ERROR = `${PREFIX_GET_SOLICITACOES}/ERROR`;

const PREFIX_DETALHES = 'acompanhmentoLimitePage/DETALHES';
const SET_OPEN = `${PREFIX_DETALHES}/SET_OPEN`;

const PREFIX_GET_DETALHES = 'acompanhmentoLimitePage/GET_DETALHES';
const GET_DETALHES_START = `${PREFIX_GET_DETALHES}/GET_DETALHES_START`;
const GET_DETALHES_SUCCESS = `${PREFIX_GET_DETALHES}/GET_DETALHES_SUCCESS`;
const GET_DETALHES_ERROR = `${PREFIX_GET_DETALHES}/GET_DETALHES_ERROR`;

const SET_PAGE_PARAMS = 'acompanhamentoLimitePage/SET_PAGE_PARAMS';
const RESET_STORE = 'acompanhamentoLimitePage/RESET_STORE';

const getSolicitacoesStart = () => ({
  type: GET_SOLICITACOES_START,
});

const getSolicitacoesError = () => ({
  type: GET_SOLICITACOES_ERROR,
});

const getSolicitacoesSuccess = (solicitacoes, pageParams) => ({
  type: GET_SOLICITACOES_SUCCESS,
  payload: { pageParams, solicitacoes },
});

const getDetalhesStart = () => ({
  type: GET_DETALHES_START,
});

const getDetalhesSuccess = (solicitacao) => ({
  type: GET_DETALHES_SUCCESS,
  payload: { solicitacao },
});

const getDetalhesError = () => ({
  type: GET_DETALHES_ERROR,
});

const setPageParams = (paramName, value) => ({
  type: SET_PAGE_PARAMS,
  payload: { paramName, value },
});

const setOpen = (value) => ({
  type: SET_OPEN,
  payload: { value },
});

const resetStore = () => ({ type: RESET_STORE });

export default {
  types: {

    PREFIX_GET_SOLICITACOES,
    GET_SOLICITACOES_START,
    GET_SOLICITACOES_SUCCESS,
    GET_SOLICITACOES_ERROR,

    PREFIX_GET_DETALHES,
    GET_DETALHES_ERROR,
    GET_DETALHES_START,
    GET_DETALHES_SUCCESS,

    PREFIX_DETALHES,
    SET_OPEN,

    SET_PAGE_PARAMS,
    RESET_STORE,
  },

  getSolicitacoesError,
  getSolicitacoesStart,
  getSolicitacoesSuccess,

  getDetalhesError,
  getDetalhesStart,
  getDetalhesSuccess,

  setOpen,

  setPageParams,
  resetStore,
};
