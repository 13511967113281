import axios from 'axios';

class InvoiceService {
  constructor() {
    this.URL_API = window.env.REACT_APP_API_URL;
  }

  getCnpjRootArray = (dealerGroups) => {
    let cnpjRootString = '';
    (dealerGroups || []).slice(0, 50).forEach((e) => {
      cnpjRootString += `${e.cnpjRoot};`;
    });
    return cnpjRootString?.substring(0, cnpjRootString.length - 1);
  };

  async getNotifications(dealerGroups, lastGet) {
    return axios.get(`${this.URL_API}/notifications/`, {
      headers: {
        'fidis-cnpj-root-array': this.getCnpjRootArray(dealerGroups),
      },
      params: {
        lastGet,
      },
    });
  }

  async markNotificationsRead(notifications) {
    const response = await axios.post(
      `${this.URL_API}/notifications`,
      { notifications },
    );
    if (response.status !== 200) {
      throw new Error('Error submitting notifications');
    }
  }
}
export default new InvoiceService();
