import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles, Tooltip } from '@material-ui/core';
import Currency from '../../../../../../../common/currency';
import ClockIcon from '../../../../../../../../assets/icons/clock';
import './statementeValue.scss';

const useStyles = makeStyles(() => ({
  customTooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
}));

const StatementeValue = ({
  tipoOperacao, totalValue, status,
}) => {
  const classes = useStyles();
  return (
    <div
      className="statement-value__list"
      data-cy="StatementValue"
    >
      {status.toUpperCase() === 'PENDENTE'
        ? (
          <Tooltip
            title="Pagamento pendente de aprovação"
            classes={{ tooltip: classes.customTooltip }}
          >
            <span className="statement-value__icon">
              <ClockIcon />
            </span>
          </Tooltip>
        )
        : null}
      <Currency
        value={tipoOperacao.toUpperCase() === 'ESTORNO_TRANSACAO' ? totalValue : -totalValue}
        explicitSign
        noColor={status.toUpperCase() === 'PENDENTE'}
      />
    </div>
  );
};

StatementeValue.propTypes = {
  tipoOperacao: PropTypes.string,
  status: PropTypes.string,
  totalValue: PropTypes.number,
};

StatementeValue.defaultProps = {
  totalValue: 0,
  status: '',
  tipoOperacao: '',
};

export default StatementeValue;
