import { createSelector } from 'reselect';

const documentosCadastro = createSelector(
  (state) => state?.gerenciadorDocumentos,
  (gerenciadorDocumentos) => ({
    parcial: gerenciadorDocumentos?.some((item) => item?.nome),
    preenchido: gerenciadorDocumentos?.every((item) => item?.nome),
  }),
);

const documentosJuridico = createSelector(
  (state) => state?.listaDocumentosComplementares?.listaDados,
  (listaDados) => ({
    parcial: listaDados?.some(
      (pessoa) => pessoa?.documentoPendente.some(
        (documento) => documento?.documentoNome,
      ),
    ),
    preenchido: listaDados?.every(
      (pessoa) => pessoa?.documentoPendente.every(
        (documento) => documento?.documentoNome,
      ),
    ),
  }),
);

const documentosFormalizar = createSelector(
  (state) => state?.listaDocumentosFormalizar?.listaDados,
  (listaDados) => ({
    parcial: listaDados?.some(
      (documento) => (
        documento?.tipoEnvioDocumento?.some((tipo) => (tipo === 'anexo_do_dealer'
          ? documento?.listaArquivos?.some(
            (arquivo) => arquivo.tipo === 'dealer' && arquivo.nomeGuid !== '',
          )
          : false))
      ),
    ),
    preenchido: listaDados?.every(
      (documento) => (
        documento?.tipoEnvioDocumento?.every((tipo) => (tipo === 'anexo_do_dealer'
          ? documento?.listaArquivos?.some(
            (arquivo) => arquivo.tipo === 'dealer' && arquivo.nomeGuid !== '',
          )
          : true))
      ),
    ),
  }),
);

const pendenciaDocumentosFormalizar = createSelector(
  (state) => state?.listaDocumentosFormalizar?.listaDados,
  (listaDados) => listaDados?.some(
    (documento) => (documento?.tipoEnvioDocumento?.some((tipo) => tipo === 'anexo_do_dealer')
      && !documento?.listaArquivos?.some(
        (arquivo) => arquivo.tipo === 'dealer' && arquivo.nomeGuid !== '',
      )),
  ),
);

export default {
  documentosCadastro,
  documentosJuridico,
  documentosFormalizar,
  pendenciaDocumentosFormalizar,
};
