import React from 'react';
import PropTypes from 'prop-types';

import FormInput from 'features/common-staff/controls/input/formInput/';

const InputCodigoSap = ({
  codigoSap,
}) => (
  <FormInput
    type="text"
    label="Código SAP"
    value={codigoSap.toString()}
  />
);

InputCodigoSap.propTypes = {
  codigoSap: PropTypes.any,
};

InputCodigoSap.defaultProps = {
  codigoSap: '',
};

export default InputCodigoSap;
