import { connect } from 'react-redux';

import AcompanhamentoListRow from './acompanhamentoListRow';

import operations from '../../../redux/operations';

const mapStateToProps = ({ limiteCreditoStore }) => ({
  isOpenAside: limiteCreditoStore.acompanhamento.detalhes.isOpen,
});

const mapDispatchToProps = (dispatch) => ({
  getDetalhes: (id) => dispatch(operations.getDetalhes(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AcompanhamentoListRow);
