import { connect } from 'react-redux';

import { PaymentOperations, PaymentSelectors } from '../../redux';
import PaymentEditor from './paymentEditor';
import { operations as BalanceOperations } from '../../../balance/redux';

const mapDispatchToProps = (dispatch) => ({
  setPaymentStep: (step) => (
    dispatch(PaymentOperations.setPaymentStep(step))
  ),
  getBalance: () => dispatch(BalanceOperations.getBalanceInfo()),
});
const mapStateToProps = ({ paymentStore, balanceStore }) => ({
  invoices: paymentStore.selectedInvoices,
  totalValue: PaymentSelectors.getTotalEditedValue(paymentStore.selectedInvoices),
  remainingBalance: balanceStore.creditoRemanescente,
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentEditor);
