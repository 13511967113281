import { connect } from 'react-redux';
import * as operations from '../../../redux/operations';

import CancelarButton from './cancelarButton';

const mapStateToProps = ({ limiteCreditoStore }) => ({
  isLoading: limiteCreditoStore.transferenciaLimite.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(operations.closeModal()),
});
export default connect(mapStateToProps, mapDispatchToProps)(CancelarButton);
