import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@mui/material';
import { camelFormat } from 'utils/format';
import SummaryPage from 'features/common/summaryPage';
import UploadDocumentoJuridico from '../../uploadDocumentoJuridico';
import RadioGroupPessoa from '../../documentosCadastro/infoCadastroList/radioGroupPessoa/radioGroupPessoa';
import RenderIconStatus from '../../renderIconStatus/renderIconStatus';
import './infoDocumentosComplementares.scss';

const InfoDocumentosComplementares = ({
  pessoaDocumentacao, indexPessoa, statusLimite,
  uploadDocumento, downloadDocumento, deleteDocumento,
}) => {
  const renderItem = (text, item) => (
    <div className="modal-documentos-complementares__content__documentos__itens">
      <span className="modal-documentos-complementares__content__documentos__text-label">{text}</span>
      <div className="modal-documentos-complementares__content__documentos__text-info">{item}</div>
    </div>
  );

  const documentosIncompletos = pessoaDocumentacao?.documentoPendente?.some(
    (d) => !d.documentoNome,
  );

  return (
    <SummaryPage
      title={camelFormat(pessoaDocumentacao?.nome, 2)}
      IconTitle={<RenderIconStatus pendencia={documentosIncompletos} />}
      level={2}
    >
      <div className="modal-documentos-complementares__sections">
        <div className="modal-documentos-complementares__content__header">
          <RadioGroupPessoa
            inputData={pessoaDocumentacao?.tipoPessoa}
            disabled
          />
          {renderItem('CNPJ|CPF', pessoaDocumentacao?.documento)}
        </div>
        <Divider />
        {pessoaDocumentacao?.documentoPendente?.map((item, index) => (
          <>
            <div key={index} className="modal-documentos-complementares__content__documentos">
              {renderItem('Nome do documento', item.tipoDocumentoNome)}
              <UploadDocumentoJuridico
                documento={{
                  nome: item?.documentoNome,
                  tamanho: item?.documentoTamanho,
                }}
                indexPessoa={indexPessoa}
                indexDocumento={index}
                statusLimite={statusLimite}
                errorStatusList={['pendente_docs_juridico_dealer']}
                downloadDocumento={() => downloadDocumento(item?.documentoId)}
                deleteDocumento={() => deleteDocumento(item?.documentoId, indexPessoa, index)}
                uploadDocumento={(file) => uploadDocumento(
                  file,
                  item?.pessoaTipoDocumentoId,
                  indexPessoa,
                  index,
                )}
              />
            </div>
            <Divider />
          </>
        ))}
      </div>
    </SummaryPage>
  );
};

InfoDocumentosComplementares.propTypes = {
  pessoaDocumentacao: PropTypes.object,
  indexPessoa: PropTypes.number.isRequired,
  statusLimite: PropTypes.string.isRequired,
  downloadDocumento: PropTypes.func,
  uploadDocumento: PropTypes.func,
  deleteDocumento: PropTypes.func,
};

InfoDocumentosComplementares.defaultProps = {
  pessoaDocumentacao: {},
  downloadDocumento: () => {},
  uploadDocumento: () => {},
  deleteDocumento: () => {},
};

export default InfoDocumentosComplementares;
