import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import BotaoRedirecionamento from './botaoRedirecionamento';
import operations from '../../../redux/operations';
import { PATH_CARTEIRA } from '../../../../../../routes/paths';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setCarteiraFilters: (type) => dispatch(operations.setCarteiraFilters(type)),
  navTo: () => dispatch(push(PATH_CARTEIRA)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BotaoRedirecionamento);
