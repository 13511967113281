const PREFIX = 'statementPage';

const PREFIX_GET_MOVIMENTACAO = `${PREFIX}/GET_MOVIMENTACAO`;
const GET_MOVIMENTACOES_START = `${PREFIX_GET_MOVIMENTACAO}/GET_MOVIMENTACOES_START`;
const GET_MOVIMENTACOES_SUCCESS = `${PREFIX_GET_MOVIMENTACAO}/GET_MOVIMENTACOES_SUCCESS`;
const GET_MOVIMENTACOES_ERROR = `${PREFIX_GET_MOVIMENTACAO}/GET_MOVIMENTACOES_ERROR`;

const PREFIX_GET_DETALHE_MOVIMENTACAO = `${PREFIX}/GET_DETALHE_PEDIDO`;
const GET_DETALHE_MOVIMENTACAO_START = `${PREFIX_GET_DETALHE_MOVIMENTACAO}/START`;
const GET_DETALHE_MOVIMENTACAO_SUCCESS = `${PREFIX_GET_DETALHE_MOVIMENTACAO}/SUCCESS`;
const GET_DETALHE_MOVIMENTACAO_ERROR = `${PREFIX_GET_DETALHE_MOVIMENTACAO}/ERROR`;

const PREFIX_GET_FILE = `${PREFIX}/GET_FILE`;
const GET_FILE_START = `${PREFIX}/GET_FILE_START`;
const GET_FILE_SUCCESS = `${PREFIX}/GET_FILE_SUCCESS`;
const GET_FILE_ERROR = `${PREFIX}/GET_FILE_ERROR`;

const DISMISS_SNACKBAR = `${PREFIX}/DISMISS_SNACKBAR`;
const SET_FILTER = `${PREFIX}/SET_FILTER`;
const SET_PAGE_PARAMS = `${PREFIX}/SET_PAGE_PARAMS`;
const SET_MODAL_TIPO = `${PREFIX}/SET_MODAL_TIPO`;
const SET_MODAL_OPEN = `${PREFIX}/SET_MODAL_OPEN`;
const SET_MODAL_COMPROVANTE_OPEN = `${PREFIX}/SET_MODAL_COMPROVANTE_OPEN`;
const RESET_STORE = `${PREFIX}/RESET_STORE`;
const SET_FILTER_DATE_TYPE = `${PREFIX}/SET_FILTER_DATE_TYPE`;
const SET_FILTER_TRANSACTION_TYPE = `${PREFIX}/SET_FILTER_TRANSACTION_TYPE`;

const setFilter = (propertyName, value) => ({
  type: SET_FILTER,
  payload: { propertyName, value },
});

const setPageParams = (propertyName, value) => ({
  type: SET_PAGE_PARAMS,
  payload: { propertyName, value },
});

const setModalTipo = (tipo, status, produto, index, header) => ({
  type: SET_MODAL_TIPO,
  payload: {
    tipo, status, produto, index, header,
  },
});

const setModalOpen = (value, selectedId) => ({
  type: SET_MODAL_OPEN,
  payload: { value, selectedId },
});

const setModalComprovanteOpen = (value) => ({
  type: SET_MODAL_COMPROVANTE_OPEN,
  payload: { value },
});

const getMovimentacoesStart = () => ({
  type: GET_MOVIMENTACOES_START,
});

const getMovimentacoesSuccess = (movimentacoes, pageParams, isUpdated) => ({
  type: GET_MOVIMENTACOES_SUCCESS,
  payload: { movimentacoes, pageParams, isUpdated },
});

const getMovimentacoesError = () => ({
  type: GET_MOVIMENTACOES_ERROR,
});

const getDetalheMovimentacaoStart = () => ({
  type: GET_DETALHE_MOVIMENTACAO_START,
});

const getDetalheMovimentacaoSuccess = (detalhes) => ({
  type: GET_DETALHE_MOVIMENTACAO_SUCCESS,
  payload: { detalhes },
});

const getDetalheMovimentacaoError = () => ({
  type: GET_DETALHE_MOVIMENTACAO_ERROR,
});

const resetStore = () => ({
  type: RESET_STORE,
});

const setFilterDateType = (value) => ({
  type: SET_FILTER_DATE_TYPE,
  payload: { value },
});

const setFilterTransactionType = (value) => ({
  type: SET_FILTER_TRANSACTION_TYPE,
  payload: { value },
});

const getFileStart = () => ({
  type: GET_FILE_START,
});

const getFilerSuccess = () => ({
  type: GET_FILE_SUCCESS,
});

const getFileError = () => ({
  type: GET_FILE_ERROR,
});

const dismissSnackbar = (id) => ({
  type: DISMISS_SNACKBAR,
  payload: { id },
});

export default {
  types: {
    PREFIX,
    PREFIX_GET_MOVIMENTACAO,
    PREFIX_GET_DETALHE_MOVIMENTACAO,
    GET_MOVIMENTACOES_START,
    GET_MOVIMENTACOES_SUCCESS,
    GET_MOVIMENTACOES_ERROR,
    GET_DETALHE_MOVIMENTACAO_START,
    GET_DETALHE_MOVIMENTACAO_SUCCESS,
    GET_DETALHE_MOVIMENTACAO_ERROR,
    SET_FILTER,
    SET_FILTER_TRANSACTION_TYPE,
    SET_PAGE_PARAMS,
    SET_MODAL_TIPO,
    SET_MODAL_OPEN,
    SET_MODAL_COMPROVANTE_OPEN,
    RESET_STORE,
    SET_FILTER_DATE_TYPE,
    PREFIX_GET_FILE,
    GET_FILE_START,
    GET_FILE_ERROR,
    GET_FILE_SUCCESS,
    DISMISS_SNACKBAR,
  },

  setFilter,
  setPageParams,
  setModalOpen,
  setModalComprovanteOpen,
  setModalTipo,
  getMovimentacoesError,
  getMovimentacoesStart,
  getMovimentacoesSuccess,
  resetStore,
  setFilterDateType,
  setFilterTransactionType,
  getDetalheMovimentacaoStart,
  getDetalheMovimentacaoSuccess,
  getDetalheMovimentacaoError,
  getFileError,
  getFileStart,
  getFilerSuccess,
  dismissSnackbar,
};
