import React from 'react';
import PropTypes from 'prop-types';

import FormInput from 'features/common-staff/controls/input/formInput';

const InputValor = ({
  valor,
}) => (
  <FormInput
    label="Valor"
    type="currency"
    value={valor}
  />
);

InputValor.propTypes = {
  valor: PropTypes.string,
};

InputValor.defaultProps = {
  valor: '',
};

export default InputValor;
