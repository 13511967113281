import { connect } from 'react-redux';

import SignInPage from './signInPage';
import { AuthOperations } from '../../../../modules/auth/redux';

const mapStateToProps = ({ authStore }) => ({
  loginLoading: authStore.isLoading,
  isLoginError: authStore.isLoginError,
  sessionExpired: authStore.sessionExpired,
  loadingRedirect: authStore.isLoadingRedirect,
  errorMessage: authStore.errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  login: (email, password) => dispatch(AuthOperations.login(email, password)),
  loginRedirect: (key) => dispatch(AuthOperations.loginRedirect(key)),
  invalidateSession: () => dispatch(AuthOperations.invalidateSession()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignInPage);
