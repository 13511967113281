import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { ArrowLeft } from 'react-feather';

const useStyles = makeStyles({
  button: {
    width: ({ width }) => width,
    height: ({ height }) => height,
    padding: (props) => props.padding,

    cursor: 'pointer',
    outline: 'none',
    color: '#555770',
    borderRadius: '6px',

    '&:hover': {
      color: '#333',
      backgroundColor: '#eee',
    },
  },

  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  arrow: {
    padding: '7px 0 0 0',
  },

  text: {
    padding: '6px 4px 4px 6px',
  },
});

const BackButton = ({
  onClick, width, height, padding, children,
}) => {
  const classes = useStyles({
    width: width || 'auto',
    height: height || 40,
    padding: padding || '0 8px',
  });

  return (
    <div
      className={`${classes.button} ${classes.flex}`}
      onClick={onClick}
      role="button"
      tabIndex={0}
    >
      <div className={classes.flex}>
        <div className={classes.arrow}>
          <ArrowLeft size={20} />
        </div>
        <div className={classes.text}>
          {children}
        </div>
      </div>
    </div>
  );
};

BackButton.propTypes = {
  onClick: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  padding: PropTypes.string,
  children: PropTypes.node,
};

BackButton.defaultProps = {
  onClick: () => {},
  width: null,
  height: null,
  padding: null,
  children: null,
};

export default BackButton;
