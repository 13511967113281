import React from 'react';

import BreadcrumbBase from 'features/common-staff/layout/breadCrumb';

const Breadcrumb = () => (
  <BreadcrumbBase
    labels={[{
      label: 'Dashboard',
      path: '/dashboard',
    },
    {
      label: 'Painel Floor Plan',
      path: '',
    }]}
  />
);

export default Breadcrumb;
