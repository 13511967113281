/* eslint-disable */
import * as React from "react";

function MoneyIcon({color, selected, width, height }) {
	return (
		<svg width={width ? width : "24"} height={height ? height : "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.58 2.58L19.41 7.41C19.79 7.79 20 8.3 20 8.83V20C20 21.1 19.1 22 18 22H6C4.9 22 4 21.1 4 20V4C4 2.9 4.9 2 6 2H13.17C13.7 2 14.21 2.21 14.58 2.58ZM15 13C15 12.45 14.55 12 14 12H11V11H14C14.55 11 15 10.55 15 10C15 9.45 14.55 9 14 9H13C13 8.45 12.55 8 12 8C11.45 8 11 8.45 11 9H10C9.45 9 9 9.45 9 10V13C9 13.55 9.45 14 10 14H13V15H10C9.45 15 9 15.45 9 16C9 16.55 9.45 17 10 17H11C11 17.55 11.45 18 12 18C12.55 18 13 17.55 13 17H14C14.55 17 15 16.55 15 16V13Z" fill={selected? '#1B2961' : color}/>
    </svg>
	);
}

export default MoneyIcon;
