import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { formatPctg } from 'utils/format';

import { CardResumoType } from '../../redux/enum';
import CardCarteira from './cardCarteira';
import CardResumo from './cardResumo';
import CardBase from '../cardBase';

import './cardsResumo.scss';

const defaultCardMinHeight = '198px';

const CardsResumo = ({
  isLoadingResumo, isErrorResumo, data, dataForDonut, className,
}) => {
  const valorDuplicatas = data?.cards?.find(
    (i) => i.valorTitulo === CardResumoType.duplicatas,
  )?.valor;

  const valorInadimplencia = data?.cards?.find(
    (i) => i.valorTitulo === CardResumoType.inadimplencia,
  )?.valor;

  const valorMaiorAtraso = data?.cards?.find(
    (i) => i.valorTitulo === CardResumoType.maiorAtraso,
  ).valor;

  return (
    <div className={className}>
      <CardBase
        isLoading={isLoadingResumo}
        isError={isErrorResumo}
        isEmpty={_.isNull(data) || _.isUndefined(data) || data.length === 0}
      >
        <CardCarteira
          data={data}
          dataForDonut={dataForDonut}
          isLoading={isLoadingResumo}
          isError={isErrorResumo}
        />
      </CardBase>
      <CardBase
        isLoading={isLoadingResumo}
        isError={isErrorResumo}
        minHeight={defaultCardMinHeight}
        isEmpty={_.isNull(data) || _.isUndefined(data) || data.length === 0}
      >
        <CardResumo
          valor={valorDuplicatas}
          valorTitulo="Total de duplicatas"
          tipo="duplicatas"
          tooltipMessage="Quantidade total de duplicatas vencidas e a vencer."
        />
      </CardBase>
      <CardBase
        isLoading={isLoadingResumo}
        isError={isErrorResumo}
        isEmpty={_.isNull(data) || _.isUndefined(data) || data.length === 0}
      >
        <CardResumo
          valor={_.isNull(valorInadimplencia) || _.isUndefined(valorInadimplencia)
            ? formatPctg(0, 2) : formatPctg(valorInadimplencia)}
          valorTitulo="Inadimplência"
          tipo="inadimplencia"
          tooltipMessage="Porcentagem de inadimplência baseado nos valores vencidos."
          messenge={valorInadimplencia === 0 || valorInadimplencia === 0.00
            ? '*Arredondado para 0% pois a inadimplência é abaixo de 0,01%' : null}
        />
      </CardBase>
      <CardBase
        isLoading={isLoadingResumo}
        isError={isErrorResumo}
        isEmpty={_.isNull(data) || _.isUndefined(data) || data.length === 0}
      >
        <CardResumo
          valor={`${valorMaiorAtraso} ${valorMaiorAtraso === 1 ? 'dia' : 'dias'}`}
          valorTitulo="Maior atraso"
          tipo="maior_atraso"
          tooltipMessage="Maior atraso de uma duplicata em dias."
        />
      </CardBase>
    </div>
  );
};

CardsResumo.propTypes = {
  isLoadingResumo: PropTypes.bool,
  isErrorResumo: PropTypes.bool,
  data: PropTypes.object,
  dataForDonut: PropTypes.object,
  className: PropTypes.string,
};

CardsResumo.defaultProps = {
  isLoadingResumo: false,
  isErrorResumo: false,
  data: {},
  dataForDonut: {},
  className: '',
};

export default CardsResumo;
