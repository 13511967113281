import * as React from "react";

const UploudFileIcon = (props) => {
	return (
		<svg focusable="false" aria-hidden="true" width="24" height="30" {...props}>
			<path fillRule="evenodd" clipRule="evenodd" d="M14.6667 0.333374H4C1.79086 0.333374 0 2.12423 0 4.33337V25.6667C0 27.8758 1.79086 29.6667 4 29.6667H20C22.2091 29.6667 24 27.8758 24 25.6667V9.66671C24 9.61519 23.997 9.56397 23.9911 9.5133L23.991 9.51121C23.9568 9.21682 23.8267 8.95168 23.6328 8.74783C23.6252 8.73977 23.6174 8.73179 23.6095 8.7239L15.61 0.724429C15.3687 0.482831 15.0351 0.333374 14.6667 0.333374ZM13.3333 3.00004H4C3.26362 3.00004 2.66667 3.59699 2.66667 4.33337V25.6667C2.66667 26.4031 3.26362 27 4 27H20C20.7364 27 21.3333 26.4031 21.3333 25.6667V11H14.6667C13.9829 11 13.4193 10.4853 13.3423 9.8222L13.3333 9.66671V3.00004ZM19.4467 8.33337L16 4.88671V8.33337H19.4467ZM12 13.6667C12.6838 13.6667 13.2473 14.1814 13.3244 14.8445L13.3333 15V17.6667H16C16.7364 17.6667 17.3333 18.2637 17.3333 19C17.3333 19.6838 16.8186 20.2474 16.1555 20.3244L16 20.3334H13.3333V23C13.3333 23.7364 12.7364 24.3334 12 24.3334C11.3162 24.3334 10.7527 23.8187 10.6756 23.1555L10.6667 23V20.3334H8C7.26362 20.3334 6.66667 19.7364 6.66667 19C6.66667 18.3163 7.18139 17.7527 7.84451 17.6757L8 17.6667H10.6667V15C10.6667 14.2637 11.2636 13.6667 12 13.6667Z" fill="#555770"/>
		</svg>
	);
}

export default UploudFileIcon;

