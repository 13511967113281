import styled from 'styled-components';
import ButtonTooltipIcon from 'features/common/buttonTooltipIcon';
import colors from 'assets/styles/colors';

export const BalanceContainer = styled.div`
  display: grid;
  padding: 16px 70px 0px 22px;
`;

export const ResourcesContainer = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.gridTemplateColumn || ''};
  margin-top: ${(props) => props.top || '0px'}; 
  margin-bottom: ${(props) => props.bottom || '0px'}; 
`;

export const ListContainer = styled.div``;

export const NamesContainer = styled.span`
  font-style: normal;
  margin-top: 4px;
  font-weight: 450;
  font-size: 10px;
  line-height: 16px;
  color: #404154;
`;

export const Values = styled.span`
  display: flex !important;
  justify-content: end;
  font-style: normal;
  margin-top: 4px;
  font-size: 12px;
  line-height: 16px;
  color: #404154;
`;

export const ButtonTooltip = styled(ButtonTooltipIcon)`
  height: 16px;
`;

export const SkeletonContainer = styled.div`
  width: ${(props) => props.width || '202px'};
  height: ${(props) => props.height || ''};
  margin-top: ${(props) => props.marginTop || '0px'};
  margin-bottom: ${(props) => props.marginBottom || '0px'};
`;

export const UpdatedBalance = styled.span`
  margin-top: 6px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #555770;
`;

export const UpdateDate = styled.span`
  margin-top: 4px;
  font-weight: ${(props) => props.fontWeight || 450};
  font-size: 10px;
  line-height: 16px;
  color: #7A7C9A;
`;

export const Title = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: ${(props) => props.color || `${colors.primary_color_600}`};
`;

export const Line = styled.div`
  box-sizing: border-box;
  width: calc(100%);
  height: 1px;
  background-color: #e9eaf0;
`;

export default null;
