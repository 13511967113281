import { saveAs } from 'file-saver';
import logger from 'utils/logger';
// import JSZip from 'jszip';
import actions from './actions';
import Service from './service';

const setFilter = (value, paramName, activeButton = true) => (dispatch) => {
  dispatch(actions.setFilter(value, paramName, activeButton));
};

const resetStore = () => (dispatch) => {
  dispatch(actions.resetStore());
};

const selectAll = () => async (dispatch) => {
  dispatch(actions.selectAll());
};

const select = (documento) => async (dispatch) => {
  dispatch(actions.select(documento));
};

const dismissSnackbar = (id) => async (dispatch) => {
  dispatch(actions.dismissSnackbars(id));
};

const getRelatoriosList = () => async (dispatch, getState) => {
  try {
    dispatch(actions.getRelatorioStart());
    const { filtros, pageParams } = getState().relatoriosIof;
    const response = await Service.getRelatorio(pageParams, filtros);
    dispatch(actions.getRelatorioSuccess(response));
  } catch (error) {
    dispatch(actions.getRelatorioError());
    logger.error(error);
  }
};

const setPageParams = (propertyName, value) => async (dispatch) => {
  dispatch(actions.setPageParams(propertyName, value));
  dispatch(getRelatoriosList());
};

const download = () => async (dispatch, getState) => {
  try {
    dispatch(actions.exportRelatorioStart());
    const { relatorios, filtros } = getState().relatoriosIof;
    const response = await Service.download(relatorios, filtros.tipoRelatorio);
    const fileName = relatorios.filter((e) => e.selected === true).length > 1
      ? 'relatorios.zip'
      : relatorios.filter((e) => e.selected === true)[0].nomeDocumento;
    const contentType = relatorios.filter((e) => e.selected === true).length > 1
      ? 'application/zip' : 'text/plain';
    const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
    // JSZip.generateAsync({type: 'blob'}).then(content => saveAs());
    saveAs(url, fileName);

    dispatch(actions.exportRelatorioSuccess());
  } catch (error) {
    dispatch(actions.exportRelatorioError());
  }
};

const setIsRelatorioSelected = (value) => (dispatch) => {
  dispatch(actions.setIsRelatorioSelected(value));
};

const setIsDateRangeSelected = (value) => (dispatch) => {
  dispatch(actions.setIsDateRangeSelected(value));
};

const setDateRange = (value, paramName, isStartDate) => (dispatch, getState) => {
  dispatch(setFilter(value, paramName));
  const { dataInicio, dataFim } = getState().relatoriosIof.filtros;
  if ((isStartDate && value && dataFim) || (!isStartDate && value && dataInicio)) {
    dispatch(setIsDateRangeSelected(true));
  } else {
    dispatch(setIsDateRangeSelected(false));
  }
};

const downloadFiles = () => null;

export default {
  setFilter,
  setPageParams,
  resetStore,
  downloadFiles,
  selectAll,
  select,
  getRelatoriosList,
  download,
  dismissSnackbar,
  setIsRelatorioSelected,
  setIsDateRangeSelected,
  setDateRange,
};
