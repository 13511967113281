/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Scrollbars } from 'react-custom-scrollbars';

import {
  Page, PageHeader, PageTitle, PageSubTitle, PageContent,
} from 'features/common-staff/layout/page';
import AlertaMensagem from 'features/common/alertaMensagem';
import Breadcrumb from './views/breadcrumb';
import Filters from './views/filters';
import DuplicatasVencidasArea from './views/duplicatasVencidasArea';
import CardsResumo from './views/cards';
import ValorCarteiraProduto from './views/valorCarteiraProduto';
import GraficoBrand from './views/graficoCarteiraBrands';

import ValorDuplicatasArea from './views/valorDuplicatasArea';
import AbsolutoDuplicatasArea from './views/absolutosDuplicatasArea';
import './painelFloorPlan.scss';

const useStyles = makeStyles({
  page: {
    gridTemplateRows: 'auto minmax(0, 1fr) auto',
  },

  header: {
    borderBottom: 'solid 1px #C5CEE0',
  },
  headerContainer: {
    padding: '12px 24px',
  },
});

const PainelFloorPlan = ({
  resetStore, load, brands, isUpdated, resetIsUpdated,
}) => {
  useEffect(() => {
    load();
    return () => resetIsUpdated();
  }, [resetStore, load]);
  const classes = useStyles();
  return (
    <Page className={classes.page}>
      <div className={isUpdated ? classes.header : null}>
        <PageHeader className={classes.headerContainer}>
          <PageSubTitle>
            <Breadcrumb />
          </PageSubTitle>
          <PageTitle>Painel Floor Plan</PageTitle>

          {isUpdated ? (
            <div className="dashboard-floor-plan__page__filter">
              <div className="dashboard-floor-plan__page__filter__mensagem">
                Todas as informações do Painel Floor Plan são atualizadas diariamente.
                Você pode optar por visualizar as informações mais recentes ou
                as informações até o dia anterior no filtro de exibição dos dados abaixo.
              </div>
              <Filters />
            </div>
          ) : null}

        </PageHeader>
      </div>
      <PageContent>
        {isUpdated
          ? (
            <Scrollbars>
              <div className="dashboard-floor-plan_page_content">
                <CardsResumo className="dashboard__page__cards resumo" />
                <ValorDuplicatasArea className="duplicataArea" />
                <AbsolutoDuplicatasArea className="dashboard-floor-plan__absoluto-duplicatas duplicataArea2" />
                <DuplicatasVencidasArea className="cards-vencimento vencidas" />
                <div className="produto-brand-area">
                  <ValorCarteiraProduto className="dashboard-floor-plan__carteira_produto produto" />
                  {brands.length > 0 ? (<GraficoBrand className="dashboard-floor-plan__carteira_brand brand" />) : null}
                </div>
              </div>
            </Scrollbars>
          )
          : (
            <div className="dashboard-floor-plan__alerta-mensagem">
              <AlertaMensagem
                mensagem="Desculpe, estamos atualizando as informações."
                subMensagem="Tente novamente mais tarde."
              />
            </div>
          )}
      </PageContent>
    </Page>
  );
};

PainelFloorPlan.propTypes = {
  resetStore: PropTypes.func,
  load: PropTypes.func,
  brands: PropTypes.array,
  isUpdated: PropTypes.bool,
  resetIsUpdated: PropTypes.func,
};

PainelFloorPlan.defaultProps = {
  resetStore: () => { },
  load: () => { },
  brands: [],
  isUpdated: true,
  resetIsUpdated: () => { },
};

export default PainelFloorPlan;
