import { styled } from '@material-ui/styles';
import { TextField } from '@mui/material';
import colors from 'assets/styles/colors';

export const textFieldHub = (props) => ({
  root: props?.noBorder
    ? {
      '& .MuiOutlinedInput-root': {
        color: 'rgba(0, 0, 0, 0.0.82)',

        '& fieldset': {
          borderColor: 'transparent',
          borderRadius: 10,
          color: 'transparent',
        },
        '&:hover fieldset': {
          borderColor: 'transparent',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'transparent',
          borderWidth: '2',
        },
        '&.Mui-focused': {
          color: 'rgba(0, 0, 0, 0.0.82)',
        },
      },
      '& .MuiFormLabel-root': {
        '&.Mui-focused': {
          color: 'transparent',
        },
      },
    } : {
      '& .MuiOutlinedInput-root': {
        color: colors.secundary_color_500,

        '& fieldset': {
          borderColor: colors.secundary_color_500,
          borderRadius: 10,
        },
        '&:hover fieldset': {
          borderColor: colors.primary_color_600,
        },
        '&.Mui-focused fieldset': {
          borderColor: colors.primary_color_600,
          borderWidth: '2',
        },
        '&.Mui-focused': {
          color: colors.primary_color_700,
        },
      },
      '& .MuiFormLabel-root': {
        '&.Mui-focused': {
          color: colors.primary_color_600,
        },
      },
      '& .MuiOutlinedInput-inputAdornedEnd': {
        cursor: 'pointer',
      },
    },
});

export const StyledTextFieldHub = styled(TextField)((props) => ({
  ...textFieldHub(props).root,
  '& .MuiInputBase-input': {
    minHeight: '20px',
    maxHeight: '20px',
  },
}));

StyledTextFieldHub.defaultProps = {
  variant: 'outlined',
};
