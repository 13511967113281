import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';

import './acompanhamentoListHeader.scss';

const useStyles = makeStyles({
  container: {
    gridTemplateAreas: (isModalOpen) => (isModalOpen ? "' id data solicitante produto valor '"
      : "' id data solicitante produto tipo valor status '"),
    gridTemplateColumns: (isModalOpen) => (isModalOpen ? '4.5em 6em minmax(270px, 1fr) 5em minmax(50px, 0.5fr) '
      : '4.5em 6em minmax(270px, 3fr) 5em 11em 10em minmax(150px, 1fr)'),
  },
});

const AcompanhamentoListHeader = ({ isOpenAside }) => {
  const classes = useStyles(isOpenAside);
  return (
    <div className={`acompanhamento__list-header__container ${classes.container}`}>
      <div className="acompanhamento__list-header__item acompanhamento__list-header__id">
        ID
      </div>
      <div className="acompanhamento__list-header__item acompanhamento__list-header__data">
        DATA
      </div>
      <div className="acompanhamento__list-header__item acompanhamento__list-header__solicitante">
        SOLICITANTE
      </div>
      <div className="acompanhamento__list-header__item acompanhamento__list-header__produto">
        PRODUTO
      </div>
      {isOpenAside ? null : (
        <div className="acompanhamento__list-header__item acompanhamento__list-header__tipo">
          TIPO DE SOLICITAÇÃO
        </div>
      )}
      <div className="acompanhamento__list-header__item acompanhamento__list-header__valor">
        VALOR
      </div>
      {isOpenAside ? null : (
        <div className="acompanhamento__list-header__item acompanhamento__list-header__status">
          STATUS
        </div>
      )}
    </div>
  );
};

AcompanhamentoListHeader.propTypes = {
  isOpenAside: PropTypes.bool,
};

AcompanhamentoListHeader.defaultProps = {
  isOpenAside: false,
};

export default AcompanhamentoListHeader;
