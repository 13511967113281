import React from 'react';
import { formatCurrency } from 'utils/format';
import ResumoPagamento from '../resumoPagamento';
import '../cardResumoPagamento.scss';

import ButtonComprovante from '../buttonComprovante';

const CardPagamento = ({
  // eslint-disable-next-line react/prop-types
  transacao, creditoAtual,
}) => (
  <div className="detalhe-movimentacao__card-resumo__card">
    <div className="detalhe-movimentacao__card-resumo">
      <div
        className="detalhe-movimentacao__card-resumo__transacao"
        data-cy="DetalheMovimentacaoCardResumoTransacao"
      >
        Transação
        {' '}
        <br />
        #
        {transacao}
      </div>
      <div
        className="detalhe-movimentacao__card-resumo__valor-Pagamento"
        data-cy="DetalheMovimentacaoCardResumoPagamento"
      >
        {creditoAtual != null ? formatCurrency(creditoAtual) : null}
      </div>
    </div>
    <ResumoPagamento />
    <div className="detalhe-movimentacao__card-resumo__card-lower">
      <div
        className="detalhe-movimentacao__card-resumo__comprovante-button"
        data-cy="DetalheMovimentaçãoCardResumoComprovanteButton"
      >
        <ButtonComprovante />
      </div>
    </div>
  </div>
);

export default CardPagamento;
