import { connect } from 'react-redux';
import DetalhePagamento from './detalhePagamento';

const mapStateToProps = ({ statementStore }) => ({
  isLoading: statementStore.modal.isLoading,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DetalhePagamento);
