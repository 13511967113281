import React from 'react';
import PropTypes from 'prop-types';

import { formatDate } from 'utils/format';

import Identificador from './views/identificador';
import StatementValue from './views/statementValue';

import './listRow.scss';

const selectedLine = (modalIndex, index) => (
  modalIndex === index ? 'selected' : null);

const isEstorno = (tipoOperacao) => (
  tipoOperacao === 'CANCELAMENTO' || tipoOperacao === 'DUPLICIDADE OU A MAIOR'
);

const isEstornoTransacao = (tipoOperacao) => (
  tipoOperacao === 'ESTORNO_TRANSACAO'
);

const ListRow = ({
  movimentacao, setModalOpen, index, modalIndex,
}) => {
  const {
    tipoOperacao,
    duplicataId,
    status,
    produto,
    idMovimentacao,
    uniqueId,
    data,
    valor,
  } = movimentacao ?? {};

  const openModal = (tipoOp) => (isEstorno(tipoOp)
    ? setModalOpen(duplicataId, 'ESTORNO', status, produto, index, tipoOperacao)
    : setModalOpen(idMovimentacao, tipoOperacao, status, produto, index)
  );

  return (
    <div
      className={`statement_page__list-row__container__outer${!isEstornoTransacao(tipoOperacao) ? '__with_hover' : ''} ${selectedLine(modalIndex, index)}`}
    >
      <div
        className={`statement_page__list-row__container ${!isEstornoTransacao(tipoOperacao) ? 'clickable' : ''}`}
        onClick={isEstornoTransacao(tipoOperacao) ? () => {} : () => openModal(tipoOperacao)}
        role="row"
        tabIndex={0}
      >
        <div className="statement_page__list-row__item statement_page__list-row__identificacao">
          <Identificador
            produto={produto}
            status={status}
            tipoOperacao={tipoOperacao}
            id={tipoOperacao === 'PAGAMENTO_INTERNO' || tipoOperacao === 'ESTORNO_TRANSACAO'
              ? `ID ${uniqueId[0]}`
              : `Duplicata Nº ${duplicataId}`}
          />
        </div>
        <div
          className="statement_page__list-row__item statement_page__list-row__data"
          data-cy="ItemStatementPageListRowData"
        >
          <span title={formatDate(data, 'DD MMM YYYY')}>
            {formatDate(data, 'DD MMM YYYY')}
          </span>
        </div>
        <div
          className="statement_page__list-row__item statement_page__list-row__valor"
          data-cy="SaldoStatementPage"
        >
          <StatementValue
            tipoOperacao={tipoOperacao}
            totalValue={valor}
            status={status}
          />
        </div>
      </div>
    </div>
  );
};

ListRow.propTypes = {
  movimentacao: PropTypes.object,
  setModalOpen: PropTypes.func,
  modalIndex: PropTypes.string,
  index: PropTypes.any,
};

ListRow.defaultProps = {
  movimentacao: null,
  setModalOpen: () => {},
  modalIndex: null,
  index: null,
};

export default ListRow;
