import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@material-ui/core/Backdrop';
import NewsIcon from 'assets/icons/news-icon';
import ButtonIcon from 'features/common-staff/controls/buttonIcon';
import { Popper } from '@mui/material';
import {
  CloseIcon,
  CardDescription,
  CardTitle,
  HeaderContainer,
  ButtonContainer,
  DescriptionContainer,
  ChildrenContainer,
  PopperModifiers,
  PopperContent,
  useStyles,
} from './onboardingPopper.style';

const OnboardingPopper = ({
  title, message, width, children, onClose, preventAction, placement, disabled,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    onClose();
    setOpen(false);
  };

  const childrenRef = useRef(null);
  const [arrowRef, setArrowRef] = useState(null);

  return (
    <>
      <ChildrenContainer ref={childrenRef} preventAction={preventAction} disabled={disabled}>
        {children}
      </ChildrenContainer>
      { (!disabled && Boolean(childrenRef.current)) && (
      <Backdrop className={classes.backdrop} open={open}>
        <Popper
          open={open}
          placement={placement}
          anchorEl={childrenRef.current}
          modifiers={PopperModifiers(arrowRef)}
          className={classes.popper}
        >
          <PopperContent width={width}>
            <HeaderContainer>
              <NewsIcon />
              <CardTitle>
                {title}
              </CardTitle>
              <ButtonContainer>
                <ButtonIcon onClick={handleClose}>
                  <CloseIcon />
                </ButtonIcon>
              </ButtonContainer>
            </HeaderContainer>
            <DescriptionContainer>
              <CardDescription>
                {message}
              </CardDescription>
            </DescriptionContainer>
          </PopperContent>
          <span className={classes.arrow} ref={setArrowRef} />
        </Popper>
      </Backdrop>
      )}
    </>
  );
};

OnboardingPopper.propTypes = {
  title: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onClose: PropTypes.func,
  preventAction: PropTypes.bool,
  placement: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

OnboardingPopper.defaultProps = {
  title: '',
  message: '',
  width: 'fit-content',
  children: null,
  onClose: () => {},
  preventAction: true,
};

export default OnboardingPopper;
