import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency, formatDate } from 'utils/format';
import UserInfo from '../userInfo';
import '../cardResumoPagamento.scss';

const DataRow = ({
  // eslint-disable-next-line react/prop-types
  label, value, highlight,
}) => (
  <div className="detalhe-movimentacao__card__data-row">
    <div className="detalhe-movimentacao__card__data-row-label">
      {label}
    </div>
    {highlight
      ? (
        <div className="detalhe-movimentacao__card__data-row-value-highligh">
          {value}
        </div>
      ) : (
        <div className="detalhe-movimentacao__card__data-row-value">
          {value}
        </div>
      )}

  </div>
);

const ResumoPagamento = ({
  concessionariaPagante, duplicatasPagas, dataPagamento,
  valorPagamento, pgtoMesmaConcessionaria, pgtoOutrasConcessionarias,
  pgtoProcessamento, pgtoRejeitado, pgtoAprovados, nomeUsuario, emailUsuario,
  tipo,
}) => (
  <>
    <div
      className="detalhe-movimentacao__card-resumo__card-lower-no-border"
      data-cy="DetalheMovimentacaoCardResumoDuplicataPaga"
    >
      <DataRow
        label="Concessionaria pagante:"
        value={concessionariaPagante}
      />
      <DataRow
        label="Duplicatas a serem pagas:"
        value={`${duplicatasPagas} duplicatas`}
      />
      <DataRow
        label="Data do pagamento:"
        value={formatDate(new Date(dataPagamento), 'DD MMM yyyy')}
      />
      <DataRow
        label="Valor do pagamento solicitado:"
        value={valorPagamento != null ? formatCurrency(valorPagamento) : null}
      />
    </div>
    {tipo === 'PAGAMENTO_EXTERNO' ? (
      <div
        className="detalhe-movimentacao__card-resumo__card-lower"
        data-cy="MovimentacaoCardResumoPagamentoForaHub"
      >
        <DataRow
          label="Pagamento processado fora do HUB"
        />
      </div>
    ) : null}
    {nomeUsuario != null ? (
      <div
        className="detalhe-movimentacao__card-resumo__card-lower"
        data-cy="MovimentacaoCardResumoUser"
      >
        <UserInfo nomeUsuario={nomeUsuario} emailUsuario={emailUsuario} />
      </div>
    ) : null}

    <div
      className="detalhe-movimentacao__card-resumo__card-lower"
      data-cy="DetalheMovimentacaoCardResumoCardLower"
    >
      <DataRow
        label="Pgto. para a mesma concessionária:"
        value={pgtoMesmaConcessionaria != null ? formatCurrency(pgtoMesmaConcessionaria) : null}
      />
      <DataRow
        label="Pgto. para outras concessionárias:"
        value={pgtoOutrasConcessionarias != null ? formatCurrency(pgtoOutrasConcessionarias) : null}
      />
    </div>
    <div
      className="detalhe-movimentacao__card-resumo__card-lower"
      data-cy="DetalheMovimentacaoCardResumoSituacaoPagamento"
    >
      <DataRow
        label="Pagamentos em processamento:"
        value={pgtoProcessamento != null ? formatCurrency(pgtoProcessamento) : null}
      />
      <DataRow
        label="Pagamentos rejeitados:"
        value={pgtoRejeitado != null ? formatCurrency(pgtoRejeitado) : null}
      />
      <DataRow
        label="Pagamentos aprovados:"
        value={pgtoAprovados != null ? formatCurrency(pgtoAprovados) : null}
        highlight
      />
    </div>
  </>
);

ResumoPagamento.propTypes = {
  concessionariaPagante: PropTypes.string,
  duplicatasPagas: PropTypes.number,
  dataPagamento: PropTypes.string,
  valorPagamento: PropTypes.number,
  pgtoMesmaConcessionaria: PropTypes.number,
  pgtoOutrasConcessionarias: PropTypes.number,
  pgtoProcessamento: PropTypes.number,
  pgtoRejeitado: PropTypes.number,
  pgtoAprovados: PropTypes.number,
  nomeUsuario: PropTypes.string,
  emailUsuario: PropTypes.string,
  tipo: PropTypes.string,
};

ResumoPagamento.defaultProps = {
  concessionariaPagante: null,
  duplicatasPagas: null,
  dataPagamento: null,
  valorPagamento: null,
  pgtoMesmaConcessionaria: null,
  pgtoOutrasConcessionarias: null,
  pgtoProcessamento: null,
  pgtoRejeitado: null,
  pgtoAprovados: null,
  nomeUsuario: null,
  emailUsuario: null,
  tipo: null,
};

export default ResumoPagamento;
