import { createSelector } from 'reselect';
import _ from 'lodash';

import { formatDate, camelFormat } from 'utils/format';

const comentariosSolicitacao = createSelector(
  (state) => state.acompanhamento.detalhes.solicitacao?.comentarios,
  (comentarios) => {
    const grupos = new Map();
    (comentarios || []).forEach((p) => {
      if (!_.isDate(p?.data)) { return; }

      const date = formatDate(p.data, 'DD MMM YYYY');
      if (grupos.has(date)) {
        const comentariosForDate = grupos.get(date);
        comentariosForDate.push(p);
      } else {
        grupos.set(date, [p]);
      }
    });

    const result = [];
    grupos.forEach((comentariosForDate, date) => {
      result.push({
        label: date,
        comentarios: comentariosForDate,
      });
    });
    return result;
  },
);

const data = createSelector(
  (state) => state.acompanhamento.filters?.data,
  (date) => (_.isEmpty(date) ? null : date),
);

const statusFilter = createSelector(
  (state) => state.acompanhamento.filters.statusFilter,
  (statusItens) => (statusItens || []).map((status) => ({
    value: status,
    text: camelFormat(status),
  })),
);

export default {
  comentariosSolicitacao,
  data,
  statusFilter,
};
