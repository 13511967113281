import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

import {
  Currency, DuplicataNumber,
} from '../../../../common';

import './paymentReceiptListing.scss';

const PaymentReceiptRow = ({ transaction }) => (
  <div className="receipt-row ">

    {transaction !== undefined ? (
      <div className="receipt-row-number">{transaction.id}</div>
    ) : (<Skeleton />)}

    <div className="receipt-row-value-origem">
      {transaction !== undefined ? (
        <>
          Nº
          <DuplicataNumber
            number={transaction.duplicataOrigemNumero}
            digit={transaction.duplicataOrigemDigito}
          />
        </>
      ) : (<Skeleton />)}
    </div>

    <div className="receipt-row-value-destino">
      {transaction !== undefined ? (
        <>
          Nº
          <DuplicataNumber
            number={transaction.duplicataDestinoNumero}
            digit={transaction.duplicataDestinoDigito}
          />
        </>
      ) : (<Skeleton />)}
    </div>

    {transaction !== undefined ? (
      <Currency
        className="receipt-row-paying-value"
        value={transaction.valor}
        noColor
      />
    ) : (<Skeleton />)}

  </div>
);

PaymentReceiptRow.propTypes = {
  transaction: PropTypes.object.isRequired,
};

const PaymentReceiptListing = ({
  className, transactions,
}) => (
  <div className={`receipt-list-container ${className}`}>
    <div className="receipt-list-header receipt-list-header-inv">
      ID
    </div>
    <div className="receipt-list-header receipt-list-header-due">
      DUPLICATA ORIGEM
    </div>
    <div className="receipt-list-header receipt-list-header-balance">
      DUPLICATA DESTINO
    </div>
    <div className="receipt-list-header receipt-list-header-pay">
      VALOR
    </div>

    <div className="receipt-rows-container">
      { transactions.map((transaction, index) => (
        <PaymentReceiptRow
          key={index}
          transaction={transaction}
        />
      )) }

    </div>
  </div>
);

PaymentReceiptListing.propTypes = {
  className: PropTypes.string,
  transactions: PropTypes.array,
};

PaymentReceiptListing.defaultProps = {
  className: '',
  transactions: [],
};

export default PaymentReceiptListing;
