import React from 'react';
import PropTypes from 'prop-types';

const CapitalizeText = ({ text }) => (
  <span>
    {text.toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase())}
  </span>
);

CapitalizeText.propTypes = {
  text: PropTypes.string,
};

CapitalizeText.defaultProps = {
  text: '',
};

export default CapitalizeText;
