/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { formatDate } from 'utils/format';

import colors from 'assets/styles/colors';
import TimelineCircle from './timelineCircle';
import TimelineIcon from './timelineIcon';
import './timelineItem.scss';

const useStyles = makeStyles({
  conteudo: {
    color: (props) => {
      if (props.isReprovado) return colors.error_color_300;
      if (props.isFuturo) return colors.secundary_color_600;
      return colors.secundary_color_700;
    },
  },

  container: {
    color: (props) => {
      if (props.isReprovado) return colors.error_color_300;
      if (props.isFuturo) return colors.secundary_color_600;
      return colors.primary_color_600;
    },
  },
});

const TimelineRow = ({
  // eslint-disable-next-line react/prop-types
  comentario, classes,
}) => (
  <div className="pedidos__modal__comentarios-list-row__container">
    <div
      className="pedidos__modal__comentarios-list-row__container_line"
    />
    <div className="pedidos__modal__comentarios-list-row__container_icone">
      <TimelineCircle situacao={comentario.situacao} />
    </div>
    <div
      className="pedidos__modal__comentarios-list-row__container_titulo"
    >
      {comentario.titulo}
    </div>
    <div className="pedidos__modal__comentarios-list-row__container_data">
      {comentario.data ? formatDate(new Date(comentario.data), 'DD MMM YYYY').toUpperCase() : null}
    </div>
    <div
      className={`pedidos__modal__comentarios-list-row__container_conteudo ${classes.conteudo}`}
    >
      {comentario.mensagem}
    </div>
    <div className="pedidos__modal__comentarios-list-row__container_hora">
      {comentario.data ? formatDate(new Date(comentario.data), 'HH:mm') : null}
    </div>
  </div>
);

const TimelineItem = ({ timeline }) => {
  const isFuturo = timeline.situacao === 'futuro';
  const isReprovado = timeline.situacao === 'reprovado';
  const classes = useStyles({ isFuturo, isReprovado });
  return (
    <div className="pedidos__modal__comentarios-list__content_outer">
      <div className={`pedidos__modal__comentarios-list__content ${classes.container}`}>
        <div className="pedidos__modal__comentarios-list__content_comentarios ">
          <div
            className="pedidos__modal__comentarios-list__content_label"
          >
            <TimelineIcon status={timeline?.statusPedido} />
          </div>
          <TimelineRow comentario={timeline} classes={classes} />
        </div>
      </div>
    </div>
  );
};

TimelineItem.propTypes = {
  timeline: PropTypes.object.isRequired,
};

export default TimelineItem;
