import React from 'react';
import PropTypes from 'prop-types';

import {
  Switch, Route, Redirect, useRouteMatch,
} from 'react-router-dom';

import PermissionRoute from 'modules/auth/routes/permissionRoute';
import { permissoes } from 'modules/auth/permissoes';

import MenuDashboard from './menuDashboard';
import PainelFloorPlan from './painelFloorPlan';
import { Page } from './redux/enums';

import './dashboard.scss';

const Dashboard = ({ permissions, className }) => {
  const { path } = useRouteMatch();

  let defaultPath = Page.principal;
  if (permissions.dashboardFloorPlan) defaultPath = Page.principal;

  return (
    <div className={`dashboard-page__page ${className}`}>
      <div className="dashboard-page__menu">
        <MenuDashboard />
      </div>
      <div className="dashboard-page__content">
        <Switch>
          <PermissionRoute
            path={`${path}/${Page.principal}`}
            requireAll={[permissoes.dashboardFloorPlan]}
          >
            <PainelFloorPlan />
          </PermissionRoute>

          <Route path="*">
            <Redirect to={`${path}/${defaultPath}`} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  permissions: PropTypes.object,
  className: PropTypes.string,
};

Dashboard.defaultProps = {
  permissions: {},
  className: '',
};

export default Dashboard;
