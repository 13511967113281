import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import TextFilterDebounce from 'features/common-staff/controls/textFilterDebounce/textFilterDebounce';
import { Tracking } from 'modules';

const TextFilter = ({
  texto, setTexto, isLoading,
}) => (
  <TextFilterDebounce
    dataCy="FilterConcessionariaPedidoMVS"
    placeholder="Buscar por concessionária, número do pedido ou código MVS"
    value={texto}
    setValue={(text) => {
      setTexto(_.isEmpty(text) ? null : text);
    }}
    disabled={isLoading}
    tracking={() => Tracking.Mixpanel.acompanhamentoTd.trackFiltroTexto()}
    showSearchIcon
  />
);

TextFilter.propTypes = {
  texto: PropTypes.string,
  setTexto: PropTypes.func,
  isLoading: PropTypes.bool,
};

TextFilter.defaultProps = {
  texto: '',
  setTexto: () => {},
  isLoading: false,
};

export default TextFilter;
