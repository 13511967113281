import { chatStates } from './chatStates';

import { valueForChatState } from '../../chatEngine/utils';

const fluxoNaoSolicitouTestDrive = [
  {
    from: chatStates.td_nao_solicitou_inicio,
    transitionFunction: () => chatStates.td_pq_nao_solicitou,
  },
  {
    from: chatStates.td_pq_nao_solicitou,
    transitionFunction: (answer) => (
      answer === 'outros' ? chatStates.td_pq_nao_solicitou_outros_qual : chatStates.td_nao_solicitou_fim
    ),
  },
  {
    from: chatStates.td_pq_nao_solicitou_outros_qual,
    transitionFunction: () => chatStates.td_nao_solicitou_fim,
  },
];

const fluxoSolicitouTestDrive = [
  {
    from: chatStates.td_solicitou_inicio, transitionFunction: () => chatStates.experiencia_hub,
  },
  {
    from: chatStates.experiencia_hub, transitionFunction: () => chatStates.td_palavra_processo,
  },
  {
    from: chatStates.td_palavra_processo, transitionFunction: () => chatStates.td_dificuldade,
  },
  {
    from: chatStates.td_dificuldade,
    transitionFunction: (answer) => (
      (answer === true) ? chatStates.td_dificuldade_qual : chatStates.td_melhoria
    ),
  },
  {
    from: chatStates.td_dificuldade_qual, transitionFunction: () => chatStates.td_melhoria,
  },
  {
    from: chatStates.td_melhoria,
    transitionFunction: (answer) => (
      (answer === true) ? chatStates.td_melhoria_qual : chatStates.td_solicitou_fim
    ),
  },
  {
    from: chatStates.td_melhoria_qual, transitionFunction: () => chatStates.td_solicitou_fim,
  },
];

const fluxoPag = [
  {
    from: chatStates.pag_inicio,
    transitionFunction: (answer, answers) => {
      const permissions = valueForChatState(answers, chatStates.get_permission_info);
      if (!permissions.efetuarPagamento) {
        return chatStates.pag_fim;
      }

      return chatStates.pag_ja_utilizou;
    },
  },
  {
    from: chatStates.pag_ja_utilizou,
    transitionFunction: (answer) => (
      answer ? chatStates.pag_experiencia : chatStates.pag_n_utilizou_motivo
    ),
  },
  {
    from: chatStates.pag_n_utilizou_motivo,
    transitionFunction: () => chatStates.pag_fim,
  },
  {
    from: chatStates.pag_experiencia,
    transitionFunction: () => chatStates.pag_palavra_hub,
  },
  {
    from: chatStates.pag_palavra_hub,
    transitionFunction: () => chatStates.pag_melhoria,
  },
  {
    from: chatStates.pag_melhoria,
    transitionFunction: (answer) => (
      answer ? chatStates.pag_melhoria_qual : chatStates.pag_fim
    ),
  },
  {
    from: chatStates.pag_melhoria_qual,
    transitionFunction: () => chatStates.pag_fim,
  },
];

export const transitions = [
  { from: chatStates.get_permission_info, transitionFunction: () => chatStates.get_user_info },
  { from: chatStates.get_user_info, transitionFunction: () => chatStates.iniciar },
  {
    from: chatStates.iniciar,
    transitionFunction: (answer, answers) => {
      if (answer === 'nao') {
        return chatStates.volte_depois;
      }

      const permissions = valueForChatState(answers, chatStates.get_permission_info);
      if (permissions.solicitarTestDrive) {
        return chatStates.solicitou_td;
      }

      return chatStates.pag_inicio;
    },
  },
  { from: chatStates.volte_depois, transitionFunction: () => chatStates.fim },
  {
    from: chatStates.solicitou_td,
    transitionFunction: (answer) => (
      answer === true
        ? chatStates.td_solicitou_inicio
        : chatStates.td_nao_solicitou_inicio
    ),
  },

  ...fluxoNaoSolicitouTestDrive,
  ...fluxoSolicitouTestDrive,

  {
    from: chatStates.td_nao_solicitou_fim, transitionFunction: () => chatStates.pag_inicio,
  },
  {
    from: chatStates.td_solicitou_fim, transitionFunction: () => chatStates.pag_inicio,
  },

  ...fluxoPag,

  { from: chatStates.pag_fim, transitionFunction: () => chatStates.melhorar_hub },

  { from: chatStates.melhorar_hub, transitionFunction: () => chatStates.envio_resposta },
  { from: chatStates.envio_resposta, transitionFunction: () => chatStates.agradecemos },
  { from: chatStates.agradecemos, transitionFunction: () => chatStates.fim },
  { from: chatStates.fim, transitionFunction: () => chatStates.fim },
];
