import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import MenuInternetBanking from './menuInternetBanking';

const mapDispatchToProps = (dispatch) => ({
  navTo: (path) => { dispatch(push(path)); },
});

export default connect(
  null,
  mapDispatchToProps,
)(MenuInternetBanking);
