import actions from './actions';

const INITIAL_STATE = {
  snackbarErrors: [],
  lastSnackbarErrorId: 0,
  cadastroPage: false,

  limitesAprovadosList: {
    isLoading: false,
    isError: false,
    limites: null,
  },

  limiteDetails: {
    isLoading: false,
    isError: false,
    condicao: null,
    detalhes: null,
    idLimite: null,
    historico: [],
  },

  limiteUpdate: {
    isLoading: false,
    isError: false,
  },
};

const reduceGetDetalheLimite = (state, action) => {
  switch (action.type) {
    case actions.types.GET_DETALHE_LIMITE_START:
      return {
        ...state,
        limiteDetails: {
          ...state.limiteDetails,
          isLoading: true,
          isError: false,
          detalhes: null,
          historico: [],
        },
      };
    case actions.types.GET_DETALHE_LIMITE_SUCCESS:
      return {
        ...state,
        limiteDetails: {
          ...state.limiteDetails,
          isLoading: false,
          isError: false,
          detalhes: action.payload.detalhes,
          historico: action.payload.historico,
        },
      };
    case actions.types.GET_DETALHE_LIMITE_ERROR:
      return {
        ...state,
        limiteDetails: {
          ...state.limiteDetails,
          isLoading: false,
          isError: true,
          detalhes: null,
          historico: [],
        },
      };
    default:
      return state;
  }
};

const reduceUpdateLimite = (state, action) => {
  switch (action.type) {
    case actions.types.UPDATE_LIMITE_START:
      return {
        ...state,
        limiteUpdate: {
          ...state.limiteUpdate,
          isLoading: true,
          isError: false,
        },
      };
    case actions.types.UPDATE_LIMITE_SUCCESS:
      return {
        ...state,
        limiteUpdate: {
          ...state.limiteUpdate,
          isLoading: false,
          isError: false,
        },
      };
    case actions.types.UPDATE_LIMITE_ERROR:
      return {
        ...state,
        limiteUpdate: {
          ...state.limiteUpdate,
          isLoading: false,
          isError: true,
        },
      };
    default:
      return state;
  }
};

export default (state = INITIAL_STATE, action = { type: 'default' }) => {
  if (action.type.startsWith(actions.types.PREFIX_LIMITES_APROVADOS_DETALHES)) {
    return reduceGetDetalheLimite(state, action);
  }
  if (action.type.startsWith(actions.types.PREFIX_LIMITES_APROVADOS_FILTERS)) {
    return reduceUpdateLimite(state, action);
  }
  switch (action.type) {
    case actions.types.ADD_SNACKBAR:
      return {
        ...state,
        snackbarErrors: [
          ...state.snackbarErrors,
          {
            id: state.lastSnackbarErrorId + 1,
            message: action.payload.message,
            type: action.payload.type,
          },
        ],
        lastSnackbarErrorId: state.lastSnackbarErrorId + 1,
      };
    case actions.types.DISMISS_SNACKBAR:
      return {
        ...state,
        snackbarErrors: state.snackbarErrors.filter((item) => item.id !== action.payload.id),
      };
    case actions.types.GET_LIMITES_APROVADOS_START:
      return {
        ...state,
        limitesAprovadosList: {
          ...state.limitesAprovadosList,
          isLoading: true,
          isError: false,
          limites: null,
        },
      };
    case actions.types.GET_LIMITES_APROVADOS_ERROR:
      return {
        ...state,
        limitesAprovadosList: {
          ...state.limitesAprovadosList,
          isLoading: false,
          isError: true,
          limites: null,
        },
      };
    case actions.types.GET_LIMITES_APROVADOS_SUCCESS:
      return {
        ...state,
        limitesAprovadosList: {
          ...state.limitesAprovadosList,
          isLoading: false,
          isError: false,
          limites: action.payload.limites,
        },
      };
    case actions.types.SET_CADASTRO_PAGE:
      return {
        ...state,
        cadastroPage: action.payload.cadastroPage,
      };
    case actions.types.UPDATE_LIMITE_STATUS:
      return {
        ...state,
        limiteDetails: {
          ...state.limiteDetails,
          detalhes: {
            ...state.limiteDetails.detalhes,
            statusLimite: action.payload.statusLimite,
          },
        },
      };
    case actions.types.RESET_STORE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
