import { connect } from 'react-redux';

import ComprovantePagamento from './comprovantePagamento';
import operations from '../../../../../redux/operations';

const mapStateToProps = ({ statementStore }) => ({
  isLoading: statementStore.modalComprovante?.isLoading,
  listaPagamentos: statementStore.modal.detalhes?.pagamentos,
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => { dispatch(operations.setModalComprovanteClose()); },
  exportarComprovante: () => { dispatch(operations.getExtrato()); },
});

export default connect(mapStateToProps, mapDispatchToProps)(ComprovantePagamento);
