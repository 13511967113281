import React from 'react';
import PropTypes from 'prop-types';

import MessageIcon from '@material-ui/icons/InsertComment';
import DotIcon from '@material-ui/icons/FiberManualRecord';
import { formatDate } from 'utils/format';

import './timelineRow.scss';

const SelectIcon = (tipo) => {
  if (tipo === 'comentario_usuario') {
    return <MessageIcon style={{ fontSize: 16 }} />;
  }
  return <DotIcon style={{ fontSize: 12 }} />;
};

const TimeLineRow = ({ comentario }) => (
  <div className="common__comentarios-list-row__container">
    <div className="common__comentarios-list-row__container_line" />
    <div
      className="common__comentarios-list-row__container_icone"
      data-cy="ComentariosListRowContainerIcon"
    >
      {SelectIcon(comentario.tipo)}
    </div>
    <div
      className="common__comentarios-list-row__container_conteudo"
      data-cy="ComentarioListRowContainerConteudo"
    >
      {comentario.texto}
    </div>
    <div
      className="common__comentarios-list-row__container_hora"
      data-cy="ComentarioListRowContainerHora"
    >
      {formatDate(new Date(comentario.hora), 'HH:mm')}
    </div>
  </div>
);

TimeLineRow.propTypes = {
  comentario: PropTypes.object,
};

TimeLineRow.defaultProps = {
  comentario: null,
};

export default TimeLineRow;
