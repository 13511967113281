import { connect } from 'react-redux';
import { compose } from 'redux';
import { push } from 'react-router-redux';
import { withRouter } from 'react-router-dom';

import PagamentoPage from './pagamentoPage';
import operations from './redux/operations';
import selector from './redux/selector';
import { PATH_CARTEIRA } from '../../../../routes/paths';

const mapStateToProps = ({ gestaoPagamentos, balanceStore }) => ({
  totalValue: selector.getTotalValue(gestaoPagamentos),
  saldoRemanescente: balanceStore.creditoRemanescente,
  invoicesTotal: selector.getTotalInvoices(gestaoPagamentos),
  snackbarErrors: gestaoPagamentos.pagamento.snackbar,
  status: gestaoPagamentos.pagamento.status,
});

const mapDispatchToProps = (dispatch) => ({
  navTo: () => dispatch(push(PATH_CARTEIRA)),
  resetStore: () => dispatch(operations.resetStore()),
  onCloseSnackBar: (id) => dispatch(operations.dismissSnackbar(id)),
  getStatus: () => dispatch(operations.getStatus()),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(PagamentoPage);
