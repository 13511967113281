import { connect } from 'react-redux';

import RemoveButton from './removeButton';
import operations from '../../../../../redux/operations';
import operationsPage from '../../../../../../carteiraPage/redux/operations';

const mapDispatchToProps = (dispatch) => ({
  onClick: (invoiceId) => {
    dispatch(operations.removeInvoice(invoiceId));
    dispatch(operationsPage.setSelectToPayment(invoiceId));
  },
});

export default connect(null, mapDispatchToProps)(RemoveButton);
