/* eslint-disable class-methods-use-this */

import axios from 'axios';
import _ from 'lodash';

class PedidosDetalheService {
  async getDetalhePedido(pedidoId) {
    const response = await axios.get(`${window.env.REACT_APP_API_URL}/testdrive/pedidos/${pedidoId}`);
    response.data.data = new Date(response.data.data);
    if (!response.data || response.status !== 200) {
      throw new Error('Falha na busca dos detalhes!');
    }

    if (_.isArray(response?.data?.comentarios)) {
      response.data.comentarios.forEach((p) => {
        // eslint-disable-next-line no-param-reassign
        p.data = new Date(p.data);
      });
    }

    return response.data;
  }

  async cancelPedido(pedidoId, currentStatus) {
    const cancelPedidoDto = {
      currentStatus,
    };

    const response = await axios.put(`${window.env.REACT_APP_API_URL}/dealer/pedidos/${pedidoId}/cancel`, cancelPedidoDto);

    if (response.status !== 200) {
      throw new Error('Falha ao tentar cancelar o pedido!');
    }
    return response.data;
  }
}
export default new PedidosDetalheService();
