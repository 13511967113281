import { connect } from 'react-redux';
import { DealerOperations, DealerSelectors } from '../../../../modules/dealers/redux';

import DealerSelector from './dealerSelector';

const mapStateToProps = ({
  dealerStore, gestaoPagamentos,
  statementStore, iofSimulatorStore,
}) => ({
  dealers: dealerStore.dealers,
  selectedDealer: DealerSelectors.getSelectedFPlanDealer(dealerStore),
  isLoadingCarteira: gestaoPagamentos?.carteira?.page?.requestStatus?.isLoading,
  isLoadingMovimentacoes: statementStore?.requestStatus?.isLoading,
  isLoadingSimulador: iofSimulatorStore.loading,
});

const mapDispatchToProps = (dispatch) => ({
  setDealer: (value) => {
    dispatch(DealerOperations.setSelectedFPlanDealer(value.dealerId));
    dispatch(DealerOperations.setSelectedFPlanBrand(value.brand));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DealerSelector);
