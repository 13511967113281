/* eslint-disable no-use-before-define */
import { etapas } from './etapas';
import { messageCreators } from '../utils/messageCreators';

const isTextInputAvailable = (state) => {
  const { etapaAtual } = state;
  return etapaAtual === etapas.descreverProblema;
};

const messages = (state) => {
  const { history } = state;
  let msgs = [];

  for (let i = 0; i < history.length; i += 1) {
    const { etapa, dados } = history[i];
    const createHistoryMessages = messageCreators.forHistory(etapa);

    msgs = msgs.concat(createHistoryMessages(dados));
  }

  const createMessages = messageCreators.forCurrentEtapa(state.etapaAtual);
  msgs = msgs.concat(createMessages(state));

  return msgs;
};

export default {
  messages,
  isTextInputAvailable,
};
