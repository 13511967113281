import { connect } from 'react-redux';

import PaymentReceiptInfoBox from './paymentReceiptInfoBox';

const mapStateToProps = ({ paymentStore }) => ({
  loading: paymentStore.paymentReceipt.loading,
  setTransactionError: paymentStore.paymentReceipt.setTransactionError,
});

export default connect(mapStateToProps, null)(PaymentReceiptInfoBox);
