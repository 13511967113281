import React from 'react';
import PropTypes from 'prop-types';

import { XCircle } from 'react-feather';
import CircularProgress from '@material-ui/core/CircularProgress';
import Frame from '../../../../../assets/imgs/Frame.png';
import './cardBase.scss';

const CardBase = ({
  isLoading, isError, children, minWidth, minHeight, isEmpty, // noData
}) => {
  if (isLoading) {
    return (
      <div
        className="card__loading"
        style={{ minWidth, minHeight }}
      >
        <CircularProgress
          color="inherit"
          size="40px"
        />
      </div>
    );
  }
  if (isError) {
    return (
      <div
        className="card__container__error"
        style={{ minWidth, minHeight }}
      >
        <div className="card__container__error_icon">
          <XCircle />
        </div>
        <div className="card__container__error_message">
          Não foi possível exibir os dados
        </div>
      </div>
    );
  }
  if (isEmpty) {
    return (
      <div
        className="card__container__dados"
        style={{ minWidth, minHeight }}
      >
        <div className="card__container__dados_icon">
          <Frame />
        </div>
        <div className="card__container__dados_message">
          <span>Ainda não há nenhum dado a ser exibido</span>
          <small>Tente selecionar outro produto ou outro ponto de venda.</small>
        </div>
      </div>
    );
  }

  return (
    <div
      className="card__container"
      style={{ minWidth, minHeight }}
    >
      {children}
    </div>
  );
};

CardBase.propTypes = {
  children: PropTypes.element.isRequired,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  minWidth: PropTypes.string,
  minHeight: PropTypes.string,
  isEmpty: PropTypes.bool,
};

CardBase.defaultProps = {
  isError: false,
  isLoading: false,
  minWidth: '180px',
  minHeight: '169px',
  isEmpty: false,
};

export default CardBase;
