import { connect } from 'react-redux';

import Footer from './footer';

const mapStateToProps = () => ({

});

const mapDispatchToProps = () => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
