import { connect } from 'react-redux';

import RelatorioList from './relatorioList';

const mapStateToProps = ({ relatoriosIof }) => ({
  isLoading: relatoriosIof.requestStatus.isLoading,
  isError: relatoriosIof.requestStatus.isError,
  documentos: relatoriosIof.relatorios,
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(RelatorioList);
