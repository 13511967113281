import React from "react";

const CategoryIcon = props => (
	<svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
		<path
			d="M6.1.96L3.62 5.013a.665.665 0 00.567 1.014H9.14c.52 0 .84-.574.567-1.014L7.233.96A.662.662 0 006.1.96zm4.233 6.4a3 3 0 100 6 3 3 0 000-6zm-9 5.667h4c.367 0 .667-.3.667-.667v-4c0-.367-.3-.667-.667-.667h-4c-.366 0-.666.3-.666.667v4c0 .367.3.667.666.667z"
			fill={props.fill ? `${props.fill}` : "currentColor"}
			fillRule="evenodd"
		/>
	</svg>
);

export default CategoryIcon;

