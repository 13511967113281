import { UserInfo } from '../utils/userInfo';

const SET_USER_INFO = 'feedback/error/SET_USER_INFO';
const SET_CATEGORIAS_DISPONIVEIS = 'feedback/error/SET_CATEGORIAS_DISPONIVEIS';
const GO_FROM_SENTIMOS_MUITO_TO_SELECIONAR_CATG = 'feedback/error/GO_FROM_SENTMS_MTO_TO_SELECIONAR_CATG';
const SELECIONAR_CATEGORIA = 'feedback/error/SELECIONAR_CATEGORIA';
const DESCREVER_PROBLEMA = 'feedback/error/DESCREVER_PROBLEMA';
const REPORTAR_OUTRO = 'feedback/error/REPORTAR_OUTRO';
const RESET_STORE = 'feedback/error/RESET_STORE';

const setUserInfo = (userInfo) => {
  if (!(userInfo instanceof UserInfo)) {
    throw Error('setUserInfo action called with argument which is not instanceof UserInfo');
  }

  return {
    type: SET_USER_INFO,
    payload: { userInfo },
  };
};

const setCategoriasDisponiveis = (categoriasDisponiveis) => ({
  type: SET_CATEGORIAS_DISPONIVEIS,
  payload: {
    categoriasDisponiveis,
  },
});

const goFromSentimosMuitoToSelecionarCategoria = () => ({
  type: GO_FROM_SENTIMOS_MUITO_TO_SELECIONAR_CATG,
  payload: null,
});

const selecionarCategoria = (categoria) => ({
  type: SELECIONAR_CATEGORIA,
  payload: {
    categoria,
  },
});

const descreverProblema = (descricao) => ({
  type: DESCREVER_PROBLEMA,
  payload: {
    descricao,
  },
});

const reportarOutro = (querReportarOutro) => ({
  type: REPORTAR_OUTRO,
  payload: {
    querReportarOutro,
  },
});

const resetStore = () => ({
  type: RESET_STORE,
  payload: null,
});

export const actions = {
  types: {
    SET_USER_INFO,
    SET_CATEGORIAS_DISPONIVEIS,
    GO_FROM_SENTIMOS_MUITO_TO_SELECIONAR_CATG,
    SELECIONAR_CATEGORIA,
    DESCREVER_PROBLEMA,
    REPORTAR_OUTRO,
    RESET_STORE,
  },

  setUserInfo,
  setCategoriasDisponiveis,
  goFromSentimosMuitoToSelecionarCategoria,
  selecionarCategoria,
  descreverProblema,
  reportarOutro,
  resetStore,
};
