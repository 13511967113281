import React from "react";

const LockIcon = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 14" {...props}>
		<path
			d="M10 4.667h-.667V3.333a3.335 3.335 0 00-6.666 0v1.334H2c-.733 0-1.333.6-1.333 1.333v6.667C.667 13.4 1.267 14 2 14h8c.733 0 1.333-.6 1.333-1.333V6c0-.733-.6-1.333-1.333-1.333zm-4 6c-.733 0-1.333-.6-1.333-1.334C4.667 8.6 5.267 8 6 8s1.333.6 1.333 1.333c0 .734-.6 1.334-1.333 1.334zm-2-6V3.333c0-1.106.893-2 2-2s2 .894 2 2v1.334H4z"
			fill="#000"
			fillRule="evenodd"
		/>
	</svg>
);

export default LockIcon;

