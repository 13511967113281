import { React } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Box, Stack } from '@mui/material';
import SearchOffRoundedIcon from '@mui/icons-material/SearchOffRounded';
import colors from 'assets/styles/colors';
import ObservacoesItem from './observacoesItem/observacoesItem';

const ObservacoesList = ({
  items, isDealer, downloadDocumento, isDrawer,
}) => (
  <Stack>
    { _.isEmpty(items) ? (
      <Stack color={colors.primary_color_600} height={104} gap="8px" alignItems="center" padding="24px 0px">
        <SearchOffRoundedIcon color="inherit" />
        <Box component="span" fontWeight={700} lineHeight="24px" fontSize="14px">
          Nenhuma mensagem foi encontrada.
        </Box>
      </Stack>
    ) : items?.map((item, index) => (
      <ObservacoesItem
        key={index}
        item={item}
        isLastItem={index === (items?.length || 0) - 1}
        isDealer={isDealer}
        downloadDocumento={downloadDocumento}
        isDrawer={isDrawer}
      />
    ))}
  </Stack>
);

ObservacoesList.propTypes = {
  items: PropTypes.array,
  isDealer: PropTypes.bool,
  downloadDocumento: PropTypes.func,
  isDrawer: PropTypes.bool,
};

ObservacoesList.defaultProps = {
  items: [],
  isDealer: false,
  downloadDocumento: () => { },
  isDrawer: false,
};

export default ObservacoesList;
