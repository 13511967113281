import { connect } from 'react-redux';

import { DealerSelectors } from 'modules/dealers/redux';
import RenderIfBrandPermission from './renderIfBrandPermission';

const mapStateToProps = ({ dealerStore }) => ({
  selectedDealer: DealerSelectors.getSelectedFPlanDealer(dealerStore),
});

export default connect(mapStateToProps, null)(RenderIfBrandPermission);
