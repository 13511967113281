import actions from './actions';

import { reduceSetDadosOrigem, reduceSetDadosDestino, INITIAL_STATE_DADOS } from './reducer_dados';
import { reduceGetProdutos, INITIAL_STATE_PRODUTOS } from './reducer_produtos';

const INITIAL_STATE = {
  isLoading: false,
  isError: false,
  isOpen: false,

  valor: null,

  snackbarErrors: [],
  lastSnackbarErrorId: 0,

  dadosOrigem: INITIAL_STATE_DADOS,
  dadosDestino: INITIAL_STATE_DADOS,

  produtos: INITIAL_STATE_PRODUTOS,
};

const reducePostSolicitacao = (state, action) => {
  switch (action.type) {
    case actions.types.POST_SOLITACAO_CREDITO_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case actions.types.POST_SOLITACAO_CREDITO_SUCCESS:
      return {
        isLoading: false,
        isError: false,
        valor: null,
        dadosOrigem: {
          codigoSap: null,
          selectedDealerId: null,
          produto: null,
        },
        dadosDestino: {
          codigoSap: null,
          selectedDealerId: null,
          produto: null,
        },
        produtos: {
          ...state.produtos,
        },
        snackbarErrors: [
          ...state.snackbarErrors,
          { id: state.lastSnackbarErrorId + 1, message: 'Solicitação feita com sucesso.', type: 'success' },
        ],
        lastSnackbarErrorId: state.lastSnackbarErrorId + 1,
      };
    case actions.types.POST_SOLITACAO_CREDITO_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        snackbarErrors: [
          ...state.snackbarErrors,
          { id: state.lastSnackbarErrorId + 1, message: 'Erro ao enviar a solicitação.', type: 'error' },
        ],
        lastSnackbarErrorId: state.lastSnackbarErrorId + 1,
      };
    default:
      return state;
  }
};

export default (state = INITIAL_STATE, action = { type: 'default' }) => {
  if (action.type.startsWith(actions.types.PREFIX_SET_DADOS_ORIGEM)) {
    return reduceSetDadosOrigem(state, action);
  }
  if (action.type.startsWith(actions.types.PREFIX_SET_DADOS_DESTINO)) {
    return reduceSetDadosDestino(state, action);
  }
  if (action.type.startsWith(actions.types.PREFIX_GET_PRODUTOS)) {
    return reduceGetProdutos(state, action);
  }
  if (action.type.startsWith(actions.types.PREFIX_POST_SOLITACAO_CREDITO)) {
    return reducePostSolicitacao(state, action);
  }
  switch (action.type) {
    case actions.types.SET_VALOR:
      return {
        ...state,
        valor: action.payload,
      };
    case actions.types.DISMISS_SNACKBAR:
      return {
        ...state,
        snackbarErrors: state.snackbarErrors.filter((item) => item.id !== action.payload),
      };
    case actions.types.SET_MODAL_OPEN:
      return {
        ...state,
        isOpen: action.payload,
      };
    case actions.types.RESET_STORE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
