import { chatStates as chatStateEnum } from './chat/chatStates';

import { actions } from './actions';

export const SEND_ANSWER_STATUS_SENDING = 'SENDING';
export const SEND_ANSWER_STATUS_ERROR = 'ERROR';
export const SEND_ANSWER_STATUS_SUCCESS = 'SUCCESS';

const INITIAL_STATE = {
  chatState: chatStateEnum.get_permission_info,
  answers: [],
  messages: [],

  sendAnswerStatus: null,
};

export default (state = INITIAL_STATE, action = { type: 'default' }) => {
  switch (action.type) {
    case actions.types.SET_CHAT_STATE:
      return {
        ...state,
        chatState: action.payload.chatState,
      };
    case actions.types.PUSH_ANSWER:
      if (action.payload.answer === undefined) { return state; }

      return {
        ...state,
        answers: [
          ...state.answers,
          { chatState: state.chatState, value: action.payload.answer },
        ],
      };
    case actions.types.PUSH_MESSAGES:
      return {
        ...state,
        messages: [...state.messages, ...action.payload.messages],
      };
    case actions.types.SEND_ANSWER_BEGIN:
      return {
        ...state,
        sendAnswerStatus: SEND_ANSWER_STATUS_SENDING,
      };
    case actions.types.SEND_ANSWER_SUCCESS:
      return {
        ...state,
        sendAnswerStatus: SEND_ANSWER_STATUS_SUCCESS,
      };
    case actions.types.SEND_ANSWER_ERROR:
      return {
        ...state,
        sendAnswerStatus: SEND_ANSWER_STATUS_ERROR,
      };
    case actions.types.RESET_STORE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
