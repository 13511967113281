import React from 'react';
import PropTypes from 'prop-types';

import './optionButton.scss';

const OptionButton = ({
  className, text, selected, value, onClick, isAnswered,
}) => (
  <div className={className}>
    { selected ? (
      <div className="choice-message__container-selected">
        <div className="choice-message__text-selected">
          {text}
        </div>
      </div>
    ) : (
      <div
        className={`choice-message__container-unselected ${isAnswered ? 'choice-message__container-unselected-answered' : 'choice-message__container-unselected-not-answered'}`}
        onClick={isAnswered ? () => {} : () => onClick(value)}
        role="button"
        tabIndex="0"
      >
        <div className="choice-message__text-unselected">
          {text}
        </div>
      </div>
    )}
  </div>
);

OptionButton.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  onClick: PropTypes.func.isRequired,
  isAnswered: PropTypes.bool,
};

OptionButton.defaultProps = {
  className: '',
  selected: false,
  isAnswered: false,
  value: null,
};

export default OptionButton;
