import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { permissoes } from 'modules/auth/permissoes';

import {
  Page, PageHeader, PageTitle, PageContent, PageSubTitle,
} from 'features/common-staff/layout/page';
import SnackbarList from 'features/common-staff/snackbarList';
import RenderIfAuthorized from 'features/menu/view/renderIfAuthorized';
import List, { ListContent } from '../../../common-staff/layout/list';

import BreadcrumbPagamento from './views/breadcrumbPagamentos';
import PagamentoList from './views/pagamentoList';
import MenuInternetBanking from '../../../menu/view/menuInternetBanking';
import ResumoCard from './views/resumoCard';
import PaymentButton from './views/paymentButton';
import BackButton from './views/backButton';
import AlertaError from './views/alertaError';

import './pagamentoPage.scss';

const disponibilidadePagamento = (status, totalValue, saldoRemanescente) => {
  if (status === null || (saldoRemanescente - totalValue) < 0 || !status.disponivel) {
    return true;
  }
  return false;
};

const PagamentoPage = ({
  totalValue, saldoRemanescente, invoicesTotal, getStatus,
  navTo, resetStore, onCloseSnackBar, snackbarErrors, status,
}) => {
  useEffect(() => {
    if (invoicesTotal <= 0) {
      navTo();
    }
    getStatus();
    return () => { resetStore(); };
  }, [resetStore, navTo, invoicesTotal, getStatus]);

  return (
    <Page>
      <div className="pagamento-page">
        <section className="pagamento-page_menu">
          <MenuInternetBanking />
        </section>

        <section className="pagamento-page_content">
          <Page
            minWidth="fit-content"
          >
            <PageHeader>
              <PageSubTitle>
                <BreadcrumbPagamento />
              </PageSubTitle>
              <PageTitle>
                <div className="pagamento-page__header">
                  <div className="pagamento-page__header__title">
                    Solicitação de pagamento
                  </div>
                  <div className="pagamento-page__header__button">
                    <BackButton />
                  </div>
                </div>
              </PageTitle>
            </PageHeader>
            <PageContent>
              <div className="pagamento-page__body">
                <div
                  className="pagamento-page__body__list"
                  data-cy="pagamento-page__body-list"
                >
                  Confira as duplicatas selecionadas que serão pagas e o valor a ser pago.
                  Você pode editar o valor a ser pago em cada duplicata,
                  para realizar pagamentos parciais.
                  <PagamentoList />
                </div>
                <div className="pagamento-page__body__resumo">
                  <List>
                    <ListContent>
                      <ResumoCard />
                      <RenderIfAuthorized requiredAuth={permissoes.efetuarPagamentoB}>
                        <div
                          className="pagamento-page__body__resumo-button"
                          data-cy="pagamento__resumo-button"
                        >
                          <PaymentButton
                            isDisabled={
                            disponibilidadePagamento(status, totalValue, saldoRemanescente)
                            }
                          />
                        </div>
                      </RenderIfAuthorized>
                      {disponibilidadePagamento(status, totalValue, saldoRemanescente)
                        ? (
                          <AlertaError
                            value={(saldoRemanescente - totalValue)}
                          />
                        )
                        : null}
                    </ListContent>
                  </List>
                </div>
              </div>
            </PageContent>
            <SnackbarList
              snackbarErrors={snackbarErrors}
              onClose={(id) => onCloseSnackBar(id)}
            />
          </Page>
        </section>
      </div>
    </Page>
  );
};

PagamentoPage.propTypes = {
  totalValue: PropTypes.number,
  saldoRemanescente: PropTypes.number,
  invoicesTotal: PropTypes.number,
  navTo: PropTypes.func,
  resetStore: PropTypes.func,
  snackbarErrors: PropTypes.array,
  onCloseSnackBar: PropTypes.func,
  getStatus: () => {},
  status: PropTypes.object,
};

PagamentoPage.defaultProps = {
  totalValue: null,
  saldoRemanescente: null,
  invoicesTotal: 0,
  navTo: () => {},
  resetStore: () => {},
  snackbarErrors: [],
  onCloseSnackBar: () => {},
  getStatus: () => {},
  status: null,
};

export default PagamentoPage;
