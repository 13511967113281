import { connect } from 'react-redux';

import RelatorioListRow from './relatorioListRow';
import operations from '../../../redux/operations';

const mapDispatchToProps = (dispatch) => ({
  onCheck: (documento) => dispatch(operations.select(documento)),
});

export default connect(null, mapDispatchToProps)(RelatorioListRow);
