import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

import { LockIcon } from '../../../../assets/icons';
import { Currency } from '../../../common';

import './valorSaldo.scss';

const ValorSaldo = ({
  creditoRemanescente, creditoBloqueado, visible,
}) => (
  <div>
    <div
      className="saldo__remanescente"
      data-cy="SaldoRemanescente"
    >
      {
        (visible && Number.isFinite(creditoRemanescente))
          ? (
            <Currency
              value={creditoRemanescente}
              noColor
            />
          )
          : (
            <Skeleton duration={-1} height={29} />
          )
      }
    </div>

    <div
      className="saldo__bloqueado"
      data-cy="SaldoBloqueado"
    >
      {
        (visible && Number.isFinite(creditoBloqueado))
          ? (
            <span>
              <Currency
                value={creditoBloqueado}
                noColor
              />
              <LockIcon />
            </span>
          )
          : (
            <Skeleton duration={-1} height={17} />
          )
      }
    </div>
  </div>
);

ValorSaldo.propTypes = {
  creditoRemanescente: PropTypes.number,
  creditoBloqueado: PropTypes.number,
  visible: PropTypes.bool,
};

ValorSaldo.defaultProps = {
  creditoRemanescente: null,
  creditoBloqueado: null,
  visible: false,
};

export default ValorSaldo;
