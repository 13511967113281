import { createSelector } from 'reselect';
import { camelFormat } from 'utils/format';

const isEnableDownload = createSelector(
  (state) => state.relatorios,
  (relatorios) => (!(relatorios.filter((e) => e.selected).length > 0)),
);

const concessionariaList = createSelector(
  (state) => state.dealers,
  (listMatriz) => (listMatriz || []).map((item) => ({
    value: item.dealerId,
    text: `${camelFormat(item.dealerName)} - ${item.dealerCorr}`,
  })),
);

export default {
  isEnableDownload,
  concessionariaList,
};
