import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  SEND_ANSWER_STATUS_SENDING,
  SEND_ANSWER_STATUS_ERROR,
} from '../redux/reducer';

import './sendingAnswerBar.scss';

const SendingAnswerBar = ({
  status, onRetryClicked,
}) => {
  const Sending = (
    <div className="feedback__sending-answer__container">
      <div className="feedback__sending-answer__sending-text">
        Sua resposta está sendo enviada...
      </div>
      <div className="feedback__sending-answer__sending-spinner">
        <CircularProgress
          color="inherit"
          size="20px"
        />
      </div>
    </div>
  );

  const Error = (
    <div className="feedback__sending-answer__container">
      <div className="feedback__sending-answer__error-text">
        Erro ao enviar as respostas.
      </div>
      <div className="feedback__sending-answer__error-try-again">
        <div role="button" tabIndex={0} onClick={onRetryClicked}>
          Tentar novamente
        </div>
      </div>
    </div>
  );

  return (
    <>
      {
        status === SEND_ANSWER_STATUS_SENDING
          ? Sending : null
      }
      {
        status === SEND_ANSWER_STATUS_ERROR
          ? Error : null
      }
    </>
  );
};

SendingAnswerBar.propTypes = {
  status: PropTypes.string,
  onRetryClicked: PropTypes.func,
};

SendingAnswerBar.defaultProps = {
  status: null,
  onRetryClicked: () => {},
};

export default SendingAnswerBar;
