import React from "react";

const FloorPlanIcon = props => (
	<svg width="1em" height="1em" viewBox="0 0 14 12" {...props}>
		<path
			d="M5.333 8.667V3.333C5.333 2.6 5.927 2 6.667 2h6v-.667C12.667.6 12.067 0 11.333 0H2C1.26 0 .667.6.667 1.333v9.334C.667 11.4 1.26 12 2 12h9.333c.734 0 1.334-.6 1.334-1.333V10h-6c-.74 0-1.334-.6-1.334-1.333zm2-5.334c-.366 0-.666.3-.666.667v4c0 .367.3.667.666.667h6V3.333h-6zm2 3.667c-.553 0-1-.447-1-1 0-.553.447-1 1-1 .554 0 1 .447 1 1 0 .553-.446 1-1 1z"
			fill="currentColor"
			fillRule="evenodd"
		/>
	</svg>
);

export default FloorPlanIcon;
