import React from 'react';

import './errorContent.scss';

export const ErrorContent = () => (
  <div className="page-error-boundary-content__container">
    <div className="page-error-boundary-content__content">
      <h1>Ocorreu um erro.</h1>
      <p>Tente recarregar a página.</p>
    </div>
  </div>
);

export default ErrorContent;
