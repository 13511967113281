import React from 'react';
import PropTypes from 'prop-types';

import { formatCurrency } from 'utils/format';
import CardBody from '../cardBody';

import './aumentoCard.scss';

const AumentoCard = ({
  solicitacao, valor, solicitanteEmail, concessionaria, produto, brand,
}) => (
  <div className="acompanhamento__detalhe__card-aumento">
    <div
      className="acompanhamento__detalhe__card-aumento__header"
      data-cy="AcompDetalheCardAumentoHeader"
    >
      <div className="acompanhamento__detalhe__card-aumento__header-title">
        {solicitacao}
      </div>
      <div className="acompanhamento__detalhe__card-aumento__header-valor">
        {formatCurrency(valor)}
      </div>
    </div>
    <div
      className="acompanhamento__detalhe__card-aumento__content"
      data-cy="AcompDetalheCardAumentoContent"
    >
      <CardBody
        solicitanteEmail={solicitanteEmail}
        concessionaria={concessionaria}
        produto={produto}
        brand={brand}
      />
    </div>
  </div>
);

AumentoCard.propTypes = {
  solicitacao: PropTypes.string,
  valor: PropTypes.number,
  solicitanteEmail: PropTypes.string,
  concessionaria: PropTypes.object,
  produto: PropTypes.string,
  brand: PropTypes.string,
};

AumentoCard.defaultProps = {
  solicitacao: '',
  valor: 0,
  solicitanteEmail: '',
  concessionaria: null,
  produto: '',
  brand: '',
};

export default AumentoCard;
