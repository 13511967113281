import { connect } from 'react-redux';

import PaymentModal from './paymentModal';
import selector from '../../redux/selector';

const mapStateToProps = ({ gestaoPagamentos }) => ({
  invoices: gestaoPagamentos.carteira.modal.modalPayment?.data,
  totalValue: selector.getTotalValue(gestaoPagamentos),
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentModal);
