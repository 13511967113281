/* eslint-disable class-methods-use-this */
import axios from 'axios';
import { formatDateForUrl } from 'utils/axios';

class ComunicadosService {
  async getDves(filtros, page, isFundao) {
    const params = new URLSearchParams();
    filtros.brand.map((t) => params.append('brands', t.value));
    if (filtros.documento) params.append('query', filtros.documento);
    params.append('page', page.page);
    params.append('itensPorPage', page.ipp);
    params.append('isFundao', isFundao);
    if (filtros.startDate) params.append('dataInicio', formatDateForUrl(filtros.startDate));
    if (filtros.endDate) params.append('dataFim', formatDateForUrl(filtros.endDate));
    const response = await axios.get(
      `${window.env.REACT_APP_API_URL}/central-comunicados/documentos`,
      {
        params,
      },
    );

    if (response.status !== 200 || !response.data) {
      throw new Error('Erro ao buscar invoices');
    }
    response.data.documentos = response.data.documentos.map((d) => ({
      ...d,
      dataEmissao: new Date(d.dataEmissao),
    }));

    return response.data;
  }
}
export default new ComunicadosService();
