import React from 'react';
import PropTypes from 'prop-types';

import CloseModalButton from './closeModalButton';

import './modal.scss';

const Modal = ({
  children, disableCloseButton, closeModal,
}) => (
  <div className="common__modal__overlay">
    <div className="common__modal__container">
      <div className="common__modal__close">
        <CloseModalButton disabled={disableCloseButton} onClick={closeModal} />
      </div>
      <div className="common__modal__content">
        {children}
      </div>
    </div>
  </div>
);

Modal.propTypes = {
  children: PropTypes.node,
  disableCloseButton: PropTypes.bool,
  closeModal: PropTypes.func,
};

Modal.defaultProps = {
  children: null,
  disableCloseButton: false,
  closeModal: () => {},
};

export default Modal;
