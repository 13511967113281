import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  TextField, makeStyles,
} from '@material-ui/core';
import PaperPlaneIcon from '../../../../assets/icons/paper-plane';

import './chatInput.scss';

const useStyles = makeStyles(() => ({
  rootNoBorder: {
    '& .MuiOutlinedInput-root': {
      color: 'rgba(0, 0, 0, 0.82)',

      '& fieldset': {
        borderColor: 'transparent',
        color: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderWidth: '0',
      },
      '&.Mui-focused': {
        color: 'rgba(0, 0, 0, 0.82)',
      },
    },
    '& .MuiFormLabel-root': {
      '&.Mui-focused': {
        color: 'transparent',
      },
    },
  },
}));

const ChatInput = ({
  className, onSendClicked, placeholder, onChange,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const clearValue = () => { setValue(''); };

  return (
    <div className={className}>
      <TextField
        value={value || ''}
        onChange={(e) => { setValue(e.target.value); }}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            onChange('\n');
          }
        }}
        className={classes.rootNoBorder}
        variant="outlined"
        placeholder={placeholder}
        fullWidth
        multiline
        rows={1}
        rowsMax={3}
        style={{
          height: '40px',
          backgroundColor: '#f9fafc',
        }}
        InputProps={{
          style: {
            height: '40px',
            fontSize: '12px',
            lineHeight: '1.33',
          },
          endAdornment: (
            <InputAdornment position="end">
              <PaperPlaneIcon
                size="24px"
                style={value ? {
                  width: '24px', height: '24px', cursor: 'pointer',
                } : {
                  width: '24px', height: '24px', cursor: 'default',
                }}
                onClick={() => {
                  if (!value) { return; }

                  onSendClicked(value, clearValue);
                }}
              />
            </InputAdornment>),
        }}
      />
    </div>
  );
};

ChatInput.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onSendClicked: PropTypes.func,
};

ChatInput.defaultProps = {
  className: '',
  placeholder: 'Descreva aqui.',
  onSendClicked: () => {},
  onChange: () => {},
};

export default ChatInput;
