import * as React from "react";

function CarIcon(props) {
	return (
		<svg
			width="1em"
			height="1em"
			viewBox="0 0 26 11"
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path d="M10.833 3.9h1.734v.867h-1.734V3.9z" />
			<path
				d="M25.628 2.604l-2.943-.42a14.866 14.866 0 00-1.668-.884A14.593 14.593 0 0014.979 0h-.51a14.73 14.73 0 00-7.034 1.794l-1.486.81A10.24 10.24 0 00.994 3.947 1.978 1.978 0 000 5.66v2.573c0 .24.194.434.433.434h1.455a2.59 2.59 0 004.89 0h11.577a2.59 2.59 0 004.89 0h.155c.055 0 .11-.01.161-.031l1.404-.563A1.639 1.639 0 0026 6.543v-3.51a.434.434 0 00-.372-.429zM6.032 8.147a1.733 1.733 0 11-3.397-.694 1.733 1.733 0 013.397.694zm7.835-.347H6.933a2.6 2.6 0 10-5.2 0H.867V5.66c0-.397.213-.763.557-.96a9.369 9.369 0 014.643-1.233h7.8V7.8zm0-5.2h-6.1l.083-.046A13.856 13.856 0 0113.867.886V2.6zm.866-1.733h.245c1.747-.002 3.477.33 5.1.976l-.757.757h-4.588V.867zm7.765 7.28a1.733 1.733 0 11-3.396-.695 1.733 1.733 0 013.396.695zM25.133 3.9h-1.3v.867h1.3v1.776a.776.776 0 01-.49.724l-1.243.5c0-.04-.005-.08-.008-.12-.002-.04-.003-.086-.008-.13-.004-.043-.013-.086-.02-.13-.007-.043-.012-.086-.02-.126-.009-.04-.022-.083-.033-.124-.011-.041-.02-.083-.033-.123-.013-.04-.028-.079-.043-.119l-.043-.119c-.016-.039-.036-.074-.054-.11-.018-.038-.036-.078-.057-.115-.02-.038-.043-.07-.063-.104a1.34 1.34 0 00-.067-.11c-.025-.035-.048-.063-.072-.095-.024-.032-.05-.07-.078-.103-.028-.034-.054-.06-.081-.087-.027-.027-.057-.064-.087-.095-.03-.03-.059-.054-.089-.08-.03-.027-.062-.06-.095-.087-.033-.028-.066-.05-.099-.075l-.1-.074c-.035-.023-.074-.043-.111-.068a1.26 1.26 0 00-.103-.061c-.035-.02-.079-.039-.119-.058-.04-.019-.07-.036-.108-.051-.037-.016-.086-.032-.13-.048-.043-.016-.071-.028-.108-.04-.05-.015-.1-.025-.15-.038-.034-.008-.065-.018-.098-.025-.054-.01-.108-.017-.162-.025-.033-.004-.063-.011-.095-.014A2.603 2.603 0 0018.202 7.8h-3.469V3.467H19.5a.433.433 0 00.306-.127l1.124-1.124c.472.224.93.475 1.374.751a.435.435 0 00.168.062l2.661.38v.49z"
			
			/>
		</svg>
	);
}

export default CarIcon;

