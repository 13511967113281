export const NOTIFICATIONS_FINISH_REQUEST = 'notification/NOTIFICATIONS_FINISH_REQUEST';
export const NOTIFICATIONS_INIT_REQUEST = 'notification/NOTIFICATIONS_INIT_REQUEST';
export const SET_NOTIFICATIONS = 'notification/SET_NOTIFICATIONS';
export const SET_HAS_ERROR_NOTIFICATIONS = 'notification/SET_HAS_ERROR_NOTIFICATIONS';
export const SET_HAS_SUCCESS_NOTIFICATIONS = 'notification/SET_HAS_SUCCESS_NOTIFICATIONS';
export const NOTIFICATIONS_INIT_POST = 'notification/NOTIFICATIONS_INIT_POST';
export const NOTIFICATIONS_FINISH_POST = 'notification/NOTIFICATIONS_FINISH_POST';
export const NOTIFICATIONS_INIT_REQUEST_LAST_GET = 'notification/NOTIFICATIONS_INIT_REQUEST_LAST_GET';
export const NOTIFICATIONS_FINISH_REQUEST_LAST_GET_ERROR = 'notification/NOTIFICATIONS_FINISH_REQUEST_LAST_GET_ERROR';
export const NOTIFICATIONS_FINISH_REQUEST_LAST_GET_SUCCESS = 'notification/NOTIFICATIONS_FINISH_REQUEST_LAST_GET_SUCCESS';
export const CONCAT_NOTIFICATIONS = 'notification/CONCAT_NOTIFICATIONS';

export const initNotificationsRequest = () => ({
  type: NOTIFICATIONS_INIT_REQUEST,
});

export const initNotificationsPost = () => ({
  type: NOTIFICATIONS_INIT_POST,
});

export const finishNotificationsPost = (isError) => ({
  type: NOTIFICATIONS_FINISH_POST,
  payload: {
    isError,
  },
});

export const initNotificationsRequestLastGet = () => ({
  type: NOTIFICATIONS_INIT_REQUEST_LAST_GET,
});

export const finishNotificationsRequestLastGetSuccess = (lastGet) => ({
  type: NOTIFICATIONS_FINISH_REQUEST_LAST_GET_SUCCESS,
  payload: {
    lastGet,
  },
});

export const finishNotificationsRequestLastGetError = () => ({
  type: NOTIFICATIONS_FINISH_REQUEST_LAST_GET_ERROR,
});

export const setNotifications = (notifications) => ({
  type: SET_NOTIFICATIONS,
  payload: {
    notifications,
  },
});

export const concatNotifications = (notifications) => ({
  type: CONCAT_NOTIFICATIONS,
  payload: {
    notifications,
  },
});

export const finishNotificationsRequest = (isError, lastGet) => ({
  type: NOTIFICATIONS_FINISH_REQUEST,
  payload: {
    isError,
    lastGet,
  },
});

export const setHasSuccessNotifications = (hasSuccessNotification) => ({
  type: SET_HAS_SUCCESS_NOTIFICATIONS,
  payload: {
    hasSuccessNotification,
  },
});

export const setHasErrorNotifications = (hasErrorNotification) => ({
  type: SET_HAS_ERROR_NOTIFICATIONS,
  payload: {
    hasErrorNotification,
  },
});
