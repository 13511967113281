import React from 'react';
import PropTypes from 'prop-types';

import FormSectionModal from '../formSectionModal';

const DadosDestinoModal = ({ codigoSap, selectedDealerId, selectedProduct }) => (
  <FormSectionModal
    title="Concessionária destino"
    codigoSap={codigoSap}
    selectedDealerId={selectedDealerId}
    selectedProduct={selectedProduct}
  />
);

DadosDestinoModal.propTypes = {
  codigoSap: PropTypes.any,
  selectedDealerId: PropTypes.number,
  selectedProduct: PropTypes.string,
};

DadosDestinoModal.defaultProps = {
  codigoSap: '',
  selectedDealerId: null,
  selectedProduct: null,
};

export default DadosDestinoModal;
