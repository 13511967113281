import styled from 'styled-components';

export const MenuLateralContainer = styled.div`
  background-color: #F9FAFC;
  grid-area: main;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  min-width: 240px;
`;

export const Line = styled.div`
  box-sizing: border-box;
  width: calc(100% - 48px);
  height: 1px;
  margin: 0 24px;
  background-color: #e9eaf0;
`;

export default null;
