import { connect } from 'react-redux';

import { PaymentOperations } from '../../redux';
import PaymentPage from './paymentPage';
import { DealerSelectors } from '../../../../modules/dealers/redux';

const mapDispatchToProps = (dispatch) => ({
  resetPaymentStore: () => dispatch(PaymentOperations.resetPaymentStore()),
  getInvoices: (page, startDate, endDate) => (
    dispatch(PaymentOperations.getInvoices(page, startDate, endDate))
  ),
});

const mapStateToProps = ({ paymentStore, balanceStore, dealerStore }) => ({
  selectedInvoices: paymentStore.selectedInvoices,
  remainingBalance: balanceStore.creditoRemanescente,
  step: paymentStore.step,
  dealer: DealerSelectors.getSelectedFPlanDealer(dealerStore),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentPage);
