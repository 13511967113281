import React from 'react';
import PropTypes from 'prop-types';
import ArrowRightIcon from '../../../../../assets/icons/arrow-right';
import Button from '../../../../common/button';

import './cardReportError.scss';

const CardReportError = ({
  className, onClick,
}) => (
  <div className={className}>
    <div className="chat-popover__card-error-feedback__info-container">
      <div className="chat-popover__card-error-feedback__title">Encontrou algum erro?</div>
      <div className="chat-popover__card-error-feedback__subtitle">Reporte pra gente</div>
    </div>
    <Button
      className="chat-popover__card-error-feedback__button"
      onClick={onClick}
    >
      <ArrowRightIcon className="chat-popover__card-error-feedback__button-image" />
    </Button>
  </div>
);

CardReportError.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

CardReportError.defaultProps = {
  className: '',
  onClick: () => {},
};

export default CardReportError;
