import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import List, { ListContent } from 'features/common-staff/layout/list';
import AcompanhamentoListHeader from './acompanhamentoListHeader';
import AcompanhamentoListRow from './acompanhamentoListRow';

import './acompanhamentoPageList.scss';

const AcompanhamentoPageList = ({ solicitacoes, isLoading, isError }) => (
  <div className="acompanhamento__list__container">
    <div
      className="acompanhamento__list__container__header"
      data-cy="AcompanhamentoListHeader"
    >
      <AcompanhamentoListHeader />
    </div>
    <List
      isLoading={isLoading}
      isError={isError}
      isEmpty={_.isEmpty(solicitacoes)}
    >
      <ListContent>
        {(Array.isArray(solicitacoes) ? solicitacoes : []).map((s, i) => (
          <AcompanhamentoListRow solicitacao={s} key={i} />
        ))}
      </ListContent>

      <ListContent type="empty">
        <div className="acompanhamento__list__message-container">
          Nenhuma solicitação foi encontrado.
        </div>
      </ListContent>

      <ListContent type="error">
        <div className="acompanhamento__list__message-container">
          Ocorreu um erro ao carregar as solicitações.
        </div>
      </ListContent>
    </List>
  </div>
);

AcompanhamentoPageList.propTypes = {
  solicitacoes: PropTypes.array,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
};

AcompanhamentoPageList.defaultProps = {
  solicitacoes: null,
  isLoading: false,
  isError: false,
};

export default AcompanhamentoPageList;
