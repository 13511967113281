import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {
  Container, IconStyle,
} from './orderButton.style';

const OrderButton = ({
  column, nomeColuna, sentidoOrdenacao, setOrder,
}) => {
  const [isHover, setIsHover] = useState(false);
  const isActive = column === nomeColuna;
  const order = isActive ? sentidoOrdenacao : 'DESC';

  const orderColumn = () => {
    const newOrder = order === 'DESC' ? 'ASC' : 'DESC';
    setOrder(column, newOrder);
  };

  const IconStyled = IconStyle(isActive, isHover);
  return (
    <Container
      onClick={() => orderColumn()}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      data-cy={`FilterOrder${column}`}
    >
      {order === 'ASC'
        ? <ArrowUpwardIcon style={IconStyled} />
        : <ArrowDownwardIcon style={IconStyled} />}
    </Container>
  );
};

OrderButton.propTypes = {
  column: PropTypes.string,
  nomeColuna: PropTypes.string,
  sentidoOrdenacao: PropTypes.string,
  setOrder: PropTypes.func,
};

OrderButton.defaultProps = {
  column: '',
  nomeColuna: '',
  sentidoOrdenacao: '',
  setOrder: () => {},
};

export default OrderButton;
