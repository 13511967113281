/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import TextInput from './formatted/textInput';
import NumberInput from './formatted/numberInput';
import CurrencyInput from './formatted/currencyInput';
import PercentInput from './formatted/percentInput';

import './formInput.scss';

const FormInput = ({
  value, setValue, type, disabled, label, required, ...other
}) => (
  <div className="common__form-input__container">
    { label ? (
      <div className="common__form-input__container-label">
        {required ? (
          <>
            {`${label} (obrigatório)`}
            <span className="red-asterisk"> *</span>
          </>
        ) : (
          label
        )}
      </div>
    ) : null}
    <div className="common__form-input__container-input">
      {type === 'text' ? (
        <TextInput
          {...other}
          value={value}
          disabled={disabled}
          setValue={setValue}
        />
      ) : null}
      {type === 'number' ? (
        <NumberInput
          {...other}
          disabled={disabled}
          value={value}
          setValue={setValue}
        />
      ) : null}
      {type === 'currency' ? (
        <CurrencyInput
          {...other}
          value={value}
          disabled={disabled}
          setValue={setValue}
        />
      ) : null}
      {type === 'percent' ? (
        <PercentInput
          {...other}
          value={value}
          disabled={disabled}
          setValue={setValue}
        />
      ) : null}
    </div>
  </div>
);
FormInput.propTypes = {
  label: PropTypes.string,
  setValue: PropTypes.func,
  value: PropTypes.any,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

FormInput.defaultProps = {
  label: '',
  setValue: () => {},
  value: '',
  type: 'text',
  disabled: true,
  required: false,
};

export default FormInput;
