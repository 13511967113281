import axios from 'axios';

class TaxasService {
  constructor() {
    this.URL_API = window.env.REACT_APP_API_URL;
  }
  async getTaxas(filters) {
    const params = new URLSearchParams();
    params.append('mes', filters.mes);
    params.append('ano', filters.ano);
    params.append('brand', filters.brand);

    const response = await axios.get(
      `${this.URL_API}/taxas`,
      {
        params,
      },
    );

    if (!response || response.status !== 200) {
      throw new Error('Erro ao buscar taxas');
    }

    return response.data;
  }
}

export default new TaxasService();
