import { connect } from 'react-redux';

import * as operations from '../solicitacaoPage/redux/testDriveOperations';

import Snackbars from './snackbars';

const mapStateToProps = ({ testDrive }) => ({
  showConditionsLoadError: testDrive.pedido.showConditionsLoadError,
  showSubmitError: testDrive.pedido.showSubmitError,
  showSubmitSuccess: testDrive.pedido.showSubmitSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  dismissShowConditionsLoadError: () => dispatch(operations.dismissShowConditionsLoadError()),
  dismissSubmitError: () => dispatch(operations.dismissSubmitError()),
  dismissSubmitSuccess: () => dispatch(operations.dismissSubmitSuccess()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Snackbars);
