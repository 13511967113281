import { createSelector } from 'reselect';

const isProdutoOrigem = (state) => state?.transferenciaLimite?.dadosOrigem?.produto;
const isCodigoSapOrigem = (state) => state?.transferenciaLimite?.dadosOrigem?.codigoSap;
const isMarcaOrigem = (state) => state?.transferenciaLimite?.dadosOrigem?.brand;
const isDealerOrigem = (state) => state?.transferenciaLimite?.dadosOrigem?.selectedDealerId;
const isProdutoDestino = (state) => state?.transferenciaLimite?.dadosDestino?.produto;
const isCodigoSapDestino = (state) => state?.transferenciaLimite?.dadosDestino?.codigoSap;
const isMarcaDestino = (state) => state?.transferenciaLimite?.dadosDestino?.brand;
const isDealerDestino = (state) => state.transferenciaLimite?.dadosDestino?.selectedDealerId;
const isValor = (state) => state?.transferenciaLimite?.valor;

const isValidSapOrigem = createSelector(
  isCodigoSapOrigem,
  (sapOrigem) => (sapOrigem?.length < 6),
);

const isValidSapDestino = createSelector(
  isCodigoSapDestino,
  (sapDestino) => (sapDestino?.length < 6),
);

const isSendDisabled = createSelector(
  isProdutoOrigem,
  isCodigoSapOrigem,
  isDealerOrigem,
  isProdutoDestino,
  isCodigoSapDestino,
  isDealerDestino,
  isValor,
  isValidSapOrigem,
  isValidSapDestino,
  isMarcaOrigem,
  isMarcaDestino,
  (
    produtoOrigem,
    codigoSapOrigem,
    ConcessionariaOrigem,
    produtoDestino,
    codigoSapDestino,
    concessionariaDestino,
    valor,
    validSapOrigem,
    validSapDestino,
    brandOrigem,
    brandDestino,
  ) => (
    produtoOrigem == null || codigoSapOrigem?.length !== 6
      || ConcessionariaOrigem == null || produtoDestino == null
      || codigoSapDestino?.length !== 6 || concessionariaDestino == null
      || valor == null || validSapOrigem || validSapDestino || brandOrigem == null
      || brandDestino == null),
);

export default {
  isSendDisabled,
  isValidSapOrigem,
  isValidSapDestino,
};
