import React from 'react';
import PropTypes from 'prop-types';
import './detailsCard.scss';
import CircleAlertYellowIcon from 'assets/icons/circle-alert-yellow';
import ButtonTooltipIcon from '../buttonTooltipIcon';
import SummaryPage from '../summaryPage';

const DetailsCard = ({
  children, data, open, handleOpenChange,
}) => {
  const tooltipTitle = (chave) => (chave === 'Valor' ? 'Valor de aprovação alterado.' : 'Data de vencimento alterada.');
  const renderTooltip = (item) => (
    <ButtonTooltipIcon title={tooltipTitle(item.chave)}>
      <CircleAlertYellowIcon width="12" height="12" className="circle-alert" />
    </ButtonTooltipIcon>
  );

  return (
    <SummaryPage level={3} title={children} open={open} handleOpenChange={handleOpenChange}>
      <div className="content" data-cy="conteudo-produto">
        {data.map((item, index) => (
          <div key={index} className="content__row">
            <div className={`${item.modificado ? 'content__row__notify' : 'content__row__key'}`}>
              {item.chave}
              {item.modificado && renderTooltip(item)}
            </div>
            <div className={`${item.modificado ? 'content__row__notify' : 'content__row__value'}`}>
              {item.valor}
            </div>
          </div>
        ))}
      </div>
    </SummaryPage>
  );
};

DetailsCard.propTypes = {
  children: PropTypes.element,
  data: PropTypes.array,
  open: PropTypes.bool,
  handleOpenChange: PropTypes.func,
};

DetailsCard.defaultProps = {
  children: {},
  data: [],
  open: false,
  handleOpenChange: () => { },
};

export default DetailsCard;
