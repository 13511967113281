import React from 'react';
import PropTypes from 'prop-types';

import Button from 'features/common-staff/controls/button';

const VoltarButton = ({ onClick }) => (
  <Button
    color="darkGrayBorder"
    onClick={onClick}
  >
    Voltar
  </Button>
);

VoltarButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default VoltarButton;
