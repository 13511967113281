import React from 'react';
import PropTypes from 'prop-types';
import { ArrowBackIos } from '@material-ui/icons';

import './popoverHeader.scss';

const PopoverHeader = ({
  onClick, className, title,
}) => (
  <div className={`popover-header__container ${className}`}>
    <div
      className="popover-header__icon"
      onClick={() => onClick()}
      role="button"
      tabIndex="0"
      aria-label="Voltar"
    >
      <ArrowBackIos />
    </div>
    <div className="popover-header__title">{ title }</div>
  </div>
);

PopoverHeader.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

PopoverHeader.defaultProps = {
  className: '',
};

export default PopoverHeader;
