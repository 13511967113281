import React from 'react';
import PropTypes from 'prop-types';

import './menuAsideButton.scss';

const MenuAsideButton = ({
  onClick,
  active,
  icon,
  label,
  visible,
  heightFidc,
}) => {
  if (visible === false) return null;
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className="menu-aside-btn"
      style={{ height: heightFidc ?? '64px' }}
      onClick={onClick}
    >
      {
      active
        ? (
          <div className="menu-aside-btn-active" />
        )
        : null
      }
      <div className="menu-aside-btn-icon">
        {icon}
      </div>
      <div className="menu-aside-btn-label">
        {label}
      </div>
    </div>
  );
};

MenuAsideButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  icon: PropTypes.element.isRequired,
  label: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  heightFidc: PropTypes.string,
};

MenuAsideButton.defaultProps = {
  heightFidc: null,
};

export default MenuAsideButton;
