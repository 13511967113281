import axios from 'axios';

class InvoiceService {
  constructor() {
    this.URL_API = window.env.REACT_APP_API_URL;
    this.INVOICE_FILTER = 'opened,delayed';
  }

  async getInvoices(page, itensPerPage, startDate, endDate, searchText) {
    return axios.get(`${this.URL_API}/invoices`, {
      params: {
        list_type: 'pay',
        p: page,
        ipp: itensPerPage,
        s: this.INVOICE_FILTER,
        startDate,
        endDate,
        f: searchText,
      },
    });
  }

  async postPayment(invoices, user) {
    const data = {
      userId: user.id,
      userName: user.name,
      userEmail: user.email,
      invoicesToPay: (invoices || []).map(({ invoiceId, selectedForPaymentValue }) => ({
        id: invoiceId,
        value: selectedForPaymentValue,
      })),
    };

    const response = await axios({
      url: `${this.URL_API}/invoices/pay`,
      method: 'post',
      data,
    });

    if (response.status !== 200) {
      throw new Error(`Error submitting payment: server responded with HTTP code ${response.status}`);
    }
    return response.data;
  }

  async downloadPaymentReport(id) {
    return axios.get(`${this.URL_API}/solicitacao-operacao/${id}/xlsx`, {
      responseType: 'arraybuffer',
    });
  }

  async getPaymentInformation(id) {
    return axios.get(`${this.URL_API}/solicitacao-operacao/${id}`);
  }
}
export default new InvoiceService();
