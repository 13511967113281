import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency, formatDate } from 'utils/format';

import './rowHistorico.scss';

const RowHistorico = ({ historico }) => (
  <div className="tabela-historico__row">
    <div className="tabela-historico__row__processamento">
      {formatDate(historico.dataProcessamento, 'DD/MM/YYYY')}
    </div>
    <div className="tabela-historico__row__pagamento">
      {formatDate(historico.dataPagamento, 'DD/MM/YYYY')}
    </div>
    <div className="tabela-historico__row__historico">
      {historico.historico}
    </div>
    <div className="tabela-historico__row__documento">
      {historico.numeroDocumento}
    </div>
    <div className="tabela-historico__row__valor-documento">
      {formatCurrency(historico.valorDocumento)}
    </div>
    <div className="tabela-historico__row__cheque">
      {historico.pagamentoCheque.toUpperCase()}
    </div>
  </div>
);

RowHistorico.propTypes = {
  historico: PropTypes.object.isRequired,
};

export default RowHistorico;
