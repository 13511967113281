import { connect } from 'react-redux';
import * as limiteCreditoOperations from '../../../redux/operations';

import RadioGroupProduto from './radioGroupProduto';

const mapStateToProps = ({ limiteCreditoStore }) => ({
  produtos: limiteCreditoStore.alteracaoLimite.produtos?.produtos,
  isLoading: limiteCreditoStore.alteracaoLimite.produtos.isLoading,
  isError: limiteCreditoStore.alteracaoLimite.produtos.isError,
  selectedProduct: limiteCreditoStore.alteracaoLimite.dados.produto,
});

const mapDispatchToProps = (dispatch) => ({
  setProduto: (produto) => dispatch(limiteCreditoOperations.setProduto(produto)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RadioGroupProduto);
