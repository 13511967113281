/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-tabs */
import React, { useEffect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { Download } from 'react-feather';

import DashboardIcon from '@material-ui/icons/Dashboard';
import FolderIcon from '@material-ui/icons/Folder';
import ExitIcon from 'assets/icons/exit';
import IconeStellantisBanco from 'assets/icons/iconsRebranding/icone-stellantis-banco';
import BalanceIcon from 'assets/icons/balance';
import SimuladorIcon from 'assets/icons/simulador';
import DirectionsCarIcon from 'assets/icons/directions-car';
import BellIcon from 'assets/icons/bell';
import LimiteIcon from 'assets/icons/limite';

import MensagemRede from 'features/mensagemRede';
import FidcIcon from 'assets/icons/fidc-icon';
import FloorPlanIcon from 'assets/icons/floor-plan';

import { Auth, Tracking } from 'modules';
import {
  PATH_PAYMENTS, PATH_STATEMENT, PATH_STATEMENT_B, PATH_CARTEIRA,
  PATH_IOF_SIMULATOR, PATH_PRODUCTS, PATH_SOLICITACAO_CREDITO,
  PATH_INVOICES, PATH_DASHBOARD, PATH_CENTRAL, PATH_RELATORIOS_IOF,
  PATH_CARTEIRA_FIDC,
} from 'routes/paths';
import NotificationDrawer from '../../../notification/views/notificationDrawer';

import MenuAsideButton from './menuAsideButton';
import AtualizarCadastroButton from './atualizarCadastroButton';

import './menuAside.scss';

const getNotificationClassName = (hasErrorNotification, hasSuccessNotification) => {
  if (hasErrorNotification) {
    return 'menu-aside-notification-error';
  }
  if (hasSuccessNotification) {
    return 'menu-aside-notification-success';
  }
  return '';
};

const MenuAside = ({
  location, navTo, permissions, setTdInitialPage,
  hasErrorNotification, hasSuccessNotification, hasLimitesPermission,
  isModalMensagensRedeOpen, getMensagensRede, hasFidcPermission, isBrand,
}) => {
  useEffect(() => {
    getMensagensRede();
    const intervalId = setInterval(() => {
      getMensagensRede();
    }, 300000);
    return () => clearInterval(intervalId);
  }, [getMensagensRede]);
  const [openNotification, setOpenNotification] = React.useState(false);
  return (
    <>
      {isModalMensagensRedeOpen ? <MensagemRede /> : null}
      <section className="menu-aside no-print">
        <Scrollbars
          autoHeight
          autoHeightMax="100vh"
          autoHeightMin="100vh"
          renderThumbHorizontal={() => (<div style={{ display: 'none' }} />)}
        >
          <div className="menu-aside__itens-container">
            <div className="menu-aside__stellantis-logo">
              <IconeStellantisBanco className="menu-aside__stellantis-logo__icon" />
            </div>
            <div className="menu-aside__buttons" data-cy="MenuAside">
              <MenuAsideButton
                onClick={() => {
                  Tracking.Mixpanel.trackMenuClick('Carteira Floor Plan');
                  if (permissions.visualizarDuplicatas === true) {
                    navTo(PATH_INVOICES);
                  } else if (permissions.visualizarDuplicatasB === true) {
                    navTo(PATH_CARTEIRA);
                  }
                }}
                active={location.pathname === PATH_CARTEIRA || location.pathname === PATH_INVOICES}
                visible={permissions.visualizarDuplicatas === true
                  || permissions.visualizarDuplicatasB === true}
                icon={<FloorPlanIcon />}
                label="Carteira Floor Plan"
              />

              <MenuAsideButton
                onClick={() => {
                  Tracking.Mixpanel.trackMenuClick('FIDC');
                  navTo(PATH_CARTEIRA_FIDC);
                }}
                visible={hasFidcPermission && isBrand.FIAT}
                active={!!useRouteMatch(`${PATH_CARTEIRA_FIDC}/*`)}
                icon={<FidcIcon />}
                label="FIDC"
                heightFidc="54px"
              />

              <MenuAsideButton
                onClick={() => {
                  Tracking.Mixpanel.trackMenuClick('Movimentações');
                  if (permissions.visualizarMovimentacoes === true) {
                    navTo(PATH_STATEMENT);
                  } else if (permissions.visualizarMovimentacoesB === true) {
                    navTo(PATH_STATEMENT_B);
                  }
                }}
                active={location.pathname === PATH_PAYMENTS
                  || location.pathname === PATH_STATEMENT
                  || location.pathname === PATH_STATEMENT_B}
                visible={permissions.visualizarMovimentacoes === true
                  || permissions.visualizarMovimentacoesB === true}
                icon={<BalanceIcon />}
                label="Recursos Disponíveis"
              />

              <MenuAsideButton
                onClick={() => {
                  Tracking.Mixpanel.trackMenuClick('Simulador IOF');
                  navTo(PATH_IOF_SIMULATOR);
                }}
                active={location.pathname === PATH_IOF_SIMULATOR}
                icon={<SimuladorIcon />}
                visible={permissions.simularIof === true}
                label="Simulador de Pgto."
              />

              <MenuAsideButton
                onClick={() => {
                  Tracking.Mixpanel.trackMenuClick('TestDrive');
                  setTdInitialPage();
                  navTo(PATH_PRODUCTS);
                }}
                active={location.pathname === PATH_PRODUCTS}
                icon={<DirectionsCarIcon />}
                label="Solicitar Veículo"
                visible={permissions.solicitarTestDrive === true}
              />

              <MenuAsideButton
                onClick={() => {
                  Tracking.Mixpanel.trackMenuClick('Limite');
                  navTo(PATH_SOLICITACAO_CREDITO);
                }}
                active={!!useRouteMatch(`${PATH_SOLICITACAO_CREDITO}/*`)}
                icon={<LimiteIcon color="white" />}
                label="Limites"
                visible={hasLimitesPermission}
              />

              <MenuAsideButton
                onClick={() => {
                  Tracking.Mixpanel.trackMenuClick('Dashboard');
                  navTo(PATH_DASHBOARD);
                }}
                active={!!useRouteMatch(`${PATH_DASHBOARD}/*`)}
                icon={<DashboardIcon />}
                label="Dashboards"
                visible={permissions.dashboardFloorPlan === true}
              />
              <MenuAsideButton
                onClick={() => {
                  Tracking.Mixpanel.trackMenuClick('Central Comunicados');
                  navTo(PATH_CENTRAL);
                }}
              active={!!useRouteMatch(`${PATH_CENTRAL}*`)}
              icon={<FolderIcon />}
                label="Central de Comunicados"
                visible={permissions.centralComunicacao === true}
              />

              <MenuAsideButton
                onClick={() => {
                  Tracking.Mixpanel.trackMenuClick('Relatórios IOF');
                  navTo(PATH_RELATORIOS_IOF);
                }}
              active={!!useRouteMatch(`${PATH_RELATORIOS_IOF}*`)}
              icon={<Download color="white" />}
                label="Relatórios"
                visible={permissions.relatoriosIof === true}
              />

              <AtualizarCadastroButton permissions={permissions} />

            </div>

            <div className="menu-aside__footer">

              <Tooltip placement="right" title="Notificações">
                <div className="menu-aside-item menu-aside-notication-center">
                  <BellIcon
                    onClick={() => setOpenNotification(!openNotification)}
                  />
                  <div className={
                getNotificationClassName(hasErrorNotification, hasSuccessNotification)
              }
                  />
                </div>
              </Tooltip>

              <Tooltip placement="right" title="Sair">
                <div className="menu-aside-item">
                  <ExitIcon onClick={() => {
                    Tracking.Mixpanel.trackLogout();
                    Auth.logout();
                  }}
                  />
                </div>
              </Tooltip>
            </div>
          </div>
        </Scrollbars>
      </section>
      <NotificationDrawer
        isOpen={openNotification}
        onClose={() => setOpenNotification(false)}
      />
    </>
  );
};

MenuAside.propTypes = {
  location: PropTypes.object.isRequired,
  navTo: PropTypes.func.isRequired,
  permissions: PropTypes.object.isRequired,
  hasErrorNotification: PropTypes.bool.isRequired,
  hasSuccessNotification: PropTypes.bool.isRequired,
  hasLimitesPermission: PropTypes.bool.isRequired,
  setTdInitialPage: PropTypes.func,
  isModalMensagensRedeOpen: PropTypes.bool,
  getMensagensRede: PropTypes.func.isRequired,
  hasFidcPermission: PropTypes.bool.isRequired,
  isBrand: PropTypes.shape({
    FIAT: PropTypes.bool,
  }),
};

MenuAside.defaultProps = {
  setTdInitialPage: () => {},
  isModalMensagensRedeOpen: false,
  isBrand: PropTypes.shape({
    FIAT: false,
  }),
};

export default MenuAside;
