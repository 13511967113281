import { connect } from 'react-redux';

import SolicitacaoLimite from './solicitacaoLimite';

const mapStateToProps = ({ authStore }) => ({
  user: authStore.user,
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(SolicitacaoLimite);
