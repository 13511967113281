export const getSelectedOffer = (state) => {
  const { offers, selectedOfferId } = state;
  return (offers || []).find((o) => o.id === selectedOfferId);
};

export const getSelectedCondition = (state) => {
  const { conditions, selectedConditionId } = state;
  if (!conditions || !selectedConditionId) {
    return undefined;
  }

  return conditions.find((c) => c.id === selectedConditionId);
};

export const getInstallmentConditions = (state) => {
  const { conditions } = state;

  return (conditions || []).filter((c) => !c.isAVista);
};

export const getAVistaConditions = (state) => {
  const { conditions } = state;

  return (conditions || []).filter((c) => c.isAVista);
};

export const isErrorUpload = (state) => {
  const { selectedFiles } = state;
  return ((selectedFiles || []).filter((f) => f.isError === true).length > 0);
};

export const dealersAuthorized = (state, dealerState) => {
  const { offers, selectedOfferId } = state.pedido;
  const { dealers } = dealerState;
  const selectedOffer = offers.filter((offer) => offer.id === selectedOfferId);
  const dealersExceptionList = selectedOffer[0].concessionariasPermitidas;
  return dealersExceptionList
    ? dealers.filter((dealer) => dealersExceptionList.indexOf(dealer.dealerId) > -1)
    : dealers;
};
