import * as React from "react";

const DodgeIcon = (props) => {
	return (
		<svg focusable="false" aria-hidden="true" width="19" height="12" {...props}>
			<path d="M0 4.46809C1.53226 4.46809 3.00323 4.46809 4.53548 4.46809C4.53548 5.93617 4.53548 7.34043 4.53548 9C7.17097 8.87234 9.68387 8.93617 12.1355 8.61702C13.9742 8.3617 14.771 6.76596 14.2194 5.04255C13.9129 4.02128 13.1774 3.51064 12.2581 3.25532C11.4613 3.06383 10.6645 3 9.86774 3C6.61936 3 3.37097 3 0.0612903 3C0.0612903 1.97872 0.0612903 1.02128 0.0612903 0.0638298C0.122581 0.0638298 0.183871 0 0.245161 0C4.71936 0.0638298 9.19355 0.12766 13.6677 0.255319C14.4645 0.255319 15.2613 0.638298 15.9968 0.957447C18.0194 1.91489 19 3.6383 19.0613 5.87234C19.0613 8.17021 18.2032 10.0213 16.0581 10.9149C14.8935 11.4255 13.6677 11.8085 12.4419 11.8723C8.51935 12 4.65806 12 0.735484 12C0.490323 12 0.0612903 11.6809 0.0612903 11.4894C0 9.12766 0 6.82979 0 4.46809Z" fill="white" />
		</svg>
	);
}

export default DodgeIcon;
