import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { textField } from 'features/common-staff/controls/input/inputStyles';
import FormInput from '../../../../../../../common-staff/controls/input/formInput';

const useStyles = makeStyles({
  ...textField,
  container: {
    ...textField.container,
    marginLeft: '5px !important',
  },
});

const InputPagamento = ({
  value, setValue, disabled, invoiceId,
}) => {
  const classes = useStyles();
  return (
    <div
      className={classes.container}
      data-cy="input-pagamento"
    >
      <FormInput
        value={value}
        setValue={(newValue) => setValue(newValue, invoiceId)}
        type="currency"
        disabled={disabled}
      />
    </div>
  );
};

InputPagamento.propTypes = {
  value: PropTypes.number,
  setValue: PropTypes.func,
  disabled: PropTypes.bool,
  invoiceId: PropTypes.number,
};

InputPagamento.defaultProps = {
  value: 0,
  setValue: () => {},
  disabled: true,
  invoiceId: null,
};

export default InputPagamento;
