import { connect } from 'react-redux';
import CardsResumo from './cardsResumo';

import Operations from '../../redux/operations';
import Selectors from '../../redux/selectors';

const mapStateToProps = ({ dashboard, filters }) => ({
  isLoadingResumo: dashboard.painelFloorPlan?.cardsResumo?.isLoading,
  isErrorResumo: dashboard.painelFloorPlan?.cardsResumo?.isError,
  data: dashboard.painelFloorPlan?.cardsResumo?.data,
  dataForDonut: Selectors.getDonutData(dashboard).dataset,
  filters,
});

const mapDispatchToProps = (dispatch) => ({
  getResumo: (filters) => dispatch(Operations.getCardsResumo(filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardsResumo);
