/* eslint-disable no-tabs */
/* eslint-disable new-cap */
import moment from 'moment';
import logger from 'utils/logger';
import 'moment/locale/pt-br';
import { Tracking } from 'modules';

import actions from './actions';
import Service from './service';
import { CardVencimentoType } from './enum';
import Operation from '../../../gestaoPagamentos/views/carteira/carteiraPage/redux/operations';

const resetStore = () => (dispatch) => {
  dispatch(actions.resetStore());
};

const setFilter = (paramName, value) => (dispatch) => {
  dispatch(actions.setFilter(paramName, value));
};

const setFilterType = (type) => (dispatch) => {
  dispatch(actions.setFilterType(type));
};

const disableFilterButton = () => (dispatch) => {
  dispatch(actions.disableFilterButton());
};

const getResumoCards = () => async (dispatch, getState) => {
  try {
    dispatch(actions.getCardsResumoStart());
    const { filters } = getState().dashboard.painelFloorPlan;
    const resumo = await Service.getCardsResumo(filters);
    dispatch(actions.getCardsResumoSuccess(resumo));
  } catch (error) {
    dispatch(actions.getCardsResumoError());
    logger.error(error);
  }
};

const getFiltrosProdutos = (flag) => async (dispatch, getState) => {
  try {
    dispatch(actions.getProdutosStart());
    const { filters } = getState().dashboard.painelFloorPlan;
    const response = await Service.getFiltroProdutos(filters);
    const filterProdutos = response.map((r) => ({
      value: r.ids,
      text: r.description,
    }));
    if (filters.selectedProduto?.length === 0) { dispatch(setFilter('selectedProduto', filterProdutos)); }
    dispatch(actions.getProdutosSuccess(filterProdutos));
    if (flag) {
      dispatch(actions.disableFilterButton());
    }
  } catch (error) {
    dispatch(actions.getProdutosError());
    dispatch(actions.disableFilterButton());
    logger.error(error);
  }
};

const getCardsVencimento = () => async (dispatch, getState) => {
  try {
    dispatch(actions.getCardsVencimentoStart());
    const { filters } = getState().dashboard.painelFloorPlan;
    const response = await Service.getCardsVencimento(filters);
    dispatch(actions.getCardsVencimentoSuccess(response));
  } catch (error) {
    dispatch(actions.getCardsVencimentoError());
    logger.error(error);
  }
};

const getDuplicatasValor = () => async (dispatch, getState) => {
  try {
    dispatch(actions.getValoresChartStart());
    const { filters } = getState().dashboard.painelFloorPlan;
    const response = await Service.getValorDuplicatas(filters);
    dispatch(actions.getValoresChartSuccess(response));
  } catch (error) {
    dispatch(actions.getValoresChartError());
    logger.error(error);
  }
};

const getCarteiraProduto = () => async (dispatch, getState) => {
  try {
    dispatch(actions.getCarteiraProdutoStart());
    const { filters } = getState().dashboard.painelFloorPlan;
    const response = await Service.getCarteiraProduto(filters);
    dispatch(actions.getCarteiraProdutoSuccess(response));
  } catch (error) {
    dispatch(actions.getCarteiraProdutoError());
    logger.error(error);
  }
};

const getCarteiraBrand = () => async (dispatch, getState) => {
  try {
    dispatch(actions.getCarteiraBrandStart());
    const { filters } = getState().dashboard.painelFloorPlan;
    const response = await Service.getCarteiraBrand(filters);
    dispatch(actions.getCarteiraBrandSuccess(response));
  } catch (error) {
    dispatch(actions.getCarteiraBrandError());
    logger.error(error);
  }
};

const getQuantidadeDuplicatas = () => async (dispatch, getState) => {
  try {
    dispatch(actions.getDuplicatasChartStart());
    const { filters } = getState().dashboard.painelFloorPlan;
    const response = await Service.getQuantidadeDuplicatas(filters);
    dispatch(actions.getDuplicatasChartSuccess(response));
  } catch (error) {
    dispatch(actions.getDuplicatasChartError());
    logger.error(error);
  }
};

const setCarteiraFilters = (tipo) => async (dispatch) => {
  try {
    if (tipo === CardVencimentoType.vencidas) {
      Tracking.Mixpanel.dashboard.duplicatasVencidas.trackDuplicatasVencidas();
      await dispatch(Operation.resetStore());
      await dispatch(Operation.setIsRedirect(true));
      await dispatch(Operation.setFilter('status', 3));
      await dispatch(Operation.setFilter('vencimentoInicio', null));
      await dispatch(Operation.setFilter('vencimentoFim', null));
      await dispatch(Operation.getCarteira());
    }
    if (tipo === CardVencimentoType.actual) {
      Tracking.Mixpanel.dashboard.duplicatasVencidas.trackDuplicatasVencimentoHoje();
      await dispatch(Operation.resetStore());
      await dispatch(Operation.setIsRedirect(true));
      await dispatch(Operation.setFilter('status', 1));
      await dispatch(Operation.setFilter('vencimentoInicio', new moment()));
      await dispatch(Operation.setFilter('vencimentoFim', new moment()));
      await dispatch(Operation.getCarteira());
    }
    if (tipo === CardVencimentoType.dez_dias) {
      Tracking.Mixpanel.dashboard.duplicatasVencidas.trackDuplicatasVencimentoDezDias();
      await dispatch(Operation.resetStore());
      await dispatch(Operation.setIsRedirect(true));
      await dispatch(Operation.setFilter('status', 1));
      await dispatch(Operation.setFilter('vencimentoInicio', new moment()));
      await dispatch(Operation.setFilter('vencimentoFim', new moment().add(10, 'days')));
      await dispatch(Operation.getCarteira());
    }
    if (tipo === CardVencimentoType.vinte_dias) {
      Tracking.Mixpanel.dashboard.duplicatasVencidas.trackDuplicatasVencimentoVinteDias();
      await dispatch(Operation.resetStore());
      await dispatch(Operation.setIsRedirect(true));
      await dispatch(Operation.setFilter('status', 1));
      await dispatch(Operation.setFilter('vencimentoInicio', new moment()));
      await dispatch(Operation.setFilter('vencimentoFim', new moment().add(20, 'days')));
      await dispatch(Operation.getCarteira());
    }
  } catch (error) {
    logger.error(error);
  }
};

const forceReload = () => (dispatch) => {
  dispatch(getCardsVencimento());
  dispatch(getResumoCards());
  dispatch(getCarteiraProduto());
  dispatch(getCarteiraBrand());
  dispatch(getDuplicatasValor());
  dispatch(getQuantidadeDuplicatas());
};

const isUpdated = () => async (dispatch) => {
  try {
    dispatch(actions.setIsLoading());
    const isUpdate = await Service.isUpdated();
    dispatch(actions.setIsUpdated(isUpdate));
    if (isUpdate) {
      dispatch(forceReload());
    }
  } catch (error) {
    logger.error(error);
  }
};

const resetIsUpdated = () => (dispatch) => {
  dispatch(actions.setIsUpdated(true));
};

export default {
  resetStore,
  setFilter,
  forceReload,
  setFilterType,
  getResumoCards,
  disableFilterButton,
  getFiltrosProdutos,
  getCardsVencimento,
  getCarteiraProduto,
  getCarteiraBrand,
  getDuplicatasValor,
  getQuantidadeDuplicatas,
  setCarteiraFilters,
  isUpdated,
  resetIsUpdated,
};
