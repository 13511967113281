import { combineReducers } from 'redux';

import acompanhamentoReducer from '../acompanhamentoPage/redux';
import solicitacaoReducer from '../solicitacaoPage/redux';

import { PAGE_PRODUTO } from './operations';
import * as actions from './actions';

const INITIAL_STATE = {
  page: PAGE_PRODUTO,
  products: {
    isLoading: false,
    isError: false,
    data: null,
    selectedCategoryId: null,
    selectedProductId: null,
  },
};

const mainReducer = (state = INITIAL_STATE, action = { type: 'default' }) => {
  switch (action.type) {
    case actions.types.SET_PAGE:
      return {
        ...state,
        page: action.payload.page,
      };
    case actions.types.LOAD_PRODUCTS_START:
      return {
        ...state,
        products: {
          ...state.products,
          isLoading: true,
          isError: false,
        },
      };
    case actions.types.LOAD_PRODUCTS_ERROR:
      return {
        ...state,
        products: {
          ...state.products,
          isLoading: false,
          isError: true,
          data: null,
          selectedId: null,
        },
      };
    case actions.types.LOAD_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: {
          ...state.products,
          isLoading: false,
          isError: false,
          data: action.payload.products,
          selectedId: null,
        },
      };
    case actions.types.SET_SELECTED_CATEGORY:
      return {
        ...state,
        products: {
          ...state.products,
          selectedCategoryId: action.payload.id,
        },
      };
    case actions.types.SET_SELECTED_PRODUCT:
      return {
        ...state,
        products: {
          ...state.products,
          selectedProductId: action.payload.id,
        },
      };
    case actions.types.RESET_SELECTIONS:
      return {
        ...state,
        products: {
          selectedCategoryId: null,
          selectedProductId: null,
        },
      };
    default:
      return state;
  }
};

export default combineReducers({
  main: mainReducer,
  acompanhamento: acompanhamentoReducer,
  pedido: solicitacaoReducer,
});
