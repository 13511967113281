import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { Check, Loader, X } from 'react-feather';
import colors from 'assets/styles/colors';

const useStyles = makeStyles({
  container: {
    width: '15px',
    height: '15px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    borderRadius: '100%',
    backgroundColor: (props) => {
      switch (props.situacao) {
        case 'em_andamento':
        case 'aprovado':
          return colors.primary_color_600;
        case 'reprovado':
          return colors.error_color_300;
        default:
          return colors.secundary_color_600;
      }
    },
  },
});

const selectIcon = (situacao) => {
  const iconStyle = { color: 'white', width: '10px' };
  switch (situacao) {
    case 'aprovado':
      return <Check style={iconStyle} />;
    case 'reprovado':
      return <X style={iconStyle} />;
    case 'em_andamento':
      return <Loader style={iconStyle} />;
    default:
      return null;
  }
};

const TimelineCircle = ({ situacao }) => {
  const classes = useStyles({ situacao });

  return (
    <div className={classes.container}>
      {selectIcon(situacao)}
    </div>
  );
};

TimelineCircle.propTypes = {
  situacao: PropTypes.string,
};

TimelineCircle.defaultProps = {
  situacao: null,
};

export default TimelineCircle;
