import React from 'react';
import BreadCrumb from 'features/common-staff/layout/breadCrumb';

const BreadcrumbRelatorios = () => (
  <BreadCrumb
    labels={[{
      label: 'Carteira Floor Plan',
      path: '/carteira',
    },
    {
      label: 'Relatórios',
      path: '',
    },
    ]}
  />
);

export default BreadcrumbRelatorios;
