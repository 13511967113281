import React from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import moment from 'moment';
import 'moment/locale/pt-br';
import omit from 'lodash/omit';
import { DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import OutsideClickHandler from 'react-outside-click-handler';
import NewCalendarIcon from 'assets/icons/new-calendar';
import ArrowContainer from './arrowContainer/arrowContainer';
import {
  utilizeFocus,
  getColorSelectedLabel,
  getDateFromEvent,
  getMessageError,
} from './helper';
import NewDatepickerStyles, {
  Button,
  ButtonContainer,
  ContainerDate,
  VerticalLine,
  InputError,
  Title,
  FieldContainer,
  InputDate,
  ButtonCalendar,
  Input,
  InputContainer,
} from './newDatePicker.style';
import './newDatePicker.scss';

const invalidDate = (
  invalidStartDate,
  invalidEndDate,
  setInvalidDate,
  startDate,
  endDate,
  focusedInput,
) => {
  const invalid = invalidStartDate || invalidEndDate;
  if (invalid !== undefined) {
    setInvalidDate(invalid);
  }
  if (((startDate && !endDate) || (!startDate && endDate)) && !focusedInput) {
    setInvalidDate(true);
    return true;
  }

  return invalid;
};

class NewDatepicker extends React.Component {
  inputFocus;

  constructor(props) {
    super(props);
    this.inputFocus = utilizeFocus();

    this.state = {
      focusedInput: null,
      startDate: props.initialStartDate,
      datePickerOpen: false,
    };

    this.onDatesChange = this.onDatesChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
  }

  onDatesChange({ startDate, endDate }) {
    const { setEndDate, setStartDate } = this.props;
    this.setState({
      startDate,
      endDate,
      startDateString: startDate ? startDate.format('DD/MM/YYYY') : null,
      endDateString: endDate ? endDate.format('DD/MM/YYYY') : null,
      invalidStartDate: false,
      invalidEndDate: false,
      focusedInput: startDate > endDate ? 'endDate' : null,
      isCleared: false,
    });

    setStartDate(startDate);
    setEndDate(endDate);
  }

  onEndDateChange(event) {
    const { setEndDate } = this.props;
    const { startDate } = this.state;
    const date = getDateFromEvent(event.target.value);
    this.setState({
      endDate: date || '',
      endDateString: event.target.value,
      focusedInput: startDate > date ? 'endDate' : null,
      isCleared: false,
    });
    setEndDate(date);
  }

  onStartDateChange(event) {
    const { setStartDate } = this.props;
    const date = getDateFromEvent(event.target.value);
    this.setState({
      startDate: date || '',
      startDateString: event.target.value,
      focusedInput: date ? 'endDate' : 'startDate',
      isCleared: false,
    });

    setStartDate(date);

    if (date) this.inputFocus.setFocus();
  }

  onFocusChange(focusedInput) {
    const { closeDrawer } = this.props;
    if (focusedInput) {
      this.setState({
        focusedInput,
        datePickerOpen: true,
      });
      closeDrawer();
    } else {
      this.setState({
        datePickerOpen: false,
      });
    }
  }

  onOpenDatePicker(focusedInput) {
    const { datePickerOpen } = this.state;
    const { closeDrawer, tracking } = this.props;
    this.setState({
      datePickerOpen: !datePickerOpen,
      focusedInput: !datePickerOpen ? focusedInput : null,
    });
    tracking();
    closeDrawer();
  }

  validateStartDate = (dateInput) => {
    const date = getDateFromEvent(dateInput);
    let invalidStartDate = false;

    if (!date && (dateInput !== '')) {
      invalidStartDate = true;
    }
    this.setState({
      invalidStartDate,
    });
  };

  validateEndDate(dateInput) {
    const date = getDateFromEvent(dateInput);
    const { startDate } = this.state;
    let invalidEndDate = false;
    if (date < startDate) {
      invalidEndDate = true;
    }

    if (!date && (dateInput !== '')) {
      invalidEndDate = true;
    }

    this.setState({
      invalidEndDate,
    });
  }

  resetDate() {
    const { setStartDate, setEndDate } = this.props;
    this.setState({
      startDateString: undefined,
      endDateString: undefined,
      startDate: undefined,
      endDate: undefined,
      invalidStartDate: false,
      invalidEndDate: false,
      isCleared: true,
    });

    setStartDate(null);
    setEndDate(null);
  }

  render() {
    const {
      showInputs,
      title,
      disabled,
      numberOfMonths,
      isOutsideRange,
      setInvalidDate,
      invalidDateProp,
      dataCy,
    } = this.props;
    const {
      focusedInput,
      startDate,
      endDate,
      startDateString,
      endDateString,
      invalidStartDate,
      invalidEndDate,
      isCleared,
      datePickerOpen,
    } = this.state;
    const props = omit(this.props, [
      'initialStartDate', 'initialEndDate',
      'setStartDate', 'setEndDate',
      'setInvalidDate', 'invalidDateProp',
      'showInputs',
      'dataCy',
      'title',
      'closeDrawer',
    ]);

    moment.locale('pt-br', {
      months: 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_'),
      monthsShort: 'jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez'.split('_'),
      weekdays: 'domingo_segunda-feira_terça-feira_quarta-feira_quinta-feira_sexta-feira_sábado'.split('_'),
      weekdaysShort: 'Dom_Seg_Ter_Qua_Qui_Sex_Sáb'.split('_'),
      weekdaysMin: 'Dom_Seg_Ter_Qua_Qui_Sex_Sáb'.split('_'),
    });

    const buttonColor = (startDate !== null && startDate !== undefined)
    || (endDate !== null && endDate !== undefined);

    const inputProps = {
      focusedInput,
      invalidStartDate,
      invalidEndDate,
      startDate,
      endDate,
    };

    return (
      <NewDatepickerStyles {...inputProps}>
        <OutsideClickHandler
          onOutsideClick={
            () => this.setState({
              focusedInput: null,
              datePickerOpen: false,
            })
          }
        >
          {showInputs && (
            <FieldContainer>
              <Title invalidDateProp={invalidDateProp}>
                {title}
              </Title>
              <InputContainer {...inputProps}>
                <InputDate paddingLeft="10px" fixed="80px">
                  <Input
                    {...inputProps}
                    onFocus={() => this.onFocusChange('startDate')}
                    type="text"
                    name="start date"
                    disabled={disabled}
                    value={isCleared ? '' : startDateString}
                    placeholder="dd/mm/aaaa"
                    onChange={(e) => this.onStartDateChange(e)}
                    onBlur={(e) => this.validateStartDate(e.target.value)}
                    data-cy={dataCy ? `${dataCy}StartDate` : null}
                  />
                </InputDate>
                <VerticalLine />
                <InputDate paddingLeft={endDate ? '10px' : '6px'}>
                  <Input
                    {...inputProps}
                    ref={this.inputFocus.ref}
                    onFocus={() => this.onFocusChange('endDate')}
                    type="text"
                    name="end date"
                    disabled={disabled}
                    value={isCleared ? '' : endDateString}
                    placeholder="dd/mm/aaaa"
                    onChange={(e) => this.onEndDateChange(e)}
                    onBlur={(e) => this.validateEndDate(e.target.value)}
                    data-cy={dataCy ? `${dataCy}EndDate` : null}
                  />
                </InputDate>
                <ButtonCalendar
                  data-cy={dataCy ? `${dataCy}Open` : null}
                  type="button"
                  onClick={() => this.onOpenDatePicker('startDate')}
                >
                  <NewCalendarIcon color={getColorSelectedLabel({ ...inputProps })} />
                </ButtonCalendar>
              </InputContainer>
              {(invalidDate(
                invalidStartDate,
                invalidEndDate,
                setInvalidDate,
                startDate,
                endDate,
                focusedInput,
              )) && (
                <InputError>
                  {getMessageError(
                    invalidStartDate,
                    invalidEndDate,
                    startDate,
                    endDate,
                  )}
                </InputError>
              )}
            </FieldContainer>
          )}
          {focusedInput && datePickerOpen && (
          <ContainerDate marginTop={(invalidDate)} data-cy={`${dataCy}Calendar`}>
            <DayPickerRangeController
              {...props}
              onDatesChange={this.onDatesChange}
              onFocusChange={this.onFocusChange}
              focusedInput={focusedInput}
              startDate={startDate}
              endDate={endDate}
              initialVisibleMonth={null}
              navPrev={<ArrowContainer margin="22px" icon />}
              navNext={<ArrowContainer margin={numberOfMonths === 1 ? '81%' : '90%'} />}
              disabled={disabled}
              numberOfMonths={numberOfMonths}
              isOutsideRange={(day) => (isOutsideRange ? day.isBefore(moment().subtract(1, 'days')) : false)}
            />
            <ButtonContainer>
              <Button
                backgroundColor={buttonColor}
                onClick={() => this.resetDate()}
              >
                Limpar
              </Button>
            </ButtonContainer>
          </ContainerDate>
          )}
        </OutsideClickHandler>
      </NewDatepickerStyles>
    );
  }
}

NewDatepicker.propTypes = {
  initialStartDate: momentPropTypes.momentObj,
  initialEndDate: momentPropTypes.momentObj,
  showInputs: PropTypes.bool,
  numberOfMonths: PropTypes.number,
  title: PropTypes.string,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  disabled: PropTypes.bool,
  isOutsideRange: PropTypes.bool,
  setInvalidDate: PropTypes.func,
  invalidDateProp: PropTypes.bool,
  closeDrawer: PropTypes.func,
  dataCy: PropTypes.string,
  tracking: PropTypes.func,
};

NewDatepicker.defaultProps = {
  initialStartDate: null,
  initialEndDate: null,
  showInputs: true,
  numberOfMonths: 1,
  title: null,
  invalidDateProp: false,
  setEndDate: () => {},
  setStartDate: () => {},
  setInvalidDate: () => {},
  disabled: false,
  isOutsideRange: false,
  closeDrawer: () => {},
  dataCy: null,
  tracking: () => {},
};

export default NewDatepicker;
