import { connect } from 'react-redux';
import { operations } from '../redux';
import { DealerSelectors } from '../../../modules/dealers/redux';

import Saldo from './saldo';

const mapStateToProps = ({ balanceStore, dealerStore }) => ({
  creditoBloqueado: balanceStore.creditoBloqueado,
  creditoRemanescente: balanceStore.creditoRemanescente,
  ultimaAtualizacao: balanceStore.ultimaAtualizacao,
  atualizando: balanceStore.atualizando,
  visible: balanceStore.visible,
  dealer: DealerSelectors.getSelectedFPlanDealer(dealerStore),
});

const mapDispatchToProps = (dispatch) => ({
  getBalance: () => dispatch(operations.getBalanceInfo()),
  toggleVisibility: () => dispatch(operations.toggleVisibility()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Saldo);
