import React from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import colors from 'assets/styles/colors';
import PaymentReceiptDealerInfo from '../paymentReceiptDealerInfo';
import PaymentReceiptUserInfo from '../paymentReceiptUserInfo';
import PaymentReceiptTransactionInfo from '../paymentReceiptTransactionInfo';

import PaymentReceiptListing from '../paymentReceiptListing';

import './paymentReceiptBody.scss';

const CircularProgressHub = withStyles({
  root: {
    '& svg': {
      color: colors.primary_color_500
      ,
    },
  },
})(CircularProgress);

class PaymentReceiptBody extends React.Component {
  componentDidMount() {
    const {
      paymentId, getPaymentInformation,
    } = this.props;
    getPaymentInformation(paymentId);
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.lastGet = Date.now();
  }

  render() {
    const {
      className, transactions, userInfo, dealerInfo, transactionInfo, loading,
    } = this.props;
    return (
      <div className={className}>
        { !loading ? (
          <>
            <PaymentReceiptDealerInfo
              dealerInfo={dealerInfo}
              className="payment-receipt-body-dealer-info-block"
            />
            <PaymentReceiptUserInfo
              userInfo={userInfo}
              className="payment-receipt-body-user-info"
            />
            <PaymentReceiptTransactionInfo
              transactionInfo={transactionInfo}
              className="payment-receipt-body-pay-info"
            />
            <div className="payment-receipt-body-listing">
              <div className="payment-receipt-body-listing-tittle">TRANSAÇÕES</div>

              <PaymentReceiptListing
                transactions={transactions}
              />
            </div>
          </>
        ) : (
          <div>
            <CircularProgressHub
              className="payment-receipt-body-loading"
            />
          </div>
        )}
      </div>
    );
  }
}

PaymentReceiptBody.propTypes = {
  className: PropTypes.string,

  getPaymentInformation: PropTypes.func.isRequired,

  paymentId: PropTypes.number.isRequired,
  transactions: PropTypes.array.isRequired,
  userInfo: PropTypes.object.isRequired,
  dealerInfo: PropTypes.object.isRequired,
  transactionInfo: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

PaymentReceiptBody.defaultProps = {
  className: '',
};

export default PaymentReceiptBody;
