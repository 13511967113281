import styled from 'styled-components';
import { Button } from 'features/common';

export const Container = styled.div`
`;

export const ContainerSelector = styled.div``;

export const FilterRow = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: repeat(${(props) => (props.isOpen ? '2' : '4')}, 224px);
  gap: 8px 24px;

  .duplicataConcessionaria {
    grid-row: 1;
    grid-column: 2;
    display: ${(props) => (props.isOpen && 'none')};
  }

  .duplicataStatus {
    grid-row: 2;
    grid-column: 2;
    display: ${(props) => (props.isOpen && 'none')};
  }

  .valorNotaFiscal {
    grid-row: 2;
    grid-column: 1;
    display: ${(props) => (props.isOpen && 'none')};
  }

  .buttonContainer {
    grid-row: 2;
    grid-column: ${(props) => (props.isOpen ? '2' : '3')} / span 2;
  }

  .dataVencimento {
    grid-row: ${(props) => (props.isOpen ? 2 : 1)};
    grid-column: ${(props) => (props.isOpen ? 1 : 3)};
  }

`;

export const Search = styled.div`
  min-width: 180px;
`;

export const Apply = styled.div`
  width: 135px;
  > .common__filters__botao-aplicar {
    width: 100% !important
  }
`;

export const ClearButton = styled(Button)`
  width: 135px;
  height: 40px;
  color: #FFFFFF;
  background-color: #505669;

  :hover {
    background-color: #3C414E !important;
  }
  :active {
    background-color: #282B34 !important;
  }
`;

export const DateContainer = styled.div``;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: left;
  align-items: end;
`;

export const ShowFiltersButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #243782;
  background-color: #F7F9FC;
  padding: 8px;
  border-radius: 4px;
  :hover {
    cursor: pointer;
    background-color: #E6EAF8;
  }
`;

export const AggregateContainer = styled.div``;
