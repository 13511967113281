import React from 'react';
import PropTypes from 'prop-types';

import MarcaBadge from 'features/common-staff/views/marcaBadge';
import { formatDate } from 'utils/format';
import FileIcon from '../../../../../../assets/icons/file';
import BotaoDownload from '../../botaoDownload';

import './cards.scss';

const Cards = ({ documento }) => (
  <div
    className="card__container-comunicados"
    data-cy="CardContainer"
  >
    <div
      className="card__container-comunicados__header"
      data-cy="CardContainerHeader"
    >
      <div className="card__container-comunicados__header__logo">
        <MarcaBadge marca={documento?.documentoTipo === 3 ? 'chrysler' : 'fiat'} />
      </div>
      <small className="card__container-comunicados__header__data-emissao">
        {formatDate(documento?.dataEmissao, 'DD MMM yyyy')}
      </small>
    </div>

    <div
      className="card__container-comunicados__arquivo"
      data-cy="CardContainerFile"
    >
      <FileIcon />
      <p title={documento?.fileName}>
        {documento?.fileName}
      </p>
    </div>
    <div
      className="card__container-comunicados__button"
      data-cy="CardContainerButton"
    >
      <BotaoDownload
        documento={documento?.fileURL}
      />
    </div>
  </div>
);

Cards.propTypes = {
  documento: PropTypes.object,
};

Cards.defaultProps = {
  documento: null,
};

export default Cards;
