import { connect } from 'react-redux';

import * as operations from './redux/operations';
import AlteracaoLimitePage from './alteracaoLimitePage';

const mapStateToProps = ({ limiteCreditoStore }) => ({
  snackbarErrors: limiteCreditoStore.alteracaoLimite.snackbarErrors,
});

const mapDispatchToProps = (dispatch) => ({
  onSnackbarClose: (id) => dispatch(operations.dismissSnackbar(id)),
  getProdutos: () => dispatch(operations.getProdutos()),
  resetStore: () => { dispatch(operations.resetStore()); },
});

export default connect(mapStateToProps, mapDispatchToProps)(AlteracaoLimitePage);
