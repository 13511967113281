const PREFIX = 'carteiraPage';
const PREFIX_GET_INVOICES = `${PREFIX}/PREFIX_GET_INVOICES`;

const SET_FILTERS = `${PREFIX}/SET_FILTERS`;
const SET_PAGE_PARAMS = `${PREFIX}/SET_PAGE_PARAMS`;
const RESET_STORE = `${PREFIX}/RESET_STORE`;
const SELECT_TO_PAYMENT = `${PREFIX}/SELECT_TO_PAYMENT`;
const SELECT_ALL = `${PREFIX}/SELECT_ALL`;
const REMOVE_ALL = `${PREFIX}/REMOVE_ALL`;
const SET_SELECTED_ALL_FALSE = `${PREFIX}/SET_SELECTED_ALL_FALSE`;
const SET_IS_REDIRECT = `${PREFIX}/SET_IS_REDIRECT`;
const DISMISS_SNACKBARS = `${PREFIX}/DISMISS_SNACKBARS`;
const SET_EXPORT_MODAL = `${PREFIX}/SET_EXPORT_MODAL`;

const GET_INVOICES_SUCCESS = `${PREFIX_GET_INVOICES}/GET_INVOICE_SUCCESS`;
const GET_INVOICES_ERROR = `${PREFIX_GET_INVOICES}/GET_INVOICES_ERROR`;
const GET_INVOICES_START = `${PREFIX_GET_INVOICES}/GET_INVOICES_START`;

const EXPORT_CARTEIRA_START = `${PREFIX}/EXPORT_CARTEIRA_START`;
const EXPORT_CARTEIRA_SUCCESS = `${PREFIX}/EXPORT_CARTEIRA_SUCCESS`;
const EXPORT_CARTEIRA_ERROR = `${PREFIX}/EXPORT_CARTEIRA_ERROR`;

const setFilters = (propertyName, value) => ({
  type: SET_FILTERS,
  payload: { propertyName, value },
});

const setPageParams = (propertyName, value) => ({
  type: SET_PAGE_PARAMS,
  payload: { propertyName, value },
});

const selectAll = () => ({
  type: SELECT_ALL,
});

const selectToPayment = (id) => ({
  type: SELECT_TO_PAYMENT,
  payload: { id },
});

const resetStore = () => ({
  type: RESET_STORE,
});

const removeAll = () => ({
  type: REMOVE_ALL,
});

const setSelectedAllFalse = () => ({
  type: SET_SELECTED_ALL_FALSE,
});

const setIsRedirect = (value) => ({
  type: SET_IS_REDIRECT,
  payload: { value },
});

const getInvoicesStart = () => ({
  type: GET_INVOICES_START,
});

const getInvoicesSuccess = (invoices, filtersParams, pageParams, isUpdated) => ({
  type: GET_INVOICES_SUCCESS,
  payload: {
    invoices, filtersParams, pageParams, isUpdated,
  },
});

const getInvoicesError = () => ({
  type: GET_INVOICES_ERROR,
});

const exportCarteiraStart = () => ({
  type: EXPORT_CARTEIRA_START,
});

const exportCarteiraSuccess = () => ({
  type: EXPORT_CARTEIRA_SUCCESS,
});

const exportCarteiraError = () => ({
  type: EXPORT_CARTEIRA_ERROR,
});

const dismissSnackbars = (id) => ({
  type: DISMISS_SNACKBARS,
  payload: { id },
});

const setExportModal = (value) => ({
  type: SET_EXPORT_MODAL,
  payload: { value },
});

export default {
  types: {
    SET_PAGE_PARAMS,
    SET_FILTERS,
    SELECT_TO_PAYMENT,
    RESET_STORE,
    SELECT_ALL,
    REMOVE_ALL,
    SET_SELECTED_ALL_FALSE,
    SET_IS_REDIRECT,
    PREFIX_GET_INVOICES,
    GET_INVOICES_ERROR,
    GET_INVOICES_START,
    GET_INVOICES_SUCCESS,
    EXPORT_CARTEIRA_ERROR,
    EXPORT_CARTEIRA_START,
    EXPORT_CARTEIRA_SUCCESS,
    DISMISS_SNACKBARS,
    SET_EXPORT_MODAL,
  },

  setFilters,
  setPageParams,
  resetStore,
  selectAll,
  selectToPayment,
  removeAll,
  setSelectedAllFalse,
  setIsRedirect,
  getInvoicesError,
  getInvoicesStart,
  getInvoicesSuccess,
  exportCarteiraError,
  exportCarteiraStart,
  exportCarteiraSuccess,
  dismissSnackbars,
  setExportModal,
};
