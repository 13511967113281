import { connect } from 'react-redux';
import * as operations from '../../../redux/operations';
import selectors from '../../../redux/selectors';

import ConfirmarButton from './confirmarButton';

const mapStateToProps = ({ limiteCreditoStore }) => ({
  isLoading: limiteCreditoStore.transferenciaLimite.isLoading,
  isSendDisabled: selectors.isSendDisabled(limiteCreditoStore),
});

const mapDispatchToProps = (dispatch) => ({
  enviarPedido: () => dispatch(
    operations.submitSolicitacao(),
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmarButton);
