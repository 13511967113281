import {
  CONCAT_INVOICES, UPDATE_SELECTED_INVOICES, RESET_STORE, SET_STEP,
  PAYMENT_STEP_LIST, UPDATE_SELECTED_INVOICE, INIT_REQUEST, FINISH_REQUEST,
  SET_IS_SUBMITTING, SET_START_DATE, SET_END_DATE, SET_INVOICES, SET_PAYMENT_ID,
  SET_PAYMENT_DEALER_INFO, SET_PAYMENT_USER_INFO, SET_PAYMENT_TRANSACTIONS_INFO,
  SET_PAYMENT_TRANSACTIONS, RECEIPT_INIT_REQUEST, RECEIPT_FINISH_REQUEST, START_DOWNLOAD,
  FINISH_DOWNLOAD, SET_FILTER_SEARCH_TEXT, SET_IS_SEARCH_ACTIVE, SET_SEARCH_TEXT_VALUE,
} from './paymentActions';

export const INITIAL_STATE = {
  step: PAYMENT_STEP_LIST,
  invoices: [],
  paymentId: null,
  hasMoreItens: true,
  loading: false,
  page: 0,
  startDate: null,
  endDate: null,
  searchText: null,
  isSearchActive: false,
  searchTextValue: '',
  selectedInvoices: [],
  isSubmitting: false,
  downloading: false,
  downloadError: null,

  paymentReceipt: {
    loading: false,
    userInfo: null,
    dealerInfo: null,
    transactionInfo: null,
    transactions: [],
    isTransactionError: false,
  },
};

const applyInvoiceUpdates = (invoicesList, newInvoice) => (
  invoicesList
    .map((invoice) => {
      if (invoice.invoiceNumber === newInvoice.invoiceNumber) {
        return { ...invoice, ...newInvoice };
      }

      return invoice;
    })
);

export default (state = INITIAL_STATE, action = { type: 'default' }) => {
  const { type, payload } = action;

  switch (type) {
    case SET_INVOICES:
      return {
        ...state,
        invoices: action.payload.invoices,
        hasMoreItens: action.payload.hasMoreItens,
        page: 0,
      };
    case CONCAT_INVOICES:
      return {
        ...state,
        invoices: [...state.invoices, ...payload.invoices],
        hasMoreItens: payload.hasMoreItens,
        page: payload.hasMoreItens ? payload.page : 0,
      };
    case UPDATE_SELECTED_INVOICES:
      return {
        ...state,
        selectedInvoices: payload.invoices,
      };
    case SET_PAYMENT_ID:
      return {
        ...state,
        paymentId: action.payload,
      };
    case UPDATE_SELECTED_INVOICE:
      return {
        ...state,
        selectedInvoices: applyInvoiceUpdates(state.selectedInvoices, payload.invoice),
      };
    case SET_STEP:
      return {
        ...state,
        step: payload.step,
      };
    case RESET_STORE:
      return INITIAL_STATE;
    case INIT_REQUEST:
      return { ...state, loading: true };
    case FINISH_REQUEST:
      return { ...state, loading: false };
    case SET_IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.payload,
      };
    case SET_START_DATE:
      return {
        ...state,
        startDate: action.payload,
      };
    case SET_END_DATE:
      return {
        ...state,
        endDate: action.payload,
      };
    case SET_FILTER_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.payload,
      };
    case SET_IS_SEARCH_ACTIVE:
      return {
        ...state,
        isSearchActive: action.payload,
      };
    case SET_SEARCH_TEXT_VALUE:
      return {
        ...state,
        searchTextValue: action.payload,
      };
    case SET_PAYMENT_USER_INFO:
      return {
        ...state,
        paymentReceipt: {
          ...state.paymentReceipt,
          userInfo: action.payload,
        },
      };
    case SET_PAYMENT_DEALER_INFO:
      return {
        ...state,
        paymentReceipt: {
          ...state.paymentReceipt,
          dealerInfo: action.payload,
        },
      };
    case SET_PAYMENT_TRANSACTIONS_INFO:
      return {
        ...state,
        paymentReceipt: {
          ...state.paymentReceipt,
          transactionInfo: action.payload,
        },
      };
    case SET_PAYMENT_TRANSACTIONS:
      return {
        ...state,
        paymentReceipt: {
          ...state.paymentReceipt,
          transactions: action.payload,
        },
      };
    case RECEIPT_INIT_REQUEST:
      return {
        ...state,
        paymentReceipt: {
          ...state.paymentReceipt,
          loading: true,
        },
      };
    case RECEIPT_FINISH_REQUEST:
      return {
        ...state,
        paymentReceipt: {
          ...state.paymentReceipt,
          isTransactionError: action.payload,
          loading: false,
        },
      };
    case START_DOWNLOAD:
      return {
        ...state,
        downloading: true,
      };
    case FINISH_DOWNLOAD:
      return {
        ...state,
        downloadError: action.payload,
        downloading: false,
      };

    default:
      return state;
  }
};
