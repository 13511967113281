import React from 'react';
import PropTypes from 'prop-types';

import DateRangePicker from '../../../../../../../common-staff/controls/dateRangePicker';

const EmissaoDateRange = ({
  isLoading, startDate, endDate,
  setEndDate, setStartDate,
}) => {
  const setEndDateAndFilter = (value) => {
    setEndDate(value);
  };

  return (
    <DateRangePicker
      startDate={startDate}
      endDate={endDate}
      setStartDate={setStartDate}
      setEndDate={setEndDateAndFilter}
      disabled={isLoading}
      numberOfMonths={1}
      title="Data de emissão"
    />
  );
};

EmissaoDateRange.propTypes = {
  isLoading: PropTypes.bool,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  setEndDate: PropTypes.func,
  setStartDate: PropTypes.func,
};

EmissaoDateRange.defaultProps = {
  isLoading: false,
  startDate: null,
  endDate: null,
  setEndDate: () => {},
  setStartDate: () => {},
};

export default EmissaoDateRange;
