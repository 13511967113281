import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Switch, Route, Redirect, useRouteMatch,
} from 'react-router-dom';
import { hasPermission, getCurrentUser } from 'modules/auth/auth';
import { permissoes } from 'modules/auth/permissoes';
import PermissionRoute from 'modules/auth/routes/permissionRoute';
import { DealerSelectors } from 'modules/dealers/redux';
import SnackbarList from 'features/common-staff/snackbarList';
import { RenderIfNotFidis } from 'modules/auth/guards';
import RenderIfPermission from 'features/menu/view/renderIfPermission';
import RenderIfBrandPermission from 'features/common/renderIfBrandPermission';
import { BRAND_FIAT } from 'utils/variables';
import MenuCarteiraFidc from './menuCarteiraFidc/menuCarteiraFidc';
import { DuplicataContextProvider } from './contexts/duplicataContext';
import { ResgateContextProvider } from './contexts/resgateContext';
import { HistoricoResgateContextProvider } from './contexts/historicoResgateContext';
import { OnboardingContextProvider } from './contexts/onboardingContext';
import DuplicataPage from './views/duplicataPage/duplicataPage';
import HistoricoResgatesPage from './views/historicoResgatesPage/historicoResgatesPage';
import JurosCarenciaPage from './views/jurosCarenciaPage/jurosCarenciaPage';
import JurosCarenciaModal from './views/jurosCarenciaPage/jurosCarenciaModal/jurosCarenciaModal';
import {
  PageContainer, MenuPageContainer, PageContent,
} from './carteiraFidc.style';

const CarteiraFidc = ({ dealer }) => {
  const { path } = useRouteMatch();
  const user = getCurrentUser();
  const hasPermissionFidcDuplicatas = hasPermission(user, permissoes.fidc.duplicatasFidc);
  let defaultPath = '';
  if (hasPermissionFidcDuplicatas) defaultPath = 'duplicatas';

  const [snackbar, setSnackbar] = useState({ nextId: 0, list: [] });

  const addSnackbar = useCallback((message, type) => {
    setSnackbar((prevState) => ({
      nextId: prevState.nextId + 1,
      list: [...prevState.list, {
        id: prevState.nextId, message, type,
      }],
    }));
  }, []);

  const closeSnackbar = useCallback((id) => {
    setSnackbar((prevState) => ({
      ...prevState,
      list: prevState.list.filter((s) => s.id !== id),
    }));
  }, []);

  return (
    <PageContainer>
      <OnboardingContextProvider>
        <DuplicataContextProvider
          dealer={dealer}
          addSnackbar={addSnackbar}
          closeSnackbar={closeSnackbar}
        >
          <ResgateContextProvider
            dealer={dealer}
            addSnackbar={addSnackbar}
            closeSnackbar={closeSnackbar}
          >
            <HistoricoResgateContextProvider
              dealer={dealer}
            >
              <MenuPageContainer>
                <MenuCarteiraFidc dealer={dealer} />
              </MenuPageContainer>
              <PageContent>
                <Switch>
                  <PermissionRoute path={`${path}/duplicatas`} requireAll={[permissoes.fidc.duplicatasFidc]}>
                    <DuplicataPage />
                  </PermissionRoute>
                  <PermissionRoute
                    path={`${path}/resgates/juros-carencia`}
                    requireAll={[permissoes.fidc.resgatesJurosCarencia]}
                  >
                    <RenderIfBrandPermission
                      requiredBrand={BRAND_FIAT}
                    >
                      <JurosCarenciaPage />
                    </RenderIfBrandPermission>

                  </PermissionRoute>
                  <PermissionRoute
                    path={`${path}/resgates/historico-resgates`}
                    requireAll={[permissoes.fidc.resgatesHistorico]}
                  >
                    <RenderIfBrandPermission
                      requiredBrand={BRAND_FIAT}
                    >
                      <HistoricoResgatesPage />
                    </RenderIfBrandPermission>
                  </PermissionRoute>
                  <Route path="*">
                    <Redirect to={`${path}/${defaultPath}`} />
                  </Route>
                </Switch>
              </PageContent>
              <RenderIfNotFidis>
                <RenderIfPermission requireAll={[permissoes.fidc.resgatesJurosCarencia]}>
                  <RenderIfBrandPermission
                    requiredBrand={BRAND_FIAT}
                  >
                    <JurosCarenciaModal />
                  </RenderIfBrandPermission>
                </RenderIfPermission>
              </RenderIfNotFidis>
              <SnackbarList
                snackbarErrors={snackbar.list}
                onClose={(id) => closeSnackbar(id)}
              />
            </HistoricoResgateContextProvider>
          </ResgateContextProvider>
        </DuplicataContextProvider>
      </OnboardingContextProvider>
    </PageContainer>
  );
};

CarteiraFidc.propTypes = {
  dealer: PropTypes.object.isRequired,
};

const mapStateToProps = ({ dealerStore }) => ({
  dealer: DealerSelectors.getSelectedFPlanDealer(dealerStore),
});

export default connect(mapStateToProps, null)(CarteiraFidc);
