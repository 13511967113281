import styled from 'styled-components';

export const Container = styled.div`
  .list__container-div > div > div > .list__inner-div {
    ::-webkit-scrollbar:vertical {
      width: 6px !important;
    }

    ::-webkit-scrollbar-button:vertical:start:increment {
      height: 36px;
      display: block;
      background: transparent;
    }
  }

  .list__container-div > div > div:nth-child(3) {
    display: none;
  }

  margin-right: 5px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

`;

export const AlertContainer = styled.div`
  margin-bottom: 128px;
`;
