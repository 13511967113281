import React from 'react';
import PropTypes from 'prop-types';

import FormInput from 'features/common-staff/controls/input/formInput';

import './inputMotivo.scss';

const InputMotivo = ({
  motivo, setMotivo,
}) => (
  <div className="solicitacao-limite-input-busca__container">
    <FormInput
      type="text"
      label="Motivo"
      placeholder=""
      rows="3"
      multiline
      value={motivo}
      setValue={(text) => {
        setMotivo(text);
      }}
      disabled={false}
    />
  </div>
);

InputMotivo.propTypes = {
  motivo: PropTypes.string,
  setMotivo: PropTypes.func,
};

InputMotivo.defaultProps = {
  motivo: '',
  setMotivo: () => {},
};

export default InputMotivo;
