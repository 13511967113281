import { connect } from 'react-redux';

import FilterButton from 'features/common-staff/controls/buttonFilter';
import operations from '../../../redux/operations';

const mapStateToProps = ({ gestaoPagamentos }) => ({
  isFilterSelected: gestaoPagamentos?.carteira?.modalTaxas?.filterButton,
  isLoading: gestaoPagamentos?.carteira?.modalTaxas?.requestStatus?.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  onClick: () => dispatch(operations.getTaxas()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterButton);
