/* eslint-disable no-use-before-define */
import store from '../../../../../setup/store';
import { Tracking } from '../../../../../modules';

import { ChatEngine } from '../chatEngine/engine';
import { valueForChatState } from '../chatEngine/utils';
import { transitions } from './chat/transitions';
import { messageCreators } from './chat/messages';
import { chatStates } from './chat/chatStates';

import { SEND_ANSWER_STATUS_SENDING } from './reducer';
import service from './service';
import { actions } from './actions';

export const engine = new ChatEngine(
  messageCreators,
  transitions,
  (answer) => store.dispatch(nextChatState(answer)),
);

const getAnswersDto = (answers) => {
  const userInfo = valueForChatState(answers, chatStates.get_user_info);
  return {
    usuarioId: userInfo.usuarioId,
    usuarioEmail: userInfo.usuarioEmail,
    usuarioNome: userInfo.usuarioNome,
    concessionariaId: userInfo.concessionariaId,
    concessionariaNome: userInfo.concessionariaNome,
    regional: userInfo.regional,
    regionalCodigo: userInfo.regionalCodigo,

    tdSolicitou: valueForChatState(answers, chatStates.solicitou_td),
    tdNaoSolicitouMotivo: valueForChatState(answers, chatStates.td_pq_nao_solicitou),
    tdNaoSolicitouMotivoOutro:
      valueForChatState(answers, chatStates.td_pq_nao_solicitou_outros_qual),

    tdExperienciaHub: valueForChatState(answers, chatStates.experiencia_hub),
    tdExperiencia: valueForChatState(answers, chatStates.td_palavra_processo),
    tdDificuldade: valueForChatState(answers, chatStates.td_dificuldade),
    tdDificuldadeDescricao: valueForChatState(answers, chatStates.td_dificuldade_qual),
    tdMelhoria: valueForChatState(answers, chatStates.td_melhoria),
    tdMelhoriaDescricao: valueForChatState(answers, chatStates.td_melhoria_qual),

    pagJaUtilizou: valueForChatState(answers, chatStates.pag_ja_utilizou),
    pagNaoUtilizouMotivo: valueForChatState(answers, chatStates.pag_n_utilizou_motivo),
    pagExperiencia: valueForChatState(answers, chatStates.pag_experiencia),
    pagPalavraHub: valueForChatState(answers, chatStates.pag_palavra_hub),
    pagMelhoria: valueForChatState(answers, chatStates.pag_melhoria),
    pagMelhoriaDescricao: valueForChatState(answers, chatStates.pag_melhoria_qual),

    hubComoMelhorar: valueForChatState(answers, chatStates.melhorar_hub),
  };
};

const sendAnswer = () => async (dispatch, getState) => {
  const { sendAnswerStatus, answers } = getState().feedback.testDrive;

  if (sendAnswerStatus !== SEND_ANSWER_STATUS_SENDING) {
    try {
      dispatch(actions.sendAnswerBegin());

      // eslint-disable-next-line no-promise-executor-return
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      const minimumSendTime = 3000; // para dar tempo do usuário ver a mensagem 'enviando'

      await Promise.all([
        service.postAnswer(getAnswersDto(answers)),
        delay(minimumSendTime),
      ]);

      Tracking.Mixpanel.feedback.user.trackSend();

      dispatch(actions.sendAnswerSuccess());
    } catch (e) {
      dispatch(actions.sendAnswerError());
      Tracking.Mixpanel.feedback.user.trackSendError();
    }
  }
};

const nextChatState = (answer) => (dispatch, getState) => {
  const { chatState: currentChatState, answers } = getState().feedback.testDrive;

  const nextState = engine.getNextChatState(currentChatState, answer, answers);

  dispatch(actions.pushAnswer(answer));
  dispatch(actions.setChatState(nextState));

  const { answers: newAnswers } = getState().feedback.testDrive;

  const answeredMessages = engine.getAnsweredMessages(currentChatState, newAnswers);
  dispatch(actions.pushMessages(answeredMessages));

  if (nextState === chatStates.envio_resposta) {
    dispatch(sendAnswer());
  }

  Tracking.Mixpanel.feedback.user.trackState(nextState);
};

const resetStore = () => (dispatch) => {
  dispatch(actions.resetStore());
};

export const operations = {
  sendAnswer,
  nextChatState,
  resetStore,
};
