import * as React from "react";

function BellIcon(props) {
	return (
		<svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
			<path
				fill="#FFF"
				fillRule="evenodd"
				d="M14 18.341c0 .9-.916 1.66-2 1.66s-2-.76-2-1.66v-.34h4v.34zm6.521-3.134l-1.801-1.803V8.936c0-3.48-2.502-6.437-5.821-6.877a6.724 6.724 0 00-5.316 1.607A6.731 6.731 0 005.28 8.727l-.001 4.677-1.8 1.804a1.631 1.631 0 00-.354 1.782c.255.613.848 1.01 1.512 1.01H8v.341c0 2.018 1.794 3.66 4 3.66s4-1.642 4-3.66v-.34h3.362c.664 0 1.257-.397 1.511-1.01a1.633 1.633 0 00-.352-1.784z"
			/>
		</svg>
	);
}

export default BellIcon;
