import React from 'react';
import PropTypes from 'prop-types';

const DuplicataNumber = ({
  number, digit, className,
}) => (
  <span className={className || null}>
    {` ${String(number).padStart(8, '0')}-${digit}`}
  </span>
);

DuplicataNumber.propTypes = {
  number: PropTypes.number,
  digit: PropTypes.number,
  className: PropTypes.string,
};

DuplicataNumber.defaultProps = {
  number: 0,
  digit: 0,
  className: '',
};

export default DuplicataNumber;
