import { React } from 'react';
import PropTypes from 'prop-types';

import {
  Box, Checkbox, FormControlLabel,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import colors from 'assets/styles/colors';

const EnviarParaDealer = ({ control, disabled }) => (
  <Box width="122px">
    <FormControlLabel
      sx={{
        '& .MuiFormControlLabel-label': {
          fontSize: '12px',
          lineHeight: '20px',
          color: colors.secundary_color_700,
        },
        margin: 0,
        gap: '4px',
      }}
      control={(
        <Controller
          name="enviarParaDealer"
          control={control}
          render={({ field }) => (
            <Checkbox
              {...field}
              size="small"
              color="primary500"
              sx={{ padding: 0 }}
              disabled={disabled}
            />
          )}
        />
      )}
      label="Enviar para dealer"
    />
  </Box>
);

EnviarParaDealer.propTypes = {
  control: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

EnviarParaDealer.defaultProps = {
  disabled: false,
};

export default EnviarParaDealer;
