export const SET_DEALERS = 'dealers/SET_DEALERS';
export const SET_SELECTED_FPLAN_DEALER = 'dealers/SET_SELECTED_FPLAN_DEALER';
export const SET_SELECTED_FPLAN_BRAND = 'dealers/SET_BRAND';

export const setDealers = (dealers) => ({
  type: SET_DEALERS,
  payload: dealers,
});

export const setSelectedFPlanDealer = (id) => ({
  type: SET_SELECTED_FPLAN_DEALER,
  payload: id,
});

export const setSelectedFPlanBrand = (name) => ({
  type: SET_SELECTED_FPLAN_BRAND,
  payload: name,
});
