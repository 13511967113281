import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import { Currency, Date } from '../../../common';

import './paymentSummary.scss';

const TextValueRow = (text, value) => (
  <div className="payment-summ-item-row">
    <div className="payment-summ-text-left">
      {text}
    </div>
    <div className="payment-summ-value-right">
      {value}
    </div>
  </div>
);

const PaymentSummary = ({
  className, t,
  totalValue, paymentValue, invoiceCount, paymentDate, userName,
}) => (
  <div className={`payment-summ-container ${className}`}>
    <div className="payment-summ-header">
      {t('CONFIRME O PAGAMENTO')}
    </div>

    <div className="payment-summ-col-left">
      {TextValueRow(
        t('Saldo das duplicatas'),
        <Currency noColor value={totalValue} />,
      )}
      {TextValueRow(t('Duplicatas selecionadas'), invoiceCount || '')}

      <div className="payment-summ-col-left-bottom">
        <div className="payment-summ-value-to-pay">
          {t('Valor a pagar')}
        </div>
        <Currency
          className="payment-summ-total"
          noColor
          value={paymentValue}
        />
      </div>
    </div>
    <div className="payment-summ-col-right">
      {TextValueRow(
        t('Usuário'),
        userName,
      )}
      {TextValueRow(
        t('Data do pagamento'),
        <Date date={paymentDate} format="DD/MM/YYYY" />,
      )}

      <div className="payment-summ-col-right-bottom">
        {t('Após a confirmação, o pagamento entrará em processamento.')}
      </div>
    </div>
  </div>
);

PaymentSummary.propTypes = {
  totalValue: PropTypes.number.isRequired,
  paymentValue: PropTypes.number.isRequired,
  invoiceCount: PropTypes.number.isRequired,
  paymentDate: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,

  className: PropTypes.string,
  t: PropTypes.func,
};

PaymentSummary.defaultProps = {
  className: '',
  t: (i) => i,
};

export default withNamespaces('payment')(PaymentSummary);
