import React from 'react';
import PropTypes from 'prop-types';

import './invoicesCount.scss';

const InvoicesCount = (props) => {
  const { className, invoices, t } = props;

  return (
    <div className={className}>
      <span className="invoices-count-desc">
        {`${t('Nº de duplicatas selecionadas')}: `}
      </span>
      <span className="invoices-count-value">
        {invoices.length}
      </span>
    </div>
  );
};

InvoicesCount.propTypes = {
  className: PropTypes.string,
  invoices: PropTypes.array,
  t: PropTypes.func.isRequired,
};

InvoicesCount.defaultProps = {
  className: '',
  invoices: [],
};

export default InvoicesCount;
