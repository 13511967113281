import { connect } from 'react-redux';

import Popover from './userFeedbackPopover';

const mapStateToProps = ({ feedback }) => ({
  isOpen: feedback.chatPopover.isOpen,
  content: feedback.chatPopover.content,
});

export default connect(mapStateToProps)(Popover);
