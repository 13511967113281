import React from 'react';
import InputMotivo from './inputMotivo';
import ToogleValor from './toogleValor';
import InputValor from './inputValor';
import RadioGroupProduto from './radioGroupProduto';

import './dadosSolicitacao.scss';

const DadosSolicitacao = () => (
  <div
    className="alteracao-limite-form__dados-solicitacao"
    data-cy="AltecaoLimiteFormDadosSolicitacao"
  >
    <div className="alteracao-limite-form__dados-solicitacao_title">
      Dados da Solicitação
    </div>
    <div className="alteracao-limite-form__dados-solicitacao_tipo">
      <ToogleValor />
    </div>
    <div className="alteracao-limite-form__dados-solicitacao_valor">
      <InputValor />
    </div>

    <div className="alteracao-limite-form__dados-solicitacao_motivo">
      <InputMotivo />
    </div>

    <div className="alteracao-limite-form__dados-solicitacao_produto">
      <RadioGroupProduto required />
    </div>
  </div>

);

export default DadosSolicitacao;
