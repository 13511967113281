import { connect } from 'react-redux';

import FilterButton from 'features/common-staff/controls/buttonFilter';
import operations from '../../../../redux/operations';

const mapStateToProps = ({ statementStore }) => ({
  isFilterSelected: statementStore?.isFilterSelected,
  isLoading: statementStore?.requestStatus?.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  onClick: () => dispatch(operations.setPageParams('page', 0)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterButton);
