import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import DuplicataNumber from '../duplicataNumber';

import './duplicataDescription.scss';

const DuplicataDescription = (
  {
    className, product, invoiceNumber, invoiceDigit,
  },
) => (
  <div className={className}>
    <div className="i-section-desc-type">
      {product}
    </div>
    <div className="i-section-desc-number">
      <span>
        Duplicata nº
        <DuplicataNumber number={invoiceNumber} digit={invoiceDigit} />
      </span>
    </div>
  </div>
);

DuplicataDescription.propTypes = {
  product: PropTypes.string.isRequired,
  invoiceNumber: PropTypes.number,
  invoiceDigit: PropTypes.number,
  className: PropTypes.string,
};

DuplicataDescription.defaultProps = {
  invoiceNumber: 0,
  invoiceDigit: 0,
  className: '',
};

export default withNamespaces('invoicePage')(DuplicataDescription);
