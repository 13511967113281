import React from 'react';
import PropTypes from 'prop-types';
import LogoStellantisBanco from 'assets/icons/iconsRebranding/logo-stellantis-banco';
import XIcon from '../../../../../assets/icons/x';

import './mainContentHeader.scss';

const MainContentHeader = ({
  className, onClose,
}) => (
  <div className={className}>
    <LogoStellantisBanco />
    <div className="chat-popover__main-content-header__x-container">
      <XIcon
        onClick={onClose}
        className="chat-popover__main-content-header__x-icon"
      />
    </div>
    <div className="chat-popover__main-content-header__title">
      <span>Olá</span>
      <span role="img" aria-label="emoji">👋</span>
    </div>
    <div className="chat-popover__main-content-header__legend">
      Gostariamos de saber como tem sido a sua
      experiência ao utilizar o nosso HUB. Para isso
      criamos esse canal de comunicação.
    </div>
  </div>
);

MainContentHeader.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

MainContentHeader.defaultProps = {
  className: '',
};

export default MainContentHeader;
