import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Page, PageHeader, PageTitle, PageSubTitle, PageContent, PageFooter,
} from 'features/common-staff/layout/page';
import SplitView from 'features/common-staff/layout/splitView';
import PageErrorBoundary from '../../../common/errorBoundary/pageErrorBoundary';

import BreadCrumbAcompanhamento from './views/breadCrumbAcompanhamento';
import AcompanhamentoList from './views/acompanhamentoList';
import PaginationFooter from './views/paginationFooter';
import DetalheSolicitacaoAside from './views/detalheSolicitacaoAside';

const TransferenciaLimitePage = ({
  getSolicitacoes, resetStore, isOpen, setOpen,
}) => {
  useEffect(() => {
    getSolicitacoes();
    return () => { resetStore(); };
  }, [getSolicitacoes, resetStore]);
  return (
    <PageErrorBoundary>
      <SplitView
        open={isOpen}
        sideSheetProps={{
          onClickClose: () => setOpen(),
          width: 400,
        }}
        renderSideSheetContent={() => <DetalheSolicitacaoAside />}
      >
        <Page
          minWidth="fit-content"
        >
          <PageHeader>
            <PageSubTitle>
              <BreadCrumbAcompanhamento />
            </PageSubTitle>
            <PageTitle>Minhas Solicitações</PageTitle>
          </PageHeader>
          <PageContent>
            <AcompanhamentoList />
          </PageContent>
          <PageFooter>
            <PaginationFooter />
          </PageFooter>
        </Page>
      </SplitView>
    </PageErrorBoundary>
  );
};
TransferenciaLimitePage.propTypes = {
  getSolicitacoes: PropTypes.func,
  resetStore: PropTypes.func,
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
};

TransferenciaLimitePage.defaultProps = {
  getSolicitacoes: () => {},
  resetStore: () => {},
  isOpen: false,
  setOpen: () => {},
};

export default TransferenciaLimitePage;
