import { connect } from 'react-redux';
import DropDivUpload from './dropDivUpload';

import { TestDriveOperations } from '../../../../redux';

const mapStateToProps = ({ testDrive }) => ({
  selectedFiles: testDrive.pedido.selectedFiles,
});

const mapDispatchToProps = (dispatch) => ({
  uploadFiles: (files) => dispatch(TestDriveOperations.uploadFiles(files)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DropDivUpload);
