import cookies from 'react-cookie';

class RcStorage {
  constructor() {
    this.COOKIE_RC_AUTH_TOKEN = 'rc-token';
    this.COOKIE_RC_FIDIS_USER_ID = 'rc-fidis-user-id';
    this.COOKIE_RC_FIDIS_USER_EMAIL = 'rc-fidis-user-email';
    this.COOKIE_RC_FIDIS_DEALER_IDS = 'rc-fidis-dealer-ids';
    this.maxDealers = 30;
    this.domain = window.location.hostname === 'localhost' ? null : this.getDomain();
    this.path = '/';
  }

  saveInfo(rcInfo) {
    this.dealers = rcInfo.fidisDealers
      ?.filter((d) => d.cnpj.slice(8, 12) === '0001')
      ?.slice(0, this.maxDealers)
      ?.map((d) => ({
        dealerId: d.dealerId,
        dealerName: d.dealerName,
        cnpj: d.cnpj,
      }));

    this.saveCookie(this.COOKIE_RC_AUTH_TOKEN, rcInfo.token);
    this.saveCookie(this.COOKIE_RC_FIDIS_USER_ID, rcInfo.userId);
    this.saveCookie(this.COOKIE_RC_FIDIS_USER_EMAIL, rcInfo.userEmail);
    this.saveCookie(this.COOKIE_RC_FIDIS_DEALER_IDS, this.dealers);
  }

  getInfo() {
    return ({
      token: cookies.load(this.COOKIE_RC_AUTH_TOKEN),
      userId: cookies.load(this.COOKIE_RC_FIDIS_USER_ID),
      userEmail: cookies.load(this.COOKIE_RC_FIDIS_USER_EMAIL),
      dealers: cookies.load(this.COOKIE_RC_FIDIS_DEALER_IDS),
    });
  }

  clearCookies() {
    cookies.remove(this.COOKIE_RC_AUTH_TOKEN, { domain: this.domain, path: this.path });
    cookies.remove(this.COOKIE_RC_FIDIS_USER_ID, { domain: this.domain, path: this.path });
    cookies.remove(this.COOKIE_RC_FIDIS_USER_EMAIL, { domain: this.domain, path: this.path });
    cookies.remove(this.COOKIE_RC_FIDIS_DEALER_IDS, { domain: this.domain, path: this.path });
  }

  saveCookie(cookie, value) {
    cookies.save(cookie, value, { domain: this.domain, path: this.path, secure: true });
  }

  getDomain() {
    this.currentUrl = window.location.href;
    this.targetString = 'fcalatam';
    if (this.currentUrl.indexOf(this.targetString) !== -1) {
      return '.fcalatam.com.br';
    }
    return '.bancostellantis.com.br';
  }
}

export default new RcStorage();
