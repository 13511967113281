import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../common/button';

import './cardUserFeedback.scss';

const CardUserFeedback = ({
  className, onClick,
}) => (
  <div className={className}>
    <div className="chat-popover__card-user-feedback__title">Pesquisa de satisfação</div>
    <div className="chat-popover__card-user-feedback__subtitle">Conte para gente como está sendo utilizar o Hub e quais são suas expectativas em relação a ele.</div>
    <Button
      className="chat-popover__card-user-feedback__button"
      onClick={onClick}
    >
      Iniciar a pesquisa
    </Button>
  </div>
);

CardUserFeedback.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

CardUserFeedback.defaultProps = {
  className: '',
  onClick: () => {},
};

export default CardUserFeedback;
