/* eslint-disable no-use-before-define */

const textMessage = (sender, text) => {
  assertSender(sender);

  return ({
    type: 'text',
    sender,
    text: text || '',
  });
};

const select = {
  open: (sender, options, onClick) => {
    assertSender(sender);
    return ({
      type: 'select',
      sender,
      optionList: options,
      onClick,
      isAnswered: false,
    });
  },

  answered: {
    fromText: (sender, optionTexts, selectedValue) => {
      assertSender(sender);
      return ({
        type: 'select',
        sender,
        optionList: (optionTexts || []).map((opt) => ({
          text: opt,
          value: opt,
          selected: opt === selectedValue,
        })),
        onClick: () => {},
        isAnswered: true,
      });
    },
    fromOptions: (sender, optionTexts, selectedValue) => {
      assertSender(sender);
      return ({
        type: 'select',
        sender,
        optionList: (optionTexts || []).map((opt) => ({
          text: opt.text,
          value: opt.value,
          selected: opt.value === selectedValue,
        })),
        onClick: () => {},
        isAnswered: true,
      });
    },
  },
};

const selectOpenYesNo = (sender, onClick) => {
  assertSender(sender);
  return ({
    type: 'select',
    sender,
    optionList: [
      option('Sim', true, false),
      option('Não', false, false),
    ],
    onClick,
    isAnswered: false,
  });
};

const selectYesNoAnswered = (sender, selectedValue) => {
  assertSender(sender);
  return ({
    type: 'select',
    sender,
    optionList: [
      option('Sim', true, selectedValue),
      option('Não', false, !selectedValue),
    ],
    onClick: () => {},
    isAnswered: true,
  });
};

const option = (text, value = null, isSelected = false) => ({
  text,
  value: (value !== undefined && value !== null) ? value : text,
  selected: isSelected,
});

function assertSender(sender) {
  if (sender !== 'fidis' && sender !== 'user') {
    throw new Error(`Invalid sender ${sender}`);
  }
}

const emojiMessageAnswered = (value) => ({
  type: 'emoji',
  value,
  isAnswered: true,
});

const emojiMessageOpen = (onClick) => ({
  type: 'emoji',
  onClick,
  isAnswered: false,
});

export const messages = {
  text: textMessage,
  select,
  selectOpenYesNo,
  selectYesNoAnswered,
  option,
  emojiMessageAnswered,
  emojiMessageOpen,
};
