import React from 'react';
import PropTypes from 'prop-types';

const CnpjFormat = ({
  className, value,
}) => (
  <span className={className}>
    {value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')}
  </span>
);

CnpjFormat.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
};

CnpjFormat.defaultProps = {
  className: '',
  value: '',
};

export default CnpjFormat;
