import React from 'react';
import PropTypes from 'prop-types';
import TextFilter from './textFilter';
import StatusFilter from './statusFilter';
import VoltarButton from './voltarButton';
import FilterButton from './filterButton';

import './filters.scss';

const Filters = ({ isModalOpen }) => (
  <div className={
    `acompanhamento__page__filters ${isModalOpen && 'acompanhamento__page__filters__open'}`
  }
  >
    <div className="acompanhamento__page__filters-text">
      <TextFilter />
    </div>
    <div className="acompanhamento__page__filters-status">
      <StatusFilter />
    </div>
    <div className="acompanhamento__page__filters-buttons">
      <div className="acompanhamento__page__filters-buttons-filtrar">
        <FilterButton
          textApply="Filtrar"
          textSelect="Filtrar"
        />
      </div>
      <div className="acompanhamento__page__filters-buttons-solicitar">
        <VoltarButton />
      </div>
    </div>
  </div>
);

Filters.propTypes = {
  isModalOpen: PropTypes.bool,
};

Filters.defaultProps = {
  isModalOpen: false,
};

export default Filters;
