import React from 'react';
import PropTypes from 'prop-types';

import EmptyStateIcon from 'assets/icons/empty-state';
import NotificationRow from '../notificationRow';

import { PageTitle, ErroConexao } from '../../../common';

class NotificationContent extends React.Component {
  componentDidUpdate() {
    const {
      isOpen, loadingPost, loading, isPostSending,
    } = this.props;
    if (isOpen && !loadingPost && !loading && !isPostSending) {
      this.postReadNotifications();
    }
  }

  postReadNotifications = () => {
    const {
      getNotifications, dealerGroups, notifications, markNotificationsRead,
    } = this.props;
    const options = notifications.filter((n) => n.lido === false);
    if (options.length > 0) {
      markNotificationsRead(options);
      setTimeout(() => {
        getNotifications(dealerGroups);
      }, 5000);
    }
  };

  contentForStep = (notifications, isError, isErrorLastGet) => {
    if ((isError && isErrorLastGet) || notifications.length === 0) {
      return (
        <ErroConexao
          title="Sem resultados."
          message="ainda não há nada por aqui"
        >
          <EmptyStateIcon className="erro-conexao-image" />
        </ErroConexao>
      );
    }
    return notifications.map((notification, index) => (
      <NotificationRow
        notification={notification}
        key={`notification-row${index}`}
      />
    ));
  };

  render() {
    const {
      isError, notifications, isErrorLastGet,
    } = this.props;
    return (
      <>
        <PageTitle className="notifications-page-title" title="Notificações" />
        {this.contentForStep(notifications, isError, isErrorLastGet)}
      </>
    );
  }
}

NotificationContent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  getNotifications: PropTypes.func.isRequired,
  markNotificationsRead: PropTypes.func.isRequired,
  dealerGroups: PropTypes.array.isRequired,
  notifications: PropTypes.array.isRequired,
  isError: PropTypes.bool.isRequired,
  loadingPost: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  isPostSending: PropTypes.bool.isRequired,
  isErrorLastGet: PropTypes.bool.isRequired,
};

export default NotificationContent;
