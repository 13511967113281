import React from 'react';
import PropTypes from 'prop-types';

import TextFilterDebounce from '../../../../../../../common-staff/controls/textFilterDebounce';

const InputVersao = ({
  versao, setVersao,
}) => (
  <TextFilterDebounce
    label="Cód. Versão"
    placeholder=""
    value={versao}
    setValue={setVersao}
  />
);

InputVersao.propTypes = {
  versao: PropTypes.string,
  setVersao: PropTypes.func,
};

InputVersao.defaultProps = {
  versao: '',
  setVersao: () => {},
};

export default InputVersao;
