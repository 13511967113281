import React from 'react';
import PropTypes from 'prop-types';

import BaseArea from '../baseArea';
import ChartCarteiraBrand from './chartCarteiraBrand';
import LegendaCarteiraBrand from './legendaCarteiraBrand';
import './graficoBrand.scss';

const GraficoBrands = ({
  isLoading, isError, isEmpty, className,
}) => (
  <BaseArea
    isEmpty={isEmpty}
    isLoading={isLoading}
    isError={isError}
    isLegendaBottom
    title="Carteira por brand"
    subtitle="Valor"
    className={className}
    legenda={<LegendaCarteiraBrand />}
  >
    <div className="dashboard-floor-plan__carteira_brand__content">
      <ChartCarteiraBrand />
    </div>

  </BaseArea>
);

GraficoBrands.propTypes = {
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  isEmpty: PropTypes.bool,
  className: PropTypes.bool,
};

GraficoBrands.defaultProps = {
  isLoading: false,
  isError: false,
  isEmpty: false,
  className: '',
};

export default GraficoBrands;
