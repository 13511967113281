import { connect } from 'react-redux';

import SelectStatus from './statusFilter';

import * as AcompanhamentoOperations from '../../acompanhamentoList/redux/operations';

const mapStateToProps = ({ testDrive }) => ({
  status: testDrive.acompanhamento.list.filters.status,
  selectedStatus: testDrive.acompanhamento.list.filters.selectedStatus,
  isLoading: testDrive?.acompanhamento?.list?.pedidosList?.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setStatus: (selectedStatus) => dispatch(AcompanhamentoOperations.setStatus(selectedStatus)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectStatus);
