import styled from 'styled-components';

export const Container = styled.div`
  padding: 12px 48px;
  overflow: scroll;
  height: 100%;
`;

export const ResgateContainer = styled.div`
  max-width: 950px;
  min-width: 700px;
  max-height: calc(100vh - 160px);
`;

export const AlertaContainer = styled.div`
   height: 100%;
   min-width: 700px;
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: center;
   align-items: center;
`;
