import React from 'react';
import PropTypes from 'prop-types';

import './pageTitle.scss';

const pageTitle = ({ className, title }) => (
  <div className={`page-title ${className}`}>
    { title }
  </div>
);

pageTitle.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
};

pageTitle.defaultProps = {
  className: '',
};

export default pageTitle;
