import logger from 'utils/logger';

import * as testDriveActions from './testDriveActions';
import testDriveService from '../testDriveService';

import * as pageSelectors from '../../redux/selectors';

import DealerOrganizer from './dealerOrganizer';

export const getOffers = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const { dealers } = state.dealerStore;
    const dealerOrganizer = new DealerOrganizer(dealers);
    const brands = dealerOrganizer.getOfferBrands();
    const produto = pageSelectors.finalProduct(state.testDrive);
    const perfil = (state.authStore.user.companies || []).filter((company) => company.companyId === 'FIDIS');

    dispatch(testDriveActions.setOffersLoading(true));
    dispatch(testDriveActions.setOffers([]));

    const offers = await testDriveService.getOffers(
      brands,
      produto?.id,
      (perfil.length > 0 ? null : dealers),
    );

    dispatch(testDriveActions.setOffers(offers));
    dispatch(testDriveActions.setOffersLoading(false));
    dispatch(testDriveActions.setShowOffersLoadError(false));
  } catch (error) {
    logger.error(error);
    dispatch(testDriveActions.setOffers([]));
    dispatch(testDriveActions.setOffersLoading(false));
    dispatch(testDriveActions.setShowOffersLoadError(true));
  }
};

export const getCommercialConditions = (
  modelo,
  versao,
  serie,
  modelYear,
  marca,
  produto,
) => async (dispatch) => {
  try {
    dispatch(testDriveActions.setConditionsLoading(true));
    dispatch(testDriveActions.setConditions([]));

    const conditions = await testDriveService.getCommercialConditions(
      modelo,
      versao,
      serie,
      modelYear,
      marca,
      produto,
    );

    dispatch(testDriveActions.setConditions(conditions));
    dispatch(testDriveActions.setConditionsLoading(false));
    dispatch(testDriveActions.setShowConditionsLoadError(false));
  } catch (error) {
    dispatch(testDriveActions.setConditions([]));
    dispatch(testDriveActions.setConditionsLoading(false));
    dispatch(testDriveActions.setShowConditionsLoadError(true));

    setTimeout(() => { dispatch(testDriveActions.setShowConditionsLoadError(false)); }, 5000);
  }
};

export const setSelectedOffer = (
  offerId,
) => (dispatch) => {
  const action = testDriveActions.setSelectedOffer(offerId);
  dispatch(action);
};

export const setSelectedCommercialCondition = (
  conditionId,
) => (dispatch) => {
  dispatch(testDriveActions.setSelectedCondition(conditionId));
};

export const setSelectedDealerId = (id) => (dispatch) => {
  dispatch(testDriveActions.setSelectedDealerId(id));
};

export const setIndustrialOrder = (order) => (dispatch) => {
  dispatch(testDriveActions.setIndustrialOrder(order));
};

export const setChassis = (chassis) => (dispatch) => {
  dispatch(testDriveActions.setChassis(chassis));
};

export const setColor = (color) => (dispatch) => {
  dispatch(testDriveActions.setColor(color));
};

export const setObservation = (observation) => (dispatch) => {
  dispatch(testDriveActions.setObservation(observation));
};

export const submitForm = (form, onSuccess, onError) => async (dispatch) => {
  try {
    dispatch(testDriveActions.setIsSubmitting(true));
    dispatch(testDriveActions.setShowSubmitError(false));
    await testDriveService.postForm(form);

    dispatch(testDriveActions.setShowSubmitSuccess(true));
    setTimeout(() => { dispatch(testDriveActions.setShowSubmitSuccess(false)); }, 5000);

    dispatch(testDriveActions.setIsSubmitting(false));
    (onSuccess || (() => {})).call();
  } catch (error) {
    dispatch(testDriveActions.setIsSubmitting(false));
    dispatch(testDriveActions.setShowSubmitError(true));
    setTimeout(() => { dispatch(testDriveActions.setShowSubmitError(false)); }, 5000);
    (onError || (() => {})).call();
  }
};

export const dismissSubmitError = () => (dispatch) => {
  dispatch(testDriveActions.setShowSubmitError(false));
};

export const dismissSubmitSuccess = () => (dispatch) => {
  dispatch(testDriveActions.setShowSubmitSuccess(false));
};

export const dismissShowConditionsLoadError = () => (dispatch) => {
  dispatch(testDriveActions.setShowConditionsLoadError(false));
};

export const clearForm = () => (dispatch) => {
  dispatch(testDriveActions.clearForm());
};

export const setSimulationBaseValue = (value) => (dispatch) => {
  dispatch(testDriveActions.setSimulationBaseValue(value));
};

export const getMotivosTestDriveExcecao = () => async (dispatch) => {
  const motivos = await testDriveService.getMotivosTestDriveExcecao();
  dispatch(testDriveActions.setMotivosTestDriveExcecao(motivos));
};

export const setSelectedMotivo = (motivo) => async (dispatch) => {
  dispatch(testDriveActions.setSelectedMotivo(motivo));
};

export const openModalUpload = () => (dispatch) => {
  dispatch(testDriveActions.setModalUpload(true));
};

export const closeModalUpload = (files) => async (dispatch) => {
  await Promise.all(files.map(async (f) => {
    try {
      await testDriveService.deleteUrlUploadFileTdExcecao(f.key);
    } catch (error) {
      dispatch(testDriveActions.setFileUploadError(f.name));
    }
  }));

  dispatch(testDriveActions.clearSelectedFiles());
  dispatch(testDriveActions.setModalUpload(false));
};

export const uploadFiles = (files) => async (dispatch) => {
  dispatch(testDriveActions.setFileUploading(true));
  await Promise.all(files.map(async (f) => {
    try {
      dispatch(testDriveActions.addNewFile(f));
      setTimeout(() => { dispatch(testDriveActions.setFileUploadProgress(f.name, 25)); }, 200);

      const fileInfo = await testDriveService.getUrlUploadFileTdExcecao(f.name, f.type);
      dispatch(testDriveActions.updateFileInfo(f.name, fileInfo, 50));

      await testDriveService.uploadFileTdExcecao(f);
      dispatch(testDriveActions.setFileUploadProgress(f.name, 99));

      setTimeout(() => { dispatch(testDriveActions.setFileUploadProgress(f.name, 100)); }, 200);
    } catch {
      dispatch(testDriveActions.setFileUploadError(f.name));
    }
  }));
  dispatch(testDriveActions.setFileUploading(false));
};

export const deleteFile = (fileName, key) => async (dispatch) => {
  try {
    dispatch(testDriveActions.setFileUploading(true));
    await testDriveService.deleteUrlUploadFileTdExcecao(key);
    dispatch(testDriveActions.deleteSelectedFile(fileName));
    dispatch(testDriveActions.deleteFilesToSend(fileName));
  } catch {
    dispatch(testDriveActions.setFileUploadError(fileName));
  }
  dispatch(testDriveActions.setFileUploading(false));
};

export const setFileUploadProgress = (fileName, value) => (dispatch) => {
  dispatch(testDriveActions.setFileUploadProgress(fileName, value));
};

export const setFileUploadError = (fileName) => (dispatch) => {
  dispatch(testDriveActions.setFileUploadError(fileName));
};

export const reUploadFile = (file) => async (dispatch) => {
  try {
    dispatch(testDriveActions.setFileUploading(true));
    dispatch(testDriveActions.setFileUploadProgress(file.name, 0));
    await testDriveService.deleteUrlUploadFileTdExcecao(file.key);

    dispatch(testDriveActions.setFileUploadProgress(file.name, 25));
    const fileInfo = await testDriveService.getUrlUploadFileTdExcecao(file.name, file.type);

    dispatch(testDriveActions.updateFileInfo(file.name, fileInfo, 50));
    await testDriveService.uploadFileTdExcecao(file);

    dispatch(testDriveActions.setFileUploadProgress(file.name, 99));
    setTimeout(() => { dispatch(testDriveActions.setFileUploadProgress(file.name, 100)); }, 200);
  } catch (error) {
    dispatch(testDriveActions.setFileUploadError(file.name));
  }
  dispatch(testDriveActions.setFileUploading(false));
};

export const sendFilestoForm = () => (dispatch) => {
  dispatch(testDriveActions.sendFilestoForm());
  dispatch(testDriveActions.clearSelectedFiles());
  dispatch(testDriveActions.setModalUpload(false));
};
