import { connect } from 'react-redux';

import Filters from './filters';

const mapStateToProps = ({ testDrive }) => ({
  isModalOpen: testDrive.acompanhamento.detalhe.isOpen,
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
