import { createSelector } from 'reselect';
import { camelFormat } from 'utils/format';

export const getSelectedFPlanDealer = (state) => {
  const { dealers, selectedFPlanDealerId } = state;

  return (dealers || []).find((d) => (d.dealerId === selectedFPlanDealerId));
};

export const getDealersFilter = createSelector(
  (state) => state.dealers,
  (dealers) => dealers.map((d) => ({
    value: d.dealerId,
    marca: d.brand,
    text: d.dealerName,
    buc: d.dealerCorr,
    cnpj: d.cnpj,
    group: d.shortName,
  })),
);

export const getDealersFilters = createSelector(
  (state) => state.dealers,
  (dealers) => dealers.map((d) => ({
    value: d.dealerId,
    marca: d.brand,
    label: d.dealerName,
    buc: d.dealerCorr,
    group: d.shortName,
    type: d.cnpj.substring(0, 8),
  })),
);

export const getDealers = createSelector(
  (state) => state.dealers,
  (dealers) => dealers.map((d) => ({
    value: d.dealerId,
    marca: d.brand,
    text: camelFormat(d.shortName),
    buc: d.dealerCorr,
    group: d.dealerName,
  })),
);
