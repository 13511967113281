import * as React from "react";

const PreviewIcon = (props) => {
	return (
		<svg width="24" height="24" viewBox="0 0 40 40" fill="none" >
			<rect width="40" height="40" rx="4" fill="#304AAF"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M20.6623 13.9224C20.6238 13.5909 20.3421 13.3335 20.0002 13.3335C19.632 13.3335 19.3335 13.632 19.3335 14.0002V20.3903L17.1382 18.1954L17.0754 18.14C16.8139 17.9366 16.4357 17.9551 16.1954 18.1954C15.9351 18.4558 15.9351 18.8779 16.1954 19.1382L19.5282 22.471C19.548 22.4908 19.569 22.5094 19.5911 22.5266L19.5916 22.527C19.6398 22.5645 19.692 22.5945 19.7466 22.6169C19.8248 22.6491 19.9104 22.6668 20.0002 22.6668C20.1821 22.6668 20.347 22.5939 20.4673 22.4758M20.4722 22.4709L23.8049 19.1382L23.8604 19.0754C24.0637 18.8139 24.0452 18.4357 23.8049 18.1954L23.7421 18.14C23.4806 17.9366 23.1024 17.9551 22.8621 18.1954L20.6668 20.3903V14.0002L20.6623 13.9224M26.0002 21.3335C26.3421 21.3335 26.6238 21.5909 26.6623 21.9224L26.6668 22.0002V24.6668C26.6668 25.7319 25.8342 26.6026 24.7843 26.6634L24.6668 26.6668H15.3335C14.2684 26.6668 13.3977 25.8342 13.3369 24.7843L13.3335 24.6668V22.0002C13.3335 21.632 13.632 21.3335 14.0002 21.3335C14.3421 21.3335 14.6238 21.5909 14.6623 21.9224L14.6668 22.0002V24.6668C14.6668 25.0087 14.9242 25.2905 15.2557 25.329L15.3335 25.3335H24.6668C25.0087 25.3335 25.2905 25.0761 25.329 24.7446L25.3335 24.6668V22.0002C25.3335 21.632 25.632 21.3335 26.0002 21.3335Z" fill="white"/>
		</svg>
	);
}

export default PreviewIcon;
