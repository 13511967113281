const PREFIX = 'relatoriosIof';
const SET_PAGE_PARAMS = `${PREFIX}/SET_PAGE_PARAMS`;
const SET_FILTER = `${PREFIX}/SET_FILTER`;
const RESET_STORE = `${PREFIX}/RESET_STORE`;
const SELECT_ALL = `${PREFIX}/SELECT_ALL`;
const SELECT = `${PREFIX}/SELECT`;
const GET_RELATORIOS_START = `${PREFIX}/GET_RELATORIOS_START`;
const GET_RELATORIOS_ERROR = `${PREFIX}/GET_RELATORIOS_ERROR`;
const GET_RELATORIOS_SUCCESS = `${PREFIX}/GET_RELATORIOS_SUCESS`;
const SET_PAGINATION_CONTROL = `${PREFIX}/SET_PAGINATION_CONTROL`;
const SET_IS_RELATORIO_SELECTED = `${PREFIX}/SET_IS_RELATORIO_SELECTED`;
const SET_IS_DATE_RANGE_SELECTED = `${PREFIX}/SET_IS_DATE_RANGE_SELECTED`;
const DISMISS_SNACKBARS = `${PREFIX}/DISMISS_SNACKBARS`;
const EXPORT_RELATORIO_ERROR = `${PREFIX}/EXPORT_RELATORIO_ERROR`;
const EXPORT_RELATORIO_SUCCESS = `${PREFIX}/EXPORT_RELATORIO_SUCCESS`;
const EXPORT_RELATORIO_START = `${PREFIX}/EXPORT_RELATORIO_START`;

const setFilter = (value, paramName, activeButton = true) => ({
  type: SET_FILTER,
  payload: { value, paramName, activeButton },
});

const resetStore = () => ({
  type: RESET_STORE,
});

const setPageParams = (propertyName, value) => ({
  type: SET_PAGE_PARAMS,
  payload: { propertyName, value },
});

const selectAll = () => ({
  type: SELECT_ALL,
});

const select = (documento) => ({
  type: SELECT,
  payload: { documento },
});

const getRelatorioStart = () => ({
  type: GET_RELATORIOS_START,
});

const getRelatorioError = () => ({
  type: GET_RELATORIOS_ERROR,
});

const getRelatorioSuccess = (relatorio) => ({
  type: GET_RELATORIOS_SUCCESS,
  payload: { relatorio },
});

const dismissSnackbars = (id) => ({
  type: DISMISS_SNACKBARS,
  payload: { id },
});

const setPaginationControl = (paramName, value) => ({
  type: SET_PAGINATION_CONTROL,
  payload: { paramName, value },
});

const setIsRelatorioSelected = (value) => ({
  type: SET_IS_RELATORIO_SELECTED,
  payload: value,
});

const setIsDateRangeSelected = (value) => ({
  type: SET_IS_DATE_RANGE_SELECTED,
  payload: value,
});

const exportRelatorioStart = () => ({
  type: EXPORT_RELATORIO_START,
});

const exportRelatorioSuccess = () => ({
  type: EXPORT_RELATORIO_SUCCESS,
});

const exportRelatorioError = () => ({
  type: EXPORT_RELATORIO_ERROR,
});

export default {
  types: {
    SET_PAGE_PARAMS,
    SET_FILTER,
    RESET_STORE,
    SELECT_ALL,
    SELECT,
    GET_RELATORIOS_START,
    GET_RELATORIOS_ERROR,
    GET_RELATORIOS_SUCCESS,
    DISMISS_SNACKBARS,
    SET_PAGINATION_CONTROL,
    SET_IS_RELATORIO_SELECTED,
    SET_IS_DATE_RANGE_SELECTED,
    EXPORT_RELATORIO_ERROR,
    EXPORT_RELATORIO_SUCCESS,
    EXPORT_RELATORIO_START,
  },
  setPageParams,
  setFilter,
  resetStore,
  selectAll,
  select,
  getRelatorioStart,
  getRelatorioError,
  getRelatorioSuccess,
  dismissSnackbars,
  setPaginationControl,
  setIsRelatorioSelected,
  setIsDateRangeSelected,
  exportRelatorioStart,
  exportRelatorioSuccess,
  exportRelatorioError,
};
