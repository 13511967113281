import React from 'react';
import PropTypes from 'prop-types';

import './limitesAprovadosList.scss';
import { makeStyles } from '@material-ui/core';
import ListHeader from './listHeader';
import ListRow from './listRow';

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'grid',
    columnGap: '16px',
    borderRadius: '2px',
    gridTemplateRows: (isSidebarOpen) => (
      isSidebarOpen
        ? '0.2fr 1fr 0.8fr 0.5fr'
        : '0.2fr 1fr 0.5fr 0.8fr 0.5fr 1fr '
    ),
    gridTemplateAreas: (isSidebarOpen) => (
      isSidebarOpen
        ? "' id produto valor vencimento '"
        : "' id produto aprovacao valor vencimento status '"
    ),
  },
});

const LimitesAprovadosList = ({
  limites, openSidebar, setOpenSidebar,
  setLimiteSelected, limiteSelected,
}) => {
  const gridTemplate = useStyles(openSidebar);
  return (
    <div className="list-content">
      <div className="list-header-fixed">
        <ListHeader
          openSidebar={openSidebar}
          gridTemplate={gridTemplate}
        />
      </div>
      {limites?.map((d, i) => (
        <ListRow
          key={i}
          data={d}
          setLimiteSelected={setLimiteSelected}
          openSidebar={openSidebar}
          gridTemplate={gridTemplate}
          setOpenSidebar={setOpenSidebar}
          isSelected={d.idLimite === limiteSelected?.idLimite}
        />
      ))}
    </div>
  );
};

LimitesAprovadosList.propTypes = {
  limites: PropTypes.array,
  openSidebar: PropTypes.bool,
  setOpenSidebar: PropTypes.func,
  setLimiteSelected: PropTypes.func,
  limiteSelected: PropTypes.object,
};

LimitesAprovadosList.defaultProps = {
  limites: [],
  openSidebar: false,
  setOpenSidebar: () => {},
  setLimiteSelected: () => {},
  limiteSelected: null,
};

export default LimitesAprovadosList;
