import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency, camelFormat } from 'utils/format';

import './legendaCarteiraBrand.scss';

const LegendaCarteiraBrand = ({ data }) => {
  const total = data.map((item) => item.total).reduce((prev, next) => prev + next);
  const percentage = data.map((d) => Math.round((100 * d.total) / total));

  return (
    <div className="dashboard-floor-plan__legenda-carteiraBrand">
      <div className="dashboard-floor-plan__legenda-carteiraBrand__item">
        <span className="dashboard-floor-plan__legenda-carteiraBrand__item__total">
          Total
        </span>
        <span className="dashboard-floor-plan__legenda-carteiraBrand__item__percentage">
          100%
        </span>
        <span className="dashboard-floor-plan__legenda-carteiraBrand__item__value">
          {formatCurrency(total)}
        </span>
      </div>
      <div className="dashboard-floor-plan__legenda-carteira__detail_line" />
      {data.map((d, i) => (
        <div className="dashboard-floor-plan__legenda-carteiraBrand__item">
          <span className={`color-brand color_${d.brand.toLowerCase()}`} />
          <span className="dashboard-floor-plan__legenda-carteiraBrand__item__brand">
            {camelFormat(d.brand)}
          </span>
          <span className="dashboard-floor-plan__legenda-carteiraBrand__item__percentage">
            {percentage[i]}
            %
          </span>
          <span className="dashboard-floor-plan__legenda-carteiraBrand__item__value">
            {formatCurrency(d.total)}
          </span>
        </div>
      ))}
    </div>
  );
};

LegendaCarteiraBrand.propTypes = {
  data: PropTypes.array,
};

LegendaCarteiraBrand.defaultProps = {
  data: [],
};

export default LegendaCarteiraBrand;
