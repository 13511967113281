import React from 'react';
import PropTypes from 'prop-types';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import TooltipMensagem from 'features/common/tooltipMensagem';

const RenderIconStatus = ({
  pendencia, successMessage, errorMessage,
}) => (
  pendencia
    ? (
      <TooltipMensagem title={errorMessage}>
        <ErrorOutlineIcon style={{ color: '#C31E10', width: 16, height: 16 }} />
      </TooltipMensagem>
    ) : (
      <TooltipMensagem title={successMessage}>
        <CheckRoundedIcon style={{ color: '#206446', width: 16, height: 16 }} />
      </TooltipMensagem>

    )
);

RenderIconStatus.propTypes = {
  pendencia: PropTypes.bool,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
};

RenderIconStatus.defaultProps = {
  pendencia: false,
  successMessage: 'Lista enviada',
  errorMessage: 'Há documentos pendentes para envio',
};

export default RenderIconStatus;
