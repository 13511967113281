import { engine } from './operations';
import { chatStates } from './chat/chatStates';

const forceNextChatState = (state) => {
  if (!state.chatState) { return false; }

  const statesThatForceNext = [
    chatStates.volte_depois,
    chatStates.agradecemos,
    chatStates.td_solicitou_inicio,
    chatStates.td_solicitou_fim,
    chatStates.td_nao_solicitou_inicio,
    chatStates.td_nao_solicitou_fim,
    chatStates.pag_inicio,
    chatStates.pag_fim,
  ];

  return statesThatForceNext.includes(state.chatState);
};

const isTextInputAvailable = (state) => {
  if (!state.chatState) { return false; }

  const statesWithTextInput = [
    chatStates.td_pq_nao_solicitou_outros_qual,
    chatStates.td_dificuldade_qual,
    chatStates.td_melhoria_qual,
    chatStates.melhorar_hub,
    chatStates.pag_n_utilizou_motivo,
    chatStates.pag_melhoria_qual,
  ];

  return statesWithTextInput.includes(state.chatState);
};

const messages = (state) => {
  if (!Array.isArray(state.messages)) {
    return [];
  }

  const currentStateMessages = engine.getActiveMessages(state.chatState, state.answers);
  return state.messages.concat(currentStateMessages);
};

export const selectors = {
  messages,
  isTextInputAvailable,
  forceNextChatState,
};
