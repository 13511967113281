import * as React from "react";

function CheckCircleIcon(props) {
	return (
		<svg width="1em" height="1em" viewBox="0 0 56 56" {...props}>
			<path
				fillRule="evenodd"
				d="M28 4.667C15.12 4.667 4.667 15.12 4.667 28 4.667 40.88 15.12 51.333 28 51.333c12.88 0 23.333-10.453 23.333-23.333C51.333 15.12 40.88 4.667 28 4.667zM21.677 38.01L13.3 29.633a2.324 2.324 0 010-3.29c.91-.91 2.38-.91 3.29 0l6.743 6.72L39.387 17.01c.91-.91 2.38-.91 3.29 0 .91.91.91 2.38 0 3.29l-17.71 17.71c-.887.91-2.38.91-3.29 0z"
			/>
		</svg>
	);
}

export default CheckCircleIcon;

