import React from 'react';
import PropTypes from 'prop-types';

import PaginationFooter from 'features/common-staff/layout/paginationFooter/paginationFooter';

const MovimentacoesPaginationFooter = ({
  page, ipp, totalItems, loading, setPage, setIpp,
}) => (
  <PaginationFooter
    ipp={ipp}
    page={page}
    totalItems={totalItems}
    loading={loading}
    setIpp={setIpp}
    setPageFetch={setPage}
  />
);

MovimentacoesPaginationFooter.propTypes = {
  page: PropTypes.number,
  ipp: PropTypes.number,
  totalItems: PropTypes.number,
  loading: PropTypes.bool,
  setPage: PropTypes.func,
  setIpp: PropTypes.func,
};

MovimentacoesPaginationFooter.defaultProps = {
  page: null,
  ipp: null,
  totalItems: null,
  loading: false,
  setPage: () => {},
  setIpp: () => {},
};

export default MovimentacoesPaginationFooter;
