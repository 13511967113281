import styled from 'styled-components';
import colors from 'assets/styles/colors';

export const Container = styled.div`
  padding: 32px 24px;
  width: 520px;
  border-radius: 4px;
  border: 1px solid #C5CEE0;
  background-color: white;
  display: flex;
  flex-direction: column;
  .modal-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: nowrap;
    justify-content: space-between;

    .message {
      display: flex;
      flex-direction: column;

      .title {
        font-family: CircularStd, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: ${colors.secundary_color_800};
      }
      .subtitle {
        font-family: CircularStd, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        margin: 24px 30px;
        margin-bottom: 24px;
        color: ${colors.secundary_color_700};
        white-space: pre-wrap;
      }
    }

    .close-button {
      margin-left: auto;
      height: 24px;
    }
  }

  .modal-body {
    margin: 0px 30px 24px 30px;
  }

  .modal-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 40px;

    .return-button {
      margin-right: 8px;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;
