import React from 'react';
import PropTypes from 'prop-types';

import FormInput from 'features/common-staff/controls/input/formInput/';

const InputConcessionariaNome = ({
  concessionariaNome, title,
}) => (
  <FormInput
    type="text"
    label={title}
    value={concessionariaNome}
  />
);

InputConcessionariaNome.propTypes = {
  concessionariaNome: PropTypes.string,
  title: PropTypes.string,
};

InputConcessionariaNome.defaultProps = {
  concessionariaNome: null,
  title: null,
};

export default InputConcessionariaNome;
