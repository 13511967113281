import { connect } from 'react-redux';

import { operations } from '../features/userFeedback/redux/operations';
import { content } from '../features/userFeedback/redux/content';

import Routes from './routes';

const mapStateToProps = ({ authStore }) => ({
  permissions: authStore.permissions,
});

const mapDispatchToProps = (dispatch) => ({
  requestOpenFeedbackTestDrive: () => {
    dispatch(operations.requestOpenContent(content.testDriveFeedback));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
