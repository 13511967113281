import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { ptBR } from '@mui/x-data-grid';
import NewPaginationFooter from '../../common-staff/layout/paginationFooter/paginationFooter';

import { Empty, Error, Loading } from './overlays/overlays';
import { DataGridMui } from './dataGrid.style';

const DataGrid = (props) => {
  const {
    cellPadding, cellPaddingBorder, columns, footer, loading, onSort, overlay, rows,
  } = props;
  const { emptyMessage, errorMessage, loadingMessage } = overlay;

  const columnList = useMemo(() => columns.map((column, index, { length }) => (
    index === 0 || index === length - 1
      ? { ...column, minWidth: column.minWidth + cellPadding + cellPaddingBorder }
      : { ...column, minWidth: column.minWidth + (cellPadding * 2) }
  )), [columns]);

  const rowList = !loading && Array.isArray(rows) ? rows : [];

  const handleSortModelChange = useCallback((newSortModel) => {
    const model = newSortModel[0];
    onSort(model.field, model.sort);
  }, []);

  return (
    <DataGridMui
      rowHeight={56}
      headerHeight={36}
      disableColumnMenu
      disableColumnReorder
      hideFooter={!footer}
      hideFooterSelectedRowCount
      sortingMode="server"
      sortingOrder={['asc', 'desc']}
      onSortModelChange={handleSortModelChange}
      components={{
        Pagination: () => <NewPaginationFooter {...footer} loading={loading} isDataGrid />,
        NoRowsOverlay: () => <Empty message={emptyMessage} />,
        ErrorOverlay: () => <Error message={errorMessage} />,
        LoadingOverlay: () => <Loading message={loadingMessage} />,
      }}
      {...props}
      columns={columnList}
      rows={rowList}
      localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
    />
  );
};

DataGrid.propTypes = {
  ...DataGridMui.propTypes,
  footer: PropTypes.shape(NewPaginationFooter.propTypes),
  onSort: PropTypes.func,
  overlay: PropTypes.shape({
    emptyMessage: PropTypes.string,
    errorMessage: PropTypes.string,
    loadingMessage: PropTypes.string,
  }),
  cellPadding: PropTypes.number,
  cellOuterPadding: PropTypes.number,
  dataCy: PropTypes.string,
};

DataGrid.defaultProps = {
  ...DataGridMui.defaultProps,
  footer: NewPaginationFooter.defaultProps,
  onSort: () => {},
  overlay: {},
  cellPadding: 16,
  cellPaddingBorder: 32,
  dataCy: 'table',
};

export default DataGrid;
