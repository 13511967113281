import styled from 'styled-components';
import colors from 'assets/styles/colors';
import { KeyboardArrowDownRounded } from '@material-ui/icons';

export const IconDropdown = styled(KeyboardArrowDownRounded)`
  color: ${colors.primary_color_700};
`;

export const ContainerStyle = () => ({
  boxShadow: 'none',
  '::before': {
    display: 'none',
  },
});

export const HeaderStyle = () => ({
  height: '32px',
  minHeight: 'unset',
  borderRadius: '2px',
  borderBottom: `1px solid ${colors.primary_color_100}`,
  backgroundColor: colors.primary_color_100_36,
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '16px',
  color: colors.primary_color_700,
  padding: '0px 32px 0px 16px',
  '.MuiAccordionSummary-content': {
    margin: 'unset',
  },
});

export const ContentStyle = () => ({
  padding: '8px 36px 16px 16px',
});
