import { connect } from 'react-redux';
import ValorDuplicatasArea from './valorDuplicatasArea';

import selectors from '../../redux/selectors';

const mapStateToProps = ({ dashboard }) => ({
  isLoading: dashboard.painelFloorPlan.valoresDuplicatas.isLoading,
  isError: dashboard.painelFloorPlan.valoresDuplicatas.isError,
  isEmpty: selectors.isEmptyValorDuplicata(dashboard),
});

export default connect(mapStateToProps, null)(ValorDuplicatasArea);
