import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import TypeText from './textInputBase';

const TextInput = ({
  value, setValue, disabled, ...other
}) => (
  <TypeText
    value={value}
    disabled={disabled}
    setValue={(event) => {
      setValue(_.isEmpty(event) ? null : event);
    }}
    {...other}
  />
);

TextInput.propTypes = {
  setValue: PropTypes.func,
  value: PropTypes.any,
  disabled: PropTypes.bool,
};

TextInput.defaultProps = {
  setValue: () => {},
  value: '',
  disabled: true,
};

export default TextInput;
