import React from "react";

const SemaphoreIcon = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			d="M11.307 5.06a.343.343 0 00-.334-.393h-1.64v-.76a2.673 2.673 0 001.974-2.18.343.343 0 00-.334-.394h-1.64V.667C9.333.3 9.033 0 8.667 0H3.333c-.366 0-.666.3-.666.667v.666h-1.64a.343.343 0 00-.334.394 2.673 2.673 0 001.974 2.18v.76h-1.64a.343.343 0 00-.334.393 2.673 2.673 0 001.974 2.18V8h-1.64a.343.343 0 00-.334.393 2.673 2.673 0 001.974 2.18v.76c0 .367.3.667.666.667h5.334c.366 0 .666-.3.666-.667v-.76a2.673 2.673 0 001.974-2.18.343.343 0 00-.334-.393h-1.64v-.76a2.673 2.673 0 001.974-2.18zM6 10.667A1.333 1.333 0 116 8a1.333 1.333 0 010 2.666zm0-3.334a1.333 1.333 0 110-2.666 1.333 1.333 0 010 2.666zM6 4a1.333 1.333 0 110-2.666A1.333 1.333 0 016 4z"
			fill={props.fill ? `${props.fill}` : "currentColor"}
			fillRule="evenodd"
		/>
	</svg>
);

export default SemaphoreIcon;

