import React from "react";

const PriorityHighIcon = props => (
	<svg width="1em" height="1em" viewBox="0 0 2 6" {...props}>
		<path
			d="M1 4.667A.667.667 0 101 6a.667.667 0 000-1.333zM1 0C.633 0 .333.3.333.667v2.666C.333 3.7.633 4 1 4c.367 0 .667-.3.667-.667V.667C1.667.3 1.367 0 1 0z"
			fill="#FFF"
			fillRule="evenodd"
		/>
	</svg>
);

export default PriorityHighIcon;

