/* eslint-disable class-methods-use-this */
import axios from 'axios';
import _ from 'lodash';

class AcompanhamentoService {
  async getPedidos(filters) {
    const response = await axios.post(
      `${window.env.REACT_APP_API_URL}/dealer/pedidos`,
      filters,
    );
    if (!response.data || response.status !== 200) {
      throw new Error('Falha na buscar os pedidos');
    }
    if (response.status === 200) {
      if (_.isArray(response?.data?.pedidos)) {
        response.data.pedidos.forEach((p) => {
          // eslint-disable-next-line no-param-reassign
          p.data = new Date(p.data);
        });
      }
    }
    return response.data;
  }

  async getDealerStatus() {
    const response = await axios.get(`${window.env.REACT_APP_API_URL}/dealer/pedidos/status`);
    if (!response.data || response.status !== 200) {
      throw new Error('Falha na busca do status do dealer!');
    }

    const status = response.data.map((p) => ({
      text: p,
      value: p,
    }));

    return status;
  }
}

export default new AcompanhamentoService();
