import styled, { css } from 'styled-components';
import colors from 'assets/styles/colors';

const AlertCard = styled.div`
  display: flex;
  flex-direction: column;

  ${({ staff }) => (staff
    ? css`
      align-items: center;
      background: white;
      border: ${(props) => (props.colorBase ? `1px solid ${props.colorBase}` : `1px solid ${colors.error_color_100}`)};
      border-radius: 8px;
      margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '12px')};
    ` : css`
      background: ${(props) => (props.colorBase ? `${props.colorBase}` : 'white')};
      border: 1px solid ${colors.alert_color_300};
      border-radius: 4px;
    `
  )};

  padding: ${(props) => (props.padding ? props.padding : '')};
  height: ${(props) => (props.height ?? props.height)};
  width: ${(props) => props.width};
  
  .alert-card-content {
    padding: ${(props) => props.alertCardContent};
  }

  .alert-card-title {
    display: flex;
    flex-direction: row;

    ${({ staff }) => (staff
    ? css`
      gap: 8px;
      font-weight: 500;
      font-size: 12px;
      color: ${colors.secundary_color_700};
    ` : css`
      gap: 10px;
      font-weight: 700;
      font-size: 14px;
      color: ${colors.secundary_color_800};
    `
  )};

    font-family: 'CircularStd', sans-serif;
    font-style: normal;
    line-height: ${(props) => (props.lineHeight ? props.lineHeight : '24px')};
  }

  .alert-card-subtitle{
    font-family: 'CircularStd', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: ${({ staff }) => (staff ? colors.secundary_color_700 : colors.secundary_color_800)};
    margin-left: 32px;
  }
`;

export default AlertCard;
