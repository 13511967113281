/* eslint-disable no-mixed-operators */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  formatCurrency, formatDate, camelFormat,
} from 'utils/format';
import StatusComponent from 'features/common/statusComponent/statusComponent';
import { Sistemas } from '../../../../carteiraPage/redux/enum';

import PaymentButton from './paymentButton';

import './cardInfo.scss';

const textoStatus = () => ({
  aprovado: 'Duplicata paga',
});
const defineMensagem = (invoice, sistema, temPermissaoPagamento) => {
  if (invoice?.duplicataStatusDescricao.toLowerCase().trim() === 'quitada') {
    return (
      <div className="modal-carteira__card-info__container__header__tag">
        <StatusComponent
          textStatus={textoStatus()}
          status="aprovado"
        />
      </div>
    );
  }
  if (!temPermissaoPagamento) {
    return (
      <div className="modal-carteira__card-info__container__header__message">
        Você não tem permissão para realizar o pagamento desta duplicata.
        Consulte o usuário Master da sua concessionária.
      </div>
    );
  }
  if (!invoice?.selectable) {
    return (
      <div className="modal-carteira__card-info__container__header__message">
        Essa duplicata não está disponível para pagamento no HUB, apenas visualização
      </div>
    );
  }
  if (sistema !== invoice.codigoSistema && sistema === Sistemas.chr) {
    return (
      <div className="modal-carteira__card-info__container__header__message">
        Essa duplicata não está disponivel para pagamento pois não pertence ao grupo CJDR
      </div>
    );
  }
  if (sistema !== invoice.codigoSistema && sistema === Sistemas.pla) {
    return (
      <div className="modal-carteira__card-info__container__header__message">
        Essa duplicata não está disponivel para pagamento pois não pertence ao grupo FIAT
      </div>
    );
  }
  return (
    <PaymentButton
      invoice={invoice}
      isDisabled={invoice?.selected}
    />
  );
};

const CardInfo = ({ invoice, sistema, temPermissaoPagamento }) => (

  <div
    className="modal-carteira__card-info__container"
    data-cy="modal-carteira-card-info"
  >
    <div className="modal-carteira__card-info__container__header">
      {`Duplicata nº ${invoice?.duplicataNumero}`}
      <span className="modal-carteira__card-info__container__header__valor">
        {formatCurrency(invoice?.saldoDevedor)}
      </span>
      {`Vencimento: ${formatDate(invoice?.dataVencimento, 'DD MMM YYYY')}`}
      <div className="modal-carteira__card-info__container__header__subtitle">
        {defineMensagem(invoice, sistema, temPermissaoPagamento)}
      </div>
    </div>
    {invoice?.chassis ? (
      <div className="modal-carteira__card-info__container__veiculo">
        {!_.isUndefined(invoice?.chassis) && invoice?.tipoProduto === 12 ? ('') : (
          <div className="modal-carteira__card-info__container__row">
            <div className="modal-carteira__card-info__container__row__param">
              Modelo e versão:
            </div>
            <div className="modal-carteira__card-info__container__row__value">
              {`${camelFormat(invoice?.modeloDescricao, 2)} ${invoice?.versao}`}
            </div>
          </div>
        )}
        {!_.isUndefined(invoice?.chassis) && invoice?.chassis.length !== 17 ? ('') : (
          <div className="modal-carteira__card-info__container__row">
            <div className="modal-carteira__card-info__container__row__param">
              Chassi:
            </div>
            <div className="modal-carteira__card-info__container__row__value">
              {invoice?.chassis}
            </div>
          </div>
        )}
      </div>
    ) : null}
  </div>
);

CardInfo.propTypes = {
  invoice: PropTypes.object,
  sistema: PropTypes.string,
  temPermissaoPagamento: PropTypes.bool,
};

CardInfo.defaultProps = {
  invoice: null,
  sistema: null,
  temPermissaoPagamento: false,
};

export default CardInfo;
