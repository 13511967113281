import React from 'react';

import './legendaAbsolutoDuplicatas.scss';

const LegendaAbsolutoDuplicatas = () => (
  <div className="dashboard-floor-plan__legenda-absolutoDuplicata">
    <div className="dashboard-floor-plan__legenda-absolutoDuplicata__item">
      <span className="azul" />
      <span>Floor Plan</span>
    </div>
    <div className="dashboard-floor-plan__legenda-absolutoDuplicata__item">
      <span className="amarelo" />
      <span>Fundão</span>
    </div>
  </div>
);

export default LegendaAbsolutoDuplicatas;
