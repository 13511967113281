import { getCnpjRaiz } from 'utils/cnpjRaiz';

export const valueGreaterThanZero = (value) => (
  value > 0 ? value : null
);

export const getBody = (filters, pageParams = null) => {
  let selectedConcessionaria = '';
  if (filters.concessionaria === 'all') {
    selectedConcessionaria = filters.cnpj.slice(0, 8);
  } else {
    selectedConcessionaria = filters.concessionaria;
  }
  const body = {
    cnpj: selectedConcessionaria ?? filters.cnpj.slice(0, 8),
    duplicatasChassi: filters.duplicataChassi,
    valorMinimoNotaFiscal: valueGreaterThanZero(filters.valorNotaFiscal.valorMinimo),
    valorMaximoNotaFiscal: valueGreaterThanZero(filters.valorNotaFiscal.valorMaximo),
    dataInicioEntradaFidc: filters.dataInicioEntradaFidc,
    dataFinalEntradaFidc: filters.dataFinalEntradaFidc,
    dataInicioVencimento: filters.dataInicioVencimento,
    dataFinalVencimento: filters.dataFimVencimento,
    nomeColuna: filters.nomeColuna,
    sentidoOrdenacao: filters.sentidoOrdenacao,
    status: filters?.status === 'all' ? null : filters?.status,
    brand: filters?.brand,
  };
  if (pageParams !== null) {
    body.page = pageParams.page;
    body.itensPerPage = pageParams.ipp;
  }
  return body;
};

const getPeriodo = (periodos) => periodos.map((periodo) => {
  const [dataInicio, dataFim] = periodo.text.split(' a ');
  const [diaInicio, mesInicio, anoInicio] = dataInicio.split('/');
  const [diaFim, mesFim, anoFim] = dataFim.split('/');
  return {
    dataInicio: `${anoInicio}-${mesInicio}-${diaInicio}`,
    dataFim: `${anoFim}-${mesFim}-${diaFim}`,
  };
});

export const getBodyHistorico = (filters, pageParams = null) => {
  let selectedConcessionaria = '';
  if (filters.concessionaria === 'all') {
    selectedConcessionaria = null;
  } else {
    selectedConcessionaria = filters.concessionaria;
  }

  const body = {
    cnpj: getCnpjRaiz(filters?.cnpj),
    concessionaria: selectedConcessionaria,
    status: filters?.status === 'all' ? null : filters?.status,
    periodos: filters?.periodo ? getPeriodo(filters?.periodo) : [],
    page: pageParams?.page,
    itensPerPage: pageParams?.ipp,
  };
  return body;
};
