import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import MenuDashboard from './menuDashboard';

const mapStateToProps = ({ authStore }) => ({
  permissions: authStore.permissions,
});

const mapDispatchToProps = (dispatch) => ({
  navTo: (path) => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuDashboard);
