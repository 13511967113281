import { connect } from 'react-redux';

import TestDriveProductCards from './testDriveProductCards';
import * as testDriveOperations from '../../../redux/operations';
import * as testDriveSelectors from '../../../redux/selectors';
import * as solicitacaoOpeations from '../../redux/testDriveOperations';

const mapStateToProps = ({ testDrive }) => ({
  products: testDrive.main.products.data,
  selectedCategory: testDriveSelectors.selectedCategory(testDrive),
  selectedProductId: testDriveSelectors.finalProductId(testDrive),
  isLoading: testDrive.main.products.isLoading,
  isError: testDrive.main.products.isError,
});

const mapDispatchToProps = (dispatch) => ({
  selectProduct: (id) => dispatch(testDriveOperations.selectProduct(id)),
  getOffers: () => dispatch(solicitacaoOpeations.getOffers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TestDriveProductCards);
