import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import XIcon from 'assets/icons/x';

import './closeModalButton.scss';

const useStyles = makeStyles({
  cursor: {
    cursor: (props) => (props.disabled ? 'default' : 'pointer'),
  },
});

const CloseModalButton = ({
  isLoading, disabled, onClick, color, showText,
}) => {
  const classes = useStyles({ disabled });
  return (
    <button
      className={`common__close-modal__button ${classes.cursor}`}
      type="button"
      data-cy="CommonCloseModal"
      disabled={disabled || isLoading}
      onClick={onClick}
    >
      {isLoading ? (
        <div className="common__close-modal__button-loading">
          <CircularProgress className="common__close-modal__button-loading" color="inherit" size="18px" />
        </div>
      ) : (
        <div className="common__close-modal__button-content">
          <XIcon className="common__close-modal__close-icon" color={color || null} />
          { showText && (
            <div className={color ? 'common__close-modal__close-text-color'
              : 'common__close-modal__close-text'}
            >
              Fechar
            </div>
          )}
        </div>
      )}
    </button>
  );
};

CloseModalButton.propTypes = {
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.string,
  showText: PropTypes.bool,
};

CloseModalButton.defaultProps = {
  isLoading: false,
  disabled: false,
  onClick: () => {},
  color: null,
  showText: true,
};

export default CloseModalButton;
