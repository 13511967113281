import { connect } from 'react-redux';
import * as operations from '../../redux/operations';

import EnviarButton from './enviarButton';

import selectors from '../../redux/selectors';

const mapStateToProps = ({ limiteCreditoStore }) => ({
  isLoading: limiteCreditoStore.alteracaoLimite.isLoading,
  isSendDisabled: selectors.isSendDisabled(limiteCreditoStore),
});

const mapDispatchToProps = (dispatch) => ({
  enviarPedido: () => dispatch(
    operations.submitSolicitacao(),
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(EnviarButton);
