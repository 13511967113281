/* eslint-disable */
import React from "react";

const TextSnippetIcon = (props) => (
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path 
			fillRule="evenodd" 
			clipRule="evenodd" 
			d="M3.7219 2.25H10.5478C10.9423 2.25 11.322 2.4075 11.5974 2.6925L15.1927 6.315C15.4756 6.5925 15.6319 6.975 15.6319 7.3725V14.25C15.6319 15.075 14.962 15.75 14.1432 15.75H3.7219C2.90309 15.75 2.23315 15.075 2.23315 14.25V3.75C2.23315 2.925 2.90309 2.25 3.7219 2.25ZM11.91 11.25H5.95503C5.54562 11.25 5.21065 11.5875 5.21065 12C5.21065 12.4125 5.54562 12.75 5.95503 12.75H11.91C12.3194 12.75 12.6544 12.4125 12.6544 12C12.6544 11.5875 12.3194 11.25 11.91 11.25ZM5.95503 8.25H11.91C12.3194 8.25 12.6544 8.5875 12.6544 9C12.6544 9.4125 12.3194 9.75 11.91 9.75H5.95503C5.54562 9.75 5.21065 9.4125 5.21065 9C5.21065 8.5875 5.54562 8.25 5.95503 8.25ZM9.6769 5.25H5.95503C5.54562 5.25 5.21065 5.5875 5.21065 6C5.21065 6.4125 5.54562 6.75 5.95503 6.75H9.6769C10.0863 6.75 10.4213 6.4125 10.4213 6C10.4213 5.5875 10.0863 5.25 9.6769 5.25Z" 
			fill={props.fill}
			/>
	</svg>
);

export default TextSnippetIcon;

