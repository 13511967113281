import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from 'utils/format';
import './customTooltip.scss';

const CustomTooltip = ({ payload }) => (
  <div className="tooltip__brand">
    <div className="tooltip__brand__valor">
      <div className="tooltip__brand__cor" style={{ backgroundColor: payload[0]?.payload?.fill }} />
      {formatCurrency(payload[0]?.value)}
    </div>
  </div>
);

CustomTooltip.propTypes = {
  payload: PropTypes.arrayOf(PropTypes.object),
};

CustomTooltip.defaultProps = {
  payload: [],
};
export default CustomTooltip;
