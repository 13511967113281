const SET_OPEN = 'pedidosDetalhe/SET_OPEN';

const PREFIX_GET_DETALHE_PEDIDO = 'detalhePedidos/GET_DETALHE_PEDIDO';
const GET_DETALHE_PEDIDO_START = `${PREFIX_GET_DETALHE_PEDIDO}/START`;
const GET_DETALHE_PEDIDO_SUCCESS = `${PREFIX_GET_DETALHE_PEDIDO}/SUCCESS`;
const GET_DETALHE_PEDIDO_ERROR = `${PREFIX_GET_DETALHE_PEDIDO}/ERROR`;

const PREFIX_CANCEL_PEDIDO = 'detalhePedidos/CANCEL_PEDIDO';
const CANCEL_PEDIDO_START = `${PREFIX_CANCEL_PEDIDO}/START`;
const CANCEL_PEDIDO_SUCCESS = `${PREFIX_CANCEL_PEDIDO}/SUCCESS`;
const CANCEL_PEDIDO_ERROR = `${PREFIX_CANCEL_PEDIDO}/ERROR`;

const setOpen = (isOpen) => ({
  type: SET_OPEN,
  payload: { isOpen },
});

const getDetalhePedidoStart = () => ({
  type: GET_DETALHE_PEDIDO_START,
});

const getDetalhePedidoSuccess = (detalhePedido) => ({
  type: GET_DETALHE_PEDIDO_SUCCESS,
  payload: { detalhePedido },
});

const getDetalhePedidoError = () => ({
  type: GET_DETALHE_PEDIDO_ERROR,
});

const cancelPedidoStart = () => ({
  type: CANCEL_PEDIDO_START,
});

const cancelPedidoSuccess = () => ({
  type: CANCEL_PEDIDO_SUCCESS,
});

const cancelPedidoError = () => ({
  type: CANCEL_PEDIDO_ERROR,
});

export default {
  types: {
    SET_OPEN,

    PREFIX_GET_DETALHE_PEDIDO,
    GET_DETALHE_PEDIDO_START,
    GET_DETALHE_PEDIDO_SUCCESS,
    GET_DETALHE_PEDIDO_ERROR,

    PREFIX_CANCEL_PEDIDO,
    CANCEL_PEDIDO_START,
    CANCEL_PEDIDO_SUCCESS,
    CANCEL_PEDIDO_ERROR,
  },

  setOpen,

  getDetalhePedidoStart,
  getDetalhePedidoSuccess,
  getDetalhePedidoError,

  cancelPedidoStart,
  cancelPedidoSuccess,
  cancelPedidoError,

};
