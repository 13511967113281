import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

import XIcon from 'assets/icons/x';

import './closeModalButton.scss';

const CloseModalButton = ({
  isLoading, disabled, onClick,
}) => (
  <button
    className="common__close-modal__button"
    type="button"
    disabled={disabled || isLoading}
    onClick={onClick}
  >
    {isLoading ? (
      <div className="common__close-modal__button-loading">
        <CircularProgress className="common__close-modal__button-loading" color="inherit" size="18px" />
      </div>
    ) : (
      <div
        className="common__close-modal__button-content"
        data-cy="CommonCloseModalButton"
      >
        <XIcon className="common__close-modal__close-icon" />
        <div className="common__close-modal__close-text">
          Fechar
        </div>
      </div>
    )}
  </button>
);

CloseModalButton.propTypes = {
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

CloseModalButton.defaultProps = {
  isLoading: false,
  disabled: false,
  onClick: () => {},
};

export default CloseModalButton;
