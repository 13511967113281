import { actions } from './actions';
import { etapas } from './etapas';

const INITIAL_STATE = {
  categoriasDisponiveis: [],
  etapaAtual: etapas.sentimosMuito,
  respostaAtual: {
    categoria: undefined,
    descricao: undefined,
  },

  history: [],
};

export default (state = INITIAL_STATE, action = { type: 'default' }) => {
  switch (action.type) {
    case actions.types.GO_FROM_SENTIMOS_MUITO_TO_SELECIONAR_CATG:
      return {
        ...state,
        etapaAtual: etapas.selecionarCategoria,
        history: [
          ...state.history,
          {
            etapa: etapas.sentimosMuito,
            dados: null,
          },
        ],
      };
    case actions.types.SELECIONAR_CATEGORIA:
      return {
        ...state,
        etapaAtual: etapas.descreverProblema,
        respostaAtual: {
          ...state.respostaAtual,
          categoria: action.payload.categoria,
        },
        history: [
          ...state.history,
          {
            etapa: etapas.selecionarCategoria,
            dados: {
              categoriasDisponiveis: state.categoriasDisponiveis,
              categoriaSelecionada: action.payload.categoria,
            },
          },
        ],
      };
    case actions.types.DESCREVER_PROBLEMA:
      return {
        ...state,
        etapaAtual: etapas.reportarOutro,
        respostaAtual: {
          ...state.respostaAtual,
          descricao: action.payload.descricao,
        },
        history: [
          ...state.history,
          {
            etapa: etapas.descreverProblema,
            dados: {
              descricao: action.payload.descricao,
            },
          },
        ],
      };
    case actions.types.REPORTAR_OUTRO: {
      let proximaEtapa;
      let proximaResposta;
      let itemHistory;
      if (action.payload.querReportarOutro === true) {
        proximaEtapa = etapas.selecionarCategoria;
        proximaResposta = { categoria: undefined, descricao: undefined };
        itemHistory = {
          etapa: etapas.reportarOutro,
          dados: { reportarOutro: true },
        };
      } else {
        proximaEtapa = etapas.finalizado;
        proximaResposta = null;
        itemHistory = {
          etapa: etapas.reportarOutro,
          dados: { reportarOutro: false },
        };
      }

      return {
        ...state,
        etapaAtual: proximaEtapa,
        respostaAtual: proximaResposta,
        history: [...state.history, itemHistory],
      };
    }
    case actions.types.SET_CATEGORIAS_DISPONIVEIS:
      return {
        ...state,
        categoriasDisponiveis: action.payload.categoriasDisponiveis,
      };
    case actions.types.SET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload.userInfo,
      };
    case actions.types.RESET_STORE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
