import PropTypes from 'prop-types';

const canAccess = (
  requiredBrand,
  selectedDealer,
) => selectedDealer.brand === requiredBrand;

const RenderIfBrandPermission = ({
  requiredBrand,
  children,
  selectedDealer,
}) => (
  canAccess(
    requiredBrand,
    selectedDealer,
  )
    ? (children)
    : null
);

RenderIfBrandPermission.propTypes = {
  children: PropTypes.node.isRequired,
  requiredBrand: PropTypes.string,
  selectedDealer: PropTypes.object,
};

RenderIfBrandPermission.defaultProps = {
  requiredBrand: '',
  selectedDealer: null,
};

export default RenderIfBrandPermission;
