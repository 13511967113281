import actions from './actions';

const INITIAL_STATE = {
  isLoading: false,
  isError: false,

  dados: {
    motivo: null,
    valor: null,
    isPositivo: true,
    selectedDealerId: null,
    produto: null,
    codigoSap: null,
    brand: null,
  },

  produtos: {
    isLoading: false,
    isError: false,
    produtos: null,
  },

  snackbarErrors: [],
  lastSnackbarErrorId: 0,
};

export default (state = INITIAL_STATE, action = { type: 'default' }) => {
  switch (action.type) {
    case actions.types.SET_MOTIVO:
      return {
        ...state,
        dados: {
          ...state.dados,
          motivo: action.payload,
        },
      };
    case actions.types.POST_SOLITACAO_CREDITO_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case actions.types.POST_SOLITACAO_CREDITO_SUCCESS:
      return {
        isLoading: false,
        isError: false,
        dados: {
          motivo: null,
          valor: null,
          isPositivo: true,
          selectedDealerId: null,
          produto: null,
        },
        produtos: {
          ...state.produtos,
        },
        snackbarErrors: [
          ...state.snackbarErrors,
          { id: state.lastSnackbarErrorId + 1, message: 'Solicitação feita com sucesso.', type: 'success' },
        ],
        lastSnackbarErrorId: state.lastSnackbarErrorId + 1,
      };
    case actions.types.POST_SOLITACAO_CREDITO_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        snackbarErrors: [
          ...state.snackbarErrors,
          { id: state.lastSnackbarErrorId + 1, message: 'Erro ao enviar a solicitação.', type: 'error' },
        ],
        lastSnackbarErrorId: state.lastSnackbarErrorId + 1,
      };
    case actions.types.GET_PRODUTOS_START:
      return {
        ...state,
        produtos: {
          ...state.produtos,
          isLoading: true,
          isError: false,
        },
      };
    case actions.types.GET_PRODUTOS_SUCCESS:
      return {
        ...state,
        produtos: {
          ...state.produtos,
          isLoading: false,
          isError: false,
          produtos: action.payload,
        },
      };
    case actions.types.GET_PRODUTOS_ERROR:
      return {
        ...state,
        produtos: {
          ...state.produtos,
          isLoading: false,
          isError: true,
          produtos: null,
        },
      };
    case actions.types.SET_IS_POSITIVO:
      return {
        ...state,
        dados: {
          ...state.dados,
          isPositivo: action.payload,
        },
      };
    case actions.types.SET_PRODUTO:
      return {
        ...state,
        dados: {
          ...state.dados,
          produto: action.payload,
        },
      };
    case actions.types.SET_VALOR:
      return {
        ...state,
        dados: {
          ...state.dados,
          valor: action.payload,
        },
      };
    case actions.types.SET_CODIGO_SAP:
      return {
        ...state,
        dados: {
          ...state.dados,
          codigoSap: action.payload,
        },
      };
    case actions.types.SET_BRAND:
      return {
        ...state,
        dados: {
          ...state.dados,
          brand: action.payload,
        },
      };
    case actions.types.SET_SELECTED_DEALER:
      return {
        ...state,
        dados: {
          ...state.dados,
          selectedDealerId: action.payload,
        },
      };
    case actions.types.DISMISS_SNACKBAR:
      return {
        ...state,
        snackbarErrors: state.snackbarErrors.filter((item) => item.id !== action.payload),
      };
    case actions.types.RESET_STORE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
