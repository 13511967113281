import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { Tracking } from '../modules';

const TrackRoutes = ({ children }) => {
  const location = useLocation();
  React.useEffect(() => {
    Tracking.Mixpanel.trackPageNavigation(location.pathname);
  }, [location.pathname]);

  return (
    <div>
      { children }
    </div>
  );
};

TrackRoutes.propTypes = {
  children: PropTypes.object.isRequired,
};

export default TrackRoutes;
