import React from 'react';
import PropTypes from 'prop-types';

import FormInput from 'features/common-staff/controls/input/formInput/';

const InputRegiaoNome = ({
  regiaoNome,
}) => (
  <FormInput
    type="text"
    label="Regional"
    value={regiaoNome}
  />
);

InputRegiaoNome.propTypes = {
  regiaoNome: PropTypes.string,

};

InputRegiaoNome.defaultProps = {
  regiaoNome: null,

};

export default InputRegiaoNome;
