import React from 'react';
import PropTypes from 'prop-types';

import { Scrollbars } from 'react-custom-scrollbars';
import Modal from 'features/common-staff/layout/modal';
import DadosOrigemModal from './dadosOrigemModal';
import DadosDestinoModal from './dadosDestinoModal';
import DadosSolicitacao from './dadosSolicitacaoModal';
import CancelarButton from './cancelarButton';
import ConfirmarButton from './confirmarButton';

import './modalConfirmacao.scss';

const ModalConfirmacao = ({
  closeModal, isLoading,
}) => (

  <Modal
    disableCloseButton={isLoading}
    closeModal={closeModal}
  >
    <div className="transferencia-limite__modal__container">
      <div
        className="transferencia-limite__modal__container_title"
        data-cy="TransferenciaLimiteModalTitle"
      >
        Dados da Transferência
      </div>
      <div className="transferencia-limite__modal__container_content">
        <Scrollbars>
          <DadosSolicitacao />
          <DadosOrigemModal />
          <DadosDestinoModal />
        </Scrollbars>
      </div>
      <div
        className="transferencia-limite__modal__container_footer"
      >
        <CancelarButton />
        <ConfirmarButton />
      </div>
    </div>
  </Modal>

);

ModalConfirmacao.propTypes = {
  closeModal: PropTypes.func,
  isLoading: PropTypes.bool,
};

ModalConfirmacao.defaultProps = {
  closeModal: () => {},
  isLoading: false,
};

export default ModalConfirmacao;
