import React from 'react';
import PropTypes from 'prop-types';

import SnackbarMessage from '../snackbarMessage';

import ErrorOutlineIcon from '../../../../assets/icons/error-outline';
import CheckCircleFilledIcon from '../../../../assets/icons/check-circle-filled';

import './snackbar.scss';

const Snackbar = ({ message }) => {
  const {
    id, type, onClose, timeout,
  } = message;
  const messageText = message.message;

  if (timeout && timeout > 0) {
    setTimeout(() => onClose(id), timeout);
  }

  return (
    <div className="snackbar-items__container">
      {
        (type === SnackbarMessage.TYPE_SUCCESS)
          ? (
            <div
              className="snackbar-succes__item-container"
              onClick={() => onClose(id)}
              role="button"
              tabIndex="0"
              data-cy="SnackbarSuccess"
            >
              <div className="snackbar__content-block">
                <CheckCircleFilledIcon className="snackbar-succes__image" />
                <span className="snackbar__text">{messageText}</span>
              </div>
            </div>
          ) : (
            <div className="snackbar-error__item-container" onClick={() => onClose(id)} role="button" tabIndex="0">
              <div className="snackbar__content-block">
                <ErrorOutlineIcon className="snackbar-error__image" />
                <span className="snackbar__text">{messageText}</span>
              </div>
            </div>
          )
      }
    </div>
  );
};

Snackbar.propTypes = {
  onClose: PropTypes.func,
  message: PropTypes.object,
  id: PropTypes.number,
  type: PropTypes.string,
  timeout: PropTypes.number,
};

Snackbar.defaultProps = {
  timeout: null,
  type: 'error',
  onClose: () => {},
  message: null,
  id: null,
};

export default Snackbar;
