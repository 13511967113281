import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'utils/format';
import { Tracking } from 'modules';
import { formatAsBRL } from '../../../../helpers/format';
import { Container, Row, Column } from './duplicataListRow.style';
import DuplicataIdentificador from './duplicataIdentificador/duplicataIdentificador';

const DuplicataListRow = ({
  duplicata, gridTemplate, index, openDrawer, isDrawerOpen, isSelected, selfClick,
}) => {
  const [isHover, setIsHover] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (selfClick && ref.current) {
      ref.current.click();
    }
  }, [selfClick, ref]);

  return (
    <Container>
      <Row
        ref={ref}
        data-cy={`DuplicataRow_${index}`}
        className={gridTemplate?.container}
        onClick={() => {
          openDrawer(duplicata, index);
          Tracking.Mixpanel.fidc.trackButtons('fidc_duplicatas_open_drawer_duplicatas');
        }}
        isSelected={isSelected}
        isHover={isHover}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <Column gridArea="duplicata" data-cy="NumeroDuplicataList">
          <DuplicataIdentificador
            duplicata={duplicata}
            isSelected={isSelected}
            isHover={isHover}
          />
        </Column>
        <Column gridArea="valorPrincipal" data-cy="ValorPrincipalDuplicata">
          {formatAsBRL(duplicata?.valorPrincipal)}
        </Column>
        <Column gridArea="valorAtualizado" data-cy="ValorAtualizadoDuplicata">
          {formatAsBRL(duplicata?.saldoAtualizado)}
        </Column>
        <Column gridArea="juros" data-cy="JurosDuplicata">
          {formatAsBRL(duplicata?.juros)}
        </Column>
        {!isDrawerOpen && (
          <>
            <Column gridArea="vencimento" data-cy="VencimentoDuplicata">
              {duplicata?.dataVencimentoProrrogada
                ? formatDate(new Date(duplicata?.dataVencimentoProrrogada), 'DD MMM YYYY')
                : '-- --- ----'}
            </Column>
            <Column gridArea="status" data-cy="StatusDuplicata">
              {duplicata?.dataPagamento ? 'Liquidada' : 'Em Aberto'}
            </Column>

          </>
        )}
      </Row>
    </Container>
  );
};

DuplicataListRow.propTypes = {
  duplicata: PropTypes.object,
  gridTemplate: PropTypes.object,
  index: PropTypes.number,
  openDrawer: PropTypes.func,
  isDrawerOpen: PropTypes.bool,
  isSelected: PropTypes.bool,
  selfClick: PropTypes.bool,
};

DuplicataListRow.defaultProps = {
  duplicata: null,
  gridTemplate: {},
  index: -1,
  openDrawer: () => {},
  isDrawerOpen: false,
  isSelected: false,
  selfClick: false,
};

export default DuplicataListRow;
