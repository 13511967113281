import { connect } from 'react-redux';

import { DealerSelectors } from '../../../../modules/dealers/redux';
import { operations as popoverOperations } from '../../redux/operations';

import { operations } from './redux/operations';
import { selectors } from './redux/selectors';

import UserFeedback from './userFeedback';

const mapStateToProps = ({ feedback, authStore, dealerStore }) => ({
  chatState: feedback.testDrive.chatState,
  messages: selectors.messages(feedback.testDrive),
  isInputAvailable: selectors.isTextInputAvailable(feedback.testDrive),
  forceNextChatState: selectors.forceNextChatState(feedback.testDrive),
  user: authStore.user,
  permissions: authStore.permissions,
  selectedDealer: DealerSelectors.getSelectedFPlanDealer(dealerStore),
  sendAnswerStatus: feedback.testDrive.sendAnswerStatus,
});

const mapDispatchToProps = (dispatch) => ({
  setPopoverContent: (content) => dispatch(popoverOperations.setContent(content)),
  nextChatState: (answer) => dispatch(operations.nextChatState(answer)),
  resetStore: () => dispatch(operations.resetStore()),
  sendAnswer: () => dispatch(operations.sendAnswer()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserFeedback);
