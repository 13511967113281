import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';

import Button from 'features/common-staff/controls/button';
import { Tracking } from 'modules';

const useStyles = makeStyles({
  button: {
    height: 40,
  },
  buttonIcon: {
    height: 18,
    width: 18,
    marginLeft: 6,
  },
});

const AcompanhamentoButton = ({
  navToAcompanhamento,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.button}>
      <Button
        onClick={() => {
          Tracking.Mixpanel.acompanhamentoTd.trackAcompanhamento();
          navToAcompanhamento();
        }}
        className={classes.buttonContent}
        color="darkGray"
      >
        Acompanhar minhas solicitações
        <ArrowForward className={classes.buttonIcon} />
      </Button>
    </div>
  );
};

AcompanhamentoButton.propTypes = {
  navToAcompanhamento: PropTypes.func.isRequired,
};

export default AcompanhamentoButton;
