import React from 'react';
import PropTypes from 'prop-types';

import Select from 'features/common/select';

const defaultValue = [{
  value: new Date().getFullYear(),
  text: `${new Date().getFullYear()}`,
}];

const AnoSelector = ({
  ano, setAno, anosList, isLoading, isBrandSelected,
}) => (
  <Select
    items={anosList.length > 0 ? anosList : defaultValue}
    label="Ano"
    value={ano}
    onSelect={(a) => {
      setAno(a);
    }}
    disabled={isLoading || !isBrandSelected}
  />
);

AnoSelector.propTypes = {
  ano: PropTypes.number,
  setAno: PropTypes.func,
  anosList: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  isBrandSelected: PropTypes.bool,
};

AnoSelector.defaultProps = {
  ano: new Date().getFullYear(),
  setAno: () => {},
  anosList: null,
  isLoading: false,
  isBrandSelected: false,
};

export default AnoSelector;
