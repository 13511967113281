export const permissoes = {
  duplicatas: 'VISUALIZAR_DUPLICATAS',
  movimentacoes: 'VISUALIZAR_MOVIMENTACOES',
  fidc: {
    duplicatasFidc: 'VISUALIZAR_DUPLICATAS_FIDC',
    resgatesJurosCarencia: 'VISUALIZAR_RESGATES_JUROS_CARENCIA_FIDC',
    resgatesHistorico: 'FIDC_VISUALIZAR_HISTORICO_RESGATE',
  },
  duplicatasB: 'VISUALIZAR_DUPLICATAS_B',
  movimentacoesB: 'VISUALIZAR_MOVIMENTACOES_B',
  efetuarPagamento: 'EFETUAR_PAGAMENTO',
  efetuarPagamentoB: 'EFETUAR_PAGAMENTO_B',
  iof: 'SIMULAR_IOF',
  testDrive: 'SOLICITAR_TEST_DRIVE',
  beta: 'USUARIO_BETA',
  relatoriosFinanceiros: 'RELATORIOS_FINANCEIROS_FIDIS',
  rcAcessarRenovacao: 'RC_ACESSAR_RENOVACAO_CADASTRAL',
  dashboardFloorPlan: 'DASHBOARD_FLOOR_PLAN',
  centralComunicacao: 'VISUALIZAR_COMUNICADOS',
  limite: {
    alteracaoCredito: 'LIM_SOLICITAR_ALTERACAO_LIMITE',
    transferenciaCredito: 'LIM_SOLICITAR_ALTERACAO_LIMITE',
  },
  liberacaoLimite: 'LIM_APROVAR_LIBERACAO_LIMITE',
  relatoriosIof: 'RELATORIOS_IOF',
  taxas: 'VISUALIZAR_TAXAS',
};
