import styled, { css } from 'styled-components';
import _ from 'lodash';

import colors from 'assets/styles/colors';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';

export const CloseIcon = styled(Close)`
  width: 24px;
  height: 24px;
  color: #555770;
`;

export const ChildrenContainer = styled.div`
  ${(props) => (props.disabled
    ? ''
    : css`
      pointer-events: ${props.preventAction ? 'none' : ''};
      z-index: 2000;
      position: relative;
    `)};
`;

export const CardTitle = styled.span`
  color: #2A2B38;
  font-family: Circular Std;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; 
  letter-spacing: 0.15px;
  margin-left: 8px;
  margin-right: 16px;
`;

export const CardDescription = styled.span`
  color: ${colors.secundary_color_800};
  font-weight: 450;
  font-family: Circular Std;
  font-size: 14px;
  font-style: normal;
  line-height: 21px; 
`;

export const TextColor = styled.span`
  color: ${(props) => props.color};
  font-weight: ${(props) => props.fontWeight};
`;

export const HeaderContainer = styled.div`
  display: flex;
  margin-left: -2px;
`;

export const ButtonContainer = styled.div`
  position: fixed;
  right: 16px;
  top: 16px;
`;

export const DescriptionContainer = styled.div`
  width: 336px;
  flex-shrink: 0;
  margin-top: 10px;
`;

export const PopperContent = styled.div`
  width: ${(props) => `${_.isNumber(props.width) ? `${props.width - 54}px` : props.width}`};
  height: fit-content;
  padding: 20px 49px 20px 32px;
  border-radius: 4px;
  border: 1px solid rgba(85, 87, 112, 0.24);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: #FFFFFF;
`;

export const useStyles = makeStyles(() => {
  const color = '#ffffff';
  return {
    backdrop: {
      zIndex: 1500,
    },
    popoverRoot: {
      backgroundColor: color,
      maxWidth: 500,
      filter: 'drop-shadow(0px 0px 30px rgba(203,203,203,0.72))',
      borderRadius: '10px',
      boxShadow: '0px 0px 30px rgb(203 203 203 / 72%)',
    },
    content: {
      padding: 20,
    },
    popper: {
      zIndex: 2000,
      '&[data-popper-placement*="bottom"]': {
        marginTop: '0.71em !important',
      },
      '&[data-popper-placement*="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: '-0.71em',
        '&::before': {
          transformOrigin: '0 100%',
        },
      },
      '&[data-popper-placement*="top"]': {
        marginBottom: '0.71em !important',
      },
      '&[data-popper-placement*="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: '-0.71em',
        '&::before': {
          transformOrigin: '100% 0',
        },
      },
      '&[data-popper-placement*="right"]': {
        marginLeft: '0.71em !important',
      },
      '&[data-popper-placement*="right"] $arrow': {
        left: 0,
        marginLeft: '-0.71em',
        height: '1em',
        width: '0.71em',
        '&::before': {
          transformOrigin: '100% 100%',
        },
      },
      '&[data-popper-placement*="left"]': {
        marginRight: '0.71em !important',
      },
      '&[data-popper-placement*="left"] $arrow': {
        right: 0,
        marginRight: '-0.71em',
        height: '1em',
        width: '0.71em',
        '&::before': {
          transformOrigin: '0 0',
        },
      },
    },
    arrow: {
      position: 'absolute',
      width: '1em',
      height: '0.71em',
      boxSizing: 'border-box',
      color,
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: '100%',
        height: '100%',
        backgroundColor: 'currentColor',
        transform: 'rotate(45deg)',
      },
    },
  };
});

export const PopperModifiers = (arrowRef) => [
  {
    name: 'flip',
    enabled: false,
  },
  {
    name: 'preventOverflow',
    enabled: true,
    options: {
      altAxis: true,
      altBoundary: false,
      tether: true,
      tetherOffset: 140,
      rootBoundary: 'document',
      padding: 8,
    },
  },
  {
    name: 'arrow',
    enabled: true,
    options: {
      element: arrowRef,
    },
  },
];
