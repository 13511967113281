import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import {
  Container, Content, Duplicata, Icon, Logo,
} from './duplicataIdentificador.style';
import { getDuplicataAssets } from '../../../../../helpers/duplicata';

const DuplicataIdentificador = ({ duplicata, isSelected, isHover }) => {
  const data = getDuplicataAssets(duplicata, false, isSelected, isHover);

  return (
    <Container>
      <Icon>
        <Tooltip title={data.title}>
          <Logo background={data.background}>
            {data.icon}
          </Logo>
        </Tooltip>
      </Icon>
      <Content>
        {data.title}
        <Duplicata
          isSelected={isSelected}
          isHover={isHover}
          data-cy="NumeroDuplicata"
        >
          {duplicata.numeroDuplicata}
        </Duplicata>
      </Content>
    </Container>
  );
};

DuplicataIdentificador.propTypes = {
  duplicata: PropTypes.object,
  isSelected: PropTypes.bool,
  isHover: PropTypes.bool,
};

DuplicataIdentificador.defaultProps = {
  duplicata: null,
  isSelected: false,
  isHover: PropTypes.bool,
};

export default DuplicataIdentificador;
