import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Page, PageHeader, PageTitle, PageContent, PageFooter, PageSubTitle,
} from 'features/common-staff/layout/page';
import AlertaMensagem from 'features/common/alertaMensagem';

import SnackbarsList from 'features/common-staff/snackbarList';
import SplitView from 'features/common-staff/layout/splitView';
import BreadCrumbAcompanhamento from './views/BreadCrumbAcompanhamento';
import MenuInternetBanking from '../menu/view/menuInternetBanking';
import Filters from './views/filters';
import StatementList from './views/statementList';
import Footer from './views/paginationFooter';
import ModalDetalhe from './views/modal';
import ComprovantePagamento from './views/modal/views/detalhePagamento/comprovantePagamento';
import './statementPage.scss';

const StatementPage = ({
  isModalOpen, closeModal, getMovimentacoes, resetStore, dealerId,
  dismissSnackbar, snackbarErrors, isComprovanteOpen, isUpdated,
}) => {
  useEffect(() => {
    getMovimentacoes();
    return () => resetStore();
  }, [resetStore, dealerId, getMovimentacoes]);
  return (
    <Page>
      <div className="statement-page no-print">
        <section className="statement-page_menu">
          <MenuInternetBanking />
        </section>

        <section className="statement-page_content">
          <SplitView
            open={isModalOpen}
            sideSheetProps={{
              onClickClose: () => {
                closeModal();
              },
              width: 400,
            }}
            renderSideSheetContent={() => <ModalDetalhe />}
          >
            <Page
              minWidth="fit-content"
            >
              <PageHeader>
                <PageSubTitle>
                  <BreadCrumbAcompanhamento />
                </PageSubTitle>
                <PageTitle>Movimentações</PageTitle>
              </PageHeader>
              <PageContent>
                <div className="statement__page__root">
                  {isUpdated ? (
                    <div className="statement__page__root__filters">
                      <Filters isModalOpen={isModalOpen} />
                    </div>
                  ) : null}
                  <div className="statement__page__root__list">
                    {isUpdated ? <StatementList />
                      : (
                        <div
                          className="statement__page__root__list__alert"
                          data-cy="StatementPageRootListAlert"
                        >
                          <AlertaMensagem
                            mensagem="Desculpe, não conseguimos exibir suas duplicatas."
                            subMensagem="Estamos trabalhando para resolver o mais rápido possível."
                          />
                        </div>
                      )}
                  </div>
                </div>
              </PageContent>
              {isUpdated ? (
                <PageFooter>
                  <Footer />
                </PageFooter>
              ) : null}
              <SnackbarsList
                snackbarErrors={snackbarErrors}
                onClose={(id) => dismissSnackbar(id)}
              />
            </Page>
          </SplitView>
        </section>
      </div>
      {
        isComprovanteOpen ? (
          <ComprovantePagamento />
        ) : null
      }
    </Page>
  );
};

StatementPage.propTypes = {
  isModalOpen: PropTypes.bool,
  isComprovanteOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  getMovimentacoes: PropTypes.func,
  resetStore: PropTypes.func,
  dealerId: PropTypes.object,
  dismissSnackbar: PropTypes.func,
  snackbarErrors: PropTypes.arrayOf(PropTypes.object),
  isUpdated: PropTypes.bool,
};

StatementPage.defaultProps = {
  isModalOpen: false,
  isComprovanteOpen: false,
  closeModal: () => {},
  getMovimentacoes: () => {},
  resetStore: () => {},
  dealerId: {},
  dismissSnackbar: () => {},
  snackbarErrors: [],
  isUpdated: true,
};

export default StatementPage;
