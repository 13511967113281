import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

import ProdutoIcon from '../produtoIcon';
import './duplicataIcon.scss';

const chooseClassBasedOnStatus = (status) => {
  switch (status) {
    case 'paid':
      return 'inv-page-icon-green';
    case 'delayed':
      return 'inv-page-icon-red';
    default:
      return 'inv-page-icon-blue';
  }
};

const DuplicataIcon = ({
  className, product, status,
}) => (
  <Tooltip title={product}>
    <div className={`inv-page-section-icon ${chooseClassBasedOnStatus(status)} ${className}`}>
      <ProdutoIcon product={product} status={chooseClassBasedOnStatus(status)} />
    </div>
  </Tooltip>
);

DuplicataIcon.propTypes = {
  status: PropTypes.string,
  product: PropTypes.string,
  className: PropTypes.string,
};

DuplicataIcon.defaultProps = {
  status: undefined,
  product: undefined,
  className: '',
};

export default DuplicataIcon;
