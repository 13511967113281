/* eslint-disable */
import * as React from "react";
import { Svg } from "./key-board-arrow.style";

function KeyBoardArrow({color, rotate, selected, isActive }) {
  return (
    <Svg
      viewBox="0 0 12 8" 
      xmlns="http://www.w3.org/2000/svg" 
      isRotate={rotate}
      isActive={!!isActive}
    >
      <path d="M9.88 6.70994L6 2.82994L2.12 6.70994C1.73 7.09994 1.1 7.09994 0.709999 6.70994C0.319999 6.31994 0.319999 5.68994 0.709999 5.29994L5.3 0.709941C5.69 0.319941 6.32 0.319941 6.71 0.709941L11.3 5.29994C11.69 5.68994 11.69 6.31994 11.3 6.70994C10.91 7.08994 10.27 7.09994 9.88 6.70994Z" fill={selected ? '#1B2961' : color}/>
    </Svg>
	);
}

export default KeyBoardArrow;
