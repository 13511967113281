import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { makeStyles } from '@material-ui/styles';
import colors from 'assets/styles/colors';

const useStyles = makeStyles({
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'space-between',
    height: '24px',
    fontSize: '12px',
    lineHeight: '16px',
  },
  label: {
    color: colors.secundary_color_800,
    fontWeight: 450,
  },
  value: {
    color: colors.text_midnight_500,
    fontWeight: 450,
  },
});

const CardRow = ({ label, value }) => {
  const classes = useStyles();
  return (
    <Box className={classes.row}>
      <Box className={classes.label}>
        {label}
        :
      </Box>
      <Box className={classes.value} data-cy="ValueCardRowDuplicata">{value}</Box>
    </Box>
  );
};

CardRow.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
};

CardRow.defaultProps = {
  label: '',
  value: null,
};

export default CardRow;
