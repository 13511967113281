import { connect } from 'react-redux';
import Dashboard from './dashboard';

const mapStateToProps = ({ authStore }) => ({
  permissions: authStore.permissions,
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
