import {
  NOTIFICATIONS_FINISH_REQUEST, NOTIFICATIONS_INIT_REQUEST,
  SET_NOTIFICATIONS, SET_HAS_ERROR_NOTIFICATIONS, SET_HAS_SUCCESS_NOTIFICATIONS,
  NOTIFICATIONS_FINISH_POST, NOTIFICATIONS_INIT_POST, NOTIFICATIONS_FINISH_REQUEST_LAST_GET_ERROR,
  NOTIFICATIONS_FINISH_REQUEST_LAST_GET_SUCCESS, NOTIFICATIONS_INIT_REQUEST_LAST_GET,
  CONCAT_NOTIFICATIONS,
} from './notificationsActions';

export const INITIAL_STATE = {
  notifications: [],
  loading: false,
  loadingLastGet: false,
  isError: false,
  hasSuccessNotification: false,
  hasErrorNotification: false,
  isErrorPost: false,
  loadingPost: false,
  isErrorLastGet: false,
  isPostSending: false,
  lastGet: null,
};

export default (state = INITIAL_STATE, action = { type: 'default' }) => {
  const { type, payload } = action;

  switch (type) {
    case NOTIFICATIONS_INIT_REQUEST:
      return {
        ...state,
        loading: true,
        isError: false,
      };
    case NOTIFICATIONS_FINISH_REQUEST:
      return {
        ...state,
        isError: payload.isError,
        lastGet: payload.lastGet,
        loading: false,
        isPostSending: false,
      };
    case NOTIFICATIONS_INIT_POST:
      return {
        ...state,
        loadingPost: true,
        isErrorPost: false,
        isPostSending: true,
      };
    case NOTIFICATIONS_FINISH_POST:
      return {
        ...state,
        isErrorPost: payload.isError,
        loadingPost: false,
      };
    case NOTIFICATIONS_INIT_REQUEST_LAST_GET:
      return {
        ...state,
        loadingLastGet: true,
        isErrorLastGet: false,
      };
    case NOTIFICATIONS_FINISH_REQUEST_LAST_GET_SUCCESS:
      return {
        ...state,
        isErrorLastGet: false,
        lastGet: payload.lastGet,
        loadingLastGet: false,
      };
    case NOTIFICATIONS_FINISH_REQUEST_LAST_GET_ERROR:
      return {
        ...state,
        isErrorLastGet: true,
        loadingLastGet: false,
      };
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: payload.notifications,
      };
    case CONCAT_NOTIFICATIONS:
      return {
        ...state,
        notifications: [...action.payload.notifications, ...state.notifications],
      };
    case SET_HAS_ERROR_NOTIFICATIONS:
      return {
        ...state,
        hasErrorNotification: payload.hasErrorNotification,
      };
    case SET_HAS_SUCCESS_NOTIFICATIONS:
      return {
        ...state,
        hasSuccessNotification: payload.hasSuccessNotification,
      };
    default:
      return state;
  }
};
