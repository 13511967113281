import axios from 'axios';
import { getBodyHistorico } from './helper';

class ResgateService {
  constructor() {
    this.URL_API = window.env.REACT_APP_API_URL;
  }

  async getAceiteConcessionaria() {
    const response = await axios.get(`${this.URL_API}/fidc/resgates/concessionaria`);

    if (!response || response.status !== 200) {
      throw new Error('Error get Aceite Concessionaria');
    }

    return response.data;
  }

  async updateAceiteConcessionaria(ocultarMensagem, aceitarResgate) {
    const params = new URLSearchParams();
    params.append('ocultarMensagem', ocultarMensagem);
    params.append('aceitarResgate', aceitarResgate);
    const response = await axios.put(`${this.URL_API}/fidc/resgates/concessionaria`, null, { params });

    if (!response || response.status !== 200) {
      throw new Error('Error Update Aceite Concessionaria');
    }

    return response.status;
  }

  async getPeriodosResgates() {
    const response = await axios.get(`${this.URL_API}/fidc/resgates/periodos`);
    if (!response || response.status !== 200) {
      throw new Error('Error get Periodo Resgate');
    }

    return response.data;
  }

  async getHistoricoResgate(filters, pageParams) {
    const body = getBodyHistorico(filters, pageParams);
    const response = await axios.post(`${this.URL_API}/fidc/resgates/historico`, body);

    if (!response || response.status !== 200) {
      throw new Error(`Error get duplicatas ${body}`);
    }

    return response.data;
  }

  async exportHistoricoResgates(filters) {
    const body = getBodyHistorico(filters);

    const response = await axios.post(
      `${this.URL_API}/fidc/resgates/export`,
      body,
      { responseType: 'arraybuffer' },
    );

    if (!response || response.status !== 200) {
      throw new Error('Error downloading resgates xlsx');
    }

    const fileName = response.headers['content-disposition'].match(/filename=(?<filename>[^,;]+);/)[1];
    return { fileContent: response.data, fileName };
  }
}

export default new ResgateService();
