import { connect } from 'react-redux';

import DetalheSolicitacaoAside from './detalheSolicitacaoAside';

const mapStateToProps = ({ limiteCreditoStore }) => ({
  isLoading: limiteCreditoStore.acompanhamento.detalhes.isLoading,
  isError: limiteCreditoStore.acompanhamento.detalhes.isError,
  tipo: limiteCreditoStore.acompanhamento.detalhes.solicitacao?.tipo,
});

export default connect(mapStateToProps, null)(DetalheSolicitacaoAside);
