export const CONCAT_INVOICES = 'payment/CONCAT_INVOICES';
export const UPDATE_SELECTED_INVOICES = 'payment/UPDATE_SELECTED_INVOICES';
export const UPDATE_SELECTED_INVOICE = 'payment/UPDATE_SELECTED_INVOICE';
export const RESET_STORE = 'payment/RESET_STORE';
export const SET_STEP = 'payment/SET_STEP';
export const INIT_REQUEST = 'payment/INIT_REQUEST';
export const FINISH_REQUEST = 'payment/FINISH_REQUEST';
export const SET_IS_SUBMITTING = 'payment/SET_IS_SUBMITTING';
export const SET_FILTER_SEARCH_TEXT = 'payment/SET_FILTER_SEARCH_TEXT';
export const SET_IS_SEARCH_ACTIVE = 'payment/SET_IS_SEARCH_ACTIVE';
export const SET_SEARCH_TEXT_VALUE = 'payment/SET_SEARCH_TEXT_VALUE';
export const SET_START_DATE = 'payment/SET_START_DATE';
export const SET_END_DATE = 'payment/SET_END_DATE';
export const SET_INVOICES = 'payment/SET_INVOICES';
export const SET_PAYMENT_ID = 'payment/SET_PAYMENT_ID';
export const SET_PAYMENT_USER_INFO = 'payment/SET_PAYMENT_USER_INFO';
export const SET_PAYMENT_DEALER_INFO = 'payment/SET_PAYMENT_DEALER_INFO';
export const SET_PAYMENT_TRANSACTIONS_INFO = 'payment/SET_PAYMENT_TRANSACTIONS_INFO';
export const SET_PAYMENT_TRANSACTIONS = 'payment/SET_PAYMENT_TRANSACTIONS';
export const SET_PAYMENT_TRANSACTIONS_ERROR = 'payment/SET_PAYMENT_TRANSACTIONS_ERROR';
export const RECEIPT_FINISH_REQUEST = 'payment/RECEIPT_FINISH_REQUEST';
export const RECEIPT_INIT_REQUEST = 'payment/RECEIPT_INIT_REQUEST';
export const START_DOWNLOAD = 'payment/START_DOWNLOAD';
export const FINISH_DOWNLOAD = 'payment/FINISH_DOWNLOAD';

export const PAYMENT_STEP_LIST = 'list';
export const PAYMENT_STEP_EDIT = 'edit';
export const PAYMENT_STEP_CONFIRM = 'confirm';
export const PAYMENT_STEP_RECEIPT = 'receipt';

export const concatInvoices = (invoices, hasMoreItens, page) => ({
  type: CONCAT_INVOICES,
  payload: { invoices, hasMoreItens, page },
});

export const setInvoices = (invoices, hasMoreItens) => ({
  type: SET_INVOICES,
  payload: {
    invoices,
    hasMoreItens,
  },
});

export const setPaymentId = (paymentId) => ({
  type: SET_PAYMENT_ID,
  payload: paymentId,
});

export const updateSelectedInvoices = (invoices) => ({
  type: UPDATE_SELECTED_INVOICES,
  payload: { invoices },
});

export const updateSelectedInvoice = (invoice) => ({
  type: UPDATE_SELECTED_INVOICE,
  payload: { invoice },
});

export const setPaymentStep = (step) => ({
  type: SET_STEP,
  payload: { step },
});

export const resetPaymentStore = () => ({
  type: RESET_STORE,
});

export const initRequest = () => ({
  type: INIT_REQUEST,
});

export const setStartDate = (startDate) => ({
  type: SET_START_DATE,
  payload: startDate,
});

export const setEndDate = (endDate) => ({
  type: SET_END_DATE,
  payload: endDate,
});

export const setFilterSearchText = (searchText) => ({
  type: SET_FILTER_SEARCH_TEXT,
  payload: searchText,
});

export const setIsSearchActive = (isActive) => ({
  type: SET_IS_SEARCH_ACTIVE,
  payload: isActive,
});

export const setSearchTextValue = (value) => ({
  type: SET_SEARCH_TEXT_VALUE,
  payload: value,
});

export const finishRequest = () => ({
  type: FINISH_REQUEST,
});

export const setIsSubmitting = (isSubmitting) => ({
  type: SET_IS_SUBMITTING,
  payload: isSubmitting,
});

export const setPaymentUserInformation = (usuarioEmail, usuarioNome) => ({
  type: SET_PAYMENT_USER_INFO,
  payload: {
    usuarioEmail,
    usuarioNome,
  },
});

export const setPaymentDealerInformation = (
  concessionariaCnpj,
  concessionariaNome,
  concessionariaCodigo,
) => ({
  type: SET_PAYMENT_DEALER_INFO,
  payload: {
    concessionariaCnpj,
    concessionariaNome,
    concessionariaCodigo,
  },
});

export const setPaymentTransactionInformation = (data, formaPagamento, valorTotal) => ({
  type: SET_PAYMENT_TRANSACTIONS_INFO,
  payload: {
    data,
    formaPagamento,
    valorTotal,
  },
});

export const setPaymentTransactions = (movimentacoes) => ({
  type: SET_PAYMENT_TRANSACTIONS,
  payload: movimentacoes,
});

export const initRequestReceipt = () => ({
  type: RECEIPT_INIT_REQUEST,
});

export const finishRequestReceipt = (isTransactionError) => ({
  type: RECEIPT_FINISH_REQUEST,
  payload: isTransactionError,
});

export const startDownload = () => ({
  type: START_DOWNLOAD,
});

export const finishDownload = (error = null) => ({
  type: FINISH_DOWNLOAD,
  payload: error,
});
