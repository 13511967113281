import React from 'react';
import PropTypes from 'prop-types';

import { formatDate } from 'utils/format';

import './header.scss';

const Header = ({ tipo, date }) => (
  <div className="acompanhamento__detalhe__cabecalho">
    <span
      className="acompanhamento__detalhe__cabecalho__tipo"
      data-cy="AcompDetalheCabecalhoTipo"
    >
      {`Pedido de ${tipo.toLowerCase()}`}
    </span>
    <span
      className="acompanhamento__detalhe__cabecalho__date"
      data-cy="AcompDetalheCabecalhoDate"
    >
      {`Realizado em ${formatDate(date, 'DD MMM YYYY')} às ${formatDate(date, 'HH:mm')}`}
    </span>
  </div>
);

Header.propTypes = {
  tipo: PropTypes.string,
  date: PropTypes.object,
};

Header.defaultProps = {
  tipo: '',
  date: null,
};

export default Header;
