import styled from '@emotion/styled';
import { DataGrid } from '@mui/x-data-grid';
import colors from 'assets/styles/colors';

const getDisabledColumns = (columnVisibilityModel) => {
  if (columnVisibilityModel) {
    const keys = Object.keys(columnVisibilityModel);
    if (keys.length) {
      return keys.filter((key) => columnVisibilityModel[key] === false)?.length || 0;
    }
  }
  return 0;
};

export const DataGridMui = styled(DataGrid)((
  {
    isRowSelectable = true,
    ...props
  },
) => ({
  '&.MuiDataGrid-root': {
    border: 'none',
  },

  '.MuiDataGrid-columnSeparator': {
    display: 'none',
  },

  '& .MuiDataGrid-sortIcon': {
    opacity: 'inherit !important',
  },

  '& .MuiDataGrid-row': {
    borderBottom: `1px solid ${colors.secundary_color_100}`,
    ':hover': {
      backgroundColor: isRowSelectable ? colors.primary_color_100_16 : 'inherit',
      cursor: isRowSelectable ? 'pointer' : 'default',
    },
    '&.Mui-selected': {
      pointerEvents: props.checkboxSelection ? 'auto' : 'none',
      backgroundColor: colors.primary_color_100_36,
      '&:hover': {
        backgroundColor: colors.primary_color_100_36,
      },
    },
    'div[role="cell"]': {
      color: colors.secundary_color_700,
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
      padding: `0px ${props.cellPadding}px`,
      ':first-of-type': {
        paddingLeft: props.cellPaddingBorder,
      },
      [`:nth-of-type(${props.columns.length - getDisabledColumns(props.columnVisibilityModel)})`]: {
        paddingRight: props.cellPaddingBorder,
      },
    },
  },

  '& .MuiDataGrid-cell': {
    borderBottom: 'none',
    ':focus-within': {
      outline: 'none !important',
    },
  },

  '& .MuiDataGrid-columnHeaders': {
    borderBottom: `1px solid ${colors.primary_color_100_48}`,
    'div[role="columnheader"]': {
      padding: `0px ${props.cellPadding - 2}px`,
      ':first-of-type': {
        paddingLeft: props.cellPaddingBorder - 2,
      },
      ':last-child': {
        paddingRight: props.cellPaddingBorder - 2,
      },
    },
  },

  '& .MuiDataGrid-columnHeader': {
    pointerEvents: 'none',
    color: colors.secundary_color_800,
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    ':focus-within': {
      outline: 'none !important',
    },
    '&--alignRight .MuiDataGrid-iconButtonContainer': {
      paddingLeft: '8px',
      paddingRight: '0px',
    },
    '&--sortable .MuiDataGrid-columnHeaderTitleContainer': {
      borderRadius: '2px',
      '&:hover': {
        backgroundColor: colors.primary_color_100_16,
      },
    },
    '&--sorted .MuiDataGrid-columnHeaderTitleContainer': {
      borderRadius: '2px',
      color: `${colors.primary_color_600} !important`,
      backgroundColor: colors.primary_color_100_24,
      path: {
        color: colors.primary_color_600,
      },
      '&:hover': {
        backgroundColor: colors.primary_color_100_16,
      },
    },
    '&TitleContainer': {
      margin: '3px -8px',
      flex: 'unset',
      '&Content': {
        pointerEvents: props.loading ? 'none' : 'auto',
        height: '30px',
        padding: '0px 10px',
      },
    },
  },

  '& .MuiDataGrid-iconButtonContainer': {
    pointerEvents: props.loading ? 'none' : 'auto',
    padding: '1px 8px 1px 0px',
    visibility: 'visible',
    width: 'auto',
    button: {
      padding: '0px',
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
      '&:hover': {
        backgroundColor: 'unset',
      },
      '&:active': {
        backgroundColor: 'unset',
      },
    },
  },

  '& .MuiDataGrid-footerContainer': {
    borderTop: `1px solid  ${colors.primary_color_100_48}`,
  },
}));
