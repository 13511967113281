import { connect } from 'react-redux';
import * as operations from '../../redux/operations';
import selectors from '../../redux/selectors';

import DadosOrigem from './dadosOrigem';

const mapStateToProps = ({ limiteCreditoStore, dealerStore }) => ({
  codigoSap: limiteCreditoStore.transferenciaLimite.dadosOrigem.codigoSap,
  error: selectors.isValidSapOrigem(limiteCreditoStore),
  brand: limiteCreditoStore.transferenciaLimite.dadosOrigem.brand,
  produtos: limiteCreditoStore.transferenciaLimite.produtos?.produtos,
  isLoading: limiteCreditoStore.transferenciaLimite.produtos.isLoading,
  isError: limiteCreditoStore.transferenciaLimite.produtos.isError,
  selectedProduct: limiteCreditoStore.transferenciaLimite.dadosOrigem.produto,
  concessionarias: dealerStore.dealers,
  selectedDealerId: limiteCreditoStore.transferenciaLimite.dadosOrigem.selectedDealerId,
});

const mapDispatchToProps = (dispatch) => ({
  setCodigoSap: (valor) => dispatch(operations.setCodigoSapOrigem(valor)),
  setConcessionariaSelecionada: (concessionaria) => dispatch(
    operations.setSelectedDealerOrigem(concessionaria),
  ),
  setProduto: (produto) => dispatch(operations.setProdutoOrigem(produto)),
  setBrand: (brand) => dispatch(operations.setBrandOrigem(brand)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DadosOrigem);
