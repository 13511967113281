import * as React from "react";

function SvgCare(props) {
	return (
		<svg width="1em" height="1em" viewBox="0 0 43 40" {...props}>
			<defs>
				<radialGradient
					id="care_svg__b"
					cx="49.95%"
					cy="49.95%"
					r="50%"
					fx="49.95%"
					fy="49.95%"
				>
					<stop offset="0%" stopColor="#F28A2D" stopOpacity={0} />
					<stop offset="100%" stopColor="#F08423" stopOpacity={0.34} />
				</radialGradient>
				<radialGradient
					id="care_svg__c"
					cx="10.817%"
					cy="52.004%"
					r="10.1%"
					fx="10.817%"
					fy="52.004%"
				>
					<stop offset="0%" stopColor="#F28A2D" stopOpacity={0.5} />
					<stop offset="100%" stopColor="#F28A2D" stopOpacity={0} />
				</radialGradient>
				<radialGradient
					id="care_svg__d"
					cx="74.173%"
					cy="73.585%"
					r="28.3%"
					fx="74.173%"
					fy="73.585%"
				>
					<stop offset="0%" stopColor="#F28A2D" stopOpacity={0.5} />
					<stop offset="100%" stopColor="#F28A2D" stopOpacity={0} />
				</radialGradient>
				<radialGradient
					id="care_svg__e"
					cx="31.836%"
					cy="12.686%"
					r="10.7%"
					fx="31.836%"
					fy="12.686%"
				>
					<stop offset="0%" stopColor="#D45F00" stopOpacity={0.15} />
					<stop offset="100%" stopColor="#F28A2D" stopOpacity={0} />
				</radialGradient>
				<radialGradient
					id="care_svg__f"
					cx="68.071%"
					cy="13.419%"
					r="10.6%"
					fx="68.071%"
					fy="13.419%"
				>
					<stop offset="0%" stopColor="#D45F00" stopOpacity={0.15} />
					<stop offset="100%" stopColor="#D45F00" stopOpacity={0} />
				</radialGradient>
				<radialGradient
					id="care_svg__h"
					cx="52.037%"
					cy="64.962%"
					r="88.554%"
					fx="52.037%"
					fy="64.962%"
					gradientTransform="matrix(0 -.86829 1 0 -.13 1.101)"
				>
					<stop offset="0%" stopColor="#3B446B" />
					<stop offset="68.8%" stopColor="#202340" />
				</radialGradient>
				<radialGradient
					id="care_svg__i"
					cx="45.763%"
					cy="64.915%"
					r="84.529%"
					fx="45.763%"
					fy="64.915%"
					gradientTransform="matrix(0 -.90963 1 0 -.192 1.065)"
				>
					<stop offset="0%" stopColor="#3B446B" />
					<stop offset="68.8%" stopColor="#202340" />
				</radialGradient>
				<radialGradient
					id="care_svg__j"
					cx="47.812%"
					cy="39.874%"
					r="30.126%"
					fx="47.812%"
					fy="39.874%"
					gradientTransform="matrix(-.09532 -.96847 .3705 -.24915 .376 .96)"
				>
					<stop offset="0%" stopColor="#E38200" />
					<stop offset="100%" stopColor="#CD6700" />
				</radialGradient>
				<radialGradient
					id="care_svg__k"
					cx="50.101%"
					cy="25.878%"
					r="30.945%"
					fx="50.101%"
					fy="25.878%"
					gradientTransform="matrix(.07392 -.9819 .38319 .18941 .365 .702)"
				>
					<stop offset="0%" stopColor="#E38200" />
					<stop offset="100%" stopColor="#CD6700" />
				</radialGradient>
				<radialGradient
					id="care_svg__m"
					cx="73.609%"
					cy="44.429%"
					r="28.887%"
					fx="73.609%"
					fy="44.429%"
					gradientTransform="scale(.9379 1) rotate(20.393 .76 .58)"
				>
					<stop offset="0%" stopColor="#FF7091" stopOpacity={0.7} />
					<stop offset="100%" stopColor="#FE6D8E" stopOpacity={0} />
				</radialGradient>
				<radialGradient
					id="care_svg__n"
					cx="26.559%"
					cy="29.879%"
					r="28.887%"
					fx="26.559%"
					fy="29.879%"
					gradientTransform="scale(.9379 1) rotate(20.393 .274 .348)"
				>
					<stop offset="0%" stopColor="#FF7091" stopOpacity={0.7} />
					<stop offset="100%" stopColor="#FE6D8E" stopOpacity={0} />
				</radialGradient>
				<radialGradient
					id="care_svg__o"
					cx="23.638%"
					cy="53.507%"
					r="24.803%"
					fx="23.638%"
					fy="53.507%"
					gradientTransform="scale(-.9379 -1) rotate(-78.39 -.664 .3)"
				>
					<stop offset="0%" stopColor="#9C0600" />
					<stop offset="100%" stopColor="#9C0600" stopOpacity={0} />
				</radialGradient>
				<radialGradient
					id="care_svg__p"
					cx="100.091%"
					cy="56.034%"
					r="14.942%"
					fx="100.091%"
					fy="56.034%"
					gradientTransform="matrix(.80701 .50954 -.4779 .86045 .46 -.432)"
				>
					<stop offset="0%" stopColor="#9C0600" stopOpacity={0.5} />
					<stop offset="100%" stopColor="#9C0600" stopOpacity={0} />
				</radialGradient>
				<radialGradient
					id="care_svg__q"
					cx="3.802%"
					cy="24.444%"
					r="12.949%"
					fx="3.802%"
					fy="24.444%"
					gradientTransform="scale(.9379 1) rotate(-20.159 .04 .237)"
				>
					<stop offset="0%" stopColor="#9C0600" stopOpacity={0.5} />
					<stop offset="100%" stopColor="#9C0600" stopOpacity={0} />
				</radialGradient>
				<radialGradient
					id="care_svg__r"
					cx="74.581%"
					cy="77.12%"
					r="17.432%"
					fx="74.581%"
					fy="77.12%"
					gradientTransform="matrix(.7769 .56022 -.52543 .82834 .572 -.285)"
				>
					<stop offset="0%" stopColor="#9C0600" stopOpacity={0.999} />
					<stop offset="100%" stopColor="#9C0600" stopOpacity={0} />
				</radialGradient>
				<radialGradient
					id="care_svg__t"
					cx="1.964%"
					cy="3.81%"
					r="122.247%"
					fx="1.964%"
					fy="3.81%"
					gradientTransform="scale(.7975 1) rotate(36.519 .022 .046)"
				>
					<stop offset="0%" stopColor="#EDA83A" />
					<stop offset="100%" stopColor="#FFDC5E" />
				</radialGradient>
				<radialGradient
					id="care_svg__v"
					cx="94.522%"
					cy="4.813%"
					r="121.348%"
					fx="94.522%"
					fy="4.813%"
					gradientTransform="matrix(.89536 0 0 1 .099 0)"
				>
					<stop offset="0%" stopColor="#EDA83A" />
					<stop offset="100%" stopColor="#FFDC5E" />
				</radialGradient>
				<linearGradient
					id="care_svg__a"
					x1="49.95%"
					x2="49.95%"
					y1="95.05%"
					y2="7.45%"
				>
					<stop offset="0%" stopColor="#F28A2D" />
					<stop offset="100%" stopColor="#FDE86F" />
				</linearGradient>
				<linearGradient
					id="care_svg__g"
					x1="49.992%"
					x2="49.992%"
					y1="-32.818%"
					y2="109.573%"
				>
					<stop offset="0%" stopColor="#482314" />
					<stop offset="100%" stopColor="#9A4111" />
				</linearGradient>
				<linearGradient
					id="care_svg__l"
					x1="52.132%"
					x2="28.355%"
					y1="33.641%"
					y2="95.941%"
				>
					<stop offset="0%" stopColor="#F34462" />
					<stop offset="100%" stopColor="#CC0820" />
				</linearGradient>
				<path
					id="care_svg__s"
					d="M40 20c0 11-9 20-20 20S0 31 0 20 9 0 20 0s20 9 20 20z"
				/>
			</defs>
			<g fill="none" fillRule="evenodd">
				<path
					fill="url(#care_svg__a)"
					d="M40 20c0 11-9 20-20 20S0 31 0 20 9 0 20 0s20 9 20 20z"
				/>
				<path
					fill="url(#care_svg__b)"
					d="M40 20c0 11-9 20-20 20S0 31 0 20 9 0 20 0s20 9 20 20z"
				/>
				<path
					fill="url(#care_svg__c)"
					d="M40 20c0 11-9 20-20 20S0 31 0 20 9 0 20 0s20 9 20 20z"
				/>
				<path
					fill="url(#care_svg__d)"
					d="M40 20c0 11-9 20-20 20S0 31 0 20 9 0 20 0s20 9 20 20z"
				/>
				<path
					fill="url(#care_svg__e)"
					d="M40 20c0 11-9 20-20 20S0 31 0 20 9 0 20 0s20 9 20 20z"
				/>
				<path
					fill="url(#care_svg__f)"
					d="M40 20c0 11-9 20-20 20S0 31 0 20 9 0 20 0s20 9 20 20z"
				/>
				<path
					fill="url(#care_svg__g)"
					d="M24.25 14.75c-.25-.75-8.25-.75-8.5 0s1.5 1.75 4.25 1.75 4.5-1 4.25-1.75z"
				/>
				<path
					fill="url(#care_svg__h)"
					d="M15 10.25c0 1.75-1 2.25-2.5 2.5s-2.75-.5-2.75-2.5c0-1.5.75-3.5 2.75-3.5 1.75 0 2.5 2 2.5 3.5z"
				/>
				<path
					fill="#4E506A"
					d="M12.25 7.75c.25.25.25 1-.25 1.25-.25.25-.75.5-1 0s-.25-.75 0-1.25c.5-.25 1-.25 1.25 0z"
				/>
				<path
					fill="url(#care_svg__i)"
					d="M25 10.25c0 1.75 1 2.25 2.75 2.5 1.5.25 2.75-.5 2.75-2.5 0-1.5-.75-3.5-2.75-3.5s-2.75 2-2.75 3.5z"
				/>
				<path
					fill="#4E506A"
					d="M27.5 7.75c.25.25 0 .75-.25 1.25-.25.25-.75.25-1 0s0-.75.25-1.25c.5-.5.75-.5 1 0z"
				/>
				<path
					fill="url(#care_svg__j)"
					d="M8.5 5.25c-.5.5 0 1.25.75 1 1.5-.75 4.5-1.5 7-1.25.75 0 1 0 .75-1 0-.75-1-1.25-3-1-3 .25-5 1.75-5.5 2.25z"
				/>
				<path
					fill="url(#care_svg__k)"
					d="M26 3c-2-.25-3 .25-3 1-.25 1 0 1 .75 1 2.75-.25 5.75.5 7 1.25 1 .5 1.25-.5.75-1S29 3.25 26 3z"
				/>
				<path
					fill="url(#care_svg__l)"
					d="M24.25 21.25c-5.25-1.5-7 2-7 2s.5-4-4.75-5.75c-5-1.5-8 3.25-8.25 6-.5 6.25 5 13.25 7 15.75.25.75 1 .75 1.75.75 3-.75 11.5-3.5 14.75-9 1.25-2.75 1.5-8.25-3.5-9.75z"
				/>
				<path
					fill="url(#care_svg__m)"
					d="M24.25 21.25c-5.25-1.5-7 2-7 2s.5-4-4.75-5.75c-5-1.5-8 3.25-8.25 6-.5 6.25 5 13.25 7 15.75.25.75 1 .75 1.75.75 3-.75 11.5-3.5 14.75-9 1.25-2.75 1.5-8.25-3.5-9.75z"
				/>
				<path
					fill="url(#care_svg__n)"
					d="M24.25 21.25c-5.25-1.5-7 2-7 2s.5-4-4.75-5.75c-5-1.5-8 3.25-8.25 6-.5 6.25 5 13.25 7 15.75.25.75 1 .75 1.75.75 3-.75 11.5-3.5 14.75-9 1.25-2.75 1.5-8.25-3.5-9.75z"
				/>
				<path
					fill="url(#care_svg__o)"
					d="M24.25 21.25c-5.25-1.5-7 2-7 2s.5-4-4.75-5.75c-5-1.5-8 3.25-8.25 6-.5 6.25 5 13.25 7 15.75.25.75 1 .75 1.75.75 3-.75 11.5-3.5 14.75-9 1.25-2.75 1.5-8.25-3.5-9.75z"
				/>
				<path
					fill="url(#care_svg__p)"
					d="M24.25 21.25c-5.25-1.5-7 2-7 2s.5-4-4.75-5.75c-5-1.5-8 3.25-8.25 6-.5 6.25 5 13.25 7 15.75.25.75 1 .75 1.75.75 3-.75 11.5-3.5 14.75-9 1.25-2.75 1.5-8.25-3.5-9.75z"
				/>
				<path
					fill="url(#care_svg__q)"
					d="M24.25 21.25c-5.25-1.5-7 2-7 2s.5-4-4.75-5.75c-5-1.5-8 3.25-8.25 6-.5 6.25 5 13.25 7 15.75.25.75 1 .75 1.75.75 3-.75 11.5-3.5 14.75-9 1.25-2.75 1.5-8.25-3.5-9.75z"
				/>
				<path
					fill="url(#care_svg__r)"
					d="M24.25 21.25c-5.25-1.5-7 2-7 2s.5-4-4.75-5.75c-5-1.5-8 3.25-8.25 6-.5 6.25 5 13.25 7 15.75.25.75 1 .75 1.75.75 3-.75 11.5-3.5 14.75-9 1.25-2.75 1.5-8.25-3.5-9.75z"
				/>
				<mask id="care_svg__u" fill="#fff">
					<use xlinkHref="#care_svg__s" />
				</mask>
				<path
					fill="url(#care_svg__t)"
					d="M3.25 19.25c-1.25-1.25-3.5-2-3.75 1-.25 2.25.75 6.25 3.5 8.5 7 5.5 13.25 2.5 13.5-1.5.25-3-3.5-2.75-4.5-2.75v-.25c.25-.25.75-.5 1-.75 1-.75.5-2-.75-1.75-.25 0-3.25 1-5.25.25s-2.25-1.5-3.75-2.75z"
					mask="url(#care_svg__u)"
				/>
				<path
					fill="url(#care_svg__v)"
					fillRule="nonzero"
					d="M35.75 19.5c.75-1.5 2-1 2.75-.75 1 .25 1.75 1 1.75 2.5 0 3.75-.5 7.25-3.75 10.75-5.75 6.5-15.5 5.5-16.5 1.5-.75-3 2.75-3.5 4-3.5v-.25c-.5-.25-.75-.5-1.25-.75-1-.75-.75-2.25.5-2 1.5.25 3.5.75 5 .5 4.75-.5 5.5-4.25 7.5-8z"
				/>
			</g>
		</svg>
	);
}

export default SvgCare;

