import React from 'react';
import PropTypes from 'prop-types';

import BasicSelect from 'features/common/basicSelect/basicSelect';
import { arrayBrands } from 'utils/array';

const InputBrand = ({
  setBrand, brand, required,
}) => (
  <BasicSelect
    dataCy="BrandAlterarLimite"
    nameLabel="Brand"
    options={arrayBrands}
    selectedOption={brand}
    setOption={setBrand}
    namePlaceholder="Selecione uma brand"
    renderAllOptions={false}
    namePlaceholderFilter={brand}
    required={required}
  />
);

InputBrand.propTypes = {
  setBrand: PropTypes.func,
  brand: PropTypes.string,
  required: PropTypes.bool,
};

InputBrand.defaultProps = {
  brand: null,
  setBrand: () => {},
  required: false,
};

export default InputBrand;
