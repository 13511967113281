const PREFIX = 'comprovantePage';

const RESET_STORE = `${PREFIX}/RESET_STORE`;
const SET_ID_MOVIMENTACAO = `${PREFIX}/SET_ID_MOVIMENTACAO`;

const EXPORT_COMPROVANTE_START = `${PREFIX}/EXPORT_COMPROVANTE_START`;
const EXPORT_COMPROVANTE_FINISH = `${PREFIX}/EXPORT_COMPROVANTE_FINISH`;
const EXPORT_COMPROVANTE_ERROR = `${PREFIX}/EXPORT_COMPROVANTE_ERROR`;

const GET_COMPROVANTE_START = `${PREFIX}/GET_COMPROVANTE_START`;
const GET_COMPROVANTE_ERROR = `${PREFIX}/GET_COMPROVANTE_ERROR`;
const GET_COMPROVANTE_SUCCESS = `${PREFIX}/GET_COMPROVANTE_SUCCESS`;

const DISMISS_SNACKBAR = `${PREFIX}/DISMISS_SNACKBAR`;

const resetStore = () => ({
  type: RESET_STORE,
});

const setIdMovimentacao = (id) => ({
  type: SET_ID_MOVIMENTACAO,
  payload: { id },
});

const exportStart = () => ({
  type: EXPORT_COMPROVANTE_START,
});

const exportError = (error) => ({
  type: EXPORT_COMPROVANTE_ERROR,
  payload: { error },
});

const exportFinish = () => ({
  type: EXPORT_COMPROVANTE_FINISH,
});

const dismissSnackbar = (id) => ({
  type: DISMISS_SNACKBAR,
  payload: { id },
});

const getComprovanteStart = () => ({
  type: GET_COMPROVANTE_START,
});

const getComprovanteError = () => ({
  type: GET_COMPROVANTE_ERROR,
});

const getComprovanteSuccess = (comprovante) => ({
  type: GET_COMPROVANTE_SUCCESS,
  payload: { comprovante },
});

export default {
  types: {
    RESET_STORE,
    SET_ID_MOVIMENTACAO,
    EXPORT_COMPROVANTE_ERROR,
    EXPORT_COMPROVANTE_FINISH,
    EXPORT_COMPROVANTE_START,
    DISMISS_SNACKBAR,
    GET_COMPROVANTE_ERROR,
    GET_COMPROVANTE_START,
    GET_COMPROVANTE_SUCCESS,
  },

  resetStore,
  setIdMovimentacao,
  exportError,
  exportFinish,
  exportStart,
  dismissSnackbar,
  getComprovanteError,
  getComprovanteStart,
  getComprovanteSuccess,
};
