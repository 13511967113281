import { connect } from 'react-redux';
import selector from 'routes/redux/selector';
import { AuthSelectors } from '../../modules/auth/redux';

import PrivateRoute from './privateRoute';

const mapStateToProps = ({ authStore, dealerStore }) => ({
  isAuthenticated: AuthSelectors.isAuthenticated(authStore),
  authorizations: AuthSelectors.getAuthorizations(authStore),
  brandList: selector.getListBrands(dealerStore),
});

export default connect(mapStateToProps, null)(PrivateRoute);
