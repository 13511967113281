import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import Button from 'features/common-staff/controls/button';

const useStyles = makeStyles({
  button: {
    width: '180px',
    height: '40px',
    marginRight: '10px',
    '&:disabled': {
      backgroundColor: '#E9EAF0',
    },
  },
});

const CancelarButton = ({
  isLoading, closeModal,
}) => {
  const classes = useStyles();
  return (
    <Button
      onClick={() => closeModal()}
      className={classes.button}
      isLoading={false}
      disabled={isLoading}
      color="darkGrayBorder"
      fullWidth
    >
      Cancelar
    </Button>
  );
};
CancelarButton.propTypes = {
  isLoading: PropTypes.bool,
  closeModal: PropTypes.func,
};

CancelarButton.defaultProps = {
  closeModal: () => {},
  isLoading: false,
};

export default CancelarButton;
