import { saveAs } from 'file-saver';
import 'moment/locale/pt-br';
import moment from 'moment';

import * as paymentActions from './paymentActions';
import paymentService from '../paymentService';

export const loadNewPage = (
  page,
  itensPerPage = 15,
  startDate = null,
  endDate = null,
  searchText = null,
) => async (dispatch) => {
  try {
    await dispatch(paymentActions.initRequest());
    const start = moment(startDate);
    const end = moment(endDate);
    const invoices = await paymentService.getInvoices(
      page,
      itensPerPage,
      start.isValid() ? start.format('MM DD YYYY') : null,
      end.isValid() ? end.format('MM DD YYYY') : null,
      searchText,
    );
    await dispatch(paymentActions.concatInvoices(invoices.data, invoices.data.length !== 0, page));
    dispatch(paymentActions.finishRequest());
  } catch (error) {
    await dispatch(paymentActions.concatInvoices([], true, page - 1));
    dispatch(paymentActions.finishRequest());
  }
};

export const getInvoices = (
  page,
  startDate = null,
  endDate = null,
  searchText = null,
  itensPerPage = 15,
) => async (dispatch) => {
  try {
    await dispatch(paymentActions.initRequest());

    const start = moment(startDate);
    const end = moment(endDate);
    const invoices = await paymentService.getInvoices(
      page,
      itensPerPage,
      start.isValid() ? start.format('MM DD YYYY') : null,
      end.isValid() ? end.format('MM DD YYYY') : null,
      searchText,
    );

    if (searchText !== null && searchText && searchText.length > 0) {
      await dispatch(paymentActions.setIsSearchActive(true));
    } else {
      await dispatch(paymentActions.setIsSearchActive(false));
    }

    await dispatch(paymentActions.setInvoices(invoices.data, invoices.data.length !== 0));

    dispatch(paymentActions.finishRequest());
  } catch (error) {
    await dispatch(paymentActions.setInvoices([], true));
    await dispatch(paymentActions.setIsSearchActive(false));
    dispatch(paymentActions.finishRequest());
  }
};

export const setStartDate = (startDate) => (dispatch) => {
  dispatch(paymentActions.setStartDate(startDate));
};

export const setEndDate = (
  endDate = null,
) => async (dispatch, getState) => {
  await dispatch(paymentActions.setEndDate(endDate));
  const { startDate, searchText } = getState().paymentStore;
  await dispatch(getInvoices(0, startDate, endDate, searchText));
};

export const selectInvoice = (invoice, selectedInvoices) => (dispatch) => {
  const invoiceIdx = selectedInvoices.find(
    (i) => (i.invoiceNumber === invoice.invoiceNumber),
  );

  if (invoiceIdx === undefined) {
    dispatch(paymentActions.updateSelectedInvoices(
      [
        ...selectedInvoices,
        {
          ...invoice,
          selectedForPaymentValue: invoice.remainingValue,
        },
      ],
    ));
  }
};

export const deselectInvoice = (invoice, selectedInvoices) => (dispatch) => {
  dispatch(paymentActions.updateSelectedInvoices(
    selectedInvoices.filter(
      (i) => (i.invoiceNumber !== invoice.invoiceNumber),
    ),
  ));
};

export const updateSelectedInvoices = (invoices) => (dispatch) => {
  dispatch(paymentActions.updateSelectedInvoices(invoices));
};

export const updateSelectedInvoice = (invoice) => (dispatch) => {
  dispatch(paymentActions.updateSelectedInvoice(invoice));
};

export const clearSelectedInvoices = () => (dispatch) => {
  dispatch(paymentActions.updateSelectedInvoices([]));
};

export const setPaymentStep = (step) => (dispatch) => {
  dispatch(paymentActions.setPaymentStep(step));
};

export const resetPaymentStore = () => (dispatch) => {
  dispatch(paymentActions.resetPaymentStore());
};

export const submitPayment = (invoices, user, onSuccess, onError) => async (dispatch) => {
  try {
    dispatch(paymentActions.setIsSubmitting(true));

    const paymentOperation = await paymentService.postPayment(invoices, user);
    await dispatch(paymentActions.setPaymentId(paymentOperation.operationRequestId));

    dispatch(paymentActions.setIsSubmitting(false));
    (onSuccess || (() => { })).call();
  } catch (e) {
    dispatch(paymentActions.setIsSubmitting(false));
    (onError || (() => { })).call(e);
  }
};

export const getPaymentInformation = (id) => async (dispatch) => {
  try {
    await dispatch(paymentActions.initRequestReceipt());
    const paymentInformation = await paymentService.getPaymentInformation(id);
    await dispatch(paymentActions.setPaymentUserInformation(
      paymentInformation.data.usuarioEmail,
      paymentInformation.data.usuarioNome,
    ));

    await dispatch(paymentActions.setPaymentDealerInformation(
      paymentInformation.data.concessionariaCnpj,
      paymentInformation.data.concessionariaNome,
      paymentInformation.data.concessionariaCodigo,
    ));

    await dispatch(paymentActions.setPaymentTransactionInformation(
      paymentInformation.data.data,
      paymentInformation.data.formaPagamento,
      paymentInformation.data.valorTotal,
    ));

    await dispatch(paymentActions.setPaymentTransactions(
      paymentInformation.data.movimentacoes,
    ));

    dispatch(paymentActions.finishRequestReceipt(false));
  } catch (error) {
    await dispatch(paymentActions.setPaymentUserInformation());
    await dispatch(paymentActions.setPaymentDealerInformation());
    await dispatch(paymentActions.setPaymentTransactionInformation());
    await dispatch(paymentActions.setPaymentTransactions([]));
    dispatch(paymentActions.finishRequestReceipt(true));
  }
};

export const downloadPaymentReport = (id) => async (dispatch) => {
  try {
    dispatch(paymentActions.startDownload());
    const xlsxResponse = await paymentService.downloadPaymentReport(id);
    const url = window.URL.createObjectURL(new Blob([xlsxResponse.data]));
    saveAs(url, 'comprovante_pagamento.xlsx');

    dispatch(paymentActions.finishDownload());
  } catch (error) {
    dispatch(paymentActions.finishDownload(true));
  }
};
