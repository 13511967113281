import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, FormHelperText,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import ButtonTooltipIcon from 'features/common/buttonTooltipIcon';
import colors from 'assets/styles/colors';
import UploadFiles from './uploadFiles';
import ModalUpload from './modalUpload';

import {
  CnpjFormat, DealerNumber, TextFieldHub,
} from '../../../../common';

import { Tracking } from '../../../../../modules';

import './testDriveForm.scss';

const AutocompleteHub = withStyles({
  root: {
    '& .MuiFormControl-root': {
      display: 'block',
      width: '100%',
      height: 56,
      borderRadius: 10,
    },
    '&.MuiAutocomplete-root': {
      width: '100%',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: colors.primary_color_600,
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.primary_color_600,
      },
    },
    '& .MuiInputLabel-outlined.Mui-focused': {
      color: colors.primary_color_600,
    },
  },
})(Autocomplete);

function TestDriveForm({
  dealers, selectedDealer, setSelectedDealerId,
  industrialOrder, chassis, color, observation,
  setIndustrialOrder, setChassis, setColor, setObservation, produto,
  motivos, setSelectedMotivo, selectedMotivo,
  isModalUploadOpen, filesToSend, openModalUpload,
}) {
  const isTestDriveExcecao = produto.titulo === 'Test Drive de Exceção' || produto.id === 4;
  const formatFilesToSend = (files) => files.map((f) => `${f.name}, `).join('').slice(0, -2);
  return (
    <>
      {isTestDriveExcecao
        ? (
          <div className="teste-drive-form-outlined-content-info-exception">
            Para solicitar um test drive de exceção é necessário selecionar um motivo e anexar
            um ou mais documentos que comprovem o motivo da solicitação.
          </div>
        )
        : null}
      <div className="teste-drive-form-outlined-content">
        <div className="test-drive-form-autocomplete-container">
          <div className="test-drive-form-autocomplete-container-select">
            <AutocompleteHub
              options={dealers}
              onChange={(_, value) => {
                setSelectedDealerId(value.dealerId);

                Tracking.Mixpanel.testDrive.trackDealerSelected();
              }}
              value={selectedDealer}
              disableClearable
              noOptionsText="Nenhuma concessionária encontrada"
              key={selectedDealer}
              getOptionLabel={(option) => option.dealerName || ''}
              renderOption={(option) => (
                <div className="dealer-selector-td-item">
                  <span>
                    <DealerNumber dealerCode={option.dealerCorr} />
                    {` / ${option.dealerName}`}
                  </span>
                </div>
              )}
              renderInput={(params) => (
                <TextFieldHub
                  {...params}
                  fullWidth
                  label="Concessionária"
                />
              )}
            />
          </div>
          {(selectedDealer.brand) ? (
            <div className="test-form-dealer-area">
              <div className="test-drive-form-dealer-container">
                <span className="test-drive-form-dealer-tittle">MARCA</span>
                <br />
                <span className="test-drive-form-dealer-description">{selectedDealer.brand}</span>
              </div>
              <div className="test-drive-form-dealer-container">
                <span className="test-drive-form-dealer-tittle">COD.</span>
                <br />
                <DealerNumber
                  className="test-drive-form-dealer-description"
                  dealerCode={selectedDealer.dealerId}
                />
              </div>

              <div className="test-drive-form-dealer-container">
                <span className="test-drive-form-dealer-tittle">CNPJ</span>
                <br />
                <CnpjFormat
                  className="test-drive-form-dealer-description"
                  value={selectedDealer.cnpj}
                />
              </div>
            </div>
          ) : ''}
        </div>

        <div className="test-drive-form-outline-field-container-pedido">
          <TextFieldHub
            label="Pedido Industrial"
            inputProps={{
              maxLength: 10,
            }}
            fullWidth
            value={industrialOrder || ''}
            onChange={(event) => { setIndustrialOrder(event.target.value); }}
          />
          <FormHelperText>Opcional</FormHelperText>
        </div>

        <div className="test-drive-form-outline-field-container-chassi">
          <TextFieldHub
            label="Chassi"
            inputProps={{
              maxLength: 10,
            }}
            fullWidth
            value={chassis || ''}
            onChange={(event) => { setChassis(event.target.value); }}
          />
          <FormHelperText>Opcional</FormHelperText>
        </div>

        <div className="test-drive-form-outline-field-container-cor">
          <TextFieldHub
            label="Cor"
            inputProps={{
              maxLength: 20,
            }}
            fullWidth
            value={color || ''}
            onChange={(event) => { setColor(event.target.value); }}
          />
          <FormHelperText>Opcional</FormHelperText>
        </div>
        <div className="test-drive-form-outline-field-container-observacao">
          <TextFieldHub
            multiline
            rows="4"
            fullWidth
            label="Observação"
            value={observation || ''}
            onChange={(event) => { setObservation(event.target.value); }}
          />
          <FormHelperText>Opcional</FormHelperText>
        </div>

        { isTestDriveExcecao ? (
          <div className="test-drive-form-outline-field-container-tdexcecao">
            <div className="test-drive-form-outline-field-container-tdexcecao-motivo">
              <AutocompleteHub
                options={motivos}
                onChange={(event, value) => {
                  setSelectedMotivo(value.id);
                  Tracking.Mixpanel.testDrive.trackMotivoSelected();
                }}
                value={selectedMotivo}
                disableClearable
                noOptionsText="Nenhum motivo selecionado"
                key={selectedMotivo}
                getOptionLabel={(option) => option.motivo || ''}
                renderInput={(params) => (
                  <TextFieldHub
                    {...params}
                    fullWidth
                    label="Motivo da solicitação"
                  />
                )}
              />
            </div>
            <div className="test-drive-form-outline-field-container-tdexcecao-anexo">
              <TextFieldHub
                fullWidth
                label="Anexar Documentos"
                value={formatFilesToSend(filesToSend)}
                disabled
                onClick={() => openModalUpload()}
                InputProps={{
                  endAdornment:
            <ButtonTooltipIcon title="Anexar arquivo">
              <UploadFiles />
            </ButtonTooltipIcon>,
                }}
              />
            </div>
          </div>
        ) : null}

        {isModalUploadOpen ? <ModalUpload /> : null}
      </div>
    </>
  );
}

TestDriveForm.propTypes = {
  dealers: PropTypes.array.isRequired,
  setSelectedDealerId: PropTypes.func.isRequired,
  setIndustrialOrder: PropTypes.func.isRequired,
  setChassis: PropTypes.func.isRequired,
  setColor: PropTypes.func.isRequired,
  setObservation: PropTypes.func.isRequired,

  selectedDealer: PropTypes.object,
  industrialOrder: PropTypes.string,
  chassis: PropTypes.string,
  color: PropTypes.string,
  observation: PropTypes.string,
  filesToSend: PropTypes.string,

  produto: PropTypes.object,
  motivos: PropTypes.array.isRequired,
  setSelectedMotivo: PropTypes.func.isRequired,
  selectedMotivo: PropTypes.object,

  isModalUploadOpen: PropTypes.bool,
  openModalUpload: PropTypes.func.isRequired,
};

TestDriveForm.defaultProps = {
  selectedDealer: {},
  industrialOrder: null,
  chassis: null,
  color: null,
  observation: null,
  filesToSend: [],

  produto: null,
  selectedMotivo: {},

  isModalUploadOpen: null,
};

export default TestDriveForm;
