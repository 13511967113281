import React from 'react';
import VeiculoVendidoIcon from 'assets/icons/veiculo-vendido';
import VeiculoEstoqueIcon from 'assets/icons/veiculo-estoque';
import colors from 'assets/styles/colors';

export const getDuplicataAssets = (duplicata, isDrawer, isSelected, isHover) => {
  let size = 20;
  let background = isSelected || isHover ? `${colors.primary_color_100_48}` : `${colors.primary_color_100_24}`;
  let iconColor = isSelected || isHover ? `${colors.primary_color_700}` : `${colors.primary_color_600}`;

  if (isDrawer) {
    size = 25;
    background = `${colors.primary_color_100_24}`;
    iconColor = `${colors.primary_color_600}`;
  }

  if (duplicata?.dataVenda || duplicata?.dataVendaDuplicata) {
    return {
      title: 'Veículo Vendido',
      icon: (
        <div style={{ width: size, display: 'flex' }}>
          <VeiculoVendidoIcon color={iconColor} />
        </div>
      ),
      background,
    };
  }

  return {
    title: 'Veículo em estoque',
    icon: (
      <div style={{ width: size, display: 'flex' }}>
        <VeiculoEstoqueIcon centerCar color={iconColor} />
      </div>
    ),
    background,
  };
};
