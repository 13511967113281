import { connect } from 'react-redux';

import operations from './redux/operations';
import ObservacoesAprovacao from './observacoesAprovacao';

const mapStateToProps = ({ limitesAprovadosObservacao }) => ({
  observacaoAprovacao: limitesAprovadosObservacao.observacaoAprovacao,
  observacoesList: limitesAprovadosObservacao.observacoes?.observacoesList,
  isLoadingObservacoes: limitesAprovadosObservacao.observacoes?.isLoading,
  visualizado: limitesAprovadosObservacao.observacoes?.visualizado,
});

const mapDispatchToProps = (dispatch) => ({
  getObservacoes: (idLimite) => dispatch(operations.getObservacoes(idLimite)),
  insertControleObservacao: (idLimite, dataLeitura) => dispatch(
    operations.insertControleObservacao(idLimite, dataLeitura),
  ),
  insertObservacao: (observacaoAprovacao, idLimite) => dispatch(
    operations.insertObservacao(observacaoAprovacao, idLimite),
  ),
  uploadArquivoTemporario: async (file) => dispatch(operations.uploadArquivoTemporario(file)),
  getDocumentoDownload: (guidDocumento) => dispatch(operations.getDocumentoDownload(guidDocumento)),
  resetStore: () => dispatch(operations.resetStore()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ObservacoesAprovacao);
