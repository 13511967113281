import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import PreviewIcon from 'assets/icons/preview';

import './arquivosExcecao.scss';
import ButtonTooltipIcon from 'features/common/buttonTooltipIcon';

const useStyles = makeStyles(() => ({
  button: {
    borderRadius: 0,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:disabled': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
}));

const ArquivosExcecao = ({
  disabled, arquivo,
}) => {
  const classes = useStyles();
  return (
    <div className="arquivosExcecao__item__container">
      <div className="arquivosExcecao__item__name">
        {arquivo.nome}
      </div>
      <div className="arquivosExcecao__item__icon">
        <IconButton
          className={classes.button}
          disabled={disabled}
          onClick={() => { window.open(arquivo.url); }}
          disableRipple
        >
          <ButtonTooltipIcon title="Baixar arquivo">
            <PreviewIcon />
          </ButtonTooltipIcon>
        </IconButton>
      </div>
    </div>
  );
};

ArquivosExcecao.propTypes = {
  arquivo: PropTypes.object,
  disabled: PropTypes.bool,
};

ArquivosExcecao.defaultProps = {
  disabled: false,
  arquivo: null,
};

export default ArquivosExcecao;
