import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;
  min-height: 50px;

  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(85, 87, 112, 0.16), 0 0 2px 0 rgba(85, 87, 112, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const CardHeader = styled.div`
  text-align: center;
  padding: 32px 16px 16px;

  grid-template-columns: 1fr;
  grid-template-rows: auto;
  display: grid;
  grid-template-areas:
    "title"
    "value";
`;

export const Title = styled.div`
  grid-area: title;
  line-height: 1.2;
  font-size: 12px;
  color: #8F9BB3;
  align-items: center;
  justify-content:center;
  text-align: center;
  padding-bottom: 5px;
`;

export const Value = styled.div`
  grid-area: value;
  color: #555770;
  font-size: 20px;
  font-weight: 900;
  display: flex;

  align-items: center;
  justify-content:center;
  text-align: center;

  text-transform: capitalize;
`;

export const CardBody = styled.div`
  border-top: solid 1px rgba(85, 87, 112, 0.08);
  padding: 16px 16px;
  color: #8f9bb3;
  font-size: 12px;
`;
