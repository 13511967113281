import { connect } from 'react-redux';

import { PaymentOperations } from '../../redux';
import PaymentEditorRow from './paymentEditorRow';

const mapDispatchToProps = (dispatch) => ({
  updateSelectedInvoice: (invoice) => (
    dispatch(PaymentOperations.updateSelectedInvoice(invoice))
  ),
});

export default connect(null, mapDispatchToProps)(PaymentEditorRow);
