import React from 'react';
import PropTypes from 'prop-types';

import TextFilterDebounce from '../../../../../../../common-staff/controls/textFilterDebounce';

const InputBusca = ({
  texto, setTexto, isLoading,
}) => (
  <TextFilterDebounce
    label=""
    placeholder="Buscar duplicata ou chassi"
    value={texto}
    setValue={setTexto}
    showSearchIcon
    disabled={isLoading}
  />
);

InputBusca.propTypes = {
  texto: PropTypes.string,
  setTexto: PropTypes.func,
  isLoading: PropTypes.bool,
};

InputBusca.defaultProps = {
  texto: '',
  setTexto: () => {},
  isLoading: false,
};

export default InputBusca;
