import { connect } from 'react-redux';

import CarteiraModal from './carteiraModal';

const mapStateToProps = ({ gestaoPagamentos }) => ({
  isOpenModalInfo: gestaoPagamentos.carteira.modal.modalInfo?.isOpen,
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CarteiraModal);
