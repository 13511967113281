import styled from 'styled-components';
import colors from 'assets/styles/colors';

export const Card = styled.div`
  width: 100%;
  min-height: 50px;

  background-color: white;
  border-radius: 8px;
  box-shadow: ${colors.box_shadow_color};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const CardHeader = styled.div`
  text-align: center;
  padding: 32px 16px 16px;

  grid-template-columns: 1fr;
  grid-template-rows: auto;
  display: grid;
  grid-template-areas:
    "title"
    "value";
`;

export const Title = styled.div`
  grid-area: title;
  line-height: 1.2;
  font-size: 12px;
  color: ${colors.text_midnight_500};
  align-items: center;
  justify-content:center;
  text-align: center;
  padding-bottom: 5px;
`;

export const Value = styled.div`
  grid-area: value;
  color: ${colors.text_midnight_500};
  font-size: 20px;
  font-weight: 900;
  display: flex;

  align-items: center;
  justify-content:center;
  text-align: center;

  text-transform: capitalize;
`;
