import React from 'react';
import PropTypes from 'prop-types';
import { formatCnpj, formatCurrency, formatDate } from 'utils/format';
import { Box } from '@mui/material';
import colors from 'assets/styles/colors';
import BadgeStatus from 'features/common/badgeStatus/badgeStatus';
import CardRow from './cardRow/cardRow';
import {
  Card, CardHeader, Title, Value,
} from './cardDetalheResgates.style';

const CardDetalheResgates = ({ resgate }) => {
  const dateValue = (value) => (value ? formatDate(new Date(value), 'DD MMM YYYY') : '-');
  return (
    <Card>
      <CardHeader>
        <Title>
          Valor do resgate
        </Title>
        <Value>
          {formatCurrency(resgate?.valorResgate)}
        </Value>
        <Box
          display="flex"
          justifyContent="center"
          paddingTop="5px"
        >
          <BadgeStatus status={resgate?.status}>
            {resgate?.status}
          </BadgeStatus>
        </Box>
      </CardHeader>
      <Box padding="8px 26px">
        <CardRow label="Data de cessão" value={dateValue(resgate?.dataCessao)} />
        <CardRow label="Data de pagamento" value={dateValue(resgate?.dataPagamento)} />
        <CardRow label="Período" value={`${dateValue(resgate?.dataInicioPagamento)} - ${dateValue(resgate?.dataFimPagamento)}`} />
        <CardRow label="Valor de juros" value={formatCurrency(resgate?.valorJuros)} />
        <CardRow label="Transit time" value={resgate?.diasTransitTime} />
        <CardRow label="Dias de carência" value={resgate?.diasCarencia} />
      </Box>
      <Box border={`1px solid ${colors.secundary_color_100}`} />
      <Box padding="8px 26px">
        <CardRow label="CNPJ Gestor" value={formatCnpj(resgate?.cnpjGestor)} />
        <CardRow label="CNPJ Filial" value={formatCnpj(resgate?.cnpjFilial)} />
      </Box>
      <Box border={`1px solid ${colors.secundary_color_100}`} />
      <Box padding="8px 26px">
        <CardRow label="Nº da duplicata:" value={resgate?.numeroDuplicata} />
        <CardRow label="Data de emissão" value={dateValue(resgate?.dataEmissaoDuplicata)} />
        <CardRow label="Chassi" value={resgate?.identChassi} />
      </Box>
    </Card>
  );
};

CardDetalheResgates.propTypes = {
  resgate: PropTypes.object.isRequired,
};

export default CardDetalheResgates;
