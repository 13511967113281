import React from 'react';
import PropTypes from 'prop-types';
import PaymentReceiptInfoBox from '../paymentReceiptInfoBox';

import './paymentReceiptUserInfo.scss';

const PaymentReceiptUserInfo = ({
  className, userInfo,
}) => (
  <div className={`payment-receipt-body-info-block ${className}`}>
    <div className="payment-receipt-body-info-tittle">DADOS USUÁRIO</div>

    <PaymentReceiptInfoBox
      subtittle="NOME"
    >
      <div className="payment-receipt-body-info-values">{userInfo !== null ? userInfo.usuarioNome : null}</div>
    </PaymentReceiptInfoBox>

    <PaymentReceiptInfoBox
      subtittle="EMAIL"
    >
      <div className="payment-receipt-body-info-values">{userInfo !== null ? userInfo.usuarioEmail : null}</div>
    </PaymentReceiptInfoBox>

  </div>
);

PaymentReceiptUserInfo.propTypes = {
  className: PropTypes.string,
  userInfo: PropTypes.shape({
    usuarioEmail: PropTypes.string,
    usuarioNome: PropTypes.string,
  }).isRequired,
};

PaymentReceiptUserInfo.defaultProps = {
  className: '',
};

export default PaymentReceiptUserInfo;
