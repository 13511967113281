import React from "react";

const AtualizarCadastroIcon = props => (
	<svg width="22" height="20" viewBox="0 0 22 20" {...props}>
		<path d="M7.79167 0C5.26036 0 3.20833 2.05203 3.20833 4.58333C3.20833 7.11464 5.26036 9.16667 7.79167 9.16667C10.323 9.16667 12.375 7.11464 12.375 4.58333C12.375 2.05203 10.323 0 7.79167 0Z" fill="white" />
		<path d="M11 11C13.4647 11 15.475 12.9454 15.5791 15.3845L15.5833 15.5833V17.4167C15.5833 17.9229 15.1729 18.3333 14.6667 18.3333C14.1966 18.3333 13.8091 17.9795 13.7562 17.5236L13.75 17.4167V15.5833C13.75 14.1188 12.6052 12.9216 11.1616 12.838L11 12.8333H4.58333C3.11879 12.8333 1.92164 13.9782 1.838 15.4218L1.83333 15.5833V17.4167C1.83333 17.9229 1.42293 18.3333 0.916667 18.3333C0.446567 18.3333 0.0591184 17.9795 0.00616709 17.5236L0 17.4167V15.5833C0 13.1186 1.94545 11.1083 4.38452 11.0042L4.58333 11H11Z" fill="white" />
		<path d="M21.7315 5.76849C21.3735 5.4105 20.7931 5.4105 20.4352 5.76849L17.4167 8.78625L16.2315 7.60182L16.1452 7.52556C15.7856 7.24596 15.2656 7.27137 14.9352 7.60182C14.5772 7.9598 14.5772 8.5402 14.9352 8.89818L16.7685 10.7315L16.8548 10.8078C17.2144 11.0874 17.7344 11.062 18.0648 10.7315L21.7315 7.06485L21.8078 6.97849C22.0874 6.61889 22.062 6.09893 21.7315 5.76849Z" fill="white" />
		<path fillRule="evenodd" clipRule="evenodd" d="M4.58335e-08 17.9667C4.58335e-08 17.3592 0.383045 16.8667 0.855556 16.8667L14.85 16.3167C15.3225 16.3167 15.5833 17.3592 15.5833 17.9667C15.5833 18.5742 15.017 19.0667 14.5444 19.0667H0.855556C0.383045 19.0667 4.58335e-08 18.5742 4.58335e-08 17.9667Z" fill="white" />
		<path d="M1.1 12.4667H14.3V17.9667H1.1V12.4667Z" fill="white" />
	</svg>
);

export default AtualizarCadastroIcon;

