import { React, useEffect } from 'react';
import {
  Page, PageContent, PageFooter, PageHeader, PageSubTitle, PageTitle,
} from 'features/common-staff/layout/page';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import ButtonsFooter from './buttonsFooter';
import BreadCrumbCadastro from './views/breadCrumbCadastro';
import HistoricoLimite from './views/historicoLimite/historicoLimite';
import DadosAprovacao from './views/dadosAprovacao/dadosAprovacao';
import DocumentosCadastro from './documentosCadastro/index';
import DocumentosJuridico from './documentosJuridico/index';
import './limitesAprovadosCadastro.scss';
import ObservacoesAprovacao from '../observacoesAprovacao';

const LimitesAprovadosCadastro = ({
  historicoLimite, historicoIsLoading, detalhes, cadastroPage,
  getPessoaDocumentacao, getDocumentosComplementares, getDocumentosFormalizar,
}) => {
  const history = useHistory();
  useEffect(() => {
    if (!cadastroPage) history.replace('limites-aprovados');
    if (!_.isNull(detalhes)) {
      getPessoaDocumentacao(detalhes?.idLimite);
      getDocumentosComplementares(detalhes?.idLimite);
      getDocumentosFormalizar(detalhes?.idLimite);
    }
  }, [getPessoaDocumentacao, getDocumentosComplementares, detalhes, cadastroPage]);

  return (
    <Page>
      <PageHeader>
        <PageSubTitle>
          <BreadCrumbCadastro />
        </PageSubTitle>
        <PageTitle>Documentos pendentes</PageTitle>
        <span className="id-limite">
          ID APROVAÇÃO #
          {detalhes?.idLimite}
        </span>
      </PageHeader>
      <PageContent>
        <div className="limite-cadastro__page__content">
          <HistoricoLimite historico={historicoLimite} historicoIsLoading={historicoIsLoading} />
          <DadosAprovacao detalhes={detalhes} />
          {!_.isEmpty(detalhes) && (
          <>
            <ObservacoesAprovacao limite={detalhes} />
            <DocumentosCadastro />
            <DocumentosJuridico />
          </>
          )}
        </div>
      </PageContent>
      <PageFooter>
        <ButtonsFooter limite={detalhes} />
      </PageFooter>
    </Page>
  );
};

LimitesAprovadosCadastro.propTypes = {
  historicoLimite: PropTypes.array,
  detalhes: PropTypes.object,
  historicoIsLoading: PropTypes.bool,
  cadastroPage: PropTypes.bool,
  getPessoaDocumentacao: PropTypes.func,
  getDocumentosComplementares: PropTypes.func,
  getDocumentosFormalizar: PropTypes.func,
};

LimitesAprovadosCadastro.defaultProps = {
  historicoLimite: [],
  detalhes: null,
  historicoIsLoading: false,
  cadastroPage: false,
  getPessoaDocumentacao: () => {},
  getDocumentosComplementares: () => {},
  getDocumentosFormalizar: () => {},
};

export default LimitesAprovadosCadastro;
