import {
  setCondicaoSelecionadaInicial,
} from './reducerUtils';

import actions from './actions';

const INITIAL_STATE = {
  isOpen: false,

  modal: {
    isLoading: false,
    isError: false,
    detalhePedido: null,
    condicaoSelecionadaId: null,
  },

  cancelPedido: {
    isLoading: false,
    isError: false,
  },
};

const reduceGetDetalhePedido = (state, action) => {
  switch (action.type) {
    case actions.types.GET_DETALHE_PEDIDO_START:
      return {
        ...state,
        modal: {
          isLoading: true,
          isError: false,
          detalhePedido: null,
        },
      };
    case actions.types.GET_DETALHE_PEDIDO_SUCCESS:
      return {
        ...state,
        modal: {
          ...state.modal,
          isLoading: false,
          isError: false,
          detalhePedido: action.payload.detalhePedido,
          condicaoSelecionadaId: setCondicaoSelecionadaInicial(action.payload.detalhePedido),
        },
      };
    case actions.types.GET_DETALHE_PEDIDO_ERROR:
      return {
        ...state,
        modal: {
          ...state.modal,
          isLoading: false,
          isError: true,
          detalhePedido: null,
        },
      };
    default:
      return state;
  }
};

const reduceCancelPedido = (state, action) => {
  switch (action.type) {
    case actions.types.CANCEL_PEDIDO_START:
      return {
        ...state,
        cancelPedido: {
          ...state.cancelPedido,
          isLoading: true,
          isError: false,
        },
      };
    case actions.types.CANCEL_PEDIDO_SUCCESS:
      return {
        ...state,
        cancelPedido: {
          ...state.cancelPedido,
          isLoading: false,
          isError: false,
        },
      };
    case actions.types.CANCEL_PEDIDO_ERROR:
      return {
        ...state,
        cancelPedido: {
          ...state.cancelPedido,
          isLoading: false,
          isError: true,
        },
      };

    default:
      return state;
  }
};

export default (state = INITIAL_STATE, action = { type: '@@detalhesPedido/INIT' }) => {
  if (action.type.startsWith(actions.types.PREFIX_GET_DETALHE_PEDIDO)) {
    return reduceGetDetalhePedido(state, action);
  }
  if (action.type.startsWith(actions.types.PREFIX_CANCEL_PEDIDO)) {
    return reduceCancelPedido(state, action);
  }

  switch (action.type) {
    case actions.types.SET_OPEN:
      return {
        ...state,
        isOpen: action.payload.isOpen,
      };
    default:
      return state;
  }
};
