import { useEffect, useState } from 'react';

export default function useToggleEffect(functionEffect, dependencies = [], ownDependency = true) {
  const [effectEnable, setEffectEnable] = useState(false);
  if (ownDependency) dependencies.push(effectEnable);

  useEffect(() => {
    if (effectEnable) {
      functionEffect();
      setEffectEnable(false);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, dependencies);

  return [() => setEffectEnable(true), effectEnable];
}
