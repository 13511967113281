import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(() => ({
  button: {
    borderRadius: 0,
    padding: 0,
    fontSize: 'inherit',
    fontWeight: 'inherit',
    letterSpacing: 'inherit',
    color: 'inherit',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:disabled': {
      // color: 'rgba(0, 0, 0, 0.54)',
      color: 'inherit',
    },
    cursor: 'pointer',
    '&.MuiIconButton-root': {
      // color: 'inherit',
    },
  },
  input: {
    display: 'none',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));

const ButtonIcon = ({
  children, disabled, onClick, className, isLoading,
}) => {
  const classes = useStyles();
  return (
    <IconButton
      className={`${classes.button} ${className}`}
      disabled={disabled || isLoading}
      disableRipple
      onClick={() => {
        onClick();
      }}
      data-cy="ButtonIcon"
    >
      {isLoading ? (
        <div className={classes.loading}>
          <CircularProgress color="inherit" size="18px" />
        </div>
      ) : children}
    </IconButton>
  );
};

ButtonIcon.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
};

ButtonIcon.defaultProps = {
  onClick: () => {},
  children: null,
  disabled: false,
  className: null,
  isLoading: false,
};

export default ButtonIcon;
