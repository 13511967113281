import { connect } from 'react-redux';
import UserInfo from './userInfo';

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
