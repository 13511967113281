import { connect } from 'react-redux';
import LimitesAprovadosCadastro from './limitesAprovadosCadastro';
import operations from './redux/operations';

const mapStateToProps = ({ limitesAprovados }) => ({
  cadastroPage: limitesAprovados.cadastroPage,
  historicoLimite: limitesAprovados.limiteDetails.historico,
  detalhes: limitesAprovados.limiteDetails.detalhes,
  historicoIsLoading: limitesAprovados.limiteDetails.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getPessoaDocumentacao: (id) => dispatch(operations.getPessoaDocumentacao(id)),
  getDocumentosComplementares: (id) => dispatch(operations.getDocumentosComplementares(id)),
  getDocumentosFormalizar: (id) => dispatch(operations.getDocumentosFormalizar(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LimitesAprovadosCadastro);
