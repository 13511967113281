import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars';

import {
  Page, PageHeader, PageTitle, PageSubTitle, PageContent,
} from 'features/common-staff/layout/page';

import SnackbarList from 'features/common-staff/snackbarList';
import BreadCrumbTransferencia from './views/breadCrumbTransferencia';
import PageErrorBoundary from '../../../common/errorBoundary/pageErrorBoundary';
import ModalConfirmacao from './views/modalConfirmacao';

import DadosSolicitacao from './views/dadosSolicitacaoForm';
import DadosOrigem from './views/dadosOrigem';
import DadosDestino from './views/dadosDestino';
import EnviarButton from './views/enviarButton';

const useStyles = makeStyles({
  content: {
    padding: '0 48px 0 48px',
    display: 'flex',
    flexDirection: 'column',
  },
});

const TransferenciaLimitePage = ({
  getProdutos, resetStore, snackbarErrors, onSnackbarClose, isOpen,
}) => {
  useEffect(() => {
    getProdutos();
    return () => { resetStore(); };
  }, [getProdutos, resetStore]);
  const classes = useStyles();
  return (
    <PageErrorBoundary>
      <Scrollbars
        autoHeight
        autoHeightMax="100vh"
        autoHeightMin="100vh"
      >
        <Page>
          <PageHeader>
            <PageSubTitle>
              <BreadCrumbTransferencia />
            </PageSubTitle>
            <PageTitle>Solicitação de transferência de limite</PageTitle>
          </PageHeader>
          <PageContent>
            <div className={classes.content}>
              <DadosSolicitacao />
              <DadosOrigem />
              <DadosDestino />
              <EnviarButton />
            </div>
          </PageContent>
        </Page>
      </Scrollbars>
      <SnackbarList
        snackbarErrors={snackbarErrors}
        onClose={(id) => onSnackbarClose(id)}
      />
      {isOpen ? <ModalConfirmacao /> : null}
    </PageErrorBoundary>
  );
};
TransferenciaLimitePage.propTypes = {
  snackbarErrors: PropTypes.array,
  onSnackbarClose: PropTypes.func,
  getProdutos: PropTypes.func,
  resetStore: PropTypes.func,
  isOpen: PropTypes.bool,
};

TransferenciaLimitePage.defaultProps = {
  snackbarErrors: [],
  onSnackbarClose: () => {},
  getProdutos: () => {},
  resetStore: () => {},
  isOpen: false,
};

export default TransferenciaLimitePage;
