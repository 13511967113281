import React from 'react';
import PropTypes from 'prop-types';

import './menuItem.scss';
import { SvgIcon } from '@mui/material';

const menuItem = ({
  title, isActive, onClick, Icon, dataCy,
}) => (
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  <div
    className={`sub-menu-item ${isActive ? 'sub-menu-item--active' : ''}`}
    onClick={onClick}
    data-cy={dataCy}
  >
    <span className="sub-menu-item-text">
      {Icon instanceof Function
        ? (<SvgIcon sx={{ fontSize: 24, verticalAlign: 'middle', marginRight: '10px' }}><Icon /></SvgIcon>)
        : (<Icon className="sub-menu-item-icon" />)}
      <span>{title}</span>
    </span>
  </div>
);

menuItem.propTypes = {
  Icon: PropTypes.any,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  dataCy: PropTypes.string,
};

menuItem.defaultProps = {
  Icon: null,
  dataCy: null,
};

export default menuItem;
