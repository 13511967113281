import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import { Tracking } from '../../../../modules';

import {
  PAYMENT_STEP_LIST, PAYMENT_STEP_CONFIRM,
} from '../../redux/paymentActions';
import { Button } from '../../../common';
import InvoicesTotalValue from '../invoicesTotalValue';
import PaymentEditorRow from '../paymentEditorRow';
import InvoicesCount from '../invoicesCount';
import PaymentHeader from '../paymentHeader';

import './paymentEditor.scss';

const PaymentEditor = (props) => {
  const {
    invoices, className, setPaymentStep, t,
    totalValue, remainingBalance, getBalance,
  } = props;

  useEffect(() => { getBalance(); }, [getBalance]);

  const handleBackClick = () => {
    Tracking.Mixpanel.trackPaymentEditBackClicked();
    setPaymentStep(PAYMENT_STEP_LIST);
  };

  const isPaymentEnabled = () => {
    const checker = (arr) => arr.every(Boolean);
    const map = invoices.map((element) => Boolean(element.selectedForPaymentValue));

    return (Boolean(checker(map)
      && Boolean(remainingBalance)
      && remainingBalance >= totalValue));
  };

  const handlePayClick = () => {
    const [numEditedInvoices, totalOriginalValue] = invoices.reduce(
      ([count, totalRemainingValue], invoice) => {
        if (invoice.remainingValue !== invoice.selectedForPaymentValue) {
          return [count + 1, totalRemainingValue + invoice.remainingValue];
        }

        return [count, totalRemainingValue + invoice.remainingValue];
      },
      [0, 0],
    );

    Tracking.Mixpanel.trackPaymentEditPayClicked(
      invoices.length,
      numEditedInvoices,
      totalOriginalValue,
      totalValue,
      remainingBalance,
    );
    setPaymentStep(PAYMENT_STEP_CONFIRM);
  };

  return (
    <div className="payment-content">
      <div className={`payment-editor-comp ${className}`}>
        <div className="payment-list-container">
          <PaymentHeader />
          <div className="payment-editor-header-row">
            <div className="payment-editor-header-row-due">
              {t('Vencimento')}
            </div>
            <div className="payment-editor-header-row-initial-value">
              {t('Valor inicial')}
            </div>
            <div className="payment-editor-header-row-payment-value">
              {t('Valor a pagar')}
            </div>
          </div>
          {
            invoices.map((invoice, index) => (
              <PaymentEditorRow
                key={`editor-row-${index}`}
                invoice={invoice}
              />
            ))
          }
        </div>

        <div className="editor-footer">
          <InvoicesCount
            className="editor-count"
            invoices={invoices}
          />

          <InvoicesTotalValue
            className="editor-total-value"
            totalValue={totalValue}
            availableBalance={remainingBalance}
            invoices={invoices}
          />

          <Button
            className="editor-btn-back"
            white
            onClick={handleBackClick}
          >
            {t('Voltar')}
          </Button>

          <Button
            className="editor-btn-confirm"
            disabled={!isPaymentEnabled()}
            onClick={handlePayClick}
          >
            {t('Pagar')}
          </Button>
        </div>
      </div>
    </div>
  );
};

PaymentEditor.propTypes = {
  className: PropTypes.string,
  setPaymentStep: PropTypes.func.isRequired,
  invoices: PropTypes.array.isRequired,
  totalValue: PropTypes.number.isRequired,
  remainingBalance: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  getBalance: PropTypes.func.isRequired,
};

PaymentEditor.defaultProps = {
  className: '',
};

export default withNamespaces('payment')(PaymentEditor);
