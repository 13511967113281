import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import CloseModalButton from './closeModalButton';

import './modal.scss';

const useStyles = makeStyles({
  container: {
    width: (props) => props.width ?? '85vw',
    height: (props) => props.height ?? '85vh',
  },
  overlay: {
    zIndex: (props) => props.zIndex ?? '1000',
  },
});

const Modal = ({
  children, disableCloseButton, closeModal, width, height, color, zIndex, showCloseText, dataCy,
}) => {
  const classes = useStyles({ width, height, zIndex });
  return (
    <div className={`common__modal__overlay ${classes.overlay}`}>
      <div className={`common__modal__container ${classes.container}`} data-cy={dataCy}>
        <div className="common__modal__close">
          <CloseModalButton
            disabled={disableCloseButton}
            onClick={closeModal}
            color={color}
            showText={showCloseText}
          />
        </div>
        <div className="common__modal__content">
          {children}
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  disableCloseButton: PropTypes.bool,
  closeModal: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  zIndex: PropTypes.string,
  showCloseText: PropTypes.bool,
  dataCy: PropTypes.string,
};

Modal.defaultProps = {
  children: null,
  color: null,
  disableCloseButton: false,
  closeModal: () => {},
  width: null,
  height: null,
  zIndex: null,
  showCloseText: true,
  dataCy: null,
};

export default Modal;
