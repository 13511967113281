import { connect } from 'react-redux';

import TestDriveForm from './testDriveForm';
import * as SolicitacaoOperations from '../../redux/testDriveOperations';
import { dealersAuthorized } from '../../redux/testDriveSelectors';
import * as PageSelectors from '../../../redux/selectors';

const mapStateToProps = ({ testDrive, dealerStore }) => ({
  dealers: dealersAuthorized(testDrive, dealerStore),
  selectedDealerId: testDrive.pedido.selectedDealerId,
  industrialOrder: testDrive.pedido.industrialOrder,
  chassis: testDrive.pedido.chassis,
  color: testDrive.pedido.color,
  observation: testDrive.pedido.observation,
  produto: PageSelectors.finalProduct(testDrive),
  motivos: testDrive.pedido.motivos,
  isModalUploadOpen: testDrive.pedido.isModalUploadOpen,
  filesToSend: testDrive.pedido.filesToSend,

});

const mapDispatchToProps = (dispatch) => ({
  setSelectedDealerId: (id) => dispatch(SolicitacaoOperations.setSelectedDealerId(id)),
  setIndustrialOrder: (order) => dispatch(SolicitacaoOperations.setIndustrialOrder(order)),
  setChassis: (chassis) => dispatch(SolicitacaoOperations.setChassis(chassis)),
  setColor: (color) => dispatch(SolicitacaoOperations.setColor(color)),
  setObservation: (observation) => dispatch(SolicitacaoOperations.setObservation(observation)),

  setSelectedMotivo: (motivo) => dispatch(SolicitacaoOperations.setSelectedMotivo(motivo)),
  openModalUpload: () => dispatch(SolicitacaoOperations.openModalUpload()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TestDriveForm);
