import { connect } from 'react-redux';
import ButtonComprovante from './buttonComprovante';
import operations from '../../../../../redux/operations';

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  onComprovanteClicked: () => { dispatch(operations.getExtratoEstorno()); },
});

export default connect(mapStateToProps, mapDispatchToProps)(ButtonComprovante);
