import { connect } from 'react-redux';

import Tracking from 'modules/tracking/MixpanelWrapper';
import InfoModal from './infoModal';
import selector from '../../../carteiraPage/redux/selectors';
import selectorModal from '../../redux/selector';
import OperationsModalHistorico from '../../../modalHistoricoDuplicata/redux/operations';

const mapStateToProps = ({ gestaoPagamentos, dealerStore, authStore }) => ({
  invoice: gestaoPagamentos.carteira.modal.modalInfo?.data,
  sistema: selector.selectSystem(dealerStore),
  companies: selectorModal.getCompanies(authStore),
});

const mapDispatchToProps = (dispatch) => ({
  setModalHistoricoOpen: () => {
    Tracking.carteira.carteiraFilters.trackHistoricoDuplicata();
    dispatch(OperationsModalHistorico.setOpenModal());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoModal);
