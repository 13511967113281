import React from 'react';
import PropTypes from 'prop-types';

import CapitalizeText from '../capitalizeText';
import DealerNumber from '../dealerNumber';

import './breadcrumb.scss';

const breadcrumb = ({ className, dealer }) => (
  <div className={`breadcrumb ${className}`}>
    <CapitalizeText text={dealer.dealerName || ''} />
    {' - '}
    <DealerNumber dealerCode={dealer.dealerCorr || ''} />
  </div>
);

breadcrumb.propTypes = {
  className: PropTypes.string,
  dealer: PropTypes.object,
};

breadcrumb.defaultProps = {
  className: '',
  dealer: {},
};

export default breadcrumb;
