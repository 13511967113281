import { connect } from 'react-redux';
import AcompanhamentoList from './acompanhamentoList';
import * as operations from './redux/operations';
import * as detalheOperations from '../acompanhamentoDetalhe/redux/operations';

const mapStateToProps = ({ testDrive }) => ({
  pedidos: testDrive.acompanhamento.list.pedidosList.pedidos,
  isError: testDrive.acompanhamento.list.pedidosList.isError,
  isLoading: testDrive.acompanhamento.list.pedidosList.isLoading,

  ipp: testDrive.acompanhamento.list.pedidosList.ipp,
  page: testDrive.acompanhamento.list.pedidosList.page,
  totalItems: testDrive.acompanhamento.list.pedidosList.totalItems,

  isModalOpen: testDrive.acompanhamento.detalhe.isOpen,
});

const mapDispatchToProps = (dispatch) => ({
  setIpp: (ipp) => dispatch(operations.setIpp(ipp)),
  setPage: (page) => dispatch(operations.setPage(page)),
  onRowClicked: (id) => dispatch(detalheOperations.openModal(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AcompanhamentoList);
