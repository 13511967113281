import React from 'react';
import PropTypes from 'prop-types';

import FormInput from 'features/common-staff/controls/input/formInput/';

const InputProduto = ({
  produto,
}) => (
  <FormInput
    type="text"
    label="Produto"
    value={produto}
  />
);

InputProduto.propTypes = {
  produto: PropTypes.string,
};

InputProduto.defaultProps = {
  produto: null,
};

export default InputProduto;
