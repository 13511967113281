import React from 'react';
import PropTypes from 'prop-types';
import { Tracking } from 'modules';

import Select from '../../../../../../../common/select';

const defaultProduto = [
  { text: 'Todos os produtos', value: 'all' },
];

const ProdutoSelect = ({
  produto, setProduto, produtoList, isLoading,
}) => (
  <Select
    items={defaultProduto.concat(produtoList)}
    label="Produto"
    value={produto || 'all'}
    onSelect={(r) => {
      Tracking.Mixpanel.carteira.carteiraFilters.trackTypeProductFilter(r);
      setProduto(r === 'all' ? null : r);
    }}
    disabled={isLoading}
  />
);

ProdutoSelect.propTypes = {
  produto: PropTypes.string,
  setProduto: PropTypes.func,
  produtoList: PropTypes.array,
  isLoading: PropTypes.bool,
};

ProdutoSelect.defaultProps = {
  produto: null,
  setProduto: () => {},
  produtoList: null,
  isLoading: false,
};

export default ProdutoSelect;
