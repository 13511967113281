export const arrayNotaFiscal = [
  {
    value: { valorMinimo: 75000, valorMaximo: 0 },
    label: 'Até R$75.000,00',
  },
  {
    value: { valorMinimo: 75000, valorMaximo: 100000 },
    label: 'R$75.000,00 à R$100.000,00',
  },
  {
    value: { valorMinimo: 100000, valorMaximo: 125000 },
    label: 'R$100.000,00 à R$125.000,00',
  },
  {
    value: { valorMinimo: 125000, valorMaximo: 150000 },
    label: 'R$125.000,00 à R$150.000,00',
  },
  {
    value: { valorMinimo: 150000, valorMaximo: 175000 },
    label: 'R$150.000,00 à R$175.000,00',
  },
  {
    value: { valorMinimo: 175000, valorMaximo: 200000 },
    label: 'R$175.000,00 à R$200.000,00',
  },
  {
    value: { valorMinimo: 0, valorMaximo: 200000 },
    label: 'Maior que R$200.000,00',
  },
];

export const arrayStatusDuplicata = [
  {
    value: 'EmAberto',
    label: 'Em aberto',
  },
  {
    value: 'Pagas',
    label: 'Liquidadas',
  }];

export const arrayBrands = ['FIAT', 'JEEP', 'RAM'];
