import React from 'react';
import BreadCrumb from 'features/common-staff/layout/breadCrumb';

const BreadCrumbHistoricoResgates = () => (
  <BreadCrumb
    labels={[{
      label: 'FIDC',
      path: '/carteira-fidc/duplicatas',
    }, {
      label: 'Resgates',
      path: '/carteira-fidc/resgates/historico-resgates',
    }, {
      label: 'Histórico de resgates',
      path: '/historico-resgates',
    }]}
    format={false}
  />
);

export default BreadCrumbHistoricoResgates;
