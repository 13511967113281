import {
  saveDealers,
  saveSelectedFPlanDealerId,
  saveSelectedFPlanBrandName,
} from '../dealers';
import * as DealerActions from './dealerActions';
import { cancelAll } from '../../../setup/axios/cancellation';

export const setDealers = (dealers) => (dispatch) => {
  saveDealers(dealers);
  dispatch(DealerActions.setDealers(dealers));
};

export const setSelectedFPlanDealer = (dealerId) => (dispatch) => {
  saveSelectedFPlanDealerId(dealerId);
  cancelAll();
  dispatch(DealerActions.setSelectedFPlanDealer(dealerId));
};

export const setSelectedFPlanBrand = (brandName) => (dispatch) => {
  saveSelectedFPlanBrandName(brandName);
  dispatch(DealerActions.setSelectedFPlanBrand(brandName));
};
