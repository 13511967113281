import React from 'react';
import PropTypes from 'prop-types';
import PaymentReceiptInfoBox from '../paymentReceiptInfoBox';
import {
  CnpjFormat, DealerNumber,
} from '../../../../common';

import './paymentReceiptDealerInfo.scss';

const PaymentReceiptDealerInfo = ({
  className, dealerInfo,
}) => (
  <div className={`payment-receipt-body-info-block ${className}`}>

    <div className="payment-receipt-body-info-tittle">DADOS CONCESSIONÁRIA</div>
    <PaymentReceiptInfoBox
      subtittle="NOME"
    >
      <div className="payment-receipt-body-info-values">{dealerInfo !== null ? dealerInfo.concessionariaNome : null}</div>
    </PaymentReceiptInfoBox>

    <PaymentReceiptInfoBox
      subtittle="COD."
    >
      <DealerNumber
        dealerCode={dealerInfo !== null ? dealerInfo.concessionariaCodigo : null}
        className="payment-receipt-body-info-values"
      />
    </PaymentReceiptInfoBox>

    <PaymentReceiptInfoBox
      subtittle="CNPJ"
    >
      <CnpjFormat
        className="payment-receipt-body-info-values"
        value={dealerInfo !== null ? dealerInfo.concessionariaCnpj : ''}
      />
    </PaymentReceiptInfoBox>
  </div>
);

PaymentReceiptDealerInfo.propTypes = {
  className: PropTypes.string,
  dealerInfo: PropTypes.shape({
    concessionariaCnpj: PropTypes.string,
    concessionariaCodigo: PropTypes.number,
    concessionariaNome: PropTypes.string,
  }).isRequired,
};

PaymentReceiptDealerInfo.defaultProps = {
  className: '',
};

export default PaymentReceiptDealerInfo;
