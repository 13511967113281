import { connect } from 'react-redux';

import { TestDriveOperations as SolicitacaoOperations } from './redux';
import * as PageOperations from '../redux/operations';
import * as PageSelectors from '../redux/selectors';
import SolicitacaoPage from './solicitacaoPage';

const mapStateToProps = ({
  testDrive, authStore, dealerStore,
}) => ({
  user: authStore.user,
  dealers: dealerStore.dealers,

  offers: testDrive.pedido.offers,
  selectedOfferId: testDrive.pedido.selectedOfferId,
  isOffersLoading: testDrive.pedido.isOffersLoading,

  motivos: testDrive.pedido.motivos,
  selectedMotivoId: testDrive.pedido.selectedMotivoId,

  conditions: testDrive.pedido.conditions,
  selectedConditionId: testDrive.pedido.selectedConditionId,

  selectedDealerId: testDrive.pedido.selectedDealerId,
  industrialOrder: testDrive.pedido.industrialOrder,
  chassis: testDrive.pedido.chassis,
  color: testDrive.pedido.color,
  observation: testDrive.pedido.observation,
  selectedFiles: testDrive.pedido.selectedFiles,

  isSubmitting: testDrive.pedido.isSubmitting,
  showOffersLoadError: testDrive.pedido.showOffersLoadError,
  showConditionsLoadError: testDrive.pedido.showConditionsLoadError,
  showSubmitError: testDrive.pedido.showSubmitError,
  showSubmitSuccess: testDrive.pedido.showSubmitSuccess,

  simulationBaseValue: testDrive.pedido.simulationBaseValue,

  selectedCategory: PageSelectors.selectedCategory(testDrive),
  selectedProduct: PageSelectors.selectedProduct(testDrive),
  finalProduct: PageSelectors.finalProduct(testDrive),
});

const mapDispatchToProps = (dispatch) => ({
  getCommercialConditions: (modelo, versao, serie, modelYear, marca, produto) => dispatch(
    SolicitacaoOperations.getCommercialConditions(modelo, versao, serie, modelYear, marca, produto),
  ),
  setSelectedOffer: (id) => dispatch(SolicitacaoOperations.setSelectedOffer(id)),
  setSelectedDealerId: (id) => dispatch(SolicitacaoOperations.setSelectedDealerId(id)),
  setSelectedCondition: (id) => dispatch(SolicitacaoOperations.setSelectedCommercialCondition(id)),
  submitTestDriveForm: (form, onSuccess, onError) => {
    dispatch(SolicitacaoOperations.submitForm(form, onSuccess, onError));
  },
  dismissSubmitError: () => dispatch(SolicitacaoOperations.dismissSubmitError()),
  dismissSubmitSuccess: () => dispatch(SolicitacaoOperations.dismissSubmitSuccess()),
  dismissShowConditionsLoadError: () => dispatch(
    SolicitacaoOperations.dismissShowConditionsLoadError(),
  ),
  clearForm: () => dispatch(SolicitacaoOperations.clearForm()),
  navToProdutos: () => dispatch(
    PageOperations.setPage(PageOperations.PAGE_PRODUTO),
  ),
  navToAcompanhamento: () => dispatch(PageOperations.setPage(PageOperations.PAGE_ACOMPANHAMENTO)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SolicitacaoPage);
