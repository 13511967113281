
/* eslint-disable */

import React from "react";

const NewCalendarIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M4.91991 14.0062C5.24658 14.3329 5.77325 14.3329 6.09991 14.0062L11.6399 8.46621C11.8999 8.20621 11.8999 7.78621 11.6399 7.52621L6.09991 1.98621C5.77325 1.65954 5.24658 1.65954 4.91991 1.98621C4.59325 2.31288 4.59325 2.83954 4.91991 3.16621L9.74658 7.99954L4.91325 12.8329C4.59325 13.1529 4.59325 13.6862 4.91991 14.0062Z" fill={props.fill}/>
  </svg>
);

export default NewCalendarIcon;

