import axios from 'axios';

import { formatDateForUrl } from 'utils/axios';

class StatementService {
  constructor() {
    this.URL_API = window.env.REACT_APP_API_URL;
  }

  async getEstornoDetalhe(duplicataNumero) {
    return axios.get(`${this.URL_API}/balance/estorno/${duplicataNumero}`, {
      params: {
      },
    });
  }

  async getPagamentoDetalhe(movimentacaoId) {
    const params = new URLSearchParams();
    movimentacaoId.map((t) => params.append('movimentacaoId', t));
    return axios.get(`${this.URL_API}/balance/pagamento?`, {
      params,
    });
  }

  async getTransactions(pageParams, filters, dealerId) {
    const { CancelToken } = axios;
    this.source = CancelToken.source();
    const response = await axios.get(`${this.URL_API}/transactions/movimentacoes?`, {
      cancelToken: this.source.token,
      params: {
        dealerId,
        ipp: pageParams.ipp,
        page: pageParams.page,
        startDate: formatDateForUrl(filters.startDate),
        endDate: formatDateForUrl(filters.endDate),
        text: filters.text,
        tipoMovimentacoes: filters.tipo,
      },
    });

    if (!response || response.status !== 200) {
      throw new Error(response.data);
    }

    return {
      ...response.data,
      transactions: response.data.transactions.map((t) => (
        {
          ...t,
          data: new Date(t.data),
        })),
    };
  }

  async getExtrato(transacaoIdList) {
    const params = new URLSearchParams();
    transacaoIdList.map((t) => params.append('transacaoIdList', t));
    const response = await axios.get(`${this.URL_API}/transactions/extrato?`, {
      params,
      responseType: 'arraybuffer',
    });

    if (!response || response.status !== 200) {
      throw new Error('Error downloading extrato xlsx');
    }

    return response.data;
  }

  async getExtratoEstorno(duplicata) {
    const response = await axios.get(`${this.URL_API}/transactions/extrato-estorno/${duplicata}`, {
      responseType: 'arraybuffer',
    });

    if (!response || response.status !== 200) {
      throw new Error('Error downloading extrato estorno xlsx');
    }

    return response.data;
  }
}

export default new StatementService();
