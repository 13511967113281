import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

import './currency.scss';

function formatAsBRL(number, positiveChar) {
  if (!number && number !== 0) {
    return null;
  }

  const parts = Math.abs(number).toFixed(2).split('.');
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  const centsPart = parts[1];
  const sign = number < 0 ? '-' : positiveChar;

  return {
    sign,
    integerPart,
    centsPart,
  };
}

export default function Currency(props) {
  const {
    value, explicitSign, className, classNameCents,
    noColor, rsValueSeparation,
  } = props;
  const positiveChar = explicitSign ? '+' : '';
  const numInfo = formatAsBRL(value, positiveChar);

  if (numInfo) {
    const { sign, integerPart, centsPart } = numInfo;

    let classes = `${className}`;
    if (!noColor) {
      classes += (value < 0) ? ' currency-negative' : ' currency-positive';
    }

    return (
      <span className={classes}>
        <span style={{ marginRight: rsValueSeparation }}>{`${sign} R$`}</span>
        <span>{integerPart}</span>
        <span className={classNameCents}>{`,${centsPart}`}</span>
      </span>
    );
  }

  return (
    <Skeleton />
  );
}

Currency.defaultProps = {
  value: undefined,
  className: '',
  classNameCents: '',
  explicitSign: false,
  noColor: false,
  rsValueSeparation: '0.1em',
};

Currency.propTypes = {
  value: PropTypes.number,
  className: PropTypes.string,
  classNameCents: PropTypes.string,
  explicitSign: PropTypes.bool,
  noColor: PropTypes.bool,
  rsValueSeparation: PropTypes.string,
};

export { formatAsBRL };
