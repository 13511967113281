import { connect } from 'react-redux';
import { AuthSelectors } from 'modules/auth/redux';

import RenderIfPermission from './renderIfPermission';

const mapStateToProps = ({ authStore }) => ({
  permissions: AuthSelectors.getAuthorizations(authStore),
});

export default connect(mapStateToProps)(RenderIfPermission);
