import React from 'react';
import PropTypes from 'prop-types';
import { useDuplicataContext } from 'features/carteiraFidc/contexts/duplicataContext';
import useToggleEffect from 'hooks/useToggleEffect';
import OrderButton from './orderButton/orderButton';
import {
  Container, Row, Column, Action, Text,
} from './duplicataListHeader.style';

const DuplicataListHeader = ({ gridTemplate, isDrawerOpen }) => {
  const duplicataContext = useDuplicataContext();
  const { state, setFilters, getDuplicatas } = duplicataContext;
  const { nomeColuna, sentidoOrdenacao } = state.filters;

  const [toggleFetch] = useToggleEffect(() => {
    getDuplicatas();
  }, [nomeColuna, sentidoOrdenacao]);

  const setOrder = (column, order) => {
    setFilters(['nomeColuna', 'sentidoOrdenacao'], [column, order]);
    toggleFetch();
  };

  const renderOrderArrow = (column) => (
    <OrderButton
      column={column}
      nomeColuna={nomeColuna}
      sentidoOrdenacao={sentidoOrdenacao}
      setOrder={setOrder}
    />
  );

  return (
    <Container>
      <Row className={gridTemplate?.container} data-cy="DuplicataListHeader">
        <Column gridArea="duplicata">
          <Action>
            {renderOrderArrow('NumeroDuplicata')}
          </Action>
          <Text>Nº da Duplicata</Text>
        </Column>
        <Column gridArea="valorPrincipal">
          <Action>
            {renderOrderArrow('ValorPrincipal')}
          </Action>
          <Text>Val. Principal</Text>
        </Column>
        <Column gridArea="valorAtualizado">
          <Action>
            {renderOrderArrow('ValorAtualizado')}
          </Action>
          <Text>Val. Atualizado</Text>
        </Column>
        <Column gridArea="juros">
          <Action>
            {renderOrderArrow('Juros')}
          </Action>
          <Text>Juros</Text>
        </Column>
        {!isDrawerOpen && (
          <>
            <Column gridArea="vencimento">
              <Action>
                {renderOrderArrow('DataVencimentoProrrogada')}
              </Action>
              <Text>Vencimento</Text>
            </Column>
            <Column gridArea="status">
              <Action>
                {renderOrderArrow('Status')}
              </Action>
              <Text>Status</Text>
            </Column>
          </>
        )}
      </Row>
    </Container>
  );
};

DuplicataListHeader.propTypes = {
  gridTemplate: PropTypes.object,
  isDrawerOpen: PropTypes.bool,
};

DuplicataListHeader.defaultProps = {
  gridTemplate: {},
  isDrawerOpen: false,
};

export default DuplicataListHeader;
