import actions from './actions';

const INITIAL_STATE = {
  modalInfo: {
    isOpen: false,
    data: null,
  },

  modalPayment: {
    isOpen: false,
    data: [],
  },
};

export default (state = INITIAL_STATE, action = { type: '@@carteiraModal/INIT' }) => {
  switch (action.type) {
    case actions.types.SET_MODAL_INFO:
      return {
        ...state,
        modalInfo: {
          ...state.modalInfo,
          data: action.payload.data,
        },
      };
    case actions.types.SET_MODAL_INFO_OPEN:
      return {
        ...state,
        modalInfo: {
          data: action.payload.value ? state.modalInfo.data : null,
          isOpen: action.payload.value,
        },
      };
    case actions.types.REMOVE_INVOICE:
      return {
        ...state,
        modalPayment: {
          ...state.modalPayment,
          data: (state.modalPayment.data || [])
            .filter((d) => d.duplicataId !== action.payload.invoiceId),
        },
      };
    case actions.types.SET_INVOICE:
      return {
        ...state,
        modalPayment: {
          ...state.modalPayment,
          data: state.modalPayment.data
            .findIndex((m) => m.duplicataId === action.payload.invoice.duplicataId) >= 0
            ? state.modalPayment.data
              .filter((m) => m.duplicataId !== action.payload.invoice.duplicataId)
            : [...state.modalPayment.data, action.payload.invoice],
        },
      };
    case actions.types.SELECT_ALL:
      return {
        ...state,
        modalPayment: {
          ...state.modalPayment,
          data: action.payload.invoices,
        },
      };
    case actions.types.RESET_STORE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
