import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import { withNamespaces } from 'react-i18next';

import MarcaBadge from 'features/common-staff/views/marcaBadge';
import Currency from '../currency';
import Date from '../date';
import XIcon from '../../../assets/icons/x';
import './transferenciaDrawer.css';

const renderFooterBasedOnType = (t, data) => {
  if (data.status === 'fca') {
    return (
      <section>
        <div className="t-drawer-subsection">
          <div className="t-drawer-label">{t('Código da concessionária')}</div>
          <div className="t-drawer-data">{data.dealerCode}</div>
        </div>
        <div className="t-drawer-subsection">
          <div className="t-drawer-label">
            {t('Crédito está sujeito a conciliação da Stellantis')}
          </div>
        </div>
      </section>
    );
  }
  if (data.status === 'ted') {
    return (
      <section>
        <div className="t-drawer-subsection">

          <div className="t-drawer-title-label">{t('Dados da Conta')}</div>

          <div className="t-drawer-bank-line">
            <div className="t-drawer-bank-left">
              <div className="t-drawer-label">{t('Banco')}</div>
              <div className="t-drawer-data">{data.bank}</div>
            </div>

            <div className="t-drawer-bank-rigth">
              <div className="t-drawer-label">{t('Agência')}</div>
              <div className="t-drawer-data">{data.bankBranch}</div>
            </div>
          </div>

          <div className="t-drawer-bank-line">
            <div className="t-drawer-bank-left">
              <div className="t-drawer-label">{t('c/c')}</div>
              <div className="t-drawer-data">{data.bankAccount}</div>
            </div>

            <div className="t-drawer-bank-rigth">
              <div className="t-drawer-label">{t('Cpf/Cnpj')}</div>
              <div className="t-drawer-data">{data.bankDocument}</div>
            </div>
          </div>

        </div>
      </section>
    );
  }
  return '';
};

const styles = {
  BackdropProps: {
    background: 'transparent',
  },
};

const TransferenciaDrawer = (
  {
    t, classes, isOpen, openPosition, onClose, data,
  },
) => (
  <Drawer
    open={isOpen}
    anchor={openPosition}
    onClose={onClose}
    ModalProps={{
      BackdropProps: {
        classes: {
          root: classes.BackdropProps,
        },
      },
    }}
  >
    <section className="t-drawer-section">

      <div className="t-drawer-header">
        <div id="t-drawer-close">
          <XIcon id="t-close-icon" onClick={onClose} />
        </div>
        <div id="t-drawer-logo">
          <MarcaBadge
            marca={(data?.brand)}
          />
        </div>
      </div>

      <div className="t-drawer-subsection">
        <div className="t-drawer-title-label">
          {
            data.status === 'ted'
              ? t('Transferência TED')
              : t('Transferência conta movimento FCA')
          }
        </div>
        <div id="t-drawer-transfer">
          {`Nº ${data.transferNumber}`}
        </div>
      </div>

      <div className="t-drawer-subsection">
        <div className="t-drawer-label">{t('Valor')}</div>
        <div id="t-drawer-value-negative">
          <Currency value={-data.totalValue} />
        </div>
      </div>

      <div className="t-drawer-subsection">
        <div className="t-drawer-label">{t('Data')}</div>
        <div className="t-drawer-data">
          <Date date={data.paymentDate} />
        </div>
      </div>

      <div className="t-drawer-subsection">
        <div className="t-drawer-label">{t('Usuário que realizou a operação')}</div>
        <div className="t-drawer-data">{data.user}</div>
      </div>

      {renderFooterBasedOnType(t, data)}

    </section>
  </Drawer>
);

TransferenciaDrawer.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  openPosition: PropTypes.oneOf(['right', 'left', 'bottom', 'top']),
  data: PropTypes.shape({
    brand: PropTypes.oneOf(['FIAT', 'JEEP']).isRequired,
    transferNumber: PropTypes.number.isRequired,
    status: PropTypes.oneOf(['ted', 'fca']).isRequired,
    totalValue: PropTypes.number.isRequired,
    dealerCode: PropTypes.number,
    user: PropTypes.string.isRequired,
    paymentDate: PropTypes.string.isRequired,
    bank: PropTypes.oneOf(['Itaú', 'Bradesco', 'Santander', 'Conta Movimento', 'Conta Corrente', '']),
    bankBranch: PropTypes.string,
    bankAccount: PropTypes.string,
    bankDocument: PropTypes.string,
  }).isRequired,
};

TransferenciaDrawer.defaultProps = {
  openPosition: 'right',
  onClose: () => (null),
};

export default withNamespaces()(withStyles(styles)(TransferenciaDrawer));
