/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import { ClickAwayListener } from '@mui/material';
import useCustomFetch from 'hooks/useFetch';
import useToggleEffect from 'hooks/useToggleEffect';
import { useDuplicataContext } from 'features/carteiraFidc/contexts/duplicataContext';
import duplicataService from 'features/carteiraFidc/services/duplicataService';
import { Tracking } from 'modules';
import ExportModal from '../exportModal/exportModal';
import { Container, ModalButton } from './exportButton.style';

const ExportButton = () => {
  const duplicataContext = useDuplicataContext();
  const { state, addSnackbar, closeDrawer } = duplicataContext;
  const [anchor, setAnchor] = useState(undefined);
  const [isCsv, setIsCsv] = useState(null);

  const [{
    loading, success, error, data,
  }, doFetch] = useCustomFetch(
    () => duplicataService.exportDuplicatas(state, isCsv),
    [],
    false,
  );

  const [toggleFetch] = useToggleEffect(() => {
    doFetch();
    setAnchor(undefined);
  }, [], true);

  useEffect(() => {
    if (success) {
      addSnackbar('Carteira exportada com sucesso', 'success');
      const url = window.URL.createObjectURL(new Blob([data.fileContent]));
      saveAs(url, data.fileName);
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      addSnackbar('Erro ao exportar carteira', 'error');
    }
  }, [error]);

  const downloadFile = (csv) => {
    setIsCsv(csv);
    toggleFetch();
  };

  const togglePopper = (event) => {
    if (anchor === undefined) {
      setAnchor(event.currentTarget);
      closeDrawer();
    } else {
      setAnchor(undefined);
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setAnchor(undefined)}>
      <Container>
        <ModalButton
          isOpen={anchor !== undefined}
          isLoading={loading}
          onClick={(event) => {
            togglePopper(event);
            Tracking.Mixpanel.fidc.trackButtons('fidc_duplicatas_botao_exportar_carteira');
          }}
          dataCy="BotaoExportarCarteiraFIDC"
        >
          Exportar carteira
        </ModalButton>
        <ExportModal
          anchor={anchor}
          togglePopper={togglePopper}
          downloadFile={downloadFile}
        />
      </Container>
    </ClickAwayListener>
  );
};

export default ExportButton;
