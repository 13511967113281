/* eslint-disable no-use-before-define */
import { messages } from '../../../../common/chat';
import store from '../../../../../setup/store';

import { etapas } from '../redux/etapas';
import { operations } from '../redux/operations';
import { texts } from './texts';

const historyCreators = [
  {
    etapa: etapas.sentimosMuito,
    creator: () => [
      messages.text('fidis', texts.sentimosMuito),
    ],
  },
  {
    etapa: etapas.selecionarCategoria,
    creator: (dados) => [
      messages.text('fidis', texts.selecioneCategoria),
      messages.select.answered.fromText('user', dados.categoriasDisponiveis, dados.categoriaSelecionada),
    ],
  },
  {
    etapa: etapas.descreverProblema,
    creator: (dados) => [
      messages.text('fidis', texts.descreva),
      messages.text('user', dados.descricao),
    ],
  },
  {
    etapa: etapas.reportarOutro,
    creator: (dados) => [
      messages.text('fidis', texts.reportarOutro),
      messages.select.answered.fromText('user', ['Sim', 'Não'], dados.reportarOutro ? 'Sim' : 'Não'),
    ],
  },
];

const currentEtapaCreators = [
  {
    etapa: etapas.sentimosMuito,
    creator: () => [messages.text('fidis', texts.sentimosMuito)],
  },
  {
    etapa: etapas.selecionarCategoria,
    creator: (state) => [
      messages.text('fidis', texts.selecioneCategoria),
      messages.select.open(
        'user',
        (state.categoriasDisponiveis || []).map((cat) => messages.option(cat, cat, false)),
        (value) => { store.dispatch(operations.selecionarCategoria(value)); },
      ),
    ],
  },
  {
    etapa: etapas.descreverProblema,
    creator: () => [messages.text('fidis', texts.descreva)],
  },
  {
    etapa: etapas.reportarOutro,
    creator: () => [
      messages.text('fidis', texts.reportarOutro),
      messages.selectOpenYesNo(
        'user',
        (value) => { store.dispatch(operations.reportarOutro(value)); },
      ),
    ],
  },
  {
    etapa: etapas.finalizado,
    creator: () => [messages.text('fidis', texts.agradecemos)],
  },
];

export const findForEtapa = (creators, etapa) => {
  const mapping = creators.find((m) => m.etapa === etapa);
  if (mapping && mapping.creator) {
    return mapping.creator;
  }

  return null;
};

const forHistory = (etapa) => findForEtapa(historyCreators, etapa);
const forCurrentEtapa = (etapa) => findForEtapa(currentEtapaCreators, etapa);

export const messageCreators = {
  forHistory,
  forCurrentEtapa,
};
