import React from 'react';
import PropTypes from 'prop-types';
import { Drawer } from '@mui/material';
import CloseModalButton from '../modal/closeModalButton';
import './basicDrawer.scss';

const BasicDrawer = ({
  open, setOpen, children, title, subtitle,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={open}
      sx={{
        width: '372px',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: '372px',
          flexDirection: 'column',
        },
      }}
    >
      <div className="drawer-container">
        <div className="drawer-header">
          <div className="drawer-title-header">
            <div className="drawer-title">
              {title}
            </div>
            <div className="drawer-subtitle">
              {subtitle}
            </div>
          </div>
          <div>
            <CloseModalButton onClick={handleClose} />
          </div>
        </div>
        {children}
      </div>
    </Drawer>
  );
};

BasicDrawer.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  children: PropTypes.element,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

BasicDrawer.defaultProps = {
  open: false,
  setOpen: () => {},
  children: null,
  title: '',
  subtitle: '',
};

export default BasicDrawer;
