import { connect } from 'react-redux';
import DocumentosJuridico from './documentosJuridico';
import selector from '../redux/selector';

const mapStateToProps = ({ limitesAprovadosCadastro }) => ({
  listaDocumentosComplementares: limitesAprovadosCadastro.listaDocumentosComplementares.listaDados,
  listaDocumentosFormalizar: limitesAprovadosCadastro.listaDocumentosFormalizar.listaDados,
  isLoading: limitesAprovadosCadastro.listaDocumentosComplementares.isLoading
          || limitesAprovadosCadastro.listaDocumentosFormalizar.isLoading,
  pendenciaDocumentosFormalizar: selector.pendenciaDocumentosFormalizar(limitesAprovadosCadastro),
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentosJuridico);
