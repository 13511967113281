import { createSelector } from 'reselect';

const getBrands = createSelector(
  (state) => state.dealers,
  (dealers) => {
    const brands = [...new Set(dealers.map((d) => d.brand))];
    if (brands.length > 1 && brands?.find((f) => f.toLowerCase() === 'fiat')) {
      return [
        {
          value: 'fiat',
          text: 'Fiat',
        },
        {
          value: 'cjdr',
          text: 'CJDR',
        },
      ];
    }
    if (brands[0].toLowerCase() === 'fiat') {
      return [{
        value: 'fiat',
        text: 'Fiat',
      }];
    }
    return [{
      value: 'cjdr',
      text: 'CJDR',
    }];
  },
);

const getQuantidadeBrands = createSelector(
  (state) => state.dealers,
  (dealers) => {
    const brands = [...new Set(dealers.map((d) => d.brand))];
    return brands.length;
  },
);

export default {
  getBrands,
  getQuantidadeBrands,
};
