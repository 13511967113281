import actions from './actions';

export const INITIAL_STATE_DADOS = {
  selectedDealerId: null,
  produto: null,
  codigoSap: null,
  brand: null,
};

export const reduceSetDadosOrigem = (state, action) => {
  switch (action.type) {
    case actions.types.SET_CODIGO_SAP_ORIGEM:
      return {
        ...state,
        dadosOrigem: {
          ...state.dadosOrigem,
          codigoSap: action.payload,
        },
      };
    case actions.types.SET_PRODUTO_ORIGEM:
      return {
        ...state,
        dadosOrigem: {
          ...state.dadosOrigem,
          produto: action.payload,
        },
      };
    case actions.types.SET_SELECTED_DEALER_ORIGEM:
      return {
        ...state,
        dadosOrigem: {
          ...state.dadosOrigem,
          selectedDealerId: action.payload,
        },
      };
    case actions.types.SET_MARCA_ORIGEM:
      return {
        ...state,
        dadosOrigem: {
          ...state.dadosOrigem,
          brand: action.payload,
        },
      };
    default:
      return state;
  }
};
export const reduceSetDadosDestino = (state, action) => {
  switch (action.type) {
    case actions.types.SET_CODIGO_SAP_DESTINO:
      return {
        ...state,
        dadosDestino: {
          ...state.dadosDestino,
          codigoSap: action.payload,
        },
      };
    case actions.types.SET_PRODUTO_DESTINO:
      return {
        ...state,
        dadosDestino: {
          ...state.dadosDestino,
          produto: action.payload,
        },
      };
    case actions.types.SET_SELECTED_DEALER_DESTINO:
      return {
        ...state,
        dadosDestino: {
          ...state.dadosDestino,
          selectedDealerId: action.payload,
        },
      };
    case actions.types.SET_MARCA_DESTINO:
      return {
        ...state,
        dadosDestino: {
          ...state.dadosDestino,
          brand: action.payload,
        },
      };
    default:
      return state;
  }
};
