import React from 'react';
import PropTypes from 'prop-types';
import StatusComponentStyle from './statusComponent.style';

const StatusComponent = ({ status, textStatus }) => {
  const renderStatus = (type) => (
    <StatusComponentStyle status={status}>
      <span>{type}</span>
    </StatusComponentStyle>
  );
  if (status === 'recusado') {
    return renderStatus(textStatus.recusado);
  }
  if (status === 'aprovado') {
    return renderStatus(textStatus.aprovado);
  }
  return renderStatus(textStatus.aguardando);
};

StatusComponent.propTypes = {
  status: PropTypes.string,
  textStatus: PropTypes.object,
};

StatusComponent.defaultProps = {
  status: '',
  textStatus: {
    recusado: '',
    aprovado: '',
    aguardando: '',
  },
};

export default StatusComponent;
