import { connect } from 'react-redux';

import PainelFloorPLan from './painelFloorPlan';
import operations from './redux/operations';
import selectors from './redux/selectors';

const mapStateToProps = ({ dealerStore, dashboard }) => ({
  brands: selectors.getBrands(dealerStore),
  isUpdated: dashboard?.painelFloorPlan?.isUpdated,
});

const mapDispatchToProps = (dispatch) => ({
  resetStore: () => dispatch(operations.resetStore()),
  load: () => dispatch(operations.isUpdated()),
  resetIsUpdated: () => dispatch(operations.resetIsUpdated()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PainelFloorPLan);
