import React from 'react';
import PropTypes from 'prop-types';
import BadgeStatus from 'features/common/badgeStatus/badgeStatus';
import MoneyIcon from 'assets/icons/money-icon';
import colors from 'assets/styles/colors';
import CardDetalheResgates from './cardDetalheResgates/cardDetalheResgates';

import {
  Container, Header, TipoDuplicata, CardIcon,
} from './detalheResgate.style';

const DetalheResgate = ({ resgate, isOpen, closeDrawer }) => (
  <Container data-cy="DuplicataDrawer">
    <Header>
      <TipoDuplicata>Informações sobre o resgate</TipoDuplicata>
    </Header>
    <CardIcon>
      <BadgeStatus
        status={resgate?.status}
        width="48px"
        height="48px"
        borderRadius="12px"
      >
        <MoneyIcon
          color={resgate?.status === 'Negado' ? colors.error_color_400 : colors.terciary_color_600}
          width="23px"
          height="23px"
        />
      </BadgeStatus>
    </CardIcon>
    <CardDetalheResgates resgate={resgate} isOpen={isOpen} closeDrawer={closeDrawer} />
  </Container>
);

DetalheResgate.propTypes = {
  resgate: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
};

DetalheResgate.defaultProps = {
};

export default DetalheResgate;
