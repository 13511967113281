/* eslint-disable */
import React from "react";

const RadioNoSelectedIcon = props => (
	<svg width="14" height="14" viewBox="0 0 14 14" fill="none">
		<path fillRule="evenodd" clipRule="evenodd" d="M0.333008 6.99992C0.333008 3.31992 3.31967 0.333252 6.99967 0.333252C10.6797 0.333252 13.6663 3.31992 13.6663 6.99992C13.6663 10.6799 10.6797 13.6666 6.99967 13.6666C3.31967 13.6666 0.333008 10.6799 0.333008 6.99992ZM1.66634 6.99992C1.66634 9.94658 4.05301 12.3333 6.99967 12.3333C9.94634 12.3333 12.333 9.94658 12.333 6.99992C12.333 4.05325 9.94634 1.66659 6.99967 1.66659C4.05301 1.66659 1.66634 4.05325 1.66634 6.99992Z" fill="#555770"/>
	</svg>
);

export default RadioNoSelectedIcon;

