import React from 'react';
import PropTypes from 'prop-types';
import { Tracking } from '../../../../../../modules';

import Select from '../../../../../common/select';

const produtoList = [
  { text: 'Todas as transações', value: 'Todas' },
  { text: 'Créditos', value: 'Creditos' },
  { text: 'Débitos', value: 'Debitos' },
];

const TipoTransacao = ({
  tipo, setTipoTransacao, isLoading,
}) => (
  <Select
    items={produtoList}
    value={tipo || 'Todas'}
    onSelect={(t) => {
      Tracking.Mixpanel.movimentacoes.trackFilters.trackTypeTransactionFilter(t);
      setTipoTransacao(t);
    }}
    disabled={isLoading}
  />
);

TipoTransacao.propTypes = {
  tipo: PropTypes.string,
  setTipoTransacao: PropTypes.func,
  isLoading: PropTypes.bool,
};

TipoTransacao.defaultProps = {
  tipo: null,
  setTipoTransacao: () => {},
  isLoading: false,
};

export default TipoTransacao;
