import { reactLocalStorage } from 'reactjs-localstorage';

const LS_FIDC_ACEITES = 'fidc/aceites/localStorage/IDs';

export const saveIdFidcAceites = (id) => {
  reactLocalStorage.setObject(LS_FIDC_ACEITES, id);
};

export const loadIdFidcAceites = () => (
  reactLocalStorage.getObject(LS_FIDC_ACEITES, [], true)
);

export const clearAllStorage = () => {
  reactLocalStorage.remove(LS_FIDC_ACEITES);
};
