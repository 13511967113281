import { makeStyles } from '@material-ui/styles';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  container: {
    marginTop: '40px',
    marginBottom: '40px',
    color: '#555770',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    marginBottom: '14px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const FormSection = ({ title, children }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.container}
      data-cy="LimitesDadosConcessionaria"
    >
      <div className={classes.title}>
        {title}
      </div>
      <div className={classes.content}>
        {children}
      </div>
    </div>
  );
};

FormSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};
FormSection.defaultProps = {
  title: null,
  children: null,
};

export default FormSection;
