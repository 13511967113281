import React from 'react';
import PropTypes from 'prop-types';

import IconeStellantisBanco from 'assets/icons/iconsRebranding/icone-stellantis-banco';
import UserIcon from '../../../../assets/imgs/empty-user.png';

import './textMessage.scss';

const TextMessage = ({
  className, message,
}) => {
  const FidisMsg = () => (
    <div className="text-message__fidis-container">
      <div className="text-message__icon">
        <IconeStellantisBanco />
      </div>
      <MessageBox message={message} color="white" />
    </div>
  );

  const UserMsg = () => (
    <div className="text-message__user-content">
      <div className="text-message__user-container">
        <div className="text-message__icon">
          <img src={UserIcon} alt="User icon" />
        </div>
        <MessageBox message={message} color="green" />
      </div>
    </div>
  );

  return (
    <div className={className}>
      {(message.sender === 'fidis') ? <FidisMsg /> : <UserMsg />}
    </div>
  );
};

// eslint-disable-next-line react/prop-types
const MessageBox = ({ message, color }) => {
  const colorClass = (color === 'green')
    ? 'text-message__box--green'
    : 'text-message__box--white';

  return (
    <div className={`text-message__box ${colorClass}`}>
      <span>{message.text}</span>
    </div>
  );
};

MessageBox.propTypes = {
  message: PropTypes.shape({
    text: PropTypes.string,
  }).isRequired,
};

TextMessage.propTypes = {
  className: PropTypes.string,
  message: PropTypes.shape({
    sender: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
};

TextMessage.defaultProps = {
  className: '',
};

export default TextMessage;
