import _ from 'lodash';

const strCmp = (s1, s2) => {
  if (!_.isString(s1) || !_.isString(s2)) return null;
  return s1.toLowerCase() === s2.toLowerCase();
};

export const isFidisUser = (user) => {
  if (user && user.companies && user.companies.length > 0) {
    return user.companies.some(
      (c) => (c.companyId || '').toLowerCase() === 'fidis',
    );
  }

  return false;
};

export const hasPermissions = (userPermissions, requiredPermissions) => {
  if (!_.isArray(userPermissions) || !_.isArray(requiredPermissions)) return null;

  let permissionsOk = true;
  requiredPermissions.forEach((requiredPerm) => {
    if (!userPermissions.some((p) => strCmp(p, requiredPerm))) {
      permissionsOk = false;
    }
  });

  return permissionsOk;
};

export const hasSomePermission = (userPermissions, requiredPermissions) => {
  if (!_.isArray(userPermissions) || !_.isArray(requiredPermissions)) return null;

  return requiredPermissions.some(
    (requiredPerm) => userPermissions.some((p) => strCmp(p, requiredPerm)),
  );
};
