import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(() => ({
  button: {
    borderRadius: 0,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:disabled': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    cursor: 'pointer',
  },
  input: {
    display: 'none',
  },
  loading: {
    marginTop: 3,
  },
}));

const ButtonIcon = ({ children, disabled, onClick }) => {
  const classes = useStyles();
  return (
    <IconButton
      className={classes.button}
      disabled={disabled}
      disableRipple
      onClick={onClick}
    >
      {children}
    </IconButton>
  );
};

ButtonIcon.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

ButtonIcon.defaultProps = {
  onClick: () => {},
  children: null,
  disabled: false,
};

export default ButtonIcon;
