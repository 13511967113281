import { connect } from 'react-redux';
import ButtonComprovante from './buttonComprovante';
import operations from '../../../../../../redux/operations';

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  onComprovanteClicked: (value) => { dispatch(operations.setModalComprovanteOpen(value)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(ButtonComprovante);
