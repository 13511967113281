import * as React from "react";

const CitroenLogoIcon = (props) => {
	return (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="32" height="32" rx="12" fill="white"/>
			<path d="M9.32422 15.4H10.7045V17.9158H11.2486V15.4H12.6289V14.8154H9.32634V15.4H9.32422Z" fill="black"/>
			<path d="M8.15625 14.8135H8.70033V17.9139H8.15625V14.8135Z" fill="black"/>
			<path d="M27.3178 14.8262H25.332V17.9185H25.8761V15.4107H27.1929C27.7052 15.4107 28.027 15.6504 28.027 16.3804V17.9185H28.5711V16.2915C28.5669 15.47 28.1943 14.8262 27.3178 14.8262Z" fill="black"/>
			<path d="M23.381 14.4471H24.1791C24.2701 14.4471 24.3485 14.3475 24.3485 14.2263C24.3485 14.0997 24.2701 14 24.1791 14H23.381C23.2815 14 23.2031 14.0997 23.2031 14.2263C23.2052 14.3421 23.2857 14.4471 23.381 14.4471Z" fill="black"/>
			<path d="M15.5877 16.6983C16.3393 16.6767 16.587 16.4208 16.587 15.7663C16.587 15.1737 16.2546 14.8262 15.6936 14.8262H13.2441V17.9185H13.7882V15.4107H15.6957C15.9116 15.4107 16.0238 15.5346 16.0238 15.7797C16.0238 15.9952 15.9709 16.2026 15.6195 16.1892H14.2476V16.3669L15.9222 17.905H16.7098L15.3189 16.6902L15.5877 16.6983Z" fill="black"/>
			<path d="M19.5682 14.7625C19.403 14.7517 19.2337 14.749 19.0643 14.749C18.7806 14.749 18.5943 14.7625 18.5922 14.7625H18.5816C18.1879 14.7787 17.8491 14.9457 17.6078 15.2473C17.377 15.5356 17.25 15.9369 17.25 16.3733V16.376C17.25 17.3161 17.7856 17.9625 18.5859 17.9868C18.751 17.9975 18.9204 18.0002 19.0897 18.0002C19.3734 18.0002 19.5597 17.9868 19.5618 17.9868H19.5703C19.9641 17.9706 20.3028 17.8036 20.5441 17.5019C20.7749 17.2137 20.9019 16.8124 20.9019 16.376V16.3733C20.904 15.4521 20.3663 14.8056 19.5682 14.7625ZM20.3261 16.3652C20.324 16.9632 20.0191 17.3484 19.5301 17.3726H19.5195H19.5089C19.4793 17.3753 19.3649 17.3861 19.1151 17.3861C18.95 17.3861 18.7891 17.3834 18.643 17.3726H18.6346C18.1392 17.3565 17.8322 16.9686 17.8322 16.3652C17.8322 15.7645 18.137 15.3766 18.6282 15.3497H18.6388H18.6494C18.6515 15.3497 18.8547 15.3362 19.1342 15.3362C19.2908 15.3362 19.3586 15.3389 19.492 15.3497H19.5131C20.0212 15.3793 20.324 15.7564 20.324 16.3625L20.3261 16.3652Z" fill="black"/>
			<path d="M21.6115 17.9185H24.5732V17.3339H22.1535V16.639H24.2747V16.0787H22.1513V15.4107H24.5711V14.8262H21.6094V17.9185H21.6115Z" fill="black"/>
			<path d="M21.9981 14.4471H22.7942C22.8873 14.4471 22.9678 14.3475 22.9678 14.2263C22.9678 14.0997 22.8894 14 22.7942 14H21.9981C21.8986 14 21.8203 14.0997 21.8203 14.2263C21.8224 14.3421 21.9008 14.4471 21.9981 14.4471Z" fill="black"/>
			<path d="M5.31469 14.8377H5.30622C4.92092 14.8538 4.59066 15.0128 4.35143 15.2956C4.12491 15.5703 4 15.9475 4 16.365V16.3677C4 17.2593 4.52503 17.8734 5.30834 17.8976C5.52851 17.9111 6.19327 17.9111 6.46848 17.9111C6.82415 17.9111 7.16499 17.9084 7.26661 17.9084V17.3158C7.11418 17.3185 5.96039 17.3185 5.82278 17.3185C5.66189 17.3185 5.50099 17.3158 5.35491 17.305C4.87434 17.2916 4.56525 16.9226 4.56525 16.3677C4.56525 15.8128 4.87223 15.4411 5.34433 15.4168H5.35491H5.3655C5.36761 15.4168 5.56662 15.4033 5.83972 15.4033C5.91593 15.4033 7.10148 15.406 7.26238 15.4087V14.8269C7.15652 14.8242 6.98716 14.8242 6.76699 14.8242C6.16363 14.8242 5.32527 14.8377 5.31469 14.8377Z" fill="black"/>
		</svg>

	);
}

export default CitroenLogoIcon;