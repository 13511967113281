import React from 'react';

import DadosSolicitacao from '../dadosSolicitacao';
import DadosConcessionaria from '../dadosConcessionaria';

import EnviarButton from '../enviarButton';

import './alteracaoLimiteForm.scss';

const AlteracaoLimiteForm = () => (
  <div className="alteracao-limite-form__content">
    <DadosSolicitacao />
    <DadosConcessionaria />
    <EnviarButton />
  </div>
);

export default AlteracaoLimiteForm;
