const SET_VALOR = 'transferenciaLimite/SET_VALOR';
const SET_MODAL_OPEN = 'transferenciaLimite/SET_MODAL_OPEN';

const PREFIX_SET_DADOS_ORIGEM = 'transferenciaLimite/dadosOrigem';
const SET_PRODUTO_ORIGEM = `${PREFIX_SET_DADOS_ORIGEM}/SET_PRODUTO_ORIGEM`;
const SET_CODIGO_SAP_ORIGEM = `${PREFIX_SET_DADOS_ORIGEM}/SET_CODIGO_SAP_ORIGEM`;
const SET_SELECTED_DEALER_ORIGEM = `${PREFIX_SET_DADOS_ORIGEM}/SET_SELECTED_DEALER_ORIGEM`;
const SET_MARCA_ORIGEM = `${PREFIX_SET_DADOS_ORIGEM}/SET_MARCA_ORIGEM`;

const PREFIX_SET_DADOS_DESTINO = 'transferenciaLimite/dadosDestino';
const SET_PRODUTO_DESTINO = `${PREFIX_SET_DADOS_DESTINO}/SET_PRODUTO_DESTINO`;
const SET_CODIGO_SAP_DESTINO = `${PREFIX_SET_DADOS_DESTINO}/SET_CODIGO_SAP_DESTINO`;
const SET_SELECTED_DEALER_DESTINO = `${PREFIX_SET_DADOS_DESTINO}/SET_SELECTED_DEALER_DESTINO`;
const SET_MARCA_DESTINO = `${PREFIX_SET_DADOS_DESTINO}/SET_MARCA_DESTINO`;

const DISMISS_SNACKBAR = 'transferenciaLimite/DISMISS_SNACKBAR';

const PREFIX_POST_SOLITACAO_CREDITO = 'transferenciaLimite/POST_SOLITACAO_CREDITO';
const POST_SOLITACAO_CREDITO_START = `${PREFIX_POST_SOLITACAO_CREDITO}/START`;
const POST_SOLITACAO_CREDITO_SUCCESS = `${PREFIX_POST_SOLITACAO_CREDITO}/SUCCESS`;
const POST_SOLITACAO_CREDITO_ERROR = `${PREFIX_POST_SOLITACAO_CREDITO}/ERROR`;

const PREFIX_GET_PRODUTOS = 'transferenciaLimite/GET_PRODUTOS';
const GET_PRODUTOS_START = `${PREFIX_GET_PRODUTOS}/START`;
const GET_PRODUTOS_SUCCESS = `${PREFIX_GET_PRODUTOS}/SUCCESS`;
const GET_PRODUTOS_ERROR = `${PREFIX_GET_PRODUTOS}/ERROR`;

const RESET_STORE = 'transferenciaLimite/RESET_STORE';

export const setModalOpen = (isOpen) => ({
  type: SET_MODAL_OPEN,
  payload: isOpen,
});

export const postSolicitacaoLimiteStart = () => ({
  type: POST_SOLITACAO_CREDITO_START,
});

export const postSolicitacaoLimiteSuccess = () => ({
  type: POST_SOLITACAO_CREDITO_SUCCESS,
});

export const getProdutosError = () => ({
  type: GET_PRODUTOS_ERROR,
});

export const getProdutosStart = () => ({
  type: GET_PRODUTOS_START,
});

export const getProdutosSuccess = (produtos) => ({
  type: GET_PRODUTOS_SUCCESS,
  payload: produtos,
});

export const postSolicitacaoLimiteError = () => ({
  type: POST_SOLITACAO_CREDITO_ERROR,
});

export const setValor = (valor) => ({
  type: SET_VALOR,
  payload: valor,
});

export const setCodigoSapOrigem = (sap) => ({
  type: SET_CODIGO_SAP_ORIGEM,
  payload: sap,
});

export const setProdutoOrigem = (produto) => ({
  type: SET_PRODUTO_ORIGEM,
  payload: produto,
});

export const setBrandOrigem = (brand) => ({
  type: SET_MARCA_ORIGEM,
  payload: brand,
});

export const setBrandDestino = (brand) => ({
  type: SET_MARCA_DESTINO,
  payload: brand,
});

export const setSelectedDealerOrigem = (dealer) => ({
  type: SET_SELECTED_DEALER_ORIGEM,
  payload: dealer,
});

export const setCodigoSapDestino = (sap) => ({
  type: SET_CODIGO_SAP_DESTINO,
  payload: sap,
});

export const setProdutoDestino = (produto) => ({
  type: SET_PRODUTO_DESTINO,
  payload: produto,
});

export const setSelectedDealerDestino = (dealer) => ({
  type: SET_SELECTED_DEALER_DESTINO,
  payload: dealer,
});

export const dismissSnackbar = (id) => ({
  type: DISMISS_SNACKBAR,
  payload: id,
});

export const resetStore = () => ({ type: RESET_STORE });

export default {
  types: {
    PREFIX_SET_DADOS_ORIGEM,
    SET_PRODUTO_ORIGEM,
    SET_CODIGO_SAP_ORIGEM,
    SET_SELECTED_DEALER_ORIGEM,
    SET_MARCA_ORIGEM,

    PREFIX_SET_DADOS_DESTINO,
    SET_PRODUTO_DESTINO,
    SET_CODIGO_SAP_DESTINO,
    SET_SELECTED_DEALER_DESTINO,
    SET_MARCA_DESTINO,

    PREFIX_GET_PRODUTOS,
    GET_PRODUTOS_START,
    GET_PRODUTOS_SUCCESS,
    GET_PRODUTOS_ERROR,

    PREFIX_POST_SOLITACAO_CREDITO,
    POST_SOLITACAO_CREDITO_START,
    POST_SOLITACAO_CREDITO_SUCCESS,
    POST_SOLITACAO_CREDITO_ERROR,

    SET_VALOR,
    SET_MODAL_OPEN,

    DISMISS_SNACKBAR,
    RESET_STORE,
  },
  setSelectedDealerDestino,
  setProdutoDestino,
  setCodigoSapDestino,

  setSelectedDealerOrigem,
  setProdutoOrigem,
  setCodigoSapOrigem,

  setValor,
  postSolicitacaoLimiteStart,
  postSolicitacaoLimiteSuccess,
  postSolicitacaoLimiteError,
  getProdutosStart,
  getProdutosSuccess,
  getProdutosError,
  dismissSnackbar,
  resetStore,
  setModalOpen,
  setBrandOrigem,
  setBrandDestino,
};
