import React from 'react';
import PropTypes from 'prop-types';

import './imageCard.scss';

const ImageCard = ({
  children, selected, noHover, onClick,
  style, src, alt,
}) => (
  <div
    className={`
    img-card-container
      ${selected ? 'img-card-container-selected' : null}
      ${noHover ? null : 'img-card-container-hover'}
    `}
    data-cy="ImgCardContainer"
    style={style}
    onClick={onClick}
    role="button"
    tabIndex="0"
  >
    <div className="img-card-image-container">
      <img className="img-card-image" src={src} alt={alt} />
    </div>
    <div className="img-card-content-container">
      {children}
    </div>
  </div>
);

ImageCard.propTypes = {
  children: PropTypes.node,
  src: PropTypes.string,
  alt: PropTypes.string,
  selected: PropTypes.bool,
  noHover: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

ImageCard.defaultProps = {
  children: null,
  src: null,
  alt: 'img',
  selected: false,
  noHover: false,
  onClick: () => {},
  style: null,
};

export default ImageCard;
