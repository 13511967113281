import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import List, { ListContent } from 'features/common-staff/layout/list';

import TimelineItem from './timelineItem';

import './timeline.scss';

const TimeLine = ({
  timeline, isLoading,
}) => (
  <div className="pedidos__modal__comentarios-list__container">
    { !isLoading ? (
      <List
        isEmpty={_.isEmpty(timeline)}
      >
        <ListContent>
          <div className="pedidos__modal__comentarios-list__container_content">
            {(Array.isArray(timeline) ? timeline : []).map((tl, i) => (
              <TimelineItem timeline={tl} key={i} index={i} />
            ))}
          </div>
        </ListContent>
        <ListContent type="empty">
          <div className="pedidos__modal__comentarios-list__message-container">
            Nenhuma etapa encontrada.
          </div>
        </ListContent>
      </List>
    ) : null}
  </div>
);

TimeLine.propTypes = {
  timeline: PropTypes.array,
  isLoading: PropTypes.bool,
};

TimeLine.defaultProps = {
  timeline: null,
  isLoading: false,
};

export default TimeLine;
