import React from 'react';
import PropTypes from 'prop-types';
import { Tracking } from 'modules';

import Select from '../../../../../../../common/select';

const defaultDoc = [
  { text: 'Todos os tipos', value: 'all' },
];

const DocSelect = ({
  doc, setDoc, docList, isLoading,
}) => (
  <Select
    items={defaultDoc.concat(docList)}
    label="Tipo Doc."
    value={doc || 'all'}
    onSelect={(r) => {
      Tracking.Mixpanel.carteira.carteiraFilters.trackTypeDocumentFilter(r);
      setDoc(r === 'all' ? null : r);
    }}
    disabled={isLoading}
  />
);

DocSelect.propTypes = {
  doc: PropTypes.string,
  setDoc: PropTypes.func,
  docList: PropTypes.array,
  isLoading: PropTypes.bool,
};

DocSelect.defaultProps = {
  doc: null,
  setDoc: () => {},
  docList: null,
  isLoading: false,
};

export default DocSelect;
