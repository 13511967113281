import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import colors from 'assets/styles/colors';

import './aVistaCondition.scss';

const avistaConditionRow = {
  rootSelected: {
    gridArea: 'A',
    width: '100%',
    height: '56px',
    borderRadius: '6px',
    border: `2px solid ${colors.primary_color_600}`,
    backgroundColor: colors.primary_color_100_24,
  },
  rootUnselected: {
    gridArea: 'A',
    width: '100%',
    height: '56px',
    borderRadius: '6px',
    border: `1px solid ${colors.secundary_color_100}`,
    backgroundColor: '#FFF',
  },
  title: {
    display: 'inline-block',
    fontSize: '24px',
    fontWeight: '900',
    lineHeight: '1.5',
    letterSpacing: '0.15px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.6)',
    margin: 'auto 0 auto 32px',
  },
};

const avistaConditionGrid = {
  rootSelected: {
    height: '334px',
    borderRadius: '6px',
    border: `2px solid ${colors.primary_color_600}`,
    backgroundColor: colors.primary_color_100_24,
    boxShadow: 'none',
  },
  rootUnselected: {
    height: '334px',
    borderRadius: '6px',
    border: `1px solid ${colors.secundary_color_100}`,
    backgroundColor: '#FFF',
    boxShadow: 'none',
  },
  title: {
    display: 'inline-block',
    fontSize: '24px',
    fontWeight: '900',
    lineHeight: '1.5',
    width: 'calc(100% - 16px)',
    letterSpacing: '0.15px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.6)',
    height: '38px',
    padding: '8px',
  },
};

const AVistaConditionCard = ({
  title, isSelected, isRow, onClick,
}) => {
  const useStyles = makeStyles(isRow ? avistaConditionRow : avistaConditionGrid);
  const classes = useStyles();
  const legalTextConditionCard = '* Verifique o percentual de desconto à vista junto a montadora';

  return (
    <Card
      variant="outlined"
      className={isSelected ? classes.rootSelected : classes.rootUnselected}
    >
      <div
        className={isRow ? 'td-avista-row' : 'td-avista-grid'}
        role="button"
        tabIndex="0"
        onClick={() => onClick()}
      >

        <div className={isRow ? 'td-avista-row-content' : 'td-avista-grid-content'}>
          <Typography className={classes.title}>
            {title}
          </Typography>
        </div>
        <div className={isRow ? 'td-avista-row-span' : 'td-avista-grid-span'}>
          <span className="td-cond-selector-card-content-offer-description-note">{legalTextConditionCard}</span>
        </div>

      </div>
    </Card>
  );
};

AVistaConditionCard.propTypes = {
  title: PropTypes.string,
  isSelected: PropTypes.bool,
  isRow: PropTypes.bool,
  onClick: PropTypes.func,
};

AVistaConditionCard.defaultProps = {
  title: '',
  isSelected: false,
  isRow: false,
  onClick: () => { },
};

export default AVistaConditionCard;
