import _ from 'lodash';
import { createSelector } from 'reselect';

const listMovimentacoes = createSelector(
  (state) => state?.filters?.order,
  (state) => state?.movimentacoes,
  (order, movimentacoes) => (_.isEqual(order, 'DESC')
    ? (movimentacoes || []).sort((A, B) => (B.data - A.data))
    : (movimentacoes || []).sort((A, B) => A.data - B.data)),
);

export default {
  listMovimentacoes,
};
