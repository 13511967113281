import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background-color: #f7f9fc;
`;

export const Content = styled.div`
  padding-top: 14px;
`;

export const MenuPadding = styled.div`
  padding: 4px 8px;
`;
