import React from 'react';
import PropTypes from 'prop-types';
import { CustomCheckbox, CustomFormLabel, CustomRadio } from './customFormOption.style';

const CustomFormOption = ({
  type, value, label, setValue, disabled, dataCy,
}) => (
  <CustomFormLabel
    control={type === 'radio' ? <CustomRadio /> : <CustomCheckbox />}
    value={value}
    label={label}
    disabled={disabled}
    checked={value}
    onChange={(e) => setValue(e.target.checked)}
    data-cy={dataCy}
  />
);

CustomFormOption.propTypes = {
  type: PropTypes.oneOf(['radio', 'checkbox']).isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.any,
  setValue: PropTypes.func,
  dataCy: PropTypes.string,
};

CustomFormOption.defaultProps = {
  disabled: false,
  label: '',
  value: null,
  setValue: () => {},
  dataCy: null,
};

export default CustomFormOption;
