import React from 'react';
import PropTypes from 'prop-types';
import { OpenInNew, CheckCircleRounded, ErrorRounded } from '@material-ui/icons';
import { formatCurrency } from 'utils/format';
import List, { ListContent } from '../../../../../../../../common-staff/layout/list';
import '../../comprovantePagamento.scss';
import ButtonIcon from '../../../../../../../../common-staff/controls/buttonIcon';
import { Tracking } from '../../../../../../../../../modules';

const styleForIcoNotApproved = {
  fontSize: '16px',
  color: 'rgb(237, 92, 111)',
  marginRight: '4px',
  marginLeft: '8px',
  position: 'absolute',
  transform: 'translate(0%, -50%)',
  top: '50%',
};

const styleForIconApproved = {
  fontSize: '16px',
  color: '#555770',
  marginRight: '4px',
  marginLeft: '8px',
  position: 'absolute',
  transform: 'translate(0%, -50%)',
  top: '50%',
};

const styleForIconDuplicata = {
  fontSize: '14px',
  color: '#555770',
  marginRight: '4px',
  marginLeft: '4px',
  marginTop: '-2px',
};

const ListRow = ({
  // eslint-disable-next-line react/prop-types
  idTransacao, duplicataOrigem, duplicataDestino, valorBaixa, status, showDuplicata, navTo,
  // eslint-disable-next-line react/prop-types
  chassiOrigem, chassiDestino,
}) => (
  <div className="comprovante__modal__list_row">
    <div
      className="comprovante__modal__list_row-identificador"
      data-cy="ComprovanteModalListRowIdentificador"
    >
      {idTransacao.toString().toLowerCase()}
    </div>
    <div
      className="comprovante__modal__list_row-origem"
      data-cy="ComprovanteModalListRowOrigem"
    >
      <div>
        {`Dup. ${duplicataOrigem}`}
        <ButtonIcon
          onClick={async () => {
            Tracking.Mixpanel.movimentacoes.trackInvoiceOrigin();
            await showDuplicata(duplicataDestino);
            navTo();
          }}
        >
          <OpenInNew style={styleForIconDuplicata} />
        </ButtonIcon>
      </div>
      {chassiOrigem
        ? (
          <span className="comprovante__modal__list_chassi">
            {`Chassi ${chassiOrigem.toString().toUpperCase()}`}
          </span>
        ) : null}
    </div>
    <div
      className="comprovante__modal__list_row-destino"
      data-cy="ComprovanteModalListRowDestino"
    >
      <div>
        {`Dup. ${duplicataDestino}`}
        <ButtonIcon
          onClick={async () => {
            Tracking.Mixpanel.movimentacoes.trackInvoiceDestination();
            await showDuplicata(duplicataDestino);
            navTo();
          }}
        >
          <OpenInNew style={styleForIconDuplicata} />
        </ButtonIcon>
      </div>
      {chassiDestino
        ? (
          <span className="comprovante__modal__list_chassi">
            {`Chassi ${chassiDestino.toString().toUpperCase()}`}
          </span>
        ) : null}
    </div>
    <div
      className="comprovante__modal__list_row-valor"
      data-cy="ComprovanteModalListRowValor"
    >
      {formatCurrency(valorBaixa)}
      {status === 'PAGAMENTO_REPROVADO'
        ? (<ErrorRounded style={styleForIcoNotApproved} />)
        : (<CheckCircleRounded style={styleForIconApproved} />) }
    </div>
  </div>
);

const PagamentosList = ({ listaPagamentos, showDuplicata, navTo }) => (
  <>
    <div className="comprovante__modal__list_header">
      <div
        className="comprovante__modal__list_header-identificador"
        data-cy="ComprovanteModalListHeaderIdentificador"
      >
        Identificador
      </div>
      <div
        className="comprovante__modal__list_header-origem"
        data-cy="ComprovanteModalListHeaderOrigem"
      >
        Doc. Origem
      </div>
      <div
        className="comprovante__modal__list_header-destino"
        data-cy="ComprovanteModalListHeaderDestino"
      >
        Doc. Destino
      </div>
      <div
        className="comprovante__modal__list_header-valor"
        data-cy="ComprovanteModalListHeaderValor"
      >
        Valor Pgto.
      </div>
    </div>
    <div className="comprovante__modal__list_content">
      <List>
        <ListContent>
          {(Array.isArray(listaPagamentos) ? listaPagamentos : []).map((p, i) => (
            <ListRow
              idTransacao={p.idTransacao}
              duplicataOrigem={p.duplicataOrigem}
              duplicataDestino={p.duplicataDestino}
              chassiDestino={p.chassiDestino?.toUpperCase()}
              chassiOrigem={p.chassiOrigem?.toUpperCase()}
              valorBaixa={p.valorBaixa}
              status={p.status}
              key={i}
              showDuplicata={showDuplicata}
              navTo={navTo}
            />
          ))}
        </ListContent>
      </List>
    </div>

  </>
);

PagamentosList.propTypes = {
  listaPagamentos: PropTypes.array,
  showDuplicata: PropTypes.func,
  navTo: PropTypes.func,
};

PagamentosList.defaultProps = {
  listaPagamentos: [],
  showDuplicata: () => null,
  navTo: () => {},
};

export default PagamentosList;
