import * as React from 'react';
import PropTypes from 'prop-types';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import './detailsTimeLine.scss';
import moment from 'moment';

const DetailsTimeLine = ({ items }) => {
  const copiaItems = [...items];
  const lastItem = copiaItems.pop();

  const timelineDot = {
    backgroundColor: '#243782',
    margin: '0px',
  };

  const timelineConnector = {
    backgroundColor: '#243782',
  };

  const timelineItem = {
    minHeight: '0px',
    padding: '0px',
    margin: '0px',
  };

  const timelineContainer = {
    padding: '0px 16px 0px 16px',
  };

  const timelineLastContainer = {
    padding: '0px 16px 15px 16px',
  };

  const formatDate = (dataHora) => (
    moment(dataHora).format('DD-MM-YYYY')
  );

  const formatHour = (dataHora) => {
    const dataFormatada = moment(dataHora);
    const hora = dataFormatada.format('HH:mm');

    return hora;
  };

  return (
    <div className="details__container">
      <div className="timeline-container">
        {copiaItems.map((item, key) => (
          <Timeline className="timeline__details-open" sx={timelineContainer} key={key}>
            <TimelineItem sx={timelineItem}>
              <TimelineSeparator>
                <TimelineDot sx={timelineDot} />
                <TimelineConnector sx={timelineConnector} />
              </TimelineSeparator>
              <TimelineContent>
                <div className="timeline__summary-content">
                  <div className="timeline__summary-content-title">
                    {`${formatDate(item?.dataHora)} | ${formatHour(item?.dataHora)}`}
                  </div>
                  <div className="timeline__summary-content-title">
                    {item.descricao}
                  </div>
                </div>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        ))}
        <Timeline className="timeline__details-open" sx={timelineLastContainer}>
          <TimelineItem sx={timelineItem}>
            <TimelineSeparator>
              <TimelineDot sx={timelineDot} />
            </TimelineSeparator>
            <TimelineContent>
              <div className="timeline__summary-content">
                <div className="timeline__summary-content-bold-title">
                  {`${formatDate(lastItem?.dataHora)} | ${formatHour(lastItem?.dataHora)}`}
                </div>
                <div className="timeline__summary-content-bold-subtitle">
                  {lastItem?.descricao}
                </div>
              </div>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </div>
    </div>
  );
};

DetailsTimeLine.propTypes = {
  items: PropTypes.array,
};

DetailsTimeLine.defaultProps = {
  items: [],
};

export default DetailsTimeLine;
