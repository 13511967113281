import React, {
  createContext, useContext, useEffect, useReducer, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import useCustomFetch from 'hooks/useFetch';
import { applyPropertyArray } from 'utils/object';
import resgateService from '../services/resgateService';

const initialState = () => ({
  jurosCarencia: null,

  requestStatus: {
    loading: false,
    success: false,
    error: null,
  },
});

const reducer = (state, action) => {
  switch (action.type) {
    case 'response':
      return {
        ...state,
        requestStatus: {
          ...action.requestStatus,
        },
        jurosCarencia: _.isEmpty(action.data) || _.isNull(action.data) ? null : action.data,
      };
    case 'resgate':
      if (_.isNull(state.jurosCarencia)) return { ...state };
      return {
        ...state,
        jurosCarencia: applyPropertyArray(
          { ...state.jurosCarencia },
          action.propertyNames,
          action.values,
        ),
      };
    case 'add_snackbar':
      return {
        ...state,
        snackbars: [...state.snackbars, {
          id: state.lastSnackbarId,
          message: action.message,
          type: action.status,
        }],
        lastSnackbarId: state.lastSnackbarId + 1,
      };
    case 'close_snackbar':
      return {
        ...state,
        snackbars: state.snackbars.filter((s) => s.id !== action.value),
      };
    default: return state;
  }
};

const ResgateContext = createContext(null);

export const ResgateContextProvider = ({
  children, dealer, addSnackbar, closeSnackbar,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState());

  const useGetResgate = useCallback(
    async () => resgateService.getAceiteConcessionaria(),
    [],
  );

  const [response, getResgate] = useCustomFetch(useGetResgate, [dealer]);
  useEffect(() => {
    const { data, ...requestStatus } = response;
    dispatch({ type: 'response', data, requestStatus });
  }, [response]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const resgateContextStore = {
    state,
    dispatch,
    getResgate,
    addSnackbar,
    closeSnackbar,
    setResgate: (propertyNames, values) => dispatch({ type: 'resgate', propertyNames, values }),
  };

  return (
    <ResgateContext.Provider value={resgateContextStore}>
      {children}
    </ResgateContext.Provider>
  );
};

ResgateContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  dealer: PropTypes.object.isRequired,
  addSnackbar: PropTypes.func.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
};

ResgateContextProvider.defaultProps = {
  children: null,
};

export const useResgateContext = () => useContext(ResgateContext);
