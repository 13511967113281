import { connect } from 'react-redux';

import operations from '../../redux/operations';

import MovimentacoesPaginationFooter from './movimentacoesPaginationFooter';

const mapStateToProps = ({ statementStore }) => ({
  page: statementStore.pageParams.page,
  ipp: statementStore.pageParams.ipp,
  totalItems: statementStore.pageParams.itemsTotais,
  isLoading: statementStore.requestStatus.isLoading,
  isOpenModal: statementStore.modal.isOpen,
});
const mapDispatchToProps = (dispatch) => ({
  setPage: (page) => dispatch(operations.setPageParams('page', page)),
  setIpp: (ipp) => dispatch(operations.setPageParams('ipp', ipp)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MovimentacoesPaginationFooter);
