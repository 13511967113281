import * as React from "react";

function PersonSearchIcon(props) {
	return (
		<svg
			width="1em"
			height="1em"
			viewBox="0 0 20 20"
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.667 6.667a3.333 3.333 0 11-6.667 0 3.333 3.333 0 016.667 0zm-3.042 5.008C6.35 11.592 1.667 12.725 1.667 15v.833c0 .459.375.834.833.834h7.117c-2.059-2.3-1.025-4.909-.992-4.992zm7.567 3.342a3.257 3.257 0 00.4-2.35c-.284-1.367-1.434-2.459-2.817-2.634a3.331 3.331 0 00-3.75 3.75c.183 1.384 1.267 2.534 2.633 2.817a3.256 3.256 0 002.35-.4l1.55 1.55a.83.83 0 101.175-1.175l-1.541-1.558zM13.333 15c-.916 0-1.666-.75-1.666-1.667 0-.916.75-1.666 1.666-1.666.917 0 1.667.75 1.667 1.666C15 14.25 14.25 15 13.333 15z"

			/>
		</svg>
	);
}

export default PersonSearchIcon;

