import React from 'react';
import { Provider } from 'react-redux';

import Typography from 'assets/styles/typography';

import { Tracking } from 'modules';
import { RenderIfLoggedIn } from 'modules/auth/guards';
import { Snackbar } from 'modules/snackbar';

import { StyledEngineProvider } from '@mui/material';
import { setupAxios } from './setup/axios/axios';
import { setupHotjar } from './setup/hotjar';
import { StylesProviderV4, ThemeProviderV4, ThemeProviderV5 } from './setup/themes';
import store from './setup/store';

import SessionExpiredModal from './features/auth/sessionExpiredModal';
import DialogButton from './features/userFeedback/views';
import Routes from './routes';

import './i18n';
import './App.scss';

const App = () => {
  setupAxios();
  setupHotjar();
  Tracking.Mixpanel.init();
  return (
    <div className="App">
      <StyledEngineProvider injectFirst>
        <Provider store={store}>
          <StylesProviderV4>
            <ThemeProviderV4>
              <ThemeProviderV5>
                <Routes />
                <Typography />
                <RenderIfLoggedIn>
                  <SessionExpiredModal />
                  <DialogButton />
                </RenderIfLoggedIn>
                <Snackbar />
              </ThemeProviderV5>
            </ThemeProviderV4>
          </StylesProviderV4>
        </Provider>
      </StyledEngineProvider>
    </div>
  );
};

export default App;
