import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

const VALID_FIRST = /^[1-9]{1}$/;
const VALID_NEXT = /^[0-9]{1}$/;
const DELETE_KEY_CODE = 8;

export const getNextValue = (valueString, key, keyCode, selectionStartArg, selectionEndArg) => {
  const selectionStart = (selectionStartArg === undefined) ? valueString.length : selectionStartArg;
  const selectionEnd = (selectionEndArg === undefined) ? selectionStart : selectionEndArg;
  let nextValueString;

  if (keyCode === DELETE_KEY_CODE) {
    if (selectionStart !== selectionEnd) {
      nextValueString = valueString.slice(0, selectionStart)
        + valueString.slice(selectionEnd, valueString.length);
    } else {
      nextValueString = valueString.slice(0, -1);
    }
  } else {
    const value = Number.parseInt(valueString.replace(/\D/g, ''), 10);
    nextValueString = value === 0 ? key : `${valueString}${key}`;
  }

  nextValueString = nextValueString.replace(/\D/g, '');
  return nextValueString === '' ? 0 : Number.parseInt(nextValueString, 10);
};

const CurrencyInput = ({
  className, max, onValueChange, value, onBlur,
}) => (
  // Using a wrapper to support the *100 translation
  <CurrencyInputRaw
    className={className}
    max={max * 100}
    value={value * 100}
    onValueChange={(v) => onValueChange(v / 100)}
    onBlur={onBlur}
  />
);

const CurrencyInputRaw = ({
  // eslint-disable-next-line react/prop-types
  className, max, onValueChange, value, onBlur,
}) => {
  if (!Number.isFinite(value) || Number.isNaN(value)) {
    throw new Error('invalid value property');
  }

  const handleKeyDown = useCallback(
    (e) => {
      const { key, keyCode } = e;

      if (
        (value === 0 && !VALID_FIRST.test(key))
        || (value !== 0 && !VALID_NEXT.test(key) && keyCode !== DELETE_KEY_CODE)
      ) {
        return;
      }

      const { selectionStart, selectionEnd } = e.target;
      const nextValue = getNextValue(e.target.value, key, keyCode, selectionStart, selectionEnd);
      if (nextValue > max) { return; }

      onValueChange(nextValue);
    },
    [max, onValueChange, value],
  );

  const handleChange = useCallback(() => { /* DUMMY TO AVOID REACT WARNING */ }, []);
  const valueDisplay = (value / 100).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  return (
    <input
      type="text"
      // inputMode="numeric"
      className={className}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onBlur={onBlur}
      value={valueDisplay}
    />
  );
};

CurrencyInput.propTypes = {
  className: PropTypes.string,
  max: PropTypes.number,
  onValueChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.number,
};

CurrencyInput.defaultProps = {
  className: '',
  max: Math.max,
  onValueChange: () => {},
  onBlur: () => {},
  value: 0,
};

export default CurrencyInput;
