import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Tracking } from 'modules';
import { PageTitle, Button, PageErrorBoundary } from 'features/common';
import BackButton from 'features/common-staff/controls/backButton';

import TestDriveProductCards from './views/testDriveProductCards';

import TestDriveForm from './views/testDriveForm';
import TestDriveOfferSelector from './views/testDriveOfferSelector';
import TestDriveConditionsSelector from './views/testDriveConditionsSelector';

import './solicitacaoPage.scss';

// eslint-disable-next-line react/prop-types
const SectionHeader = ({ number, label }) => (
  <div className="test-drive-form-page-pag-index">
    <span className="test-drive-form-page-pag-index-number test-drive-form-page-pag-index-number-container">{number}</span>
    <span className="test-drive-form-page-pag-index-text">{label}</span>
  </div>
);

class SolicitacaoPage extends React.Component {
  componentWillUnmount() {
    const { clearForm } = this.props;
    clearForm();
  }

  handleSelectCondition = (id) => {
    const { setSelectedCondition } = this.props;

    setSelectedCondition(id);

    Tracking.Mixpanel.testDrive.trackConditionSelected();
  };

  handleSubmit = () => {
    const {
      user, dealers, conditions, selectedConditionId, selectedDealerId,
      offers, selectedOfferId,
      industrialOrder, chassis, color, observation,
      simulationBaseValue, submitTestDriveForm, clearForm, navToAcompanhamento,
      finalProduct, selectedMotivoId, selectedFiles,
    } = this.props;

    const selectedDealer = dealers.find((d) => (d.dealerId === selectedDealerId)) || {};
    const selectedOffer = (offers || []).find((o) => (o.id === selectedOfferId));
    const selectedCondition = (conditions || []).find((c) => (c.id === selectedConditionId));
    const files = (selectedFiles || []).map((f) => ({
      fileName: f.name,
      urlPreview: f.urlPreview,
      key: f.key,
    }));

    const data = {
      descontoVeiculoId: selectedOfferId,
      condicaoVeiculoId: selectedConditionId,
      usuarioNome: (user || {}).name,
      usuarioEmail: (user || {}).email,
      concessionariaCodigo: (selectedDealer || {}).dealerId,
      concessionariaNome: (selectedDealer || {}).dealerName,
      concessionariaCnpj: (selectedDealer || {}).cnpj,
      pedidoIndustrial: industrialOrder,
      chassi: chassis,
      cor: color,
      observacao: observation,
      regiaoCodigo: selectedDealer.regionId,
      produtoId: finalProduct.id,
      selectedMotivoId: selectedMotivoId ?? null,
      files,
      marca: (selectedDealer || {}).brand,
    };
    submitTestDriveForm(data, () => {
      clearForm();

      Tracking.Mixpanel.testDrive.trackSubmit({
        offerId: selectedOffer.id,
        offerMvs: `${selectedOffer.modelDescription} ${selectedOffer.versionDescription} ${selectedOffer.serieDescription}`,
        conditionId: selectedCondition.id,
        conditionName: `${selectedCondition.name}`,
        didFillSimulationValue: !!(simulationBaseValue),
        didFillIndustrialOrder: !!(industrialOrder),
        didFillChassis: !!(chassis),
        didFillColor: !!(color),
        didFillObservation: !!(observation),
      });

      navToAcompanhamento();
    }, () => { Tracking.Mixpanel.testDrive.trackSubmitError(); });
  };

  canSendForm = () => {
    const {
      selectedConditionId, finalProduct,
      selectedOfferId, isSubmitting, selectedDealerId, selectedMotivoId,
    } = this.props;
    return (
      selectedConditionId >= 0
      && selectedOfferId >= 0
      && selectedDealerId >= 0
      && !isSubmitting
      && ((selectedMotivoId !== null && finalProduct.id === 4) || finalProduct.id !== 4)
    );
  };

  render() {
    const {
      className, navToProdutos, selectedCategory, selectedProduct,
      offers, selectedOfferId,
      dealers, selectedDealerId, setSelectedDealerId,
      motivos, selectedMotivoId,
    } = this.props;

    const selectedOffer = offers.find((offer) => offer.id === selectedOfferId);
    const selectedDealer = (dealers || []).find(
      (dealer) => (dealer.dealerId === selectedDealerId),
    );

    const selectedMotivo = (motivos || []).find((m) => m.id === selectedMotivoId);

    let dealersForOffer = [];
    if (selectedOffer && this.dealerOrganizer) {
      dealersForOffer = this.dealerOrganizer.getDealersForOfferBrand(selectedOffer.brand);
    }

    if (!selectedDealerId && dealersForOffer.length === 1) {
      setSelectedDealerId(dealersForOffer[0].dealerId);
    }

    const needsSelectProduct = selectedCategory.parent !== null;
    const productSelected = (!needsSelectProduct || _.isObject(selectedProduct));

    return (
      <PageErrorBoundary>
        <section className={`test-drive-form-page ${className}`}>

          <div className="test-drive-form-page-header">
            <div
              className="test-drive-form-page-header-back"
              data-cy="TestDriveFormPageHeaderBack"
            >
              <BackButton
                padding="0 8px 0 0"
                onClick={() => {
                  Tracking.Mixpanel.testDrive.trackPrevious();
                  navToProdutos();
                }}
              >
                Voltar
              </BackButton>
            </div>
            <div
              className="test-drive-form-page-header-title"
              data-cy="TestDriveFormPageHeaderTitle"
            >
              <PageTitle title={`Ofertas de ${selectedCategory?.titulo}`} />
            </div>
          </div>

          {needsSelectProduct ? (
            <div className="test-drive-form-page-content">
              <SectionHeader
                number="1"
                label="Selecione um produto"
              />
              <div
                className="td-page-offer-card-container"
                data-cy="TdPageOfferCardProductCards"
              >
                <TestDriveProductCards />
              </div>
            </div>
          ) : null}

          {(needsSelectProduct && productSelected) || !needsSelectProduct ? (
            <div className="test-drive-form-page-content">
              <SectionHeader
                number={needsSelectProduct ? '2' : '1'}
                label="Selecione uma oferta"
              />
              <div className="td-page-offer-card-container">
                <TestDriveOfferSelector />
              </div>
            </div>
          ) : null}

          {(selectedOffer) ? (
            <div className="test-drive-form-page-content">
              <SectionHeader
                number={needsSelectProduct ? '3' : '2'}
                label="Informe alguns dados"
              />

              <TestDriveForm
                dealers={dealersForOffer}
                selectedDealer={selectedDealer}
                selectedMotivo={selectedMotivo}
              />
            </div>
          ) : null}

          {(selectedOffer && selectedDealerId) ? (
            <div className="test-drive-form-page-submit">
              <SectionHeader
                number={needsSelectProduct ? '4' : '3'}
                label="Selecione uma condição"
              />

              <TestDriveConditionsSelector
                onChange={this.handleSelectCondition}
              />

              <Button
                type="button"
                onClick={this.handleSubmit}
                disabled={!this.canSendForm()}
                className="test-drive-form-page-button"
              >
                Enviar
              </Button>
            </div>
          ) : null}
        </section>

      </PageErrorBoundary>

    );
  }
}

SolicitacaoPage.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired,
  dealers: PropTypes.array.isRequired,

  offers: PropTypes.array.isRequired,
  selectedOfferId: PropTypes.number,

  motivos: PropTypes.array.isRequired,
  selectedMotivoId: PropTypes.number,

  conditions: PropTypes.array.isRequired,
  selectedConditionId: PropTypes.number,
  setSelectedCondition: PropTypes.func.isRequired,

  selectedFiles: PropTypes.array.isRequired,

  setSelectedDealerId: PropTypes.func.isRequired,
  selectedDealerId: PropTypes.number,
  industrialOrder: PropTypes.string,
  chassis: PropTypes.string,
  color: PropTypes.string,
  observation: PropTypes.string,

  submitTestDriveForm: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,

  clearForm: PropTypes.func.isRequired,
  selectedCategory: PropTypes.object,
  selectedProduct: PropTypes.object,
  finalProduct: PropTypes.object,
  navToProdutos: PropTypes.func.isRequired,
  navToAcompanhamento: PropTypes.func.isRequired,

  simulationBaseValue: PropTypes.number,
};

SolicitacaoPage.defaultProps = {
  className: '',
  selectedOfferId: null,
  selectedConditionId: null,

  selectedMotivoId: null,

  selectedDealerId: null,
  industrialOrder: null,
  chassis: null,
  color: null,
  observation: null,

  simulationBaseValue: null,

  selectedCategory: null,
  selectedProduct: null,
  finalProduct: null,
};

export default SolicitacaoPage;
