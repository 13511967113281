import React from 'react';
import PropTypes from 'prop-types';
import { Tracking } from 'modules';
import NewBasicSelect from 'features/common/newBasicSelect/newBasicSelect';

const defaultRelatorios = [
  { value: 1, label: 'Demonstrativo de cálculo de IOC - diários' },
  { value: 6, label: 'Demonstrativo de cálculo de IOC - mensal' },
  { value: 2, label: 'Relatório de títulos vencidos - diário' },
  { value: 3, label: 'Relatório de títulos liquidados - mensal' },
  { value: 4, label: 'Relatório de títulos em aberto - mensal' },
  { value: 5, label: 'Arquivos de duplicatas - diário' },
];

const RelatorioSelect = ({
  relatorio,
  setRelatorio,
  isLoading,
}) => {
  const handleSetRelatorio = (r) => {
    Tracking.Mixpanel.relatoriosIof.filtros.trackFiltroTipoRelatorio(r);
    setRelatorio(r);
  };

  return (
    <NewBasicSelect
      selectedOption={relatorio}
      setOption={handleSetRelatorio}
      options={defaultRelatorios}
      renderAllOptions={false}
      placeholder="Selecione o tipo do arquivo"
      nameLabel="Tipo de arquivo"
      isLoading={isLoading}
      selectFirstOptionDefault={false}
    />
  );
};

RelatorioSelect.propTypes = {
  relatorio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setRelatorio: PropTypes.func,
  isLoading: PropTypes.bool,
};

RelatorioSelect.defaultProps = {
  relatorio: null,
  setRelatorio: () => {},
  isLoading: false,
};

export default RelatorioSelect;
