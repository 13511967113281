import React from 'react';
import PropTypes from 'prop-types';
import { Receipt } from '@material-ui/icons';

import { makeStyles } from '@material-ui/core';

import colors from 'assets/styles/colors';
import ButtonIcon from '../../../../../../common-staff/controls/buttonIcon';

const useStyle = makeStyles({
  button: {
    backgroundColor: 'inherit',
    color: colors.primary_color_600,
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
  },

  icon: {
    marginRight: '4px',
    fill: colors.primary_color_600,
    fontSize: 'inherit',
    size: '18px',
  },
});

const ButtonComprovante = ({ onComprovanteClicked }) => {
  const classes = useStyle();
  return (
    <ButtonIcon
      onClick={() => onComprovanteClicked()}
      className={classes.button}
    >
      <Receipt className={classes.icon} size="18px" />
      Comprovante da transação
    </ButtonIcon>
  );
};

ButtonComprovante.propTypes = {
  onComprovanteClicked: PropTypes.func,
};

ButtonComprovante.defaultProps = {
  onComprovanteClicked: () => {},
};

export default ButtonComprovante;
