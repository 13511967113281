import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  position: relative;
  margin:0 24px 0 24px;
`;

export const Header = styled.div`
  padding-top: 24px;
  padding-bottom: 48px;
  font-size: 14px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const TipoDuplicata = styled.span`
  color: #555770;
  font-weight: bold;
`;

export const CardIcon = styled.div`
  height: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
`;

export const DuplicataIcon = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.background};
  color: #404154;
`;
