class ChatEngine {
  constructor(messageCreators, transitions, handleAnswer) {
    this.messageCreators = messageCreators || [];
    this.transitions = transitions || [];
    this.handleAnswer = handleAnswer || (() => {});
  }

  getNextChatState(currentChatState, answer, previousAnswers) {
    const transition = this.transitions.find((t) => t.from === currentChatState);

    if (transition && transition.transitionFunction) {
      const nextState = transition.transitionFunction(answer, previousAnswers);
      if (nextState === null || nextState === undefined) {
        throw new Error(`Invalid transition from ${currentChatState} to ${nextState}`);
      }

      return nextState;
    }

    throw new Error(`No transition from state ${currentChatState}`);
  }

  getAnsweredMessages(chatState, previousAnswers) {
    const msgCreator = this.getMessageCreator(chatState);
    const isAnswered = true;

    if (msgCreator && msgCreator.createMessages) {
      return msgCreator.createMessages(isAnswered, previousAnswers);
    }

    return [];
  }

  getActiveMessages(chatState, previousAnswers) {
    const msgCreator = this.getMessageCreator(chatState);
    const isAnswered = false;

    if (msgCreator && msgCreator.createMessages) {
      return msgCreator.createMessages(isAnswered, previousAnswers, this.handleAnswer);
    }

    return [];
  }

  getMessageCreator(chatState) {
    return this.messageCreators.find((m) => m.chatState === chatState);
  }
}

export { ChatEngine };
