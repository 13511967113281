import { createSelector } from 'reselect';

const getTotalValue = createSelector(
  (state) => state.pagamento.docs,
  (invoices) => invoices.reduce((a, b) => a + b.toPay, 0),
);

const getTotalInvoices = createSelector(
  (state) => state.pagamento.docs,
  (invoices) => invoices.length,
);

export default {
  getTotalValue,
  getTotalInvoices,
};
