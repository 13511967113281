import { connect } from 'react-redux';

import * as operations from './redux/operations';
import TransferenciaLimitePage from './transferenciaLimitePage';

const mapStateToProps = ({ limiteCreditoStore }) => ({
  isOpen: limiteCreditoStore.transferenciaLimite.isOpen,
  snackbarErrors: limiteCreditoStore.transferenciaLimite.snackbarErrors,
});

const mapDispatchToProps = (dispatch) => ({
  onSnackbarClose: (id) => dispatch(operations.dismissSnackbar(id)),
  getProdutos: () => dispatch(operations.getProdutos()),
  resetStore: () => { dispatch(operations.resetStore()); },
});

export default connect(mapStateToProps, mapDispatchToProps)(TransferenciaLimitePage);
