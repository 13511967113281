import React from 'react';

import BreadCrumb from 'features/common-staff/layout/breadCrumb';

const BreadCrumbDuplicata = () => (
  <BreadCrumb
    labels={[{
      label: 'FIDC',
      path: ' ',
    },
    {
      label: 'Duplicatas',
      path: '/duplicatas',
    }]}
    format={false}
  />
);

export default BreadCrumbDuplicata;
