import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox as MuiCheckbox } from '@mui/material';
import colors from 'assets/styles/colors';

const Checkbox = ({
  onChange, checked, size, disabled, padding, color, checkedColor, width, height,
}) => (
  <MuiCheckbox
    onChange={onChange}
    checked={checked}
    size={size}
    disabled={disabled}
    sx={{
      color,
      borderRadius: 0,
      width: { width },
      height: { height },
      padding,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&.Mui-checked': {
        color: checkedColor,
      },
    }}
  />
);

Checkbox.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  padding: PropTypes.string,
  color: PropTypes.string,
  checkedColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

Checkbox.defaultProps = {
  onChange: () => {},
  checked: false,
  size: 'small',
  disabled: false,
  padding: '0px',
  color: colors.secundary_color_600,
  checkedColor: colors.primary_color_700,
  width: 16,
  height: 16,
};

export default Checkbox;
