import { connect } from 'react-redux';
import IofSimulatorMenu from './iofSimulatorMenu';

import { IofSimulatorActions } from '../../redux';

const mapStateToProps = ({ iofSimulatorStore }) => ({
  transmissionValue: iofSimulatorStore.transmissionValue,
  paymentValue: iofSimulatorStore.paymentValue,
  selectedValue: iofSimulatorStore.selectedValue,
  lastUpdate: iofSimulatorStore.lastUpdate,
  movementAccountPaymentsValue: iofSimulatorStore.movementAccountPaymentsValue,
  dealerGroup: iofSimulatorStore.dealerGroup,
});

const mapDispatchToProps = (dispatch) => ({
  setMovementAccountPaymentsValue: (value) => dispatch(
    IofSimulatorActions.setMovementAccountPaymentsValue(value),
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IofSimulatorMenu);
