import { connect } from 'react-redux';

import Table from './table';
import selectors from '../../../redux/selectors';

const mapStateToProps = ({ dashboard }) => ({
  data: selectors.selectDuplicatasValorTable(dashboard),
});

export default connect(mapStateToProps, null)(Table);
