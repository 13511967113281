import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FileButton from 'features/common/fileButton/fileButton';

const UploadDocumentoJuridico = ({
  uploadDocumento,
  downloadDocumento,
  deleteDocumento,
  documento,
  statusLimite,
  errorStatusList,
  label,
  maxFileSize,
  fileExtensions,
  showDeleteButton,
  validateAllTypes,
}) => {
  const [componentState, setComponentState] = useState({
    error: '',
    isLoading: false,
  });
  const [arquivoCarregado, setArquivoCarregado] = useState(null);

  const validatefile = (file) => {
    const validTypes = validateAllTypes ? [
      'image/',
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'text/csv',
    ] : [
      'image/',
      'application/pdf',
    ];
    if (file.size > maxFileSize) {
      setComponentState({ ...componentState, error: 'Erro ao inserir arquivo. O tamanho excede o limite 5mb' });
      return false;
    }
    if (!validTypes.some((type) => file.type.includes(type))) {
      setComponentState({
        ...componentState,
        error: validateAllTypes
          ? 'Erro ao inserir arquivo. O arquivo deve se tratar de uma imagem, pdf, word ou excel.'
          : 'Erro ao inserir arquivo. O arquivo deve se tratar de uma imagem ou pdf',
      });
      return false;
    }
    return true;
  };

  const upload = async (file) => {
    if (validatefile(file)) {
      setComponentState({ ...componentState, isLoading: true });
      if (await uploadDocumento(file)) setArquivoCarregado(file);
      setComponentState({ ...componentState, isLoading: false });
    }
  };

  const download = async () => {
    if (arquivoCarregado) {
      const blobUrl = URL.createObjectURL(arquivoCarregado);
      window.open(blobUrl, '_blank');
    } else {
      setComponentState({ ...componentState, isLoading: true });
      await downloadDocumento();
      setComponentState({ ...componentState, isLoading: false });
    }
  };

  const deleteFile = async () => {
    setComponentState({ ...componentState, isLoading: true });
    await deleteDocumento();
    setArquivoCarregado(null);
    setComponentState({ ...componentState, isLoading: false });
  };

  return (
    <FileButton
      downloadFile={download}
      uploadFile={upload}
      deleteFile={deleteFile}
      erro={
        Boolean(componentState.error)
        || (!documento?.nome && errorStatusList.includes(statusLimite))
      }
      nome={documento?.nome}
      tamanho={documento?.tamanho}
      isLoading={componentState.isLoading}
      textoErro={componentState.error}
      label={label}
      accept={fileExtensions}
      isDocumentoCarregado={Boolean(arquivoCarregado)}
      showDeleteButton={showDeleteButton}
    />
  );
};

UploadDocumentoJuridico.propTypes = {
  uploadDocumento: PropTypes.func,
  downloadDocumento: PropTypes.func,
  deleteDocumento: PropTypes.func,
  documento: PropTypes.shape({
    nome: PropTypes.string,
    tamanho: PropTypes.string,
  }),
  statusLimite: PropTypes.string.isRequired,
  errorStatusList: PropTypes.array,
  label: PropTypes.string,
  maxFileSize: PropTypes.number,
  fileExtensions: PropTypes.string,
  showDeleteButton: PropTypes.bool,
  validateAllTypes: PropTypes.bool,
};

UploadDocumentoJuridico.defaultProps = {
  uploadDocumento: () => {},
  downloadDocumento: () => {},
  deleteDocumento: () => {},
  documento: null,
  errorStatusList: [],
  label: 'Documento',
  maxFileSize: 5242880,
  fileExtensions: '.pdf,.png,.jpg,.jpeg',
  showDeleteButton: true,
  validateAllTypes: false,
};

export default UploadDocumentoJuridico;
