import { applyProperty } from 'utils/object';
import actions from './actions';

const INITIAL_STATE = {
  filters: {
    dealerID: null,
    texto: null,
    concessionaria: null,
    status: null,
    docs: null,
    produto: null,
    modelo: null,
    versao: null,
    vencimentoInicio: null,
    vencimentoFim: null,
    valor: [null, null],
    carencia: null,
    emissaoInicio: null,
    emissaoFim: null,
    periodo: 90,
  },
  isFilterSelected: false,

  selectors: {
    status: null,
    docs: null,
    produto: null,
    carencia: null,
  },

  requestStatus: {
    isLoading: false,
    isError: false,
    exporting: false,
    errors: [],
    isLoadingExport: false,
  },

  isRedirect: false,

  pageParams: {
    page: 0,
    ipp: 25,
    totalItens: 0,
  },

  selectAll: false,

  invoices: [],

  snackbars: [],
  lastSnackbarErrorId: 0,
  isUpdated: true,
  exportPopperOpen: false,
};

const reduceGetInvoices = (state, action) => {
  switch (action.type) {
    case actions.types.GET_INVOICES_ERROR:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          isLoading: false,
          isError: true,
        },
        filters: {
          ...state.filters,
        },
      };
    case actions.types.GET_INVOICES_START:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          isLoading: true,
          isError: false,
        },
      };
    case actions.types.GET_INVOICES_SUCCESS:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          isLoading: false,
          isError: false,
        },

        invoices: action.payload.invoices,
        pageParams: action.payload.pageParams,
        isFilterSelected: false,
        selectors: {
          status: state.selectors.status ?? action.payload.filtersParams.status,
          docs: state.selectors.docs ?? action.payload.filtersParams.docs,
          produto: state.selectors.produto ?? action.payload.filtersParams.produto,
        },
        isUpdated: action.payload.isUpdated,
      };
    default:
      return state;
  }
};

export default (state = INITIAL_STATE, action = { type: '@@carteiraPage/INIT' }) => {
  if (action.type.startsWith(actions.types.PREFIX_GET_INVOICES)) {
    return reduceGetInvoices(state, action);
  }

  switch (action.type) {
    case actions.types.SET_FILTERS:
      return {
        ...state,
        filters: applyProperty(
          state.filters,
          action.payload.propertyName,
          action.payload.value,
        ),
        isFilterSelected: true,
      };
    case actions.types.SET_PAGE_PARAMS:
      return {
        ...state,
        pageParams: applyProperty(
          state.pageParams,
          action.payload.propertyName,
          action.payload.value,
        ),
      };
    case actions.types.SELECT_TO_PAYMENT:
      return {
        ...state,
        invoices: state.invoices.map((i) => {
          if (i.duplicataId === action.payload.id && !i?.selected) {
            return {
              ...i,
              selected: true,
            };
          }
          if (i.duplicataId === action.payload.id && i?.selected) {
            return {
              ...i,
              selected: false,
            };
          }
          return i;
        }),
        selectAll: state.selectAll ? !state.selectAll : state.selectAll,
      };
    case actions.types.SELECT_ALL:
      return {
        ...state,
        invoices: state.invoices.map((i) => {
          if (state.selectAll && i.duplicataStatusDescricao.toLowerCase().trim() !== 'quitada') {
            return {
              ...i,
              selected: false,
            };
          }
          if (!state.selectAll && i.duplicataStatusDescricao.toLowerCase().trim() !== 'quitada') {
            return {
              ...i,
              selected: true,
            };
          }
          return i;
        }),
        selectAll: !state.selectAll,
      };
    case actions.types.REMOVE_ALL:
      return {
        ...state,
        invoices: state.invoices.map((i) => {
          if (i.duplicataStatusDescricao.toLowerCase().trim() !== 'quitada') {
            return {
              ...i,
              selected: false,
            };
          }

          return i;
        }),
      };
    case actions.types.SET_SELECTED_ALL_FALSE:
      return {
        ...state,
        selectAll: false,
      };
    case actions.types.SET_IS_REDIRECT:
      return {
        ...state,
        isRedirect: action.payload.value,
      };
    case actions.types.SET_EXPORT_MODAL:
      return {
        ...state,
        exportPopperOpen: action.payload.value,
      };
    case actions.types.EXPORT_CARTEIRA_START:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          isLoadingExport: true,
        },
      };
    case actions.types.EXPORT_CARTEIRA_ERROR:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          isLoadingExport: false,
        },
        snackbars: [...state.snackbars, {
          id: state.lastSnackbarErrorId,
          message: 'Error ao exportar carteira',
          type: 'error',
        }],
        lastSnackbarErrorId: state.lastSnackbarErrorId + 1,
      };
    case actions.types.EXPORT_CARTEIRA_SUCCESS:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          isLoadingExport: false,
        },
        snackbars: [...state.snackbars, {
          id: state.lastSnackbarErrorId,
          message: 'Carteira exportada com sucesso',
          type: 'success',
        }],
        lastSnackbarErrorId: state.lastSnackbarErrorId + 1,
      };
    case actions.types.DISMISS_SNACKBARS:
      return {
        ...state,
        snackbars: state.snackbars.filter((s) => s.id !== action.payload.id),
      };
    case actions.types.RESET_STORE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
