import React from 'react';
import PropTypes from 'prop-types';

import DateRangePicker from '../../../common-staff/controls/dateRangePicker';

const DateFilter = ({
  startDate, setStartDate, endDate,
  isLoading, setEndDate,
}) => (
  <DateRangePicker
    title="Selecione um período"
    startDate={startDate}
    setStartDate={setStartDate}
    setEndDate={setEndDate}
    endDate={endDate}
    disabled={isLoading}
    numberOfMonths={2}
  />
);

DateFilter.propTypes = {
  startDate: PropTypes.object,
  setStartDate: PropTypes.func,
  endDate: PropTypes.object,
  isLoading: PropTypes.bool,
  setEndDate: PropTypes.func,
};

DateFilter.defaultProps = {
  startDate: null,
  setStartDate: () => {},
  endDate: null,
  isLoading: false,
  setEndDate: () => {},
};

export default DateFilter;
