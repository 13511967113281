class DealerOrganizer {
  dealerToOfferMapping = {
    fiat: ['fiat'],
    jeep: ['jeep', 'chrysler', 'dodge', 'ram'],
    chrysler: ['jeep', 'chrysler', 'dodge', 'ram'],
  };

  offerToDealerMapping = {
    fiat: ['fiat'],
    jeep: ['jeep', 'chrysler'],
    chrysler: ['chrysler'],
    dodge: ['chrysler'],
    ram: ['chrysler'],
  };

  constructor(dealers) {
    this.dealersByBrand = new Map();

    (dealers || []).forEach((dealer) => {
      if (!dealer.brand) { return; }
      const brand = dealer.brand.toLowerCase();

      if (!this.dealersByBrand.has(brand)) {
        this.dealersByBrand.set(brand, []);
      }

      this.dealersByBrand.get(brand).push(dealer);
    });
  }

  getOfferBrands = () => {
    const brands = [];
    this.dealersByBrand.forEach((_, key) => {
      (this.dealerToOfferMapping[key] || []).forEach((b) => {
        if (!brands.includes(b)) {
          brands.push(b);
        }
      });
    });

    return brands;
  };

  getDealersForOfferBrand = (offerBrand) => {
    const dealerBrands = this.offerToDealerMapping[(offerBrand || '').toLowerCase()];
    let dealers = [];

    (dealerBrands || []).forEach((dealerBrand) => {
      dealers = dealers.concat(this.dealersByBrand.get(dealerBrand) || []);
    });

    return dealers;
  };
}

export default DealerOrganizer;
