const PREFIX_GET_MENSAGENS_REDE = 'mensagensRede/GET_MENSAGENS_REDE';
const GET_MENSAGENS_REDE_START = `${PREFIX_GET_MENSAGENS_REDE}/START`;
const GET_MENSAGENS_REDE_SUCCESS = `${PREFIX_GET_MENSAGENS_REDE}/SUCCESS`;
const GET_MENSAGENS_REDE_ERROR = `${PREFIX_GET_MENSAGENS_REDE}/ERROR`;

const SET_MODAL_OPEN = 'mensagensRede/SET_MODAL_OPEN';
const RESET_STORE = 'mensagensRede/RESET_STORE';

const getMensagensRedeStart = () => ({
  type: GET_MENSAGENS_REDE_START,
});

const getMensagensRedeError = () => ({
  type: GET_MENSAGENS_REDE_ERROR,
});

const getMensagensRedeSuccess = (mensagensRede) => ({
  type: GET_MENSAGENS_REDE_SUCCESS,
  payload: { mensagensRede },
});

const setModalOpen = (isOpen) => ({
  type: SET_MODAL_OPEN,
  payload: { isOpen },
});

const resetStore = () => ({
  type: RESET_STORE,
});

export default {
  types: {
    PREFIX_GET_MENSAGENS_REDE,
    GET_MENSAGENS_REDE_START,
    GET_MENSAGENS_REDE_SUCCESS,
    GET_MENSAGENS_REDE_ERROR,

    SET_MODAL_OPEN,
    RESET_STORE,
  },

  getMensagensRedeStart,
  getMensagensRedeError,
  getMensagensRedeSuccess,

  setModalOpen,
  resetStore,
};
