import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@material-ui/core';

import colors from 'assets/styles/colors';
import {
  DuplicataValue, DuplicataDate, DuplicataDescription, DuplicataIcon,
} from '../../../common';

import './invoiceRow.scss';

const InvoiceRow = ({ invoice, onClick }) => {
  const [isActive, toogleActive] = useState(false);

  return (
    <section
      className={
        `iof-row-section ${isActive ? 'iof-row-activated' : ''}`
      }
      data-cy="IofRowSection"
      onClick={() => { toogleActive(!isActive); onClick(invoice, !isActive); }}
      role="row"
      tabIndex="0"
    >
      <Checkbox
        className="iof-row-checkbox"
        style={{
          color: `${colors.primary_color_700}`,
        }}
        inputProps={{
          'aria-label': 'uncontrolled-checkbox',
        }}
        color="default"
        checked={isActive}
      />

      <DuplicataIcon
        className="iof-row-icon"
        product={invoice.product}
        status={invoice.status}
      />

      <DuplicataDescription
        className="iof-row-desc"
        product={invoice.product}
        invoiceNumber={invoice.invoiceNumber}
        invoiceDigit={invoice.invoiceDigit}
      />

      <DuplicataDate
        status={invoice.status}
        dueDate={invoice.dueDate}
        paymentDate={invoice.paymentDate}
        className="iof-row-date"
      />
      <DuplicataValue
        status={invoice.status}
        totalValue={invoice.totalValue}
        processingValue={invoice.processingValue}
        remainingValue={invoice.remainingValue}
        className="iof-row-value"
      />
    </section>
  );
};

InvoiceRow.propTypes = {
  onClick: PropTypes.func,
  invoice: PropTypes.shape({
    invoiceNumber: PropTypes.number,
    invoiceDigit: PropTypes.number,
    paymentDate: PropTypes.string,
    dueDate: PropTypes.string,
    status: PropTypes.oneOf(['canceled', 'duplicated', 'paid', 'opened', 'delayed']).isRequired,
    product: PropTypes.oneOf([
      'BRIDGE', 'ESTOQUE FLOOR PLAN', 'ESTOQUE FUNDAO CDB', 'ESTOQUE FUNDAO SCP', 'FLOOR PLAN USA', 'FUNDAO USA',
      'FUNDAO PEGASO', 'IOF NOVOS FLOOR PLAN', 'IOF NOVOS FLOOR SCP', 'IOF USADOS FLOOR PLAN', 'JUROS',
      'MULTA', 'OUTROS', 'PEÇAS', 'PLANO EMERGENCIAL CDB', 'PLANO EMERGENCIAL FLOOR PLAN', 'PLANO EMERGENCIAL SCP',
      'TEST DRIVE', 'VEICULO VENDIDO FLOOR PLAN', 'VEICULO VENDIDO FUNDAO CDB', 'VEICULO VENDIDO FUNDAO SCP', 'Outros',
    ]).isRequired,
    totalValue: PropTypes.number.isRequired,
    processingValue: PropTypes.number,
    remainingValue: PropTypes.number,
  }).isRequired,
};

InvoiceRow.defaultProps = {
  onClick: () => {},
};

export default InvoiceRow;
