import React from 'react';
import PropTypes from 'prop-types';

import { ConcessionariaSelector } from 'features/common/';
import { Tracking } from 'modules';

const SelectConcessionaria = ({
  setConcessionariaSelecionada, concessionarias, selectedDealerId,
}) => (
  <ConcessionariaSelector
    concessionarias={concessionarias}
    concessionariaSelecionada={concessionarias.find((c) => c.dealerId === selectedDealerId)}
    setConcessionariaSelecionada={(value) => {
      Tracking.Mixpanel.alterarLimite.trackConcessionaria(value);
      setConcessionariaSelecionada(value);
    }}
  />
);

SelectConcessionaria.propTypes = {
  setConcessionariaSelecionada: PropTypes.func,
  concessionarias: PropTypes.array,
  selectedDealerId: PropTypes.number,
};

SelectConcessionaria.defaultProps = {
  concessionarias: [],
  setConcessionariaSelecionada: () => {},
  selectedDealerId: null,
};

export default SelectConcessionaria;
