import * as React from "react";

function SvgWow(props) {
	return (
		<svg width="1em" height="1em" viewBox="0 0 40 40" {...props}>
			<defs>
				<linearGradient id="wow_svg__a" x1="50%" x2="50%" y1="10.25%" y2="100%">
					<stop offset="0%" stopColor="#FEEA70" />
					<stop offset="100%" stopColor="#F69B30" />
				</linearGradient>
				<linearGradient id="wow_svg__b" x1="50%" x2="50%" y1="0%" y2="100%">
					<stop offset="0%" stopColor="#8B3A0E" />
					<stop offset="100%" stopColor="#472315" />
				</linearGradient>
				<linearGradient id="wow_svg__c" x1="50%" x2="50%" y1="0%" y2="100%">
					<stop offset="0%" stopColor="#191A33" />
					<stop offset="87.2%" stopColor="#3B426A" />
				</linearGradient>
				<linearGradient
					id="wow_svg__d"
					x1="49.998%"
					x2="49.998%"
					y1="-.04%"
					y2="100.021%"
				>
					<stop offset="0%" stopColor="#E78E0D" />
					<stop offset="100%" stopColor="#CB6000" />
				</linearGradient>
			</defs>
			<g fill="none">
				<path
					fill="url(#wow_svg__a)"
					d="M40 20a19.998 19.998 0 01-20 20 20 20 0 1120-20"
				/>
				<path
					fill="url(#wow_svg__b)"
					d="M14 32.413c0 .342.235.587.625.587.877 0 2.09-1.563 6.043-1.563 3.952 0 5.167 1.563 6.042 1.563.39 0 .625-.245.625-.587 0-.993-2.098-4.413-6.668-4.413S14 31.42 14 32.413z"
					transform="matrix(1 0 0 -1 0 61)"
				/>
				<path
					fill="url(#wow_svg__c)"
					d="M8.75 17.75c0-2.07 1.398-3.75 3.125-3.75S15 15.68 15 17.75c0 2.073-1.398 3.75-3.125 3.75S8.75 19.823 8.75 17.75zm16.25 0c0-2.07 1.4-3.75 3.125-3.75 1.727 0 3.125 1.68 3.125 3.75 0 2.073-1.398 3.75-3.125 3.75C26.4 21.5 25 19.823 25 17.75z"
				/>
				<path
					fill="#000"
					d="M8.75 17.75c0-2.07 1.398-3.75 3.125-3.75S15 15.68 15 17.75c0 2.073-1.398 3.75-3.125 3.75S8.75 19.823 8.75 17.75zm16.25 0c0-2.07 1.4-3.75 3.125-3.75 1.727 0 3.125 1.68 3.125 3.75 0 2.073-1.398 3.75-3.125 3.75C26.4 21.5 25 19.823 25 17.75z"
				/>
				<path
					fill="#4E506A"
					d="M11.204 15.418c.465.105.725.63.58 1.172-.143.545-.635.9-1.1.795-.465-.105-.725-.63-.58-1.175.142-.54.635-.9 1.1-.792zm16.645.157c.515.118.805.7.645 1.3-.16.608-.705 1-1.223.885-.515-.115-.805-.7-.645-1.302.158-.605.705-1 1.225-.883h-.002z"
				/>
				<path
					fill="#000"
					d="M27.67 8.24c.13-.013.26-.018.393-.018 1.218 0 2.475.51 3.43 1.405a.925.925 0 01.285.626.925.925 0 01-.23.65.866.866 0 01-.947.241.84.84 0 01-.293-.182c-.688-.647-1.64-1-2.48-.922a1.993 1.993 0 00-1.475.827.872.872 0 01-.92.323.873.873 0 01-.308-.153.925.925 0 01-.34-.6.924.924 0 01.173-.667 3.728 3.728 0 012.713-1.53zM8.509 9.627a5.107 5.107 0 013.43-1.405 3.731 3.731 0 013.105 1.548.922.922 0 01-.165 1.267.865.865 0 01-.975.061.891.891 0 01-.255-.23 1.989 1.989 0 00-1.475-.828c-.837-.078-1.792.275-2.48.922a.855.855 0 01-.966.149.837.837 0 01-.274-.209.922.922 0 01.055-1.275z"
				/>
				<path
					fill="url(#wow_svg__d)"
					d="M27.67 8.24c.13-.013.26-.018.393-.018 1.218 0 2.475.51 3.43 1.405a.925.925 0 01.285.626.925.925 0 01-.23.65.866.866 0 01-.947.241.84.84 0 01-.293-.182c-.688-.647-1.64-1-2.48-.922a1.993 1.993 0 00-1.475.827.872.872 0 01-.92.323.873.873 0 01-.308-.153.925.925 0 01-.34-.6.924.924 0 01.173-.667 3.728 3.728 0 012.713-1.53zM8.509 9.627a5.107 5.107 0 013.43-1.405 3.731 3.731 0 013.105 1.548.922.922 0 01-.165 1.267.865.865 0 01-.975.061.891.891 0 01-.255-.23 1.989 1.989 0 00-1.475-.828c-.837-.078-1.792.275-2.48.922a.855.855 0 01-.966.149.837.837 0 01-.274-.209.922.922 0 01.055-1.275z"
				/>
			</g>
		</svg>
	);
}

export default SvgWow;

