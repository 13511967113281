import React from 'react';
import PropTypes from 'prop-types';

import BaseArea from '../baseArea';
import ChartCarteiraProduto from './chartCarteiraProduto';
import LegendaCarteiraProduto from './legendaCarteiraProduto';
import './valorCarteiraProduto.scss';

const ValorCarteiraProduto = ({
  isLoading, isError, isEmpty, className,
}) => (
  <BaseArea
    isEmpty={isEmpty}
    isLoading={isLoading}
    isError={isError}
    title="Carteira x Produto"
    subtitle="Valor"
    className={className}
    legenda={<LegendaCarteiraProduto />}
  >
    <div className="dashboard-floor-plan__carteira_produto__content">
      <ChartCarteiraProduto />
    </div>

  </BaseArea>
);

ValorCarteiraProduto.propTypes = {
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  isEmpty: PropTypes.bool,
  className: PropTypes.string,
};

ValorCarteiraProduto.defaultProps = {
  isLoading: false,
  isError: false,
  isEmpty: false,
  className: '',
};

export default ValorCarteiraProduto;
