import axios from 'axios';

const getFilters = (filters) => ({
  dealersId: filters.selectedPonto.map((m) => m.value),
  produtosId: filters.selectedProduto.length > 0
    ? filters.selectedProduto.map((m) => m.value)
      .reduce((result, current) => [...result, ...current])
    : null,
  atualizadoAteOntem: filters.atualizadoOntem,
});

class DashboardService {
  constructor() {
    this.URL_API = window.env.REACT_APP_API_URL;
  }

  async getFiltroProdutos(filters) {
    const body = {
      dealersId: getFilters(filters).dealersId,
    };

    const response = await axios.post(`${this.URL_API}/dashboard/filter/produtos`, body);

    if (!response || response.status !== 200) {
      throw new Error('Erro ao busca filtro de produtos');
    }

    return response.data;
  }

  async getCardsResumo(filters) {
    const body = getFilters(filters);

    const response = await axios.post(`${this.URL_API}/dashboard/resumo`, body);

    if (!response || response.status !== 200) {
      throw new Error('Error ao buscar cards vencimento');
    }

    return response.data;
  }

  async getCardsVencimento(filters) {
    const body = getFilters(filters);
    const response = await axios.post(`${this.URL_API}/dashboard/card-vencimento`, body);

    if (!response || response.status !== 200) {
      throw new Error('Error ao buscar cards vencimento');
    }

    return response.data;
  }

  async getCarteiraProduto(filters) {
    const body = getFilters(filters);
    const response = await axios.post(`${this.URL_API}/dashboard/card-saldo-devedor`, body);

    if (!response || response.status !== 200) {
      throw new Error('Error ao buscar carteira produto');
    }

    return response.data;
  }

  async getValorDuplicatas(filters) {
    const body = getFilters(filters);
    const response = await axios.post(
      `${this.URL_API}/dashboard/card-valores-vencidas-a-vencer`,
      body,
    );

    if (!response || response.status !== 200) {
      throw new Error('Error ao buscar carteira produto');
    }

    return response.data;
  }

  async getQuantidadeDuplicatas(filters) {
    const body = getFilters(filters);
    const response = await axios.post(
      `${this.URL_API}/dashboard/card-quantidade-vencidas-a-vencer`,
      body,
    );

    if (!response || response.status !== 200) {
      throw new Error('Error ao buscar carteira produto');
    }

    return response.data;
  }

  async getCarteiraBrand(filters) {
    const body = getFilters(filters);
    const response = await axios.post(`${this.URL_API}/dashboard/card-carteira-por-brand`, body);

    if (!response || response.status !== 200) {
      throw new Error('Error ao buscar carteira por brand');
    }

    return response.data;
  }
  async isUpdated() {
    const response = await axios.get(`${this.URL_API}/dashboard/is-updated`);

    return response.data;
  }
}

export default new DashboardService();
