import React from 'react';
import PropTypes from 'prop-types';

import { formatCurrency } from 'utils/format';
import Button from '../../../../../../../common-staff/controls/button';

import './footer.scss';

const Footer = ({
  totalValue, invoices, navTo, setDocs,
}) => (
  <div className="carteira-modal__footer">
    <div className="carteira-modal__footer__valor">
      Valor total:
      <span>
        {formatCurrency(totalValue)}
      </span>
    </div>
    <div className="carteira-modal__footer__button">
      <Button
        onClick={() => {
          setDocs(invoices);
          navTo();
        }}
      >
        Prosseguir para Pagamento
      </Button>
    </div>
  </div>
);

Footer.propTypes = {
  totalValue: PropTypes.number,
  invoices: PropTypes.arrayOf(PropTypes.object),
  navTo: PropTypes.func,
  setDocs: PropTypes.func,
};

Footer.defaultProps = {
  totalValue: 0,
  invoices: null,
  navTo: () => {},
  setDocs: () => {},
};

export default Footer;
