import React from 'react';

import OrderByButton from './views/orderByButton';

import './listHeader.scss';

const ListHeader = () => (
  <div className="statement_page__list-header__outer">
    <div className="statement_page__list-header__container">
      <div
        className="statement_page__list-header__item statement_page__list-header__transacao"
        data-cy="ItemStatementPageListHeaderTransacao"
      >
        TRANSAÇÃO
      </div>
      <div
        className="statement_page__list-header__item statement_page__list-header__data"
        data-cy="ItemStatementPageListHeaderData"
      >
        <OrderByButton />
      </div>
      <div
        className="statement_page__list-header__item statement_page__list-header__saldo"
        data-cy="ItemStatementPageListHeaderSaldo"
      >
        SALDO
      </div>
    </div>
  </div>
);

export default ListHeader;
