/* eslint-disable no-tabs */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import QuoteRequestIcon from 'assets/icons/quote-request';
import {
  Page, PageHeader, PageTitle, PageContent, PageFooter, PageSubTitle,
} from 'features/common-staff/layout/page';
import SplitView from 'features/common-staff/layout/splitView';
import SnackbarList from 'features/common-staff/snackbarList';
import AlertaMensagem from 'features/common/alertaMensagem';
import ButtonIcon from 'features/common-staff/controls/buttonIcon';
import MenuInternetBanking from '../../../../menu/view/menuInternetBanking';

import Filters from './views/filters';
import BreadcrumbCarteira from './views/breadcrumbCarteira';
import CarteiraList from './views/carteiraList';
import CarteiraPageFooter from './views/carteiraPageFooter';
import WarningRedirect from './views/warning';
import ModalTaxasFloorPlan from '../modalTaxasFloorPlan';
import ModalHistoricoDuplicata from '../modalHistoricoDuplicata';

import Modal from '../carteiraModal';

import './carteiraPage.scss';

const CarteiraPage = ({
  isModalInfoOpen, closeModalInfo, isModalOpen, isUpdated,
  closeModal, getCarteira, resetStore, selectedDealer,
  snackbarErrors, onCloseSnackBar, isRedirect, setConcessionaria,
  isModalTaxasOpen, isModalHistoricoOpen, setModalTaxaOpen, permissions,
  exportPopperOpen,
}) => {
  useEffect(() => {
    setConcessionaria();
    getCarteira();
    return () => { resetStore(); };
  }, [getCarteira, resetStore, selectedDealer, setConcessionaria]);

  useEffect(() => {
    closeModal();
  }, [exportPopperOpen, closeModal]);

  return (
    <Page>
      <div className="carteira-page">
        <section className="carteira-page_menu">
          <MenuInternetBanking />
        </section>

        <section className="carteira-page_content">
          <SplitView
            open={(isModalOpen || isModalInfoOpen) && !exportPopperOpen}
            sideSheetProps={{
              onClickClose: () => {
                if (isModalInfoOpen) {
                  closeModalInfo();
                  return;
                }
                closeModal();
              },
              width: 400,
            }}
            renderSideSheetContent={() => <Modal />}
          >
            <Page
              minWidth="fit-content"
            >
              <PageHeader>
                <PageSubTitle>
                  <BreadcrumbCarteira />
                </PageSubTitle>
                <PageTitle>
                  <div className="carteira-page__title">
                    Carteira Floor Plan
                  </div>
                  { (permissions.visualizarTaxas === true && !isModalInfoOpen && !isModalOpen) ? (
                    <div className="carteira-page__button-modal">
                      <ButtonIcon
                        className="carteira-page__button-modal-taxas"
                        onClick={() => setModalTaxaOpen()}
                      >
                        <div className="carteira-page__button-modal__title">
                          <span>Consulte as taxas do Banco Stellantis</span>
                          <QuoteRequestIcon
                            width="15"
                            height="15"
                          />
                        </div>
                      </ButtonIcon>
                    </div>
                  ) : null }
                </PageTitle>
              </PageHeader>
              <PageContent>
                <div className="carteira-page__conteudo">
                  {isUpdated ? (
                    <div className="carteira-page__conteudo__filters">
                      <Filters />
                    </div>
                  ) : null}
                  {isRedirect ? (
                    <div className="carteira-page__conteudo__warning">
                      <WarningRedirect
                        selectedDealer={selectedDealer}
                      />
                    </div>
                  )
                    : null}
                  <div className="carteira-page__conteudo__list">
                    {isUpdated ? <CarteiraList />
                      : (
                        <div className="carteira-page__conteudo__list-alerta">
                          <AlertaMensagem
                            mensagem="Desculpe, não conseguimos exibir suas duplicatas."
                            subMensagem="Estamos trabalhando para resolver o mais rápido possível."
                          />
                        </div>
                      )}
                  </div>
                </div>
              </PageContent>
              {isUpdated
                ? (
                  <PageFooter>
                    <CarteiraPageFooter />
                  </PageFooter>
                ) : null}
              <SnackbarList
                snackbarErrors={snackbarErrors}
                onClose={(id) => onCloseSnackBar(id)}
              />
              {isModalHistoricoOpen ? <ModalHistoricoDuplicata /> : null}
            </Page>
          </SplitView>
        </section>
      </div>
      {isModalTaxasOpen ? <ModalTaxasFloorPlan /> : null}
    </Page>
  );
};

CarteiraPage.propTypes = {
  isModalInfoOpen: PropTypes.bool,
  closeModalInfo: PropTypes.func,
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  getCarteira: PropTypes.func,
  resetStore: PropTypes.func,
  selectedDealer: PropTypes.number,
  snackbarErrors: PropTypes.array,
  onCloseSnackBar: PropTypes.func,
  isRedirect: PropTypes.bool,
  setConcessionaria: PropTypes.func,
  isUpdated: PropTypes.bool,
  isModalTaxasOpen: PropTypes.bool,
  permissions: PropTypes.object.isRequired,
  setModalTaxaOpen: PropTypes.func,
  isModalHistoricoOpen: PropTypes.bool,
  exportPopperOpen: PropTypes.bool,
};

CarteiraPage.defaultProps = {
  isModalInfoOpen: false,
  isModalOpen: PropTypes.bool,
  closeModalInfo: () => {},
  closeModal: () => {},
  getCarteira: () => {},
  resetStore: () => {},
  selectedDealer: null,
  snackbarErrors: [],
  onCloseSnackBar: () => {},
  isRedirect: false,
  setConcessionaria: () => {},
  isUpdated: true,
  isModalTaxasOpen: false,
  setModalTaxaOpen: () => {},
  isModalHistoricoOpen: false,
  exportPopperOpen: false,
};

export default CarteiraPage;
