import * as React from "react";

const DefaultIcon = (props) => {
    return(
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100%" viewBox="0 0 592 576" enable-background="new 0 0 592 576" {...props}>
            <path fill="#cccccc" opacity="1.000000" stroke="none" 
                d="
            M172.318268,141.139618 
                C176.234451,142.129898 178.967468,144.188446 181.497696,146.718582 
                C274.574615,239.791702 367.676117,332.840179 460.766754,425.899567 
                C468.593048,433.723297 469.435028,442.852753 463.052765,449.883301 
                C456.829102,456.739136 446.840240,457.097900 439.876160,450.432404 
                C433.137238,443.982391 426.500458,437.410004 420.114655,430.613373 
                C417.014740,427.314056 413.727814,425.993317 409.179932,426.002106 
                C344.864380,426.126343 280.548462,426.088837 216.232666,426.081024 
                C206.937286,426.079895 198.434387,423.836365 191.510895,417.198242 
                C184.295197,410.279938 181.710587,401.577728 181.711929,391.872040 
                C181.720795,327.722870 181.676605,263.573517 181.842606,199.424759 
                C181.855743,194.341873 180.524353,190.622009 176.775757,187.171082 
                C170.407227,181.308212 164.420853,175.026505 158.342255,168.853470 
                C152.538361,162.959442 151.218246,156.291412 154.414978,149.747559 
                C157.496582,143.439377 163.938629,140.226379 172.318268,141.139618 
            M349.493347,378.667297 
                C354.782227,378.446747 360.174194,379.239929 365.610168,378.013672 
                C364.986847,375.284088 363.620911,374.239746 362.476196,373.090546 
                C351.422424,361.993622 340.370850,350.894257 329.254303,339.860382 
                C324.163727,334.807648 323.232849,334.924225 318.755096,340.506622 
                C314.513214,345.794983 310.279999,351.090332 306.260986,356.110291 
                C303.496246,355.583832 302.622131,353.851746 301.472076,352.473785 
                C292.183929,341.345459 282.988525,330.138550 273.603210,319.092987 
                C269.374329,314.115997 265.296722,314.215454 261.014282,319.120422 
                C258.826477,321.626343 256.897583,324.358643 254.858215,326.993713 
                C244.764709,340.035431 234.635620,353.049896 224.600693,366.136536 
                C220.826645,371.058319 221.925140,375.691620 227.114792,377.958893 
                C229.201233,378.870392 231.370438,378.634369 233.517609,378.635712 
                C271.843750,378.659698 310.169922,378.661560 349.493347,378.667297 
            z"/>
            <path fill="#cccccc" opacity="1.000000" stroke="none" 
                d="
            M415.996063,147.904221 
                C423.144165,147.984650 429.849182,147.348221 436.379425,149.135300 
                C450.509705,153.002197 459.582520,164.999191 459.617279,179.779785 
                C459.735626,230.095825 459.752808,280.412384 460.021210,330.727600 
                C460.106506,346.720245 458.796417,362.696350 459.653351,378.690887 
                C459.695251,379.473114 459.505341,380.267731 459.511047,380.212677 
                C382.528015,303.206329 305.324188,225.979126 227.537384,148.168762 
                C247.973312,147.533524 268.757355,148.030746 289.523315,147.959473 
                C310.518829,147.887421 331.514801,147.939301 352.510559,147.932983 
                C373.506073,147.926666 394.501556,147.915527 415.996063,147.904221 
            z"/>
        </svg>
    );
}

export default DefaultIcon;