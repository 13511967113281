import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';

import DashboardIcon from '@material-ui/icons/Dashboard';

import { PATH_DASHBOARD } from 'routes/paths';

import MenuItem from 'features/common-staff/controls/menuItem';
import './menuDashboard.scss';

const MenuDashboard = ({ permissions }) => {
  const { url } = useRouteMatch();
  const history = useHistory();

  const navTo = (itemName) => {
    history.replace(`${url}/${itemName}`);
  };
  return (
    <div className="menu-dashboard__container">
      <div className="menu-dashboard__container__title">
        <div className="menu-dashboard__container__title_icon">
          <DashboardIcon />
        </div>
        <div className="menu-dashboard__container__title_text">
          Dashboard
        </div>
      </div>
      <div className="menu-dashboard__container__content">
        {permissions.dashboardFloorPlan === true
          ? (
            <div
              className="menu-dashboard__item"
            >
              <MenuItem
                Icon={DashboardIcon}
                title="Painel Floor Plan"
                isActive={window.location.pathname === `${PATH_DASHBOARD}/`}
                onClick={() => navTo(' ')}
              />
            </div>
          )
          : null}
      </div>
    </div>
  );
};

MenuDashboard.propTypes = {
  permissions: PropTypes.object,
};

MenuDashboard.defaultProps = {
  permissions: {},
};

export default MenuDashboard;
