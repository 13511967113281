class SnackbarMessage {
  id;
  type;
  message;
  onClose;
  timeout;

  static TYPE_SUCCESS = 'success';
  static TYPE_ERROR = 'error';

  constructor(type, message, onClose, timeout = 7000, id = null) {
    this.id = id;
    this.type = type;
    this.message = message;
    this.onClose = onClose;
    this.timeout = timeout;
  }
}

export default SnackbarMessage;
