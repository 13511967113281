import React from 'react';
import PropTypes from 'prop-types';

import './componentErrorBoundary.scss';

class ComponentErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? (
      <div className="component-error-boundary__content">
        <div>Ocorreu um erro.</div>
      </div>
    ) : children;
  }
}

ComponentErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ComponentErrorBoundary;
