import {
  React, useEffect, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { deburr } from 'lodash';
import moment from 'moment';
import { getCurrentUser } from 'modules/auth/auth';

import { Box, Divider, Stack } from '@mui/material';
import { Quill } from 'react-quill';
import { useForm, useFieldArray } from 'react-hook-form';
import LooksOneRoundedIcon from '@mui/icons-material/LooksOneRounded';
import LooksTwoRoundedIcon from '@mui/icons-material/LooksTwoRounded';

import colors from 'assets/styles/colors';
import SummaryPage from 'features/common/summaryPage';
import EnviarParaDealer from './enviarParaDealer/enviarParaDealer';
import InputToolbar from './inputToolbar/inputToolbar';
import InputContainer from './inputContainer/InputContainer';
import 'react-quill/dist/quill.snow.css';
import ObservacoesList from './observacoesList/observacoesList';
import TextFieldComponent from './pesquisaObservacoes/TextFieldComponent';
import SelectFilterPerfil from './pesquisaObservacoes/SelectFilterPerfil';

const AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);

const HistoricoObservacoes = ({
  observacoes, inputMaxLines, isDealer, isDrawer, addSnackbar, maxHeight,
  insertObservacao, uploadArquivoTemporario, getDocumentoDownload,
}) => {
  const {
    register, handleSubmit, formState: { errors, isSubmitting },
    setValue, watch, control, clearErrors,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      observacao: '',
      enviarParaDealer: isDealer,
      anexos: [],
    },
  });
  const normalizeText = (text) => deburr(text.toLowerCase().normalize('NFD'));
  const perfilMap = {
    cadastro: 'Cadastro',
    dealer: 'Dealer',
    financiamento: 'Finan. Rede',
  };

  const [search, setSearch] = useState('');
  const [perfil, setPerfil] = useState(null);

  const observacaoValue = watch('observacao');
  const isErrorObservacao = Boolean(errors.observacao);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'anexos',
  });

  useEffect(() => {
    register('observacao', {
      validate: (value) => {
        const tmpValue = new DOMParser().parseFromString(value, 'text/html');
        const validInput = Boolean(tmpValue.body.textContent.trim());
        if (!validInput && fields.length === 0) setValue('observacao', '');
        return validInput || fields.length > 0;
      },
    });
  }, [register, fields]);

  const onSubmit = async (data) => {
    try {
      const observacaoComAnexos = {
        ...data,
        anexos: fields,
      };

      await insertObservacao(observacaoComAnexos);

      setValue('observacao', '');
      remove();
    } catch (error) {
      addSnackbar('Erro ao enviar observação');
    }
  };

  const uploadDocumento = async (file) => {
    const novoDocumento = await uploadArquivoTemporario(file);

    if (novoDocumento) {
      append(novoDocumento);
      clearErrors('observacao');
    }
  };

  const deleteDocumento = (documento) => {
    remove(fields.findIndex((field) => field.nomeGuid === documento.nomeGuid));
  };

  const downloadDocumento = async (guidDocumento) => getDocumentoDownload(guidDocumento);

  const filterByPerfil = (item) => {
    if (!perfil || perfil.length === 3) return true;

    const usuarioNameText = normalizeText(item?.usuarioNome);

    const isPerfilSelected = perfil.some((p) => p.label === item.perfil);
    const isUserNameSelected = perfil.some((p) => p.label === 'usuarioNome') && usuarioNameText === normalizeText(getCurrentUser().name);

    return isPerfilSelected || isUserNameSelected;
  };

  const filterBySearch = (item) => {
    const normalizeSearch = normalizeText(search);
    const domParser = new DOMParser();

    const htmlDocument = domParser.parseFromString(item?.observacao, 'text/html');
    const perfilText = normalizeText(perfilMap[item?.perfil] || 'N/A');
    const itemText = normalizeText(htmlDocument.body.textContent);
    const usuarioEmailText = normalizeText(item?.usuarioEmail);
    const creationDate = moment(item?.criadoEm).format('DD/MM/YYYY').toLowerCase();
    const creationTime = moment(item?.criadoEm).format('HH:mm').toLowerCase();

    return [
      perfilText.includes(normalizeSearch),
      itemText.includes(normalizeSearch),
      usuarioEmailText.includes(normalizeSearch),
      creationDate.includes(normalizeSearch),
      creationTime.includes(normalizeSearch),
    ].some((condition) => condition);
  };

  const filteredObservacoes = useMemo(
    () => observacoes.filter((item) => filterByPerfil(item) && filterBySearch(item)),
    [search, observacoes, perfil],
  );

  const filledFieldsCount = [perfil, search].filter((field) => {
    if (Array.isArray(field)) {
      return field.length !== 0 && field.length !== 3;
    }
    return field && field.length > 0;
  }).length;

  const getIcon = () => {
    if (filledFieldsCount === 1) {
      return (
        <LooksOneRoundedIcon style={{ color: colors.error_color_300 }} />
      );
    }

    if (filledFieldsCount === 2) {
      return (
        <LooksTwoRoundedIcon style={{ color: colors.error_color_300 }} />
      );
    }

    return null;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        minWidth={310}
        maxHeight={maxHeight}
        sx={{
          overflowY: 'scroll',
          '&::-webkit-scrollbar': {
            width: '4px',
          },
        }}
      >
        <Stack direction="row" height={56} gap="12px" padding="16px 12px 16px 6px" justifyContent="space-between">
          <InputToolbar disabled={isSubmitting} />
          {!isDrawer && (
            <Stack direction="row" alignItems="center">
              <TextFieldComponent
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <SelectFilterPerfil
                perfil={perfil}
                setPerfil={setPerfil}
              />
            </Stack>
          )}
        </Stack>
        <Divider sx={{ borderColor: colors.secundary_color_100 }} />
        {isDrawer && (
          <SummaryPage
            level={4}
            title="Filtros"
            IconTitle={getIcon()}
          >
            <Stack direction="column" alignItems="center" spacing="8px" data-cy="conteudo-produto" style={{ marginTop: '8px', marginBottom: '8px' }}>
              <SelectFilterPerfil
                perfil={perfil}
                setPerfil={setPerfil}
                drawerWidth={329}
                isDrawer={isDrawer}
              />
              <TextFieldComponent
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Stack>
            <Divider sx={{ borderColor: colors.secundary_color_100 }} />
          </SummaryPage>
        )}
        <Stack padding="12px 12px 0px 12px" gap="8px">
          {!isDealer && (
            <Stack direction="row" minHeight={20} gap="8px">
              <EnviarParaDealer control={control} disabled={isSubmitting} />
            </Stack>
          )}
          <InputContainer
            inputMaxLines={inputMaxLines}
            observacao={observacaoValue}
            setObservacao={(value) => { setValue('observacao', value); if (isErrorObservacao) clearErrors('observacao'); }}
            error={isErrorObservacao}
            isSubmitting={isSubmitting}
            uploadDocumento={uploadDocumento}
            deleteDocumento={deleteDocumento}
            documentos={fields}
          />
        </Stack>
        <Box padding="0px 12px 8px 12px">
          <ObservacoesList
            items={filteredObservacoes}
            isDealer={isDealer}
            downloadDocumento={downloadDocumento}
            isDrawer={isDrawer}
          />
        </Box>
      </Stack>
    </form>
  );
};

HistoricoObservacoes.propTypes = {
  observacoes: PropTypes.array,
  inputMaxLines: PropTypes.number,
  isDealer: PropTypes.bool,
  isDrawer: PropTypes.bool,
  addSnackbar: PropTypes.func,
  maxHeight: PropTypes.number,
  insertObservacao: PropTypes.func.isRequired,
  uploadArquivoTemporario: PropTypes.func.isRequired,
  getDocumentoDownload: PropTypes.func.isRequired,
};

HistoricoObservacoes.defaultProps = {
  observacoes: [],
  inputMaxLines: 9,
  isDealer: false,
  isDrawer: false,
  addSnackbar: () => { },
  maxHeight: 320,
};

export default HistoricoObservacoes;
