/* eslint-disable */
import React from "react";

const RadioSelectedIcon = props => (
	<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M6.99967 0.333496C3.31967 0.333496 0.333008 3.32016 0.333008 7.00016C0.333008 10.6802 3.31967 13.6668 6.99967 13.6668C10.6797 13.6668 13.6663 10.6802 13.6663 7.00016C13.6663 3.32016 10.6797 0.333496 6.99967 0.333496ZM6.99967 12.3335C4.05301 12.3335 1.66634 9.94683 1.66634 7.00016C1.66634 4.0535 4.05301 1.66683 6.99967 1.66683C9.94634 1.66683 12.333 4.0535 12.333 7.00016C12.333 9.94683 9.94634 12.3335 6.99967 12.3335ZM6.99967 10.3335C8.84062 10.3335 10.333 8.84111 10.333 7.00016C10.333 5.15921 8.84062 3.66683 6.99967 3.66683C5.15872 3.66683 3.66634 5.15921 3.66634 7.00016C3.66634 8.84111 5.15872 10.3335 6.99967 10.3335Z" fill="#1B2961"/>
	</svg>
);

export default RadioSelectedIcon;

