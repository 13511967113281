import { connect } from 'react-redux';

import BotaoDownload from './botaoDownload';
import operations from '../../../redux/operations';
import selectors from '../../../redux/selectors';

const mapStateToProps = ({ relatoriosIof }) => ({
  disabled: selectors.isEnableDownload(relatoriosIof),
  isLoading: relatoriosIof.requestStatus.isLoadingExport,
});

const mapDispatchToProps = (dispatch) => ({
  onClick: () => dispatch(operations.download()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BotaoDownload);
