import { connect } from 'react-redux';

import ModalHistoricoDuplicata from './modalHistoricoDuplicata';
import operations from './redux/operations';

const mapStateToProps = ({ gestaoPagamentos }) => ({
  isLoading: gestaoPagamentos.carteira?.modalHistoricoDuplicata?.isLoading,
  isError: gestaoPagamentos.carteira?.modalHistoricoDuplicata?.isError,
  historico: gestaoPagamentos.carteira?.modalHistoricoDuplicata?.data,
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(operations.setOpenModal()),
  resetStore: () => dispatch(operations.resetStore()),
  getHistorico: () => dispatch(operations.getHistorico()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalHistoricoDuplicata);
