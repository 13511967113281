import moment from 'moment';

export const arrayStatus = [
  { value: 'negado', label: 'Negado' },
  { value: 'aceito', label: 'Aceito' },
];

const dateFormat = (date) => {
  const formatDate = moment(date).format('DD/MM/YYYY');
  return formatDate;
};

export const periodoOptions = (periodo) => (
  periodo.map((p, index) => ({
    value: index + 1,
    text: `${dateFormat(p.dataInicioPagamento)} a ${dateFormat(p.dataFimPagamento)}`,
  })));
