import { connect } from 'react-redux';

import UploadDocumento from './uploadDocumento';
import operations from '../redux/operations';

const mapStateToProps = ({ limitesAprovadosCadastro }) => ({
  gerenciadorDocumentos: limitesAprovadosCadastro.gerenciadorDocumentos,
  urlDownload: limitesAprovadosCadastro.urlDownload,
});

const mapDispatchToProps = (dispatch) => ({
  uploadDocumento: (file, idPessoaDocumento, idTipoDocumento) => {
    (dispatch(operations.uploadDocumento(file, idPessoaDocumento, idTipoDocumento)));
  },
  downloadDocumento: (idDocumento, idPessoaDocumento, idTipoDocumento) => {
    (dispatch(operations.downloadDocumento(idDocumento, idPessoaDocumento, idTipoDocumento)));
  },
  deleteDocumento: (idPessoaDocumentacao, idTipoDocumento) => {
    (dispatch(operations.deleteDocumento(idPessoaDocumentacao, idTipoDocumento)));
  },
  setErroDocumento: (idPessoaDocumento, idTipoDocumento, textoErro) => {
    (dispatch(operations.setErroDocumento(idPessoaDocumento, idTipoDocumento, textoErro)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocumento);
