export const INIT_LOGIN = 'auth/INIT_LOGIN';
export const FINISH_LOGIN = 'auth/FINISH_LOGIN';
export const SET_USER = 'auth/SET_USER';
export const SET_PERMISSIONS = 'auth/SET_PERMISSIONS';
export const SET_SESSION_EXPIRED = 'auth/SET_SESSION_EXPIRED';
export const INIT_LOGIN_REDIRECT = 'auth/INIT_LOGIN_REDIRECT';
export const SET_ERROR_MESSAGE = 'auth/SET_ERROR_MESSAGE';

export const initLogin = () => ({
  type: INIT_LOGIN,
  payload: {},
});

export const initLoginRedirect = () => ({
  type: INIT_LOGIN_REDIRECT,
  payload: {},
});

export const finishLogin = (user, permissions, isLoginError = false) => ({
  type: FINISH_LOGIN,
  payload: {
    user,
    permissions,
    isLoginError,
  },
});

export const setUser = (user) => ({
  type: SET_USER,
  payload: {
    user,
  },
});

export const setPermissions = (permissions) => ({
  type: SET_PERMISSIONS,
  payload: {
    permissions,
  },
});

export const setSessionExpired = (expired) => ({
  type: SET_SESSION_EXPIRED,
  payload: {
    expired,
  },
});

export const setErrorMessage = (message) => ({
  type: SET_ERROR_MESSAGE,
  payload: {
    message,
  },
});
