import React from "react";

const ExitIcon = props => (
	<svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
		<path
			fill="#FFF"
			fillRule="evenodd"
			d="M10.79 16.29c.39.39 1.02.39 1.41 0l3.59-3.59a.996.996 0 000-1.41L12.2 7.7a.996.996 0 10-1.41 1.41L12.67 11H4c-.55 0-1 .45-1 1s.45 1 1 1h8.67l-1.88 1.88c-.39.39-.38 1.03 0 1.41zM19 3H5a2 2 0 00-2 2v3c0 .55.45 1 1 1s1-.45 1-1V6c0-.55.45-1 1-1h12c.55 0 1 .45 1 1v12c0 .55-.45 1-1 1H6c-.55 0-1-.45-1-1v-2c0-.55-.45-1-1-1s-1 .45-1 1v3c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
		/>
	</svg>
);

export default ExitIcon;

