import React from 'react';
import PropTypes from 'prop-types';

const dealerNumber = ({
  dealerCode, className,
}) => {
  const dealerCodeStr = String(dealerCode || 0).padStart(6, '0');

  return (
    <span className={className || null}>
      {
        (dealerCode)
          ? `${dealerCodeStr.slice(0, 5)}-${dealerCodeStr.slice(5)}`
          : null
      }
    </span>
  );
};

dealerNumber.propTypes = {
  className: PropTypes.string,
  dealerCode: PropTypes.number,
};

dealerNumber.defaultProps = {
  className: '',
  dealerCode: undefined,
};

export default dealerNumber;
