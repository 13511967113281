import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Select from 'features/common/select';

const selectInitialBrand = (setBrand, brand, brandList) => {
  if (!_.isNull(brand)) return brand;

  if (brandList.length >= 1) {
    setBrand(brandList[0].value, false);
    return brandList[0].value;
  }

  return null;
};

const BrandSelector = ({
  setBrand, brand, brandList, isLoading,
}) => (
  <Select
    items={brandList}
    placeholder="Selecionar brand"
    label="Brand"
    value={selectInitialBrand(setBrand, brand, brandList)}
    onSelect={(b) => {
      setBrand(b);
    }}
    disabled={isLoading || brandList.length === 1}
    defaultValue=""
  />
);

BrandSelector.propTypes = {
  setBrand: PropTypes.func,
  brand: PropTypes.string,
  brandList: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
};

BrandSelector.defaultProps = {
  setBrand: () => {},
  brand: null,
  brandList: null,
  isLoading: false,
};

export default BrandSelector;
