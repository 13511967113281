import React from 'react';
import PropTypes from 'prop-types';

import { formatCodigoConcessionaria, formatNomeConcessionaria } from 'utils/format';

import './concessionaria.scss';
import TooltipMensagem from 'features/common/tooltipMensagem';

const Concessionaria = ({ name, buc }) => (
  <div className="carteira-page__concessionaria">
    {formatCodigoConcessionaria(buc)}
    <TooltipMensagem title={formatNomeConcessionaria(name.trim())} maxWidth="400px">
      <span className="carteira-page__concessionaria__nome">
        {formatNomeConcessionaria(name.trim(), 2)}
      </span>
    </TooltipMensagem>
  </div>
);

Concessionaria.propTypes = {
  name: PropTypes.string,
  buc: PropTypes.number,
};

Concessionaria.defaultProps = {
  name: '',
  buc: null,
};

export default Concessionaria;
