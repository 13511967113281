import { connect } from 'react-redux';

import InputPagamento from './inputPagamento';
import operations from '../../../../redux/operations';

const mapDispatchToProps = (dispatch) => ({
  setValue: (value, invoiceId) => dispatch(operations.setValue(invoiceId, Number(value))),
});

export default connect(null, mapDispatchToProps)(InputPagamento);
