import { connect } from 'react-redux';

import { operations } from '../../redux/operations';

import PopoverContentMain from './popoverContentMain';

const mapStateToProps = ({ authStore }) => ({
  permissions: authStore.permissions,
});

const mapDispatchToProps = (dispatch) => ({
  setIsOpen: (isOpen) => dispatch(operations.setIsOpen(isOpen)),
  setContent: (content) => dispatch(operations.setContent(content)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PopoverContentMain);
