import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';

import {
  formatDate, camelFormat, safeConcat,
} from 'utils/format';

import { Tracking } from 'modules';

import './acompanhamentoListRow.scss';

const useStyles = makeStyles({
  container: {
    gridTemplateAreas: (isModalOpen) => (isModalOpen ? "' id data solicitante produto valor '"
      : "' id data solicitante produto tipo valor status '"),
    gridTemplateColumns: (isModalOpen) => (isModalOpen ? '4.5em 6em minmax(270px, 1fr) 5em minmax(50px, 0.5fr) '
      : '4.5em 6em minmax(270px, 3fr) 5em 11em 10em minmax(150px, 1fr)'),
  },
});

const AcompanhamentoListRow = ({
  solicitacao, getDetalhes, isOpenAside,
}) => {
  const classes = useStyles(isOpenAside);
  return (
    <div
      className={`acompanhamento__list-row__container ${classes.container}`}
      data-cy="AcompanhamentoListRowContainer"
      onClick={() => {
        getDetalhes(solicitacao?.id);
        Tracking.Mixpanel.acompanhamentoLimite.trackDetalheSolicitação();
      }}
      role="row"
      tabIndex={0}
    >
      <div className="acompanhamento__list-row__item acompanhamento__list-row__id">
        {safeConcat('#', solicitacao?.id)}
      </div>
      <div className="acompanhamento__list-row__item acompanhamento__list-row__data">
        {formatDate(solicitacao?.data, 'DD/MM/YYYY')}
      </div>
      <div className="acompanhamento__list-row__item acompanhamento__list-row__solicitante">
        {solicitacao?.solicitante.toLowerCase()}
      </div>
      <div className="acompanhamento__list-row__item acompanhamento__list-row__produto">
        {solicitacao?.produto}
      </div>
      {isOpenAside ? null : (
        <div className="acompanhamento__list-row__item acompanhamento__list-row__tipo">
          {solicitacao?.tipo}
        </div>
      )}
      <div className="acompanhamento__list-row__item acompanhamento__list-row__valor">
        {solicitacao?.valor.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })}
      </div>
      {isOpenAside ? null : (
        <div className="acompanhamento__list-row__item acompanhamento__list-row__status">
          {camelFormat(solicitacao?.status)}
        </div>
      )}
    </div>
  );
};

AcompanhamentoListRow.propTypes = {
  solicitacao: PropTypes.object,
  getDetalhes: PropTypes.func,
  isOpenAside: PropTypes.bool,
};

AcompanhamentoListRow.defaultProps = {
  solicitacao: null,
  getDetalhes: () => {},
  isOpenAside: false,
};

export default AcompanhamentoListRow;
