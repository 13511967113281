/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core';
import _ from 'lodash';

import { StyledTextField, textField as styles } from '../../inputStyles';

const useStyles = makeStyles(styles);

export const NumericInput = ({
  value, setValue, disabled, ...other
}) => {
  const classes = useStyles();
  return (
    <NumberFormat
      {...other}
      onValueChange={(event) => {
        setValue(_.isEmpty(event) ? null : event.value);
      }}
      customInput={StyledTextField}
      style={{ width: '100%', marginTop: '-30px' }}
      className={classes.root}
      value={value || ''}
      isNumericString
      displayType={disabled ? 'text' : 'input'}
    />
  );
};

NumericInput.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  disabled: PropTypes.bool,
};

NumericInput.defaultProps = {
  value: null,
  setValue: () => {},
  disabled: true,
};

export default NumericInput;
