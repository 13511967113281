import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '../input/textField';

const useStyles = makeStyles(() => ({
  input: {
    '&::placeholder': {
      opacity: 1,
    },
  },
  root: {
    height: '40px',
  },
  container: {
    marginTop: '1px',
  },
}));

const TextFilterDebounce = ({
  value, setValue, label, placeholder, showSearchIcon, disabled, dataCy, tracking,
}) => {
  const classes = useStyles();
  const [isTextFieldFocused, setIsTextFieldFocused] = useState(false);

  const textFieldRef = useRef(null);

  useEffect(() => {
    if (isTextFieldFocused) {
      textFieldRef.current.focus();
    }
  }, [isTextFieldFocused]);

  const handleTextFieldFocus = () => {
    setIsTextFieldFocused(true);
  };

  const handleTextFieldBlur = () => {
    setIsTextFieldFocused(false);
  };
  return (
    <TextField
      inputRef={textFieldRef}
      className={classes.container}
      InputProps={{
        startAdornment:
        showSearchIcon
          ? <SearchIcon style={{ fill: '#8f9bb3', color: '#8f9bb3', marginRight: '8px' }} />
          : null,
        classes: { input: classes.input, root: classes.root },
      }}
      label={label}
      placeholder={isTextFieldFocused ? '' : placeholder}
      value={value}
      onChange={(text) => {
        setValue(_.isEmpty(text) ? null : text);
        tracking();
      }}
      onFocus={handleTextFieldFocus}
      onBlur={handleTextFieldBlur}
      disabled={disabled}
      dataCy={dataCy}
    />
  );
};

TextFilterDebounce.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  showSearchIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  dataCy: PropTypes.string,
  tracking: PropTypes.func,
};

TextFilterDebounce.defaultProps = {
  value: '',
  setValue: () => {},
  label: null,
  placeholder: null,
  showSearchIcon: false,
  disabled: false,
  dataCy: null,
  tracking: () => {},
};

export default TextFilterDebounce;
