import { connect } from 'react-redux';

import MensagemRede from './mensagemRede';
import operations from './redux/operations';

const mapStateToProps = ({ mensagensRedeStore }) => ({
  mensagens: mensagensRedeStore?.mensagens,
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(operations.closeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MensagemRede);
