import React from 'react';
import PropTypes from 'prop-types';

import { Download } from 'react-feather';

import Button from 'features/common-staff/controls/button';
import { Tracking } from 'modules';

import './botaoDownload.scss';

const BotaoDownload = ({ documento }) => (
  <Button
    className="comunicados__botao-download"
    onClick={() => {
      Tracking.Mixpanel.centralComunicados.botoes.trackBotaoDownload();
      window.open(documento);
    }}
  >
    <span>Baixar arquivo</span>
    <Download style={{ marginLeft: '8px' }} size={18} />
  </Button>
);

BotaoDownload.propTypes = {
  documento: PropTypes.string,
};

BotaoDownload.defaultProps = {
  documento: '',
};

export default BotaoDownload;
