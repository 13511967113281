import { createGlobalStyle } from 'styled-components';

const Typography = createGlobalStyle`
  .font-head-2 {
    font-family: Encode Sans Expanded, sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 1.5px;
    color: white;
  }
`;

export default Typography;
