import { combineReducers } from 'redux';

import CarteiraPageReducer from '../views/carteira/redux/reducer';
import ComprovantePageReducer from '../views/comprovante/redux/reducer';
import PagamentoPageReducer from '../views/pagamento/redux/reducer';

export default combineReducers({
  carteira: CarteiraPageReducer,
  comprovante: ComprovantePageReducer,
  pagamento: PagamentoPageReducer,
});
