import axios from 'axios';

class ProductService {
  constructor() {
    this.URL_API_TD = `${window.env.REACT_APP_API_URL}`;
  }

  async getProducts() {
    const response = await axios.get(`${this.URL_API_TD}/produtos`);

    return response.data;
  }
}

export default new ProductService();
