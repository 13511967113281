import { connect } from 'react-redux';

import { IofSimulatorSelectors, IofSimulatorOperations } from '../../redux';

import IofDealerGroupSelector from './iofDealerGroupSelector';

const mapStateToProps = ({ dealerStore, iofSimulatorStore }) => ({
  dealerGroups: IofSimulatorSelectors.getDealerGroups(dealerStore),
  dealerGroup: iofSimulatorStore.dealerGroup,
  loading: iofSimulatorStore.loading,
});

const mapDispatchToProps = (dispatch) => ({
  setDealerGroup: (group) => dispatch(IofSimulatorOperations.setDealerGroup(group)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IofDealerGroupSelector);
