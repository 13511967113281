import moment from 'moment';

export const getDateFromEvent = (date) => {
  const dateFormat = moment(date, 'DD/MM/YYYY', true);
  return dateFormat.isValid() ? dateFormat : undefined;
};

export const getDate = (date) => (
  date ? date.format('YYYY-MM-DD') : null
);

export const compareFilters = (local, context) => ({
  filled: context.some((value) => !!value),
  diff: local.some((value, i) => value !== context[i]),
  localFilled: local.some((value) => !!value),
});
