import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MarcaBadge from 'features/common-staff/views/marcaBadge';
import ProdutoBadge from 'features/common-staff/views/produtoBadge';
import {
  formatMvs, safeConcat, formatDate, formatDescVeiculo,
  formatCodigoConcessionaria, formatNomeConcessionaria,
  capitalize,
} from 'utils/format';
import DataGrid from 'features/common/dataGrid/dataGrid';

import { Tracking } from 'modules';

import './acompanhamentoList.scss';

const renderVeiculo = (param) => {
  const descricao = capitalize(formatDescVeiculo(param?.row?.descricao));
  const nomeVeiculo = safeConcat(descricao, ` • ${param?.row?.modelYear}`);
  return (
    <div
      title={nomeVeiculo}
      className="pedidos__acompanhamento-list__container__list__veiculo"
    >
      <div>
        <MarcaBadge marca={param?.row?.marca} />
      </div>
      <div>
        <div className="pedidos__acompanhamento-list__container__list__veiculo__mvs">
          {formatMvs(param?.row?.modelo, param?.row?.versao, param?.row?.serie)}
        </div>
        <div className="pedidos__acompanhamento-list__container__list__veiculo__nome">
          {nomeVeiculo}
        </div>
      </div>
    </div>
  );
};

const renderConcessionaria = (param) => {
  const nomeConcessionaria = formatNomeConcessionaria(param?.row?.concessionariaNome);
  return (
    <div title={nomeConcessionaria}>
      <div className="pedidos__acompanhamento-list__container__list__codigo">
        {formatCodigoConcessionaria(param?.row?.corretorId)}
      </div>
      <div
        className="pedidos__acompanhamento-list__container__list__concessionaria"
      >
        {nomeConcessionaria}
      </div>
    </div>
  );
};

const AcompanhamentoList = ({
  pedidos, isLoading, isError, page, ipp, setIpp,
  setPage, totalItems, onRowClicked, isModalOpen,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const columns = [
    {
      field: 'pedidoId',
      headerName: 'ID',
      editable: false,
      sortable: false,
      flex: 0.3,
      minWidth: 29,
      align: 'left',
      valueFormatter: (param) => safeConcat('#', param?.value),
    },
    {
      field: 'data',
      headerName: 'DATA',
      editable: false,
      sortable: false,
      flex: 0.3,
      minWidth: 80,
      align: 'left',
      valueFormatter: (param) => formatDate(param?.value, 'DD/MM/YYYY'),
    },
    {
      field: 'marca',
      headerName: 'VEÍCULO',
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 300,
      align: 'left',
      renderCell: (param) => renderVeiculo(param),
    },
    {
      field: 'concessionariaNome',
      headerName: 'CONCESSIONÁRIA',
      editable: false,
      sortable: false,
      flex: 0.5,
      minWidth: 120,
      align: 'left',
      hide: isModalOpen,
      renderCell: (param) => renderConcessionaria(param),
    },
    {
      field: 'isAVista',
      headerName: 'MODALIDADE',
      editable: false,
      sortable: false,
      flex: 0.5,
      minWidth: 90,
      align: 'left',
      valueFormatter: (param) => (param?.value ? 'À Vista' : 'Financiado'),
    },
    {
      field: 'produto',
      headerName: 'PRODUTO',
      editable: false,
      sortable: false,
      flex: 0.5,
      minWidth: 90,
      align: 'left',
      renderCell: (param) => <ProdutoBadge produto={param?.row?.produto} />,
    },
    {
      field: 'status',
      headerName: 'STATUS',
      editable: false,
      sortable: false,
      flex: 0.5,
      minWidth: 120,
      align: 'left',
      hide: isModalOpen,
    },
  ];

  return (
    <div
      className={`${isModalOpen
        ? 'pedidos__acompanhamento-list__container__open'
        : 'pedidos__acompanhamento-list__container'}`}
    >
      <DataGrid
        error={isError}
        loading={isLoading}
        columns={columns}
        rows={pedidos}
        getRowId={(row) => row.pedidoId}
        onRowClick={(param) => {
          Tracking.Mixpanel.acompanhamentoTd.trackClickDetalhe();
          onRowClicked(param.row.pedidoId);
        }}
        overlay={{
          emptyMessage: 'Nenhum pedido encontrado.',
          errorMessage: 'Ocorreu um erro ao carregar os pedidos.',
        }}
        cellPadding={20}
        onSelectionModelChange={
          (selectionModel) => setSelectedRows(selectionModel)
        }
        selectionModel={isModalOpen ? selectedRows : []}
        footer={{
          ipp,
          page,
          totalItems,
          setIpp,
          setPageFetch: setPage,
        }}
      />

    </div>
  );
};

AcompanhamentoList.propTypes = {
  pedidos: PropTypes.array,
  onRowClicked: PropTypes.func,
  isModalOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  page: PropTypes.number,
  ipp: PropTypes.number,
  totalItems: PropTypes.number,
  setPage: PropTypes.func.isRequired,
  setIpp: PropTypes.func,
};

AcompanhamentoList.defaultProps = {
  pedidos: null,
  onRowClicked: () => { },
  isModalOpen: false,
  isLoading: false,
  isError: false,
  page: null,
  ipp: null,
  totalItems: null,
  setIpp: () => {},
};

export default AcompanhamentoList;
