import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Page, PageHeader, PageTitle, PageContent,
} from 'features/common-staff/layout/page';
import SnackbarList from 'features/common-staff/snackbarList';
import List, { ListContent } from '../../../common-staff/layout/list';

import MenuInternetBanking from '../../../menu/view/menuInternetBanking';
import ResumoCard from './views/resumoCard';
import BackButton from './views/backButton';
import ExtratoButton from './views/extratoButton';
import ComprovanteList from './views/comprovanteList';
import PrintButton from './views/printButton';

import './comprovantePage.scss';

const ComprovantePage = ({
  resetStore, snackbarErrors, isLoading,
  onCloseSnackBar, getComprovante, isError,
  idMovimentacao, navTo,
}) => {
  useEffect(() => {
    if (!idMovimentacao) {
      navTo();
    } else {
      getComprovante();
    }
    return () => resetStore();
  }, [resetStore, getComprovante, navTo, idMovimentacao]);
  return (
    <Page>
      <div className="comprovante-page">
        <section className="comprovante-page_menu no-print">
          <MenuInternetBanking />
        </section>

        <section className="comprovante-page_content">
          <Page
            minWidth="fit-content"
          >
            <PageHeader>
              <PageTitle>
                <div className="comprovante-page__header no-print">
                  <div className="comprovante-page__header__title no-print">
                    Solicitação de pagamento realizada com sucesso
                  </div>
                  <div className="comprovante-page__header__button no-print">
                    <BackButton />
                  </div>
                </div>
              </PageTitle>
            </PageHeader>
            <PageContent>
              <List
                isLoading={isLoading}
                isError={isError}
                disabledScrolls
              >
                <ListContent>
                  <div className="comprovante-page__body">
                    <div className="comprovante-page__body__list no-print">
                      Abaixo você tem acesso a todas as transações necessárias para efeturar
                      o pagamento das duplicatas selecionadas. Você poderá acompanhar os pagamentos
                      solicitados e consultar novamente o comprovante de pagamento pela
                      tela de movimentações.
                      <ComprovanteList />
                    </div>
                    <div className="comprovante-page__body__resumo">
                      <ResumoCard />
                      <div className="comprovante-page__body__resumo-buttons">
                        <div className="comprovante-page__body__resumo-buttons__extrato">
                          <ExtratoButton />
                        </div>
                        <div className="comprovante-page__body__resumo-buttons__print">
                          <PrintButton />
                        </div>
                      </div>
                    </div>
                  </div>
                </ListContent>
                <ListContent type="error">
                  <div className="comprovante-page__body__erro">
                    Erro ao buscar comprovante.
                    <span>Acesse a aba de movimentações</span>
                  </div>
                </ListContent>
              </List>
            </PageContent>
            <SnackbarList
              snackbarErrors={snackbarErrors}
              onClose={(id) => onCloseSnackBar(id)}
            />
          </Page>
        </section>
      </div>
    </Page>
  );
};

ComprovantePage.propTypes = {
  resetStore: PropTypes.func,
  snackbarErrors: PropTypes.array,
  onCloseSnackBar: PropTypes.func,
  getComprovante: PropTypes.func,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  idMovimentacao: PropTypes.string,
  navTo: PropTypes.func,
};

ComprovantePage.defaultProps = {
  resetStore: () => {},
  snackbarErrors: [],
  onCloseSnackBar: () => {},
  getComprovante: () => {},
  isLoading: false,
  isError: false,
  idMovimentacao: null,
  navTo: () => {},
};

export default ComprovantePage;
