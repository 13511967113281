import * as iofSimulatorActions from './iofSimulatorActions';
import iofSimulatorService from '../iofSimulatorService';

export const setDealerGroup = (dealerGroup) => (dispatch) => {
  dispatch(iofSimulatorActions.setDealerGroup(dealerGroup));
};

export const getFinancialValues = (
  dealerGroup,
) => async (dispatch) => {
  try {
    dispatch(iofSimulatorActions.setFinancialValues(undefined));
    dispatch(iofSimulatorActions.startRequest());

    const serviceResponse = await iofSimulatorService.getFinancialValues(
      dealerGroup,
    );
    const {
      transmissionTotal, paymentsTotal, lastUpdate, isUpdated,
    } = serviceResponse.data;

    dispatch(iofSimulatorActions.setFinancialValues(
      transmissionTotal || 0.0,
      paymentsTotal || 0.0,
      lastUpdate,
    ));
    dispatch(iofSimulatorActions.setIsUpdated(isUpdated));
    dispatch(iofSimulatorActions.setSelectedValue(0.0));
    dispatch(iofSimulatorActions.setLoadError(false));
    dispatch(iofSimulatorActions.finishRequest());
  } catch (error) {
    await dispatch(iofSimulatorActions.setFinancialValues([], true));
    dispatch(iofSimulatorActions.setLoadError(true));
    dispatch(iofSimulatorActions.finishRequest());
  }
};

export const getInvoicesWithBilling = (
  dealerGroup,
  page,
  itensPerPage = 15,
) => async (dispatch, getState) => {
  try {
    dispatch(iofSimulatorActions.setInvoices([], true));
    dispatch(iofSimulatorActions.setSelectedValue(undefined));
    dispatch(iofSimulatorActions.startRequest());

    const { filters } = getState().iofSimulatorStore;
    const serviceResponse = await iofSimulatorService.getInvoicesWithBilling(
      dealerGroup,
      page,
      itensPerPage,
      filters,
    );
    const invoices = serviceResponse.data.value;
    dispatch(iofSimulatorActions.setInvoices(
      invoices || [],
      invoices.length !== 0,
    ));
    dispatch(iofSimulatorActions.setSelectedValue(0.0));

    dispatch(iofSimulatorActions.setLoadError(false));
    dispatch(iofSimulatorActions.finishRequest());
  } catch (error) {
    await dispatch(iofSimulatorActions.setInvoices([], true));
    dispatch(iofSimulatorActions.setLoadError(true));
    dispatch(iofSimulatorActions.finishRequest());
  }
};

export const loadNewPage = (dealerGroup, page, itensPerPage = 15) => async (dispatch, getState) => {
  try {
    await dispatch(iofSimulatorActions.startRequest());

    const { filters } = getState().iofSimulatorStore;
    const serviceResponse = await iofSimulatorService.getInvoicesWithBilling(
      dealerGroup,
      page,
      itensPerPage,
      filters,
    );

    const hasMoreInvoices = serviceResponse.data.value.length !== 0;
    dispatch(iofSimulatorActions.concatInvoices(
      serviceResponse.data.value,
      hasMoreInvoices,
      page,
    ));

    dispatch(iofSimulatorActions.finishRequest());
  } catch (error) {
    dispatch(iofSimulatorActions.concatInvoices([], true, page - 1));
    dispatch(iofSimulatorActions.finishRequest());
  }
};

export const resetStore = () => (dispatch) => {
  dispatch(iofSimulatorActions.resetStore());
};

export const setStartDate = (startDate) => (dispatch) => {
  dispatch(iofSimulatorActions.setStartDate(startDate));
};

export const setEndDate = (endDate) => (dispatch, getState) => {
  dispatch(iofSimulatorActions.setEndDate(endDate));
  const { dealerGroup, page } = getState().iofSimulatorStore;
  dispatch(getInvoicesWithBilling(dealerGroup, page));
};
