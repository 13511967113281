import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';

import './loadMoreButton.scss';

const loadMoreButton = (
  {
    t, message, hasMoreItens, noItemsMessage, loading, onClick, white,
  },
) => (
  <div className="load-more-btn">
    {
      hasMoreItens
        ? (
          <button
            type="button"
            id={white ? 'load-more-btn-btn' : 'load-more-btn-green-btn'}
            onClick={onClick}
            disabled={loading}
          >
            {
              loading
                ? (
                  <CircularProgress color="inherit" size="20px" />
                )
                : (
                  <span>{message || t('Carregar mais')}</span>
                )
            }
          </button>
        )
        : (
          <span id="load-more-btn-finalmsg">
            {noItemsMessage}
          </span>
        )
    }
  </div>
);

loadMoreButton.propTypes = {
  t: PropTypes.func.isRequired,
  message: PropTypes.string,
  noItemsMessage: PropTypes.string,
  hasMoreItens: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  white: PropTypes.bool,
};

loadMoreButton.defaultProps = {
  message: null,
  noItemsMessage: '',
  hasMoreItens: true,
  loading: false,
  white: false,
};

export default withNamespaces()(loadMoreButton);
