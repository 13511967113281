import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import UploudFileIcon from 'assets/icons/upload-file';
import { Modal, Button } from '../../../../../common';

import ListFiles from './listFiles';
import DropDivUpload from './dropDivUpload';

import './modalUpload.scss';

const useStyles = makeStyles({
  button: {
    fontSize: '14px',
    width: '150px',
    height: '40px',
  },
});

const ModalUpload = ({
  closeModalUpload, isUploading, selectedFiles, sendFilestoForm, isError, filesToSend,
}) => {
  const classes = useStyles();
  const maxFiles = 5;
  const disabled = selectedFiles.length >= maxFiles;
  const isInsertEnabled = selectedFiles.length > 0;
  return (
    <Modal
      disableCloseButton={isUploading}
      closeModal={() => { closeModalUpload(selectedFiles); }}
    >
      <div className="test-drive__modal__container">
        <div
          className="test-drive__modal__container__header"
          data-cy="TestDriveModalContainerHeader"
        >
          <div className="test-drive__modal__container__header_title">
            Anexar Documentos
          </div>
          <div className="test-drive__modal__container__header_subtitle">
            {`Os arquivos podem ser somente PDF (limite de ${maxFiles} arquivos)`}
          </div>
        </div>

        <div className="test-drive__modal__container__content">
          <div className={`test-drive__modal__container__content-upload
             ${disabled ? 'test-drive__upload_disabled'
            : 'test-drive__upload_enbaled'}
            `}
          >
            <DropDivUpload disabled={disabled}>
              <div className="test-drive__modal__container__content-upload_icon">
                <UploudFileIcon />
              </div>
              <div className="test-drive__modal__container__content-upload_title">
                Arraste e solte os arquivos
              </div>
              <div className="test-drive__modal__container__content-upload_subtitle">
                ou clique e selecione um arquivo do seu computador.
              </div>
            </DropDivUpload>
          </div>
          <div className="test-drive__modal__container__content_preview">
            { (selectedFiles || []).map((f, i) => (
              <ListFiles file={f} key={i} />
            ))}
            {(filesToSend || []).map((f, i) => (
              <ListFiles file={f} key={i} />
            ))}
          </div>
        </div>
        <div className="test-drive__modal__container__footer">
          {isInsertEnabled
            ? (
              <Button
                className={classes.button}
                onClick={() => { sendFilestoForm(); }}
                disabled={isUploading || isError}
              >
                Inserir Arquivos
              </Button>
            ) : null}
        </div>
      </div>
    </Modal>
  );
};

ModalUpload.propTypes = {
  closeModalUpload: PropTypes.func,
  isUploading: PropTypes.bool,
  selectedFiles: PropTypes.array,
  sendFilestoForm: PropTypes.func,
  isError: PropTypes.bool,
  filesToSend: PropTypes.array,
};

ModalUpload.defaultProps = {
  closeModalUpload: () => { },
  isUploading: false,
  selectedFiles: null,
  sendFilestoForm: () => { },
  isError: false,
  filesToSend: [],
};

export default ModalUpload;
