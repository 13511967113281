import styled from 'styled-components';

export const PageContainer = styled.div`
  display: grid;
  height: 100vh;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "menu content";
`;

export const MenuPageContainer = styled.div`
  grid-area: menu;
  width: 248px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
`;

export const PageContent = styled.div`
  grid-area: content;
  overflow-y: auto;
  overflow-x: auto;
`;

export default null;
