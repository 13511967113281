import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

import { FormControl, RadioGroup } from '@material-ui/core';
import CircleAlertYellowIcon from 'assets/icons/circle-alert-yellow';

import RadioButton from 'features/common/radioButton/radioButton';
import ButtonTooltipIcon from 'features/common/buttonTooltipIcon';
import SummaryPage from 'features/common/summaryPage';
import CardCondicoesStyle from './cardCondicoes.style';

const CardCondicoes = ({
  disabled, value, setValue, showAlert, textCardReceive, showTooltip, open, handleOpenChange,
}) => {
  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  const renderTooltip = (
    <ButtonTooltipIcon title="As condições da aprovação
    foram alteradas."
    >
      <CircleAlertYellowIcon width="14" height="14" className="circle-alert" />
    </ButtonTooltipIcon>
  );

  return (
    <CardCondicoesStyle showAlert={showAlert} showTooltip={showTooltip}>
      <SummaryPage level={3} title="Condições" open={open} handleOpenChange={handleOpenChange} IconTitle={showTooltip ? renderTooltip : null}>
        <div className="condicao-container">
          <div className="condicao-text">{parse(textCardReceive)}</div>
        </div>
        <div className="condicoes-footer">
          <FormControl>
            <RadioGroup
              value={value}
              onChange={handleRadioChange}
            >
              <RadioButton
                value="accept"
                disabled={disabled}
                dataCy="aceita-condicao"
              />
            </RadioGroup>
          </FormControl>
          <span className="text-footer">Declaro que li e estou ciente das condições.</span>
          {showAlert && <CircleAlertYellowIcon width="13" height="13" />}
        </div>
      </SummaryPage>
    </CardCondicoesStyle>
  );
};

CardCondicoes.propTypes = {
  disabled: PropTypes.bool,
  showAlert: PropTypes.bool,
  value: PropTypes.string,
  textCardReceive: PropTypes.string,
  setValue: PropTypes.func,
  showTooltip: PropTypes.bool,
  open: PropTypes.bool,
  handleOpenChange: PropTypes.func,
};

CardCondicoes.defaultProps = {
  disabled: false,
  showAlert: false,
  value: '',
  textCardReceive: '',
  setValue: () => { },
  showTooltip: false,
  open: false,
  handleOpenChange: () => { },
};
export default CardCondicoes;
