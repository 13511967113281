import React from 'react';
import PropTypes from 'prop-types';

import { formatCodigoConcessionaria, formatNomeConcessionaria } from 'utils/format';

import './concessionaria.scss';

const Concessionaria = ({ name, buc }) => (
  <div className="carteira-page__concessionaria">
    {formatCodigoConcessionaria(buc)}
    <span className="carteira-page__concessionaria__nome">
      {formatNomeConcessionaria(name.trim(), 2)}
    </span>
  </div>
);

Concessionaria.propTypes = {
  name: PropTypes.string,
  buc: PropTypes.number,
};

Concessionaria.defaultProps = {
  name: '',
  buc: null,
};

export default Concessionaria;
