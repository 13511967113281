import { connect } from 'react-redux';
import AbsolutosDuplicatasArea from './absolutosDuplicatasArea';

import selectors from '../../redux/selectors';

const mapStateToProps = ({ dashboard }) => ({
  isLoading: dashboard.painelFloorPlan.quantidadeDuplicatas.isLoading,
  isError: dashboard.painelFloorPlan.quantidadeDuplicatas.isError,
  isEmpty: selectors.isEmptyQuantidadeDuplicata(dashboard),
});

export default connect(mapStateToProps, null)(AbsolutosDuplicatasArea);
