import logger from 'utils/logger';
import { PATH_SOLICITACAO_CREDITO } from 'routes/paths';

import { Tracking } from 'modules';
import * as actions from './actions';
import service from './service';

export const submitSolicitacao = () => async (dispatch, getState) => {
  try {
    Tracking.Mixpanel.alterarLimite.trackSubmit();

    const { user } = getState().authStore;
    const { dealers } = getState().dealerStore;
    const dadosSolicitacao = getState().limiteCreditoStore.alteracaoLimite.dados;

    dispatch(actions.postSolicitacaoLimiteStart());

    const dto = service.makeSolicitacaoDto(user, dadosSolicitacao, dealers);
    await service.postPedidoAnaliseCredito(dto);

    dispatch(actions.postSolicitacaoLimiteSuccess());
    window.location.assign(`${PATH_SOLICITACAO_CREDITO}/listagem`);
    return true;
  } catch (error) {
    logger.error(error);
    dispatch(actions.postSolicitacaoLimiteError());
    Tracking.Mixpanel.alterarLimite.trackSubmitError();
    return false;
  }
};

export const getProdutos = () => async (dispatch, getState) => {
  try {
    dispatch(actions.getProdutosStart());
    let { produtos } = getState().limiteCreditoStore.alteracaoLimite.produtos;
    if (produtos === null) produtos = await service.getProdutos();
    dispatch(actions.getProdutosSuccess(produtos));
  } catch (error) {
    logger.error(error);
    dispatch(actions.getProdutosError());
  }
};

export const setProduto = (produto) => (dispatch) => {
  dispatch(actions.setProduto(produto));
};

export const setSelectedDealer = (dealer) => (dispatch) => {
  dispatch(actions.setSelectedDealer(dealer));
};

export const setMotivo = (motivo) => (dispatch) => {
  dispatch(actions.setMotivo(motivo));
};

export const setCodigoSap = (sap) => (dispatch) => {
  dispatch(actions.setCodigoSap(sap));
};

export const setBrand = (brand) => (dispatch) => {
  dispatch(actions.setBrand(brand));
};

export const setValor = (valor) => (dispatch) => {
  dispatch(actions.setValor(valor));
};

export const setIsPositivo = (isPositivo) => (dispatch) => {
  dispatch(actions.setIsPositivo(isPositivo));
};

export const dismissSnackbar = (id) => (dispatch) => {
  dispatch(actions.dismissSnackbar(id));
};

export const resetStore = () => (dispatch) => {
  dispatch(actions.resetStore());
};

export default {
  dismissSnackbar,
  setSelectedDealer,
  setMotivo,
  setValor,
  setIsPositivo,
  setCodigoSap,
  setBrand,
  resetStore,
};
