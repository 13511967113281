import styled from 'styled-components';
import colors from '../../../assets/styles/colors';

const validateBackgroundColor = (status) => {
  if (status === 'recusado') {
    return 'rgba(237, 92, 111, 0.16);';
  }
  if (status === 'aprovado') {
    return colors.success_color_100_48;
  }
  return 'rgba(0, 99, 247, 0.08);';
};

const validateTextColor = (status) => {
  if (status === 'recusado') {
    return colors.error_color_300;
  }
  if (status === 'aprovado') {
    return colors.success_color_300;
  }
  return colors.process_color_300;
};

const StatusComponent = styled.span`
background: ${(props) => validateBackgroundColor(props.status)};
color: ${(props) => validateTextColor(props.status)};
  font-family: 'Circular Std';
  font-weight: 450;
  font-size: 12px;
  border-radius: 4px;
  padding: 1px 4px 1px 4px;
  width: fit-content;
`;

export default StatusComponent;
