import React from 'react';
import PropTypes from 'prop-types';

import PaperclipIcon from 'assets/icons/paperclip';
import { ButtonIcon } from '../../../../../common';

const UploadFiles = ({
  openModalUpload,
}) => (
  <ButtonIcon
    onClick={() => openModalUpload()}
  >
    <PaperclipIcon />
  </ButtonIcon>
);

UploadFiles.propTypes = {
  openModalUpload: PropTypes.func,
};

UploadFiles.defaultProps = {
  openModalUpload: () => {},
};

export default UploadFiles;
