import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import 'moment/locale/pt-br';

import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import colors from 'assets/styles/colors';
import DefaultCar from '../../../../../assets/imgs/default-car.png';

import './offerCard.scss';

const useStyles = makeStyles({
  rootSelected: {
    height: '250px',
    borderRadius: '6px',
    border: `solid 2px ${colors.primary_color_600}`,
    backgroundColor: colors.primary_color_100_16,
    boxShadow: 'none',
    padding: '8px',
    cursor: 'pointer',
  },
  rootUnselected: {
    boxShadow: 'none',
    height: '250px',
    borderRadius: '6px',
    border: 'solid 2px #dbdbdb',
    padding: '8px',
    cursor: 'pointer',
  },
  title: {
    height: '42px',
    alignSelf: 'center',
    flexShrink: '0',
    fontWeight: '900',
    fontSize: '14px',
    lineHeight: '1.5',
    flexFlow: 'row',
    letterSpacing: '0.15px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  description: {
    height: '16px',
    display: 'flex',
    flexFlow: 'column',
    fontSize: '10px',
    fontWeight: '500',
    lineHeight: '1.6',
    letterSpacing: '1px',
    color: 'rgba(0, 0, 0, 0.3)',
  },
  date: {
    height: '24px',
    fontSize: '14px',
    display: 'flex',
    flexFlow: 'column',
    fontWeight: '500',
    lineHeight: '1.71',
    letterSpacing: '0.1px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
});

const OfferCard = ({
  offer, selectedOfferId, displayDuration, onClick,
}) => {
  const classes = useStyles();

  return (
    <div key={offer.id}>
      <Card
        className={(selectedOfferId === offer.id)
          ? classes.rootSelected : classes.rootUnselected}
        key={offer.id}
        value={offer.id}
      >
        <div onClick={() => onClick(offer.id)} className="td-cond-selector-card-content" role="button" tabIndex="0">
          <Typography className={classes.title}>
            {`${offer.modelDescription} ${offer.versionDescription} ${offer.serieDescription}`}
          </Typography>
          <div className="td-cond-selector-img-text-container">
            <div className="td-cond-selector-card-image">
              <img src={(offer.imageUrl || DefaultCar)} alt="" />
            </div>
            <div className="td-cond-selector-text-container">
              { displayDuration ? (
                <div className="td-cond-selector-text-container-content">
                  <Typography className={classes.description}>
                    VÁLIDO POR:
                  </Typography>
                  <Typography className={classes.date}>
                    {
                      Math.ceil(moment(offer.endDate).diff(new Date(), 'days', true))
                    }
                    &nbsp;
                    dias
                  </Typography>
                </div>
              ) : null}

            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

OfferCard.propTypes = {
  offer: PropTypes.object.isRequired,
  selectedOfferId: PropTypes.number,
  displayDuration: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

OfferCard.defaultProps = {
  selectedOfferId: null,
};

export default OfferCard;
