import { SaldoInfo } from '../service/saldoInfo';

const SET_BALANCE = 'balance/SET_BALANCE';
const TOGGLE_VISIBILITY = 'balance/TOGGLE_VISIBILITY';

const LOAD_SALDO_START = 'saldo/LOAD_SALDO_START';
const LOAD_SALDO_SUCCESS = 'saldo/LOAD_SALDO_SUCCESS';
const LOAD_SALDO_ERROR = 'saldo/LOAD_SALDO_ERROR';

const setBalance = (blockedBalance, remainingBalance, lastUpdate = null) => ({
  type: SET_BALANCE,
  payload: {
    blockedBalance,
    remainingBalance,
    lastUpdate,
  },
});

const loadSaldoStart = () => ({
  type: LOAD_SALDO_START,
  payload: null,
});

const loadSaldoSuccess = (saldoInfo) => {
  if (!(saldoInfo instanceof SaldoInfo)) {
    throw new Error('loadSaldoSuccess: argument must be instance of SaldoInfo');
  }

  return {
    type: LOAD_SALDO_SUCCESS,
    payload: { saldoInfo },
  };
};

const loadSaldoError = () => ({
  type: LOAD_SALDO_ERROR,
  payload: null,
});

const toggleVisibility = () => ({
  type: TOGGLE_VISIBILITY,
});

export const actions = {
  types: {
    SET_BALANCE,
    TOGGLE_VISIBILITY,
    LOAD_SALDO_START,
    LOAD_SALDO_SUCCESS,
    LOAD_SALDO_ERROR,
  },

  setBalance,
  toggleVisibility,

  loadSaldoStart,
  loadSaldoSuccess,
  loadSaldoError,
};
