import logger from 'utils/logger';
import { PATH_SOLICITACAO_CREDITO } from 'routes/paths';

import { Tracking } from 'modules';
import * as actions from './actions';
import service from './service';

export const submitSolicitacao = () => async (dispatch, getState) => {
  try {
    Tracking.Mixpanel.transferirLimite.trackSubmit();

    const { user } = getState().authStore;
    const { dealers } = getState().dealerStore;
    const dadosSolicitacao = getState().limiteCreditoStore.transferenciaLimite;

    dispatch(actions.postSolicitacaoLimiteStart());

    const dto = service.makeSolicitacaoDto(user, dadosSolicitacao, dealers);
    await service.postPedidoAnaliseCredito(dto);

    dispatch(actions.postSolicitacaoLimiteSuccess());
    dispatch(actions.setModalOpen(false));
    window.location.assign(`${PATH_SOLICITACAO_CREDITO}/listagem`);
  } catch (error) {
    logger.error(error);
    dispatch(actions.postSolicitacaoLimiteError());
    Tracking.Mixpanel.transferirLimite.trackSubmitError();
  }
};

export const getProdutos = () => async (dispatch, getState) => {
  try {
    let { produtos } = getState().limiteCreditoStore.transferenciaLimite.produtos;
    if (produtos === null) {
      dispatch(actions.getProdutosStart());
      produtos = await service.getProdutos();
      dispatch(actions.getProdutosSuccess(produtos));
    }
  } catch (error) {
    logger.error(error);
    dispatch(actions.getProdutosError());
  }
};

export const setProdutoOrigem = (produto) => (dispatch) => {
  dispatch(actions.setProdutoOrigem(produto));
};

export const setSelectedDealerOrigem = (dealer) => (dispatch) => {
  dispatch(actions.setSelectedDealerOrigem(dealer));
};

export const setCodigoSapOrigem = (sap) => (dispatch) => {
  dispatch(actions.setCodigoSapOrigem(sap));
};

export const setBrandOrigem = (brand) => (dispatch) => {
  dispatch(actions.setBrandOrigem(brand));
};

export const setProdutoDestino = (produto) => (dispatch) => {
  dispatch(actions.setProdutoDestino(produto));
};

export const setSelectedDealerDestino = (dealer) => (dispatch) => {
  dispatch(actions.setSelectedDealerDestino(dealer));
};

export const setCodigoSapDestino = (sap) => (dispatch) => {
  dispatch(actions.setCodigoSapDestino(sap));
};

export const setBrandDestino = (brand) => (dispatch) => {
  dispatch(actions.setBrandDestino(brand));
};

export const setValor = (valor) => (dispatch) => {
  dispatch(actions.setValor(valor));
};

export const dismissSnackbar = (id) => (dispatch) => {
  dispatch(actions.dismissSnackbar(id));
};

export const resetStore = () => (dispatch) => {
  dispatch(actions.resetStore());
};

export const closeModal = () => (dispatch) => {
  dispatch(actions.setModalOpen(false));
};

export const openModal = () => (dispatch) => {
  dispatch(actions.setModalOpen(true));
};

export default {
  setSelectedDealerOrigem,
  setCodigoSapOrigem,
  setProdutoOrigem,
  setBrandOrigem,
  setSelectedDealerDestino,
  setCodigoSapDestino,
  setProdutoDestino,
  setBrandDestino,
  dismissSnackbar,
  setValor,
  resetStore,
  closeModal,
  openModal,
};
