import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Modal from 'features/common-staff/layout/modal';
import Loading from 'features/common-staff/layout/loading';
import AlertaMensagem from 'features/common/alertaMensagem';
import Error from 'features/common-staff/layout/error';
import TabelaHistorico from './views/tabelaHistorico';

import './modalHistoricoDuplicata.scss';

const ModalHistoricoDuplicata = ({
  closeModal, resetStore, isLoading,
  isError, historico, getHistorico,
}) => {
  useEffect(() => {
    getHistorico();
    return () => resetStore();
  }, [getHistorico, resetStore]);

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="modal-historico__container__mensagem">
          <Loading
            mensagem="Estamos buscando o histórico. Aguarde um instante..."
          />
        </div>
      );
    }
    if (isError) {
      return (
        <div className="modal-historico__container__mensagem">
          <Error
            mensagem="Não foi possível encontrar o histórico."
            subMensagem="Tente novamente em alguns instântes."
          />
        </div>
      );
    }
    if (_.isNull(historico) || _.isUndefined(historico) || _.isEmpty(historico)) {
      return (
        <div className="modal-historico__container__mensagem">
          <AlertaMensagem
            mensagem="Não há histórico disponível para essa duplicata."
            subMensagem="Não foram encontrados registros de pagamentos."
          />
        </div>
      );
    }

    return (
      <TabelaHistorico
        historicos={historico}
      />
    );
  };

  return (
    <Modal
      width="68%"
      height="fit-content"
      closeModal={closeModal}
      zIndex="2000"
    >
      <div className="modal-historico__container">
        <div className="modal-historico__container__titulo">
          Detalhamento do histórico da duplicata
        </div>
        <div className="modal-historico__container__historico">
          {renderContent()}
        </div>
      </div>

    </Modal>
  );
};

ModalHistoricoDuplicata.propTypes = {
  closeModal: PropTypes.func,
  getHistorico: PropTypes.func,
  resetStore: PropTypes.func,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  historico: PropTypes.arrayOf(PropTypes.object),
};

ModalHistoricoDuplicata.defaultProps = {
  closeModal: () => {},
  getHistorico: () => {},
  resetStore: () => {},
  isLoading: false,
  isError: false,
  historico: null,
};

export default ModalHistoricoDuplicata;
