import * as React from "react";

function FaturaIcon(props) {
	return (
		<svg
			width="1em"
			height="1em"
			viewBox="0 0 16 22"
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M15.427.552a.953.953 0 00-1.052.162l-1.362 1.228L11.16.255a.968.968 0 00-1.308 0L8 1.94 6.148.255a.968.968 0 00-1.309 0L2.986 1.942 1.622.714A.963.963 0 00.246.797c-.16.183-.248.42-.246.666V19.87a.985.985 0 00.97.999.966.966 0 00.655-.251l1.362-1.228L4.84 21.08c.372.34.936.34 1.308 0L8 19.393l1.852 1.685c.372.34.936.34 1.309 0l1.853-1.687 1.364 1.228a.963.963 0 001.376-.082c.16-.184.248-.421.246-.666V1.462a.984.984 0 00-.573-.911zm-1.764 18.091a.967.967 0 00-1.304.003l-1.853 1.688-1.852-1.686a.968.968 0 00-1.308 0l-1.852 1.686-1.854-1.688a.962.962 0 00-1.3-.003L.982 19.87v-2.663L.977 1.463l1.36 1.227a.967.967 0 001.304-.003L5.494.999l1.852 1.686c.372.34.936.34 1.308 0L10.506 1l1.854 1.688c.37.337.929.339 1.3.003l1.358-1.227v12.654l.005 5.753-1.36-1.227z"
			
			/>
			<path
				d="M12.652 10.167H3.347a.495.495 0 00-.49.5c0 .275.22.5.49.5h9.305c.271 0 .491-.225.491-.5 0-.276-.22-.5-.49-.5zM8.235 7.17H3.347a.495.495 0 00-.49.5c0 .275.22.499.49.499h4.888c.271 0 .49-.224.49-.5s-.219-.5-.49-.5zM12.652 13.164H3.347a.495.495 0 00-.49.5c0 .276.22.5.49.5h9.305c.271 0 .491-.224.491-.5s-.22-.5-.49-.5z"
			
			/>
		</svg>
	);
}

export default FaturaIcon;

