import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { PATH_LOGIN } from '../paths';

// TODO: passar checagem de se tem permissão X para dentro do módulo auth
// ex: Auth.checkPermission(permissions, requiredPerm)
// ex2: state.authStore.hasPermissionX
const canAccess = (
  isAuthenticated,
  authorizations,
  requiredAuthorizations,
  brandList,
  requiredBrands,
) => {
  if (!isAuthenticated) return false;

  const hasAllAuths = requiredAuthorizations.every((required) => (
    authorizations.includes(required)
  ));

  if (!requiredBrands || requiredBrands.length === 0) {
    return hasAllAuths;
  }

  return hasAllAuths && requiredBrands.every((requiredBrand) => (
    brandList.includes(requiredBrand)
  ));
};

const PrivateRoute = ({
  isAuthenticated, authorizations, requiredAuthorizations,
  path, exact, children, brandList, requiredBrands,
}) => (
  canAccess(isAuthenticated, authorizations, requiredAuthorizations, brandList, requiredBrands)
    ? (
      <Route exact={exact} path={path}>
        { children }
      </Route>
    )
    : <Redirect push to={PATH_LOGIN} />
);

PrivateRoute.propTypes = {
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  authorizations: PropTypes.array,
  requiredAuthorizations: PropTypes.array,
  children: PropTypes.node.isRequired,
  brandList: PropTypes.array,
  requiredBrands: PropTypes.array,
};

PrivateRoute.defaultProps = {
  exact: false,
  isAuthenticated: false,
  authorizations: [],
  requiredAuthorizations: [],
  brandList: [],
  requiredBrands: [],
};

export default PrivateRoute;
