import React from 'react';
import PropTypes from 'prop-types';

const TruncateText = ({
  className, value, size, sufix,
}) => (
  <span className={className}>
    {
      value.length > size
        ? `${value.substr(0, size)}${sufix}`
        : value
    }
  </span>
);

TruncateText.propTypes = {
  className: PropTypes.string,
  sufix: PropTypes.string,
  size: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
};

TruncateText.defaultProps = {
  className: '',
  sufix: '',
};

export default TruncateText;
