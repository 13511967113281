export const PATH_LOGIN = '/login';

export const PATH_STATEMENT = '/statement';
export const PATH_STATEMENT_B = '/movimentacoes';
export const PATH_INVOICES = '/duplicatas';
export const PATH_PAYMENTS = '/pagamentos';

export const PATH_IOF_SIMULATOR = '/simulador';
export const PATH_PRODUCTS = '/products';
export const PATH_SOLICITACAO_CREDITO = '/solicitacao-credito';

export const PATH_CARTEIRA = '/carteira';
export const PATH_COMPROVANTE_PAGAMENTO = '/comprovante-pagamento';
export const PATH_PAGAMENTO = '/novo-pagamento';

export const PATH_SESSION_EXPIRED = '/sessaoexpirada';

export const PATH_DASHBOARD = '/dashboard';
export const PATH_CENTRAL = '/central';
export const PATH_RELATORIOS_IOF = '/relatorios-dcs';
export const PATH_CARTEIRA_FIDC = '/carteira-fidc';
