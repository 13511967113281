import { connect } from 'react-redux';
import ResumoPagamento from './resumoPagamento';

const mapStateToProps = ({ statementStore }) => ({
  resumo: statementStore.modal.detalhes?.resumo,
  concessionariaPagante: statementStore.modal.detalhes?.resumo?.codigoConcessionariaOrigem,
  duplicatasPagas: statementStore.modal.detalhes?.resumo?.quantidadeDuplicatasPagas,
  dataPagamento: statementStore.modal.detalhes?.resumo?.dataTransacao,
  valorPagamento: statementStore.modal.detalhes?.resumo?.valorPagamento,
  pgtoMesmaConcessionaria: statementStore.modal.detalhes?.resumo?.valorMesmaConcessionaria,
  // eslint-disable-next-line max-len
  pgtoOutrasConcessionarias: statementStore.modal.detalhes?.resumo?.valorDestinoDiferenteConcessionaria,
  pgtoProcessamento: statementStore.modal.detalhes?.resumo?.valorProcessamento,
  pgtoRejeitado: statementStore.modal.detalhes?.resumo?.valorRejeitado,
  pgtoAprovados: statementStore.modal.detalhes?.resumo?.valorAprovado,
  nomeUsuario: statementStore.modal.detalhes?.resumo?.nomeUsuario,
  emailUsuario: statementStore.modal.detalhes?.resumo?.email,
  tipo: statementStore.modal.tipo,
});

const mapDispatchToProps = () => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(ResumoPagamento);
