import { connect } from 'react-redux';

import CarteiraPageFooter from './carteiraPageFooter';

import operations from '../../redux/operations';

const mapStateToProps = ({ gestaoPagamentos }) => ({
  page: gestaoPagamentos.carteira.page.pageParams.page,
  ipp: gestaoPagamentos.carteira.page.pageParams.ipp,
  totalItems: gestaoPagamentos.carteira.page.pageParams.totalItens,
  isLoading: gestaoPagamentos.carteira.page.requestStatus.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setPage: (value) => dispatch(operations.setPageParams('page', value)),
  setIpp: (value) => dispatch(operations.setPageParams('ipp', value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CarteiraPageFooter);
