import React from 'react';

import BreadCrumb from 'features/common-staff/layout/breadCrumb';

const BreadCrumbAcompanhamento = () => (
  <BreadCrumb
    labels={[{
      label: 'Limites',
      path: ' ',
    },
    {
      label: 'Minhas Solicitações',
      path: '/listagem',
    }]}
  />
);

export default BreadCrumbAcompanhamento;
