import React from 'react';
import PropTypes from 'prop-types';

import './produtoIcon.scss';
import colors from 'assets/styles/colors';
import AssignmentLateIcon from '../../../assets/icons/assignment-late';
import CategoryIcon from '../../../assets/icons/category';
import DirectionsCarIcon from '../../../assets/icons/directions-car';
import ExtensionIcon from '../../../assets/icons/extension';
import FlagIcon from '../../../assets/icons/flag';
import BookmarkIcon from '../../../assets/icons/bookmark';
import DepartureBoardIcon from '../../../assets/icons/departure-board';
import SemaphoreIcon from '../../../assets/icons/semaphore';
import BarChartIcon from '../../../assets/icons/bar-chart';

const setColorIcon = (status) => {
  switch (status.toLowerCase().trim()) {
    case 'quitada':
      return colors.terciary_color_600;
    case 'paid':
      return colors.terciary_color_600;
    case 'credito':
      return colors.terciary_color_600;
    case 'em aberto':
      return colors.primary_color_600;
    case 'em atraso':
      return colors.error_color_300;
    case 'debito':
      return colors.error_color_300;
    default:
      return colors.primary_color_600;
  }
};

const ProdutoIcon = ({ product, className, status }) => {
  if (product) {
    switch (product) {
      case (product.match(/^VE.CULO/) || {}).input:
        return (<DirectionsCarIcon id="produto-icon" className={className} fill={setColorIcon(status)} />);
      case (product.match(/^ESTOQUE/) || {}).input:
        return (<DepartureBoardIcon id="produto-icon" className={className} fill={setColorIcon(status)} />);
      case (product.match(/^IOF/) || {}).input:
        return (<BookmarkIcon id="produto-icon" className={className} fill={setColorIcon(status)} />);
      case 'JUROS':
        return (<FlagIcon id="produto-icon" className={className} fill={setColorIcon(status)} />);
      case 'MULTA':
        return (<FlagIcon id="produto-icon" className={className} fill={setColorIcon(status)} />);
      case (product.match(/^PLANO/) || {}).input:
        return (<AssignmentLateIcon id="produto-icon" className={className} fill={setColorIcon(status)} />);
      case (product.match(/^FUNDAO/) || {}).input:
        return (<BarChartIcon id="produto-icon" className={className} fill={setColorIcon(status)} />);
      case 'PEÇAS':
        return (<ExtensionIcon id="produto-icon" className={className} fill={setColorIcon(status)} />);
      case 'TEST DRIVE':
        return (<SemaphoreIcon id="produto-icon" className={className} fill={setColorIcon(status)} />);
      default:
        return (<CategoryIcon id="produto-icon" className={className} fill={setColorIcon(status)} />);
    }
  }
  return (<CategoryIcon id="produto-icon" className={className} />);
};

ProdutoIcon.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string,
  product: PropTypes.oneOf([
    'BRIDGE', 'ESTOQUE FLOOR PLAN', 'ESTOQUE FUNDAO CDB', 'ESTOQUE FUNDAO SCP', 'FLOOR PLAN USA', 'FUNDAO USA',
    'FUNDAO PEGASO', 'IOF NOVOS FLOOR PLAN', 'IOF NOVOS FLOOR SCP', 'IOF USADOS FLOOR PLAN', 'JUROS', '',
    'MULTA', 'OUTROS', 'PEÇAS', 'PLANO EMERGENCIAL CDB', 'PLANO EMERGENCIAL FLOOR PLAN', 'PLANO EMERGENCIAL SCP',
    'TEST DRIVE', 'VEICULO VENDIDO FLOOR PLAN', 'VEICULO VENDIDO FUNDAO CDB', 'VEICULO VENDIDO FUNDAO SCP', 'Outros',
    'IOC USADOS FLOOR PLAN',
  ]),
};

ProdutoIcon.defaultProps = {
  className: '',
  status: '',
  product: 'Outros',
};

export default ProdutoIcon;
