/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slider from 'react-slick';

import { Tracking } from 'modules';
import colors from 'assets/styles/colors';
import TestDriveNoResults from '../testDriveNoResults';
import OfferCard from './offerCard';

import './testDriveOfferSelector.scss';

const CircularProgressHub = withStyles({
  root: {
    '& svg': {
      color: colors.primary_color_500,
    },
  },
})(CircularProgress);

export default function TestDriveOfferSelector({
  isOffersLoading, offers, showOffersLoadError,
  produto, getMotivosTestDriveExcecao, selectedOfferId,
  setSelectedOffer, getCommercialConditions, setSelectedCondition, setSelectedDealerId,
}) {
  const isTestDriveExcecao = produto.titulo === 'Test Drive de Exceção' || produto.id === 4;
  useEffect(() => {
    if (isTestDriveExcecao) getMotivosTestDriveExcecao();
  }, [produto, getMotivosTestDriveExcecao, isTestDriveExcecao]);

  let display = 'selector';
  if (isOffersLoading) {
    display = 'loading';
  } else {
    if (offers?.length === 0) display = 'empty';
    if (showOffersLoadError) display = 'error';
  }

  const onChange = (id) => {
    setSelectedCondition(null);
    setSelectedDealerId(null);
    setSelectedOffer(id);

    const selectedOffer = (offers || []).find((o) => o.id === id);
    getCommercialConditions(
      selectedOffer.model,
      selectedOffer.version,
      selectedOffer.serie,
      selectedOffer.modelYear,
      selectedOffer.brand,
      produto.id,
    );

    Tracking.Mixpanel.testDrive.trackOfferSelected();
  };

  return (
    <div>
      { display === 'selector' ? (
        <Slider
          dots
          arrows
          infinite={false}
          speed="500"
          slidesToShow={3}
          slidesToScroll={3}
        >
          {(offers || []).map((offer, key) => (
            <OfferCard
              offer={offer}
              selectedOfferId={selectedOfferId}
              displayDuration={!isTestDriveExcecao}
              onClick={(id) => onChange(id)}
              key={key}
            />
          ))}
        </Slider>
      ) : null}
      {display === 'loading' ? (
        <div className="td-page-spinner-container">
          <CircularProgressHub />
        </div>
      ) : null}

      {display === 'empty' ? (
        <div className="td-page-no-results-container">
          <TestDriveNoResults />
        </div>
      ) : null}

      {display === 'error' ? (
        <div className="td-page-no-results-container">
          <TestDriveNoResults isError />
        </div>
      ) : null}
    </div>
  );
}

TestDriveOfferSelector.propTypes = {
  offers: PropTypes.array.isRequired,
  selectedOfferId: PropTypes.number,
  produto: PropTypes.object,
  getMotivosTestDriveExcecao: PropTypes.func,
  isOffersLoading: PropTypes.bool.isRequired,
  showOffersLoadError: PropTypes.bool.isRequired,

  setSelectedOffer: PropTypes.func.isRequired,
  getCommercialConditions: PropTypes.func.isRequired,
  setSelectedCondition: PropTypes.func.isRequired,
  setSelectedDealerId: PropTypes.func.isRequired,
};

TestDriveOfferSelector.defaultProps = {
  produto: null,
  selectedOfferId: null,
  getMotivosTestDriveExcecao: () => { },
};
