import React from 'react';
import PropTypes from 'prop-types';

import Logo from '../../../../../logo';

import './identificador.scss';

const titleText = {
  PAGAMENTO_EXTERNO: 'Duplicata paga fora do HUB',
  PAGAMENTO_INTERNO: 'Pagamento de duplicatas',
  CANCELAMENTO: 'Cancelamento de duplicata',
  'DUPLICIDADE OU A MAIOR': 'Duplicidade ou a maior',
  ESTORNO_TRANSACAO: 'Estorno',
};

const Identificador = ({
  status, tipoOperacao, id, produto,
}) => (
  <div className="statement_page__identificador__container">
    <div
      className="statement_page__identificador__logo"
      data-cy="StatementPageIdentificadorLogo"
    >
      <Logo
        status={status}
        tipoOperacao={tipoOperacao}
        produto={produto}
      />
    </div>
    <div
      className="statement_page__identificador__dados"
      data-cy="StatementPageIdentificadorDados"
      title={id}
    >
      <span>{titleText[tipoOperacao] ?? ''}</span>
      {id}
    </div>
  </div>
);

Identificador.propTypes = {
  status: PropTypes.string,
  tipoOperacao: PropTypes.string,
  id: PropTypes.string,
  produto: PropTypes.string,
};

Identificador.defaultProps = {
  status: '',
  tipoOperacao: '',
  id: '',
  produto: '',
};

export default Identificador;
