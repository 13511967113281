/* eslint-disable object-property-newline */
import { connect } from 'react-redux';
import AcompanhamentoPageList from './acompanhamentoPageList';

const mapStateToProps = ({ limiteCreditoStore }) => ({
  isLoading: limiteCreditoStore.acompanhamento.list.isLoading,
  isError: limiteCreditoStore.acompanhamento.list.isError,
  solicitacoes: limiteCreditoStore.acompanhamento.list.solicitacoes,
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(AcompanhamentoPageList);
