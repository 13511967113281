import { connect } from 'react-redux';
import operations from './redux/operations';
import LimitesAprovadosPage from './limitesAprovadosPage';
import { filterConcessionarias } from './redux/selector';

const mapStateToProps = ({ limitesAprovados, dealerStore }) => ({
  snackbarErrors: limitesAprovados.snackbarErrors,
  limitesAprovadosList: limitesAprovados.limitesAprovadosList.limites,
  isLoadingList: limitesAprovados.limitesAprovadosList.isLoading,
  isErrorList: limitesAprovados.limitesAprovadosList.isError,
  concessionarias: filterConcessionarias(dealerStore),
});

const mapDispatchToProps = (dispatch) => ({
  onSnackbarClose: (id) => dispatch(operations.dismissSnackbar(id)),
  getLimitesAprovados: (codigoBuc) => dispatch(operations.getLimitesAprovados(codigoBuc)),
  getFilters: () => dispatch(operations.getFilters()),
  resetStore: () => dispatch(operations.resetStore()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LimitesAprovadosPage);
