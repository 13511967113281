import React from 'react';
import PropTypes from 'prop-types';

import List, { ListContent } from '../../../../../common-staff/layout/list';

import AumentoCard from './aumentoCard';
import TransferenciaCard from './transferenciaCard';
import TimelineSolicitacoes from './timelineSolicitacoes';
import Header from './header';

import './detalheSolicitacaoAside.scss';

const DetalheSolicitacaoAside = ({ isLoading, isError, tipo }) => (
  <List
    isLoading={isLoading}
    isError={isError}
    disabledScrolls
  >
    <ListContent>
      <div className="acompanhamento__detalhe">
        <div className="acompanhamento__detalhe__header">
          <Header />
        </div>
        <div className="acompanhamento__detalhe__cards">
          {tipo === 'Alteração de Limite' ? (<AumentoCard />) : (<TransferenciaCard />)}
        </div>
        <div className="acompanhamento__detalhe__timeline">
          <div className="acompanhamento__detalhe__timeline-content">
            <TimelineSolicitacoes />
          </div>
        </div>
      </div>
    </ListContent>

    <ListContent type="error">
      <div className="acompanhamento__detalhe__error">
        Ocorreu um erro ao carregar os detalhes da solicitação.
      </div>
    </ListContent>
  </List>
);

DetalheSolicitacaoAside.propTypes = {
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  tipo: PropTypes.string,
};

DetalheSolicitacaoAside.defaultProps = {
  isLoading: false,
  isError: false,
  tipo: null,
};

export default DetalheSolicitacaoAside;
