export const types = {
  SET_PAGE: 'testDrivePage/SET_PAGE',
  SET_SELECTED_CATEGORY: 'testDrivePage/SET_SELECTED_CATEGORY',
  SET_SELECTED_PRODUCT: 'testDrivePage/SET_SELECTED_PRODUCT',
  LOAD_PRODUCTS_START: 'testDrivePage/LOAD_PRODUCTS/START',
  LOAD_PRODUCTS_SUCCESS: 'testDrivePage/LOAD_PRODUCTS/SUCCESS',
  LOAD_PRODUCTS_ERROR: 'testDrivePage/LOAD_PRODUCTS/ERROR',
  RESET_SELECTIONS: 'testDrivePage/RESET_SELECTIONS',
};

export const loadProductsStart = () => ({
  type: types.LOAD_PRODUCTS_START,
});

export const loadProductsError = () => ({
  type: types.LOAD_PRODUCTS_ERROR,
});

export const loadProductsSuccess = (products) => ({
  type: types.LOAD_PRODUCTS_SUCCESS,
  payload: { products },
});

export const setSelectedCategory = (id) => ({
  type: types.SET_SELECTED_CATEGORY,
  payload: { id },
});

export const setSelectedProduct = (id) => ({
  type: types.SET_SELECTED_PRODUCT,
  payload: { id },
});

export const setPage = (page) => ({
  type: types.SET_PAGE,
  payload: { page },
});

export const resetSelections = () => ({
  type: types.RESET_SELECTIONS,
});
