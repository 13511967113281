import styled, { css } from 'styled-components';
import Button from 'features/common-staff/controls/button';
import AlertCard from 'features/common/alertCard/alertCard';
import colors from 'assets/styles/colors';

export const Container = styled.div`
  display: grid;
  grid-template-areas: ${(props) => `
    "title" "." "message" "." "aceite" "." ${props.isModal ? '"divider" "."' : ''} "footer" ${props.isModal ? '' : '"." "reversao"'}
  `};
  grid-template-rows: ${(props) => (props.isModal
    ? '24px 20px 170px 9px 42px 13px 1px 11px 69px'
    : 'fit-content(24px) 20px minmax(170px, 1fr) 9px 42px 13px 69px 52px 96px'
  )};
  max-height: inherit;
`;

export const Title = styled.div`
  grid-area: title;
  margin-right: ${(props) => (props.isModal ? '64px' : '0px')};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #404154;

  ${(props) => (props.isModal
    ? css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;`
    : css`
      display: block;
      overflow-wrap: break-word;`
  )}; 
`;

export const Message = styled.div`
  grid-area: message;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  color: #404154;
  overflow-y: scroll;
  padding-right: 36px;
  margin-right: -4px;
`;

export const Aceite = styled.div`
  grid-area: aceite;
  height: 42px;
`;

export const Divider = styled.div`
  grid-area: divider;
  margin: 0px -40px;
  background: #EDF1F7;
  width: 806px;
  border: none;
  height: 1px;
`;

export const Footer = styled.div`
  grid-area: footer;
  display: grid;
  grid-template-areas: "ocultar actions";
  margin-right: ${(props) => (props.isModal ? '-16px' : '')};
`;

export const Reverter = styled.div`
  grid-area: reversao;
`;

export const OcultarMensagem = styled.div`
  grid-area: ocultar;
`;

export const Actions = styled.div`
  grid-area: actions;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const ButtonRow = styled.div`
  display: flex;
  height: 40px;
  gap: 8px;
`;

export const CloseButton = styled(Button)`
  border: 1px solid #555770 !important;
  background-color: #FFFFFF !important;
  color: #555770 !important;
  width: 92px;

  :hover {
    background-color: #F2F2F4 !important;
  }
  :active {
    background-color: #E4E4E8 !important;
  }
`;

export const AcceptButton = styled(Button)`
  background-color: #304AAF !important;
  color: #FFFFFF !important;
  width: 104px;
  height: 40px;

  :disabled {
    background-color: #F1F2F4 !important;
    color: #A6A8BB !important;
  }

  ${(props) => !props.disabled && !props.isLoading && css`
    :hover {
      background-color: #243782 !important;
    }
    :active {
      background-color: #121C41 !important;
    }
  `};
`;

export const DeclineButton = styled(Button)`
  background-color: #555770 !important;
  color: #FFFFFF !important;
  width: 205px;

  :disabled {
    background-color: #F1F2F4 !important;
    color: #A6A8BB !important;
  }
  ${(props) => !props.disabled && !props.isLoading && css`
    :hover {
      background-color: #3B3C48 !important;
    }
    :active {
      background-color: #313237 !important;
    }
  `};
`;

export const AlertCheckbox = styled.div``;

export const AlertLink = styled.div`
  margin-top: ${(props) => (props.isModal ? '42px' : '6px')};
`;

export const LinkRedirect = styled.a`
  color: #7A7C9A;
  font-weight: 450;
  font-size: 12px;
  line-height: 16px;
`;

export const AlertReverter = styled(AlertCard)`
  .alert-card-title {
    font-size: 16px;
  }
`;

export const RevertButtonContainer = styled.div`
  margin-left: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const AlertContainer = styled.div`
  width: fit-content;
  border: solid 1px transparent;
  border-radius: 4px;
  margin-left: -11px;
  padding-right: 8px;
  padding-left: 11px;
  display: flex;
  align-items: center;

  > label {
    padding-right: 8px;
    margin-right: 0px;
  }

  ${(props) => props.showAlert && css`
    border: 1px solid ${colors.alert_color_300};
  `};
`;

export const AlertCardDecline = styled(AlertCard)`
  .alert-card-title {
    color: ${colors.secundary_color_800};
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    gap: 7px;
    .MuiIcon-root {
      width: auto;
    }
  }

  .alert-card-subtitle {
    color: ${colors.secundary_color_800};
    font-size: 12px;
    font-style: normal;
    line-height: 16px;
    margin-left: 23px;
  }
`;
