import { connect } from 'react-redux';

import operations from '../../redux/operations';

import ComunicadosPaginationFooter from './comunicadosPaginationFooter';

const mapStateToProps = ({ comunicados }) => ({
  page: comunicados.page.paginationControl.page,
  ipp: comunicados.page.paginationControl.ipp,
  totalItems: comunicados.page.paginationControl.totalItems,
  isLoading: comunicados.page.requestStatus.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setPage: (page) => dispatch(operations.setPageParams('page', page)),
  setIpp: (ipp) => dispatch(operations.setPageParams('ipp', ipp)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComunicadosPaginationFooter);
