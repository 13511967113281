import { connect } from 'react-redux';
import operations from '../../../redux/operations';

import ExportarCarteira from './exportarCarteira';

const mapStateToProps = ({ gestaoPagamentos }) => ({
  isLoading: gestaoPagamentos.carteira.page.requestStatus.isLoadingExport,
  periodo: gestaoPagamentos.carteira.page.filters.periodo,
});

const mapDispatchToProps = (dispatch) => ({
  download: (isCsv) => dispatch(operations.downloadCarteira(isCsv)),
  setExportPeriodo: (label, value) => dispatch(operations.setFilter(label, value)),
  setExportPopperOpen: (value) => dispatch(operations.setExportModal(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExportarCarteira);
