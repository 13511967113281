import React from 'react';

import './pagamentoListHeader.scss';

const PagamentoListHeader = () => (
  <div className="pagamento-page__list-header__container">
    <div className="pagamento-page__list-header__container__item pagamento-page__list-header__container__identificador">
      DOCUMENTO
    </div>
    <div className="pagamento-page__list-header__container__item pagamento-page__list-header__container__vencimento">
      VENCIMENTO
    </div>
    <div
      className="pagamento-page__list-header__container__item pagamento-page__list-header__container__saldo"
    >
      SALDO
    </div>
    <div
      className="pagamento-page__list-header__container__item pagamento-page__list-header__container__pagamento"
    >
      VALOR PGTO.
    </div>
    <div className="pagamento-page__list-header__container__item pagamento-page__list-header__container__icon" />
  </div>
);

export default PagamentoListHeader;
