import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  groupContainerDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  groupHeader: {
    width: '100%',
    height: '26px',
    backgroundColor: 'rgba(228, 233, 242, 0.24)',

    display: 'flex',
    alignItems: 'center',
    padding: '0 40px',

    '& span': {
      fontSize: '10px',
      fontWeight: '500',
      letterSpacing: '1.5px',
      color: '#555770',

      whiteSpace: 'pre-wrap',
      textTransform: 'uppercase',
    },
  },

  groupContent: {
    width: '100%',
  },
});

const ListGroup = ({ children, label, className }) => {
  const classes = useStyles();

  return (
    <div className={classes.groupContainerDiv}>
      <div className={`${classes.groupHeader} ${className}`}>
        <div>
          <span>{label}</span>
        </div>
      </div>
      <div className={classes.groupContent}>
        {children}
      </div>
    </div>
  );
};

ListGroup.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  className: PropTypes.string,
};

ListGroup.defaultProps = {
  children: null,
  label: null,
  className: '',
};

export { ListGroup };
