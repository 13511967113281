import { connect } from 'react-redux';

import operations from '../../../redux/operations';
import selectors from '../../../redux/selectors';
import ProdutoSelect from './produtoSelect';

const mapStateToProps = ({ gestaoPagamentos }) => ({
  produto: gestaoPagamentos.carteira.page.filters?.produto,
  produtoList: selectors.produtoList(gestaoPagamentos),
  isLoading: gestaoPagamentos?.carteira?.page?.requestStatus?.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setProduto: (value) => dispatch(operations.setFilter('produto', value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProdutoSelect);
