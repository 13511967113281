import React from 'react';
import PropTypes from 'prop-types';

import Button from 'features/common-staff/controls/button';

import './buttonFilter.scss';

const ButtonFilter = ({
  isFilterSelected,
  onClick,
  isLoading,
  textApply,
  textSelect,
  fullWidth,
  error = false,
  dataCy,
}) => (
  <Button
    className="common__filters__botao-aplicar"
    onClick={() => onClick()}
    disabled={!isFilterSelected || isLoading || error}
    fullWidth={fullWidth}
    dataCy={dataCy}
    color="darkGray"
  >
    {isFilterSelected ? textApply : textSelect}
  </Button>
);

ButtonFilter.propTypes = {
  isFilterSelected: PropTypes.bool,
  error: PropTypes.bool,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  textApply: PropTypes.string,
  textSelect: PropTypes.string,
  fullWidth: PropTypes.bool,
  dataCy: PropTypes.string,
};

ButtonFilter.defaultProps = {
  isFilterSelected: false,
  error: false,
  isLoading: false,
  onClick: () => {},
  textApply: 'Aplicar Filtros',
  textSelect: 'Selecione os filtros',
  fullWidth: false,
  dataCy: null,
};

export default ButtonFilter;
