import { connect } from 'react-redux';

import ProductCards from './productCards';
import * as operations from '../../../redux/operations';

const mapStateToProps = ({ testDrive }) => ({
  products: testDrive.main.products.data,
  isLoading: testDrive.main.products.isLoading,
  isError: testDrive.main.products.isError,
});

const mapDispatchToProps = (dispatch) => ({
  selectCategory: (id) => dispatch(operations.selectCategory(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductCards);
