import { connect } from 'react-redux';

import InputModelo from './inputVersao';

import operations from '../../../redux/operations';

const mapStateToProps = ({ gestaoPagamentos }) => ({
  versao: gestaoPagamentos.carteira.page.filters?.versao,
  isLoading: gestaoPagamentos?.carteira?.page?.requestStatus?.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setVersao: (value) => dispatch(operations.setFilter('versao', value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InputModelo);
