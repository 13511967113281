/* eslint-disable */
import React from "react";

const AlertIcon = props => (
	<svg width="1em" height="1em" viewBox="0 0 16 13" {...props}>
		<path
			d="M8 10a.667.667 0 110 1.334A.667.667 0 018 10zm0-4.667c.368 0 .667.299.667.667v2.667a.667.667 0 01-1.334 0V6c0-.368.299-.667.667-.667zm5.91 6.902c-.153.27-.45.432-.796.432H2.885c-.345 0-.643-.162-.795-.432a.647.647 0 01.012-.678L7.216 3.08c.311-.516 1.256-.516 1.568 0l5.114 8.478a.649.649 0 01.012.678m1.13-1.366l-5.114-8.48c-.4-.661-1.12-1.056-1.926-1.056-.807 0-1.527.395-1.926 1.056L.96 10.87a1.982 1.982 0 00-.03 2.022C1.314 13.575 2.064 14 2.884 14h10.23c.82 0 1.57-.425 1.956-1.11a1.982 1.982 0 00-.03-2.021"
			fill="#000"
			fillRule="evenodd"
			id="alert_svg__a"
		/>
	</svg>
);

export default AlertIcon;

