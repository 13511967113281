import { connect } from 'react-redux';
import * as limiteCreditoOperations from '../../../redux/operations';

import ToogleValor from './toogleValor';

const mapStateToProps = ({ limiteCreditoStore }) => ({
  isPositivo: limiteCreditoStore.alteracaoLimite.dados.isPositivo,
});

const mapDispatchToProps = (dispatch) => ({
  setIsPositivo: (isPositivo) => dispatch(limiteCreditoOperations.setIsPositivo(isPositivo)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ToogleValor);
