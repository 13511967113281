import React, { useState } from 'react';
import _ from 'lodash';

import CancelButton from './cancelButton';
import VoltarButton from './voltarButton';
import ConfirmarCancelamentoButton from './confirmarCancelamentoButton';

import './footer.scss';

const makeButtonMap = (setCancelConfirmation) => {
  const cancelButton = <CancelButton onClick={() => setCancelConfirmation(true)} />;
  const voltarButton = <VoltarButton onClick={() => setCancelConfirmation(false)} />;

  return [
    { status: false, button1: null, button2: cancelButton },
    {
      status: true,
      button1: voltarButton,
      button2: <ConfirmarCancelamentoButton />,
    },
  ];
};

const getButtons = (buttonMap, status) => {
  if (!_.isArray(buttonMap)) return null;
  return buttonMap.find((m) => m.status === status);
};

const Footer = () => {
  const [isCancelConfirmation, setCancelConfirmation] = useState(false);
  const buttonMap = makeButtonMap(setCancelConfirmation);
  const buttons = getButtons(buttonMap, isCancelConfirmation);
  return (
    <div className="pedidos__modal_footer__container">
      {isCancelConfirmation ? (
        <div className="pedidos__modal_footer__confirm">
          Deseja cancelar o pedido?
        </div>
      ) : null}

      <div className="pedidos__modal_footer__button1">
        {buttons?.button1}
      </div>
      <div className="pedidos__modal_footer__button2">
        {buttons?.button2}
      </div>
    </div>
  );
};

export default Footer;
