import { connect } from 'react-redux';

import TransferenciaCard from './transferenciaCard';

const mapStateToProps = ({ limiteCreditoStore }) => ({
  solicitacao: limiteCreditoStore.acompanhamento.detalhes.solicitacao?.solicitacao,
  valor: limiteCreditoStore.acompanhamento.detalhes.solicitacao?.valor,
  solicitanteEmail: limiteCreditoStore.acompanhamento.detalhes.solicitacao?.solicitanteEmail,
  concessionariaOrigem:
limiteCreditoStore.acompanhamento.detalhes.solicitacao?.concessionariaOrigem,
  produtoOrigem:
limiteCreditoStore.acompanhamento.detalhes.solicitacao?.produtoOrigem,
  concessionariaDestino:
limiteCreditoStore.acompanhamento.detalhes.solicitacao?.concessionariaDestino,
  produtoDestino: limiteCreditoStore.acompanhamento.detalhes.solicitacao?.produtoDestino,
  marcaOrigem: limiteCreditoStore.acompanhamento.detalhes.solicitacao?.concessionariaOrigem.marca,
  marcaDestino: limiteCreditoStore.acompanhamento.detalhes.solicitacao?.concessionariaDestino.marca,
});

export default connect(mapStateToProps, null)(TransferenciaCard);
