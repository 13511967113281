import { connect } from 'react-redux';

import operations from '../../../redux/operations';
import selectors from '../../../redux/selectors';
import ConcessionaiaraSelect from './concessionariaSelect';

const mapStateToProps = ({ gestaoPagamentos, dealerStore }) => ({
  concessionaria: gestaoPagamentos.carteira.page.filters?.concessionaria,
  concessionariaList: selectors.concessionariaList(dealerStore),
  isLoading: gestaoPagamentos?.carteira?.page?.requestStatus?.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setConcessionaria: (value) => dispatch(operations.setFilter('concessionaria', value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConcessionaiaraSelect);
