import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import TypeNumber from './numericInputBase';

const CurrencyInput = ({
  value, setValue, disabled, ...other
}) => (
  <TypeNumber
    value={value}
    disabled={disabled}
    setValue={(event) => {
      setValue(_.isEmpty(event) ? null : event);
    }}
    decimalSeparator=","
    thousandSeparator="."
    decimalScale="2"
    prefix="R$ "
    {...other}
  />
);

CurrencyInput.propTypes = {
  setValue: PropTypes.func,
  value: PropTypes.any,
  disabled: PropTypes.bool,
};

CurrencyInput.defaultProps = {
  setValue: () => {},
  value: '',
  disabled: true,
};

export default CurrencyInput;
