import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import Checkbox from 'features/common/checkbox/checkbox';
import colors from 'assets/styles/colors';

import './carteiraListHeader.scss';

const useStyles = makeStyles({
  display: {
    display: (modal) => (modal.isModalOpen ? 'none' : 'inherit'),
  },
  container: {
    gridTemplateAreas: (modal) => {
      if (modal.infoModalOpen) return "' checkbox documento  concessionaria  vencimento '";
      if (modal.isModalOpen && !modal.infoModalOpen) return "' checkbox documento  vencimento  saldo '";
      return "' checkbox documento  concessionaria  vencimento  saldo  status '";
    },
    gridTemplateColumns: (modal) => {
      if (modal.infoModalOpen) return '2.5em 17em auto 8em';
      if (modal.isModalOpen && !modal.infoModalOpen) return '2.5em auto 8em 8em';
      return '2.5em 17em auto 9em 8em 8em';
    },
  },
});

const CarteiraListHeader = ({
  onCheck, selectedAll, infoModalOpen,
  isModalOpen, isLoading, isError, temPermissaoPagamento,
}) => {
  const classes = useStyles({ isModalOpen, infoModalOpen });

  return (
    <div className={`carteira-page__list-header__container ${classes.container}`}>
      <div className="carteira-page__list-header__item carteira-page__list-header__checkbox">
        <Checkbox
          onChange={() => onCheck(selectedAll)}
          checked={selectedAll}
          disabled={isLoading || isError || !temPermissaoPagamento}
          size="medium"
          color={colors.text_midnight_500}
          checkedColor={colors.text_midnight_500}
        />
      </div>
      <div className="carteira-page__list-header__item carteira-page__list-header__identificador">
        DOCUMENTO
      </div>
      <div
        className={
`carteira-page__list-header__item carteira-page__list-header__concessionaria ${infoModalOpen ? null : classes.display}`
}
      >
        CONCESSIONÁRIA
      </div>
      <div className="carteira-page__list-header__item carteira-page__list-header__vencimento">
        VENCIMENTO
      </div>
      <div
        className="carteira-page__list-header__item carteira-page__list-header__saldo"
        style={infoModalOpen ? { display: 'none' } : null}
      >
        SALDO
      </div>
      <div
        className={`carteira-page__list-header__item carteira-page__list-header__status ${classes.display}`}
        style={infoModalOpen ? { display: 'none' } : null}
      >
        STATUS
      </div>
    </div>
  );
};

CarteiraListHeader.propTypes = {
  onCheck: PropTypes.func,
  selectedAll: PropTypes.bool,
  infoModalOpen: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  temPermissaoPagamento: PropTypes.bool,
};

CarteiraListHeader.defaultProps = {
  onCheck: () => {},
  selectedAll: false,
  infoModalOpen: false,
  isModalOpen: false,
  isLoading: false,
  isError: false,
  temPermissaoPagamento: false,
};

export default CarteiraListHeader;
