import React from 'react';
import PropTypes from 'prop-types';

import { ComponentErrorBoundary } from '../../../common';
import { content as contentEnum } from '../../redux/content';

import PopoverContentMain from '../popoverContentMain';
import ErrorFeedback from '../errorFeedback';
import UserFeedback from '../userFeedback';

import './userFeedbackPopover.scss';

const UserFeedbackPopover = ({
  className, content, isOpen,
  // eslint-disable-next-line arrow-body-style
}) => {
  return (isOpen) ? (
    <div className={`user-feedback-popover__container ${className}`}>
      <ComponentErrorBoundary>
        {(content === contentEnum.main) ? (
          <PopoverContentMain />
        ) : null}
        {(content === contentEnum.errorFeedback) ? (
          <ErrorFeedback />
        ) : null}
        {(content === contentEnum.testDriveFeedback) ? (
          <UserFeedback />
        ) : null}
      </ComponentErrorBoundary>
    </div>
  ) : null;
};

UserFeedbackPopover.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
};

UserFeedbackPopover.defaultProps = {
  className: '',
};

export default UserFeedbackPopover;
