import { connect } from 'react-redux';

import ResumoCard from './resumoCard';

const mapStateToProps = ({
  gestaoPagamentos, dealerStore,
}) => ({
  dealerId: dealerStore.selectedFPlanDealerId,
  resumo: gestaoPagamentos.comprovante.comprovantes?.resumo,
});

export default connect(mapStateToProps, null)(ResumoCard);
