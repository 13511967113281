import React, {
  createContext, useContext, useEffect, useReducer, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import useCustomFetch from 'hooks/useFetch';
import { applyProperty, applyPropertyArray } from 'utils/object';
import { saveAs } from 'file-saver';
import resgateService from '../services/resgateService';
import { periodoOptions } from '../views/historicoResgatesPage/helper';

const initialState = (cnpj = null) => ({
  requestStatus: {
    loading: false,
    success: false,
    error: null,
  },

  filters: {
    cnpj,
    periodo: [],
    status: 'all',
    concessionaria: 'all',
  },

  periodoList: [],
  data: [],
  isUpdated: true,

  isFiltered: false,

  resgateDrawer: {
    isOpen: false,
    resgateDetalhe: undefined,
    transition: false,
  },

  pageParams: {
    page: 0,
    ipp: 25,
    total: 0,
  },
});

const reducer = (state, action) => {
  switch (action.type) {
    case 'dealer':
      return {
        ...state,
        filters: {
          ...state.filters,
          cnpj: action.cnpj,
        },
        isUpdated: true,
      };
    case 'response':
      return {
        ...state,
        pageParams: {
          ...state.pageParams,
          total: action.data && _.isNumber(action.data.total) ? action.data.total
            : state.pageParams.total,
        },
        requestStatus: {
          ...action.requestStatus,
        },
        isUpdated: action.data && _.isBoolean(action.data.isUpdated) ? action.data.isUpdated
          : state.isUpdated,
      };
    case 'filters':
      return {
        ...state,
        filters: applyPropertyArray(
          { ...state.filters },
          action.propertyNames,
          action.values,
        ),
      };
    case 'pagination':
      return {
        ...state,
        pageParams: applyProperty(
          { ...state.pageParams },
          action.propertyName,
          action.value,
        ),
      };
    case 'data':
      return {
        ...state,
        data: action.data?.resgates ?? [],
        pageParams: {
          page: action.data?.page,
          ipp: action.data?.itensPerPage,
          total: action.data?.total,
        },
        isFiltered: action.isFiltered,
      };
    case 'drawer':
      return {
        ...state,
        resgateDrawer: {
          ...state.duplicataDrawer,
          isOpen: action.isOpen,
          resgateDetalhe: action.resgateDetalhe,
          transition: action.isOpen ? true : state.resgateDrawer.transition,
        },
      };
    case 'transition':
      return {
        ...state,
        resgateDrawer: {
          ...state.resgateDrawer,
          transition: !state.resgateDrawer.transition,
        },
      };
    case 'periodo':
      return {
        ...state,
        periodoList: action.values,
      };
    case 'reset_filters':
      return {
        ...state,
        filters: {
          ...state.filters,
          periodo: [],
          status: 'all',
          concessionaria: 'all',
        },
        pageParams: {
          page: 0,
          ipp: 25,
          total: 0,
        },
      };
    default: return state;
  }
};

const HistoricoResgateContext = createContext(null);

export const HistoricoResgateContextProvider = ({
  children, dealer, addSnackbar, closeSnackbar,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState(dealer.cnpj));

  const closeDrawer = useCallback(() => {
    dispatch({
      type: 'drawer', isOpen: false, resgateDetalhe: undefined,
    });
  }, []);

  const { pageParams, filters } = state;

  const fetchHistorico = useCallback(async (localFilters, localPageParams) => {
    try {
      const data = await resgateService.getHistoricoResgate({
        periodo: localFilters?.periodo ?? filters.periodo,
        status: localFilters?.status ?? filters.status,
        concessionaria: localFilters?.concessionaria ?? filters.concessionaria,
        cnpj: localFilters?.cnpj ?? dealer.cnpj,
      }, {
        page: localPageParams?.page ?? pageParams.page,
        ipp: localPageParams?.ipp ?? pageParams.ipp,
        total: localPageParams?.total ?? pageParams.total,
      });
      dispatch({ type: 'data', data, isFiltered: false });
    } catch (err) {
      dispatch({ type: 'data', data: [], isFiltered: false });
    }
  }, [pageParams]);

  useEffect(() => {
    dispatch({ type: 'dealer', cnpj: dealer.cnpj });
    dispatch({ type: 'reset_filters' });
    fetchHistorico(
      {
        periodo: [],
        status: 'all',
        concessionaria: 'all',
        cnpj: dealer.cnpj,
      },
      {
        page: 0,
        ipp: 25,
      },
    );
  }, [dealer]);

  const getPeriodos = useCallback(async () => {
    const periodos = await resgateService.getPeriodosResgates();
    dispatch({ type: 'periodo', values: periodoOptions(periodos) });
  }, []);

  const getInitialHistorico = async () => {
    const {
      filters: initialStateFilters, pageParams: initialStatePageParams,
    } = initialState(dealer.cnpj);
    return resgateService.getHistoricoResgate(
      { ...initialStateFilters, concessionaria: null },
      initialStatePageParams,
    );
  };

  const [{ loading: initialHistoricoDataLoading }] = useCustomFetch(
    getInitialHistorico,
    [],
    true,
    (success, data) => {
      if (success) {
        dispatch({ type: 'data', data, isFiltered: false });
      } else {
        dispatch({ type: 'data', data: [], isFiltered: false });
      }
    },
  );

  const [{
    exportHistoricoLoading,
  }, fetchExportHistorico] = useCustomFetch(
    () => resgateService.exportHistoricoResgates(state.filters),
    [],
    false,
    (success, res) => {
      if (success) {
        const url = window.URL.createObjectURL(new Blob([res.fileContent]));
        saveAs(url, res.fileName);
      }
    },
  );

  const [{ loading: getHistoricoLoading }] = useCustomFetch(
    getPeriodos,
    [dealer],
    true,
    (success, data) => {
      if (success) {
        dispatch({ type: 'response', data, requestStatus: { error: null, success } });
      } else {
        dispatch({ type: 'response', data: [], requestStatus: { error: data, success } });
      }
    },
  );

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const historicoResgateContextStore = {
    state,
    getHistoricoLoading,
    historicoDataLoading: initialHistoricoDataLoading,
    exportHistoricoLoading,
    fetchHistorico,
    fetchExportHistorico,
    dispatch,
    addSnackbar,
    closeSnackbar,
    closeDrawer,
    openDrawer: (value) => dispatch({
      type: 'drawer', isOpen: true, resgateDetalhe: value,
    }),
    transitionDrawer: () => dispatch({ type: 'transition' }),
    setPagination: (propertyName, value) => dispatch({ type: 'pagination', propertyName, value }),
    setFilters: (propertyNames, values) => dispatch({ type: 'filters', propertyNames, values }),
  };

  return (
    <HistoricoResgateContext.Provider value={historicoResgateContextStore}>
      {children}
    </HistoricoResgateContext.Provider>
  );
};

HistoricoResgateContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  dealer: PropTypes.object.isRequired,
  addSnackbar: PropTypes.object.isRequired,
  closeSnackbar: PropTypes.object.isRequired,
};

HistoricoResgateContextProvider.defaultProps = {
  children: null,
};

export const useHistoricoResgateContext = () => useContext(HistoricoResgateContext);
