import React from 'react';
import PropTypes from 'prop-types';

import Button from 'features/common-staff/controls/button';

import { Tracking } from 'modules';

const ConfirmarCancelamentoButton = ({
  cancelPedido, isLoadingCancel,
}) => (
  <Button
    disabled={isLoadingCancel}
    isLoading={isLoadingCancel}
    onClick={() => {
      Tracking.Mixpanel.acompanhamentoTd.trackCancel();
      cancelPedido();
    }}
    color="darkGray"
  >
    Cancelar
  </Button>
);

ConfirmarCancelamentoButton.propTypes = {
  cancelPedido: PropTypes.func.isRequired,
  isLoadingCancel: PropTypes.bool.isRequired,
};

export default ConfirmarCancelamentoButton;
