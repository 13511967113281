import logger from 'utils/logger';
import { SnackbarActions } from 'modules/snackbar';
import serviceLimites from '../../limitesAprovadosPage/redux/service';
import actions from './actions';
import service from './service';
import pageOperations from '../../limitesAprovadosPage/redux/operations';

const setSelectedItems = (idTipo) => (dispatch) => {
  dispatch(actions.setSelectedItems(idTipo));
};

const buildGerenciadorDocumentos = (listaPessoaDocumentacao) => (dispatch) => {
  const gerenciadorDocumentos = listaPessoaDocumentacao?.flatMap(
    (item) => item.pessoaDocumentacao.tipoDocumento.map((doc) => ({
      nome: item.documentos
        .find((documento) => doc.value === documento.idTipoDocumento)?.nomeOriginal,
      tamanho: item.documentos
        .find((documento) => doc.value === documento.idTipoDocumento)?.tamanho,
      idPessoaDocumento: item.pessoaDocumentacao.idPessoaDocumentacao,
      idTipoDocumento: doc.value,
      validado: item.documentos
        .find((documento) => doc.value === documento.idTipoDocumento)?.validado,
      isLoading: false,
      isError: false,
    })),
  );
  dispatch(actions.buildGerenciadorDocumentos(gerenciadorDocumentos));
};

const getPessoaDocumentacao = (idLimite) => async (dispatch) => {
  try {
    dispatch(actions.getPessoaDocumentacaoStart());
    const listaPessoaDocumentacao = await service.getPessoaDocumentacao(idLimite);
    dispatch(actions.getPessoaDocumentacaoSuccess(
      listaPessoaDocumentacao,
    ));
    dispatch(buildGerenciadorDocumentos(listaPessoaDocumentacao));
  } catch (e) {
    logger.error(e);
    dispatch(actions.getPessoaDocumentacaoError());
  }
};

const updateAndSaveStatus = (idLimite, status) => async (dispatch) => {
  try {
    dispatch(actions.updateLimiteStart());
    const response = await serviceLimites.updateAndSaveStatus({
      status,
      idLimiteList: [idLimite],
      tipo: 'alterar_status',
    });
    if (response.status !== 200) {
      throw new Error('Erro ao enviar lista de documentos.');
    } else {
      dispatch(SnackbarActions.addSnackbar('Documentos enviados com sucesso.', 'success'));
      dispatch(actions.updateLimiteSuccess());
      return true;
    }
  } catch (e) {
    dispatch(actions.updateLimiteError());
    dispatch(SnackbarActions.addSnackbar('Erro ao enviar lista de documentos.', 'error'));
    logger.error(e);
    return false;
  }
};

const uploadDocumento = (
  file,
  idPessoaDocumento,
  idTipoDocumento,
) => async (dispatch, getState) => {
  const { gerenciadorDocumentos } = getState().limitesAprovadosCadastro;
  let documentos = [...gerenciadorDocumentos];
  const documento = documentos.find(
    (objeto) => objeto.idPessoaDocumento === idPessoaDocumento
      && objeto.idTipoDocumento === idTipoDocumento,
  );
  try {
    documento.isLoading = true;
    documento.isError = false;
    dispatch(actions.updateDocumentoStart(documentos));

    const urlUpload = await service.getUrlUploadDocumento(file, idPessoaDocumento, idTipoDocumento);

    await service.uploadDocumento(urlUpload, file);

    documentos = [...gerenciadorDocumentos];
    documento.nome = file.name;
    documento.tamanho = Math.round((file.size / 1048576) * 1000) / 1000;
    documento.isLoading = false;
    documento.isError = false;
    dispatch(actions.updateDocumentoSuccess(documentos));
  } catch (e) {
    logger.error(e);
    documentos = [...gerenciadorDocumentos];
    documento.isLoading = false;
    documento.isError = true;
    dispatch(actions.updateDocumentoError(documentos));
  }
};

const downloadDocumento = (
  idDocumento,
  idPessoaDocumento,
  idTipoDocumento,
) => async (dispatch, getState) => {
  const { gerenciadorDocumentos } = getState().limitesAprovadosCadastro;
  let documentos = [...gerenciadorDocumentos];
  const documento = documentos.find(
    (objeto) => objeto.idPessoaDocumento === idPessoaDocumento
      && objeto.idTipoDocumento === idTipoDocumento,
  );
  try {
    documento.isLoading = true;
    documento.isError = false;
    dispatch(actions.downloadDocumentoStart(gerenciadorDocumentos));
    const urlDownload = await service.getUrlDownloadDocumento(idDocumento);
    documentos = [...gerenciadorDocumentos];
    documento.isLoading = false;
    documento.isError = false;
    dispatch(actions.downloadDocumentoSuccess(documentos, urlDownload));
    return urlDownload;
  } catch (e) {
    logger.error(e);
    documento.isLoading = false;
    documento.isError = true;
    dispatch(actions.downloadDocumentoError(gerenciadorDocumentos));
  }
  return '';
};

const deleteDocumento = (idPessoaDocumento, idTipoDocumento) => async (dispatch, getState) => {
  const { gerenciadorDocumentos } = getState().limitesAprovadosCadastro;
  let documentos = [...gerenciadorDocumentos];
  const documento = documentos.find(
    (objeto) => objeto.idPessoaDocumento === idPessoaDocumento
      && objeto.idTipoDocumento === idTipoDocumento,
  );
  try {
    documento.isLoading = true;
    documento.isError = false;
    dispatch(actions.deleteDocumentoStart(documentos));
    const statusLimite = await service.deleteDocumento(idPessoaDocumento, idTipoDocumento);
    documentos = [...gerenciadorDocumentos];
    documento.nome = null;
    documento.tamanho = null;
    documento.isLoading = false;
    documento.isError = false;
    dispatch(actions.deleteDocumentoSuccess(documentos));
    if (statusLimite) dispatch(pageOperations.updateLimiteStatus(statusLimite));
  } catch (e) {
    logger.error(e);
    documentos = [...gerenciadorDocumentos];
    documento.isLoading = false;
    documento.isError = true;
    dispatch(actions.updateDocumentoError(documentos));
    dispatch(actions.deleteDocumentoError(gerenciadorDocumentos));
  }
};

const setErroDocumento = (
  idPessoaDocumento,
  idTipoDocumento,
  textoErro,
) => async (dispatch, getState) => {
  const { gerenciadorDocumentos } = getState().limitesAprovadosCadastro;
  const documentos = [...gerenciadorDocumentos];
  const documento = documentos.find(
    (objeto) => objeto.idPessoaDocumento === idPessoaDocumento
      && objeto.idTipoDocumento === idTipoDocumento,
  );
  documento.isError = true;
  documento.textoErro = textoErro;
  dispatch(actions.deleteDocumentoStart(documentos));
};

const getDocumentosComplementares = (idLimite) => async (dispatch) => {
  try {
    dispatch(actions.getDocumentosComplementaresStart());
    const listaDocumentosComplementares = await service.getDocumentosComplementares(idLimite);
    dispatch(actions.getDocumentosComplementaresSuccess(listaDocumentosComplementares));
  } catch (e) {
    logger.error(e);
    dispatch(actions.getDocumentosComplementaresError());
  }
};

const updateObservacaoDealer = (idPessoa, observacao) => async (dispatch) => {
  try {
    await service.updateObservacaoDealer(idPessoa, observacao);
    dispatch(SnackbarActions.addSnackbar('Observação atualizada com sucesso.', 'success'));
  } catch (e) {
    logger.error(e);
    dispatch(SnackbarActions.addSnackbar('Erro ao enviar observação.', 'error'));
  }
};

const updateObservacaoDealerCadastro = (id, observacao) => async (dispatch) => {
  try {
    await service.updateObservacaoDealerCadastro(id, observacao);
    dispatch(SnackbarActions.addSnackbar('Observação atualizada com sucesso!', 'success'));
  } catch (e) {
    logger.error(e);
    dispatch(SnackbarActions.addSnackbar('Erro ao enviar observação.', 'error'));
  }
};

const uploadDocumentoComplementar = (
  file,
  idPessoaTipoDocumentoJuridico,
  indexPessoa,
  indexDocumento,
) => async (dispatch) => {
  try {
    const response = await service.getUrlUploadTemp(file);
    const { urlUpload, ...documento } = response;
    await service.uploadDocumento(urlUpload, file);
    const id = await service.insertDocumentoComplementar(documento, idPessoaTipoDocumentoJuridico);

    dispatch(actions.insertDocumentosComplementar(indexPessoa, indexDocumento, {
      id,
      nome: documento.nomeOriginal,
      tamanho: documento.tamanhoEmMb,
    }));
    dispatch(SnackbarActions.addSnackbar('Documento complementar inserido com sucesso.', 'success'));
    return true;
  } catch (e) {
    logger.error(e);
    dispatch(SnackbarActions.addSnackbar('Erro ao inserir documento complementar.', 'error'));
    return false;
  }
};

const getDocumentoComplementar = (idDocumento) => async (dispatch) => {
  try {
    const response = await service.getDocumentoComplementarUrl(idDocumento);
    window.open(response, '_blank');
  } catch (e) {
    logger.error(e);
    dispatch(SnackbarActions.addSnackbar('Erro ao abrir documento complementar.', 'error'));
  }
};

const deleteDocumentoComplementar = (
  idDocumento,
  indexPessoa,
  indexDocumento,
) => async (dispatch) => {
  try {
    await service.deleteDocumentoComplementar(idDocumento);
    dispatch(actions.deleteDocumentosComplementar(indexPessoa, indexDocumento));
    dispatch(SnackbarActions.addSnackbar('Documento complementar excluído com sucesso.', 'success'));
  } catch (e) {
    logger.error(e);
    dispatch(SnackbarActions.addSnackbar('Erro ao excluir documento complementar.', 'error'));
  }
};

const getDocumentosFormalizar = (id) => async (dispatch) => {
  try {
    dispatch(actions.getDocumentoFormalizarStart());
    const documentoList = await service.getDocumentosFormalizar(id);

    dispatch(actions.getDocumentoFormalizarSuccess(documentoList));
  } catch (e) {
    logger.error(e);
    dispatch(actions.getDocumentoFormalizarError());
  }
};

const downloadAnexoDocumentoFormalizar = (idDocumento) => async (dispatch) => {
  try {
    const response = await service.getAnexoDocumentoFormalizar(idDocumento);

    if (response) {
      window.open(response);
    }
  } catch (e) {
    dispatch(SnackbarActions.addSnackbar('Erro ao baixar documento'));
    logger.error(e);
  }
};

const updateDocumentoFormalizar = (observacao, indexList) => async (dispatch, getState) => {
  try {
    dispatch(actions.updateDocumentoFormalizarStart());
    const dados = getState()
      .limitesAprovadosCadastro.listaDocumentosFormalizar.listaDados[indexList];

    const response = await service.updateDocumentosFormalizar(observacao, dados);

    dispatch(actions.updateDocumentoFormalizarSuccess(response));
    dispatch(SnackbarActions.addSnackbar('Observação atualizada com sucesso', 'success'));
  } catch (e) {
    dispatch(SnackbarActions.addSnackbar('Erro ao enviar observação', 'error'));
    logger.error(e);
  }
};

const uploadAnexoDocumentoFormalizar = (
  file,
  idDocumentoFormalizarJuridico,
  indexList,
) => async (dispatch, getState) => {
  try {
    const response = await service.getUrlUploadTemp(file);
    const { urlUpload, ...documento } = response;
    await service.uploadDocumento(urlUpload, file);
    await service.insertAnexoDocumentoFormalizar(documento, idDocumentoFormalizarJuridico);

    const documentoList = getState().limitesAprovadosCadastro.listaDocumentosFormalizar.listaDados;
    const indexDocumento = documentoList[indexList].listaArquivos?.findIndex(
      (anexo) => anexo.nome === '' && anexo.tipo === 'dealer',
    );

    dispatch(actions.insertAnexoDocumentoFormalizar(indexList, indexDocumento, {
      nomeOriginal: documento.nomeOriginal,
      tamanho: documento.tamanhoEmMb,
      nomeGuid: documento.nomeGuid,
      validado: 0,
      tipo: 'dealer',
    }));
    dispatch(SnackbarActions.addSnackbar('Documento inserido com sucesso.', 'success'));
    return true;
  } catch (e) {
    logger.error(e);
    dispatch(SnackbarActions.addSnackbar('Erro ao inserir documento.', 'error'));
    return false;
  }
};

const appendAnexoDocumentoFormalizar = (indexList) => async (dispatch) => {
  try {
    dispatch(actions.appendAnexoDocumentoFormalizar(indexList));
  } catch (e) {
    logger.error(e);
    dispatch(SnackbarActions.addSnackbar('Erro ao adicionar novo anexo.', 'error'));
  }
};

const deleteAnexoDocumentoFormalizar = (guid, indexList) => async (dispatch, getState) => {
  try {
    const documentoList = getState().limitesAprovadosCadastro.listaDocumentosFormalizar.listaDados;
    const indexDocumento = documentoList[indexList].listaArquivos?.findIndex(
      (anexo) => anexo.nomeGuid === guid,
    );

    await service.deleteAnexoDocumentoFormalizar(guid);

    dispatch(actions.deleteAnexoDocumentoFormalizar(indexList, indexDocumento));
    dispatch(SnackbarActions.addSnackbar('Documento excluído com sucesso.', 'success'));
  } catch (e) {
    logger.error(e);
    dispatch(SnackbarActions.addSnackbar('Erro ao excluir documento.', 'error'));
  }
};

export default {
  setSelectedItems,
  getPessoaDocumentacao,
  updateAndSaveStatus,
  uploadDocumento,
  downloadDocumento,
  deleteDocumento,
  setErroDocumento,
  getDocumentosComplementares,
  updateObservacaoDealer,
  updateObservacaoDealerCadastro,
  uploadDocumentoComplementar,
  getDocumentoComplementar,
  deleteDocumentoComplementar,
  getDocumentosFormalizar,
  downloadAnexoDocumentoFormalizar,
  updateDocumentoFormalizar,
  uploadAnexoDocumentoFormalizar,
  appendAnexoDocumentoFormalizar,
  deleteAnexoDocumentoFormalizar,
};
