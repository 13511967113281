const PREFIX = 'comunicadosModal';

const SET_MODAL_OPEN = `${PREFIX}/SET_MODAL_OPEN`;
const SET_DOCUMENTO = `${PREFIX}/SET_DOCUMENTO`;
const RESET_STORE = `${PREFIX}/RESET_STORE`;

const setModalOpen = (isOpen) => ({
  type: SET_MODAL_OPEN,
  payload: { isOpen },
});

const setDocumento = (documento) => ({
  type: SET_DOCUMENTO,
  payload: { documento },
});

const resetStore = () => ({
  type: RESET_STORE,
});

export default {
  types: {
    SET_MODAL_OPEN,
    SET_DOCUMENTO,
    RESET_STORE,
  },

  setModalOpen,
  setDocumento,
  resetStore,
};
