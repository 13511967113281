import { connect } from 'react-redux';

import ListRow from './listRow';

import operations from '../../../../redux/operations';

const mapStateToProps = ({ statementStore }) => ({
  modalIndex: statementStore.modal?.index,
});

const mapDispatchToProps = (dispatch) => ({
  // eslint-disable-next-line max-len
  setModalOpen: (id, tipo, status, produto, index, header) => dispatch(operations.setModalOpen(id, tipo, status, produto, index, header)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListRow);
