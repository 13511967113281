export const getDealerGroups = (dealerStore) => {
  const { dealers } = dealerStore;
  const groups = [];

  dealers.forEach((dealer) => {
    if (dealer.matrizId !== undefined && dealer.matrizId !== null && dealer.matrizId !== 0) {
      if (dealer.matrizId === dealer.dealerId) {
        const dealerMatriz = dealer.dealerCorr.toString();
        const dealerRoot = (dealer.cnpj || '').substring(0, 8);
        const isGroupPresent = groups.some((group) => (
          group.matrizId === dealerMatriz && group.brand === dealer.brand
        ));

        /*
    if (dealer.matrizId !== undefined && dealer.matrizId !== null && dealer.matrizId !== 0) {
      const dealerMatriz = dealer.matrizId.toString();
      const dealerRoot = (dealer.cnpj || '').substring(0, 8);
      const isGroupPresent = groups.some((group) => (
        group.matrizId === dealerMatriz && group.brand === dealer.brand
      )); */

        if (!isGroupPresent) {
          groups.push({
            matrizId: dealerMatriz,
            brand: dealer.brand,
            cnpjRoot: dealerRoot,
            name: dealer.dealerName,
          });
        }
      }
    }
  });

  return groups;
};
