import { connect } from 'react-redux';

import { AuthSelectors } from '../../redux';
import RenderIfLoggedIn from './renderIfLoggedIn';

const mapStateToProps = ({ authStore }) => ({
  isAuthenticated: AuthSelectors.isAuthenticated(authStore),
});

export default connect(mapStateToProps)(RenderIfLoggedIn);
