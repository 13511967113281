import React from 'react';
import _ from 'lodash';
import { useResgateContext } from 'features/carteiraFidc/contexts/resgateContext';
import Loading from 'features/common-staff/layout/loading';
import AlertaMensagem from 'features/common/alertaMensagem';
import JurosCarenciaMessage from '../jurosCarenciaMessage/jurosCarenciaMessage';
import { AlertaContainer, Container, ResgateContainer } from './jurosCarenciaPageContent.style';

const JurosCarenciaPageContent = () => {
  const resgateContext = useResgateContext();
  const { state, addSnackbar, getResgate } = resgateContext;
  const { jurosCarencia, requestStatus } = state;
  const { loading, error } = requestStatus;

  const data = _.isNull(jurosCarencia) || _.isEmpty(jurosCarencia) ? undefined : jurosCarencia;

  const renderContent = () => {
    if (error) {
      return (
        <AlertaContainer>
          <AlertaMensagem
            breakLine
            mensagem={'A carteira FIDC está indisponível no momento.\nPor favor, tente novamente mais tarde.'}
          />
        </AlertaContainer>
      );
    }

    if (loading) {
      return (
        <AlertaContainer>
          <Loading
            delay
            mensagem="Estamos buscando os resgates. Aguarde um instante..."
          />
        </AlertaContainer>
      );
    }

    if (_.isUndefined(data)) {
      return (
        <AlertaContainer>
          <AlertaMensagem
            breakLine
            mensagem="Não existem resgates disponíveis em sua carteira FIDC."
          />
        </AlertaContainer>
      );
    }

    return (
      <ResgateContainer>
        <JurosCarenciaMessage
          jurosCarencia={data}
          addSnackbar={addSnackbar}
          getResgate={getResgate}
        />
      </ResgateContainer>
    );
  };

  return (
    <Container>
      {renderContent()}
    </Container>
  );
};

export default JurosCarenciaPageContent;
