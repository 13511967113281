import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import Button from 'features/common-staff/controls/button';
import './downloadNotaFiscalButton.scss';
import { Tracking } from 'modules';

const useStyles = makeStyles({
  title: {
    marginRight: '12px',
  },
  icon: {
    marginTop: '4px',
  },
});

const DownloadNotaFiscalButton = ({ urlFatura, canDownloadNotaFiscal, isAVista }) => {
  const classes = useStyles();
  return (
    canDownloadNotaFiscal
      ? (
        <Button
          className="common__filters__botao-aplicar__nota-fiscal"
          disabled={isAVista}
          isLoading={false}
          color="darkGray"
          onClick={() => {
            Tracking.Mixpanel.acompanhamentoTd.trackNotaFiscal();
            window.open(urlFatura);
          }}
          fullWidth
        >
          <div className={classes.title}>
            Nota Fiscal
          </div>
        </Button>
      ) : null
  );
};

DownloadNotaFiscalButton.propTypes = {
  urlFatura: PropTypes.string,
  canDownloadNotaFiscal: PropTypes.bool,
  isAVista: PropTypes.object,
};

DownloadNotaFiscalButton.defaultProps = {
  urlFatura: null,
  canDownloadNotaFiscal: false,
  isAVista: null,
};

export default DownloadNotaFiscalButton;
