import { connect } from 'react-redux';
import CardResumoPagamento from './cardResumoPagamento';
import operations from '../../../../../redux/operations';

const mapStateToProps = ({ statementStore }) => ({
  isLoading: statementStore.modal.isLoading,
  resumo: statementStore.modal.detalhes?.resumo,
  tipo: statementStore.modal.tipo,
  status: statementStore.modal.status,
  produto: statementStore.modal.produto,
});

const mapDispatchToProps = (dispatch) => ({
  onComprovanteClicked: (value) => { dispatch(operations.setModalComprovanteOpen(value)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(CardResumoPagamento);
