import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import './menuDropDown.scss';
import PropTypes from 'prop-types';
import KeyBoardArrow from 'assets/icons/key-board-arrow';
import {
  SummaryContainer,
  MenuPadding,
  DetailsContainer,
  DropDownLabel,
  TitleDropDown,
  ContainerSubmenu,
  SpanContainer,
  Span,
  Border,
} from './menuDropDown.style';

const MenuDropDown = ({
  Icon,
  isActive,
  titleDropdown,
  options,
  dataCy,
  trackingMixPanel,
  disabled,
}) => {
  const [isRotated, setIsRotated] = useState(false);
  const handleIconClick = () => {
    setIsRotated(!isRotated);
  };
  const { url } = useRouteMatch();
  const history = useHistory();
  const navTo = (item) => {
    history.replace(`${url}/${item}`);
  };

  const selected = options && options.some((item) => item.isActive);
  return (
    <MenuPadding>
      <DetailsContainer>
        <SummaryContainer
          isActive={isActive}
          onClick={handleIconClick}
          selected={selected}
          data-cy="MenuResgateSummary"
        >
          <DropDownLabel>
            {Icon
              && (
              <Icon
                color={isActive ? '#505669' : '#7C839A'}
                selected={selected}
              />
              )}
            {' '}
            <TitleDropDown
              isActive={isActive}
              selected={selected}
            >
              {titleDropdown}
            </TitleDropDown>
            <KeyBoardArrow
              color={isActive ? '#505669' : '#7C839A'}
              selected={selected}
              rotate={isRotated}
              isActive={isActive}
            />
          </DropDownLabel>
        </SummaryContainer>
        {!disabled && isActive && options && options.map((item) => (
          <ContainerSubmenu
            disabled={item.disabled}
            onClick={() => {
              if (!item.disabled) {
                trackingMixPanel();
                navTo(item.urlRedirect);
              }
            }}
            key={item.urlRedirect}
            isActive={item.isActive}
            data-cy={dataCy}
          >
            <SpanContainer>
              <Border isActive={item.isActive} />
              <Span isActive={item.isActive}>
                {item.title}
              </Span>
            </SpanContainer>
          </ContainerSubmenu>
        ))}
      </DetailsContainer>
    </MenuPadding>
  );
};

MenuDropDown.propTypes = {
  isActive: PropTypes.bool.isRequired,
  Icon: PropTypes.any,
  titleDropdown: PropTypes.string.isRequired,
  dataCy: PropTypes.string,
  options: PropTypes.array,
  trackingMixPanel: PropTypes.func,
  disabled: PropTypes.bool,
};

MenuDropDown.defaultProps = {
  Icon: null,
  dataCy: null,
  options: [],
  trackingMixPanel: () => {},
  disabled: false,
};

export default MenuDropDown;
