import * as React from "react";

const ReloadIcon = (props) => {
	return (
		<svg focusable="false" aria-hidden="true" width="12" height="12" {...props}>
			<path d="M9.76684 2.23329C8.68018 1.14662 7.14018 0.519954 5.44684 0.693287C3.00018 0.939954 0.986844 2.92662 0.71351 5.37329C0.346844 8.60662 2.84684 11.3333 6.00018 11.3333C8.12684 11.3333 9.95351 10.0866 10.8068 8.29329C11.0202 7.84662 10.7002 7.33329 10.2068 7.33329C9.96018 7.33329 9.72684 7.46662 9.62018 7.68662C8.86684 9.30662 7.06018 10.3333 5.08684 9.89329C3.60684 9.56662 2.41351 8.35995 2.10018 6.87995C1.54018 4.29329 3.50684 1.99995 6.00018 1.99995C7.10684 1.99995 8.09351 2.45995 8.81351 3.18662L7.80684 4.19329C7.38684 4.61329 7.68018 5.33329 8.27351 5.33329H10.6668C11.0335 5.33329 11.3335 5.03329 11.3335 4.66662V2.27329C11.3335 1.67995 10.6135 1.37995 10.1935 1.79995L9.76684 2.23329Z" fill="#ED5C6F"/>
		</svg>
	);
}

export default ReloadIcon;
