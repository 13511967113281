import React from 'react';
import PropTypes from 'prop-types';

import EmptyStateIcon from '../../../../../assets/icons/empty-state';

import './testDriveNoResults.scss';

const message = 'Caro concessionário, obrigado por acessar a nossa Plataforma Digital - Hub. Informamos que neste momento não existem modelos com ofertas disponíveis. Tão logo seja disponibilizado, a visualização/solicitação de pedido deverá ser feita por aqui.';

const TestDriveNoResults = ({ isError }) => (
  <div className="td__no-results">
    <div className="td__no-results__image-container">
      <EmptyStateIcon className="td__no-results__image" />
    </div>
    <div className="td__no-results__text-big">
      <span className="empty-state-span-bigger">
        { (isError) ? (
          'Sem conexão com o servidor'
        ) : (
          'Nenhuma oferta disponível'
        )}
      </span>
    </div>
    <div className="td__no-results__text-small">
      { (isError) ? (
        <div>Tente novamente mais tarde.</div>
      ) : (
        <>
          <div style={{ width: 640, textAlign: 'justify' }}>
            {message}
          </div>
          <div>
            Continue acessando e acompanhando.
          </div>
        </>
      )}
    </div>
  </div>
);

TestDriveNoResults.propTypes = {
  isError: PropTypes.bool,
};

TestDriveNoResults.defaultProps = {
  isError: false,
};

export default TestDriveNoResults;
