import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import { Currency } from '../../../../common';

import './testDriveConditionCard.scss';

function TestDriveConditionCard({
  title, discount, dueDate, installmentsNumber, tax, coefficient, simulatedValue,
  isSelected, onClick,
}) {
  const legalTextConditionCard = '* Sujeito a análise e aprovação de crédito';
  return (
    <Card
      className={`td-cond-selector-card ${(isSelected)
        ? 'td-cond-selector-card-selected' : ''}
        `}
      variant="outlined"
    >
      <div onClick={() => onClick()} className="td-cond-selector-card-clickable" role="button" tabIndex="0">
        <div className="td-cond-selector-card-title-container">
          <span className="td-cond-selector-card-title-text">{title}</span>
        </div>
        <div className="td-cond-selector-card-content-container">
          <div className="td-cond-selector-card-content-item">
            <span className="td-cond-selector-card-content-header-number">
              {discount}
              %
            </span>
            <span className="td-cond-selector-card-content-header-text">de desconto</span>
          </div>
          <div className="td-cond-selector-card-content-item">
            <span className="td-cond-selector-card-content-center-text">
              {installmentsNumber}
              {' '}
              {installmentsNumber > 1
                ? 'parcelas' : 'parcela'}
            </span>
          </div>
          <div className="td-cond-selector-card-content-item">
            <span className="td-cond-selector-card-content-offer-description-text">1º VENCIMENTO</span>
            <span className="td-cond-selector-card-content-taxes-number">{`${dueDate} dias`}</span>
          </div>
          <div className="td-cond-selector-card-content-item">
            <div className="td-cond-selector-card-content-offer-description">
              <span className="td-cond-selector-card-content-offer-description-text">TAXA MÊS</span>
              <span className="td-cond-selector-card-content-taxes-number">
                {tax}
                %
              </span>
            </div>
            <div className="td-cond-selector-card-content-offer-description">
              <span className="td-cond-selector-card-content-offer-description-text td-cond-selector-card-content-offer-description-text-right">COEFICIENTE</span>
              <span className="td-cond-selector-card-content-taxes-number td-cond-selector-card-content-taxes-number-right">{coefficient}</span>
            </div>
          </div>
          <div className="td-cond-selector-card-content-item" style={{ display: 'none' }}>
            <span className="td-cond-selector-card-content-offer-description-text">VALOR SIMULADO</span>
            <span className="td-cond-selector-card-content-taxes-number">
              <Currency className="td-cond-selector-card-content-taxes-number" noColor explicitSign={false} value={simulatedValue} />
            </span>
          </div>
          <div className="td-cond-selector-card-content-item">
            <span className="td-cond-selector-card-content-offer-description-note">{legalTextConditionCard}</span>
          </div>
        </div>
      </div>
    </Card>
  );
}

TestDriveConditionCard.propTypes = {
  title: PropTypes.string.isRequired,
  discount: PropTypes.number.isRequired,
  dueDate: PropTypes.number.isRequired,
  installmentsNumber: PropTypes.number.isRequired,
  tax: PropTypes.number.isRequired,
  coefficient: PropTypes.number.isRequired,
  simulatedValue: PropTypes.number.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

TestDriveConditionCard.defaultProps = {
  isSelected: false,
  onClick: () => { },
};

export default TestDriveConditionCard;
