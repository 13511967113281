import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@mui/material';
import colors from 'assets/styles/colors';

const useStyles = makeStyles({
  green: (props) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: props.width,
    height: props.height,
    backgroundColor: colors.success_color_100,
    color: colors.terciary_color_600,
    borderRadius: props.borderRadius,
    fontSize: '10px',
    fontWeight: '450',
    lineHeight: '16px',
    textAlign: 'center',
  }),
  red: (props) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: props.width,
    height: props.height,
    backgroundColor: colors.error_color_100_46,
    color: colors.error_color_400,
    borderRadius: props.borderRadius,
    fontSize: '10px',
    fontWeight: '450',
    lineHeight: '16px',
    textAlign: 'center',
  }),
});

const BadgeStatus = ({
  status, children, width, height, borderRadius,
}) => {
  const classes = useStyles({ width, height });

  const setColor = () => {
    switch (status.toLowerCase()) {
      case 'aceito':
        return classes.green;
      case 'negado':
        return classes.red;
      default: return classes.red;
    }
  };

  return (
    <Box
      width={width}
      height={height}
      status={status}
      className={setColor()}
      borderRadius={borderRadius}
    >
      {children}
    </Box>
  );
};

BadgeStatus.propTypes = {
  status: PropTypes.string,
  children: PropTypes.any,
  width: PropTypes.string,
  height: PropTypes.string,
  borderRadius: PropTypes.string,
};

BadgeStatus.defaultProps = {
  status: 'negado',
  children: null,
  width: '44px',
  height: '16px',
  borderRadius: '4px',
};

export default BadgeStatus;
