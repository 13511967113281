import React from 'react';
import PropTypes from 'prop-types';
import { formatPctg } from 'utils/format';
import './customTooltip.scss';

const CustomTooltip = ({ payload }) => (
  <div className="tooltip__carteira">
    <div className="tooltip__carteira__cor" style={{ backgroundColor: payload[0]?.payload?.fill }} />
    {formatPctg(payload[0]?.payload.percentage)}
  </div>
);

CustomTooltip.propTypes = {
  payload: PropTypes.arrayOf(PropTypes.object),
};

CustomTooltip.defaultProps = {
  payload: [],
};
export default CustomTooltip;
