import React, {
  createContext, useContext, useEffect, useReducer, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import useCustomFetch from 'hooks/useFetch';
import { applyProperty } from 'utils/object';
import onboardingService from '../services/onboardingService';

const onboardingOrder = [
  'HISTORICO_DUPLICATA',
  'MENU_RESGATE',
];

const createOnboarding = (values) => {
  const activeOnboardingList = onboardingOrder.filter((x) => !values.includes(x));

  const onboardingList = activeOnboardingList.reduce((ac, onboard, i) => {
    const auxList = ac;
    auxList[onboard] = { active: i === 0, ready: false };
    return auxList;
  }, {});

  return {
    queue: activeOnboardingList,
    onboarding: onboardingList,
  };
};

const initialState = () => ({
  queue: [],
  onboarding: {},

  requestStatus: {
    loading: false,
    success: false,
    error: null,
  },

  duplicatas: false,
});

const reducer = (state, action) => {
  switch (action.type) {
    case 'response':
      return action.requestStatus?.success
        ? {
          ...state,
          requestStatus: {
            ...action.requestStatus,
          },
          ...createOnboarding(action.data),
        } : {
          ...state,
          requestStatus: {
            ...action.requestStatus,
          },
        };

    case 'update':
      return action.next
        ? {
          ...state,
          onboarding: {
            ...state.onboarding,
            [action.current]: {
              ...state.onboarding[action.current],
              active: false,
            },
            [action.next]: {
              ...state.onboarding[action.next],
              active: true,
            },
          },
          queue: state.queue.slice(1),
        } : {
          ...state,
          onboarding: {
            ...state.onboarding,
            [action.current]: {
              ...state.onboarding[action.current],
              active: false,
            },
          },
          queue: state.queue.slice(1),
        };
    case 'ready':
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          [action.propertyName]: {
            ...state.onboarding[action.propertyName],
            ready: true,
          },
        },
      };
    case 'active':
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          [action.current]: {
            ...state.onboarding[action.current],
            active: false,
          },
          [action.next]: {
            ...state.onboarding[action.next],
            active: true,
          },
        },
        queue: [action.next, ...state.queue.filter((item) => item !== action.next)],
      };
    case 'apply':
      return applyProperty(
        { ...state },
        action.propertyName,
        action.value,
      );
    default: return state;
  }
};

const OnboardingContext = createContext(null);

export const OnboardingContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState());

  const useGetControleOnboarding = useCallback(
    async () => onboardingService.getControleUsuario(),
    [],
  );

  const [response, getControleOnboarding] = useCustomFetch(useGetControleOnboarding, []);

  useEffect(() => {
    const { data, ...requestStatus } = response;
    dispatch({ type: 'response', data, requestStatus });
  }, [response]);

  const updateControleUsuario = useCallback(async () => {
    if (state?.queue.length) {
      const current = state.queue[0];
      if (state.queue.length === 1) {
        dispatch({ type: 'update', current, next: null });
      } else {
        const next = state.queue[1];
        dispatch({ type: 'update', current, next });
      }
      await onboardingService.updateControleUsuario(current);
    }
  }, [state]);

  const activeControleUsuario = useCallback((onboarding) => {
    if (Object.prototype.hasOwnProperty.call(state?.onboarding, onboarding)
        && !state?.onboarding[onboarding].active
        && state?.queue.length
        && state?.queue.includes(onboarding)) {
      const current = state.queue[0];
      dispatch({ type: 'active', current, next: onboarding });
    }
  }, [state]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const onboardingContextStore = {
    state,
    dispatch,
    getControleOnboarding,
    updateControleUsuario,
    activeControleUsuario,
    readyControleUsuario: (value) => dispatch({ type: 'ready', propertyName: value }),
    applyPropertyControle: (propertyName) => dispatch({ type: 'apply', value: true, propertyName }),
  };

  return (
    <OnboardingContext.Provider value={onboardingContextStore}>
      {children}
    </OnboardingContext.Provider>
  );
};

OnboardingContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

OnboardingContextProvider.defaultProps = {
  children: null,
};

export const useOnboardingContext = () => useContext(OnboardingContext);
