import * as React from "react";

function DocumentoIcon(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            {...props}
        >
            <path d="M13.5686 3.33817C13.5671 3.33672 13.5658 3.33528 13.5645 3.33368C13.5627 3.33166 13.5608 3.32949 13.5588 3.32747L13.4363 3.19383L11.0099 0.545081C10.7401 0.250486 10.382 0.0652246 9.99006 0.0143176C9.98963 0.0143176 9.98919 0.0143176 9.98876 0.0141729C9.98355 0.0135945 9.97835 0.0128714 9.973 0.0122929C9.90647 0.00419403 9.83922 0 9.77154 0H3.05225C2.2979 0 1.68412 0.613632 1.68412 1.36798V2.03339H1.21656C0.545804 2.03339 0 2.57919 0 3.25024V17.0962C0 18.1217 0.83418 18.9559 1.8597 18.9559H10.3591C11.0299 18.9559 11.5757 18.4101 11.5757 17.7393V17.1596H12.6319C13.3862 17.1596 14 16.5458 14 15.7916V4.46203C14 4.04551 13.8468 3.64694 13.5686 3.33817ZM12.9907 3.80342H10.4675C10.3211 3.80342 10.2021 3.68439 10.2021 3.53804V0.845316C10.2996 0.895645 10.3885 0.963039 10.4638 1.04533L12.8147 3.61136L12.9907 3.80342ZM12.632 16.4191H3.05225C2.70617 16.4191 2.42459 16.1375 2.42459 15.7916V14.0688C2.42459 13.8643 2.25885 13.6986 2.05436 13.6986C1.85001 13.6986 1.68412 13.8643 1.68412 14.0688V15.7916C1.68412 16.5459 2.2979 17.1596 3.05225 17.1596H10.8352V17.7393C10.8352 18.0018 10.6216 18.2154 10.3591 18.2154H1.8597C1.24259 18.2154 0.740465 17.7133 0.740465 17.0962V3.25024C0.740465 2.98746 0.954072 2.77385 1.21656 2.77385H1.68412V11.107C1.68412 11.3115 1.85001 11.4772 2.05436 11.4772C2.25885 11.4772 2.42459 11.3115 2.42459 11.107V1.36798C2.42459 1.02204 2.70617 0.740465 3.05225 0.740465H9.46161V3.53804C9.46161 4.09266 9.91283 4.54388 10.4675 4.54388H13.2595V15.7916C13.2595 16.1375 12.978 16.4191 12.632 16.4191Z"  />
            <path d="M4.40783 5.27538H8.36614C8.57049 5.27538 8.73637 5.10964 8.73637 4.90514C8.73637 4.70065 8.57049 4.53491 8.36614 4.53491H4.40783C4.20333 4.53491 4.0376 4.70065 4.0376 4.90514C4.0376 5.10964 4.20333 5.27538 4.40783 5.27538Z"  />
            <path d="M10.2122 7.59729H4.40783C4.20333 7.59729 4.0376 7.76303 4.0376 7.96752C4.0376 8.17202 4.20333 8.33776 4.40783 8.33776H10.2122C10.4167 8.33776 10.5825 8.17202 10.5825 7.96752C10.5825 7.76303 10.4167 7.59729 10.2122 7.59729Z"  />
            <path d="M10.2122 10.6597H4.40783C4.20333 10.6597 4.0376 10.8254 4.0376 11.0299C4.0376 11.2344 4.20333 11.4001 4.40783 11.4001H10.2122C10.4167 11.4001 10.5825 11.2344 10.5825 11.0299C10.5825 10.8254 10.4167 10.6597 10.2122 10.6597Z"  />
            <path d="M10.2122 13.7219H4.40783C4.20333 13.7219 4.0376 13.8877 4.0376 14.0922C4.0376 14.2967 4.20333 14.4624 4.40783 14.4624H10.2122C10.4167 14.4624 10.5825 14.2967 10.5825 14.0922C10.5825 13.8877 10.4167 13.7219 10.2122 13.7219Z"  />
            <path d="M2.05444 12.2181C1.84994 12.2181 1.6842 12.3845 1.6842 12.5888C1.6842 12.7933 1.84994 12.959 2.05444 12.959C2.25879 12.959 2.42467 12.7933 2.42467 12.5888V12.5879C2.42467 12.3834 2.25879 12.2181 2.05444 12.2181Z"  />
        </svg>
    );
}

export default DocumentoIcon;

