import React from 'react';
import PropTypes from 'prop-types';

import Button from 'features/common-staff/controls/button';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Modal from '@material-ui/core/Modal';
import colors from 'assets/styles/colors';
import NewButton from 'features/common/newButton/newButton';
import ButtonTooltipIcon from '../buttonTooltipIcon';
import { Container, Header } from './alertModal.style';

const AlertModal = ({
  buttonAction, title, subtitle, textRedButton, textGrayButton, openModal, setOpen,
  disableAction, isLoadingAction, closeOnSubmit, mixpanelTarget, mixpanelPage, children,
  alertCardTitle, icone, colorBase, limitesAprovados, openPopper, setOpenPopper,
}) => {
  const handleClose = () => {
    setOpenPopper(false);
    setOpen(false);
  };

  const onClick = () => {
    if (closeOnSubmit) handleClose();
    buttonAction();
  };

  const handleClick = () => {
    if (closeOnSubmit) handleClose();
    if (openPopper) {
      setOpenPopper(true);
    } else {
      setOpenPopper(false);
      buttonAction();
    }
  };

  const renderButtonSave = () => {
    if (limitesAprovados) {
      return (
        <NewButton
          className="gray"
          onClick={handleClick}
          openPopper={openPopper}
          alertCardTitle={alertCardTitle}
          icone={icone}
          colorBase={colorBase}
        >
          <span>Salvar</span>
        </NewButton>
      );
    }
    return (
      <Button
        color="darkGray"
        onClick={() => onClick()}
        disabled={disableAction}
        isLoading={isLoadingAction}
        mixpanelTarget={mixpanelTarget}
        mixpanelPage={mixpanelPage}
      >
        {textRedButton}
      </Button>
    );
  };
  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      disableEscapeKeyDown
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Container>
        <div className="modal-header">
          <div className="message">
            <Header>
              <WarningRoundedIcon style={{ color: colors.error_color_300 }} />
              <span className="title">{title}</span>
              <ButtonTooltipIcon buttonAction={() => handleClose()} title="Fechar" className="close-button">
                <CloseRoundedIcon />
              </ButtonTooltipIcon>
            </Header>
            <span className="subtitle">{subtitle}</span>
          </div>
        </div>
        {children && (
        <div className="modal-body">
          {children}
        </div>
        )}
        <div className="modal-footer">
          <div className="return-button">
            <Button
              color="darkGrayBorder"
              onClick={() => handleClose()}
            >
              {textGrayButton}
            </Button>
          </div>
          <div>
            {renderButtonSave()}
          </div>
        </div>
      </Container>
    </Modal>
  );
};

AlertModal.propTypes = {
  setOpen: PropTypes.func,
  buttonAction: PropTypes.func,
  openModal: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  textRedButton: PropTypes.string,
  textGrayButton: PropTypes.string,
  disableAction: PropTypes.bool,
  closeOnSubmit: PropTypes.bool,
  isLoadingAction: PropTypes.bool,
  mixpanelTarget: PropTypes.string,
  mixpanelPage: PropTypes.string,
  children: PropTypes.element,
  alertCardTitle: PropTypes.string,
  icone: PropTypes.element,
  colorBase: PropTypes.string,
  limitesAprovados: PropTypes.bool,
  openPopper: PropTypes.bool,
  setOpenPopper: PropTypes.func,
};

AlertModal.defaultProps = {
  setOpen: () => {},
  buttonAction: () => {},
  openModal: false,
  title: '',
  subtitle: '',
  textRedButton: '',
  textGrayButton: 'Voltar',
  disableAction: false,
  isLoadingAction: false,
  closeOnSubmit: true,
  mixpanelTarget: null,
  mixpanelPage: null,
  children: null,
  alertCardTitle: '',
  icone: null,
  colorBase: colors.alert_color_200,
  limitesAprovados: false,
  openPopper: false,
  setOpenPopper: () => {},
};

export default AlertModal;
