import { connect } from 'react-redux';

import EditButton from './editButton';
import operations from '../../../../redux/operations';

const mapDispatchToProps = (dispatch) => ({
  onClick: (invoiceId) => dispatch(operations.toggleEdit(invoiceId)),
});

export default connect(null, mapDispatchToProps)(EditButton);
