import { connect } from 'react-redux';

import { Tracking } from 'modules';
import DateRange from './dateRange';

import operations from '../../../redux/operations';

const mapStateToProps = ({ relatoriosIof }) => ({
  isLoading: relatoriosIof.requestStatus.isLoading,
  startDate: relatoriosIof.filtros.dataInicio,
  endDate: relatoriosIof.filtros.dataFim,
});

const mapDispatchToProps = (dispatch) => ({
  setStartDate: (value) => {
    dispatch(operations.setDateRange(value, 'dataInicio', true));
  },
  setEndDate: (value) => {
    dispatch(operations.setDateRange(value, 'dataFim', false));
    Tracking.Mixpanel.relatoriosIof.filtros.trackFiltroData();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DateRange);
