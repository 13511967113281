import { connect } from 'react-redux';

import AnoSelector from './anoSelector';
import operations from '../../../redux/operations';
import selector from '../../../redux/selector';

const mapStateToProps = ({ gestaoPagamentos }) => ({
  ano: gestaoPagamentos?.carteira?.modalTaxas?.filters?.ano,
  anosList: selector.getListAnos(gestaoPagamentos),
  isLoading: gestaoPagamentos?.carteira?.modalTaxas?.requestStatus?.isLoading,
  isBrandSelected: selector.isBrandSelected(gestaoPagamentos),
});

const mapDispatchToProps = (dispatch) => ({
  setAno: (value) => dispatch(operations.setFilters('ano', value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnoSelector);
