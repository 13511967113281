import React from 'react';
import PropTypes from 'prop-types';

import InputConcessionariaNome from '../formFieldsModal/inputConcessionariaNome';
import InputConcessionariaCodigo from '../formFieldsModal/inputConcessionariaCodigo';
import InputConcessionariaCnpj from '../formFieldsModal/inputConcessionariaCnpj';
import InputRegiaoCodigo from '../formFieldsModal/inputRegiaoCodigo';
import InputRegiaoNome from '../formFieldsModal/inputRegiaoNome';
import InputCodigoSap from '../formFieldsModal/inputCodigoSap';
import InputProduto from '../formFieldsModal/inputProduto';

import './formSectionModal.scss';

export const FormSectionModal = ({
  codigoSap, selectedDealerId, selectedProduct,
  produtos, concessionarias, title,
}) => {
  const selectedDealer = (concessionarias?.find((c) => c.dealerId === selectedDealerId));
  const produto = (produtos.find((p) => p.value.toString() === selectedProduct));
  return (
    <div className="transferencia-limite__dados__container">
      <div
        className="transferencia-limite__dados__container__concessionaria"
        data-cy="TransfLimiteDadosContainerConcessionaria"
      >
        <InputConcessionariaNome
          title={title}
          concessionariaNome={selectedDealer?.dealerName}
        />
      </div>
      <div
        className="transferencia-limite__dados__container_codanagrafico"
        data-cy="TransfLimiteDadosContainerCodanagrafico"
      >
        <InputConcessionariaCodigo
          concessionariaCodigo={selectedDealer?.dealerCorr}
        />
      </div>
      <div
        className="transferencia-limite__dados__container_cnpj"
        data-cy="TransfLimiteDadosContainerCnpj"
      >
        <InputConcessionariaCnpj
          concessionariaCnpj={selectedDealer?.cnpj}
        />
      </div>
      <div
        className="transferencia-limite__dados__container_codregional"
        data-cy="TransfLimiteDadosContainerCodregional"
      >
        <InputRegiaoCodigo
          regiaoCodigo={selectedDealer?.regionId}
        />
      </div>
      <div
        className="transferencia-limite__dados__container_regional"
        data-cy="TransfLimiteDadosContainerRegional"
      >
        <InputRegiaoNome
          regiaoNome={selectedDealer?.region}
        />
      </div>
      <div
        className="transferencia-limite__dados__container_sap"
        data-cy="TransfLimiteDadosContainerSap"
      >
        <InputCodigoSap
          codigoSap={codigoSap}
        />
      </div>
      <div
        className="transferencia-limite__dados__container_produto"
        data-cy="TransfLimiteDadosContainerProduto"
      >
        <InputProduto
          produto={produto?.label}
        />
      </div>
    </div>
  );
};

FormSectionModal.propTypes = {
  codigoSap: PropTypes.any,
  selectedDealerId: PropTypes.number,
  selectedProduct: PropTypes.string,
  produtos: PropTypes.array,
  concessionarias: PropTypes.array,
  title: PropTypes.string,
};

FormSectionModal.defaultProps = {
  codigoSap: '',
  selectedDealerId: null,
  selectedProduct: null,
  concessionarias: [],
  produtos: [],
  title: null,
};

export default FormSectionModal;
