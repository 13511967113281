import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

import ProdutoIcon from '../../../../../../../../common/produtoIcon';
import './invoiceIcon.scss';

const setClassStatus = (status) => {
  switch (status.toLowerCase().trim()) {
    case 'quitada':
      return 'icon-green';
    case 'em aberto':
      return 'icon-blue';
    default:
      return 'icon-red';
  }
};

const InvoiceIcon = ({
  product, status,
}) => (
  <Tooltip title={product}>
    <div
      className={`carteira-page__identificador__container__logo ${setClassStatus(status)}`}
    >
      <ProdutoIcon product={product} status={status} />
    </div>
  </Tooltip>
);

InvoiceIcon.propTypes = {
  status: PropTypes.string,
  product: PropTypes.string,
};

InvoiceIcon.defaultProps = {
  status: undefined,
  product: undefined,
};

export default InvoiceIcon;
