import { connect } from 'react-redux';

import AumentoCard from './aumentoCard';

const mapStateToProps = ({ limiteCreditoStore }) => ({
  solicitacao: limiteCreditoStore.acompanhamento.detalhes.solicitacao?.solicitacao,
  valor: limiteCreditoStore.acompanhamento.detalhes.solicitacao?.valor,
  solicitanteEmail: limiteCreditoStore.acompanhamento.detalhes.solicitacao?.solicitanteEmail,
  concessionaria: limiteCreditoStore.acompanhamento.detalhes.solicitacao?.concessionariaOrigem,
  produto: limiteCreditoStore.acompanhamento.detalhes.solicitacao?.produtoOrigem,
  brand: limiteCreditoStore.acompanhamento.detalhes.solicitacao?.concessionariaOrigem.marca,
});

export default connect(mapStateToProps, null)(AumentoCard);
