import React from 'react';
import PropTypes from 'prop-types';
import { Tracking } from 'modules';

import Select from '../../../../../../../common/select';

const defaultStatus = [
  { text: 'Todos os status', value: 'all' },
];

const StatusSelect = ({
  status, setStatus, statusList, isLoading,
}) => (
  <Select
    items={defaultStatus.concat(statusList)}
    label="Status"
    value={status || 'all'}
    onSelect={(r) => {
      Tracking.Mixpanel.carteira.carteiraFilters.trackTypeStatusFilter(r);
      setStatus(r === 'all' ? null : r);
    }}
    disabled={isLoading}
  />
);

StatusSelect.propTypes = {
  status: PropTypes.string,
  setStatus: PropTypes.func,
  statusList: PropTypes.array,
  isLoading: PropTypes.bool,
};

StatusSelect.defaultProps = {
  status: null,
  setStatus: () => {},
  statusList: null,
  isLoading: false,
};

export default StatusSelect;
