import React from 'react';
import PropTypes from 'prop-types';
import { Tracking } from 'modules';
import NewBasicSelect from 'features/common/newBasicSelect/newBasicSelect';

const flags = [
  { label: 'Atualizados hoje', value: 'false' },
  { label: 'Atualizados até ontem', value: 'true' },
];

const DataSelector = ({
  valor, setValor,
}) => (
  <NewBasicSelect
    selectedOption={valor ? 'true' : 'false'}
    setOption={(b) => {
      Tracking.Mixpanel.dashboard.filtros.trackSelectData();
      setValor(b === 'true');
    }}
    options={flags}
    renderAllOptions={false}
    nameLabel="Exibição de dados"
  />
);

DataSelector.propTypes = {
  valor: PropTypes.bool,
  setValor: PropTypes.func,
};

DataSelector.defaultProps = {
  valor: false,
  setValor: () => { },
};

export default DataSelector;
