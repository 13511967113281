/* eslint-disable class-methods-use-this */
import axios from 'axios';

class UserFeedbackService {
  async postAnswer(answerDto) {
    const response = await axios.post(
      `${window.env.REACT_APP_API_URL}/feedback-user`,
      answerDto,
    );

    if (!response || response.status !== 200) {
      throw new Error('Error submitting user feedback');
    }
  }
}

export default new UserFeedbackService();
