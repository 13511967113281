import { connect } from 'react-redux';
import * as limiteCreditoOperations from '../../../redux/operations';

import InputBrand from './inputBrand';

const mapStateToProps = ({ limiteCreditoStore }) => ({
  brand: limiteCreditoStore.alteracaoLimite.dados.brand,
});

const mapDispatchToProps = (dispatch) => ({
  setBrand: (brand) => dispatch(
    limiteCreditoOperations.setBrand(brand),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(InputBrand);
