import React from 'react';
import PropTypes from 'prop-types';

import DetailsTimeLine from 'features/common/detailsTimeLine/detailsTimeLine';
import './cardHistorico.scss';
import SummaryPage from '../summaryPage';

const CardHistorico = ({
  textCardReceive, open, handleOpenChange,
}) => (
  <SummaryPage level={3} title="Histórico da proposta" open={open} handleOpenChange={handleOpenChange}>
    <div className="historico-text">
      <DetailsTimeLine
        items={textCardReceive}
      />
    </div>
  </SummaryPage>
);

CardHistorico.propTypes = {
  textCardReceive: PropTypes.array,
  open: PropTypes.bool,
  handleOpenChange: PropTypes.func,
};

CardHistorico.defaultProps = {
  textCardReceive: [],
  open: false,
  handleOpenChange: () => { },
};
export default CardHistorico;
