import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from 'utils/format';

import './table.scss';

const Table = ({ data, className }) => (
  <div className={`dashboard-floor-plan__table ${className}`}>
    <div className="dashboard-floor-plan__table__row">
      <div className="dashboard-floor-plan__table_produto" />
      <div className="dashboard-floor-plan__table_vencido title">
        Vencido
      </div>
      <div className="dashboard-floor-plan__table_a-vencer title">
        A vencer
      </div>
      <div className="dashboard-floor-plan__table_total title">
        Total
      </div>
    </div>
    <div className="dashboard-floor-plan__table__row">
      <div className="dashboard-floor-plan__table_produto title">
        Floor Plan
      </div>
      <div className="dashboard-floor-plan__table_vencido">
        {formatCurrency(data?.floorPlan?.vencido)}
      </div>
      <div className="dashboard-floor-plan__table_a-vencer">
        {formatCurrency(data?.floorPlan?.aVencer)}
      </div>
      <div className="dashboard-floor-plan__table_total">
        {formatCurrency(data?.floorPlan?.total)}
      </div>
    </div>
    <div className="dashboard-floor-plan__table__row">
      <div className="dashboard-floor-plan__table_produto title">
        Fundão
      </div>
      <div className="dashboard-floor-plan__table_vencido">
        {formatCurrency(data?.fundao?.vencido)}
      </div>
      <div className="dashboard-floor-plan__table_a-vencer">
        {formatCurrency(data?.fundao?.aVencer)}
      </div>
      <div className="dashboard-floor-plan__table_total">
        {formatCurrency(data?.fundao?.total)}
      </div>
    </div>
  </div>
);

Table.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
};

Table.defaultProps = {
  data: null,
  className: '',
};

export default Table;
