/* eslint-disable lines-between-class-members */

export class UserInfo {
  // TODO: [blanc] validar que o objeto foi construido com todos os campos
  // e.g.: https://github.com/alexeyraspopov/dataclass
  constructor(data) {
    Object.assign(this, data);
  }

  usuarioId;
  usuarioEmail;
  usuarioNome;
  concessionariaId;
  concessionariaNome;
  regional;
  regionalCodigo;
}
