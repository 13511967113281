import * as React from "react";

const PeugeotLogoIcon = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M20 0H12C5.37258 0 0 5.37258 0 12V20C0 26.6274 5.37258 32 12 32H20C26.6274 32 32 26.6274 32 20V12C32 5.37258 26.6274 0 20 0Z" fill="black" />
            <path d="M4.43246 16.1851V16.1845L5.32773 16.1832C5.50802 16.1814 5.57307 16.113 5.57427 15.943V15.6223C5.57307 15.4485 5.50667 15.372 5.3366 15.3714L3.50623 15.3689C3.426 15.3689 3.3949 15.3967 3.39686 15.4777L3.39626 16.0523C3.39175 16.1623 3.43727 16.1883 3.53718 16.1863L4.43246 16.1845M3.3949 16.5448V17.2308C3.28313 17.2308 3.18637 17.2322 3.08902 17.2302C2.99602 17.2283 3.01811 17.1557 3.0175 17.1019L3.0169 15.446C3.0169 15.3088 3.0169 15.171 3.0169 15.0193L3.16925 15.0104C3.85464 15.0098 4.54063 15.0091 5.22602 15.011L5.47767 15.0331C5.79062 15.0958 5.95047 15.281 5.95498 15.5991C5.95753 15.7849 5.95363 15.9733 5.92463 16.156C5.89488 16.3444 5.75772 16.4569 5.57623 16.5024L5.26012 16.5424C4.69492 16.5462 4.12897 16.5448 3.56377 16.5448C3.5151 16.5448 3.46702 16.5448 3.3949 16.5448Z" fill="white" />
			<path d="M9.63535 15.3695H9.46904C8.76907 15.3695 8.06926 15.3721 7.36929 15.3664C7.24415 15.3652 7.20118 15.4024 7.2144 15.525V15.7786C7.20433 15.8866 7.2479 15.9152 7.35157 15.9146L9.1971 15.912C9.37739 15.912 9.37739 15.912 9.37739 16.0922V16.2679H7.2183C7.2183 16.4601 7.2138 16.6365 7.22386 16.8123C7.22522 16.8325 7.30604 16.8641 7.35036 16.8641L9.1959 16.8666C9.2584 16.8666 9.32045 16.8666 9.38295 16.8666C9.65488 16.8666 9.65548 16.8666 9.64602 17.1366L9.64031 17.1885C9.59794 17.2245 9.56324 17.2302 9.52913 17.2302L6.96155 17.2314C6.87936 17.2314 6.82934 17.2245 6.83009 17.1195L6.83189 15.0875C6.83189 15.0698 6.8358 15.0522 6.83895 15.0225H9.63535V15.3695Z" fill="white"/>
			<path d="M10.6016 15.0176H10.9752C10.9778 15.07 10.9821 15.1167 10.9821 15.1636L10.9829 16.6208C10.9829 16.8029 11.0404 16.8661 11.2199 16.8667L12.9717 16.8675C13.1469 16.8667 13.2064 16.8011 13.207 16.6184V15.1875C13.207 15.1351 13.207 15.0826 13.207 15.0207H13.5984C13.5984 15.1971 13.5984 15.3652 13.5984 15.5341L13.5978 16.6038C13.5958 17.0096 13.4561 17.1779 13.0527 17.2151C12.7916 17.2392 12.5273 17.2348 12.2644 17.2315C11.8642 17.2271 11.462 17.2291 11.0631 17.1974C10.7634 17.1722 10.6079 16.9863 10.6035 16.6878L10.6016 15.0176Z" fill="white"/>
			<path d="M17.1424 16.2789H15.988V15.9268C16.0272 15.923 16.0619 15.9167 16.0961 15.9167L17.3929 15.9142C17.4991 15.913 17.5306 15.9483 17.5287 16.0527L17.5281 17.1092C17.5287 17.1951 17.5123 17.2387 17.4118 17.2381L15.2192 17.2298C15.1571 17.2298 15.0946 17.2209 15.0333 17.2115C14.695 17.1571 14.559 17.0054 14.5547 16.6646V15.5949C14.5604 15.263 14.7058 15.0948 15.0314 15.0348L15.2704 15.0145C15.9253 15.0132 16.581 15.0171 17.236 15.0108C17.3574 15.0094 17.3992 15.0486 17.3814 15.1631L17.3789 15.1992C17.3984 15.3357 17.3568 15.3781 17.2283 15.3768L15.2634 15.3736C15.2147 15.3736 15.1648 15.3718 15.1167 15.3799C15.0074 15.3988 14.9398 15.4621 14.9391 15.5791L14.9398 16.6482C14.941 16.7949 15.0231 16.8682 15.1825 16.8695L16.0916 16.8707C16.4034 16.8707 16.7157 16.8682 17.0273 16.8721C17.1134 16.8733 17.1475 16.8461 17.1444 16.7576C17.1393 16.6065 17.143 16.4553 17.143 16.2783" fill="white"/>
			<path d="M18.4805 17.235V15.0216C18.5279 15.0184 18.5703 15.0139 18.6133 15.0133L21.1535 15.0108C21.2649 15.0101 21.3104 15.0431 21.2952 15.1542L21.2908 15.2169C21.3078 15.3509 21.2579 15.3755 21.1631 15.3743L19.9327 15.373C19.6299 15.373 19.3265 15.373 19.0236 15.373H18.8604C18.8604 15.5412 18.8561 15.6961 18.8655 15.8497L18.9325 15.9117C19.2051 15.9186 19.4415 15.9155 19.678 15.9155L20.8811 15.9135C20.988 15.9123 21.0321 15.9389 21.0284 16.0546C21.0195 16.3183 21.0455 16.2765 20.8078 16.2771L19.0167 16.2777C18.9692 16.2777 18.9219 16.2777 18.8604 16.2777L18.8598 16.7993C18.8612 16.8941 18.9357 16.8695 18.9881 16.8695L20.6326 16.8701C20.811 16.8701 20.9892 16.8752 21.1675 16.8683C21.27 16.8646 21.2978 16.9036 21.2958 17.0004C21.2908 17.2773 21.3092 17.2331 21.0713 17.2337L18.6385 17.235C18.5904 17.235 18.5425 17.235 18.4805 17.235Z" fill="white"/>
			<path d="M23.7745 16.8683L24.6704 16.8677C24.8491 16.8665 24.9219 16.7924 24.9226 16.6154V15.6392C24.9219 15.4413 24.8574 15.3725 24.6589 15.3717H22.8936C22.6946 15.3725 22.6276 15.4401 22.6262 15.6374V16.6002C22.627 16.7955 22.6977 16.8677 22.8918 16.8683H23.7745ZM23.7674 17.2059C23.4115 17.2059 23.0542 17.2236 22.6997 17.2001C22.4145 17.1812 22.2754 17.0492 22.262 16.7703C22.2405 16.3265 22.2514 15.8801 22.2671 15.4356C22.2754 15.2168 22.4271 15.0936 22.6345 15.0519L22.9923 15.014C23.5051 15.0101 24.0179 15.0095 24.5306 15.0133L24.8884 15.0499C25.1337 15.0968 25.2917 15.2637 25.2987 15.5097L25.2994 16.7394C25.2917 16.9991 25.1311 17.1723 24.8619 17.1964C24.4989 17.2293 24.133 17.2318 23.768 17.2476L23.7674 17.2198" fill="white"/>
			<path d="M28.9575 15.0259L28.9664 15.0397C29.0139 15.3686 29.0107 15.3718 28.6881 15.3718L27.8328 15.3698C27.7316 15.368 27.7032 15.4047 27.7038 15.502L27.7057 17.0528C27.7057 17.106 27.7057 17.1584 27.7057 17.2229H27.3112C27.3112 17.171 27.3112 17.1237 27.3112 17.0762L27.3132 15.4989C27.3138 15.3996 27.2852 15.368 27.1847 15.3698L26.1688 15.3743C26.0499 15.3767 26.0044 15.3395 26.0208 15.2226L26.024 15.1511C26.0157 15.0442 26.0403 15.0107 26.1188 15.0107L28.8994 15.0133C28.9203 15.0133 28.9411 15.0222 28.9575 15.0259Z" fill="white"/>
        </svg>
    );
}

export default PeugeotLogoIcon;