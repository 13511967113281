import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { operations } from './redux/operations';

import PaymentButton from './paymentButton';

const mapStateToProps = ({ router, paymentButton }) => ({
  pathname: router.location.pathname,
  paymentAvailable: paymentButton.paymentAvailable,
  userMessage: paymentButton.userMessage,
  nextLoadTime: paymentButton.nextLoadTime,
  loading: paymentButton.loading,
});

const mapDispatchToProps = (dispatch) => ({
  navTo: (path) => { dispatch(push(path)); },
  requestLoadStatus: () => dispatch(operations.requestLoadStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentButton);
