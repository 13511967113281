import React from 'react';
import PropTypes from 'prop-types';

import SubmenuPatternIcon from 'assets/icons/iconsRebranding/submenu-pattern';
import { permissoes } from '../../../../modules/auth/permissoes';

import Balance from '../../../balance';
import DealerSelector from '../../../dealerSelector';
import { FakeInterfaceWarning } from '../../../common';

import RenderIfAuthorized from '../renderIfAuthorized';
import PaymentButton from '../paymentButton';
import './menuInternetBanking.scss';

const MenuInternetBanking = ({ children }) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  return (
    <div className="menu-main">
      <DealerSelector />

      <div className="menu-divider" />

      <div className="menu-balance">
        <Balance />
      </div>

      <div className="menu-divider" />

      <RenderIfAuthorized requiredAuth={permissoes.efetuarPagamento}>
        <div className="menu-action-btns">
          <PaymentButton />
        </div>
        <div className="menu-divider" />
      </RenderIfAuthorized>

      <div className="menu-nav-btns">
        {children}
      </div>

      <div id="menu-main-footer">
        <SubmenuPatternIcon />
      </div>
      <FakeInterfaceWarning open={openDialog} onClose={() => setOpenDialog(false)} />
    </div>
  );
};

MenuInternetBanking.propTypes = {
  children: PropTypes.node,
};
MenuInternetBanking.defaultProps = {
  children: null,
};

export default MenuInternetBanking;
