import { permissoes } from '../../../../../modules/auth';

export const getCategoriasFromPermissions = (permissions) => {
  if (!Array.isArray(permissions)) return ['Outros'];

  const mapeamento = [
    { permissao: permissoes.duplicatas, categoria: 'Carteira Floor Plan' },
    { permissao: permissoes.movimentacoes, categoria: 'Carteira Floor Plan' },
    { permissao: permissoes.efetuarPagamento, categoria: 'Pagamento' },
    { permissao: permissoes.testDrive, categoria: 'Test Drive' },
    { permissao: permissoes.iof, categoria: 'Simulador IOF' },
    { permissao: permissoes.rcAcessarRenovacao, categoria: 'RC Atualização Cadastral' },
    { permissao: permissoes.limite.alteracaoCredito, categoria: 'Soliciticar alteração credito' },
    { permissao: permissoes.limite.transferenciaCredito, categoria: 'Soliciticar transferência credito' },
    { permissao: permissoes.liberacaoLimite, categoria: 'Aprovar liberação limite' },
  ];

  const categorias = [];
  for (let i = 0; i < mapeamento.length; i += 1) {
    if (permissions.includes(mapeamento[i].permissao)) {
      categorias.push(mapeamento[i].categoria);
    }
  }
  categorias.push('Outros');

  const categoriasUnique = categorias.filter(
    (cat, idx, self) => (self.indexOf(cat) === idx),
  );

  return categoriasUnique;
};
