/* eslint-disable */
import * as React from "react";

function LimiteAprovadoIcon(props) {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" aria-hidden="true">
			<path fill={props.color?"white":null} fillRule="evenodd" clipRule="evenodd" d="M19 4.00369H21.02C21.56 4.00369 22 4.44423 22 4.9849V5.02495C22 5.56561 21.56 6.00616 21.02 6.00616H19V8.01863C19 8.55929 18.56 8.99984 18.02 8.99984H17.99C17.44 9.00985 17 8.55929 17 8.01863V6.00616H14.99C14.44 6.00616 14 5.56561 14 5.01494V4.9849C14.01 4.44423 14.45 4.00369 14.99 4.00369H17V1.98121C17 1.44054 17.44 1 17.99 1H18.02C18.56 1 19 1.44054 19 1.98121V4.00369ZM17 11.5C17 10.8469 17.45 10.3125 18 10.3125C18.55 10.3125 19 10.8469 19 11.5V18.625C19 19.9312 18.1 21 17 21H5C3.9 21 3 19.9312 3 18.625V4.375C3 3.06875 3.9 2 5 2H11C11.55 2 12 2.53437 12 3.1875C12 3.84063 11.55 4.375 11 4.375H6C5.45 4.375 5 4.90937 5 5.5625V17.6987C5 18.3519 5.45 18.8862 6 18.8862H16C16.55 18.8862 17 18.3519 17 17.6987V11.5Z"/>
			<path fill={props.color?"white":null} d="M11.18 11.3889C9.818 11.0611 9.38 10.7222 9.38 10.1944C9.38 9.58889 9.986 9.16667 11 9.16667C11.852 9.16667 12.278 9.46667 12.434 9.94444C12.506 10.1667 12.704 10.3333 12.956 10.3333H13.136C13.532 10.3333 13.814 9.97222 13.676 9.62778C13.424 8.97222 12.836 8.42778 11.9 8.21667V7.83333C11.9 7.37222 11.498 7 11 7C10.502 7 10.1 7.37222 10.1 7.83333V8.2C8.936 8.43333 8 9.13333 8 10.2056C8 11.4889 9.146 12.1278 10.82 12.5C12.32 12.8333 12.62 13.3222 12.62 13.8389C12.62 14.2222 12.326 14.8333 11 14.8333C10.01 14.8333 9.5 14.5056 9.302 14.0389C9.212 13.8222 9.008 13.6667 8.762 13.6667H8.594C8.192 13.6667 7.91 14.0444 8.06 14.3889C8.402 15.1611 9.2 15.6167 10.1 15.7944V16.1667C10.1 16.6278 10.502 17 11 17C11.498 17 11.9 16.6278 11.9 16.1667V15.8056C13.07 15.6 14 14.9722 14 13.8333C14 12.2556 12.542 11.7167 11.18 11.3889Z"/>
		</svg>
	);
}

export default LimiteAprovadoIcon;


