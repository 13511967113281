import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import DataGrid from 'features/common/dataGrid/dataGrid';
import { useHistoricoResgateContext } from 'features/carteiraFidc/contexts/historicoResgateContext';
import BadgeStatus from 'features/common/badgeStatus/badgeStatus';
import { Box } from '@mui/material';
import { formatCurrency } from 'utils/format';
import useToggleEffect from 'hooks/useToggleEffect';
import DuplicataIdentificador from 'features/common/duplicataIdentificador/duplicataIdentificador';

const useStyles = makeStyles({
  headerContainer: {
    padding: '12px 32px',
  },
  resgates: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flexStart',
  },
});

const HistoricoResgatesList = () => {
  const classes = useStyles();
  const {
    state, openDrawer, setPagination, fetchHistorico,
  } = useHistoricoResgateContext();
  const {
    data, requestStatus, pageParams, resgateDrawer,
  } = state;
  const { isOpen } = resgateDrawer;
  const { loading, error } = requestStatus;
  const { ipp, total, page } = pageParams;

  const [toggleFetch] = useToggleEffect(fetchHistorico, [ipp, page]);
  const [selectionModel, setSelectionModel] = useState([]);

  const setPage = (value) => {
    setPagination('page', value);
    toggleFetch();
  };

  const setIpp = (value) => setPagination('ipp', value);

  const dateFormat = (date) => (moment(date).format('DD/MM/YYYY'));

  const formatResgates = (status, valorResgate) => (
    <Box className={classes.resgates}>
      <Box>
        <BadgeStatus
          status={status}
        >
          {status}
        </BadgeStatus>
      </Box>
      <Box>
        {formatCurrency(valorResgate)}
      </Box>
    </Box>
  );

  const formatNumDuplicata = (duplicata) => (
    <DuplicataIdentificador
      duplicata={duplicata}
    />
  );

  const columns = [
    {
      field: 'numeroDuplicata',
      headerName: 'Nº da duplicata',
      editable: false,
      sortable: false,
      flex: 0.6,
      minWidth: 150,
      align: 'left',
      renderCell: (param) => formatNumDuplicata(param.row),
    },
    {
      field: 'valorResgate',
      headerName: 'Resgate',
      editable: false,
      sortable: false,
      flex: 0.4,
      minWidth: 90,
      align: 'left',
      renderCell: (param) => formatResgates(param.row.status, param.row.valorResgate),
    },
    {
      field: 'valorJuros',
      headerName: 'Juros',
      editable: false,
      sortable: false,
      flex: 0.4,
      minWidth: 90,
      align: 'left',
      renderCell: (param) => formatCurrency(param.row.valorJuros),
    },
    {
      field: 'dataCessao',
      headerName: 'data de cessão',
      editable: false,
      sortable: false,
      flex: 0.5,
      minWidth: 105,
      align: 'left',
      renderCell: (param) => dateFormat(param.row.dataCessao),
    },
    {
      field: 'dataPagamento',
      headerName: 'Data de Pagamento',
      editable: false,
      sortable: false,
      flex: 0.5,
      minWidth: 142,
      align: 'left',
      renderCell: (param) => dateFormat(param.row.dataPagamento),
    },
  ];

  return (
    <DataGrid
      error={error}
      loading={loading}
      columns={columns}
      rows={data}
      getRowId={(row) => row.numeroDuplicata}
      overlay={{
        emptyMessage: 'Nenhum resgate encontrado.',
        errorMessage: 'Ocorreu um erro ao carregar os resgates.',
      }}
      onSelectionModelChange={(selection) => setSelectionModel(selection)}
      selectionModel={isOpen ? selectionModel : []}
      onRowClick={(param) => openDrawer(param.row)}
      cellPadding={20}
      footer={{
        ipp,
        page,
        totalItems: total,
        setIpp,
        setPageFetch: setPage,
      }}
    />
  );
};

export default HistoricoResgatesList;
