import { connect } from 'react-redux';
import InfoDocumentosComplementares from './infoDocumentosComplementares';
import operations from '../../redux/operations';

const mapStateToProps = ({ limitesAprovados }) => ({
  statusLimite: limitesAprovados.limiteDetails?.detalhes?.statusLimite,
});

const mapDispatchToProps = (dispatch) => ({
  updateObservacaoDealer: (idPessoa, observacao) => dispatch(
    operations.updateObservacaoDealer(idPessoa, observacao),
  ),
  uploadDocumento: (file, idPessoaTipoDocumentoJuridico, indexPessoa, indexDocumento) => dispatch(
    operations.uploadDocumentoComplementar(
      file,
      idPessoaTipoDocumentoJuridico,
      indexPessoa,
      indexDocumento,
    ),
  ),
  downloadDocumento: (idDocumento) => dispatch(operations.getDocumentoComplementar(idDocumento)),
  deleteDocumento: (idDocumento, indexPessoa, indexDocumento) => dispatch(
    operations.deleteDocumentoComplementar(idDocumento, indexPessoa, indexDocumento),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoDocumentosComplementares);
