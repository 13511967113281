import React from 'react';
import PropTypes from 'prop-types';

import EmptyStateIcon from 'assets/icons/empty-state';
import { LoadMoreButton, ErroConexao } from '../../../common';
import InvoiceRow from '../invoiceRow';

import './iofInvoiceList.scss';

const IofInvoiceList = ({
  className, dealerGroup, invoiceList, page, hasMoreItems, loading,
  loadNewPage, addToSelectedValue,
}) => {
  const handleClickInvoice = (invoice, isActive) => {
    if (isActive) {
      addToSelectedValue(invoice.remainingValue);
    } else {
      addToSelectedValue(invoice.remainingValue * -1);
    }
  };

  const getFooter = () => {
    if (!dealerGroup) {
      return (
        <div className="iof-list__footer-text">
          Selecione um grupo econômico.
        </div>
      );
    }

    if (!loading && (invoiceList || []).length === 0) {
      return (
        <div>
          <ErroConexao
            title="Sem resultados."
            message="Nenhuma duplicata foi encontrada."
          >
            <EmptyStateIcon className="erro-conexao-image" />
          </ErroConexao>
        </div>
      );
    }

    return (
      <LoadMoreButton
        hasMoreItens={hasMoreItems}
        onClick={() => loadNewPage(dealerGroup, page + 1)}
        loading={loading}
        noItemsMessage="Não há outras duplicatas disponíveis."
      />
    );
  };
  return (
    <div className={`iof-list__container ${className}`}>
      {
        (dealerGroup !== null && invoiceList !== null) ? (
          invoiceList.map((invoice, index) => (
            <InvoiceRow
              key={`iof-sim-${index}`}
              invoice={invoice}
              onClick={handleClickInvoice}
            />
          ))
        ) : null
      }

      { getFooter() }
    </div>
  );
};

IofInvoiceList.propTypes = {
  className: PropTypes.string,
  dealerGroup: PropTypes.object,
  invoiceList: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  hasMoreItems: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,

  loadNewPage: PropTypes.func.isRequired,
  addToSelectedValue: PropTypes.func.isRequired,
};

IofInvoiceList.defaultProps = {
  dealerGroup: null,
  className: '',
};

export default IofInvoiceList;
