import React, { useState } from 'react';
import PropTypes from 'prop-types';

import IconFilter from 'assets/icons/filter-icon';

import ButtonTooltipIcon from 'features/common/buttonTooltipIcon';
import InputBusca from './inputBusca';
import ConcessionariaSelect from './concessionariaSelect';
import DocSelect from './docSelect';
import EmissaoDateRange from './emissaoDateRange';
import InputModelo from './inputModelo';
import InputVersao from './inputVersao';
import ProdutoSelect from './produtoSelect';
import StatusSelect from './statusSelect';
import ValorRangeSelect from './valorRangeSelect';
import VencimentoDateRange from './vencimentoDateRange';
import ExportarCarteira from './exportarCarteira';
import FilterButton from './filterButton';
import './filters.scss';

const Filters = ({
  selectedFilter, isModalOpen, infoModalOpen,
}) => {
  const [showFilters, setShowFilters] = useState(false);

  return (
    <div className="carteira-page__filters__container">
      <div className="carteira-page__filters__container__primeira-camada">
        <div className="carteira-page__filters__container__primeira-camada__busca">
          <InputBusca />
        </div>
        {isModalOpen || infoModalOpen ? null
          : (
            <div className="carteira-page__filters__container__primeira-camada__concessionaria">
              <ConcessionariaSelect />
            </div>
          )}
        <ButtonTooltipIcon
          title="Mostrar filtros."
          className={`carteira-page__filters__container__primeira-camada__show-button ${selectedFilter && !showFilters ? 'show-filters' : ''}`}
          buttonAction={() => setShowFilters(!showFilters)}
        >
          <IconFilter />
        </ButtonTooltipIcon>
        <div className="carteira-page__filters__container__primeira-camada__aplicar">
          <FilterButton />
        </div>
        <div className="carteira-page__filters__container__primeira-camada__export-button">
          <ExportarCarteira />
        </div>
      </div>
      {showFilters ? (
        <>
          <div className="carteira-page__filters__container__segunda-camada">
            <div className="carteira-page__filters__container__segunda-camada__vencimento">
              <VencimentoDateRange />
            </div>
            <div className="carteira-page__filters__container__segunda-camada__emissao">
              <EmissaoDateRange />
            </div>
            <div className="carteira-page__filters__container__segunda-camada__status">
              <StatusSelect />
            </div>
            <div className="carteira-page__filters__container__segunda-camada__produto">
              <ProdutoSelect />
            </div>
          </div>
          <div className="carteira-page__filters__container__terceira-camada">
            <div className="carteira-page__filters__container__terceira-camada__doc">
              <DocSelect />
            </div>
            <div className="carteira-page__filters__container__terceira-camada__modelo">
              <InputModelo />
            </div>
            <div className="carteira-page__filters__container__terceira-camada__versao">
              <InputVersao />
            </div>
            <div className="carteira-page__filters__container__terceira-camada__valor">
              <ValorRangeSelect />
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

Filters.propTypes = {
  selectedFilter: PropTypes.bool,
  infoModalOpen: PropTypes.bool,
  isModalOpen: PropTypes.bool,
};

Filters.defaultProps = {
  selectedFilter: false,
  infoModalOpen: false,
  isModalOpen: false,
};

export default Filters;
