import { connect } from 'react-redux';
import AtualizarCadastroButton from './atualizarCadastroButton';

const mapStateToProps = () => ({

});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(AtualizarCadastroButton);
