import { connect } from 'react-redux';

import { IofSimulatorOperations } from '../../redux';

import IofErrorBox from './iofErrorBox';

const mapStateToProps = ({ iofSimulatorStore }) => ({
  dealerGroup: iofSimulatorStore.dealerGroup,
  loading: iofSimulatorStore.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getInvoicesWithBilling: (dealerGroup, page) => {
    dispatch(IofSimulatorOperations.getInvoicesWithBilling(dealerGroup, page));
  },
  getFinancialValues: (dealerGroup) => {
    dispatch(IofSimulatorOperations.getFinancialValues(dealerGroup));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(IofErrorBox);
