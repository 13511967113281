import * as React from "react";

function EmptyStateIcon() {
	return (
		<svg width="273" height="158" viewBox="0 0 273 158" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M273 157H0V158H273V157Z" fill="black"/>
			<path d="M219.806 155.103L216.396 155.104L215.317 141.81L219.802 141.967L219.806 155.103Z" fill="#A0616A"/>
			<path d="M208.123 155.03C208.017 155.208 207.961 155.785 207.961 155.992C207.961 156.631 208.479 157.148 209.119 157.148L219.679 157.144C220.115 157.144 220.468 156.791 220.468 156.355L220.468 155.916C220.468 155.916 220.99 154.596 219.914 152.97C219.914 152.97 218.578 154.244 216.579 152.25L215.99 151.185L211.727 154.3L209.364 154.592C208.847 154.655 208.389 154.582 208.123 155.03Z" fill="#2F2E41"/>
			<path d="M216.26 21.703C220.528 21.703 223.989 17.9101 223.989 13.2315C223.989 8.55277 220.528 4.75995 216.26 4.75995C211.992 4.75995 208.531 8.55277 208.531 13.2315C208.531 17.9101 211.992 21.703 216.26 21.703Z" fill="#A0616A"/>
			<path d="M220.906 12.4831C220.283 12.824 220.874 14.4517 220.484 14.5924C220.034 14.7551 218.391 12.8879 218.371 10.7965C218.365 10.1636 218.511 9.85964 218.37 9.10915C218.187 8.12748 217.624 6.96962 217.103 7.00036C216.794 7.01858 216.492 7.45489 216.469 7.84427C216.438 8.38999 216.968 8.60478 216.892 8.89874C216.746 9.45865 214.517 9.84522 213.724 8.89984C213.102 8.15867 213.579 6.85738 213.301 6.79075C213.096 6.74163 212.876 7.45808 212.034 8.26765C211.717 8.57266 211.245 9.02628 210.978 8.90079C210.592 8.71943 210.873 7.44134 210.767 7.42439C210.679 7.41036 210.624 8.3087 210.134 9.112C209.504 10.144 208.206 10.9395 207.6 10.5893C207.228 10.3748 207.199 9.77756 207.177 9.32395C207.13 8.36831 205.739 6.17013 206.099 5.14782C206.831 3.0671 208.086 4.53381 208.653 3.83942C209.421 2.89939 210.111 3.4048 211.398 2.36201C212.656 1.34331 212.667 0.319421 213.51 0.252041C214.268 0.191362 214.538 0.999521 215.411 0.884154C216.15 0.786423 216.311 0.159611 216.889 0.25087C217.418 0.334417 217.453 0.886605 218.156 1.09413C218.606 1.22661 218.704 1.03485 219.213 1.09376C220.27 1.21634 220.981 2.17843 221.114 2.35865C221.627 3.05341 221.428 3.34639 221.959 4.04574C222.417 4.64884 223.433 4.39273 223.815 4.69395C225.062 5.67818 225.599 7.4933 225.609 9.5621C225.617 11.0091 225.428 11.7401 225.006 13.5665C224.686 14.9534 223.204 16.7455 222.386 17.5447C222.167 17.7592 221.552 18.3606 221.119 18.1779C220.709 18.0048 220.697 17.2262 220.696 17.1235C220.695 16.7699 220.789 16.3324 221.541 15.2248C222.33 14.0614 222.644 13.9286 222.596 13.5371C222.511 12.845 221.403 12.2113 220.906 12.4831Z" fill="#2F2E41"/>
			<path d="M187.625 91.331C186.84 93.2264 187.181 95.1666 188.386 95.6646C189.591 96.1627 191.203 95.03 191.988 93.1346C192.331 92.3049 192.459 91.4667 192.393 90.7446L195.616 82.6718L191.799 81.2435L189.027 89.3533C188.47 89.8179 187.968 90.5013 187.625 91.331Z" fill="#A0616A"/>
			<path d="M205.286 28.1036C205.286 28.1036 203.179 28.1336 201.785 30.8536C201.044 32.299 195.5 58 195.5 58L189.5 84L195.5 90.5L203 63L205.844 48.2479L205.286 28.1036Z" fill="#CACED7"/>
			<path d="M221.594 85.9772C220.999 87.9376 221.528 89.8336 222.777 90.2122C224.026 90.5909 225.522 89.3087 226.118 87.3483C226.379 86.4903 226.424 85.6445 226.287 84.9329L228.707 76.5949L224.765 75.5441L222.798 83.8752C222.288 84.391 221.855 85.1192 221.594 85.9772Z" fill="#A0616A"/>
			<path d="M161.782 156.87C161.028 158.377 197.087 157.303 197.504 157.303C197.504 157.303 207.424 157.084 207.633 156.723C207.737 156.543 207.789 156.345 207.789 156.147C207.789 155.949 207.737 155.751 207.632 155.571L203.064 147.28C202.856 146.92 202.482 146.705 202.065 146.705C202.048 146.705 202.022 146.72 201.989 146.75C195.895 152.188 188.385 155.783 180.214 155.904C171.525 156.033 161.83 156.775 161.782 156.87Z" fill="black"/>
			<path d="M206.731 143.869L203.501 144.96L197.748 133.037L202.515 131.426L206.731 143.869Z" fill="#A0616A"/>
			<path d="M195.64 147.536C195.597 147.739 195.729 148.303 195.795 148.5C196 149.104 196.657 149.429 197.263 149.224L207.265 145.843C207.678 145.703 207.9 145.256 207.76 144.843L207.619 144.427C207.619 144.427 207.69 143.01 206.149 141.814C206.149 141.814 205.292 143.448 202.76 142.198L201.86 141.377L198.821 145.692L196.675 146.724C196.206 146.95 195.748 147.027 195.64 147.536Z" fill="#2F2E41"/>
			<path d="M127.313 156.976L57.6934 157C54.1959 157.001 51.063 155.209 49.3132 152.205C48.4382 150.703 48.0006 149.056 48 147.408C47.9994 145.76 48.4361 144.112 49.3098 142.61L84.0973 79.8006C85.8452 76.7957 88.9769 75.0012 92.4744 75C95.9719 74.9988 99.1049 76.7911 100.855 79.7948L135.691 142.588C136.563 144.086 137 145.731 137 147.378C137 149.025 136.563 150.673 135.69 152.176C133.942 155.18 130.81 156.975 127.313 156.976ZM48.8751 147.407C48.875 148.907 56.5573 145.674 57.3531 147.041C58.9451 149.773 54.5111 156.135 57.6931 156.134C57.6931 156.134 133.343 154.476 134.933 151.743C135.728 150.376 136.125 148.877 136.125 147.378C136.124 145.878 135.726 144.38 134.93 143.013L100.094 80.2203C98.5067 77.4956 95.6567 75.8649 92.4747 75.866C89.2942 75.8671 48.8752 145.906 48.8751 147.407Z" fill="#282B34"/>
			<path d="M44.71 128.017L41.8515 134C45.3072 131.545 50.7048 129.429 55 128.295C50.9979 126.38 46.1008 123.292 43.1752 120.235L44.8193 126.558C25.544 122.668 11.7021 108.018 11.6963 91.2373L11 91C11.0061 108.528 24.6565 124.045 44.71 128.017Z" fill="#3F3D56"/>
			<path d="M92.9493 149.479C106.838 149.479 118.098 138.235 118.098 124.364C118.098 110.493 106.838 99.249 92.9493 99.249C79.0601 99.249 67.8008 110.493 67.8008 124.364C67.8008 138.235 79.0601 149.479 92.9493 149.479Z" fill="#C76800"/>
			<path d="M93.8164 139.52C95.7322 139.52 97.2852 137.969 97.2852 136.056C97.2852 134.143 95.7322 132.592 93.8164 132.592C91.9007 132.592 90.3477 134.143 90.3477 136.056C90.3477 137.969 91.9007 139.52 93.8164 139.52Z" fill="white"/>
			<path d="M92.944 108.968C92.7319 110.376 93.3829 119.168 94.0333 125.447C94.9005 131.726 93.3829 131.942 92.0822 131.076C90.7084 130.161 86.6623 115.92 84.7111 112.456C81.0255 107.693 92.0822 104.229 92.944 108.968Z" fill="white"/>
			<path d="M227.172 26.4591C227.172 26.4591 230.227 24.1689 232.775 27.7288C235.323 31.2887 236.352 60.5376 236.352 60.5376L229.227 78.5982L223.879 76.8197L228.964 54.6903L220.045 37.9069L227.172 26.4591Z" fill="#CACED7"/>
			<path d="M201.464 67.6712L194.85 89.5468L187.042 109.224L199.403 137.324L203.987 135.288L198.502 109.474L210.004 91.9578L215.371 146.639L219.955 146.383L226.172 80.3797L227.058 66.0092L201.464 67.6712Z" fill="#1B2961"/>
			<path d="M221.439 19.2123L211.762 20.9961L210.999 25.3201L205.143 28.1199L202.098 59.9136C202.098 59.9136 197.006 66.5282 201.082 68.5615L227.058 66.0091C227.058 66.0091 228.33 61.3064 227.056 60.8602C225.783 60.4141 225.779 49.181 225.779 49.181L231.629 26.076L222.969 23.0269L221.439 19.2123Z" fill="#B0B5C2"/>
	</svg>
	);
}

export default EmptyStateIcon;

