import React from 'react';
import { PageErrorBoundary } from 'features/common';
import SplitView from 'features/common-staff/layout/splitView';
import {
  Page, PageContent, PageFooter, PageHeader, PageSubTitle, PageTitle,
} from 'features/common-staff/layout/page';
import SnackbarList from 'features/common-staff/snackbarList';
import { useDuplicataContext } from 'features/carteiraFidc/contexts/duplicataContext';
import AlertaMensagem from 'features/common/alertaMensagem';
import { useOnboardingContext } from 'features/carteiraFidc/contexts/onboardingContext';
import BreadCrumbDuplicata from './breadCrumbDuplicata/breadCrumbDuplicata';
import DuplicataList from './duplicataList/duplicataList';
import DuplicataFilters from './duplicataFilters/duplicataFilters';
import DuplicataFooter from './duplicataFooter/duplicataFooter';
import {
  PageAreas, PageDescription, PageFilters, PageList, AlertContainer,
} from './duplicataPage.style';
import DetalheDuplicata from './detalheDuplicata/detalheDuplicata';

const DuplicataPage = () => {
  const {
    state, closeDrawer, closeSnackbar, transitionDrawer,
  } = useDuplicataContext();
  const { snackbars, duplicataDrawer, isUpdated } = state;
  const { isOpen, duplicataDetalhe, transition } = duplicataDrawer;

  const onboardingContext = useOnboardingContext();
  const { state: stateControle, readyControleUsuario } = onboardingContext;
  const historicoDuplicatas = stateControle.onboarding.HISTORICO_DUPLICATA;

  return (
    <PageErrorBoundary>
      <SplitView
        open={isOpen}
        sideSheetProps={{
          width: 400,
          unmountOnExit: true,
          onClickClose: () => closeDrawer(),
          onTransitionEnd: () => {
            if (!historicoDuplicatas?.ready) readyControleUsuario('HISTORICO_DUPLICATA');
            if (transition) transitionDrawer();
          },
        }}
        renderSideSheetContent={
          () => (
            <DetalheDuplicata
              duplicata={duplicataDetalhe}
              isOpen={isOpen}
              closeDrawer={closeDrawer}
            />
          )
        }
      >
        <Page minWidth="fit-content">
          <PageHeader>
            <PageSubTitle>
              <BreadCrumbDuplicata />
            </PageSubTitle>
            <PageTitle data-cy="PageTitle">Duplicatas</PageTitle>
          </PageHeader>
          { isUpdated
            ? (
              <>
                <PageContent>
                  <PageAreas>
                    <PageDescription>
                      Insira os dados nos filtros abaixo e em seguida clique em filtrar
                    </PageDescription>
                    <PageFilters>
                      <DuplicataFilters />
                    </PageFilters>
                    <PageList isDrawerOpen={isOpen}>
                      <DuplicataList />
                    </PageList>
                  </PageAreas>
                </PageContent>
                <PageFooter>
                  <DuplicataFooter />
                </PageFooter>
              </>
            ) : (
              <AlertContainer>
                <AlertaMensagem
                  breakLine
                  mensagem={'A carteira FIDC está indisponível no momento.\nPor favor, tente novamente mais tarde.'}
                />
              </AlertContainer>
            )}
          <SnackbarList
            snackbarErrors={snackbars}
            onClose={(id) => closeSnackbar(id)}
          />
        </Page>
      </SplitView>
    </PageErrorBoundary>
  );
};

export default DuplicataPage;
