const isAnyFalsyButNotZero = (...args) => {
  let hasAny = false;
  args.forEach((i) => {
    if (!i && i !== 0) {
      hasAny = true;
    }
  });

  return hasAny;
};

const getSimulatedValue = (baseValue, condition, offer) => {
  if (!condition) { return null; }

  const descontoPctParcelado = offer.percentualFinanciado;
  const descontoPctAVista = offer.percentualAVista;
  const coeficiente = condition.coefficient;
  const parcelas = condition.installments;

  if (isAnyFalsyButNotZero(descontoPctParcelado, descontoPctAVista, parcelas, coeficiente)) {
    return null;
  }

  if (condition.isAVista) {
    return baseValue * (1 - descontoPctAVista / 100);
  }

  const nf = baseValue * (1 - descontoPctParcelado / 100);
  const pmt = nf * coeficiente;
  const tco = pmt * parcelas;

  return tco;
};

export { getSimulatedValue };
