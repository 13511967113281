import React from 'react';
import PropTypes from 'prop-types';

import List, { ListContent } from '../../../../../common-staff/layout/list';
import PagamentoListHeader from './pagamentoListHeader';
import PagamentoListRow from './pagamentoListRow';

import './pagamentoList.scss';

const PagamentoList = ({ invoices }) => (
  <div className="pagamento-page__list__container">
    <PagamentoListHeader />
    <List>
      <ListContent>
        {(Array.isArray(invoices) ? invoices : []).map((d, i) => (
          <PagamentoListRow
            invoice={d}
            key={i}
          />
        ))}
      </ListContent>
    </List>
  </div>
);

PagamentoList.propTypes = {
  invoices: PropTypes.array,
};

PagamentoList.defaultProps = {
  invoices: null,
};

export default PagamentoList;
