import React from "react";

const EyeOffIcon = props => (
	<svg width="1em" height="1em" viewBox="0 0 16 14" {...props}>
		<path
			d="M8 3.313a3.335 3.335 0 013.333 3.334c0 .34-.066.666-.16.973l2.04 2.04a7.868 7.868 0 002.12-3.02 7.888 7.888 0 00-9.76-4.613L7.02 3.473c.313-.093.64-.16.98-.16zM1.807 1.087c-.26.26-.26.68 0 .94L3.12 3.34A7.928 7.928 0 00.667 6.647c1.153 2.926 4 5 7.333 5 1.013 0 1.98-.2 2.873-.547l1.814 1.813a.664.664 0 10.94-.94L2.753 1.087a.672.672 0 00-.946 0zM8 9.98a3.335 3.335 0 01-3.333-3.333c0-.514.12-1 .326-1.427L6.04 6.267c-.02.12-.04.246-.04.38 0 1.106.893 2 2 2 .133 0 .253-.02.38-.047l1.047 1.047A3.208 3.208 0 018 9.98zm1.98-3.553a1.98 1.98 0 00-1.76-1.76l1.76 1.76z"
			fill="#000"
			fillRule="evenodd"
		/>
	</svg>
);

export default EyeOffIcon;

