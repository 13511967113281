import React from 'react';
import PropTypes from 'prop-types';

import TextFilterDebounce from 'features/common-staff/controls/textFilterDebounce';

const InputDocumento = ({
  documento, setDocumento, isLoading,
}) => (
  <TextFilterDebounce
    showSearchIcon
    placeholder="Buscar carta"
    value={documento}
    setValue={setDocumento}
    disabled={isLoading}
  />
);

InputDocumento.propTypes = {
  documento: PropTypes.string,
  setDocumento: PropTypes.func,
  isLoading: PropTypes.bool,
};

InputDocumento.defaultProps = {
  documento: '',
  setDocumento: () => {},
  isLoading: false,
};

export default InputDocumento;
