import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import TypeNumber from './numericInputBase';

const NumberInput = ({
  value, setValue, disabled, ...other
}) => (
  <TypeNumber
    disabled={disabled}
    value={value}
    setValue={(event) => {
      setValue(_.isEmpty(event) ? null : event);
    }}
    {...other}
  />
);

NumberInput.propTypes = {
  setValue: PropTypes.func,
  value: PropTypes.any,
  disabled: PropTypes.bool,
};

NumberInput.defaultProps = {
  setValue: () => {},
  value: '',
  disabled: true,
};

export default NumberInput;
