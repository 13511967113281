import React from 'react';
import PropTypes from 'prop-types';

import List, { ListContent } from '../../../../../common-staff/layout/list';
import ComprovanteListHeader from './comprovanteListHeader';
import ComprovanteListRow from './comprovanteListRow';

import './comprovanteList.scss';

const ComprovanteList = ({ comprovantes }) => (
  <div className="comprovante-page__list__container">
    <ComprovanteListHeader />
    <List>
      <ListContent>
        {(Array.isArray(comprovantes) ? comprovantes : []).map((d, i) => (
          <ComprovanteListRow
            comprovante={d}
            key={i}
          />
        ))}
      </ListContent>
    </List>
  </div>
);

ComprovanteList.propTypes = {
  comprovantes: PropTypes.array,
};

ComprovanteList.defaultProps = {
  comprovantes: null,
};

export default ComprovanteList;
