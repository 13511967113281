import { connect } from 'react-redux';

import ProdutoSelector from './produtoSelector';
import operations from '../../../redux/operations';

const mapStateToProps = ({ dashboard }) => ({
  produtos: dashboard.painelFloorPlan.filtersSelector.produto.data,
  selectedProdutos: dashboard.painelFloorPlan.filters.selectedProduto,
  isLoading: dashboard.painelFloorPlan.filtersSelector.produto.isLoading,
  isError: dashboard.painelFloorPlan.filtersSelector.produto.isError,
});

const mapDispatchToProps = (dispatch) => ({
  setFilter: (produtos) => dispatch(operations.setFilter('selectedProduto', produtos)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProdutoSelector);
