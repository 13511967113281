import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import PeugeotLogoIcon from 'assets/icons/marcaBadge/peugeot-logo';
import CitroenLogoIcon from 'assets/icons/marcaBadge/citroen-logo';
import FiatIcon from '../../../../assets/icons/marcaBadge/fiat-logo';
import JeepIcon from '../../../../assets/icons/marcaBadge/jeep-logo';
import ChryslerIcon from '../../../../assets/icons/marcaBadge/chrysler-logo';
import RamIcon from '../../../../assets/icons/marcaBadge/ram-logo';
import DodgeIcon from '../../../../assets/icons/marcaBadge/dodge-logo';
import DefaultIcon from '../../../../assets/icons/marcaBadge/default-icon';

const useStyles = makeStyles({
  containerBase: {
    height: '32px',
    width: '32px',
    borderRadius: '12px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  containerColor: ({ marca }) => {
    switch (String(marca).toLowerCase()) {
      case 'fiat':
        return { backgroundColor: '#ED5C6F' };
      case 'jeep':
        return { backgroundColor: '#555770' };
      case 'dodge':
        return { backgroundColor: '#DE1932' };
      case 'ram':
        return { backgroundColor: '#15161C' };
      case 'chrysler':
        return { backgroundColor: '#004AB9' };
      case 'citroen':
        return { backgroundColor: 'none' };
      case 'peugeot':
        return { backgroundColor: 'none' };
      default:
        return { backgroundColor: '#FFFFFF' };
    }
  },
});

const GetBadgeIcon = (marca) => {
  switch (String(marca).toLowerCase()) {
    case 'fiat':
      return <FiatIcon />;
    case 'jeep':
      return <JeepIcon />;
    case 'dodge':
      return <DodgeIcon />;
    case 'ram':
      return <RamIcon />;
    case 'chrysler':
      return <ChryslerIcon />;
    case 'citroen':
      return <CitroenLogoIcon />;
    case 'peugeot':
      return <PeugeotLogoIcon />;
    default:
      return <DefaultIcon />;
  }
};

const MarcaBadge = ({
  marca,
}) => {
  const classes = useStyles({ marca });

  return (
    <div className={`${classes.containerBase} ${classes.containerColor}`}>
      {GetBadgeIcon(marca)}
    </div>
  );
};

MarcaBadge.propTypes = {
  marca: PropTypes.string,
};

MarcaBadge.defaultProps = {
  marca: '',
};

export default MarcaBadge;
