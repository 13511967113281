import React from 'react';
import PropTypes from 'prop-types';

import { Clock } from 'react-feather';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import MoneyIcon from '@material-ui/icons/Money';
import FloorPlanIcon from 'assets/icons/floor-plan';

const CardIcons = ({ etapa }) => {
  switch (etapa) {
    case 'carteira':
      return <FloorPlanIcon />;
    case 'duplicatas':
      return <MoneyIcon />;
    case 'inadimplencia':
      return <MoneyOffIcon />;
    case 'maior_atraso':
      return <Clock />;
    default:
      return <FloorPlanIcon />;
  }
};

CardIcons.propTypes = {
  etapa: PropTypes.string,
};

CardIcons.defaultProps = {
  etapa: null,
};

export default CardIcons;
