import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './relatoriosPage.scss';
import {
  Page, PageHeader, PageTitle, PageContent, PageFooter, PageSubTitle,
} from 'features/common-staff/layout/page';

import SnackbarList from '../common-staff/snackbarList';
import BreadcrumbRelatorios from './views/breadcrumbRelatorios';
import Filtros from './views/filtros';
import RelatoriosList from './views/listagem';
import RelatorioPageFooter from './views/relatorioPageFooter';

const RelatorioPage = ({
  resetStore, onCloseSnackBar, snackbarErrors, wasFilted,
}) => {
  useEffect(() => () => resetStore(), [resetStore]);

  return (
    <Page>
      <PageHeader>
        <PageSubTitle>
          <BreadcrumbRelatorios />
        </PageSubTitle>
        <PageTitle data-cy="RelatoriosTitulo">Relatórios</PageTitle>
      </PageHeader>
      <PageContent>
        <div className="relatorios-page__conteudo" data-cy="RelatoriosDcsPage">
          <Filtros />
          { wasFilted
            ? (
              <div className="relatorios-page__conteudo__list">
                <RelatoriosList />
              </div>
            )
            : (
              <div
                className="relatorios-page__conteudo__list__mensagem"
                data-cy="MensagemSelecioneDelaer"
              >
                <span>
                  Selecione um tipo de arquivo e o período para exibir os relatórios disponíveis.
                </span>
              </div>
            )}
        </div>
      </PageContent>
      {wasFilted
        ? (
          <PageFooter>
            <RelatorioPageFooter />
          </PageFooter>
        ) : null}
      <SnackbarList
        snackbarErrors={snackbarErrors}
        onClose={(id) => onCloseSnackBar(id)}
      />
    </Page>
  );
};

RelatorioPage.propTypes = {
  resetStore: PropTypes.func,
  onCloseSnackBar: PropTypes.func,
  snackbarErrors: PropTypes.array,
  wasFilted: PropTypes.bool,
};

RelatorioPage.defaultProps = {
  resetStore: () => {},
  onCloseSnackBar: () => {},
  snackbarErrors: [],
  wasFilted: false,
};

export default RelatorioPage;
