import { connect } from 'react-redux';

import { NotificationsOperations, NotificationSelectors } from '../../redux';
import NotificationContent from './notificationContent';

const mapDispatchToProps = (dispatch) => ({
  getNotifications: (dealerGroups) => (
    dispatch(NotificationsOperations.getNotifications(dealerGroups))
  ),
  markNotificationsRead: (notifications) => (
    dispatch(NotificationsOperations.markNotificationsRead(notifications))
  ),
});

const mapStateToProps = ({ notificationStore, dealerStore }) => ({
  notifications: notificationStore.notifications,
  isError: notificationStore.isError,
  loadingPost: notificationStore.loadingPost,
  isPostSending: notificationStore.isPostSending,
  loading: notificationStore.loading,
  isErrorLastGet: notificationStore.isErrorLastGet,
  dealerGroups: NotificationSelectors.getDealerGroups(dealerStore),
});
export default connect(mapStateToProps, mapDispatchToProps)(NotificationContent);
