/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { makeStyles } from '@material-ui/styles';
import HelpIcon from '@material-ui/icons/Help';
import { Tooltip as TooltipCard } from '@material-ui/core';
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { formatCurrency } from 'utils/format';
import colors from 'assets/styles/colors';
import CardIcons from '../cardIcons/cardIcons';
import CustomTooltip from './tooltip';
import { cardDonutStyles } from './cardDonutStyles';

import './cardCarteira.scss';

const useStyles = makeStyles(cardDonutStyles);

const carteiraColors = [colors.primary_color_600, colors.alert_color_200_80];

const ChartCarteira = ({ data }) => (
  <ResponsiveContainer width="100%" height="100%">
    <PieChart width={50} height={50}>
      <Pie
        data={data}
        cx="50%"
        cy="50%"
        labelLine={false}
        outerRadius={38}
        innerRadius={20}
        blendStroke
        fill="#8884d8"
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={carteiraColors[index % carteiraColors.length]} />
        ))}
      </Pie>
      <Tooltip
        content={<CustomTooltip />}
      />
    </PieChart>
  </ResponsiveContainer>
);

// eslint-disable-next-line react/prop-types
const ChartLegend = ({ floorPlanValue, fundaoValue }) => (
  <>
    <div className="dashboard-floor-plan__legenda-carteira__item">
      <span className="color-label color_floor_plan" />
      <span className="dashboard-floor-plan__legenda-carteira__item__label">
        Floor Plan
      </span>
      <span className="dashboard-floor-plan__legenda-carteira__item__value">
        {_.isNull(floorPlanValue) || _.isUndefined(floorPlanValue)
          ? formatCurrency(0) : formatCurrency(floorPlanValue)}
      </span>
    </div>
    <div className="dashboard-floor-plan__legenda-carteira__item">
      <span className="color-label color_fundao" />
      <span className="dashboard-floor-plan__legenda-carteira__item__label">
        Fundão
      </span>
      <span className="dashboard-floor-plan__legenda-carteira__item__value">
        {_.isNull(floorPlanValue) || _.isUndefined(floorPlanValue)
          ? formatCurrency(0) : formatCurrency(fundaoValue)}
      </span>
    </div>
  </>
);

const CardCarteira = ({
  data, dataForDonut,
}) => {
  const classes = useStyles(true);
  return (
    <div className="dashboard__card-carteira">
      <div className="dashboard__card-carteira__header">
        <div className="dashboard__card-carteira__header_value">
          {data.valorCarteira !== null ? formatCurrency(data.valorCarteira) : formatCurrency(0)}
        </div>
        <div className="dashboard__card-carteira__header_icon">
          <CardIcons etapa="'carteira'" />
        </div>
      </div>
      <div className="dashboard__card-carteira__content">
        {data.valorCarteiraTitulo}
        <TooltipCard
          title="Valor total da carteira vencida e a vencer."
        >
          <HelpIcon style={{ fill: colors.secundary_color_700, marginLeft: '4px', fontSize: 'medium' }} />
        </TooltipCard>
      </div>
      <div className="dashboard__card-carteira__chart">
        <div className={`dashboard__card-donut__container ${classes.container}`}>
          <div className={`dashboard__card-donut__container_donut ${classes.donut}`}>
            <ChartCarteira data={dataForDonut} />
          </div>
          <div className="dashboard__card-donut__container_legend">
            <ChartLegend
              floorPlanValue={dataForDonut[0].value}
              fundaoValue={dataForDonut[1].value}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

CardCarteira.propTypes = {
  dataForDonut: PropTypes.object,
  data: PropTypes.object,
};

CardCarteira.defaultProps = {
  dataForDonut: {},
  data: {},
};

export default CardCarteira;
