import { connect } from 'react-redux';
import DocumentosCadastro from './documentosCadastro';

const mapStateToProps = ({ limitesAprovadosCadastro, limitesAprovados }) => ({
  listaPessoaDocumentacao: limitesAprovadosCadastro.listaPessoaDocumentacao.listaDados,
  isLoading: limitesAprovadosCadastro.listaPessoaDocumentacao.isLoading,
  gerenciadorDocumentos: limitesAprovadosCadastro.gerenciadorDocumentos,
  statusLimite: limitesAprovados.limiteDetails.detalhes?.statusLimite,

});

export default connect(mapStateToProps, null)(DocumentosCadastro);
