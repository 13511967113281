const valueForChatState = (answers, state) => {
  if (!Array.isArray(answers)) { return null; }
  const answersForState = answers.filter(
    (c) => (c.chatState || '') === state,
  );
  if (!answersForState.length) { return null; }

  if (answersForState.length > 1) {
    throw new Error(`more than 1 answer for chatState ${state}`);
  }

  return answersForState[0].value;
};

export { valueForChatState };
