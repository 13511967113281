import React from "react";

const SubmenuPatternIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="143" height="27" viewBox="0 0 143 27" fill="none">
    <path d="M80.4014 11.1648C80.4014 12.7833 81.7057 14.0968 83.3128 14.0968C84.9199 14.0968 86.2242 12.7833 86.2242 11.1648C86.2242 9.54641 84.9199 8.23291 83.3128 8.23291C81.7057 8.23291 80.4014 9.54641 80.4014 11.1648Z" fill="#C3C1F7"/>
    <path d="M65.5186 11.1648C65.5186 12.7833 66.8229 14.0968 68.43 14.0968C70.0371 14.0968 71.3414 12.7833 71.3414 11.1648C71.3414 9.54641 70.0371 8.23291 68.43 8.23291C66.8229 8.23291 65.5186 9.54641 65.5186 11.1648Z" fill="#C3C1F7"/>
    <path d="M50.6353 11.1648C50.6353 12.7833 51.9396 14.0968 53.5467 14.0968C55.1538 14.0968 56.4581 12.7833 56.4581 11.1648C56.4581 9.54641 55.1538 8.23291 53.5467 8.23291C51.9396 8.23291 50.6353 9.54641 50.6353 11.1648Z" fill="#C3C1F7"/>
    <path d="M87.0396 22.0247C87.0396 24.3233 88.8796 26.1763 91.1621 26.1763C93.4447 26.1763 95.2847 24.3233 95.2847 22.0247C95.2847 19.726 93.4447 17.873 91.1621 17.873C88.8796 17.873 87.0396 19.726 87.0396 22.0247Z" fill="#C3C1F7"/>
    <path d="M72.1562 22.0247C72.1562 24.3233 73.9963 26.1763 76.2788 26.1763C78.5614 26.1763 80.4014 24.3233 80.4014 22.0247C80.4014 19.726 78.5614 17.873 76.2788 17.873C73.9963 17.873 72.1562 19.726 72.1562 22.0247Z" fill="#C3C1F7"/>
    <path d="M57.2734 22.0247C57.2734 24.3233 59.1135 26.1763 61.396 26.1763C63.6785 26.1763 65.5186 24.3233 65.5186 22.0247C65.5186 19.726 63.6785 17.873 61.396 17.873C59.1135 17.873 57.2734 19.726 57.2734 22.0247Z" fill="#C3C1F7"/>
    <path d="M42.3901 22.0247C42.3901 24.3233 44.2302 26.1763 46.5127 26.1763C48.7952 26.1763 50.6353 24.3233 50.6353 22.0247C50.6353 19.726 48.7952 17.873 46.5127 17.873C44.2302 17.873 42.3901 19.726 42.3901 22.0247Z" fill="#C3C1F7"/>
    <path d="M28.1592 22.6579C28.1592 24.5813 29.7197 26.1528 31.6296 26.1528C33.5395 26.1528 35.1 24.5813 35.1 22.6579C35.1 20.7346 33.5395 19.1631 31.6296 19.1631C29.7197 19.1631 28.1592 20.7346 28.1592 22.6579Z" fill="#C3C1F7"/>
    <path d="M103.553 22.6579C103.553 24.5813 105.113 26.1528 107.023 26.1528C108.933 26.1528 110.494 24.5813 110.494 22.6579C110.494 20.7346 108.933 19.1631 107.023 19.1631C105.113 19.1631 103.553 20.7346 103.553 22.6579Z" fill="#C3C1F7"/>
    <path d="M14.0449 23.432C14.0449 24.9332 15.2561 26.1528 16.7467 26.1528C18.2374 26.1528 19.4485 24.9332 19.4485 23.432C19.4485 21.9309 18.2374 20.7112 16.7467 20.7112C15.2561 20.7112 14.0449 21.9309 14.0449 23.432Z" fill="#C3C1F7"/>
    <path d="M120.835 23.432C120.835 24.9332 122.046 26.1528 123.537 26.1528C125.027 26.1528 126.239 24.9332 126.239 23.432C126.239 21.9309 125.027 20.7112 123.537 20.7112C122.046 20.7112 120.835 21.9309 120.835 23.432Z" fill="#C3C1F7"/>
    <path d="M0 24.2763C0 25.3084 0.838485 26.1528 1.8633 26.1528C2.88812 26.1528 3.72661 25.3084 3.72661 24.2763C3.72661 23.2443 2.88812 22.3999 1.8633 22.3999C0.838485 22.3999 0 23.2443 0 24.2763Z" fill="#C3C1F7"/>
    <path d="M138.862 24.2763C138.862 25.3084 139.701 26.1528 140.726 26.1528C141.75 26.1528 142.589 25.3084 142.589 24.2763C142.589 23.2443 141.75 22.3999 140.726 22.3999C139.701 22.3999 138.862 23.2443 138.862 24.2763Z" fill="#C3C1F7"/>
    <path d="M74.4155 1.87644C74.4155 2.90848 75.254 3.75287 76.2788 3.75287C77.3036 3.75287 78.1421 2.90848 78.1421 1.87644C78.1421 0.844402 77.3036 0 76.2788 0C75.254 0 74.4155 0.844402 74.4155 1.87644Z" fill="#C3C1F7"/>
    <path d="M58.9502 1.87644C58.9502 2.90848 59.7887 3.75287 60.8135 3.75287C61.8383 3.75287 62.6768 2.90848 62.6768 1.87644C62.6768 0.844402 61.8383 0 60.8135 0C59.7887 0 58.9502 0.844402 58.9502 1.87644Z" fill="#C3C1F7"/>
    <path d="M36.9629 12.197C36.9629 13.229 37.8014 14.0734 38.8262 14.0734C39.851 14.0734 40.6895 13.229 40.6895 12.197C40.6895 11.165 39.851 10.3206 38.8262 10.3206C37.8014 10.3206 36.9629 11.165 36.9629 12.197Z" fill="#C3C1F7"/>
    <path d="M96.6821 12.197C96.6821 13.229 97.5206 14.0734 98.5454 14.0734C99.5702 14.0734 100.409 13.229 100.409 12.197C100.409 11.165 99.5702 10.3206 98.5454 10.3206C97.5206 10.3206 96.6821 11.165 96.6821 12.197Z" fill="#C3C1F7"/>
  </svg>
);

export default SubmenuPatternIcon;
