export const SET_SELECTED_DEALER = 'alteracaoLimite/SET_SELECTED_DEALER';
export const SET_MOTIVO = 'alteracaoLimite/SET_MOTIVO';
export const SET_VALOR = 'alteracaoLimite/SET_VALOR';
export const SET_IS_POSITIVO = 'alteracaoLimite/SET_IS_POSITIVO';
export const SET_PRODUTO = 'alteracaoLimite/SET_PRODUTO';
export const SET_CODIGO_SAP = 'alteracaoLimite/SET_CODIGO_SAP';
export const SET_BRAND = 'alteracaoLimite/SET_BRAND';
export const DISMISS_SNACKBAR = 'alteracaoLimite/DISMISS_SNACKBAR';

const PREFIX_POST_SOLITACAO_CREDITO = 'alteracaoLimite/POST_SOLITACAO_CREDITO';
const POST_SOLITACAO_CREDITO_START = `${PREFIX_POST_SOLITACAO_CREDITO}/START`;
const POST_SOLITACAO_CREDITO_SUCCESS = `${PREFIX_POST_SOLITACAO_CREDITO}/SUCCESS`;
const POST_SOLITACAO_CREDITO_ERROR = `${PREFIX_POST_SOLITACAO_CREDITO}/ERROR`;

const PREFIX_GET_PRODUTOS = 'solicitacaoLimite/GET_PRODUTOS';
const GET_PRODUTOS_START = `${PREFIX_GET_PRODUTOS}/START`;
const GET_PRODUTOS_SUCCESS = `${PREFIX_GET_PRODUTOS}/SUCCESS`;
const GET_PRODUTOS_ERROR = `${PREFIX_GET_PRODUTOS}/ERROR`;

const RESET_STORE = 'solicitacaoLimite/RESET_STORE';

export const postSolicitacaoLimiteStart = () => ({
  type: POST_SOLITACAO_CREDITO_START,
});

export const postSolicitacaoLimiteSuccess = () => ({
  type: POST_SOLITACAO_CREDITO_SUCCESS,
});

export const getProdutosError = () => ({
  type: GET_PRODUTOS_ERROR,
});

export const getProdutosStart = () => ({
  type: GET_PRODUTOS_START,
});

export const getProdutosSuccess = (produtos) => ({
  type: GET_PRODUTOS_SUCCESS,
  payload: produtos,
});

export const postSolicitacaoLimiteError = () => ({
  type: POST_SOLITACAO_CREDITO_ERROR,
});

export const setProduto = (produto) => ({
  type: SET_PRODUTO,
  payload: produto,
});

export const setSelectedDealer = (dealer) => ({
  type: SET_SELECTED_DEALER,
  payload: dealer,
});

export const setMotivo = (motivo) => ({
  type: SET_MOTIVO,
  payload: motivo,
});

export const setValor = (valor) => ({
  type: SET_VALOR,
  payload: valor,
});

export const setCodigoSap = (sap) => ({
  type: SET_CODIGO_SAP,
  payload: sap,
});

export const setBrand = (brand) => ({
  type: SET_BRAND,
  payload: brand,
});

export const setIsPositivo = (isPositivo) => ({
  type: SET_IS_POSITIVO,
  payload: isPositivo,
});

export const dismissSnackbar = (id) => ({
  type: DISMISS_SNACKBAR,
  payload: id,
});

export const resetStore = () => ({ type: RESET_STORE });

export default {
  types: {
    SET_SELECTED_DEALER,
    SET_MOTIVO,
    SET_VALOR,
    SET_IS_POSITIVO,
    SET_PRODUTO,
    POST_SOLITACAO_CREDITO_START,
    POST_SOLITACAO_CREDITO_SUCCESS,
    POST_SOLITACAO_CREDITO_ERROR,
    GET_PRODUTOS_START,
    GET_PRODUTOS_SUCCESS,
    GET_PRODUTOS_ERROR,
    SET_CODIGO_SAP,
    SET_BRAND,
    DISMISS_SNACKBAR,
    RESET_STORE,
  },
  setSelectedDealer,
  setMotivo,
  setValor,
  setIsPositivo,
  setProduto,
  postSolicitacaoLimiteStart,
  postSolicitacaoLimiteSuccess,
  postSolicitacaoLimiteError,
  getProdutosStart,
  getProdutosSuccess,
  getProdutosError,
  dismissSnackbar,
  setCodigoSap,
  resetStore,
  setBrand,
};
