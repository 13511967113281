import React from 'react';
import PropTypes from 'prop-types';

import { Tracking } from 'modules';
import InputSap from '../formFields/inputSap';
import SelectConcessionaria from '../formFields/selectConcessionaria';
import SelectProduto from '../formFields/selectProduto';
import FormSection from '../formSection';
import InputBrand from '../formFields/inputBrand';
import '../transferenciaLimitePage.scss';

const DadosOrigem = ({
  codigoSap, setCodigoSap,
  produtos, selectedProduct, setProduto, isError, isLoading,
  setConcessionariaSelecionada, concessionarias, selectedDealerId, error,
  brand, setBrand,

}) => (
  <FormSection title="Origem: ">
    <div className="dados-concessionario__container">
      <InputSap
        codigoSap={codigoSap}
        setCodigoSap={setCodigoSap}
        error={error}
        required
      />
      <InputBrand
        brand={brand}
        setBrand={setBrand}
        required
      />
    </div>
    <SelectConcessionaria
      setConcessionariaSelecionada={(value) => {
        Tracking.Mixpanel.transferirLimite.trackConcessionariaOrigem(value);
        setConcessionariaSelecionada(value);
      }}
      concessionarias={concessionarias}
      selectedDealerId={selectedDealerId}
    />
    <SelectProduto
      produtos={produtos}
      selectedProduct={selectedProduct}
      setProduto={(value) => {
        Tracking.Mixpanel.transferirLimite.trackProdutoOrigem(value);
        setProduto(value);
      }}
      isError={isError}
      isLoading={isLoading}
      required
    />
  </FormSection>
);

DadosOrigem.propTypes = {
  codigoSap: PropTypes.string,
  setCodigoSap: PropTypes.func,
  setProduto: PropTypes.func,
  selectedProduct: PropTypes.string,
  produtos: PropTypes.array,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  setConcessionariaSelecionada: PropTypes.func,
  concessionarias: PropTypes.array,
  selectedDealerId: PropTypes.number,
  error: PropTypes.bool,
  brand: PropTypes.string,
  setBrand: PropTypes.func,
};

DadosOrigem.defaultProps = {
  codigoSap: '',
  setCodigoSap: () => {},
  setProduto: () => {},
  selectedProduct: null,
  produtos: null,
  isLoading: null,
  isError: null,
  concessionarias: [],
  setConcessionariaSelecionada: () => {},
  selectedDealerId: null,
  error: false,
  brand: null,
  setBrand: () => {},
};

export default DadosOrigem;
