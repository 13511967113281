/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';
import { Tracking } from 'modules';
import NewMultipleSelectComponent from 'features/common-staff/controls/newMultipleSelectComponent/newMultipleSelectComponent';

const PontoSelector = ({
  pontos, selectedPontos, setFilter, disabledFilterButton,
  isLoading, getFiltroProdutos,
}) => {
  const debouncedSetPonto = useCallback(debounce(500, async (value) => {
    await getFiltroProdutos(value);
  }), []);

  useEffect(() => {
    if (selectedPontos.length === 0) {
      setFilter(pontos);
    }
    debouncedSetPonto(true);
    disabledFilterButton();
  }, [pontos]);

  const dictionary = () => ({
    singular: 'concessionária',
    plural: 'concessionárias',
    type: 'a',
  });

  return (
    <NewMultipleSelectComponent
      options={pontos}
      setOption={(b) => {
        Tracking.Mixpanel.dashboard.filtros.trackFiltroPontos();
        setFilter(b);
      }}
      dictionary={dictionary()}
      startWithAllSelected
      selectedOption={selectedPontos}
      label="Concessionária"
      disabled={isLoading}
      dataCy="brands"
    />
  );
};

PontoSelector.propTypes = {
  setFilter: PropTypes.func,
  selectedPontos: PropTypes.array,
  pontos: PropTypes.array,
  isLoading: PropTypes.bool,
  getFiltroProdutos: PropTypes.func,
  disabledFilterButton: PropTypes.func,
};

PontoSelector.defaultProps = {
  setFilter: () => { },
  selectedPontos: null,
  pontos: [],
  isLoading: false,
  getFiltroProdutos: () => { },
  disabledFilterButton: () => { },
};

export default PontoSelector;
