import { saveAs } from 'file-saver';
import actions from './actions';
import service from './service';

const setIdMovimentacao = (id) => async (dispatch) => {
  dispatch(actions.setIdMovimentacao(id));
};

const resetStore = () => async (dispatch) => {
  dispatch(actions.resetStore());
};

const getExtrato = () => async (dispatch, getState) => {
  try {
    dispatch(actions.exportStart);
    const { movimentacaoId } = getState().gestaoPagamentos.comprovante;
    const response = await service.getExtrato(movimentacaoId);
    const url = window.URL.createObjectURL(new Blob([response]));
    saveAs(url, 'extrato.xlsx');
    dispatch(actions.exportFinish());
  } catch (error) {
    dispatch(actions.exportError('Erro eo exportar extrato'));
  }
};

const dismissSnackbar = (id) => async (dispatch) => {
  dispatch(actions.dismissSnackbar(id));
};

const getComprovante = () => async (dispatch, getState) => {
  try {
    dispatch(actions.getComprovanteStart());
    const { movimentacaoId } = getState().gestaoPagamentos.comprovante;
    const response = await service.getComprovante(movimentacaoId);
    dispatch(actions.getComprovanteSuccess(response));
  } catch (error) {
    dispatch(actions.getComprovanteError());
  }
};

export default {
  setIdMovimentacao,
  resetStore,
  getExtrato,
  dismissSnackbar,
  getComprovante,
};
