const PREFIX = 'pagamentoPage';

const SET_DOCUMENTOS_PAGAMENTO = `${PREFIX}/SET_DOCUMENTOS_PAGAMENTO`;
const SET_VALOR_PAGAMENTO = `${PREFIX}/SET_VALOR_PAGAMENTO`;
const RESET_STORE = `${PREFIX}/RESET_STORE`;
const TOGGLE_EDIT = `${PREFIX}/TOGGLE_EDIT`;

const SET_STATUS = `${PREFIX}/SET_STATUS`;

const PAYMENT_START = `${PREFIX}/PAYMENT_START`;
const PAYMENT_ERROR = `${PREFIX}/PAYMENT_ERROR`;

const DISMISS_SNACKBAR = `${PREFIX}/DISMISS_SNACKBAR`;

const setDocumentosPagemento = (invoices) => ({
  type: SET_DOCUMENTOS_PAGAMENTO,
  payload: { invoices },
});

const setValorPagamento = (invoiceId, value) => ({
  type: SET_VALOR_PAGAMENTO,
  payload: { invoiceId, value },
});

const toogleEdit = (invoiceId) => ({
  type: TOGGLE_EDIT,
  payload: { invoiceId },
});

const resetStore = () => ({
  type: RESET_STORE,
});

const setStartPayment = () => ({
  type: PAYMENT_START,
});

const setPaymentError = (error) => ({
  type: PAYMENT_ERROR,
  payload: { error },
});

const dismissSnackbar = (id) => ({
  type: DISMISS_SNACKBAR,
  payload: { id },
});

const setStatus = (status) => ({
  type: SET_STATUS,
  payload: { status },
});

export default {
  types: {
    RESET_STORE,
    SET_DOCUMENTOS_PAGAMENTO,
    SET_VALOR_PAGAMENTO,
    TOGGLE_EDIT,
    SET_STATUS,
    PAYMENT_ERROR,
    PAYMENT_START,
    DISMISS_SNACKBAR,
  },

  resetStore,
  setDocumentosPagemento,
  setValorPagamento,
  toogleEdit,
  setPaymentError,
  setStartPayment,
  dismissSnackbar,
  setStatus,
};
