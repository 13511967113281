import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/pt-br';

export const safeConcat = (s1, s2) => ((s1 && s2) ? (`${s1 || ''}${s2 || ''}`) : '');

export const formatMvs = (modelo, versao, serie) => (`${modelo || '???'}-${versao || '???'}-${serie || '?'}`);

export const formatPctg = (pctg, digits) => {
  if (!_.isNumber(pctg)) {
    return '-- %';
  }

  if (_.isNumber(digits)) {
    return `${pctg.toFixed(digits)} %`;
  }

  return `${pctg} %`;
};

export const formatCodigoConcessionaria = (number) => {
  if (number) {
    let string = number.toString();
    if (string.includes('-')) {
      string = string.replace('-', '');
    }
    if (string.length >= 4) {
      return (`${string.slice(0, -1)}-${string.split('').pop()}`);
    }
    return string;
  }
  return '';
};

export const formatCnpj = (cnpj) => {
  if (cnpj) {
    const string = cnpj.toString();
    if (string.length === 14) {
      return (`${string.slice(0, 2)}.${string.slice(2, 5)}.${string.slice(5, 8)}/${string.slice(8, 12)}.${string.slice(12, 14)}`);
    }
    return string;
  }
  return '';
};

export const formatModalidade = (desconto, parcelas, prazo, taxa) => `Financiado • ${desconto || ''}% desconto • ${parcelas || ''}x em ${prazo || ''} dias com taxa de ${taxa}% a.m`;

export const formatDescVeiculo = (string) => string?.toLowerCase().replace(/(\b[0-9][x][0-9])|(\b[m][y])|(\b\s[a-z](?=[a-z][a-z]\s))|(\b[0-9][0-9][v])/g, (s) => s.toUpperCase());

export const formatNomeConcessionaria = (string) => string?.toLowerCase().replace(/(\b[a-z][a-z]\s)|(\b[a-z](?=[a-z-]))/g, (s) => s.toUpperCase()).replace(/(\b[Dd][Ee](?=[\s]))/g, (s) => s.toLowerCase());

export const formatDate = (date, formatStr = null) => {
  if (_.isDate(date) && !formatStr) {
    return (date?.toLocaleDateString());
  }

  if (_.isDate(date) && formatStr) {
    // eslint-disable-next-line new-cap
    const momentObj = new moment(date);
    return momentObj.format(formatStr).replace(/(\b\s[a-z](?=[a-z][a-z]\s))/g, (s) => s.toUpperCase());
  }

  if (_.isString(date) && formatStr) {
    const [day, month, year] = date.split('/');
    // eslint-disable-next-line new-cap
    const momentObj = new moment(new Date(year, month - 1, day));
    return momentObj.format(formatStr);
  }

  if (moment.isMoment(date) && formatStr) {
    // eslint-disable-next-line new-cap
    return date.locale('pt').format(formatStr).replace(/(\b[a-z](?=[a-z][a-z]))/g, (s) => s.toUpperCase());
  }

  return '';
};

export const formatHour = (hour, formatStr = null) => {
  if (_.isDate(hour) && !formatStr) {
    return (hour?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
  }
  if (_.isString(hour) && formatStr) {
    // eslint-disable-next-line new-cap
    const momentObj = new moment(new Date(hour));
    return momentObj.format(formatStr);
  }
  return '';
};

export const camelFormat = (value, length = 0) => value
  ?.toLowerCase()
  ?.split(' ')
  ?.map((word) => (word.length > length ? word.charAt(0).toUpperCase() + word.slice(1) : word))
  ?.join(' ');

export const capitalize = (value, length = 0) => value
  ?.split(' ')
  ?.map((word) => (word.length > length ? word.charAt(0).toUpperCase() + word.slice(1) : word))
  ?.join(' ');

export const getPercent = (value, total) => (
  total > 0 ? (100 * (value / total)) : 0
);

export const formatCurrency = (value = 0) => value.toLocaleString('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});
