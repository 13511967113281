import { actions } from './balanceActions';

export const INITIAL_STATE = {
  blockedBalance: undefined,
  remainingBalance: undefined,
  lastUpdate: null,
  visible: true,
  atualizando: false,
};

export default (state = INITIAL_STATE, action = { type: 'default' }) => {
  switch (action.type) {
    case actions.types.LOAD_SALDO_START:
      return {
        ...state,
        loading: true,
      };
    case actions.types.LOAD_SALDO_SUCCESS: {
      const { saldoInfo } = action.payload;

      if (saldoInfo.atualizando === true) {
        return {
          ...state,
          loading: false,
          atualizando: true,
          ultimaAtualizacao: null,
          creditoBloqueado: null,
          creditoRemanescente: null,
        };
      }

      if (saldoInfo.atualizando === false) {
        return {
          ...state,
          loading: false,
          atualizando: false,
          ultimaAtualizacao: saldoInfo.ultimaAtualizacao,
          creditoRemanescente: saldoInfo.creditoRemanescente,
          creditoBloqueado: saldoInfo.creditoBloqueado,
        };
      }

      return INITIAL_STATE; // should never happen
    }
    case actions.types.LOAD_SALDO_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.types.TOGGLE_VISIBILITY:
      return { ...state, visible: !state.visible };
    default:
      return state;
  }
};
