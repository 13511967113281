import React from 'react';
import PropTypes from 'prop-types';
import { EmojiButtonActive, EmojiButtonAnswered } from './emojiButtons';

import './emojisMessage.scss';

const emojiValues = ['angry', 'funny', 'heart', 'sad', 'smile'];

const EmojisMessage = ({
  className, onClick, value, isAnswered,
}) => (
  <div className={className}>
    <div className="chat__emoji-msg__container">
      {emojiValues.map((val, index) => (
        isAnswered ? (
          <EmojiButtonAnswered
            key={index}
            value={val}
            selected={val === value}
          />
        ) : (
          <EmojiButtonActive
            key={index}
            value={val}
            selected={val === value}
            onClick={() => onClick(val)}
          />
        )
      ))}
    </div>
  </div>
);

EmojisMessage.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.string,
  isAnswered: PropTypes.bool.isRequired,
};

EmojisMessage.defaultProps = {
  className: '',
  value: null,
  onClick: () => {},
};

export default EmojisMessage;
