import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'utils/format';
import TimelineRow from '../timeLineListRow/timelineRow';

import './timelineGroup.scss';

const TimeLineGroup = ({ grupoComentarios, index }) => {
  if (grupoComentarios?.data) {
    return (
      <div className={`common__comentarios-list__content_outer${(index === 0) ? '' : '-border'}`}>
        <div className="common__comentarios-list__content">
          <div
            className="common__comentarios-list__content_label"
            data-cy="ComentarioListContentLabel"
          >
            {(formatDate(new Date(grupoComentarios?.data), 'DD MMM YYYY')).toUpperCase()}
          </div>
          <div className="common__comentarios-list__content_comentarios">
            {
          (grupoComentarios?.eventos || []).map((c, i) => (
            <TimelineRow comentario={c} key={i} />
          ))
        }
          </div>
        </div>
      </div>
    );
  }
  return null;
};

TimeLineGroup.propTypes = {
  grupoComentarios: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default TimeLineGroup;
