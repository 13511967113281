import React from 'react';
import PropTypes from 'prop-types';

import { RadioGroup } from 'features/common';
import { Tracking } from 'modules';

const RadioGroupProduto = ({
  produtos, selectedProduct, setProduto, isError, isLoading, required,
}) => (
  <RadioGroup
    label="Produto"
    items={produtos}
    setItem={(value) => {
      Tracking.Mixpanel.alterarLimite.trackProduto(value);
      setProduto(value);
    }}
    selectedItem={selectedProduct}
    isError={isError}
    isLoading={isLoading}
    required={required}
  />
);

RadioGroupProduto.propTypes = {
  setProduto: PropTypes.func,
  selectedProduct: PropTypes.string,
  produtos: PropTypes.array,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  required: PropTypes.bool,
};

RadioGroupProduto.defaultProps = {
  setProduto: () => {},
  selectedProduct: null,
  produtos: null,
  isLoading: null,
  isError: null,
  required: false,
};

export default RadioGroupProduto;
