import React from 'react';

import BreadCrumb from 'features/common-staff/layout/breadCrumb';

const BreadCrumbCarteira = () => (
  <BreadCrumb
    labels={[{
      label: 'Carteira Floor Plan',
      path: '/carteira',
    }]}
  />
);

export default BreadCrumbCarteira;
