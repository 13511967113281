import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars';

import {
  DuplicataNumber, DuplicataValue, Date, Currency,
} from '../../../common';

import './paymentConfirmationList.scss';

const PaymentConfirmationRow = ({ invoice }) => {
  const {
    invoiceNumber, invoiceDigit, dueDate, status,
    totalValue, processingValue, remainingValue, selectedForPaymentValue,
  } = invoice;

  return (
    <div className="confirmation-row ">
      <div className="confirmation-row-number">
        Nº
        <DuplicataNumber
          number={invoiceNumber}
          digit={invoiceDigit}
        />
      </div>

      <Date
        className="confirmation-row-date"
        date={dueDate}
        format="DD MMM YYYY"
      />

      <DuplicataValue
        className="confirmation-row-total-value"
        status={status}
        totalValue={totalValue}
        processingValue={processingValue}
        remainingValue={remainingValue}
      />

      <Currency
        className="confirmation-row-paying-value"
        value={selectedForPaymentValue}
        noColor
      />
    </div>
  );
};

const PaymentConfirmationList = ({
  className, invoices, t,
}) => (
  <div className={`confirmation-list-container ${className}`}>
    <div className="confirmation-list-header confirmation-list-header-inv">
      {t('Duplicata')}
    </div>
    <div className="confirmation-list-header confirmation-list-header-due">
      {t('Vencimento')}
    </div>
    <div className="confirmation-list-header confirmation-list-header-balance">
      {t('Saldo duplicata')}
    </div>
    <div className="confirmation-list-header confirmation-list-header-pay">
      {t('Valor a pagar')}
    </div>
    <div className="confirmation-rows-container">
      <Scrollbars>
        {invoices.map((invoice, index) => (
          <PaymentConfirmationRow
            invoice={invoice}
            key={index}
          />
        ))}
      </Scrollbars>
    </div>

  </div>
);

PaymentConfirmationList.propTypes = {
  className: PropTypes.string,
  t: PropTypes.func,
  invoices: PropTypes.array,
};

PaymentConfirmationList.defaultProps = {
  className: '',
  invoices: [],
  t: (i) => i,
};

PaymentConfirmationRow.propTypes = {
  invoice: PropTypes.object.isRequired,
};

export default withNamespaces('payment')(PaymentConfirmationList);
