import React from 'react';
import PropTypes from 'prop-types';

import Chat, { ChatInput } from '../../../common/chat';
import PopoverHeader from '../popoverHeader';

import { UserInfo } from './utils/userInfo';
import { getCategoriasFromPermissions } from './utils/getCategoriasFromPermissions';
import { content } from '../../redux/content';
import { etapas } from './redux/etapas';

import './errorFeedback.scss';

// eslint-disable-next-line no-promise-executor-return
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// eslint-disable-next-line arrow-body-style
class ErrorFeedback extends React.Component {
  componentDidMount() {
    const {
      user, selectedDealer, permissions,
      goToSelecionarCategoria, setCategoriasDisponiveis, setUserInfo,
    } = this.props;

    setUserInfo(new UserInfo({
      usuarioId: user.id,
      usuarioEmail: user.email,
      usuarioNome: user.name,
      concessionariaId: selectedDealer.dealerId,
      concessionariaNome: selectedDealer.dealerName,
      regional: selectedDealer.region,
      regionalCodigo: selectedDealer.regionId,
    }));
    setCategoriasDisponiveis(getCategoriasFromPermissions(permissions));
    delay(200).then(() => goToSelecionarCategoria());
  }

  componentWillUnmount() {
    const { etapaAtual, resetStore } = this.props;
    if (etapaAtual === etapas.finalizado) {
      resetStore();
    }
  }

  resetPopoverContent = () => {
    const { setPopoverContent, resetStore } = this.props;
    resetStore();
    setPopoverContent(content.main);
  };

  render() {
    const {
      className, mensagens, isInputAvailable, descreverProblema,
    } = this.props;

    return (
      <div className={`feedback-error__container ${className}`}>
        <PopoverHeader
          onClick={() => { this.resetPopoverContent(); }}
          className="feedback-error__header"
          title="Relatar um Problema"
        />
        {/* <div className="feedback-error__header">
          <div
            className="feedback-error__header-icon"
            onClick={() => { this.resetPopoverContent(); }}
            role="button"
            tabIndex="0"
          >
            <ArrowBackIos />
          </div>
          <span>Relatar um Problema</span>
        </div>
  */}
        <Chat
          className="feedback-error__chat"
          mensagens={mensagens}
        />
        <div className="feedback-error__input">
          { isInputAvailable ? (
            <ChatInput
              onSendClicked={(value) => {
                descreverProblema(value);
              }}
            />
          ) : null }
        </div>
      </div>
    );
  }
}

ErrorFeedback.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired,
  selectedDealer: PropTypes.object.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string),
  etapaAtual: PropTypes.string.isRequired,
  mensagens: PropTypes.array,
  isInputAvailable: PropTypes.bool.isRequired,

  setCategoriasDisponiveis: PropTypes.func.isRequired,
  goToSelecionarCategoria: PropTypes.func.isRequired,
  descreverProblema: PropTypes.func.isRequired,
  resetStore: PropTypes.func.isRequired,
  setPopoverContent: PropTypes.func.isRequired,
  setUserInfo: PropTypes.func.isRequired,
};

ErrorFeedback.defaultProps = {
  className: '',
  permissions: [],
  mensagens: [],
};

export default ErrorFeedback;
