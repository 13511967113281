import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

const StandardCurrencyFormat = ({ inputRef, onChange, ...other }) => (
  <NumberFormat
    {...other}
    getInputRef={inputRef}
    onValueChange={(values) => {
      onChange({
        target: {
          value: values.value,
        },
      });
    }}
    allowNegative={false}
    decimalSeparator=","
    decimalScale="2"
    fixedDecimalScale
    thousandSeparator="."
    isNumericString
    prefix="R$ "
  />
);

StandardCurrencyFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default StandardCurrencyFormat;
