import * as React from "react";

function ExtensionIcon(props) {
	return (
		<svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
			<path
				d="M21 3a1 1 0 011 1v16a1 1 0 01-1 1H4a1 1 0 01-1-1v-8.586a1 1 0 01.293-.707l7.414-7.414A1 1 0 0111.414 3H21zm-3 11h-1a1 1 0 00-.117 1.993L17 16h1a1 1 0 000-2zm1-9h-6.58a1 1 0 00-.706.292l-4.005 4A1 1 0 008.416 11H19a1 1 0 001-1V6a1 1 0 00-1-1z"
				fill={props.fill ? `${props.fill}` : "currentColor"}
				fillRule="evenodd"
			/>
		</svg>
	);
}

export default ExtensionIcon;

