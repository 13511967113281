import { connect } from 'react-redux';

import { NotificationsOperations, NotificationSelectors } from '../../redux';
import NotificationDrawer from './notificationDrawer';

const mapDispatchToProps = (dispatch) => ({
  getNotifications: (cnpjRoot) => (
    dispatch(NotificationsOperations.getNotifications(cnpjRoot))
  ),
  getNotificationsLastGet: (dealerGroups, lastGet) => (
    dispatch(NotificationsOperations.getNotificationsLastGet(dealerGroups, lastGet))
  ),
});

const mapStateToProps = ({ notificationStore, dealerStore }) => ({
  dealerGroups: NotificationSelectors.getDealerGroups(dealerStore),
  lastGet: notificationStore.lastGet,
});
export default connect(mapStateToProps, mapDispatchToProps)(NotificationDrawer);
