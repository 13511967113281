import React from 'react';
import PropTypes from 'prop-types';
import OptionButton from './optionButton';
import './optionsMessage.scss';

const OptionsMessage = ({
  className, optionList, onClick, isAnswered,
}) => (
  <div className={className}>
    <div className="choice-message-list__container">
      {
      (optionList || []).map((option, index) => (
        <OptionButton
          key={index}
          className="choice-message-list__item-container"
          selected={option.selected}
          text={option.text}
          value={option.value}
          onClick={onClick}
          isAnswered={isAnswered}
        />
      ))
      }
    </div>
  </div>
);

OptionsMessage.propTypes = {
  className: PropTypes.string,
  optionList: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  isAnswered: PropTypes.bool.isRequired,
};

OptionsMessage.defaultProps = {
  className: '',
};

export default OptionsMessage;
