import React from 'react';
import PropTypes from 'prop-types';

import { formatCurrency } from 'utils/format';
import { CardVencimentoType } from '../../../redux/enum';
import BotaoRedirecionamento from '../botaoRedirecionamento';

import './cardVencimento.scss';

const defineLabel = (type) => {
  if (type === CardVencimentoType.actual) return 'Duplicatas vencem hoje';
  if (type === CardVencimentoType.vencidas) return 'Duplicatas vencidas';
  if (type === CardVencimentoType.dez_dias) return 'Duplicatas vencem em 10 dias';
  if (type === CardVencimentoType.vinte_dias) return 'Duplicatas vencem em 20 dias';
  return null;
};

const defineClass = (type, quantidade) => {
  if (type === CardVencimentoType.vencidas && quantidade >= 1) return 'card-vencimento__container-vencida';
  return 'card-vencimento__container-comun';
};

const Card = ({ item }) => (
  <div className={defineClass(item?.tipo, item?.quantidadeDuplicatas)}>
    <div className="card-vencimento__quantidades">
      {item?.quantidadeDuplicatas}
    </div>
    <div className="card-vencimento__label">
      {defineLabel(item?.tipo)}
    </div>
    <div className="card-vencimento__valor">
      {`Valor: ${formatCurrency(item?.valor)}`}
    </div>
    <div className="card-vencimento__valor-acumulado">
      {item?.tipo !== CardVencimentoType.vencidas
        ? `Valor acumulado: ${formatCurrency(item?.valorAcumulado)}`
        : (<>&nbsp;</>)}
    </div>
    <div className="card-vencimento__footer">
      <BotaoRedirecionamento
        type={item?.tipo}
        isVencida={item?.tipo === CardVencimentoType.vencidas && item?.quantidadeDuplicatas >= 1}
      />
    </div>
  </div>
);

Card.propTypes = {
  item: PropTypes.object,
};

Card.defaultProps = {
  item: null,
};

export default Card;
