import { connect } from 'react-redux';

import ResumoCard from './resumoCard';
import selector from '../../redux/selector';

const mapStateToProps = ({
  gestaoPagamentos, dealerStore, authStore, balanceStore,
}) => ({
  dealerId: dealerStore.selectedFPlanDealerId,
  invoicesTotal: selector.getTotalValue(gestaoPagamentos),
  user: authStore.user,
  invoices: gestaoPagamentos.pagamento.docs,
  saldoRemanescente: balanceStore.creditoRemanescente,
});

export default connect(mapStateToProps, null)(ResumoCard);
