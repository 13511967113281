import { connect } from 'react-redux';
import { compose } from 'redux';
import { push } from 'react-router-redux';
import { withRouter } from 'react-router-dom';

import ComprovantePage from './comprovantePage';

import operations from './redux/operations';

import {
  PATH_CARTEIRA,
} from '../../../../routes/paths';

const mapStateToProps = ({ gestaoPagamentos }) => ({
  idMovimentacao: gestaoPagamentos.comprovante.movimentacaoId,
  isLoading: gestaoPagamentos.comprovante.isLoadingComprovantes,
  isError: gestaoPagamentos.comprovante.isError,
  snackbarErrors: gestaoPagamentos.comprovante.snackbars,
});

const mapDispatchToProps = (dispatch) => ({
  navTo: () => dispatch(push(PATH_CARTEIRA)),
  resetStore: () => dispatch(operations.resetStore()),
  onCloseSnackBar: (id) => dispatch(operations.dismissSnackbar(id)),
  getComprovante: () => dispatch(operations.getComprovante()),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ComprovantePage);
