import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AlertCard from 'features/common/alertCard/alertCard';
import colors from 'assets/styles/colors';
import PopperComponent from '../popperComponent/popperComponent';
import ButtonStyle from './newButton.styles';

const NewButton = ({
  onClick, disabled, type, openPopper, widthCard,
  children, className, width, ref, alertCardTitle, icone,
  colorBase, placement,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (!openPopper) {
      setAnchorEl(null);
    }
  }, [openPopper]);

  const handleClick = (event) => {
    if (openPopper) {
      setAnchorEl(event.currentTarget);
    } else {
      onClick();
    }
  };

  return (
    <>
      <ButtonStyle
        type={type}
        disabled={disabled}
        onClick={handleClick}
        className={className}
        width={width}
        ref={ref}
      >
        {children}
      </ButtonStyle>
      <PopperComponent anchorEl={anchorEl} open={openPopper} placement={placement}>
        <AlertCard
          width={widthCard}
          title={alertCardTitle}
          icone={icone}
          colorBase={colorBase}
          alertCardContent="8px"
          staff
        />
      </PopperComponent>
    </>
  );
};

NewButton.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  widthCard: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.element,
  ref: PropTypes.element,
  disabled: PropTypes.bool,
  openPopper: PropTypes.bool,
  alertCardTitle: PropTypes.string,
  icone: PropTypes.element,
  colorBase: PropTypes.string,
  placement: PropTypes.string,
};

NewButton.defaultProps = {
  className: '',
  width: '',
  widthCard: '',
  type: 'button',
  onClick: () => {},
  children: null,
  ref: null,
  disabled: false,
  openPopper: false,
  alertCardTitle: '',
  icone: null,
  colorBase: colors.alert_color_200,
  placement: 'top-end',
};

export default NewButton;
