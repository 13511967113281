import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import useCustomFetch from 'hooks/useFetch';
import DealerSelector from 'features/dealerSelector';
import SaldoAtualizado from './saldoAtualizado/saldoAtualizado';
import MenuPageList from './menuPageList/menuPageList';
import { MenuLateralContainer, Line } from './menuCarteiraFidc.style';
import totalService from '../services/totalService';

const MenuCarteiraFidc = ({ dealer }) => {
  const [{ loading, data }] = useCustomFetch(
    () => totalService.getSaldoAtualizado(dealer?.cnpj, dealer?.brand),
    [dealer],
  );

  const saldoAtualizado = _.isEmpty(data) || _.isNull(data) ? undefined : data;

  return (
    <MenuLateralContainer>
      <DealerSelector isLoadingFidc={loading} />
      <Line />
      <MenuPageList loading={loading}>
        <SaldoAtualizado loading={loading} data={saldoAtualizado} />
      </MenuPageList>
    </MenuLateralContainer>
  );
};

MenuCarteiraFidc.propTypes = {
  dealer: PropTypes.object.isRequired,
};

export default MenuCarteiraFidc;
