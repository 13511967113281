import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';

import XIcon from '../../../../assets/icons/x';

import NotificationContent from '../notificationContent';

import './notificationDrawer.scss';

const styles = {
  paper: {
    marginLeft: '64px',
    boxShadow: 'none',
    width: '290px',
  },
  BackdropProps: {
    background: 'linear-gradient(to left, rgba(0,0,0,0.4), rgba(0,0,0,0.4), rgba(0,0,0,0.4), rgba(0,0,0,0.4), rgba(0,0,0,0.4), rgba(0,0,0,0.4),transparent);',
  },
};

class NotificationDrawer extends React.Component {
  componentDidMount() {
    const {
      getNotifications, dealerGroups,
    } = this.props;
    getNotifications(dealerGroups);
    setInterval(() => {
      this.lastGet();
    }, 300000);
  }

  lastGet = () => {
    const {
      dealerGroups, getNotificationsLastGet, lastGet,
    } = this.props;
    getNotificationsLastGet(dealerGroups, lastGet);
  };

  render() {
    const {
      isOpen, openPosition, onClose, classes,
    } = this.props;
    return (
      <Drawer
        open={isOpen}
        anchor={openPosition}
        onClose={onClose}
        classes={{ paper: classes.paper }}
        ModalProps={{
          BackdropProps: {
            classes: {
              root: classes.BackdropProps,
            },
          },
        }}
      >
        <section className="notifications-page-section">
          <XIcon
            className="notifications-page-x"
            onClick={onClose}
          />

          <NotificationContent
            isOpen={isOpen}
          />

        </section>
      </Drawer>
    );
  }
}

NotificationDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  getNotifications: PropTypes.func.isRequired,
  dealerGroups: PropTypes.array.isRequired,
  openPosition: PropTypes.oneOf(['right', 'left', 'bottom', 'top']),
  getNotificationsLastGet: PropTypes.func.isRequired,
  lastGet: PropTypes.string,
};

NotificationDrawer.defaultProps = {
  openPosition: 'left',
  onClose: () => (null),
  lastGet: null,
};

export default withStyles(styles)(NotificationDrawer);
