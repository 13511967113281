import styled from 'styled-components';

const getColor = (isActive) => {
  if (isActive.selected) return '#1B2961';
  if (isActive.isActive) return '#505669';
  return '#7C839A';
};

export const MenuPadding = styled.div`
  padding: 4px 8px;
`;

export const DetailsContainer = styled.details`
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.25px;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
`;

export const DropDownLabel = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: -5px;
`;

export const TitleDropDown = styled.span`
  font-family: Circular Std;
  font-size: 14px;
  font-style: normal;
  font-weight: ${(props) => (props.isActive ? '500' : '300')};
  color: ${getColor};
  line-height: 24px;
  margin-left: 8px;
  margin-top: 4px;
`;

export const Border = styled.span`
  border: ${(props) => (props.isActive ? '0.6mm solid #1B2961' : 'none')};
  height: 20px;
  display: inline-block;
  border-top-right-radius: 80px 80px;
  border-bottom-right-radius: 80px 80px;
`;

export const SpanContainer = styled.span`
  display: contents;
  color: #555770;
`;

export const Span = styled.span`
  color: ${(props) => (props.isActive ? '#1B2961' : '#555770')};
  font-family: Circular Std;
  font-size: 14px;
  font-style: normal;
  margin-left: 30px;
  letter-spacing: 0.25px;
  font-weight: ${(props) => (props.isActive ? '500' : '300')};
  line-height: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
`;

export const ContainerSubmenu = styled.div`
  height: 28px;
  align-items: center;
  padding: 4px 0px;
  border-radius: 4px;
  background-color: ${(props) => (props.isActive ? 'rgba(210, 217, 243, 0.24)' : '#F9FAFC')};
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  overflow: hidden;
  &:hover ${Border} {
    border: 0.6mm solid #555770;
    height: 20px;
    display: inline-block;
    border-top-right-radius: 80px 80px;
    border-bottom-right-radius: 80px 80px;
  }
  &:hover {
    background-color: rgba(85, 87, 112, 0.08);
    span {
      color: #404154;
      font-family: Circular Std;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
`;

export const SummaryContainer = styled.summary`
  border: none !important;
  height: 28px;
  padding: 4px 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.isActive ? 'pointer' : 'default')};
  margin-bottom: 2px;
  overflow: hidden;
  border-radius: 4px;
  background-color: #F9FAFC;
  color: ${getColor};
  ${(props) => props.isActive
   && `
    &:hover {
    background-color: #E5E6EB;
    span {
      color: #404154;
      font-family: Circular Std;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; 
    }
    path {
      fill: #404154;
    }
  }
`}
`;
