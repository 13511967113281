import { connect } from 'react-redux';
import CardPagamento from './cardPagamento';

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CardPagamento);
