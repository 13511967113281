const PREFIX = 'modalTaxasFloorPlan';

const SET_MODAL_OPEN = `${PREFIX}/SET_MODAL_OPEN`;
const SET_FILTER = `${PREFIX}/SET_FILTER`;
const RESET_STORE = `${PREFIX}/RESET_STORE`;
const GET_TAXAS_START = `${PREFIX}/GET_TAXAS_START`;
const GET_TAXAS_ERROR = `${PREFIX}/GET_TAXAS_ERROR`;
const GET_TAXAS_SUCESS = `${PREFIX}/GET_TAXAS_SUCESS`;

const setModalOpen = () => ({
  type: SET_MODAL_OPEN,
});

const resetStore = () => ({
  type: RESET_STORE,
});

const setFilter = (paramName, value, filterButton) => ({
  type: SET_FILTER,
  payload: { paramName, value, filterButton },
});

const getTaxasStart = () => ({
  type: GET_TAXAS_START,
});

const getTaxasError = () => ({
  type: GET_TAXAS_ERROR,
});

const getTaxasSucesso = (data, filters, brand) => ({
  type: GET_TAXAS_SUCESS,
  payload: { data, filters, brand },
});

export default {
  types: {
    RESET_STORE,
    SET_MODAL_OPEN,
    SET_FILTER,
    GET_TAXAS_ERROR,
    GET_TAXAS_START,
    GET_TAXAS_SUCESS,
  },

  resetStore,
  setModalOpen,
  setFilter,
  getTaxasError,
  getTaxasStart,
  getTaxasSucesso,
};
