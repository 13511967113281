import { connect } from 'react-redux';

import DealerSelect from './dealerSelect';
import operations from '../../../redux/operations';
import selectors from '../../../redux/selectors';

const mapStateToProps = ({ relatoriosIof, dealerStore }) => ({
  items: selectors.concessionariaList(dealerStore),
  dealerIsLoading: relatoriosIof.requestStatus?.isLoading,
  selectedDealers: relatoriosIof.filtros.dealers,
});

const mapDispatchToProps = (dispatch) => ({
  onSelect: (value) => dispatch(operations.setFilter(value, 'dealers')),
});

export default connect(mapStateToProps, mapDispatchToProps)(DealerSelect);
