import React from 'react';
import PropTypes from 'prop-types';

import CheckmarkIcon from 'assets/icons/check-mark';
import DocumentoIcon from 'assets/icons/documento';
import PersonSearchIcon from 'assets/icons/person-search';
import FaturaIcon from 'assets/icons/fatura';
import CardIcon from 'assets/icons/card';
import CarIcon from 'assets/icons/carro';

const TimelineIcon = ({ status }) => {
  switch (status) {
    case 'analise_comercial':
      return <DocumentoIcon style={{ fontSize: 20 }} />;
    case 'analise_credito':
      return <CardIcon style={{ fontSize: 20 }} />;
    case 'revisao':
      return <PersonSearchIcon style={{ fontSize: 20 }} />;
    case 'separacao':
      return <CarIcon style={{ fontSize: 20 }} />;
    case 'faturamento':
      return <FaturaIcon style={{ fontSize: 20 }} />;
    case 'concluido':
      return <CheckmarkIcon style={{ fontSize: 20 }} />;
    default:
      return <CardIcon style={{ fontSize: 20 }} />;
  }
};

TimelineIcon.propTypes = {
  status: PropTypes.string,
};

TimelineIcon.defaultProps = {
  status: null,
};

export default TimelineIcon;
