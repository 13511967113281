import actions from './actions';

export const INITIAL_STATE_PRODUTOS = {
  isLoading: false,
  isError: false,
  produtos: null,
};

export const reduceGetProdutos = (state, action) => {
  switch (action.type) {
    case actions.types.GET_PRODUTOS_START:
      return {
        ...state,
        produtos: {
          ...state.produtos,
          isLoading: true,
          isError: false,
        },
      };
    case actions.types.GET_PRODUTOS_SUCCESS:
      return {
        ...state,
        produtos: {
          ...state.produtos,
          isLoading: false,
          isError: false,
          produtos: action.payload,
        },
      };
    case actions.types.GET_PRODUTOS_ERROR:
      return {
        ...state,
        produtos: {
          ...state.produtos,
          isLoading: false,
          isError: true,
          produtos: null,
        },
      };
    default:
      return state;
  }
};
