/* eslint-disable no-tabs */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';

import {
  formatCurrency, formatDate,
} from 'utils/format';

import './detalhamentoDuplicata.scss';

const DetalhamentoDuplicata = ({ invoice }) => (
  <div className="modal-carteira__detalhamento-duplicata">
    <Scrollbars>
      <div className="modal-carteira__detalhamento-duplicata__container">
        <div className="modal-carteira__detalhamento-duplicata__container__bloco">
          <div className="modal-carteira__detalhamento-duplicata__container__bloco__row">
            <div className="modal-carteira__detalhamento-duplicata__containe__bloco__row__param">
              Data de emissão:
            </div>
            <div className="modal-carteira__detalhamento-duplicata__container__bloco__row__value">
              {formatDate(invoice?.dataEmissao, 'DD MMM YYYY')}
            </div>
          </div>
          {_.isNull(invoice?.dataExpedicao) || _.isUndefined(invoice?.dataExpedicao)
            ? null
            : (
              <div className="modal-carteira__detalhamento-duplicata__container__bloco__row">
                <div className="modal-carteira__detalhamento-duplicata__container__bloco__row__param">
                  Data de expedição:
                </div>
                <div className="modal-carteira__detalhamento-duplicata__container__bloco__row__value">
                  {formatDate(invoice?.dataExpedicao, 'DD MMM YYYY')}
                </div>
              </div>
            )}
          {_.isNull(invoice?.dataVenda) || _.isUndefined(invoice?.dataVenda)
            ? null
            : (
              <div className="modal-carteira__detalhamento-duplicata__container__bloco__row">
                <div className="modal-carteira__detalhamento-duplicata__container__bloco__row__param">
                  Data de venda:
                </div>
                <div className="modal-carteira__detalhamento-duplicata__container__bloco__row__value">
                  {formatDate(invoice?.dataVenda, 'DD MMM YYYY')}
                </div>
              </div>
            )}
          {_.isNull(invoice?.dataUltimoPagamento) || _.isUndefined(invoice?.dataUltimoPagamento)
            ? null
            : (
              <div className="modal-carteira__detalhamento-duplicata__container__bloco__row">
                <div className="modal-carteira__detalhamento-duplicata__container__bloco__row__param">
                  Data último pagamento:
                </div>
                <div className="modal-carteira__detalhamento-duplicata__container__bloco__row__value">
                  {formatDate(invoice?.dataUltimoPagamento, 'DD MMM YYYY')}
                </div>
              </div>
            )}
          {_.isNull(invoice?.dataQuitacaoIntegral) || _.isUndefined(invoice?.dataQuitacaoIntegral)
            ? null
            : (
              <div className="modal-carteira__detalhamento-duplicata__container__bloco__row">
                <div className="modal-carteira__detalhamento-duplicata__container__bloco__row__param">
                  Data de processamento:
                </div>
                <div className="modal-carteira__detalhamento-duplicata__container__bloco__row__value">
                  {formatDate(invoice?.dataQuitacaoIntegral, 'DD MMM YYYY')}
                </div>
              </div>
            )}
          {_.isNull(invoice?.diasEmAtraso) ? null
            : (
              <div className="modal-carteira__detalhamento-duplicata__container__bloco__row">
                <div className="modal-carteira__detalhamento-duplicata__container__bloco__row__param">
                  Dias de atraso:
                </div>
                <div className="modal-carteira__detalhamento-duplicata__container__bloco__row__value">
                  {`${invoice?.diasEmAtraso} ${invoice?.diasEmAtraso === 1 ? 'dia' : 'dias'}`}
                </div>
              </div>
            )}
        </div>
        <div className="modal-carteira__detalhamento-duplicata__container__bloco">
          <div className="modal-carteira__detalhamento-duplicata__container__bloco__row">
            <div className="modal-carteira__detalhamento-duplicata__container__bloco__row__param">
              Valor de emissão:
            </div>
            <div className="modal-carteira__detalhamento-duplicata__container__bloco__row__value">
              {formatCurrency(invoice?.valorEmissao)}
            </div>
          </div>
          <div className="modal-carteira__detalhamento-duplicata__container__bloco__row">
            <div className="modal-carteira__detalhamento-duplicata__container__bloco__row__param">
              Saldo atual:
            </div>
            <div className="modal-carteira__detalhamento-duplicata__container__bloco__row__value">
              {formatCurrency(invoice?.saldoDevedor)}
            </div>
          </div>
          {_.isNull(invoice?.dataVencimentoVenda) || _.isUndefined(invoice?.dataVencimentoVenda)
            ? null
            : (
              <div className="modal-carteira__detalhamento-duplicata__container__bloco__row">
                <div className="modal-carteira__detalhamento-duplicata__container__bloco__row__param">
                  Vencimento pela venda:
                </div>
                <div className="modal-carteira__detalhamento-duplicata__container__bloco__row__value">
                  {formatDate(invoice?.dataVencimentoVenda, 'DD MMM YYYY')}
                </div>
              </div>
            )}
          <div className="modal-carteira__detalhamento-duplicata__container__bloco__row">
            <div className="modal-carteira__detalhamento-duplicata__container__bloco__row__param">
              Total de créditos:
            </div>
            <div className="modal-carteira__detalhamento-duplicata__container__bloco__row__value">
              {formatCurrency(invoice?.valorTotalCredito)}
            </div>
          </div>
          {_.isNull(invoice?.dataVencimentoVenda) && !_.isNull(invoice?.dataVencimento)
            ? (
              <div className="modal-carteira__detalhamento-duplicata__container__bloco__row">
                <div className="modal-carteira__detalhamento-duplicata__container__bloco__row__param">
                  Vencimento:
                </div>
                <div className="modal-carteira__detalhamento-duplicata__container__bloco__row__value">
                  {formatDate(invoice?.dataVencimento, 'DD MMM YYYY')}
                </div>
              </div>
            ) : null}
        </div>
        {_.isNull(invoice?.jurosEmAberto) && _.isNull(invoice?.jurosPagos) ? null
          : (
            <div className="modal-carteira__detalhamento-duplicata__container__bloco">
              {_.isNull(invoice?.jurosEmAberto) ? null
                : (
                  <div className="modal-carteira__detalhamento-duplicata__container__bloco__row">
                    <div className="modal-carteira__detalhamento-duplicata__container__bloco__row__param">
                      Valor de juros em aberto:
                    </div>
                    <div className="modal-carteira__detalhamento-duplicata__container__bloco__row__value">
                      {formatCurrency(invoice?.jurosEmAberto)}
                    </div>
                  </div>
                )}
              {_.isNull(invoice?.jurosPagos) ? null
                : (
                  <div className="modal-carteira__detalhamento-duplicata__container__bloco__row">
                    <div className="modal-carteira__detalhamento-duplicata__container__bloco__row__param">
                      Valor de juros pagos:
                    </div>
                    <div className="modal-carteira__detalhamento-duplicata__container__bloco__row__value">
                      {formatCurrency(invoice?.jurosPagos)}
                    </div>
                  </div>
                )}
            </div>
          )}
      </div>
    </Scrollbars>
  </div>
);

DetalhamentoDuplicata.propTypes = {
  invoice: PropTypes.object,
};

DetalhamentoDuplicata.defaultProps = {
  invoice: null,
};

export default DetalhamentoDuplicata;
