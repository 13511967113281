import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LogoStellantisBanco from 'assets/icons/iconsRebranding/logo-stellantis-banco';
import UserEntry from '../userEntry';
import LoadingRedirect from '../loadingRedirect';

import './signIn.scss';

class SignIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
    };
  }

  componentDidMount() {
    const { sessionExpired, invalidateSession, loginRedirect } = this.props;
    loginRedirect(localStorage.getItem('key'));
    localStorage.removeItem('key');
    if (sessionExpired) {
      invalidateSession();
    }
  }

  submit = async () => {
    const { email, password } = this.state;
    const { login } = this.props;
    await login(email, password);
  };

  handleChange = (value, id) => {
    this.setState({ [id]: value });
  };

  render() {
    const { email, password } = this.state;
    const {
      isLoginError, loginLoading, loadingRedirect, errorMessage,
    } = this.props;
    return (
      <div className="login-component">
        <div className="entry-background">
          {loadingRedirect
            ? (<LoadingRedirect />)
            : (
              <UserEntry
                username={email}
                password={password}
                onClick={this.submit}
                isLoginError={isLoginError}
                loginLoading={loginLoading}
                onChange={this.handleChange}
                className="user-entry"
                errorMessage={errorMessage}
              />
            )}
        </div>
        <div className="card-login">
          <div className="title-box">
            <div id="stellantis-logo">
              <LogoStellantisBanco className="logo-stellantis" alt="Banco Stellantis Logo" />
            </div>
            <div className="card-login-subtitle">
              <p className="font-head-2">SISTEMA HUB</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SignIn.propTypes = {
  isLoginError: PropTypes.bool.isRequired,
  loginLoading: PropTypes.bool.isRequired,
  sessionExpired: PropTypes.bool.isRequired,
  invalidateSession: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  loginRedirect: PropTypes.func.isRequired,
  loadingRedirect: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
};

export default SignIn;
