import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import ErrorIcon from '@material-ui/icons/Error';
import { formatCurrency } from 'utils/format';
import { PagamentoStatus } from '../../redux/enum';

import './alertaError.scss';

const defineErrorMessage = (status, value) => {
  if (value < 0) {
    return `Não é possível realizar o pagamento pois o valor solicitado ultrapassa em ${formatCurrency((value * (-1)))} o saldo atual. Edite o valor das duplicatas selecionadas para prosseguir.`;
  }

  if (!status) return 'Pagamento indisponível no momento.';

  if (status.motivo === PagamentoStatus.PAG_INDISPONIVEL_OUTRO) {
    return 'Estamos atualizando alguns dados da sua concessionária, durante esse período os pagamentos estarão desabilitados.';
  }

  if (status.motivo === PagamentoStatus.PAG_INDISPONIVEL_HORARIO_INVALIDO) {
    const { horaInicio, horaFim } = status;
    if (
      moment.isMoment(horaInicio) && horaInicio.isValid()
      && moment.isMoment(horaFim) && horaFim.isValid()
    ) {
      return 'Os pagamentos só podem ser realizados '
      + `entre ${horaInicio.format('HH:mm')} e ${horaFim.format('HH:mm')}.`;
    }
  }

  return 'Pagamento indisponível no momento.';
};

const defineTitle = (value) => {
  if (value < 0) return 'Saldo Insuficiente';
  return 'Pagamento Indisponível';
};

const AlertaError = ({ value, status }) => (
  <div
    className="pagamento-page__alerta-error"
    data-cy="pagamento__alerta-error"
  >
    <div className="pagamento-page__alerta-error__tittle">
      <ErrorIcon style={{ color: '#FFB600', marginRight: '8px' }} />
      {defineTitle(value)}
    </div>
    <div className="pagamento-page__alerta-error__body">
      {defineErrorMessage(status, value)}
    </div>
  </div>
);

AlertaError.propTypes = {
  value: PropTypes.number,
  status: PropTypes.object,
};

AlertaError.defaultProps = {
  value: 0,
  status: null,
};

export default AlertaError;
