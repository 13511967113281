import { connect } from 'react-redux';

import { DealerSelectors } from '../../../../modules/dealers/redux';
import { AuthSelectors } from '../../../../modules/auth/redux';
import { operations as popoverOperations } from '../../redux/operations';

import { operations } from './redux/operations';
import selectors from './redux/selectors';

import ErrorFeedback from './errorFeedback';

const mapStateToProps = ({ authStore, dealerStore, feedback }) => ({
  permissions: AuthSelectors.getAuthorizations(authStore),
  mensagens: selectors.messages(feedback.errorFeedback),
  isInputAvailable: selectors.isTextInputAvailable(feedback.errorFeedback),
  etapaAtual: feedback.errorFeedback.etapaAtual,
  user: authStore.user,
  selectedDealer: DealerSelectors.getSelectedFPlanDealer(dealerStore),
});

const mapDispatchToProps = (dispatch) => ({
  setCategoriasDisponiveis: (cats) => dispatch(operations.setCategoriasDisponiveis(cats)),
  goToSelecionarCategoria: () => dispatch(operations.goFromSentimosMuitoToSelecionarCategoria()),
  selecionarCategoria: (cat) => dispatch(operations.selecionarCategoria(cat)),
  descreverProblema: (desc) => dispatch(operations.descreverProblema(desc)),
  reportarOutro: (querReportar) => dispatch(operations.reportarOutro(querReportar)),
  resetStore: () => dispatch(operations.resetStore()),
  setPopoverContent: (content) => dispatch(popoverOperations.setContent(content)),
  setUserInfo: (userInfo) => dispatch(operations.setUserInfo(userInfo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorFeedback);
