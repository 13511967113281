import styled from 'styled-components';
import { Close } from '@material-ui/icons';
import { Button } from 'features/common';
import { baseButton } from '../exportButton/exportButton.style';

export const PopoverContent = styled.div`
  width: 472px;
  height: fit-content;
  padding: 32px;
  margin-top: 12px;
  border: 1px solid rgba(85, 87, 112, 0.24);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  background-color: #FFFFFF;
`;

export const PopoverArrow = styled.span`
  top: 8px;
  height: 6px;
  width: 6px;

  &::before {
    content: "";
    margin: auto;
    display: block;
    width: 100%;
    height: 100%;
    border: solid 1px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    border-bottom:  solid transparent;
    border-right: solid transparent;
    transform: rotate(45deg);
  }
`;

export const TitleRow = styled.div`
  margin-bottom: 20px;
`;

export const TextRow = styled.div`
  margin-bottom: 24px;
`;

export const Title = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #555770;
`;

export const CloseIcon = styled(Close)`
  margin: 8px;
  width: 24px;
  height: 24px;
  color: #555770;
`;

export const CloseButton = styled.div`
  z-index: 3;
  position: absolute;
  top: 28px;
  right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  :hover {
    cursor: pointer;
    background-color: #F7F9FC;
  }
`;

export const Text = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #7A7C9A;
  text-align: justify;
`;

export const ActionRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CancelButton = styled(Button)`
  width: 116px;
  height: 38px;
  border: rgba(0, 0, 0, 0.02);
  :hover {
    background-color: #F7F9FC;
  };
`;

export const ExportButton = styled(Button)`
  width: 116px;
  height: 38px;
  margin-left: 12px;
  ${baseButton}
`;

export const PopperModifiers = (arrowRef) => [
  {
    name: 'flip',
    enabled: false,
  },
  {
    name: 'preventOverflow',
    enabled: true,
    options: {
      altAxis: true,
      altBoundary: false,
      tether: true,
      tetherOffset: 140,
      rootBoundary: 'document',
      padding: 8,
    },
  },
  {
    name: 'arrow',
    enabled: true,
    options: {
      element: arrowRef,
    },
  },
];
