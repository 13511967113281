import moment from 'moment';

import { actions } from './actions';
import paymentStatusService from './pagamentoStatusService';

const loadStatus = () => async (dispatch) => {
  dispatch(actions.getStatusInit());
  const timeOfRequest = moment();

  try {
    const status = await paymentStatusService.getStatus();

    dispatch(actions.getStatusSuccess(status, timeOfRequest));
  } catch (e) {
    dispatch(actions.getStatusError(timeOfRequest));
  }
};

const requestLoadStatus = () => async (dispatch, getState) => {
  const now = moment();
  const nextLoad = getState().paymentButton.nextLoadTime;

  if (
    moment.isMoment(nextLoad) && nextLoad.isValid()
    && now.isAfter(nextLoad) && !getState().paymentButton.loading
  ) {
    dispatch(loadStatus());
  }
};

export const operations = {
  loadStatus,
  requestLoadStatus,
};
