import { createSelector } from 'reselect';
import _ from 'lodash';
import moment from 'moment';
import { camelFormat, formatDate } from 'utils/format';

const isFundao = createSelector(
  (state) => state.dealers,
  (dealers) => (!!dealers.find((d) => d.isFundao === 1)),
);

const isBrand = createSelector(
  (state) => state.dealers,
  (dealers) => ({
    FIAT: !!dealers.find((d) => d.brand === 'FIAT'),
    CJDR: !!dealers.find((d) => d.brand === 'CHRYSLER' || d.brand === 'JEEP' || d.brand === 'DODGE' || d.brand === 'RAM'),
  }),
);

const isBrandSelected = createSelector(
  (state) => state.carteira.modalTaxas.filters.brand,
  (brand) => (!_.isNull(brand)),
);

const getListMeses = createSelector(
  (state) => state.carteira.modalTaxas.selectors.mes,
  (meses) => (meses ?? []).map((m) => {
    const date = moment().month(m - 1);
    return {
      value: m,
      text: camelFormat(formatDate(date, 'MMMM')),
    };
  }),
);

const getListAnos = createSelector(
  (state) => state.carteira.modalTaxas.selectors.ano,
  (anos) => (anos ?? []).map((a) => ({
    value: a,
    text: `${a}`,
  })),
);

const getListBrands = createSelector(
  isBrand,
  (brands) => {
    const brandResultList = [];

    if (brands.FIAT) {
      brandResultList.push({ text: 'FIAT', value: 2 });
    }
    if (brands.CJDR) {
      brandResultList.push({ text: 'CJDR', value: 3 });
    }

    return brandResultList;
  },
);

const createTaxaObject = (
  header,
  firstRowName,
  secondRowName,
  firstRowValue,
  secondRowValue,
  firstRowPraticada,
  secondRowPraticada,
  showFirstRow,
  showSecondRow,
) => ({
  header,
  firstRowName,
  secondRowName,
  firstRowValue,
  secondRowValue,
  firstRowPraticada,
  secondRowPraticada,
  showFirstRow,
  showSecondRow,
});

const mapTaxaObjects = (t) => ([
  createTaxaObject(
    'NOVOS',
    'FLOOR PLAN',
    'FUNDO',
    t.novoFloorPlan,
    t.novoFundao,
    t.novoFloorPlanPraticada,
    t.novoFundaoPraticada,
    true,
    true,
  ),

  createTaxaObject(
    'USADOS',
    'FLOOR PLAN',
    'FUNDO',
    t.usadosFloorPlan,
    t.usadosFundao,
    t.usadosFloorPlanPraticada,
    t.usadosFundaoPraticada,
    true,
    true,
  ),

  createTaxaObject(
    'PEÇAS',
    null,
    null,
    t.pecas,
    null,
    t.pecasPraticada,
    null,
    true,
    false,
  ),

  createTaxaObject(
    'IDENTIDADE VISUAL',
    null,
    null,
    t.identidadeVisual,
    null,
    t.identidadeVisualPraticada,
    null,
    true,
    false,
  ),

  createTaxaObject(
    'TEST DRIVE',
    null,
    null,
    t.testDrive,
    null,
    t.testDrivePraticada,
    null,
    true,
    false,
  ),
]);

const getTaxas = createSelector(
  (state) => state.carteira.modalTaxas.taxas,
  (taxas) => {
    if (_.isEmpty(taxas) || _.isUndefined(taxas) || _.isNull(taxas)) { return []; }
    return taxas.map((t) => {
      const { inicioVigencia, fimVigencia, ...taxa } = t;
      const { brand } = t;
      return {
        brand,
        inicioVigencia,
        fimVigencia,
        taxas: mapTaxaObjects(taxa),
      };
    });
  },
);

export default {
  isFundao,
  isBrand,
  isBrandSelected,
  getListMeses,
  getListAnos,
  getListBrands,
  getTaxas,
};
