import { actions } from './balanceActions';
import SaldoService from '../service/saldoService';

const getBalanceInfo = () => async (dispatch) => {
  try {
    dispatch(actions.loadSaldoStart());

    const saldoInfo = await SaldoService.getSaldoInfo();

    dispatch(actions.loadSaldoSuccess(saldoInfo));
  } catch (error) {
    dispatch(actions.loadSaldoError());
  }
};

const toggleVisibility = () => (dispatch) => {
  dispatch(actions.toggleVisibility());
};

export const operations = {
  getBalanceInfo,
  toggleVisibility,
};
