import React from 'react';
import PropTypes from 'prop-types';

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import ButtonIcon from '../../../../../../../common-staff/controls/buttonIcon';

const styleForIcon = {
  fontSize: '12px',
  color: '#555770',
  marginRight: '4px',
};

const OrderByButton = ({ order, onClick }) => (
  <ButtonIcon
    onClick={() => onClick(order)}
  >
    {order === 'ASC'
      ? <ArrowUpwardIcon style={styleForIcon} />
      : <ArrowDownwardIcon style={styleForIcon} />}
    DATA
  </ButtonIcon>
);

OrderByButton.propTypes = {
  order: PropTypes.string,
  onClick: PropTypes.func,
};

OrderByButton.defaultProps = {
  order: 'DESC',
  onClick: () => {},
};

export default OrderByButton;
