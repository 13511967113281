import { connect } from 'react-redux';

import PontoSelector from './pontoSelector';
import operations from '../../../redux/operations';
import selector from '../../../redux/selectors';

const mapStateToProps = ({ dashboard, dealerStore }) => ({
  pontos: selector.selectPontosVenda(dealerStore),
  selectedPontos: dashboard.painelFloorPlan.filters.selectedPonto,
});

const mapDispatchToProps = (dispatch) => ({
  setFilter: (pontos) => dispatch(operations.setFilter('selectedPonto', pontos)),
  getFiltroProdutos: (value) => dispatch(operations.getFiltrosProdutos(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PontoSelector);
