import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import MenuSolicitacaoLimite from './menuSolicitacaoLimite';

const mapStateToProps = ({ authStore }) => ({
  permissions: authStore.permissions,
});

const mapDispatchToProps = (dispatch) => ({
  navTo: (path) => { dispatch(push(path)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuSolicitacaoLimite);
