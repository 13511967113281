import React from "react";

const AssignmentLateIcon = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 14" {...props}>
		<path
			d="M10.667 1.667H7.88A2.007 2.007 0 006 .333c-.867 0-1.6.56-1.88 1.334H1.333C.6 1.667 0 2.267 0 3v9.333c0 .734.6 1.334 1.333 1.334h9.334c.733 0 1.333-.6 1.333-1.334V3c0-.733-.6-1.333-1.333-1.333zm-4 10H5.333v-1.334h1.334v1.334zm0-3.334C6.667 8.7 6.367 9 6 9a.669.669 0 01-.667-.667V5.667C5.333 5.3 5.633 5 6 5c.367 0 .667.3.667.667v2.666zM6 3a.669.669 0 01-.667-.667c0-.366.3-.666.667-.666.367 0 .667.3.667.666C6.667 2.7 6.367 3 6 3z"
			fill={props.fill ? `${props.fill}` : "currentColor"}
			fillRule="evenodd"
		/>
	</svg>
);

export default AssignmentLateIcon;

