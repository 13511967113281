import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import Button from 'features/common-staff/controls/button';

const useStyles = makeStyles({
  button: {
    width: '310px',
  },
});

const CancelButton = ({
  onClick,
}) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.button}
      disabled={false}
      isLoading={false}
      onClick={onClick}
      color="darkGray"
      fullWidth
    >
      Cancelar Pedido
    </Button>

  );
};

CancelButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CancelButton;
