import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@material-ui/core';

import {
  DuplicataValue, DuplicataDate, DuplicataDescription, DuplicataIcon,
} from '../../../common';

import './paymentListRow.scss';

class PaymentListRow extends React.Component {
  shouldComponentUpdate(nextProps) {
    const nextActive = nextProps.isActive;
    const nextInvoice = nextProps.invoice;
    const currentProps = this.props;
    const { invoice, isActive } = currentProps;

    if (nextInvoice.invoiceNumber !== invoice.invoiceNumber
        || nextActive !== isActive) {
      return true;
    }

    return false;
  }

  render() {
    const { invoice, isActive, onClick } = this.props;

    return (
      <section
        className={
          `payment-row-section ${isActive ? 'payment-row-activated' : ''}`
        }
        onClick={() => onClick(invoice)}
        role="row"
        tabIndex="0"
      >
        <Checkbox
          className="payment-row-checkbox"
          style={{
            color: '#8F93A1',
          }}
          inputProps={{
            'aria-label': 'uncontrolled-checkbox',
          }}
          color="default"
          checked={isActive}
        />

        <DuplicataIcon
          className="payment-row-icon"
          product={invoice.product}
          status={invoice.status}
        />

        <DuplicataDescription
          className="payment-row-desc"
          product={invoice.product}
          invoiceNumber={invoice.invoiceNumber}
          invoiceDigit={invoice.invoiceDigit}
        />

        <DuplicataDate
          status={invoice.status}
          dueDate={invoice.dueDate}
          paymentDate={invoice.paymentDate}
          className="payment-row-date"
        />
        <DuplicataValue
          status={invoice.status}
          totalValue={invoice.totalValue}
          processingValue={invoice.processingValue}
          remainingValue={invoice.remainingValue}
          className="payment-row-value"
        />
      </section>
    );
  }
}

PaymentListRow.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  invoice: PropTypes.shape({
    invoiceNumber: PropTypes.number,
    invoiceDigit: PropTypes.number,
    paymentDate: PropTypes.string,
    dueDate: PropTypes.string,
    status: PropTypes.oneOf(['canceled', 'duplicated', 'paid', 'opened', 'delayed']).isRequired,
    product: PropTypes.oneOf([
      'BRIDGE', 'ESTOQUE FLOOR PLAN', 'ESTOQUE FUNDAO CDB', 'ESTOQUE FUNDAO SCP', 'FLOOR PLAN USA', 'FUNDAO USA',
      'FUNDAO PEGASO', 'IOF NOVOS FLOOR PLAN', 'IOF NOVOS FLOOR SCP', 'IOF USADOS FLOOR PLAN', 'JUROS',
      'MULTA', 'OUTROS', 'PEÇAS', 'PLANO EMERGENCIAL CDB', 'PLANO EMERGENCIAL FLOOR PLAN', 'PLANO EMERGENCIAL SCP',
      'TEST DRIVE', 'VEICULO VENDIDO FLOOR PLAN', 'VEICULO VENDIDO FUNDAO CDB', 'VEICULO VENDIDO FUNDAO SCP', 'Outros',
    ]).isRequired,
    totalValue: PropTypes.number.isRequired,
    processingValue: PropTypes.number,
    remainingValue: PropTypes.number,
  }).isRequired,
};

PaymentListRow.defaultProps = {
  isActive: false,
  onClick: () => {},
};

export default PaymentListRow;
