import React from 'react';
import PropTypes from 'prop-types';
import TooltipMensagem from '../tooltipMensagem';
import './buttonTooltipIcon.scss';

const ButtonTooltipIcon = ({
  children, title, buttonAction, className,
}) => (
  <TooltipMensagem title={title}>
    <button
      data-cy="ButtonIconTooltip"
      type="button"
      onClick={buttonAction}
      className={className}
    >
      {children}
    </button>
  </TooltipMensagem>
);

ButtonTooltipIcon.propTypes = {
  children: PropTypes.element,
  title: PropTypes.string,
  className: PropTypes.string,
  buttonAction: PropTypes.func,
};

ButtonTooltipIcon.defaultProps = {
  children: {},
  title: '',
  className: '',
  buttonAction: () => {},
};

export default ButtonTooltipIcon;
