export const cardDonutStyles = {
  container: (isHorizontal) => {
    if (isHorizontal) {
      return {
        gridTemplateAreas: "'donut legend'",
        gridTemplateColumns: '80px auto',
        gridGap: '8px',
      };
    }
    return {
      gridTemplateAreas: "'donut legend'",
      gridTemplateColumns: '100%',
    };
  },
  outer: {
    width: '100%',
    height: '100%',
    borderRadius: '4px',
    position: 'relative',
  },
};
