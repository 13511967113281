import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Modal from 'features/common-staff/layout/modal';
import Loading from 'features/common-staff/layout/loading';
import AlertaMensagem from 'features/common/alertaMensagem';
import Error from 'features/common-staff/layout/error';

import Filters from './views/filters';

import Taxas from './views/taxas';
import './modalTaxasFloorPlan.scss';

const renderTables = (isLoading, isError, taxas, isFundao) => {
  if (isLoading) {
    return (
      <div className="modal-taxas__container__tabelas-mensagem">
        <Loading
          mensagem="Estamos buscando as taxas. Aguarde um instante..."
        />
      </div>
    );
  }
  if (isError) {
    return (
      <div className="modal-taxas__container__tabelas-mensagem">
        <Error
          mensagem="Não foi possível encontrar as taxas."
          subMensagem="Tente novamente em alguns instântes."
        />
      </div>
    );
  }
  if (_.isNull(taxas) || _.isEmpty(taxas)) {
    return (
      <div className="modal-taxas__container__tabelas-mensagem">
        <AlertaMensagem
          mensagem="Não há taxas disponíveis no período selecionado."
          subMensagem="Selecione um novo período e tente novamente."
        />
      </div>
    );
  }
  return taxas.map((t, i) => (
    <Taxas
      key={i}
      taxa={t}
      isFundao={isFundao}
    />
  ));
};

const ModalTaxasFloorPlan = ({
  closeModal, resetStore, isFundao,
  isLoading, isError, taxas, getTaxa,
}) => {
  useEffect(
    () => {
      getTaxa();
      return () => resetStore();
    },
    [resetStore, getTaxa],
  );

  return (
    <Modal
      width="75%"
      height="fit-content"
      closeModal={closeModal}
    >
      <div className="modal-taxas__container">
        <div className="modal-taxas__container__title">
          Taxas
        </div>

        <Filters />

        <div className="modal-taxas__container__tabelas">
          {renderTables(isLoading, isError, taxas, isFundao)}
        </div>
        {!isLoading && !isError && !_.isEmpty(taxas) && !_.isNull(taxas) && !_.isUndefined(taxas)
          ? (
            <div className="modal-taxas__container__legenda">
              <small className="modal-taxas__container__legenda-titulo">
                Legenda:
              </small>
              <small className="modal-taxas__container__legenda-valores">
                a.d.: taxas ao dia | a.m.: taxas ao mês
              </small>
            </div>
          )
          : null}
      </div>
    </Modal>
  );
};

ModalTaxasFloorPlan.propTypes = {
  closeModal: PropTypes.func,
  resetStore: PropTypes.func,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  taxas: PropTypes.array,
  getTaxa: PropTypes.func,
  isFundao: PropTypes.bool,
};

ModalTaxasFloorPlan.defaultProps = {
  closeModal: () => {},
  resetStore: () => {},
  isLoading: false,
  isError: false,
  taxas: [],
  isFundao: false,
  getTaxa: () => {},
};

export default ModalTaxasFloorPlan;
