import { actions } from './actions';
import Tracking from '../../../modules/tracking';

const requestOpenContent = (content) => (dispatch) => {
  dispatch(actions.requestOpenContent(content));
};

const fulfillOpenContentRequest = () => (dispatch) => {
  dispatch(actions.fulfillOpenContentRequest());
};

const setIsOpen = (isOpen) => (dispatch) => {
  dispatch(actions.setIsOpen(isOpen));

  if (isOpen) {
    Tracking.Mixpanel.feedback.popover.trackOpen();
  }
};

const setContent = (content) => (dispatch) => {
  dispatch(actions.setContent(content));
};

export const operations = {
  requestOpenContent,
  fulfillOpenContentRequest,
  setIsOpen,
  setContent,
};
