import { connect } from 'react-redux';

import SelectConcessionaria from './selectConcessionaria';
import * as limiteCreditoOperations from '../../../redux/operations';

const mapStateToProps = ({ dealerStore, limiteCreditoStore }) => ({
  concessionarias: dealerStore.dealers,
  selectedDealerId: limiteCreditoStore.alteracaoLimite.dados.selectedDealerId,
});

const mapDispatchToProps = (dispatch) => ({
  setConcessionariaSelecionada: (concessionaria) => dispatch(
    limiteCreditoOperations.setSelectedDealer(concessionaria),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectConcessionaria);
