import React from 'react';
import PropTypes from 'prop-types';

import HeaderHistorico from './headerHistorico';
import RowHistorico from './rowHistorico';

import './tabelaHistorico.scss';

const TabelaHistorico = ({ historicos }) => (
  <div className="tabela-historico__container">
    <HeaderHistorico />
    <div className="tabela-historico__container__rows">
      {historicos.map((h) => (
        <RowHistorico
          key={h.id}
          historico={h}
        />
      ))}
    </div>
  </div>
);

TabelaHistorico.propTypes = {
  historicos: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TabelaHistorico;
