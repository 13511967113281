import { connect } from 'react-redux';

import ChartValorDuplicatas from './chartValorDuplicatas';
import selector from '../../../redux/selectors';

const mapStateToProps = ({ dashboard }) => ({
  data: selector.selectorDuplicatasValorData(dashboard),
});

export default connect(mapStateToProps)(ChartValorDuplicatas);
