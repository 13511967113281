import React from "react";

const DepartureBoardIcon = props => (
	<svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
		<path
			d="M11.561.752a4.671 4.671 0 00-4.747 1.947c.007-.007.007-.014.014-.02-.267-.014-.547-.014-.827-.014C3.054 2.665.668 3 .668 5.332v6.667c0 .586.26 1.113.666 1.48v.853c0 .553.447 1 1 1 .554 0 1-.447 1-1v-.333h5.334v.333c0 .547.446 1 1 1 .546 0 1-.447 1-1v-.853a1.99 1.99 0 00.666-1.48V9.945a4.664 4.664 0 003.947-5.313c-.28-1.933-1.8-3.527-3.72-3.88zm-8.56 11.913c-.553 0-1-.446-1-1 0-.553.447-1 1-1 .553 0 1 .447 1 1 0 .554-.447 1-1 1zm-1-4V5.332h4c0 1.307.54 2.487 1.407 3.333H2zm7 4c-.553 0-1-.446-1-1 0-.553.447-1 1-1 .553 0 1 .447 1 1 0 .554-.447 1-1 1zm1.667-4a3.335 3.335 0 010-6.666 3.335 3.335 0 010 6.666zm-.167-6c-.273 0-.5.227-.5.5V5.62c0 .233.127.453.327.573l1.68 1.007c.226.133.52.06.653-.16a.474.474 0 00-.167-.66l-1.493-.88V3.165c0-.273-.227-.5-.5-.5z"
			fill={props.fill ? `${props.fill}` : "currentColor"}
			fillRule="evenodd"
		/>
	</svg>
);

export default DepartureBoardIcon;

