import React from 'react';
import PropTypes from 'prop-types';

import Avatar from '@material-ui/core/Avatar';

import { formatDate, camelFormat, formatCurrency } from 'utils/format';

import './resumoCard.scss';

const ResumoCard = ({
  dealerId, resumo,
}) => (
  <div className="comprovante-page__resumo-card">
    <div className="comprovante-page__resumo-card__header">
      Resumo da solicitação de pagamento
      <div className="comprovante-page__resumo-card__header__info">
        <div className="comprovante-page__resumo-card__row">
          <div className="comprovante-page__resumo-card__prop">
            Concessionária Pagante:
          </div>
          <div className="comprovante-page__resumo-card__value">
            {dealerId}
          </div>
        </div>
        <div className="comprovante-page__resumo-card__row">
          <div className="comprovante-page__resumo-card__prop">
            Duplicatas a serem pagas:
          </div>
          <div className="comprovante-page__resumo-card__value">
            {`${resumo?.quantidadeDuplicatasPagas} ${resumo?.quantidadeDuplicatasPagas > 1 ? 'duplicatas' : 'duplicata'}`}
          </div>
        </div>
        <div className="comprovante-page__resumo-card__row">
          <div className="comprovante-page__resumo-card__prop">
            Data do pagamento:
          </div>
          <div className="comprovante-page__resumo-card__value">
            {formatDate(new Date(), 'DD MMM YYYY')}
          </div>
        </div>
        <div className="comprovante-page__resumo-card__row">
          <div className="comprovante-page__resumo-card__prop">
            ID. do pagamento:
          </div>
          <div className="comprovante-page__resumo-card__value">
            {`#${resumo?.idTransacao}`}
          </div>
        </div>
        <div className="comprovante-page__resumo-card__row">
          <div className="comprovante-page__resumo-card__prop">
            Valor do pagamento solicitado:
          </div>
          <div className="comprovante-page__resumo-card__value">
            {formatCurrency(resumo?.valorPagamento)}
          </div>
        </div>
      </div>
    </div>
    { resumo?.nomeUsuario ? (
      <div className="comprovante-page__resumo-card__user">
        <div className="comprovante-page__resumo-card__avatar">
          <Avatar style={{ backgroundColor: '#0063F7' }}>
            {resumo?.nomeUsuario ? `${resumo?.nomeUsuario.trim().split(' ')[0].substring(0, 1)
              .toUpperCase()}${resumo?.nomeUsuario.trim().split(' ').at(-1).substring(0, 1)
              .toUpperCase()}` : null}
          </Avatar>
        </div>
        <div className="comprovante-page__resumo-card__user-name">
          {resumo?.nomeUsuario ? camelFormat(resumo.nomeUsuario.trim()) : null}
          <span>{resumo.email ? resumo.email.toLowerCase().trim() : null}</span>
        </div>
      </div>
    ) : null}
    <div className="comprovante-page__resumo-card__info-dealers">
      <div className="comprovante-page__resumo-card__row">
        <div className="comprovante-page__resumo-card__prop">
          Pgto. para mesma concessionária:
        </div>
        <div className="comprovante-page__resumo-card__value">
          {formatCurrency(resumo.valorMesmaConcessionaria)}
        </div>
      </div>
      <div className="comprovante-page__resumo-card__row">
        <div className="comprovante-page__resumo-card__prop">
          Pgto. para outras concessionárias:
        </div>
        <div className="comprovante-page__resumo-card__value">
          {formatCurrency(resumo.valorDestinoDiferenteConcessionaria)}
        </div>
      </div>
    </div>
    <div className="comprovante-page__resumo-card__footer">
      <div className="comprovante-page__resumo-card__row">
        <div className="comprovante-page__resumo-card__prop">
          Pagamentos em processamento:
        </div>
        <div className="comprovante-page__resumo-card__value">
          {formatCurrency(resumo.valorProcessamento)}
        </div>
      </div>
      <div className="comprovante-page__resumo-card__row">
        <div className="comprovante-page__resumo-card__prop">
          Pagamentos rejeitados:
        </div>
        <div className="comprovante-page__resumo-card__value">
          {formatCurrency(resumo.valorRejeitado)}
        </div>
      </div>
      <div className="comprovante-page__resumo-card__row">
        <div className="comprovante-page__resumo-card__prop">
          Pagamentos Aprovados:
        </div>
        <div
          className="comprovante-page__resumo-card__value total positivo"
        >
          {formatCurrency(resumo.valorAprovado)}
        </div>
      </div>
    </div>
  </div>

);

ResumoCard.propTypes = {
  dealerId: PropTypes.number,
  resumo: PropTypes.object,
};

ResumoCard.defaultProps = {
  dealerId: null,
  resumo: {},
};

export default ResumoCard;
