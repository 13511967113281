import React from 'react';
import PropTypes from 'prop-types';

import {
  Row, Label, Value,
} from './cardRow.style';

const CardRow = ({ label, value }) => (
  <Row>
    <Label>
      {label}
      :
    </Label>
    <Value data-cy="ValueCardRowDuplicata">{value}</Value>
  </Row>
);

CardRow.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
};

CardRow.defaultProps = {
  label: '',
  value: null,
};

export default CardRow;
