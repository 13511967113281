import React from 'react';
import PropTypes from 'prop-types';

import ErrorContent from './errorContent';

class PageErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? <ErrorContent /> : children;
  }
}

PageErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageErrorBoundary;
