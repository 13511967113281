import styled from 'styled-components';
import colors from 'assets/styles/colors';

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  height: 24px;
  font-size: 12px;
  line-height: 16px;
`;

export const Label = styled.div`
  color: ${(props) => (props.highlight ? '#00AFAD' : '#555770')};
  font-weight: ${(props) => (props.highlight ? 500 : 450)};
  // TODO: Descomentar quando subir Status
  /* color: ${colors.secundary_color_700};
  font-weight: 450; */
`;

export const Value = styled.span`
  color: ${(props) => (props.highlight ? '#404154' : '#8F9BB3')};
  font-weight: ${(props) => (props.highlight ? 500 : 450)}; 
  // TODO: Descomentar quando subir Status
  // color: ${colors.secundary_color_800};
  // font-weight: 450;
`;
