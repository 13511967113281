import { connect } from 'react-redux';

import AcompanhamentoPage from './acompanhamentoPage';

import operations from './redux/operations';

const mapStateToProps = ({ limiteCreditoStore }) => ({
  isOpen: limiteCreditoStore.acompanhamento.detalhes.isOpen,
});

const mapDispatchToProps = (dispatch) => ({
  getSolicitacoes: () => dispatch(operations.getSolicitacoes()),
  resetStore: () => { dispatch(operations.resetStore()); },
  setOpen: () => { dispatch(operations.setOpen(false)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(AcompanhamentoPage);
