import React from 'react';

import './comprovanteListHeader.scss';

const ComprovanteListHeader = () => (
  <div className="comprovante-page__list-header__container">
    <div
      className="comprovante-page__list-header__container__item comprovante-page__list-header__container__identificador"
    >
      IDENTIFICADOR
    </div>
    <div className="comprovante-page__list-header__container__item comprovante-page__list-header__container__origem">
      DOC. ORIGEM
    </div>
    <div
      className="comprovante-page__list-header__container__item comprovante-page__list-header__container__destino"
    >
      DOC. DESTINO
    </div>
    <div
      className="comprovante-page__list-header__container__item comprovante-page__list-header__container__pagamento"
    >
      VALOR PGTO.
    </div>
    <div className="comprovante-page__list-header__container__item comprovante-page__list-header__container__icon" />
  </div>
);

export default ComprovanteListHeader;
