import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import WarningSvg from 'features/common-staff/_assets/icons/warning-svg';
import MensagemCardIcon from './mensagemCardIcon';

import './mensagemCard.scss';

const MensagemCard = ({
  card,
}) => (
  <div className="mensagem-rede__card__content">
    <div className="mensagem-rede__card__content_icon">
      {card?.urlImagem != null
        ? (<MensagemCardIcon urlImagem={card?.urlImagem} />)
        : <WarningSvg width={42} height={42} color="#00AFAD" />}
    </div>
    <div className="mensagem-rede__card__content_title">
      {card?.titulo}
    </div>
    <div className="mensagem-rede__card__content_content">
      {parse(card?.mensagem)}
    </div>
  </div>

);

MensagemCard.propTypes = {
  card: PropTypes.object,
};

MensagemCard.defaultProps = {
  card: [],
};

export default MensagemCard;
