import React from 'react';
import PropTypes from 'prop-types';

import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { makeStyles } from '@material-ui/styles';
import colors from 'assets/styles/colors';

const useStyles = makeStyles({
  container: {
    height: '40px',
    background: colors.alert_color_200_08,
    border: `1px solid ${colors.alert_color_300}`,
    borderRadius: '4px',
    color: colors.secundary_color_800,
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    margin: '-10px 48px 15px 48px',
  },
  text: {
    marginLeft: '10px',
    marginRight: '10px',
  },
  icon: {
    marginLeft: '10px',
    color: colors.alert_color_300,
  },
});

const Warning = ({ selectedDealer }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <WarningRoundedIcon
        width="24px"
        height="24px"
        className={classes.icon}
      />
      <div className={classes.text}>
        As duplicatas exibidas abaixo correspondem apenas
        às do grupo da concessionária
        {' '}
        {selectedDealer}
        .
        <br />
        Para visualizar duplicatas de outras concessionárias do mesmo grupo,
        utilize o filtro de concessionárias acima.
      </div>
    </div>
  );
};

Warning.propTypes = {
  selectedDealer: PropTypes.number,
};

Warning.defaultProps = {
  selectedDealer: null,
};

export default Warning;
