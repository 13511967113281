import { connect } from 'react-redux';

import ComunicadosPage from './comunicadosPage';
import operations from './redux/operations';

const mapStateToProps = ({ comunicados }) => ({
  snackbarErrors: comunicados.page.snackbarErrors,
});

const mapDispatchToProps = (dispatch) => ({
  resetStore: () => dispatch(operations.resetStore()),
  getDve: () => dispatch(operations.getDve()),
  setFilterBrand: () => dispatch(operations.setSelectorBrand()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComunicadosPage);
