import React from 'react';
import PropTypes from 'prop-types';
import { Tracking } from 'modules';

import Select from '../../../../../../../common/select';

const defaultConcessionaria = [
  { text: 'Todas concessionárias', value: 'all' },
];

const ConcessionariaSelect = ({
  concessionaria, setConcessionaria, concessionariaList, isLoading,
}) => (
  <Select
    items={defaultConcessionaria.concat(concessionariaList)}
    label="Concessionária"
    value={concessionaria || 'all'}
    onSelect={(r) => {
      Tracking.Mixpanel.carteira.carteiraFilters.trackTypeDealerFilter(r);
      setConcessionaria(r === 'all' ? null : r);
    }}
    disabled={isLoading}
  />
);

ConcessionariaSelect.propTypes = {
  concessionaria: PropTypes.number,
  setConcessionaria: PropTypes.func,
  concessionariaList: PropTypes.array,
  isLoading: PropTypes.bool,
};

ConcessionariaSelect.defaultProps = {
  concessionaria: null,
  setConcessionaria: () => {},
  concessionariaList: null,
  isLoading: false,
};

export default ConcessionariaSelect;
