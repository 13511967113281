import React from 'react';
import PropTypes from 'prop-types';

import NewMultipleSelectComponent from 'features/common-staff/controls/newMultipleSelectComponent/newMultipleSelectComponent';
import { Tracking } from 'modules';

const dictionary = () => ({
  singular: 'status',
  plural: 'status',
  type: 'o',
});

const StatusFilter = ({
  selectedStatus, setStatus, status, isLoading,
}) => (
  <div className="pedidos-acompanhamento__select-status__container">
    <NewMultipleSelectComponent
      options={status}
      setOption={(s) => {
        Tracking.Mixpanel.acompanhamentoTd.trackFiltroStatus(s);
        setStatus(s);
      }}
      startWithAllSelected
      selectedOption={selectedStatus}
      label="Status"
      disabled={isLoading}
      dictionary={dictionary()}
      dataCy="status"
      minWidth={200}
    />
  </div>
);

StatusFilter.propTypes = {
  selectedStatus: PropTypes.array,
  setStatus: PropTypes.func,
  status: PropTypes.array,
  isLoading: PropTypes.bool,
};

StatusFilter.defaultProps = {
  selectedStatus: [],
  setStatus: () => {},
  status: null,
  isLoading: false,
};

export default StatusFilter;
