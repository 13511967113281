import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'features/common/checkbox/checkbox';
import './relatorioListHeader.scss';
import colors from 'assets/styles/colors';

const RelatorioListHeader = ({
  onCheck, selectedAll, isLoading, isError,
}) => (
  <div
    className="relatorios-page__list-header__container "
    data-cy="RelatoriosHeader"
  >
    <div className="relatorios-page__list-header__item relatorios-page__list-header__checkbox">
      <Checkbox
        onChange={() => onCheck(selectedAll)}
        checked={selectedAll}
        disabled={isLoading || isError}
        color={colors.text_midnight_500}
        checkedColor={colors.text_midnight_500}
      />
    </div>
    <div
      className="relatorios-page__list-header__item relatorios-page__list-header__concessionaria "
    >
      CONCESSIONÁRIA
    </div>
    <div className="relatorios-page__list-header__item relatorios-page__list-header__tipo-documento">
      TIPO DE ARQUIVO
    </div>

    <div className="relatorios-page__list-header__item relatorios-page__list-header__nome-documento">
      NOME DO DOCUMENTO
    </div>
    <div
      className="relatorios-page__list-header__item relatorios-page__list-header__data"
    >
      DATA
    </div>
  </div>
);

RelatorioListHeader.propTypes = {
  onCheck: PropTypes.func,
  selectedAll: PropTypes.bool,

  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
};

RelatorioListHeader.defaultProps = {
  onCheck: () => {},
  selectedAll: false,

  isLoading: false,
  isError: false,
};

export default RelatorioListHeader;
