import { connect } from 'react-redux';
import { AuthSelectors } from '../../../../modules/auth/redux';

import RenderIfAuthorized from './renderIfAuthorized';

const mapStateToProps = ({ authStore }) => ({
  authorizations: AuthSelectors.getAuthorizations(authStore),
});

export default connect(mapStateToProps)(RenderIfAuthorized);
