import { actions } from './actions';
import { content } from './content';

const INITIAL_STATE = {
  isOpen: false,
  content: content.main,

  openContentRequest: {
    pending: false,
    content: null,
  },
};

const reducer = (state = INITIAL_STATE, action = { type: 'default' }) => {
  switch (action.type) {
    case actions.types.SET_IS_OPEN:
      return {
        ...state,
        isOpen: action.payload.isOpen,
      };
    case actions.types.SET_CONTENT:
      return {
        ...state,
        content: action.payload.content,
      };
    case actions.types.REQUEST_OPEN_CONTENT:
      return {
        ...state,
        openContentRequest: {
          pending: true,
          content: action.payload.content,
        },
      };
    case actions.types.FULFILL_OPEN_CONTENT_REQUEST:
      if (state.openContentRequest.pending) {
        return {
          ...state,
          isOpen: true,
          content: state.openContentRequest.content,
          openContentRequest: {
            pending: false,
            content: null,
          },
        };
      }

      return state;
    default:
      return state;
  }
};

export default reducer;
