import * as React from "react";

function BalanceIcon(props) {
	return (
		<svg width="1em" height="1em" viewBox="0 0 9 16" {...props}>
			<defs>
				<path
					d="M10.25 9.083c-1.892-.491-2.5-1-2.5-1.791 0-.909.842-1.542 2.25-1.542 1.183 0 1.775.45 1.992 1.167.1.333.375.583.725.583h.25a.79.79 0 00.75-1.058c-.35-.984-1.167-1.8-2.467-2.117V3.75c0-.692-.558-1.25-1.25-1.25s-1.25.558-1.25 1.25v.55c-1.617.35-2.917 1.4-2.917 3.008 0 1.925 1.592 2.884 3.917 3.442 2.083.5 2.5 1.233 2.5 2.008 0 .575-.408 1.492-2.25 1.492-1.375 0-2.083-.492-2.358-1.192-.125-.325-.409-.558-.75-.558h-.234a.795.795 0 00-.741 1.083c.475 1.159 1.583 1.842 2.833 2.109v.558c0 .692.558 1.25 1.25 1.25s1.25-.558 1.25-1.25v-.542c1.625-.308 2.917-1.25 2.917-2.958 0-2.367-2.025-3.175-3.917-3.667z"
					id="recurs_svg__a"
				/>
			</defs>
			<use
				fill="#FFF"
				xlinkHref="#recurs_svg__a"
				transform="translate(-5.5 -2)"
				fillRule="evenodd"
			/>
		</svg>
	);
}

export default BalanceIcon;

