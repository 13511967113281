import { connect } from 'react-redux';
import * as limiteCreditoOperations from '../../../redux/operations';
import selectors from '../../../redux/selectors';

import InputSap from './inputSap';

const mapStateToProps = ({ limiteCreditoStore }) => ({
  codigoSap: limiteCreditoStore.alteracaoLimite.dados.codigoSap,
  error: selectors.isValidSap(limiteCreditoStore),
});

const mapDispatchToProps = (dispatch) => ({
  setCodigoSap: (valor) => dispatch(
    limiteCreditoOperations.setCodigoSap(valor),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(InputSap);
