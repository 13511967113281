const RESET_STORE = 'feedback/user/RESET_STORE';
const PUSH_ANSWER = 'feedback/user/PUSH_ANSWER';
const PUSH_MESSAGES = 'feedback/user/PUSH_MESSAGES';
const SET_CHAT_STATE = 'feedback/user/SET_STATE';

const SEND_ANSWER_BEGIN = 'feedback/user/SEND_ANSWER_BEGIN';
const SEND_ANSWER_SUCCESS = 'feedback/user/SEND_ANSWER_SUCCESS';
const SEND_ANSWER_ERROR = 'feedback/user/SEND_ANSWER_ERROR';

const pushAnswer = (answer) => ({
  type: PUSH_ANSWER,
  payload: { answer },
});

const pushMessages = (messages) => ({
  type: PUSH_MESSAGES,
  payload: { messages },
});

const setChatState = (chatState) => ({
  type: SET_CHAT_STATE,
  payload: { chatState },
});

const resetStore = () => ({
  type: RESET_STORE,
  payload: null,
});

const sendAnswerBegin = () => ({
  type: SEND_ANSWER_BEGIN,
});

const sendAnswerSuccess = () => ({
  type: SEND_ANSWER_SUCCESS,
});

const sendAnswerError = () => ({
  type: SEND_ANSWER_ERROR,
});

export const actions = {
  types: {
    RESET_STORE,
    PUSH_ANSWER,
    PUSH_MESSAGES,
    SET_CHAT_STATE,
    SEND_ANSWER_BEGIN,
    SEND_ANSWER_SUCCESS,
    SEND_ANSWER_ERROR,
  },

  setChatState,
  pushAnswer,
  pushMessages,
  resetStore,

  sendAnswerBegin,
  sendAnswerSuccess,
  sendAnswerError,
};
