import { connect } from 'react-redux';

import CancelButton from './cancelButton';

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CancelButton);
