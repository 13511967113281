export const Status = {
  paid: 'Liquidada',
  delayed: 'Em Atraso',
  open: 'Em Aberto',
  canceled: 'Cancelada',
};

export const Sistemas = {
  pla: 'PLA',
  chr: 'CHR',
  usa: 'USA',
};
