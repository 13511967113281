import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popper } from '@mui/material';
import { Tracking } from 'modules';
import {
  PopoverContent, PopperModifiers, PopoverArrow, TitleRow, TextRow, Title, Text,
  ActionRow, CloseButton, CloseIcon, CancelButton, ExportButton,
} from './exportModal.style';

const ExportModal = ({ anchor, togglePopper, downloadFile }) => {
  const [arrowRef, setArrowRef] = useState(null);

  return (
    <Popper
      placement="bottom-start"
      open={Boolean(anchor)}
      anchorEl={anchor}
      onClose={(event) => togglePopper(event)}
      modifiers={PopperModifiers(arrowRef)}
      data-cy="PoppoverModalExportarCarteira"
    >
      <PopoverContent>
        <PopoverArrow ref={setArrowRef} />
        <TitleRow>
          <Title data-cy="TituloModalExportarCarteira">
            Exportar Relatório de Duplicatas
          </Title>
          <CloseButton onClick={(e) => togglePopper(e)}>
            <CloseIcon />
          </CloseButton>
        </TitleRow>
        <TextRow>
          <Text data-cy="TextoModalExportarCarteira">
            Ao exportar sua carteira, serão exibidas as duplicatas em aberto de acordo
            com os filtros selecionados. É possível personalizar de acordo com a data
            de vencimento, emissão, tipo de duplicata, etc. Para exportar a carteira
            completa, lembre-se de remover os filtros caso tenha algum aplicado.
          </Text>
        </TextRow>
        <ActionRow>
          <CancelButton
            white
            onClick={(e) => {
              togglePopper(e);
              Tracking.Mixpanel.fidc.trackButtons('fidc_duplicatas_botao_exportar_carteira_cancelar');
            }}
            dataCy="BotaoCancelarModalExportarCarteira"
          >
            Cancelar
          </CancelButton>
          <ExportButton
            onClick={() => {
              downloadFile(true);
              Tracking.Mixpanel.fidc.trackButtons('fidc_duplicatas_botao_exportar_carteira_csv');
            }}
            dataCy="BotaoCSVModalExportarCarteira"
          >
            Exportar CSV
          </ExportButton>
          <ExportButton
            onClick={() => {
              downloadFile(false);
              Tracking.Mixpanel.fidc.trackButtons('fidc_duplicatas_botao_exportar_carteira_xlsx');
            }}
            dataCy="BotaoXLSXModalExportarCarteira"
          >
            Exportar XLSX
          </ExportButton>
        </ActionRow>
      </PopoverContent>
    </Popper>
  );
};

ExportModal.propTypes = {
  anchor: PropTypes.object,
  togglePopper: PropTypes.func,
  downloadFile: PropTypes.func,
};

ExportModal.defaultProps = {
  anchor: null,
  togglePopper: () => {},
  downloadFile: () => {},
};

export default ExportModal;
