import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import ImageCard from 'features/common-staff/views/imageCard';
import Button from 'features/common/button';

import './productCards.scss';

import { Tracking } from 'modules';

const CardContent = ({
  product, selectCategory,
}) => (
  <div
    className="product-card__container"
    data-cy="ProductCardContainer"
  >
    <div className="product-card__title">
      {product.titulo}
    </div>
    <div className="product-card__text">
      {product.descricao}
    </div>
    <div className="product-card__button">
      <Button onClick={() => {
        Tracking.Mixpanel.testDrive.trackProductSelected(product.titulo);
        selectCategory(product.id);
      }}
      >
        {`Solicitar ${product.titulo || 'Produto'}`}
      </Button>
    </div>
  </div>
);

CardContent.propTypes = {
  selectCategory: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'wrap',
    width: '100%',
    justifyContent: 'center',
  },
});

const ProductCards = ({
  products, selectCategory,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {(products || []).filter((p) => p.exibirPrincipal).map((product, index) => (
        <ImageCard
          product={product}
          src={product.imagem}
          alt={product.titulo}
          key={index}
        >
          <CardContent product={product} selectCategory={selectCategory} />
        </ImageCard>
      ))}
    </div>
  );
};

ProductCards.propTypes = {
  products: PropTypes.array,
  selectCategory: PropTypes.func.isRequired,
};
ProductCards.defaultProps = {
  products: null,
};

export default ProductCards;
