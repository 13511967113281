import React from 'react';
import PropTypes from 'prop-types';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
} from 'recharts';
import { formatCurrency } from 'utils/format';
import colors from 'assets/styles/colors';

const axisStyles = {
  fontSize: '10px',
  lineHeigth: '16px',
};

const labelStyles = {
  fontSize: '10px',
  lineHeigth: '12px',
};

const ChartCarteiraProduto = ({ data }) => (
  <ResponsiveContainer
    width="100%"
    height={500}
  >
    <BarChart
      data={data}
      margin={{
        top: 20,
        right: 60,
        left: 20,
        bottom: 5,
      }}
      layout="vertical"
    >
      <CartesianGrid vertical={false} />
      <XAxis type="number" style={axisStyles} stroke="#7A7C9A" />
      <YAxis type="category" dataKey="produto" style={axisStyles} stroke="#7A7C9A" />
      <Bar dataKey="saldoDevedorNaoVencido" barSize={8} fill={colors.secundary_color_600}>
        <LabelList position="right" formatter={formatCurrency} style={labelStyles} fill="#555770" />
      </Bar>
      <Bar dataKey="saldoDevedorVencido" barSize={8} fill={colors.error_color_200}>
        <LabelList position="right" formatter={formatCurrency} style={labelStyles} fill="#555770" />
      </Bar>
    </BarChart>
  </ResponsiveContainer>
);

ChartCarteiraProduto.propTypes = {
  data: PropTypes.object,
};

ChartCarteiraProduto.defaultProps = {
  data: null,
};

export default ChartCarteiraProduto;
