import { connect } from 'react-redux';

import Filters from './filters';

import selector from '../../redux/selectors';
import selectorModal from '../../../carteiraModal/redux/selector';

const mapStateToProps = ({ gestaoPagamentos }) => ({
  selectedFilter: selector.selectedFilter(gestaoPagamentos),
  infoModalOpen: gestaoPagamentos.carteira.modal.modalInfo.isOpen,
  isModalOpen: selectorModal.isModalPaymentOpen(gestaoPagamentos),
});

export default connect(mapStateToProps, null)(Filters);
