import React from 'react';
import PropTypes from 'prop-types';
import CardDetalheDuplicata from './cardDetalheDuplicata/cardDetalheDuplicata';
import {
  Container, Header, TipoDuplicata, CardIcon, DuplicataIcon,
} from './detalheDuplicata.style';
import { getDuplicataAssets } from '../../../helpers/duplicata';

const DetalheDuplicata = ({ duplicata, isOpen, closeDrawer }) => {
  const data = getDuplicataAssets(duplicata, true);

  return (
    <Container data-cy="DuplicataDrawer">
      <Header>
        <TipoDuplicata>{data.title}</TipoDuplicata>
      </Header>
      <CardIcon>
        <DuplicataIcon background={data.background}>
          {data.icon}
        </DuplicataIcon>
      </CardIcon>
      <CardDetalheDuplicata duplicata={duplicata} isOpen={isOpen} closeDrawer={closeDrawer} />
    </Container>
  );
};

DetalheDuplicata.propTypes = {
  duplicata: PropTypes.object,
  isOpen: PropTypes.bool,
  closeDrawer: PropTypes.func,
};

DetalheDuplicata.defaultProps = {
  duplicata: {
    numeroDuplicata: '',
    valorPrincipal: 0,
    juros: 0,
    multa: 0,
    mora: 0,
    saldoAtualizado: 0,
    dataVencimentoProrrogada: null,
    chassi: '',
    dataVenda: null,
    entradaFidc: null,
  },
  isOpen: false,
  closeDrawer: () => {},
};

export default DetalheDuplicata;
