import React from 'react';
import PropTypes from 'prop-types';

import BaseArea from '../baseArea';
import LegendaAbsolutoDuplicatas from './legendaAbsolutoDuplicatas';
import ChartAbsolutosDuplicatas from './chartAbsolutosDuplicatas';
import Table from './table';

const AbsolutosDuplicatasArea = ({
  isEmpty, isLoading, isError, className,
}) => (
  <BaseArea
    isEmpty={isEmpty}
    isLoading={isLoading}
    isError={isError}
    title="Duplicatas vencidas e a vencer"
    subtitle="Total"
    className={className}
    legenda={<LegendaAbsolutoDuplicatas />}
  >
    <>
      <ChartAbsolutosDuplicatas />
      <Table />
    </>
  </BaseArea>
);

AbsolutosDuplicatasArea.propTypes = {
  isLoading: PropTypes.bool,
  isEmpty: PropTypes.bool,
  isError: PropTypes.bool,
  className: PropTypes.string,
};

AbsolutosDuplicatasArea.defaultProps = {
  isLoading: false,
  isEmpty: false,
  isError: false,
  className: '',
};

export default AbsolutosDuplicatasArea;
