import React from 'react';
import PropTypes from 'prop-types';

import { Currency } from '../../../common';
import { AlertIcon } from '../../../../assets/icons';

import './invoicesTotalValue.scss';

const InvoicesTotalValue = ({
  t, className, totalValue, remainingBalance, loading,
}) => {
  const lacksBalance = (totalValue > remainingBalance);

  return (
    <div className={className}>
      <div className="invoices-total-upper-part">
        <span className="invoices-total-desc">
          {`${t('Valor total')}: `}
        </span>
        <Currency
          className="invoices-total-value"
          value={totalValue}
          noColor
        />
      </div>
      {
        (lacksBalance && !loading) ? (
          <div className="invoices-total-no-balance">
            <AlertIcon id="invoice-total-alert-icn" />
            {t('Saldo insuficiente')}
          </div>
        ) : null
      }
    </div>
  );
};

InvoicesTotalValue.propTypes = {
  className: PropTypes.string,
  totalValue: PropTypes.number.isRequired,
  remainingBalance: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  t: PropTypes.func,
};

InvoicesTotalValue.defaultProps = {
  className: '',
  t: (i) => (i),
};

export default InvoicesTotalValue;
