import React from 'react';
import PropTypes from 'prop-types';
import { Tracking } from 'modules';

import { formatCurrency } from 'utils/format';
import Select from '../../../../../../../common/select';

const rangers = [
  { text: 'Todos os valores', value: [null, null] },
  { text: `Até ${formatCurrency(100000)}`, value: [0, 100000] },
  { text: `${formatCurrency(100000)} á ${formatCurrency(200000)}`, value: [100000, 200000] },
  { text: `${formatCurrency(200000)} á ${formatCurrency(300000)}`, value: [200000, 300000] },
  { text: `${formatCurrency(300000)} á ${formatCurrency(400000)}`, value: [300000, 400000] },
  { text: `${formatCurrency(400000)} á ${formatCurrency(500000)}`, value: [400000, 500000] },
  { text: `Maior que ${formatCurrency(500000)}`, value: [500000, null] },
];

const ValorRangeSelect = ({
  valor, setValor, isLoading,
}) => (
  <Select
    items={rangers}
    label="Range de preço"
    value={valor}
    onSelect={(r) => {
      Tracking.Mixpanel.carteira.carteiraFilters.trackTypePriceRangeFilter(r);
      setValor(r === 'all' ? null : r);
    }}
    disabled={isLoading}
  />
);

ValorRangeSelect.propTypes = {
  valor: PropTypes.string,
  setValor: PropTypes.func,
  isLoading: PropTypes.bool,
};

ValorRangeSelect.defaultProps = {
  valor: null,
  setValor: () => {},
  isLoading: false,
};

export default ValorRangeSelect;
