import actions from './actions';
import HistoricoService from './service';

const setOpenModal = () => async (dispatch) => {
  dispatch(actions.setModalOpen());
};

const resetStore = () => async (dispatch) => {
  dispatch(actions.resetStore());
};

const getHistorico = () => async (dispatch, getState) => {
  try {
    dispatch(actions.getHistoricoStart());
    const {
      dealerId,
      duplicataNumero,
    } = getState().gestaoPagamentos.carteira.modal.modalInfo.data;
    const response = await HistoricoService.getHistorico(duplicataNumero, dealerId);
    const data = response.map((m) => ({
      ...m,
      dataProcessamento: new Date(m.dataProcessamento),
      dataPagamento: new Date(m.dataPagamento),
      dataCriacao: new Date(m.dataCriacao),
      updateDate: new Date(m.updateDate),
    }));

    dispatch(actions.getHistoricoSucesso(data));
  } catch (error) {
    dispatch(actions.getHistoricoError());
  }
};

export default {
  getHistorico,
  resetStore,
  setOpenModal,
};
