import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import colors from 'assets/styles/colors';

import FormInput from 'features/common-staff/controls/input/formInput';

const useStyles = makeStyles({
  input: {
    width: '400px',
    marginBottom: '15px',
    marginLeft: '8px',
  },
  error: {
    color: colors.error_color_300,
    fontSize: '11px',
  },
});

const InputSap = ({
  codigoSap, setCodigoSap, error, required,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.input} data-cy="CodigoSap">
      <FormInput
        type="number"
        label="Código SAP"
        value={codigoSap}
        setValue={(value) => {
          setCodigoSap(value);
        }}
        allowEmptyFormatting="true"
        format="802######"
        mask=" -"
        disabled={false}
        error={error}
        required={required}
      />
      {error ? (
        <span className={classes.error} data-cy="ErroCodigoSap">
          Código utilizado para acessar sistema SAP Stellantis (9 dígitos)
        </span>
      ) : null}

    </div>
  );
};

InputSap.propTypes = {
  codigoSap: PropTypes.string,
  setCodigoSap: PropTypes.func,
  error: PropTypes.bool,
  required: PropTypes.bool,
};

InputSap.defaultProps = {
  codigoSap: '',
  setCodigoSap: () => {},
  error: false,
  required: false,
};

export default InputSap;
