const DISMISS_SNACKBAR = 'analistasPage/DISMISS_SNACKBAR';
const ADD_SNACKBAR = 'analistasPage/ADD_SNACKBAR';
const SET_CADASTRO_PAGE = 'limitesAprovadosPage/SET_CADASTRO_PAGE';
const UPDATE_LIMITE_STATUS = 'analistasPage/UPDATE_LIMITE_STATUS';

const PREFIX_LIMITES_APROVADOS = 'limitesAprovadosPage/GET_LIMITES_APROVADOS';
const PREFIX_LIMITES_APROVADOS_DETALHES = 'limitesAprovadosPage/GET_DETALHE_LIMITE';
const PREFIX_LIMITES_APROVADOS_FILTERS = 'limitesAprovadosPage/UPDATE_LIMITE';
const GET_LIMITES_APROVADOS_START = `${PREFIX_LIMITES_APROVADOS}/START`;
const GET_LIMITES_APROVADOS_ERROR = `${PREFIX_LIMITES_APROVADOS}/ERROR`;
const GET_LIMITES_APROVADOS_SUCCESS = `${PREFIX_LIMITES_APROVADOS}/SUCCESS`;
const GET_DETALHE_LIMITE_START = `${PREFIX_LIMITES_APROVADOS_DETALHES}/SUCCESS`;
const GET_DETALHE_LIMITE_ERROR = `${PREFIX_LIMITES_APROVADOS_DETALHES}/START`;
const GET_DETALHE_LIMITE_SUCCESS = `${PREFIX_LIMITES_APROVADOS_DETALHES}/ERROR`;
const UPDATE_LIMITE_START = `${PREFIX_LIMITES_APROVADOS_FILTERS}/START`;
const UPDATE_LIMITE_ERROR = `${PREFIX_LIMITES_APROVADOS_FILTERS}/ERROR`;
const UPDATE_LIMITE_SUCCESS = `${PREFIX_LIMITES_APROVADOS_FILTERS}/SUCCESS`;
const CLEAR_FILTERS = `${PREFIX_LIMITES_APROVADOS}/CLEAR_FILTERS`;

const RESET_STORE = 'limitesAprovadosPage/RESET_STORE';

const addSnackbar = (message, type) => ({
  type: ADD_SNACKBAR,
  payload: { message, type },
});

const dismissSnackbar = (id) => ({
  type: DISMISS_SNACKBAR,
  payload: { id },
});

const getLimitesAprovadosStart = () => ({
  type: GET_LIMITES_APROVADOS_START,
});

const getLimitesAprovadosError = () => ({
  type: GET_LIMITES_APROVADOS_ERROR,
});

const getLimitesAprovadosSuccess = (limites) => ({
  type: GET_LIMITES_APROVADOS_SUCCESS,
  payload: { limites },
});

const getDetalheLimiteStart = () => ({
  type: GET_DETALHE_LIMITE_START,
});

const getDetalheLimiteError = () => ({
  type: GET_DETALHE_LIMITE_ERROR,
});

const getDetalheLimiteSuccess = (detalhes, historico) => ({
  type: GET_DETALHE_LIMITE_SUCCESS,
  payload: { detalhes, historico },
});

const resetStore = () => ({ type: RESET_STORE });

const updateLimiteStart = () => ({
  type: UPDATE_LIMITE_START,
});

const updateLimiteError = () => ({
  type: UPDATE_LIMITE_ERROR,
});

const updateLimiteSuccess = (filters) => ({
  type: UPDATE_LIMITE_SUCCESS,
  payload: { filters },
});

const setCadastroPage = (cadastroPage) => ({
  type: SET_CADASTRO_PAGE,
  payload: { cadastroPage },
});

const clearFilters = () => ({
  type: CLEAR_FILTERS,
});

const updateLimiteStatus = (statusLimite) => ({
  type: UPDATE_LIMITE_STATUS,
  payload: { statusLimite },
});

export default {
  types: {
    DISMISS_SNACKBAR,
    ADD_SNACKBAR,
    GET_LIMITES_APROVADOS_START,
    GET_LIMITES_APROVADOS_ERROR,
    GET_LIMITES_APROVADOS_SUCCESS,
    RESET_STORE,
    GET_DETALHE_LIMITE_START,
    GET_DETALHE_LIMITE_SUCCESS,
    GET_DETALHE_LIMITE_ERROR,
    PREFIX_LIMITES_APROVADOS_DETALHES,
    PREFIX_LIMITES_APROVADOS_FILTERS,
    UPDATE_LIMITE_START,
    UPDATE_LIMITE_SUCCESS,
    UPDATE_LIMITE_ERROR,
    SET_CADASTRO_PAGE,
    UPDATE_LIMITE_STATUS,
  },
  addSnackbar,
  dismissSnackbar,
  getLimitesAprovadosStart,
  getLimitesAprovadosError,
  getLimitesAprovadosSuccess,
  resetStore,
  getDetalheLimiteStart,
  getDetalheLimiteSuccess,
  getDetalheLimiteError,
  updateLimiteStart,
  updateLimiteSuccess,
  updateLimiteError,
  clearFilters,
  setCadastroPage,
  updateLimiteStatus,
};
