import React from 'react';
import PropTypes from 'prop-types';

import {
  DuplicataValue, DuplicataDate, DuplicataDescription, DuplicataIcon,
} from '../../../common';

import './paymentEditorRow.scss';
// import CurrencyInput from '../../../common/currencyInput';
import CurrencyInput from './currencyInputPayment/currencyInput';

class PaymentEditorRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editValue: 0,
    };
  }

  async componentDidMount() {
    const { invoice, updateSelectedInvoice } = this.props;
    const newInvoice = invoice;

    newInvoice.selectedForPaymentValue = invoice.remainingValue;

    this.setState({ editValue: newInvoice.selectedForPaymentValue });
    await updateSelectedInvoice(newInvoice);
  }

  handleValueOnBlur = async (value) => {
    const { invoice, updateSelectedInvoice } = this.props;

    const newInvoice = invoice;

    if (value > newInvoice.remainingValue) {
      newInvoice.selectedForPaymentValue = newInvoice.remainingValue;
      this.setState({ editValue: newInvoice.selectedForPaymentValue });
    } else {
      newInvoice.selectedForPaymentValue = Number(Number(value).toFixed(2));
      this.setState({ editValue: Number(Number(value).toFixed(2)) });
    }
    await updateSelectedInvoice(newInvoice);
  };

  handleValueUpdate = async (value) => {
    const { invoice, updateSelectedInvoice } = this.props;

    this.setState({ editValue: Number(Number(value).toFixed(2)) });

    const newInvoice = invoice;
    newInvoice.selectedForPaymentValue = Number(Number(value).toFixed(2));
    await updateSelectedInvoice(newInvoice);
  };

  render() {
    const { className, invoice } = this.props;
    const { editValue } = this.state;

    return (
      <div className={`editor-row ${className}`}>
        <DuplicataIcon
          className="editor-row-icon"
          product={invoice.product}
          status={invoice.status}
        />

        <DuplicataDescription
          className="editor-row-desc"
          product={invoice.product}
          invoiceNumber={invoice.invoiceNumber}
          invoiceDigit={invoice.invoiceDigit}
        />

        <DuplicataDate
          className="editor-row-date"
          status={invoice.status}
          dueDate={invoice.dueDate}
          showLabel={false}
          paymentDate={invoice.paymentDate}
        />

        <DuplicataValue
          className="editor-row-total-value"
          status={invoice.status}
          totalValue={invoice.totalValue}
          processingValue={invoice.processingValue}
          remainingValue={invoice.remainingValue}
        />

        <div className="editor-row-paying-value">
          <CurrencyInput
            value={editValue}
            max={100000000}
            className="editor-row-paying-value-input"
            onValueChange={(value) => { this.handleValueUpdate(value); }}
            onBlur={() => {
              this.handleValueOnBlur(Number(parseFloat(editValue || 0.0).toFixed(2)));
            }}
          />
        </div>

      </div>
    );
  }
}

PaymentEditorRow.propTypes = {
  className: PropTypes.string,
  invoice: PropTypes.object.isRequired,
  updateSelectedInvoice: PropTypes.func.isRequired,
};

PaymentEditorRow.defaultProps = {
  className: '',
};

export default PaymentEditorRow;
