import { createSelector } from 'reselect';

const timelineArray = (state) => state?.acompanhamento?.detalhe?.modal?.detalhePedido?.timeline;

const canCancel = createSelector(
  timelineArray,
  (timeline) => {
    const separacao = (timeline || []).find((tl) => tl.statusPedido === 'separacao');
    if (separacao?.situacao === 'em_andamento' || separacao?.situacao === 'futuro') {
      return true;
    }
    return false;
  },
);

const currentStatus = (state) => state?.acompanhamento?.detalhe?.modal?.detalhePedido?.status;

const canDownloadNotaFiscal = createSelector(
  currentStatus,
  (status) => (status === 'faturado' || status === 'contrato_anexado' || status === 'contrato_pago')
  ,
);

export default {
  canCancel,
  canDownloadNotaFiscal,
};
