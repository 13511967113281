import { connect } from 'react-redux';
import DetalheEstorno from './detalheEstorno';

const mapStateToProps = ({ statementStore }) => ({
  isLoading: statementStore.modal.isLoading,
  resumoEstorno: statementStore.modal.detalhes?.resumo,
  historicoEstorno: statementStore.modal.detalhes?.historico,
  header: statementStore.modal.header,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DetalheEstorno);
