import { connect } from 'react-redux';
import InfoDocumentosFormalizar from './infoDocumentosFormalizar';
import operations from '../../redux/operations';

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  updateObservacao: (observacao, indexList) => dispatch(
    operations.updateDocumentoFormalizar(observacao, indexList),
  ),
  appendAnexo: (id) => dispatch(operations.appendAnexoDocumentoFormalizar(id)),
  downloadAnexo: (id) => dispatch(
    operations.downloadAnexoDocumentoFormalizar(id),
  ),
  deleteAnexo: (id, indexList) => dispatch(
    operations.deleteAnexoDocumentoFormalizar(id, indexList),
  ),
  uploadAnexo: (file, idDocumentoFormalizarJuridico, indexList) => dispatch(
    operations.uploadAnexoDocumentoFormalizar(file, idDocumentoFormalizarJuridico, indexList),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoDocumentosFormalizar);
