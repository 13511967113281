import { connect } from 'react-redux';
import InfoCadastroList from './infoCadastroList';
import operations from '../../redux/operations';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  updateObservacaoDealerCadastro: (idPessoa, observacao) => dispatch(
    operations.updateObservacaoDealerCadastro(idPessoa, observacao),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoCadastroList);
