import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import colors from 'assets/styles/colors';

import './loadingRedirect.scss';

const LoadingRedirect = () => (
  <div className="login-redirect__container">
    Aguarde!
    <span className="login-redirect__container__mensagem">
      Estamos te redirecionando para o sistema Hub...
    </span>
    <div className="login-redirect__container__loading">
      <CircularProgress color={colors.primary_color_500} size="56px" />
    </div>
  </div>
);

export default LoadingRedirect;
