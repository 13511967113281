import moment from 'moment';

import { pagamentoStatusMotivo } from './pagamentoStatusMotivo';
import { actions } from './actions';

const INITIAL_STATE = {
  loading: false,
  paymentAvailable: true,
  userMessage: null,

  lastLoadTime: null,
  nextLoadTime: moment(),
};

const getNextLoadTime = (lastLoadTime) => {
  if (moment.isMoment(lastLoadTime) && lastLoadTime.isValid()) {
    return lastLoadTime.add(5, 'minutes');
  }

  return moment().add(10, 'seconds');
};

const getUserMessage = (status) => {
  if (status.disponivel) return null;

  if (status.motivo === pagamentoStatusMotivo.PAG_INDISPONIVEL_OUTRO) {
    return 'Estamos atualizando alguns dados da sua concessionária, durante esse período os pagamentos estarão desabilitados.';
  }

  if (status.motivo === pagamentoStatusMotivo.PAG_INDISPONIVEL_HORARIO_INVALIDO) {
    const { horaInicio, horaFim } = status;
    if (
      moment.isMoment(horaInicio) && horaInicio.isValid()
      && moment.isMoment(horaFim) && horaFim.isValid()
    ) {
      return 'Os pagamentos só podem ser realizados '
      + `entre ${horaInicio.format('HH:mm')} e ${horaFim.format('HH:mm')}.`;
    }
  }

  return 'Pagamento indisponível no momento.';
};

export default (state = INITIAL_STATE, action = { type: 'default' }) => {
  switch (action.type) {
    case actions.types.GET_STATUS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actions.types.GET_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentAvailable: action.payload.status.disponivel,
        userMessage: getUserMessage(action.payload.status),

        lastLoadTime: action.payload.timeOfRequest,
        nextLoadTime: getNextLoadTime(action.payload.timeOfRequest),
      };
    case actions.types.GET_STATUS_ERROR:
      return {
        ...state,
        loading: false,

        lastLoadTime: action.payload.timeOfRequest,
        nextLoadTime: getNextLoadTime(action.payload.timeOfRequest),
      };
    default:
      return state;
  }
};
