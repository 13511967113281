import { applyProperty } from 'utils/object';
import actions from './actions';

const INITIAL_STATE = {
  relatorios: [],

  requestStatus: {
    isLoading: false,
    isError: false,
    errors: [],
    isLoadingExport: false,
  },

  filtros: {
    dataInicio: null,
    dataFim: null,
    tipoRelatorio: '_default',
    dealers: [],
  },

  isActiveFiltrar: false,
  isRelatorioSelected: false,
  isDateRangeSelected: false,
  wasFilted: false,

  snackbarErrors: [],
  snackbarId: 0,

  pageParams: {
    page: 0,
    ipp: 25,
    totalItens: 0,
  },
  selectedAll: false,
};

const isSelectedAll = (relatorios, documento) => {
  const relatoriosTemp = relatorios.map((e) => {
    if (
      e.dealerCorr === documento.dealerCorr
      && e.tipoDocumento === documento.tipoDocumento
      && e.nomeDocumento === documento.nomeDocumento
      && e.data === documento.data
    ) {
      return {
        ...e,
        selected: !e.selected,
      };
    }
    return e;
  });
  const selectedAll = relatoriosTemp
    .filter((e) => e.selected === true).length === relatoriosTemp.length;
  return { relatorios: relatoriosTemp, selectedAll };
};

export default (
  state = INITIAL_STATE,
  action = { type: '@@relatoriosIof/INIT' },
) => {
  switch (action.type) {
    case actions.types.SET_FILTER:
      return {
        ...state,
        filtros: {
          ...applyProperty(
            state.filtros,
            action.payload.paramName,
            action.payload.value,
          ),
        },
        isActiveFiltrar: action.payload.activeButton,
      };
    case actions.types.SELECT_ALL:
      return {
        ...state,
        selectedAll: !state.selectedAll,
        relatorios: state.relatorios.map((e) => ({
          ...e,
          selected: !state.selectedAll,
        })),
      };
    case actions.types.SELECT:
      return {
        ...state,
        selectedAll: isSelectedAll(state.relatorios, action.payload.documento)
          .selectedAll,
        relatorios: isSelectedAll(state.relatorios, action.payload.documento)
          .relatorios,
      };
    case actions.types.SET_PAGE_PARAMS:
      return {
        ...state,
        pageParams: applyProperty(
          state.pageParams,
          action.payload.propertyName,
          action.payload.value,
        ),
      };
    case actions.types.GET_RELATORIOS_START:
      return {
        ...state,
        requestStatus: {
          isLoading: true,
          isError: false,
        },
        wasFilted: true,
      };
    case actions.types.GET_RELATORIOS_ERROR:
      return {
        ...state,
        requestStatus: {
          isLoading: false,
          isError: true,
        },
      };
    case actions.types.GET_RELATORIOS_SUCCESS:
      return {
        ...state,
        requestStatus: {
          isLoading: false,
          isError: false,
        },
        relatorios: action.payload.relatorio.relatorios,
        pageParams: {
          page: action.payload.relatorio.page,
          ipp: action.payload.relatorio.itensPerPage,
          totalItens: action.payload.relatorio.totalItens,
        },
        isActiveFiltrar: false,
      };
    case actions.types.SET_IS_RELATORIO_SELECTED:
      return {
        ...state,
        isRelatorioSelected: action.payload,
      };
    case actions.types.SET_IS_DATE_RANGE_SELECTED:
      return {
        ...state,
        isDateRangeSelected: action.payload,
      };
    case actions.types.EXPORT_RELATORIO_START:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          isLoadingExport: true,
        },
      };
    case actions.types.EXPORT_RELATORIO_SUCCESS:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          isLoadingExport: false,
        },
        snackbarErrors: [...state.snackbarErrors, {
          id: state.snackbarId,
          message: 'Download feito com sucesso',
          type: 'success',
        }],
        snackbarId: state.snackbarId + 1,
      };
    case actions.types.EXPORT_RELATORIO_ERROR:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          isLoadingExport: false,
        },
        snackbarErrors: [...state.snackbarErrors, {
          id: state.snackbarId,
          message: 'Error ao fazer download',
          type: 'error',
        }],
        snackbarId: state.snackbarId + 1,
      };

    case actions.types.DISMISS_SNACKBARS:
      return {
        ...state,
        snackbarErrors: state.snackbarErrors.filter((item) => item.id !== action.payload.id),
      };
    case actions.types.RESET_STORE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
