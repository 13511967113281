import React from 'react';
import InputValor from './inputValor';
import FormSection from '../formSection';
import './dadosSolicitacao.scss';

const DadosSolicitacao = () => (
  <FormSection title="Dados da Solicitação">
    <div className="dados-concessionario__container ">
      <InputValor />
    </div>
  </FormSection>

);

export default DadosSolicitacao;
