import React from 'react';

import './legendaCarteiraProduto.scss';

const LegendaCarteiraProduto = () => (
  <div className="dashboard-floor-plan__legenda-carteiraProduto">
    <div className="dashboard-floor-plan__legenda-carteiraProduto__item">
      <span className="vencido" />
      <span>Vencidos</span>
    </div>
    <div className="dashboard-floor-plan__legenda-carteiraProduto__item">
      <span className="nao-vencido" />
      <span>A vencer</span>
    </div>
  </div>
);

export default LegendaCarteiraProduto;
