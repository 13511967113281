import { saveAs } from 'file-saver';
import logger from 'utils/logger';
import { Tracking } from 'modules';
import actions from './actions';
import service from './service';
import { DealerSelectors } from '../../../modules/dealers/redux';
import Operation from '../../gestaoPagamentos/views/carteira/carteiraPage/redux/operations';

const setFilter = (propertyName, value) => async (dispatch) => {
  dispatch(actions.setFilter(propertyName, value));
};

const setModalTipo = (tipo, status, produto) => async (dispatch) => {
  dispatch(actions.setModalTipo(tipo, status, produto));
};

const setModalComprovanteOpen = (value) => async (dispatch) => {
  dispatch(actions.setModalOpen(false, null));
  dispatch(actions.setModalComprovanteOpen(value));
};

const setModalComprovanteClose = () => async (dispatch) => {
  dispatch(actions.setModalComprovanteOpen(false));
};

const setModalOpen = (selectedId, type, status, produto, index, header = '') => async (dispatch) => {
  try {
    dispatch(actions.setModalTipo(type, status, produto, index, header));
    dispatch(actions.setModalOpen(true, selectedId));
    dispatch(actions.getDetalheMovimentacaoStart());

    if (type.toLowerCase() === 'estorno') {
      const detalheMovimentacao = await service.getEstornoDetalhe(selectedId);
      dispatch(actions.getDetalheMovimentacaoSuccess(detalheMovimentacao.data));
    } else {
      const detalheMovimentacao = await service.getPagamentoDetalhe(selectedId);
      const detalhe = { ...detalheMovimentacao.data, movimentacaoId: selectedId };
      dispatch(actions.getDetalheMovimentacaoSuccess(detalhe));
    }
  } catch (e) {
    dispatch(actions.getDetalheMovimentacaoError());
  }
};

const setModalClose = () => async (dispatch) => {
  dispatch(actions.setModalOpen(false, null));
};

const resetStore = () => async (dispatch) => {
  dispatch(actions.resetStore());
};

const setFilterDateType = (type) => async (dispatch) => {
  dispatch(actions.setFilterDateType(type));
};

const setFilterTransactionType = (type) => async (dispatch) => {
  dispatch(actions.setFilterTransactionType(type));
};

const getMovimentacoes = () => async (dispatch, getState) => {
  try {
    dispatch(actions.getMovimentacoesStart());
    const { pageParams, filters } = getState().statementStore;
    const dealerId = DealerSelectors.getSelectedFPlanDealer(getState().dealerStore);
    const response = await service.getTransactions(pageParams, filters, dealerId.dealerId);
    const newPageParams = {
      page: response.page,
      ipp: response.ipp,
      itemsTotais: response.itensTotal,
    };
    dispatch(actions.getMovimentacoesSuccess(
      response.transactions,
      newPageParams,
      response.isUpdated,
    ));
  } catch (error) {
    dispatch(actions.getMovimentacoesError());
    logger.error(error);
  }
};

const dismissSnackbar = (id) => async (dispatch) => {
  dispatch(actions.dismissSnackbar(id));
};

const getExtrato = () => async (dispatch, getState) => {
  try {
    dispatch(actions.getFileStart());
    const movimentacaoId = getState()?.statementStore?.modal?.detalhes?.movimentacaoId;
    Tracking.Mixpanel.movimentacoes.trackPaymentExport();
    const response = await service.getExtrato(movimentacaoId);
    const url = window.URL.createObjectURL(new Blob([response]));
    saveAs(url, 'extrato.xlsx');
    dispatch(actions.getFilerSuccess());
  } catch (error) {
    dispatch(actions.getFileError());
  }
};

const getExtratoEstorno = () => async (dispatch, getState) => {
  try {
    dispatch(actions.getFileStart());
    const duplicata = getState()?.statementStore?.modal?.detalhes?.resumo?.duplicataNumero;
    const response = await service.getExtratoEstorno(duplicata);
    const url = window.URL.createObjectURL(new Blob([response]));
    saveAs(url, 'extratoEstorno.xlsx');
    dispatch(actions.getFilerSuccess());
  } catch (error) {
    dispatch(actions.getFileError());
  }
};

const showDuplicata = (invoiceNumber) => async (dispatch) => {
  try {
    await dispatch(Operation.resetStore());
    await dispatch(Operation.setIsRedirect(false));
    await dispatch(Operation.setFilter('texto', invoiceNumber));
    await dispatch(Operation.getCarteira());
  } catch (error) {
    logger.error(error);
  }
};

const setPageParams = (propertyName, value) => async (dispatch) => {
  dispatch(actions.setPageParams(propertyName, value));
  dispatch(getMovimentacoes());
};

export default {
  setFilter,
  setModalOpen,
  setModalClose,
  setModalTipo,
  setPageParams,
  resetStore,
  getMovimentacoes,
  setFilterDateType,
  setFilterTransactionType,
  dismissSnackbar,
  getExtrato,
  getExtratoEstorno,
  setModalComprovanteOpen,
  setModalComprovanteClose,
  showDuplicata,
};
