import React from 'react';
import PropTypes from 'prop-types';

import BaseArea from '../baseArea';
import LegendaValorDuplicatas from './legendaValorDuplicatas';
import ChartValorDuplicatas from './chartValorDuplicatas';
import Table from './table';

const ValorDuplicatasArea = ({ isEmpty, isLoading, isError }) => (
  <BaseArea
    isEmpty={isEmpty}
    isLoading={isLoading}
    isError={isError}
    title="Valores vencidos e a vencer"
    subtitle="Total"
    className="dashboard-floor-plan__valor-duplicatas"
    legenda={<LegendaValorDuplicatas />}
  >
    <>
      <ChartValorDuplicatas />
      <Table />
    </>
  </BaseArea>
);

ValorDuplicatasArea.propTypes = {
  isLoading: PropTypes.bool,
  isEmpty: PropTypes.bool,
  isError: PropTypes.bool,
};

ValorDuplicatasArea.defaultProps = {
  isLoading: false,
  isEmpty: false,
  isError: false,
};

export default ValorDuplicatasArea;
