import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import Button from 'features/common-staff/controls/button';

const useStyles = makeStyles({
  button: {
    width: '250px',
    height: '40px',
    '&:disabled': {
      backgroundColor: '#E9EAF0',
    },
  },
});

const ConfirmarButton = ({
  enviarPedido, isLoading, isSendDisabled,
}) => {
  const classes = useStyles();
  return (
    <Button
      onClick={() => {
        enviarPedido();
      }}
      className={classes.button}
      isLoading={isLoading}
      disabled={isSendDisabled}
      fullWidth
    >
      Confirmar Solicitação
    </Button>
  );
};
ConfirmarButton.propTypes = {
  enviarPedido: PropTypes.func,
  isLoading: PropTypes.bool,
  isSendDisabled: PropTypes.bool,
};

ConfirmarButton.defaultProps = {
  enviarPedido: () => {},
  isLoading: false,
  isSendDisabled: true,
};

export default ConfirmarButton;
