import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Minus } from 'react-feather';

import ProdutoIcon from '../../../../../../../../../common/produtoIcon';

import './removeButton.scss';

const setClassStatus = (status) => {
  if (status.toLowerCase().trim() === 'em aberto') {
    return 'icon-white';
  }
  return 'icon-red';
};

const RemoveButton = ({
  onClick, invoiceId, produto, status,
}) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <div
      className={`modal-carteira__payment-modal__remove-button ${setClassStatus(status)}`}
      onClick={() => onClick(invoiceId)}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onFocus={() => setIsHover(true)}
      onBlur={() => setIsHover(false)}
      role="button"
      tabIndex={0}
    >
      {!isHover ? <ProdutoIcon product={produto} status={status} /> : <Minus />}
    </div>
  );
};

RemoveButton.propTypes = {
  onClick: PropTypes.func,
  invoiceId: PropTypes.number,
  produto: PropTypes.string,
  status: PropTypes.string,
};

RemoveButton.defaultProps = {
  onClick: () => {},
  invoiceId: null,
  produto: null,
  status: '',
};

export default RemoveButton;
