/* eslint-disable no-unused-vars */
import { messages } from '../../../../../common/chat/messages';

import { chatStates } from './chatStates';
import { valueForChatState } from '../../chatEngine/utils';

const messagesFluxoSolicitouTestDrive = [
  {
    chatState: chatStates.experiencia_hub,
    createMessages: (isAnswered, answers, handleAnswer) => [
      messages.text('fidis', 'Até agora, como você classifica sua experiência com o Hub?'),
      (
        isAnswered
          ? messages.emojiMessageAnswered(valueForChatState(answers, chatStates.experiencia_hub))
          : messages.emojiMessageOpen((ans) => handleAnswer(ans))
      ),
    ],
  },
  {
    chatState: chatStates.td_palavra_processo,
    createMessages: (isAnswered, answers, handleAnswer) => {
      const options = [
        messages.option('Útil', 'util'),
        messages.option('Inútil', 'inutil'),
        messages.option('Intuitivo', 'intuitivo'),
        messages.option('Confuso', 'confuso'),
        messages.option('Burocrático', 'burocratico'),
        messages.option('Prático', 'pratico'),
      ];

      return [
        messages.text('fidis', 'Selecione abaixo a palavra que melhor descreve como foi para você o processo de solicitar um novo veículo de test drive?'),
        ((isAnswered === false)
          ? messages.select.open('fidis', options, (ans) => handleAnswer(ans))
          : messages.select.answered.fromOptions('fidis', options, valueForChatState(answers, chatStates.td_palavra_processo))
        ),
      ];
    },
  },
  {
    chatState: chatStates.td_dificuldade,
    createMessages: (isAnswered, answers, handleAnswer) => [
      messages.text('fidis', 'Durante a solicitação do Test Drive, houve algo que você teve dificuldade?'),
      (
        (isAnswered === false)
          ? messages.selectOpenYesNo('fidis', (ans) => handleAnswer(ans))
          : messages.selectYesNoAnswered('fidis', valueForChatState(answers, chatStates.td_dificuldade))
      ),
    ],
  },
  {
    chatState: chatStates.td_dificuldade_qual,
    createMessages: (isAnswered, answers, handleAnswer) => [
      messages.text('fidis', 'Por favor, nos diga qual dificuldade encontrou.'),
      (isAnswered === false
        ? ''
        : messages.text('user', valueForChatState(answers, chatStates.td_dificuldade_qual))
      ),
    ],
  },
  {
    chatState: chatStates.td_melhoria,
    createMessages: (isAnswered, answers, handleAnswer) => [
      messages.text('fidis', 'Há alguma melhoria ou informação que gostaria de ver na solicitação de Test Drive no Hub?'),
      (
        (isAnswered === false)
          ? messages.selectOpenYesNo('fidis', (ans) => handleAnswer(ans))
          : messages.selectYesNoAnswered('fidis', valueForChatState(answers, chatStates.td_melhoria))
      ),
    ],
  },
  {
    chatState: chatStates.td_melhoria_qual,
    createMessages: (isAnswered, answers, handleAnswer) => [
      messages.text('fidis', 'Gostariamos de saber, quais melhorias ou informações você gostaria de ver na solicitação de Test Drive do Hub?'),
      (isAnswered === false
        ? ''
        : messages.text('user', valueForChatState(answers, chatStates.td_melhoria_qual))
      ),
    ],
  },
];

const messagesFluxoNaoSolicitouTestDrive = [
  {
    chatState: chatStates.td_pq_nao_solicitou,
    createMessages: (isAnswered, answers, handleAnswer) => {
      const options = [
        messages.option('Não tenho necessidade nesse momento', 'nao_tem_necessidade', false),
        messages.option('As condições não me pareceram atrativas', 'condicoes_nao_atrativas', false),
        messages.option('Não encontrei o veículo que eu queria', 'nao_encontrou_veiculo', false),
        messages.option('Outros', 'outros', false),
      ];

      return [
        messages.text('fidis', 'Por qual motivo você não solicitou nenhum veículo de Test Drive?'),
        ((isAnswered === false)
          ? messages.select.open('fidis', options, (ans) => handleAnswer(ans))
          : messages.select.answered.fromOptions('fidis', options, valueForChatState(answers, chatStates.td_pq_nao_solicitou))
        ),
      ];
    },
  },
  {
    chatState: chatStates.td_pq_nao_solicitou_outros_qual,
    createMessages: (isAnswered, answers, handleAnswer) => [
      messages.text('fidis', 'Você poderia nos contar o motivo?'),
      (isAnswered === false
        ? ''
        : (messages.text('user', valueForChatState(answers, chatStates.td_pq_nao_solicitou_outros_qual)))
      ),
    ],
  },
];

const messagesFluxoPagamento = [
  {
    chatState: chatStates.pag_ja_utilizou,
    createMessages: (isAnswered, answers, handleAnswer) => [
      messages.text('fidis', 'Você já utilizou a funcionalidade de pagamentos?'),
      (
        (isAnswered === false)
          ? messages.selectOpenYesNo('fidis', (ans) => handleAnswer(ans))
          : messages.selectYesNoAnswered('fidis', valueForChatState(answers, chatStates.pag_ja_utilizou))
      ),
    ],
  },
  {
    chatState: chatStates.pag_n_utilizou_motivo,
    createMessages: (isAnswered, answers, handleAnswer) => [
      messages.text('fidis', 'Você se importaria em dizer para gente por qual motivo?'),
      (isAnswered === false
        ? ''
        : (messages.text('user', valueForChatState(answers, chatStates.pag_n_utilizou_motivo)))
      ),
    ],
  },
  {
    chatState: chatStates.pag_experiencia,
    createMessages: (isAnswered, answers, handleAnswer) => {
      const options = [
        messages.option('Excelente', 'excelente'),
        messages.option('Boa', 'boa'),
        messages.option('Razoável', 'razoavel'),
        messages.option('Ruim', 'ruim'),
        messages.option('Muito Ruim', 'muito_ruim'),
      ];

      return [
        messages.text('fidis', 'Como você classificaria a experiência em realizar um pagamento pelo Hub?'),
        ((isAnswered === false)
          ? messages.select.open('fidis', options, (ans) => handleAnswer(ans))
          : messages.select.answered.fromOptions('fidis', options, valueForChatState(answers, chatStates.pag_experiencia))
        ),
      ];
    },
  },
  {
    chatState: chatStates.pag_palavra_hub,
    createMessages: (isAnswered, answers, handleAnswer) => {
      const options = [
        messages.option('Útil', 'util'),
        messages.option('Inútil', 'inutil'),
        messages.option('Intuitivo', 'intuitivo'),
        messages.option('Confuso', 'confuso'),
        messages.option('Burocrático', 'burocratico'),
        messages.option('Prático', 'pratico'),
      ];

      return [
        messages.text('fidis', 'Qual palavra melhor representa o Hub?'),
        ((isAnswered === false)
          ? messages.select.open('fidis', options, (ans) => handleAnswer(ans))
          : messages.select.answered.fromOptions('fidis', options, valueForChatState(answers, chatStates.pag_palavra_hub))
        ),
      ];
    },
  },
  {
    chatState: chatStates.pag_melhoria,
    createMessages: (isAnswered, answers, handleAnswer) => [
      messages.text('fidis', 'Você gostaria de sugerir alguma melhoria para os pagamentos?'),
      (
        (isAnswered === false)
          ? messages.selectOpenYesNo('fidis', (ans) => handleAnswer(ans))
          : messages.selectYesNoAnswered('fidis', valueForChatState(answers, chatStates.pag_melhoria))
      ),
    ],
  },
  {
    chatState: chatStates.pag_melhoria_qual,
    createMessages: (isAnswered, answers, handleAnswer) => [
      messages.text('fidis', 'Qual?'),
      (isAnswered === false
        ? ''
        : (messages.text('user', valueForChatState(answers, chatStates.pag_melhoria_qual)))
      ),
    ],
  },
];

export const messageCreators = [
  {
    chatState: chatStates.iniciar,
    createMessages: (isAnswered, answers, handleAnswer) => [
      messages.text('fidis', 'Agradecemos o seu interesse em nos ajudar a tornar o Hub cada vez melhor, para isso faremos algumas perguntas sobre a sua experiência sobre o uso do HUB. Quando estiver preparado, clique em iniciar.'),
      (isAnswered === false
        ? (messages.select.open('fidis', [
          messages.option('Iniciar Pesquisa', 'iniciar', false),
          messages.option('Agora não', 'nao', false),
        ], (ans) => handleAnswer(ans))
        ) : (messages.select.answered.fromOptions('fidis', [
          messages.option('Iniciar Pesquisa', 'iniciar', false),
          messages.option('Agora não', 'nao', false),
        ], valueForChatState(answers, chatStates.iniciar))
        )
      ),
    ],
  },
  {
    chatState: chatStates.volte_depois,
    createMessages: (isAnswered, answers, handleAnswer) => [
      messages.text('fidis', 'Tudo bem, volte quando quiser responder nossa pesquisa.'),
    ],
  },
  {
    chatState: chatStates.solicitou_td,
    createMessages: (isAnswered, answers, handleAnswer) => [
      messages.text('fidis', 'Muito bem, vamos começar. Você solicitou algum veículo de test drive pelo Hub nos últimos 30 dias?'),
      (isAnswered === false
        ? (messages.selectOpenYesNo('fidis', (ans) => handleAnswer(ans))
        ) : (messages.selectYesNoAnswered('fidis', valueForChatState(answers, chatStates.solicitou_td))
        )
      ),
    ],
  },

  ...messagesFluxoNaoSolicitouTestDrive,
  ...messagesFluxoSolicitouTestDrive,
  ...messagesFluxoPagamento,

  {
    chatState: chatStates.melhorar_hub,
    createMessages: (isAnswered, answers, handleAnswer) => [
      messages.text('fidis', 'Antes de finalizarmos, gostariamos de te ouvir. Como conseguimos melhorar o Hub?'),
      (isAnswered === false
        ? ''
        : (messages.text('user', valueForChatState(answers, chatStates.melhorar_hub))
        )
      ),
    ],
  },
  {
    chatState: chatStates.agradecemos,
    createMessages: (isAnswered, answers, handleAnswer) => [
      messages.text('fidis', 'Agradecemos muito sua contribuição e seu interesse em nos ajudar a tornar o Hub cada vez melhor.'),
    ],
  },
  {
    chatState: chatStates.fim,
    createMessages: (isAnswered, answers, handleAnswer) => [],
  },
];
