import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Page,
  PageHeader,
  PageTitle,
  PageContent,
  PageFooter,
} from 'features/common-staff/layout/page';
import SnackbarsList from 'features/common-staff/snackbarList';
import Filters from './views/filters';
import CardSection from './views/cardsSection';
import ComunicadosPaginationFooter from './views/paginationFooter';
import './comunicadosPage.scss';

const ComunicadosPage = ({
  resetStore, getDve, snackbarErrors, dismissSnackbar,
}) => {
  useEffect(() => {
    getDve();
    return () => resetStore();
  }, [resetStore, getDve]);

  return (
    <Page>
      <PageHeader className="comunicados__page__header">
        <PageTitle>Central de comunicados</PageTitle>
      </PageHeader>
      <PageContent>
        <div className="comunicados__page__content">
          <div className="comunicados__page__content__filtros">
            <Filters />
          </div>
          <div
            className="comunicados__page__content__cards"
            data-cy="CardSection"
          >
            <CardSection />
          </div>
        </div>
      </PageContent>
      <PageFooter>
        <ComunicadosPaginationFooter />
      </PageFooter>
      <SnackbarsList
        snackbarErrors={snackbarErrors}
        onClose={(id) => dismissSnackbar(id)}
      />
    </Page>
  );
};

ComunicadosPage.propTypes = {
  resetStore: PropTypes.func,
  getDve: PropTypes.func,
  snackbarErrors: PropTypes.array,
  dismissSnackbar: PropTypes.func,
};

ComunicadosPage.defaultProps = {
  resetStore: () => {},
  getDve: () => {},
  snackbarErrors: [],
  dismissSnackbar: () => {},
};

export default ComunicadosPage;
