import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import ImageCard from 'features/common-staff/views/imageCard';
import { Tracking } from 'modules';

const useStyles = makeStyles({
  container: {
    paddingTop: '48px',
    display: 'flex',
    flexFlow: 'wrap',
    width: '100%',
    justifyContent: 'flex-start',
  },

  cardContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  cardTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '1.5',
    letterSpacing: '0.15px',
    textAlign: 'center',
    color: '#555770',
  },
  cardDesc: {
    padding: '16px 0',
    fontSize: '12px',
    lineHeight: '1.33',
    textAlign: 'center',
    color: '#8f9bb3',
  },
});

const TestDriveProductCards = ({
  products, selectedCategory, selectedProductId, selectProduct,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {(products || []).filter((p) => p.parent === selectedCategory.id).map((product, index) => (
        <ImageCard
          product={product}
          key={index}
          src={product.imagem}
          alt={product.titulo}
          style={{ height: '220px', cursor: 'pointer' }}
          selected={product.id === selectedProductId}
          noHover={product.id === selectedProductId}
          onClick={() => {
            Tracking.Mixpanel.testDrive.trackProductSelected(product.titulo);
            selectProduct(product.id);
          }}
          beforeChange={(currentValue, newValue) => {
            Tracking.Mixpanel.testDrive.trackArrow(currentValue, newValue);
          }}
        >
          <div className={classes.cardContainer}>
            <div className={classes.cardTitle}>
              {product.titulo}
            </div>
            <div className={classes.cardDesc}>
              {product.descricao}
            </div>
          </div>
        </ImageCard>
      ))}
    </div>
  );
};

TestDriveProductCards.propTypes = {
  products: PropTypes.array,
  selectedCategory: PropTypes.object,
  selectedProductId: PropTypes.number,
  selectProduct: PropTypes.func.isRequired,
};

TestDriveProductCards.defaultProps = {
  products: [],
  selectedCategory: null,
  selectedProductId: null,
};

export default TestDriveProductCards;
