import { connect } from 'react-redux';
import ListRow from './listRow';

import operations from '../../../redux/operations';

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  getDetalheLimite: (idLimite, detalhesLimite) => dispatch(
    operations.getDetalheLimite(idLimite, detalhesLimite),
  ),
  setCadastroPage: (cadastroPage) => {
    dispatch(
      operations.setCadastroPage(cadastroPage),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ListRow);
