import { connect } from 'react-redux';

import PaymentSummary from './paymentSummary';

const mapStateToProps = ({ authStore }) => ({
  user: authStore.user,
});

export default connect(
  mapStateToProps,
  null,
)(PaymentSummary);
