import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  input: {
    display: 'none',
  },
  div: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    flex: 1,
    justifyContent: 'center',

  },
}));

const DropDivUpload = ({
  children, uploadFiles, disabled, selectedFiles,
}) => {
  const fileInput = useRef();
  const classes = useStyles();
  const maxFiles = 5;
  const fileType = 'application/pdf';
  let filesToUpload = maxFiles - selectedFiles.length;

  const handleFiles = async (files) => {
    const newFiles = [];
    files.forEach((f) => {
      if (((selectedFiles || []).filter((sf) => sf.name === f.name).length === 0)
      && filesToUpload > 0 && f.type === fileType) {
        const newFile = f;
        newFile.isError = false;
        newFile.progress = 0;

        newFiles.push(newFile);
        filesToUpload -= 1;
      }
    });
    if (newFiles.length > 0) {
      uploadFiles(newFiles);
    }
  };

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };
  const fileDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.dataTransfer.files.length > 0 && !disabled) {
      handleFiles(Array.from(e.dataTransfer.files));
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={classes.div}
      onDragOver={dragOver}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDrop={fileDrop}
      onClick={() => {
        if (!disabled) {
          fileInput.current.value = null;
          fileInput.current.click();
        }
      }}
    >
      <input
        className={classes.input}
        multiple
        type="file"
        id="file"
        accept=".pdf"
        ref={fileInput}
        onChange={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (e.target.files && e.target.files.length > 0) {
            handleFiles(Array.from(e.target.files));
          }
        }}
      />
      {children}
    </div>
  );
};

DropDivUpload.propTypes = {
  children: PropTypes.node,
  uploadFiles: PropTypes.func,
  disabled: PropTypes.bool,
  selectedFiles: PropTypes.array,
};

DropDivUpload.defaultProps = {
  children: null,
  uploadFiles: () => {},
  disabled: true,
  selectedFiles: [],
};

export default DropDivUpload;
