import { connect } from 'react-redux';
import * as limiteCreditoOperations from '../../../redux/operations';

import InputValor from './inputValor';

const mapStateToProps = ({ limiteCreditoStore }) => ({
  valor: limiteCreditoStore.alteracaoLimite.dados.valor,
});

const mapDispatchToProps = (dispatch) => ({
  setValor: (valor) => dispatch(
    limiteCreditoOperations.setValor(valor),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(InputValor);
