import React from 'react';
import PropTypes from 'prop-types';

import './table.scss';

const Table = ({ data, className }) => (
  <div className={`dashboard-floor-plan__table-quantidade ${className}`}>
    <div className="dashboard-floor-plan__table-quantidade__row">
      <div className="dashboard-floor-plan__table-quantidade_produto" />
      <div className="dashboard-floor-plan__table-quantidade_vencido title">
        Vencido
      </div>
      <div className="dashboard-floor-plan__table-quantidade_a-vencer title">
        A vencer
      </div>
      <div className="dashboard-floor-plan__table-quantidade_total title">
        Total
      </div>
    </div>
    <div className="dashboard-floor-plan__table-quantidade__row">
      <div className="dashboard-floor-plan__table-quantidade_produto title">
        Floor Plan
      </div>
      <div className="dashboard-floor-plan__table-quantidade_vencido">
        {data?.floorPlan?.vencido}
      </div>
      <div className="dashboard-floor-plan__table-quantidade_a-vencer">
        {data?.floorPlan?.aVencer}
      </div>
      <div className="dashboard-floor-plan__table-quantidade_total">
        {data?.floorPlan?.total}
      </div>
    </div>
    <div className="dashboard-floor-plan__table-quantidade__row">
      <div className="dashboard-floor-plan__table-quantidade_produto title">
        Fundão
      </div>
      <div className="dashboard-floor-plan__table-quantidade_vencido">
        {data?.fundao?.vencido}
      </div>
      <div className="dashboard-floor-plan__table-quantidade_a-vencer">
        {data?.fundao?.aVencer}
      </div>
      <div className="dashboard-floor-plan__table-quantidade_total">
        {data?.fundao?.total}
      </div>
    </div>
  </div>
);

Table.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
};

Table.defaultProps = {
  data: null,
  className: '',
};

export default Table;
