/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import colors from "assets/styles/colors";

const FilterIcon = ({fill}) => (
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M14 5C14 5.55 13.55 6 13 6C12.45 6 12 5.55 12 5V1C12 0.45 12.45 0 13 0C13.55 0 14 0.45 14 1V2H17C17.55 2 18 2.45 18 3C18 3.55 17.55 4 17 4H14V5ZM1 4C0.45 4 0 3.55 0 3C0 2.45 0.45 2 1 2H10V4H1ZM1 16C0.45 16 0 15.55 0 15C0 14.45 0.45 14 1 14H6V16H1ZM10 17V16H17C17.55 16 18 15.55 18 15C18 14.45 17.55 14 17 14H10V13C10 12.45 9.55 12 9 12C8.45 12 8 12.45 8 13V17C8 17.55 8.45 18 9 18C9.55 18 10 17.55 10 17ZM4 8V7C4 6.45 4.45 6 5 6C5.55 6 6 6.45 6 7V11C6 11.55 5.55 12 5 12C4.45 12 4 11.55 4 11V10H1C0.45 10 0 9.55 0 9C0 8.45 0.45 8 1 8H4ZM18 9C18 8.45 17.55 8 17 8H8V10H17C17.55 10 18 9.55 18 9Z" fill={fill}/>
	</svg>

);

FilterIcon.propTypes = {
	fill: PropTypes.string
}

FilterIcon.defaultProps = {
	fill: colors.primary_color_600
}

export default FilterIcon;

