import React from 'react';
import PropTypes from 'prop-types';
import { OpenInNew } from '@material-ui/icons';

import { makeStyles } from '@material-ui/core';

import ButtonIcon from 'features/common-staff/controls/buttonIcon';

const useStyles = makeStyles(() => ({
  button: {
    color: '#FFFFFF',
    fontSize: '14px',
    lineHeight: '16px',
  },
  icon: {
    color: '#FFFFFF',
    fontSize: '16px',
    marginLeft: '8px',
  },
}));

const BotaoRedirecionamento = ({
  type, navTo, setCarteiraFilters,
}) => {
  const classes = useStyles();

  return (
    <ButtonIcon
      className={classes.button}
      onClick={async () => { await setCarteiraFilters(type); navTo(); }}
    >
      Visualizar duplicatas
      <OpenInNew className={classes.icon} />
    </ButtonIcon>
  );
};

BotaoRedirecionamento.propTypes = {
  type: PropTypes.string,
  navTo: PropTypes.func,
  setCarteiraFilters: PropTypes.func,
};

BotaoRedirecionamento.defaultProps = {
  type: '',
  navTo: () => {},
  setCarteiraFilters: () => {},
};

export default BotaoRedirecionamento;
