import React from 'react';
import PropTypes from 'prop-types';

import { formatCnpj } from 'utils/format';

import FormInput from 'features/common-staff/controls/input/formInput/';

const InputConcessionariaCnpj = ({
  concessionariaCnpj,
}) => (
  <FormInput
    type="number"
    label="CNPJ"
    value={concessionariaCnpj}
    format={formatCnpj}
  />
);

InputConcessionariaCnpj.propTypes = {
  concessionariaCnpj: PropTypes.string,

};

InputConcessionariaCnpj.defaultProps = {
  concessionariaCnpj: null,

};

export default InputConcessionariaCnpj;
