import styled from 'styled-components';
import colors from 'assets/styles/colors';

export const Container = styled.div`

`;

export const Row = styled.div`
  height: 56px;
  border-bottom: ${(props) => (props.isSelected || props.isHover ? `1px solid ${colors.primary_color_100_56}` : `1px solid ${colors.primary_color_100_24}`)};
  background-color: ${(props) => (props.isSelected ? `${colors.primary_color_100_36} !important` : 'white')};
  color: ${(props) => (props.isSelected || props.isHover ? '#404154' : '#555770')};
  cursor: pointer;
  &:hover {
    background: ${colors.primary_color_100_16};
  }
  > div:first-child {
    padding-left: 24px;
  };
  > div:last-child {
    padding-right: 24px;
  };
`;

export const Column = styled.div`
  grid-area: ${(props) => props.gridArea};
  display: flex;
  font-weight: 450;
  font-size: 14px;
  line-height: 24px;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (
    props.gridArea === 'duplicata' || props.gridArea === 'vencimento' || props.gridArea === 'status'
      ? ''
      : 'end'
  )};
`;
