import { connect } from 'react-redux';

import ModalUpload from './modalUpload';

import { TestDriveOperations } from '../../../redux';

import { isErrorUpload } from '../../../redux/testDriveSelectors';

const mapStateToProps = ({ testDrive }) => ({
  selectedFiles: testDrive.pedido.selectedFiles,
  isUploading: testDrive.pedido.isUploading,
  isError: isErrorUpload(testDrive.pedido),
  filesToSend: testDrive.pedido.filesToSend,
});

const mapDispatchToProps = (dispatch) => ({
  closeModalUpload:
  (selectedFiles) => dispatch(TestDriveOperations.closeModalUpload(selectedFiles)),
  sendFilestoForm: () => dispatch(TestDriveOperations.sendFilestoForm()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalUpload);
