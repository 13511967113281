import React from 'react';
import PropTypes from 'prop-types';

import './paymentReceiptInfoBox.scss';

const PaymentReceiptInfoBox = ({ className, subtittle, children }) => (
  <div className={`payment-receipt-body-info-container ${className}`}>
    <div className="payment-receipt-body-info-subtittle">{subtittle}</div>
    {children}
  </div>
);

PaymentReceiptInfoBox.propTypes = {
  className: PropTypes.string,
  subtittle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

PaymentReceiptInfoBox.defaultProps = {
  className: '',
};

export default PaymentReceiptInfoBox;
