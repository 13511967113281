import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { Tracking } from 'modules';

import ButtonIcon from '../../../../../common-staff/controls/buttonIcon';

const useStyle = makeStyles({
  button: {
    backgroundColor: 'inherit',
    color: '#ED5C6F',
    fontSize: '12px',
    lineHeight: '16px',
    alignContent: 'right',
    margin: '0',
    padding: '0',
  },
});

const BackButton = ({ onClick }) => {
  const classes = useStyle();
  return (
    <ButtonIcon
      onClick={() => {
        Tracking.Mixpanel.carteira.pagamento.trackTypeCancelPaymentButton();
        onClick();
      }}
      className={classes.button}
    >
      Cancelar e voltar para a carteira
    </ButtonIcon>
  );
};

BackButton.propTypes = {
  onClick: PropTypes.func,
};

BackButton.defaultProps = {
  onClick: () => {},
};

export default BackButton;
