import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import {
  ContainerStyle, ContentStyle, HeaderStyle, IconDropdown,
} from './summary.style';

const Summary = ({ expanded, title, children }) => {
  const [open, setOpen] = useState(expanded);

  return (
    <Accordion
      sx={ContainerStyle}
      disableGutters
      expanded={open}
      onChange={() => setOpen(!open)}
    >
      <AccordionSummary
        sx={HeaderStyle}
        expandIcon={<IconDropdown />}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails sx={ContentStyle}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

Summary.propTypes = {
  expanded: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Summary.defaultProps = {
  expanded: false,
  title: '',
  children: null,
};

export default Summary;
