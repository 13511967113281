import { connect } from 'react-redux';

import AcompanhamentoDetalhe from './acompanhamentoDetalhe';

import selectors from './redux/selectors';

const mapStateToProps = ({ testDrive }) => ({
  canCancel: selectors.canCancel(testDrive),
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(AcompanhamentoDetalhe);
