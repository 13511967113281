import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import './tableTaxa.scss';

const TableTaxa = ({
  header, firstRowName, secondRowName, firstRowValue, secondRowValue,
  firstRowPraticada, secondRowPraticada, showFirstRow, showSecondRow, width,
}) => {
  const renderTestDriveMessage = () => 'Consulte a aba "Solicitar Veículo”, no menu lateral, para verificar as taxas de Test Drive.';

  const renderTaxaValue = (value) => {
    if (_.isNull(value) || _.isUndefined(value)) {
      return 'Não possui';
    }
    return `${value.toString().replace('.', ',')}% `;
  };

  const renderTaxaPraticada = (praticada) => {
    if (_.isNull(praticada) || _.isUndefined(praticada)) {
      return '.';
    }
    return `${praticada}`;
  };

  const renderTaxaName = (name) => {
    if (_.isNull(name) || _.isEmpty(name) || _.isUndefined(name)) {
      return '';
    }
    return name;
  };

  const renderTaxaTotal = (value, praticada) => (
    <>
      {renderTaxaValue(value)}
      {renderTaxaPraticada(praticada)}
    </>
  );
  return (
    <div className="modal-taxa__tabelas" style={{ width }}>
      <div className="modal-taxa__tabelas__header">
        {header}
      </div>
      { showFirstRow && (
        header === 'TEST DRIVE' ? (
          <div className="modal-taxa__tabelas__test-drive">
            {renderTestDriveMessage()}
          </div>
        ) : (
          <div data-cy="firstRow">
            <div className={showSecondRow ? 'modal-taxa__tabelas__title' : 'modal-taxa__tabelas__title-unico'}>
              {renderTaxaName(firstRowName)}
            </div>
            <div className={showSecondRow ? 'modal-taxa__tabelas__taxa' : 'modal-taxa__tabelas__taxa-unica'}>
              {renderTaxaTotal(firstRowValue, firstRowPraticada)}
            </div>
          </div>
        )
      )}
      { showSecondRow && (
        <>
          { showFirstRow
            ? <div className="modal-taxa__tabelas__divisor" />
            : null }
          <div data-cy="secondRow">
            <div className={showFirstRow ? 'modal-taxa__tabelas__title' : 'modal-taxa__tabelas__title-unico'}>
              {renderTaxaName(secondRowName)}
            </div>
            <div className={showFirstRow ? 'modal-taxa__tabelas__taxa' : 'modal-taxa__tabelas__taxa-unica'}>
              {renderTaxaTotal(secondRowValue, secondRowPraticada)}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

TableTaxa.propTypes = {
  header: PropTypes.string,
  firstRowName: PropTypes.string,
  secondRowName: PropTypes.string,
  firstRowValue: PropTypes.number,
  secondRowValue: PropTypes.number,
  firstRowPraticada: PropTypes.string,
  secondRowPraticada: PropTypes.string,
  showFirstRow: PropTypes.bool,
  showSecondRow: PropTypes.bool,
  width: PropTypes.string,
};

TableTaxa.defaultProps = {
  header: null,
  firstRowName: '',
  secondRowName: '',
  firstRowValue: null,
  secondRowValue: null,
  firstRowPraticada: '',
  secondRowPraticada: '',
  showFirstRow: false,
  showSecondRow: false,
  width: '25%',
};

export default TableTaxa;
