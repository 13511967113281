import React from 'react';
import { useResgateContext } from 'features/carteiraFidc/contexts/resgateContext';
import { useOnboardingContext } from 'features/carteiraFidc/contexts/onboardingContext';
import MenuDropDown from 'features/common-staff/controls/menuDropDown/menuDropDown';
import MoneyIcon from 'assets/icons/money-icon';
import { useRouteMatch } from 'react-router-dom';
import OnboardingPopper from 'features/common/onboardingPopper/onboardingPopper';
import AlertCard from 'features/common/alertCard/alertCard';
import { PATH_CARTEIRA_FIDC } from 'routes/paths';
import CircleAlertYellowIcon from 'assets/icons/circle-alert-yellow';
import { Tracking } from 'modules';
import colors from 'assets/styles/colors';
import { TextColor } from 'features/common/onboardingPopper/onboardingPopper.style';
import { permissoes } from 'modules/auth';
import { getCurrentUser, hasPermission } from 'modules/auth/auth';
import { MenuPadding } from './menuResgates.style';

const MenuResgates = () => {
  const user = getCurrentUser();
  const hasPermissionHistorico = hasPermission(user, permissoes.fidc.resgatesHistorico);

  const trackingMixPanel = () => Tracking.Mixpanel.fidc.trackButtons('fidc_juros_carencia_submenu');
  const resgateContext = useResgateContext();
  const onboardingContext = useOnboardingContext();

  const { state } = resgateContext;
  const { state: stateControle, updateControleUsuario } = onboardingContext;
  const menuResgate = stateControle.onboarding.MENU_RESGATE;

  const options = [
    hasPermissionHistorico ? {
      urlRedirect: 'resgates/historico-resgates',
      title: 'Histórico de resgates',
      isActive: !!useRouteMatch(`${PATH_CARTEIRA_FIDC}/resgates/historico-resgates`),
    } : null,
    {
      urlRedirect: 'resgates/juros-carencia',
      title: 'Juros Carência',
      isActive: !!useRouteMatch(`${PATH_CARTEIRA_FIDC}/resgates/juros-carencia`),
      disabled: !state?.jurosCarencia || state.requestStatus.loading,
    },
  ].filter(Boolean);

  return (
    <>
      <OnboardingPopper
        placement="right"
        disabled={
          !state?.jurosCarencia
          || (!menuResgate?.active || stateControle.requestStatus.loading)
        }
        onClose={() => {
          Tracking.Mixpanel.fidc.trackButtons('fidc_botao_onboarding_nova_funcionalidade');
          updateControleUsuario();
        }}
        title="Nova funcionalidade: Resgates"
        message={(
          <>
            <TextColor>A aba de </TextColor>
            <TextColor color={colors.primary_color_700} fontWeight="700">Resgates </TextColor>
            <TextColor>é a mais recente adição a carteira FIDC, projetada </TextColor>
            <TextColor color={colors.primary_color_700}>
              com o intuito de centralizar as mensagens de resgate em um só lugar.
            </TextColor>
            <br />
            <TextColor> Aqui você consegue rever a mensagem, </TextColor>
            <TextColor>fazer o não aceite e se necessário uma reversão do mesmo.</TextColor>
          </>
        )}
      >
        <MenuDropDown
          options={options}
          titleDropdown="Resgates"
          isActive
          Icon={MoneyIcon}
          dataCy="MenuItemResgate"
          trackingMixPanel={trackingMixPanel}
        />
      </OnboardingPopper>
      {!state?.jurosCarencia
      && !state.requestStatus.loading
      && (
      <MenuPadding>
        <AlertCard
          icone={<CircleAlertYellowIcon width="20" height="20" />}
          title="Não há resgates disponíveis no momento."
          colorBase="rgba(255, 190, 120, 0.16)"
          width="200px"
          height="35px"
          padding="8px 14px 8px 15px"
          lineHeight="18px"
          alertCardContent="0px"
        />
      </MenuPadding>
      )}
    </>
  );
};

export default MenuResgates;
