const PREFIX = 'modalHistoricoDuplicata';

const SET_MODAL_OPEN = `${PREFIX}/SET_MODAL_OPEN`;
const RESET_STORE = `${PREFIX}/RESET_STORE`;
const GET_HISTORICO_START = `${PREFIX}/GET_HISTORICO_START`;
const GET_HISTORICO_ERROR = `${PREFIX}/GET_HISTORICO_ERROR`;
const GET_HISTORICO_SUCCESS = `${PREFIX}/GET_HISTORICO_SUCCESS`;

const setModalOpen = () => ({
  type: SET_MODAL_OPEN,
});

const resetStore = () => ({
  type: RESET_STORE,
});

const getHistoricoStart = () => ({
  type: GET_HISTORICO_START,
});

const getHistoricoError = () => ({
  type: GET_HISTORICO_ERROR,
});

const getHistoricoSucesso = (data) => ({
  type: GET_HISTORICO_SUCCESS,
  payload: { data },
});

export default {
  types: {
    RESET_STORE,
    SET_MODAL_OPEN,
    GET_HISTORICO_ERROR,
    GET_HISTORICO_START,
    GET_HISTORICO_SUCCESS,
  },

  resetStore,
  setModalOpen,
  getHistoricoError,
  getHistoricoStart,
  getHistoricoSucesso,
};
