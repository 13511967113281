import React from 'react';
import PropTypes from 'prop-types';

import './cardResumoPagamento.scss';
import { formatDate } from 'utils/format';
import Logo from '../../../../logo';
import CardPagamento from './cardPagamento';

const CardResumoPagamento = ({
  tipo, status, produto, resumo,
}) => (
  <div className="detalhe-movimentacao__card__container">
    <div className="detalhe-movimentacao__card__header">
      <div className="detalhe-movimentacao__card__header-movimentacao">
        Pagamento de duplicata
      </div>
      <div className="detalhe-movimentacao__card__header-data">
        Realizado em
        {' '}
        {formatDate(new Date(resumo?.dataTransacao), 'DD MMM YYYY')}
        {' '}
        às
        {' '}
        {formatDate(new Date(resumo?.dataTransacao), 'HH:mm')}
      </div>
    </div>

    <div
      className="detalhe-movimentacao__card__img"
      data-cy="DetalheMovimentacaoCardImg"
    >
      <Logo
        tipoOperacao={tipo != null ? tipo : ''}
        status={status != null ? status : ''}
        produto={produto != null ? produto : ''}
        isModal="true"
      />
    </div>

    <CardPagamento
      transacao={resumo?.idTransacao}
      creditoAtual={resumo?.valorPagamento}
    />
  </div>
);

CardResumoPagamento.propTypes = {
  resumo: PropTypes.object,
  tipo: PropTypes.string,
  status: PropTypes.string,
  produto: PropTypes.string,
};

CardResumoPagamento.defaultProps = {
  resumo: null,
  tipo: null,
  status: null,
  produto: null,
};

export default CardResumoPagamento;
