import { connect } from 'react-redux';

import operations from '../../redux/operations';

import AcompanhamentoPaginationFooter from './acompanhamentoPaginationFooter';

const mapStateToProps = ({ limiteCreditoStore }) => ({
  page: limiteCreditoStore.acompanhamento.pageParams.page,
  ipp: limiteCreditoStore.acompanhamento.pageParams.ipp,
  totalItems: limiteCreditoStore.acompanhamento.pageParams.totalItems,
  isLoading: limiteCreditoStore.acompanhamento.list.isLoading,
  isOpenModal: limiteCreditoStore.acompanhamento.detalhes.isOpen,
});

const mapDispatchToProps = (dispatch) => ({
  setPage: (page) => dispatch(operations.setPageParams('page', page)),
  setIpp: (ipp) => dispatch(operations.setPageParams('ipp', ipp)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AcompanhamentoPaginationFooter);
