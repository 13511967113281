import React from 'react';
import PropTypes from 'prop-types';
import './renderOptionSelector.scss';
import { CnpjFormat, DealerNumber } from 'features/common';

const RenderOptionSelector = ({ dealerGroup }) => (
  <div
    className="las__container"
    data-cy="IofDetailContainer"
  >
    <div className="las-name">
      {dealerGroup?.dealerName}
    </div>
    <div className="las-description-container">
      <CnpjFormat value={dealerGroup?.cnpj.slice(0, 8)} />
      <div className="las-item-divider" />
      <DealerNumber dealerCode={dealerGroup?.dealerId} />
    </div>
  </div>
);

RenderOptionSelector.propTypes = {
  dealerGroup: PropTypes.object,
};

RenderOptionSelector.defaultProps = {
  dealerGroup: null,
};

export default RenderOptionSelector;
