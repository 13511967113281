// import moment from 'moment';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import { Tracking } from 'modules';
import actions from './actions';
import carteiraService from './service';

const getCarteira = () => async (dispatch, getState) => {
  try {
    dispatch(actions.getInvoicesStart());
    const { filters, pageParams } = getState().gestaoPagamentos.carteira.page;
    const { selectedFPlanDealerId } = getState().dealerStore;
    const response = await carteiraService.getInvoices({
      ...filters, ...pageParams, dealerId: selectedFPlanDealerId,
    });
    const filtersParams = {
      status: response.filtroStatus,
      docs: response.filtroDocumentos,
      produto: response.filtroProdutos,
    };
    const responsePageParams = {
      page: pageParams.page,
      ipp: pageParams.ipp,
      totalItens: response?.itensTotal ?? 0,
    };
    const invoices = response.invoiceList.map((i) => ({
      ...i,
      dataCalculoEncargos: _.isNull(i.dataCalculoEncargos) ? null : new Date(i.dataCalculoEncargos),
      dataCarencia: _.isNull(i.dataCarencia) ? null : new Date(i.dataCarencia),
      dataDebito: _.isNull(i.dataDebito) ? null : new Date(i.dataDebito),
      dataEmissao: _.isNull(i.dataEmissao) ? null : new Date(i.dataEmissao),
      dataInicioCalculoJuros: _.isNull(i.dataInicioCalculoJuros)
        ? null : new Date(i.dataInicioCalculoJuros),
      dataQuitacaoIntegral: _.isNull(i.dataQuitacaoIntegral)
        ? null : new Date(i.dataQuitacaoIntegral),
      dataTransferenciaFundo: _.isNull(i.dataTransferenciaFundo)
        ? null : new Date(i.dataTransferenciaFundo),
      dataUltimoPagamento: _.isNull(i.dataUltimoPagamento) ? null : new Date(i.dataUltimoPagamento),
      dataVencimento: _.isNull(i.dataVencimento) ? null : new Date(i.dataVencimento),
      dataVencimentoVenda: _.isNull(i.dataVencimentoVenda) ? null : new Date(i.dataVencimentoVenda),
      dataVendaVeiculo: _.isNull(i.dataVendaVeiculo) ? null : new Date(i.dataVendaVeiculo),
      dataExpedicao: _.isNull(i.dataExpedicao) ? null : new Date(i.dataExpedicao),
      dataVenda: _.isNull(i.dataVenda) ? null : new Date(i.dataVenda),
    }));
    dispatch(
      actions.getInvoicesSuccess(invoices, filtersParams, responsePageParams, response.isUpdated),
    );
  } catch (error) {
    dispatch(actions.getInvoicesError());
  }
};

const setFilter = (propertyName, value) => async (dispatch) => {
  dispatch(actions.setFilters(propertyName, value));
};

const setPageParams = (propertyName, value) => async (dispatch) => {
  dispatch(actions.setPageParams(propertyName, value));
  dispatch(getCarteira());
};

const setSelectToPayment = (id) => async (dispatch) => {
  dispatch(actions.selectToPayment(id));
};

const selectAll = () => async (dispatch) => {
  dispatch(actions.selectAll());
};

const resetStore = () => async (dispatch) => {
  dispatch(actions.resetStore());
};

const removeAll = () => async (dispatch) => {
  dispatch(actions.removeAll());
};

const setSelectedAllFalse = () => async (dispatch) => {
  dispatch(actions.setSelectedAllFalse());
};

const setIsRedirect = (value) => async (dispatch) => {
  dispatch(actions.setIsRedirect(value));
};

const setExportModal = (value) => async (dispatch) => {
  dispatch(actions.setExportModal(value));
};

const downloadCarteira = (isCsv) => async (dispatch, getState) => {
  try {
    const { filters } = getState().gestaoPagamentos.carteira.page;
    Tracking.Mixpanel.carteira.pagamento.trackTypeExportXLSXButton();

    dispatch(actions.exportCarteiraStart());
    const { selectedFPlanDealerId } = getState().dealerStore;
    const body = {
      ...filters, dealerId: selectedFPlanDealerId,
    };

    const xlsxResponse = await carteiraService.downloadCarteira(body, isCsv);
    const url = window.URL.createObjectURL(new Blob([xlsxResponse.data]));
    if (isCsv) {
      saveAs(url, 'carteira_floor_plan.csv');
    } else {
      saveAs(url, 'carteira_floor_plan.xlsx');
    }
    dispatch(actions.exportCarteiraSuccess());
  } catch (error) {
    dispatch(actions.exportCarteiraError());
  }
};

const dismissSnackbar = (id) => async (dispatch) => {
  dispatch(actions.dismissSnackbars(id));
};

export default {
  setFilter,
  getCarteira,
  downloadCarteira,
  setPageParams,
  setSelectToPayment,
  selectAll,
  resetStore,
  removeAll,
  setSelectedAllFalse,
  dismissSnackbar,
  setIsRedirect,
  setExportModal,
};
