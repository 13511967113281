import React from 'react';
import PropTypes from 'prop-types';

import './toogleValor.scss';
import { Tracking } from 'modules';
import colors from 'assets/styles/colors';

const getStyle = (isPositivo) => (isPositivo ? { color: 'white', backgroundColor: colors.primary_color_600, cursor: 'default' } : { color: colors.secundary_color_600, cursor: 'pointer' });

//  TODO: [prioridade menor]
//  criar o toggle no common
const ToogleValor = ({ setIsPositivo, isPositivo }) => (
  <div className="solicitacao-page__toogle-container">
    <div
      role="button"
      tabIndex="0"
      onClick={() => {
        Tracking.Mixpanel.alterarLimite.trackType('Aumentar Limite');
        setIsPositivo(true);
      }}
      className="solicitacao-page__toogle-item"
      style={getStyle(isPositivo)}
    >
      Aumentar Limite
    </div>
    <div
      role="button"
      tabIndex="0"
      onClick={() => {
        Tracking.Mixpanel.alterarLimite.trackType('Reduzir Limite');
        setIsPositivo(false);
      }}
      className="solicitacao-page__toogle-item"
      style={getStyle(!isPositivo)}
    >
      Reduzir Limite
    </div>
  </div>
);

ToogleValor.propTypes = {
  setIsPositivo: PropTypes.func,
  isPositivo: PropTypes.bool,
};

ToogleValor.defaultProps = {
  setIsPositivo: () => {},
  isPositivo: true,
};

export default ToogleValor;
