import React from 'react';
import PropTypes from 'prop-types';

import XIcon from 'assets/icons/x';

import './backButton.scss';

const BackButton = ({
  onClick,
}) => (
  <button
    className="back-button"
    type="button"
    onClick={onClick}
  >
    <div className="back-button__content">
      <XIcon className="back-button__close-icon" />
      <div className="back-button__close-text">
        Fechar
      </div>
    </div>
  </button>
);

BackButton.propTypes = {
  onClick: PropTypes.func,
};

BackButton.defaultProps = {
  onClick: () => {},
};

export default BackButton;
