/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
import axios from 'axios';
import _ from 'lodash';
import qs from 'qs';

class AcompanhamentoPageService {
  async getSolicitacoes(filters) {
    const response = await axios.get(`${window.env.REACT_APP_API_URL}/solicitacoes/dealer`, {
      params: {
        page: filters.page,
        ipp: filters.ipp,
        dealersId: (filters.dealers || []).slice(0, 50).map((d) => d.dealerId),
      },
      paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
    });

    if (response.status === 200) {
      if (_.isArray(response?.data?.solicitacoes)) {
        response.data.solicitacoes.forEach((a) => {
          a.data = new Date(a.data);
        });
      }
      return response.data;
    }

    throw new Error('Falha ao tentar buscar as Solicitações');
  }

  async getDetalhes(id) {
    try {
      const response = await axios.get(`${window.env.REACT_APP_API_URL}/solicitacoes/${id}/detalhes`);
      return {
        ...response.data,
        data: new Date(response.data.data),
        comentarios: response.data.comentarios.map((comentario) => ({
          ...comentario,
          data: new Date(comentario.data),
        })),
      };
    } catch (error) {
      throw new Error(`Falha ao buscar detalhes da solicitação ${id}`);
    }
  }
}

export default new AcompanhamentoPageService();
