import { createSelector } from 'reselect';
import { permissoes } from '../permissoes';
import { isFidisUser } from '../auth';
import { hasSomePermission } from '../authLogic';

const getAuthorizations = createSelector(
  (state) => state.permissions,
  (permission) => {
    const perms = [];
    if (!permission) return perms;

    if (permission.visualizarDuplicatas) {
      perms.push(permissoes.duplicatas);
    }
    if (permission.visualizarDuplicatasB) {
      perms.push(permissoes.duplicatasB);
    }
    if (permission.visualizarMovimentacoes) {
      perms.push(permissoes.movimentacoes);
    }
    if (permission.duplicatasFidc) {
      perms.push(permissoes.fidc.duplicatasFidc);
    }
    if (permission.resgatesJurosCarencia) {
      perms.push(permissoes.fidc.resgatesJurosCarencia);
    }
    if (permission.resgatesHistorico) {
      perms.push(permissoes.fidc.resgatesHistorico);
    }
    if (permission.visualizarMovimentacoesB) {
      perms.push(permissoes.movimentacoesB);
    }
    if (permission.efetuarPagamento) {
      perms.push(permissoes.efetuarPagamento);
    }
    if (permission.efetuarPagamentoB) {
      perms.push(permissoes.efetuarPagamentoB);
    }
    if (permission.simularIof) {
      perms.push(permissoes.iof);
    }
    if (permission.solicitarTestDrive) {
      perms.push(permissoes.testDrive);
    }
    if (permission.alteracaoCredito) {
      perms.push(permissoes.limite.alteracaoCredito);
    }
    if (permission.transferenciaCredito) {
      perms.push(permissoes.limite.transferenciaCredito);
    }
    if (permission.liberacaoLimite) {
      perms.push(permissoes.liberacaoLimite);
    }
    if (permission.usuarioBeta) {
      perms.push(permissoes.beta);
    }
    if (permission.relatoriosFinanceiros) {
      perms.push(permissoes.relatoriosFinanceiros);
    }
    if (permission.rcAcessarRenovacao) {
      perms.push(permissoes.rcAcessarRenovacao);
    }
    if (permission.dashboardFloorPlan) {
      perms.push(permissoes.dashboardFloorPlan);
    }
    if (permission.centralComunicacao) {
      perms.push(permissoes.centralComunicacao);
    }
    if (permission.relatoriosIof) {
      perms.push(permissoes.relatoriosIof);
    }
    return perms;
  },
);

const isAuthenticated = createSelector(
  getAuthorizations,
  (authorizations) => (authorizations.length > 0),
);

const isFidis = createSelector(
  (state) => state.user,
  (user) => isFidisUser(user),
);

export const hasLimitesPermission = createSelector(
  getAuthorizations,
  (permissions) => {
    const limitesPermissions = [...Object.values(permissoes.limite)];
    return hasSomePermission(permissions, limitesPermissions);
  },
);

export const hasFidcPermission = createSelector(
  getAuthorizations,
  (permissions) => {
    const fidcPermissions = [...Object.values(permissoes.fidc)];
    return hasSomePermission(permissions, fidcPermissions);
  },
);

export const AuthSelectors = {
  // TODO: rename para getPermissions
  getAuthorizations,
  isAuthenticated,
  isFidis,
  hasLimitesPermission,
  hasFidcPermission,
};
