import * as React from "react";

function SvgSad(props) {
	return (
		<svg width="1em" height="1em" viewBox="0 0 40 41" {...props}>
			<defs>
				<linearGradient id="sad_svg__a" x1="50%" x2="50%" y1="10.25%" y2="100%">
					<stop offset="0%" stopColor="#FEEA70" />
					<stop offset="100%" stopColor="#F69B30" />
				</linearGradient>
				<linearGradient id="sad_svg__b" x1="50%" x2="50%" y1="0%" y2="100%">
					<stop offset="0%" stopColor="#472315" />
					<stop offset="100%" stopColor="#8B3A0E" />
				</linearGradient>
				<linearGradient
					id="sad_svg__c"
					x1="49.998%"
					x2="49.998%"
					y1=".001%"
					y2="100.001%"
				>
					<stop offset="0%" stopColor="#191A33" />
					<stop offset="87.2%" stopColor="#3B426A" />
				</linearGradient>
				<linearGradient
					id="sad_svg__d"
					x1="50.002%"
					x2="50.002%"
					y1=".018%"
					y2="99.981%"
				>
					<stop offset="0%" stopColor="#E78E0D" />
					<stop offset="100%" stopColor="#CB6000" />
				</linearGradient>
				<linearGradient
					id="sad_svg__e"
					x1="50%"
					x2="50%"
					y1="81.905%"
					y2="17.943%"
				>
					<stop offset="0%" stopColor="#35CAFC" />
					<stop offset="100%" stopColor="#007EDB" />
				</linearGradient>
				<linearGradient
					id="sad_svg__f"
					x1="49.983%"
					x2="49.983%"
					y1="-.014%"
					y2="99.986%"
				>
					<stop offset="0%" stopColor="#6AE1FF" stopOpacity={0.287} />
					<stop offset="100%" stopColor="#A8E3FF" stopOpacity={0.799} />
				</linearGradient>
			</defs>
			<g fill="none">
				<path
					fill="url(#sad_svg__a)"
					d="M40 20a19.998 19.998 0 01-20 20 20 20 0 1120-20"
				/>
				<path
					fill="url(#sad_svg__b)"
					d="M13.333 31.913c0 .342.235.587.625.587.877 0 2.09-1.563 6.042-1.563 3.953 0 5.168 1.563 6.043 1.563.39 0 .625-.245.625-.587 0-.993-2.098-4.413-6.668-4.413s-6.667 3.42-6.667 4.413z"
				/>
				<path
					fill="url(#sad_svg__c)"
					d="M8.997 22c0-2.025 1.272-3.665 2.835-3.665 1.567 0 2.835 1.64 2.835 3.665 0 .845-.223 1.625-.595 2.245-.167.28-.437.482-.753.562a5.93 5.93 0 01-1.487.193c-.608 0-1.133-.1-1.488-.193a1.223 1.223 0 01-.75-.562A4.348 4.348 0 018.997 22zm16.335 0c0-2.025 1.27-3.665 2.832-3.665C29.732 18.335 31 19.975 31 22c0 .845-.222 1.625-.595 2.245-.082.139-.19.26-.32.356a1.206 1.206 0 01-.432.206 5.917 5.917 0 01-2.973 0 1.24 1.24 0 01-.752-.562A4.349 4.349 0 0125.333 22z"
				/>
				<path
					fill="#000"
					d="M8.997 22c0-2.025 1.272-3.665 2.835-3.665 1.567 0 2.835 1.64 2.835 3.665 0 .845-.223 1.625-.595 2.245-.167.28-.437.482-.753.562a5.93 5.93 0 01-1.487.193c-.608 0-1.133-.1-1.488-.193a1.223 1.223 0 01-.75-.562A4.348 4.348 0 018.997 22zm16.335 0c0-2.025 1.27-3.665 2.832-3.665C29.732 18.335 31 19.975 31 22c0 .845-.222 1.625-.595 2.245-.082.139-.19.26-.32.356a1.206 1.206 0 01-.432.206 5.917 5.917 0 01-2.973 0 1.24 1.24 0 01-.752-.562A4.349 4.349 0 0125.333 22z"
				/>
				<path
					fill="#4E506A"
					d="M11.54 19.964c.32.312.34.93.042 1.377-.3.445-.8.555-1.12.24-.32-.312-.337-.93-.042-1.375.3-.447.8-.555 1.12-.242zm16.223 0c.32.312.34.93.044 1.377-.3.445-.8.555-1.124.24-.318-.312-.335-.93-.038-1.375.297-.447.797-.555 1.117-.242z"
				/>
				<path
					fill="url(#sad_svg__d)"
					d="M10.393 12.881c.83-.382 1.49-.547 2.002-.547.693 0 1.128.297 1.376.765.437.822.24 1.002-.496 1.147-2.764.56-5.542 2.355-6.747 3.475-.752.7-1.472-.075-1.09-.685.385-.61 1.935-2.762 4.955-4.155zm15.837.218c.248-.468.683-.765 1.375-.765.515 0 1.173.165 2.003.547 3.02 1.393 4.57 3.545 4.952 4.155.383.61-.335 1.385-1.087.685-1.207-1.12-3.982-2.915-6.75-3.475-.735-.145-.927-.325-.492-1.147z"
				/>
				<path
					fill="url(#sad_svg__e)"
					d="M33.75 40C31.68 40 30 38.13 30 35.822c0-2.305.89-3.862 1.608-5.367 1.494-3.145 1.79-3.58 2.142-3.58.352 0 .648.435 2.142 3.58.718 1.505 1.608 3.063 1.608 5.367C37.5 38.13 35.82 40 33.75 40z"
				/>
				<path
					fill="url(#sad_svg__f)"
					d="M33.75 34.016c-.82 0-1.484-.74-1.484-1.65 0-.915.352-1.533.637-2.13.59-1.245.708-1.415.848-1.415s.257.17.847 1.415c.285.6.638 1.215.638 2.127 0 .913-.665 1.653-1.485 1.653"
				/>
			</g>
		</svg>
	);
}

export default SvgSad;

