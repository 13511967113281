import { push } from 'react-router-redux';
import actions from './actions';
import { PATH_COMPROVANTE_PAGAMENTO } from '../../../../../routes/paths';
import paymentoService from './service';
import operationsComprovante from '../../comprovante/redux/operations';

const setDocumentosPagemento = (invoices) => async (dispatch) => {
  dispatch(actions.setDocumentosPagemento(invoices));
};

const setValue = (invoiceId, value) => async (dispatch) => {
  dispatch(actions.setValorPagamento(invoiceId, value));
};

const resetStore = () => async (dispatch) => {
  dispatch(actions.resetStore());
};

const toggleEdit = (invoiceId) => async (dispatch) => {
  dispatch(actions.toogleEdit(invoiceId));
};

const postPayment = () => async (dispatch, getState) => {
  try {
    dispatch(actions.setStartPayment());
    const { docs } = getState().gestaoPagamentos.pagamento;
    const { user } = getState().authStore;

    const response = await paymentoService.postPayment(docs, user);
    dispatch(operationsComprovante.setIdMovimentacao(response.operationRequestId));
    dispatch(push(PATH_COMPROVANTE_PAGAMENTO));
  } catch (error) {
    dispatch(actions.setPaymentError('Error ao efetuar pagamento'));
  }
};

const getStatus = () => async (dispatch) => {
  try {
    const status = await paymentoService.getStatus();
    dispatch(actions.setStatus(status));
  } catch (error) {
    dispatch(actions.setStatus(null));
  }
};

const dismissSnackbar = (id) => async (dispatch) => {
  dispatch(actions.dismissSnackbar(id));
};

export default {
  setDocumentosPagemento,
  setValue,
  resetStore,
  toggleEdit,
  postPayment,
  dismissSnackbar,
  getStatus,
};
