import React from 'react';
import PropTypes from 'prop-types';

import { formatCurrency } from 'utils/format';
import Timeline from 'features/common-staff/views/timeline';
import CardResumoMovimentacao from './cardResumo/cardResumoMovimentacao';
import BotaoComprovante from './buttonComprovante';

import './detalheEstorno.scss';

const getCreditoAtual = (resumoEstorno) => {
  if (resumoEstorno) {
    return formatCurrency(resumoEstorno.creditoRemanescente);
  }
  return null;
};

const getValorEstorno = (resumoEstorno) => {
  if (resumoEstorno) {
    return (formatCurrency(resumoEstorno.creditoOriginal));
  }
  return null;
};

const DetalheEstorno = ({
  resumoEstorno, historicoEstorno, isLoading, header,
}) => (

  <div className="detalhe-movimentacao__container">
    {!isLoading ? (
      <>
        <div className="detalhe-movimentacao__modal__detalhes">
          <CardResumoMovimentacao
            valorEstorno={getValorEstorno(resumoEstorno)}
            creditoAtual={getCreditoAtual(resumoEstorno)}
            duplicata={resumoEstorno?.duplicataNumero}
            header={header}
          />
        </div>
        <div className="detalhe-movimentacao__modal__mensagem">
          <div
            className="detalhe-movimentacao__modal__mensagem_timeline"
            data-cy="MovimentacaoModalMensagemTimeline"
          >
            <Timeline gruposComentarios={historicoEstorno} />
          </div>
        </div>
      </>
    ) : null }
    <div
      className="detalhe-movimentacao__modal__footer"
      data-cy="MovimentacaoModalBotaoComprovante"
    >
      <BotaoComprovante />
    </div>
  </div>
);

DetalheEstorno.propTypes = {
  resumoEstorno: PropTypes.object,
  historicoEstorno: PropTypes.array,
  isLoading: PropTypes.bool,
  header: PropTypes.string,
};

DetalheEstorno.defaultProps = {
  resumoEstorno: null,
  historicoEstorno: [],
  isLoading: null,
  header: '',
};

export default DetalheEstorno;
