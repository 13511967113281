/* eslint-disable class-methods-use-this */
import axios from 'axios';
import moment from 'moment';

class PagamentoStatusService {
  constructor() {
    this.URL_API = window.env.REACT_APP_API_URL;
  }

  async getStatus() {
    const response = await axios.get(`${this.URL_API}/payments/status`);
    const { status, data } = response;
    if (status !== 200 || !data) {
      throw new Error('getStatus error');
    }

    return {
      disponivel: data.disponivel,
      motivo: data.motivo,
      horaInicio: this.tryParse(data.horaInicio),
      horaFim: this.tryParse(data.horaFim),
    };
  }

  tryParse(time) {
    if (!time) { return null; }

    const res = moment(time);
    if (!res) { return null; }

    return res;
  }
}

export default new PagamentoStatusService();
