import React from 'react';
import Popper from '@mui/material/Popper';
import PropTypes from 'prop-types';

const PopperComponent = ({
  children, anchorEl, placement, x, y,
}) => (
  <Popper
    open={!!anchorEl}
    anchorEl={anchorEl}
    placement={placement}
    sx={{ zIndex: '2000' }}
    modifiers={[
      {
        name: 'offset',
        options: {
          offset: [x, y],
        },
      },
    ]}
  >
    {children}
  </Popper>
);

PopperComponent.propTypes = {
  children: PropTypes.element,
  anchorEl: PropTypes.object,
  placement: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
};

PopperComponent.defaultProps = {
  children: {},
  anchorEl: null,
  placement: 'top-end',
  x: 0,
  y: 0,
};

export default PopperComponent;
