import actions from './actions';

const INITIAL_STATE = {
  movimentacaoId: null,
  snackbars: [],
  lastSnackbarErrorId: 0,
  isLoading: false,
  isLoadingComprovantes: false,
  isError: false,
  comprovantes: null,
};

export default (state = INITIAL_STATE, action = { type: '@@comprovantePage/INIT' }) => {
  switch (action.type) {
    case actions.types.SET_ID_MOVIMENTACAO:
      return {
        ...state,
        movimentacaoId: action.payload.id,
      };
    case actions.types.action:
      return {
        ...state,
      };
    case actions.types.EXPORT_COMPROVANTE_START:
      return {
        ...state,
        isLoading: true,
      };
    case actions.types.EXPORT_COMPROVANTE_FINISH:
      return {
        ...state,
        isLoading: false,
        snackbars: [...state.snackbars, {
          id: state.lastSnackbarErrorId,
          message: 'Documento Gerado com sucesso',
          type: 'success',
        }],
        lastSnackbarErrorId: state.lastSnackbarErrorId + 1,
      };
    case actions.types.EXPORT_COMPROVANTE_ERROR:
      return {
        ...state,
        isLoading: false,
        snackbars: [...state.snackbars, {
          id: state.lastSnackbarErrorId,
          message: action.payload.error,
          type: 'error',
        }],
        lastSnackbarErrorId: state.lastSnackbarErrorId + 1,
      };
    case actions.types.DISMISS_SNACKBAR:
      return {
        ...state,
        snackbars: state.snackbars.filter((i) => i.id !== action.payload.id),
      };
    case actions.types.GET_COMPROVANTE_START:
      return {
        ...state,
        isLoadingComprovantes: true,
        isError: false,
      };
    case actions.types.GET_COMPROVANTE_ERROR:
      return {
        ...state,
        isLoadingComprovantes: false,
        isError: true,
      };
    case actions.types.GET_COMPROVANTE_SUCCESS:
      return {
        ...state,
        isLoadingComprovantes: false,
        comprovantes: action.payload.comprovante,
      };
    case actions.types.RESET_STORE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
