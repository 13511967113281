import moment from 'moment';
import * as notificationsActions from './notificationsActions';
import notificationsService from '../notificationService';

export const setStatusLeituraFirstGet = (notifications) => async (dispatch) => {
  const nlidas = notifications.data.filter((n) => n.lido === false);

  if (nlidas.length > 0) {
    const hasErrorNotifications = nlidas.filter(
      (n) => n.detalheTipo.includes('reprovado') || n.descricao.includes('reprovado') || n.descricao.includes('não efetivado'),
    );
    const hasSuccessNotifications = nlidas.filter(
      (n) => n.detalheTipo.includes('aprovado') || n.descricao.includes('aprovado') || n.descricao.includes('efetivado'),
    );
    if (hasSuccessNotifications.length > 0) {
      await dispatch(notificationsActions.setHasSuccessNotifications(true));
    }
    if (hasErrorNotifications.length > 0) {
      await dispatch(notificationsActions.setHasErrorNotifications(true));
    }
  } else {
    await dispatch(notificationsActions.setHasErrorNotifications(false));
    await dispatch(notificationsActions.setHasSuccessNotifications(false));
  }
};

export const getNotifications = (dealerGroups) => async (dispatch) => {
  try {
    await dispatch(notificationsActions.initNotificationsRequest());
    const notifications = await notificationsService.getNotifications(dealerGroups);
    await dispatch(notificationsActions.setNotifications(
      notifications.data,
    ));
    await dispatch(setStatusLeituraFirstGet(notifications));
    dispatch(notificationsActions.finishNotificationsRequest(false, moment().format()));
  } catch (error) {
    await dispatch(notificationsActions.setNotifications([]));
    dispatch(notificationsActions.finishNotificationsRequest(true));
  }
};

export const setStatusLeituraLastGet = (notifications) => async (dispatch) => {
  const nlidas = notifications.data.filter((n) => n.lido === false);

  if (nlidas.length > 0) {
    const hasErrorNotifications = nlidas.filter((n) => n.detalheTipo === 'Pagamento reprovado');
    const hasSuccessNotifications = nlidas.filter((n) => n.detalheTipo === 'Pagamento aprovado');
    if (hasSuccessNotifications.length > 0) {
      await dispatch(notificationsActions.setHasSuccessNotifications(true));
    }
    if (hasErrorNotifications.length > 0) {
      await dispatch(notificationsActions.setHasErrorNotifications(true));
    }
  }
};

export const getNotificationsLastGet = (dealerGroups, lastGet) => async (dispatch) => {
  try {
    await dispatch(notificationsActions.initNotificationsRequestLastGet());
    const notifications = await notificationsService.getNotifications(dealerGroups, lastGet);
    await dispatch(notificationsActions.concatNotifications(
      notifications.data,
    ));
    await dispatch(setStatusLeituraLastGet(notifications));
    dispatch(notificationsActions.finishNotificationsRequestLastGetSuccess(moment().format()));
  } catch (error) {
    dispatch(notificationsActions.finishNotificationsRequestLastGetError());
  }
};

export const markNotificationsRead = (notifications) => async (dispatch) => {
  try {
    await dispatch(notificationsActions.initNotificationsPost());
    await notificationsService.markNotificationsRead(notifications);
    dispatch(notificationsActions.finishNotificationsPost(false));
  } catch (error) {
    dispatch(notificationsActions.finishNotificationsPost(true));
  }
};
