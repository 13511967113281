import { createSelector } from 'reselect';

const getListBrands = createSelector(
  (state) => state.dealers,
  (dealers) => {
    const brands = [];
    if (dealers.find((d) => d.brand === 'FIAT')) {
      brands.push('FIAT');
    }

    if (
      dealers.find(
        (d) => d.brand === 'CHRYSLER'
            || d.brand === 'JEEP'
            || d.brand === 'DODGE'
            || d.brand === 'RAM',
      )
    ) {
      brands.push('CJDR');
    }

    return brands;
  },
);

const isBrand = createSelector(
  (state) => state.dealers,
  (dealers) => ({
    FIAT: !!dealers.find((d) => d.brand === 'FIAT'),
    CJDR: !!dealers.find((d) => d.brand === 'CHRYSLER' || d.brand === 'JEEP' || d.brand === 'DODGE' || d.brand === 'RAM'),
  }),
);

export default {
  getListBrands,
  isBrand,
};
