import { connect } from 'react-redux';
import { AuthSelectors } from '../../modules/auth/redux';

import PublicRoute from './publicRoute';

const mapStateToProps = ({ authStore }) => ({
  isAuthenticated: AuthSelectors.isAuthenticated(authStore),
});

export default connect(mapStateToProps, null)(PublicRoute);
