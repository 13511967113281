import { connect } from 'react-redux';

import Timeline from './timeline';

const mapStateToProps = ({ testDrive }) => ({
  timeline: testDrive.acompanhamento.detalhe.modal?.detalhePedido?.timeline,
  isLoading: testDrive.acompanhamento.detalhe.modal.isLoading,
});

const mapDispatchToProps = () => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Timeline);
