import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '../../../../assets/icons/check-circle';
import WarningRedIcon from '../../../../assets/icons/warning-red';

import Button from '../../../common/button';

import './paymentConfirmationModal.scss';

const styles = () => ({
  paper: { borderRadius: '11px' },
});

const PaymentConfirmationModal = (
  {
    className, open, onCloseClick, classes, type, onClickRefresh,
  },
) => (
  <Dialog
    className={`feedback-dialog ${className}`}
    open={open}
    classes={classes}
  >
    {type === 'success' ? (
      <div>
        <div
          className="payment-confirmation-image-container"
        >
          <CheckCircleIcon className="payment-confirmation-image" />
        </div>
        <div className="payment-confirmation-span">
          <span>
            Pagamento em processamento.
          </span>
        </div>

        <div className="payment-confirmation-button-container">
          <Button
            className="payment-confirmation-button"
            onClick={onCloseClick}
          >
            Ok
          </Button>
        </div>
      </div>
    ) : null}

    {type === 'error' ? (
      <div>
        <div
          className="payment-confirmation-image-container"
        >
          <WarningRedIcon className="payment-confirmation-image" />
        </div>
        <div className="payment-confirmation-span">
          <div>
            <div className="session-modal-line-block">
              <span className="session-modal-line-smaller">Ocorreu um erro ao enviar sua solicitação.</span>
            </div>
            <span className="payment-modal-line-bigger">Favor confirmar as informações e enviar novamente.</span>
          </div>
        </div>

        <div className="payment-confirmation-button-container-error">
          <Button
            className="payment-confirmation-button-refresh"
            onClick={onCloseClick}
          >
            Tentar Novamente
          </Button>
          <Button
            className="payment-confirmation-button-error"
            onClick={onClickRefresh}
            white
          >
            Voltar
          </Button>

        </div>
      </div>
    ) : null}

  </Dialog>
);

PaymentConfirmationModal.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onClickRefresh: PropTypes.func,
  open: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

PaymentConfirmationModal.defaultProps = {
  className: '',
  open: false,
  onClickRefresh: null,
};

export default withStyles(styles)(PaymentConfirmationModal);
