import React from 'react';
import { WarningRounded } from '@material-ui/icons';
import './alertaPagamento.scss';

const styleForIcon = {
  fontSize: '24px',
  color: '#555770',
};

const AlertaPagamento = () => (
  <div className="comprovante__modal__alert_pagamentos_rejeitados">
    <div className="comprovante__modal__alert_pagamentos_rejeitados-title">
      Um ou mais pagamentos foram rejeitados
    </div>
    <div className="comprovante__modal__alert_pagamentos_rejeitados-message">
      Isso pode ocorrer devido a duplicata de destino ter sofrido outro
      pagamento e a tentativa de pagamento ser superior ao saldo da duplicata.
      Aguarde alguns minutos e tente novamente.
    </div>
    <div className="comprovante__modal__alert_pagamentos_icon">
      <WarningRounded style={styleForIcon} />
    </div>
  </div>
);

export default AlertaPagamento;
