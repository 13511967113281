export const SET_OFFERS = 'testDrive/solicitacao/SET_OFFERS';
export const SET_SELECTED_OFFER = 'testDrive/solicitacao/SET_SELECTED_OFFER';
export const SET_OFFERS_LOADING = 'testDrive/solicitacao/SET_OFFERS_LOADING';
export const SET_OFFERS_LOAD_ERROR = 'testDrive/solicitacao/SET_OFFERS_LOAD_ERROR';

export const SET_OPEN_MODAL_UPLOAD = 'testDrive/solicitacao/SET_OPEN_MODAL_UPLOAD';
export const DELETE_SELECTED_FILE = 'testDrive/solicitacao/DELETE_SELECTED_FILE';
export const DELETE_FILES_TO_SEND = 'testDrive/solicitacao/DELETE_FILES_TO_SEND';
export const CLEAR_SELECTED_FILES = 'testDrive/solicitacao/CLEAR_SELECTED_FILES';
export const SET_FILE_UPLOAD_PROGRESS = 'testDrive/solicitacao/SET_FILE_UPLOAD_PROGRESS';
export const SET_FILE_UPLOAD_ERROR = 'testDrive/solicitacao/SET_FILE_UPLOAD_ERROR';
export const UPDATE_FILE_INFO = 'testDrive/solicitacao/UPDATE_FILE_INFO';
export const SEND_FILES_TO_FORM = 'testDrive/solicitacao/SEND_FILES_TO_FORM';
export const SET_FILES_UPLOADING = 'testDrive/solicitacao/SET_FILES_UPLOADING';

export const ADD_NEW_FILE = 'testDrive/solicitacao/ADD_NEW_FILE';

export const SET_CONDITIONS = 'testDrive/solicitacao/SET_CONDITIONS';
export const SET_SELECTED_CONDITION = 'testDrive/solicitacao/SET_SELECTED_CONDITION';
export const SET_CONDITIONS_LOADING = 'testDrive/solicitacao/SET_CONDITIONS_LOADING';
export const SET_CONDITIONS_LOAD_ERROR = 'testDrive/solicitacao/SET_CONDITIONS_LOAD_ERROR';

export const SET_MOTIVOS_TEST_DRIVE_EXCECAO = 'testDrive/solicitacao/SET_MOTIVOS_TEST_DRIVE_EXCECAO';

export const SET_SELECTED_DEALER_ID = 'testDrive/solicitacao/SET_SELECTED_DEALER_ID';
export const SET_INDUSTRIAL_ORDER = 'testDrive/solicitacao/SET_INDUSTRIAL_ORDER';
export const SET_CHASSIS = 'testDrive/solicitacao/SET_CHASSIS';
export const SET_COLOR = 'testDrive/solicitacao/SET_COLOR';
export const SET_OBSERVATION = 'testDrive/solicitacao/SET_OBSERVATION';
export const SET_SELECTED_MOTIVO = 'testDrive/solicitacao/SET_SELECTED_MOTIVO';

export const SET_IS_SUBMITTING = 'testDrive/solicitacao/SET_IS_SUBMITTING';
export const SET_SUBMIT_ERROR = 'testDrive/solicitacao/SET_SUBMIT_ERROR';
export const SET_SUBMIT_SUCCESS = 'testDrive/solicitacao/SET_SUBMIT_SUCCESS';

export const SET_SIMULATION_BASE_VALUE = 'testDrive/solicitacao/SET_SIMULATION_BASE_VALUE';

export const CLEAR_FORM = 'testDrive/solicitacao/CLEAR_FORM';

export const setOffers = (offers) => ({
  type: SET_OFFERS,
  payload: offers,
});

export const setSelectedOffer = (id) => ({
  type: SET_SELECTED_OFFER,
  payload: id,
});

export const setOffersLoading = (isLoading) => ({
  type: SET_OFFERS_LOADING,
  payload: isLoading,
});

export const setShowOffersLoadError = (showError) => ({
  type: SET_OFFERS_LOAD_ERROR,
  payload: showError,
});

export const setConditions = (conditions) => ({
  type: SET_CONDITIONS,
  payload: conditions,
});

export const setSelectedCondition = (conditionId) => ({
  type: SET_SELECTED_CONDITION,
  payload: conditionId,
});

export const setConditionsLoading = (isLoading) => ({
  type: SET_CONDITIONS_LOADING,
  payload: isLoading,
});

export const setShowConditionsLoadError = (showError) => ({
  type: SET_CONDITIONS_LOAD_ERROR,
  payload: showError,
});

export const setSelectedDealerId = (id) => ({
  type: SET_SELECTED_DEALER_ID,
  payload: id,
});

export const setIndustrialOrder = (order) => ({
  type: SET_INDUSTRIAL_ORDER,
  payload: order,
});

export const setChassis = (chassis) => ({
  type: SET_CHASSIS,
  payload: chassis,
});

export const setColor = (color) => ({
  type: SET_COLOR,
  payload: color,
});

export const setObservation = (observation) => ({
  type: SET_OBSERVATION,
  payload: observation,
});

export const setIsSubmitting = (isSubmitting) => ({
  type: SET_IS_SUBMITTING,
  payload: isSubmitting,
});

export const setShowSubmitError = (showError) => ({
  type: SET_SUBMIT_ERROR,
  payload: showError,
});

export const setShowSubmitSuccess = (showSuccess) => ({
  type: SET_SUBMIT_SUCCESS,
  payload: showSuccess,
});

export const setSimulationBaseValue = (value) => ({
  type: SET_SIMULATION_BASE_VALUE,
  payload: value,
});

export const setMotivosTestDriveExcecao = (motivos) => ({
  type: SET_MOTIVOS_TEST_DRIVE_EXCECAO,
  payload: motivos,
});

export const setSelectedMotivo = (motivo) => ({
  type: SET_SELECTED_MOTIVO,
  payload: motivo,
});

export const setModalUpload = (state) => ({
  type: SET_OPEN_MODAL_UPLOAD,
  payload: state,
});

export const deleteSelectedFile = (fileName) => ({
  type: DELETE_SELECTED_FILE,
  payload: fileName,
});

export const deleteFilesToSend = (fileName) => ({
  type: DELETE_FILES_TO_SEND,
  payload: fileName,
});

export const clearSelectedFiles = () => ({
  type: CLEAR_SELECTED_FILES,
});

export const setFileUploadProgress = (fileName, value) => ({
  type: SET_FILE_UPLOAD_PROGRESS,
  payload: { fileName, value },
});

export const setFileUploadError = (fileName) => ({
  type: SET_FILE_UPLOAD_ERROR,
  payload: fileName,
});

export const updateFileInfo = (fileName, fileInfo, progress) => ({
  type: UPDATE_FILE_INFO,
  payload: { fileName, fileInfo, progress },
});

export const sendFilestoForm = () => ({
  type: SEND_FILES_TO_FORM,
});

export const setFileUploading = (value) => ({
  type: SET_FILES_UPLOADING,
  payload: value,
});

export const addNewFile = (file) => ({
  type: ADD_NEW_FILE,
  payload: file,
});

export const clearForm = () => ({
  type: CLEAR_FORM,
});
