import { createSelector } from 'reselect';

const isModalPaymentOpen = createSelector(
  (state) => state.carteira.modal.modalPayment?.data,
  (invoices) => invoices.length > 0,
);

const getTotalValue = createSelector(
  (state) => state.carteira.modal.modalPayment?.data,
  (invoices) => invoices.reduce((a, b) => a + b.saldoDevedor, 0),
);

const getCompanies = createSelector(
  (state) => state?.user?.companies,
  (companies) => (companies || []).map((c) => c.companyId),
);

export default {
  isModalPaymentOpen,
  getTotalValue,
  getCompanies,
};
