import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import InputAdornment from '@material-ui/core/InputAdornment';
import { Search as SearchIcon } from '@material-ui/icons';
import XIcon from '../../../assets/icons/x';

import TextFieldHub from '../textFieldHub';

const ENTER_KEY_CODE = 13;

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      color: 'rgba(0,0,0,0,5)',

      '& fieldset': {
        borderColor: '#00B1AE',
        borderRadius: 10,
        color: '#00B1AE',
      },
    },
    '& .MuiFormLabel-root': {
      '&.Mui-focused': {
        color: '#00B1AE',
      },
    },
  },
  input: {
    minWidth: '120px',

    '&::placeholder': {
      fontSize: 13,
    },
  },
});

const SearchInput = (
  {
    className, onSearch, isSearchActive, value, onChange,
  },
) => {
  const [lastSearch, setLastSearch] = useState('');
  const classes = useStyles();

  const onClear = () => {
    onChange('');
    onSearch('');
    setLastSearch('');
  };

  return (
    <div className={className}>
      <TextFieldHub
        value={value || ''}
        onBlur={() => {
          if (lastSearch !== value) {
            onSearch(value);
            setLastSearch(value);
          }
        }}
        placeholder="Buscar duplicata ou chassi"
        classes={isSearchActive && lastSearch === value ? classes : null}
        onKeyDown={(e) => {
          if (e.keyCode === ENTER_KEY_CODE) {
            onSearch(e.target.value);
            setLastSearch(e.target.value);
          }
        }}
        onChange={(e) => onChange(e.target.value)}
        label="Buscar"
        InputProps={{
          endAdornment: isSearchActive && lastSearch === value ? (
            <InputAdornment
              position="end"
              onClick={() => onClear()}
              style={{
                cursor: 'pointer',
              }}
            >
              <XIcon />
            </InputAdornment>
          ) : (
            <InputAdornment
              position="end"
              onClick={() => {
                onSearch(value);
                setLastSearch(value);
              }}
              style={{
                cursor: 'pointer',
              }}
            >
              <SearchIcon />
            </InputAdornment>
          ),
          classes: { input: classes.input },
        }}
      />
    </div>
  );
};

SearchInput.propTypes = {
  className: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  isSearchActive: PropTypes.bool.isRequired,
};

SearchInput.defaultProps = {
  className: '',
};

export default SearchInput;
