import * as React from "react";

function ConnectionErrorIcon(props) {
	return (
	  <svg xmlns="http://www.w3.org/2000/svg" width="188" height="211" viewBox="0 0 188 211" fill="none">
        <g clip-path="url(#clip0_248_986)">
        <path d="M106.579 162.579C151.546 162.579 188 126.184 188 81.2895C188 36.3946 151.546 0 106.579 0C61.6107 0 25.157 36.3946 25.157 81.2895C25.157 126.184 61.6107 162.579 106.579 162.579Z" fill="#D2D9F3" fill-opacity="0.16"/>
        <path d="M159.786 36.3525H10.633C10.3132 36.3457 10.0595 36.0813 10.0663 35.762C10.0729 35.4523 10.3228 35.2028 10.633 35.1962H159.786C160.106 35.203 160.36 35.4674 160.353 35.7867C160.346 36.0964 160.096 36.3459 159.786 36.3525Z" fill="#CACED7"/>
        <path d="M108.955 55.8378H67.7233C64.6263 55.8378 62.1157 58.3443 62.1157 61.4362V61.4363C62.1157 64.5282 64.6263 67.0347 67.7233 67.0347H108.955C112.052 67.0347 114.563 64.5282 114.563 61.4362C114.563 58.3443 112.052 55.8378 108.955 55.8378Z" fill="#CACED7"/>
        <path d="M41.0873 78.2317H135.591C138.688 78.2317 141.199 80.7382 141.199 83.8302C141.199 86.9221 138.688 89.4286 135.591 89.4286H41.0873C37.9903 89.4286 35.4797 86.9221 35.4797 83.8302C35.4797 80.7382 37.9903 78.2317 41.0873 78.2317Z" fill="#CACED7"/>
        <path d="M49.1585 90.2721C49.2913 90.121 49.4704 89.3521 49.5766 87.0502H53.8282L55.7769 91.5307L49.1585 90.2721C49.114 90.3227 49.0748 90.304 49.0452 90.2505L49.1585 90.2721Z" fill="#A0616A"/>
        <path d="M49.5766 87.0502C49.4349 90.1225 49.1633 90.4639 49.0452 90.2505L55.7769 91.5307L53.8282 87.0502H49.5766Z" stroke="#A0616A"/>
        <path d="M41.0873 100.626H135.591C138.688 100.626 141.199 103.132 141.199 106.224C141.199 109.316 138.688 111.823 135.591 111.823H41.0873C37.9903 111.823 35.4797 109.316 35.4797 106.224C35.4797 103.132 37.9903 100.626 41.0873 100.626Z" fill="#CACED7"/>
        <path d="M29.3761 210.508L29.1537 210.486C21.6076 209.709 14.7859 205.657 10.501 199.407C9.3273 197.665 8.37794 195.783 7.67527 193.805L7.60773 193.616L7.80768 193.6C10.1949 193.408 12.6472 193.004 13.7367 192.811L6.71173 190.435L6.6683 190.227C6.25181 188.273 7.06775 186.263 8.729 185.15C10.3947 183.984 12.6037 183.947 14.308 185.056C15.0728 185.544 15.8728 186.027 16.6464 186.494C19.3049 188.1 22.0538 189.76 24.1149 192.031C27.2401 195.545 28.61 200.279 27.8429 204.915L29.3761 210.508Z" fill="#F2F2F2"/>
        <path d="M54.9116 207.02L58.3736 207.019L60.021 193.686L54.9106 193.686L54.9116 207.02Z" fill="#A0616A"/>
        <path d="M54.7895 210.626L65.4366 210.625V210.491C65.4364 208.206 63.5811 206.354 61.2924 206.354L59.3473 204.88L55.7187 206.354L54.7893 206.354L54.7895 210.626Z" fill="#2F2E41"/>
        <path d="M27.1643 197.445L29.9336 199.52L39.266 189.841L35.1782 186.78L27.1643 197.445Z" fill="#A0616A"/>
        <path d="M24.8992 200.257L33.4161 206.636L33.4969 206.528C34.8702 204.701 34.4996 202.107 32.6689 200.736L31.9985 198.392L28.2104 197.397L27.4669 196.84L24.8992 200.257Z" fill="#2F2E41"/>
        <path d="M38.6735 144.45C38.6735 144.45 38.8646 149.655 39.1057 153.829C39.1452 154.514 37.5387 155.258 37.5803 156.002C37.6079 156.496 37.9099 156.977 37.9379 157.492C37.9675 158.036 37.5506 158.517 37.5803 159.079C37.6093 159.629 38.085 160.259 38.1137 160.821C38.4224 166.873 39.5416 174.117 38.4209 176.143C37.8686 177.141 30.8284 190.968 30.8284 190.968C30.8284 190.968 34.7509 196.843 35.8716 194.884C37.3519 192.298 48.76 180.199 48.76 177.888C48.76 175.58 51.3993 158.285 51.3993 158.285L53.2396 168.52L54.0835 170.548L53.8183 171.739L54.3637 173.345L54.3931 174.935L54.924 177.888C54.924 177.888 53.327 200.893 54.2264 201.534C55.1286 202.177 59.4966 203.332 60.0121 202.177C60.5249 201.022 63.8623 178.168 63.8623 178.168C63.8623 178.168 64.4002 167.695 64.983 157.96C65.0166 157.395 65.4145 156.713 65.4453 156.156C65.4818 155.51 65.2296 154.671 65.2632 154.044C65.2996 153.325 65.619 152.819 65.6498 152.131C65.888 146.813 64.5511 140.338 64.1252 139.7C62.8392 137.775 61.682 136.237 61.682 136.237C61.682 136.237 44.3605 130.555 39.7319 136.329L38.6735 144.45Z" fill="#243782"/>
        <path d="M55.9493 90.9238L49.0826 89.5527L45.8782 93.8945C39.899 99.7642 39.8089 104.945 40.8763 113.248V124.903L40.1038 132.715C40.1038 132.715 37.3689 138.427 40.1896 139.756C43.0104 141.086 61.5383 140.973 63.3073 140.442C65.0762 139.911 63.5429 139.259 63.0784 136.786C62.2058 132.139 62.7895 134.38 62.8495 132.901C63.7105 111.678 61.7051 102.965 61.5014 100.675L58.0093 94.3516L55.9493 90.9238Z" fill="#3C414E"/>
        <path d="M92.1406 108.035C91.4249 109.389 89.7456 109.907 88.3896 109.193C88.245 109.117 88.1074 109.028 87.9785 108.928L73.1494 118.328L73.1922 113.21L87.5975 104.864C88.6236 103.715 90.388 103.615 91.5384 104.639C92.5011 105.496 92.75 106.9 92.1406 108.035Z" fill="#A0616A"/>
        <path d="M50.5277 96.8538L48.8395 96.6752C47.2795 96.5137 45.749 97.1807 44.807 98.4325C44.4463 98.9073 44.1871 99.4511 44.0457 100.03L44.0447 100.035C43.6212 101.777 44.2855 103.604 45.7302 104.669L51.5613 108.961C55.6533 114.482 63.3438 118.144 72.3649 121.119L86.387 112.425L81.4273 106.428L71.8095 111.574L57.5132 100.525L57.505 100.519L52.0638 97.0187L50.5277 96.8538Z" fill="#3C414E"/>
        <path d="M53.6293 87.5574C58.0517 87.5574 61.6367 83.9782 61.6367 79.563C61.6367 75.1477 58.0517 71.5685 53.6293 71.5685C49.2069 71.5685 45.6218 75.1477 45.6218 79.563C45.6218 83.9782 49.2069 87.5574 53.6293 87.5574Z" fill="#A0616A"/>
        <path d="M53.8141 87.5592C53.7496 87.5613 53.6849 87.5628 53.6203 87.5645C53.5926 87.6362 53.5628 87.7072 53.5283 87.7769L53.8141 87.5592Z" fill="#2F2E41"/>
        <path d="M56.0117 79.7848C56.0243 79.8636 56.0435 79.9411 56.0691 80.0167C56.0586 79.9375 56.0393 79.8598 56.0117 79.7848Z" fill="#2F2E41"/>
        <path d="M61.739 71.9106C61.4094 72.9323 61.1152 71.6362 60.031 71.8694C58.7267 72.1498 57.2089 72.0535 56.1407 71.2553C54.5492 70.0895 52.4868 69.7756 50.6197 70.4152C48.792 71.0607 45.7301 71.5159 45.2538 73.3921C45.0883 74.0443 45.0226 74.752 44.6213 75.2925C44.2705 75.7651 43.7168 76.0345 43.2538 76.3982C41.6903 77.6264 42.8853 81.1155 43.6253 82.9592C44.3652 84.8028 46.0626 86.1458 47.9288 86.8338C49.7345 87.4994 51.6942 87.6168 53.6204 87.5645C53.9554 86.697 53.8107 85.695 53.5343 84.7957C53.235 83.8222 52.7919 82.8826 52.6669 81.8719C52.5418 80.8612 52.8164 79.7131 53.6652 79.1484C54.4454 78.6293 55.7151 78.9455 56.0119 79.7848C55.8387 78.7356 56.9033 77.7202 58.001 77.5417C59.1795 77.35 60.358 77.7748 61.5332 77.9862C62.7083 78.1977 62.2912 73.2583 61.739 71.9106Z" fill="#2F2E41"/>
        <path d="M106.523 92.7875C107.269 83.7828 100.562 75.8788 91.5431 75.1335C82.5237 74.3883 74.6069 81.0839 73.8604 90.0887C73.1139 99.0935 79.8205 106.997 88.8399 107.743C97.8593 108.488 105.776 101.792 106.523 92.7875Z" fill="#C31E10"/>
        <path d="M94.4961 84.6212L90.1916 88.9188L85.887 84.6212C85.1902 83.9256 84.0605 83.9256 83.3637 84.6212C82.6669 85.3169 82.6669 86.4448 83.3637 87.1405L87.6682 91.4381L83.3637 95.7356C82.6675 96.4319 82.6686 97.5598 83.366 98.2549C84.0626 98.949 85.1905 98.949 85.887 98.2549L90.1916 93.9573L94.4961 98.2549C95.1935 98.9499 96.3233 98.9488 97.0194 98.2525C97.7147 97.5571 97.7147 96.431 97.0194 95.7356L92.7149 91.4381L97.0194 87.1405C97.7162 86.4448 97.7162 85.3169 97.0194 84.6212C96.3226 83.9256 95.1929 83.9256 94.4961 84.6212Z" fill="white"/>
        <path d="M83.7339 77.6872C84.217 79.14 83.4289 80.7086 81.9738 81.1909C81.8186 81.2423 81.6592 81.2799 81.4975 81.3034L78.0257 98.4948L74.356 94.9207L78.2965 78.7645C78.1767 77.2301 79.3255 75.8893 80.8624 75.7697C82.1483 75.6697 83.3355 76.4624 83.7339 77.6872Z" fill="#A0616A"/>
        <path d="M46.8415 99.9237L45.5436 101.016C44.3469 102.028 43.7694 103.593 44.0221 105.138C44.1154 105.727 44.3291 106.29 44.6497 106.792L44.6523 106.796C45.6187 108.308 47.3986 109.094 49.1689 108.791L56.308 107.561C63.1309 108.435 71.1022 105.43 79.498 100.991L82.9215 84.8694L75.1534 84.2908L72.2145 94.7825L54.3312 97.4329L54.3207 97.4342L48.024 98.9312L46.8415 99.9237Z" fill="#3C414E"/>
        <path d="M87.4919 210.902L0.380449 211C0.169654 210.999 -0.000680742 210.828 2.04556e-06 210.618C0.000678422 210.408 0.170612 210.239 0.380449 210.238L87.4919 210.14C87.7027 210.14 87.873 210.311 87.8723 210.522C87.8717 210.731 87.7017 210.901 87.4919 210.902Z" fill="#3C414E"/>
        <path d="M18.2003 29.0285C19.6975 29.0285 20.9112 27.7898 20.9112 26.2617C20.9112 24.7337 19.6975 23.495 18.2003 23.495C16.7032 23.495 15.4895 24.7337 15.4895 26.2617C15.4895 27.7898 16.7032 29.0285 18.2003 29.0285Z" fill="#243782"/>
        <path d="M27.5651 29.0285C29.0622 29.0285 30.2759 27.7898 30.2759 26.2617C30.2759 24.7337 29.0622 23.495 27.5651 23.495C26.0679 23.495 24.8542 24.7337 24.8542 26.2617C24.8542 27.7898 26.0679 29.0285 27.5651 29.0285Z" fill="#243782"/>
        <path d="M36.9296 29.0285C38.4267 29.0285 39.6404 27.7898 39.6404 26.2617C39.6404 24.7337 38.4267 23.495 36.9296 23.495C35.4324 23.495 34.2188 24.7337 34.2188 26.2617C34.2188 27.7898 35.4324 29.0285 36.9296 29.0285Z" fill="#243782"/>
        <path d="M47.608 28.4485C47.5247 28.4485 47.4416 28.4163 47.3788 28.3523L45.5497 26.4855C45.4278 26.3611 45.4278 26.1623 45.5497 26.0379L47.3788 24.1713C47.5029 24.045 47.7061 24.043 47.8321 24.1663C47.9587 24.2899 47.9609 24.4926 47.8371 24.6189L46.2273 26.2617L47.8371 27.9047C47.9609 28.031 47.9587 28.2337 47.8321 28.3573C47.7698 28.4182 47.6889 28.4485 47.608 28.4485Z" fill="#243782"/>
        <path d="M50.6814 28.4485C50.6005 28.4485 50.5196 28.4182 50.4573 28.3572C50.3306 28.2336 50.3285 28.0311 50.4523 27.9047L52.0619 26.2617L50.4523 24.6188C50.3285 24.4926 50.3307 24.2899 50.4573 24.1663C50.5836 24.0425 50.7868 24.0449 50.9106 24.1713L52.7395 26.0379C52.8615 26.1623 52.8615 26.3611 52.7395 26.4855L50.9106 28.3522C50.8478 28.4163 50.7647 28.4485 50.6814 28.4485Z" fill="#243782"/>
        <path d="M146.371 23.8153H142.97C142.582 23.8153 142.268 24.1289 142.268 24.5162V27.915C142.268 28.3022 142.582 28.6159 142.97 28.6159H146.371C146.759 28.6159 147.076 28.3022 147.076 27.915V24.5162C147.076 24.1289 146.759 23.8153 146.371 23.8153Z" fill="#243782"/>
        <path d="M138.037 23.8153H134.636C134.248 23.8153 133.934 24.1289 133.934 24.5162V27.915C133.934 28.3022 134.248 28.6159 134.636 28.6159H138.037C138.425 28.6159 138.742 28.3022 138.742 27.915V24.5162C138.742 24.1289 138.425 23.8153 138.037 23.8153Z" fill="#243782"/>
        <path d="M154.225 23.9753H150.824C150.436 23.9753 150.122 24.289 150.122 24.6762V28.075C150.122 28.4623 150.436 28.7759 150.824 28.7759H154.225C154.613 28.7759 154.93 28.4623 154.93 28.075V24.6762C154.93 24.289 154.613 23.9753 154.225 23.9753Z" fill="#243782"/>
        <path d="M102.961 25.2683H75.7741C75.2997 25.2683 74.9182 25.6524 74.9182 26.1228C74.9182 26.5933 75.2997 26.9773 75.7741 26.9773H102.961C103.432 26.9773 103.816 26.5933 103.816 26.1228C103.816 25.6524 103.432 25.2683 102.961 25.2683Z" fill="#243782"/>
        </g>
        <defs>
          <clipPath id="clip0_248_986">
            <rect width="188" height="211" fill="white"/>
          </clipPath>
        </defs>
      </svg>
	);
}

export default ConnectionErrorIcon;

