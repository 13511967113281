import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';
import { FormControl } from '@material-ui/core';
import './limitesAprovadosSelector.scss';
import { TextFieldHub } from 'features/common';
import colors from 'assets/styles/colors';
import RenderOptionSelector from './renderOption/renderOptionSelector';

const styles = {
  root: {
    '& .MuiFormControl-root': {
      display: 'block',
      verticalAlign: 'middle',
      height: 48,
      marginLeft: '-16px',
      borderColor: 'red',
    },
  },
  input: { color: '#555770' },

  formcontrol: {
    display: 'block',
  },

  paper: {
    borderRadius: '10px',
    width: '208',
    boxShadow: '0 5px 10px 0 rgba(138, 149, 158, 0.2)',
  },

  option: {
    padding: '0px 0px 0px 0px',
    '&[data-focus="true"]': { backgroundColor: colors.primary_color_100_36 },
    '&:hover': {
      backgroundColor: colors.secundary_color_100_36,
    },
    '&[aria-selected="true"]': {
      backgroundColor: colors.primary_color_100_36,
    },
  },
};

const LimitesAprovadosSelector = ({
  classes, setNewDealer, items, selectedItem,
}) => {
  const onSelectChange = (_event, newDealerGroup) => {
    setNewDealer(newDealerGroup);
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => (option?.dealerId || '') + (option?.shortName || ''),
  });

  return (
    <div className="las-selector">
      <div className="las-area">
        <FormControl
          classes={{
            // eslint-disable-next-line react/destructuring-assignment
            root: classes.formcontrol,
          }}
        >
          <div className="las-select">
            <Autocomplete
              options={items}
              onChange={onSelectChange}
              noOptionsText="Nenhuma concessionária encontrada"
              disableClearable
              value={(items.find((gp) => gp.dealerId === selectedItem?.dealerId) || null)}
              getOptionLabel={(option) => `${option.dealerName} - ${option?.cnpj.slice(0, 8)}  •  ${option?.dealerId.toString().slice(0, 5)}-${option?.dealerId.toString().slice(5)}`}
              key={selectedItem}
              filterOptions={filterOptions}
              classes={{
                root: classes.root,
                input: classes.input,
                paper: classes.paper,
                option: classes.option,
              }}
              renderOption={(option) => (
                <RenderOptionSelector
                  dealerGroup={option}
                />
              )}
              renderInput={(params) => (
                <TextFieldHub
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  label="Matriz"
                  fullWidth
                />
              )}
            />
          </div>
        </FormControl>
      </div>
    </div>
  );
};

LimitesAprovadosSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedItem: PropTypes.object,
  className: PropTypes.string,
  setNewDealer: PropTypes.func,
  items: PropTypes.array,
};

LimitesAprovadosSelector.defaultProps = {
  className: '',
  selectedItem: PropTypes.object,
  setNewDealer: () => {},
  items: [],
};

export default withStyles(styles)(LimitesAprovadosSelector);
