import React from 'react';
import PropTypes from 'prop-types';

import { camelFormat } from 'utils/format';
import RemoveButton from './removeButton';
import Valor from './valor';

import './listRow.scss';

const ListRow = ({ invoice }) => (
  <div
    className="modal-carteira__list-row__container"
  >
    <div
      className="modal-carteira__list-row__identificador"
    >
      <div className="modal-carteira__list-row__identificador__button">
        <RemoveButton
          invoiceId={invoice?.duplicataId}
          produto={invoice?.descricaoProduto}
          status={invoice?.duplicataStatusDescricao}
        />
      </div>
      <div className="modal-carteira__list-row__identificador__dados">
        {invoice?.descricaoProduto}
        <span>
          {`${camelFormat(invoice?.tipoDocumento)}. ${invoice?.duplicataNumero}`}
        </span>
      </div>
    </div>
    <div className="modal-carteira__list-row__valor">
      <Valor
        valor={invoice?.saldoDevedor}
        status={invoice?.duplicataStatusDescricao}
      />
    </div>
  </div>
);

ListRow.propTypes = {
  invoice: PropTypes.object,
};

ListRow.defaultProps = {
  invoice: null,
};

export default ListRow;
