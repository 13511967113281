import service from './service';
import * as actions from './actions';

export const getPedidos = () => async (dispatch, getState) => {
  try {
    dispatch(actions.getPedidosStart());
    const { filters, pedidosList } = getState().testDrive.acompanhamento.list;
    const { dealers } = getState().dealerStore;
    const pedidos = await service.getPedidos({
      texto: filters.texto,
      status: filters.selectedStatus.length !== filters.status.length
        ? filters.selectedStatus.map((s) => s.value)
        : null,
      dealersId: dealers.map((d) => d.dealerId),
      ipp: pedidosList.ipp,
      page: pedidosList.page,
    });
    dispatch(actions.getPedidosSuccess(pedidos));
  } catch (e) {
    dispatch(actions.getPedidosError());
  }
};

export const resetStore = () => async (dispatch) => {
  dispatch(actions.resetStore());
};

export const setStatus = (selectedStatus) => (dispatch) => {
  dispatch(actions.setSelectedStatus(selectedStatus));
};

export const setTexto = (texto) => (dispatch) => {
  dispatch(actions.setTexto(texto));
};

export const setPage = (page) => (dispatch) => {
  dispatch(actions.setPage(page));
  dispatch(getPedidos());
};

export const getDealerStatus = () => async (dispatch, getState) => {
  let { status } = getState().testDrive.acompanhamento.list.filters;
  if (!status?.length) status = await service.getDealerStatus();
  dispatch(actions.setDealerStatusFilter(status));
};

export const setIpp = (ipp) => (dispatch) => {
  dispatch(actions.setIpp(ipp));
};
