import { connect } from 'react-redux';

import RelatorioPageFooter from './relatorioPageFooter';

import operations from '../../redux/operations';

const mapStateToProps = ({ relatoriosIof }) => ({
  page: relatoriosIof.pageParams.page,
  ipp: relatoriosIof.pageParams.ipp,
  totalItems: relatoriosIof.pageParams.totalItens,
  isLoading: relatoriosIof.requestStatus.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setPage: (value) => dispatch(operations.setPageParams('page', value)),
  setIpp: (value) => dispatch(operations.setPageParams('ipp', value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RelatorioPageFooter);
