import React from 'react';
import PropTypes from 'prop-types';

import TextFilterDebounce from '../../../../../../../common-staff/controls/textFilterDebounce';

const InputModelo = ({
  modelo, setModelo,
}) => (
  <TextFilterDebounce
    label="Cód. Modelo"
    placeholder=""
    value={modelo}
    setValue={setModelo}
  />
);

InputModelo.propTypes = {
  modelo: PropTypes.string,
  setModelo: PropTypes.func,
};

InputModelo.defaultProps = {
  modelo: '',
  setModelo: () => {},
};

export default InputModelo;
