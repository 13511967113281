import React from 'react';
import PropTypes from 'prop-types';

import CardResumo from './views/cardResumo';
import Timeline from './views/timeline';
import Footer from './views/footer';
import DownloadNotaFiscalButton from './views/downloadNotaFiscalButton';

import './acompanhamentoDetalhe.scss';

const AcompanhamentoDetalhe = ({ canCancel }) => (
  <div className="pedidos__modal__sidebar">
    <div className="pedidos__modal__detalhes">
      <CardResumo />
    </div>
    <div className="pedidos__modal__mensagem">
      <div className="pedidos__modal__mensagem_timeline">
        <Timeline />
      </div>
    </div>
    <div className="pedidos__modal__footer">
      {canCancel ? <Footer /> : <DownloadNotaFiscalButton />}
    </div>
  </div>
);

AcompanhamentoDetalhe.propTypes = {
  canCancel: PropTypes.bool,
};

AcompanhamentoDetalhe.defaultProps = {
  canCancel: false,
};

export default AcompanhamentoDetalhe;
