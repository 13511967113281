import { saveAs } from 'file-saver';
import logger from 'utils/logger';
import actions from './actions';

import service from './service';

const setModalInfo = (data) => async (dispatch) => {
  dispatch(actions.setModalInfo(data));
  dispatch(actions.setModalInfoOpen(true));
};

const closeModalInfo = () => async (dispatch) => {
  dispatch(actions.setModalInfoOpen(false));
};

const removeInvoice = (invoiceId) => async (dispatch) => {
  dispatch(actions.removeInvoice(invoiceId));
};

const setInvoice = (invoice) => async (dispatch) => {
  dispatch(actions.setInvoice(invoice));
};

const resetStore = () => async (dispatch) => {
  dispatch(actions.resetStore());
};

const selectedAll = (selectAll) => async (dispatch, getState) => {
  const { invoices } = getState().gestaoPagamentos.carteira.page;
  if (!selectAll) {
    const filtedInvoices = invoices
      .filter((i) => (i.duplicataStatusDescricao.toLowerCase().trim() !== 'quitada'));
    dispatch(actions.selectALL(filtedInvoices));
  } else {
    dispatch(actions.selectALL([]));
  }
};

const getExtrato = () => async (dispatch, getState) => {
  try {
    const duplicata = getState().gestaoPagamentos.carteira.modal.modalInfo?.data?.duplicataNumero;
    const response = await service.getExtrato(duplicata);
    const url = window.URL.createObjectURL(new Blob([response]));
    saveAs(url, `extrato-${duplicata}.xlsx`);
  } catch (error) {
    logger.error(error);
  }
};

export default {
  setModalInfo,
  closeModalInfo,
  removeInvoice,
  setInvoice,
  resetStore,
  selectedAll,
  getExtrato,
};
