import { connect } from 'react-redux';

import * as pageOperations from '../../../../redux/operations';

import VoltarButton from './voltarButton';

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  navBack: () => dispatch(pageOperations.setPage(pageOperations.PAGE_PRODUTO)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VoltarButton);
