import { connect } from 'react-redux';

import operations from '../../../redux/operations';
import selectors from '../../../redux/selector';

import BrandsSelect from './brandsSelect';

const mapStateToProps = ({ comunicados, dealerStore }) => ({
  brand: comunicados.page.filtros.brand,
  brandList: selectors.getBrands(dealerStore),
  isLoading: comunicados?.page?.requestStatus?.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setBrand: (value) => dispatch(operations.setFilter('brand', value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandsSelect);
