import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import List, { ListContent } from 'features/common-staff/layout/list';
import Card from './cards';

import './cardsSection.scss';

const CardSection = ({ documentosList, isLoading, isError }) => (
  <div className="comunicados__container__body">
    <List
      isLoading={isLoading}
      isError={isError}
      isEmpty={_.isEmpty(documentosList)}
    >
      <ListContent>
        <div
          className="comunicados__container__body__cards"
          data-cy="ListContent"
        >
          {
          (Array.isArray(documentosList) ? documentosList : []).map((d) => (
            <Card key={d?.idDocumento} documento={d} />
          ))
          }
        </div>
      </ListContent>

      <ListContent type="empty">
        Nenhum comunicado foi encontrado.
      </ListContent>

      <ListContent type="error">
        Error ao buscar comunicados
      </ListContent>

    </List>
  </div>
);

CardSection.propTypes = {
  documentosList: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
};

CardSection.defaultProps = {
  documentosList: [],
  isLoading: false,
  isError: false,
};

export default CardSection;
