import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import CommonRadioGroup from '@material-ui/core/RadioGroup';
import RadioGroupItem from './radioGroupItem';

import './radioGroup.scss';

class RadioGroup extends React.Component {
  handleChange = (event) => {
    const {
      setItem,
    } = this.props;
    setItem(event.target.value);
  };

  contentForState = () => {
    const {
      items, isLoading, isError, selectedItem,
    } = this.props;

    if (isError) {
      return (
        <div className="radio-group-error">
          <div>
            Ocorreu um erro.
          </div>
        </div>
      );
    }
    if (isLoading) {
      return (
        <div className="radio-group-circular">
          <CircularProgress
            color="inherit"
            size="30px"
          />
        </div>
      );
    }
    return (
      <CommonRadioGroup className="radio-group-container" name="items" value={selectedItem} onChange={this.handleChange}>
        { (items || []).map((item, index) => (
          <RadioGroupItem
            item={item}
            selectedItem={selectedItem}
            key={index}
          />
        ))}
      </CommonRadioGroup>
    );
  };

  render() {
    const {
      label,
      required,
    } = this.props;
    return (
      <>
        <div className="radio-group-subtittle">
          {required ? (
            <>
              {`${label} (obrigatório)`}
              <span className="red-asterisk"> *</span>
            </>
          ) : (
            label
          )}
        </div>
        {this.contentForState()}
      </>
    );
  }
}

RadioGroup.propTypes = {
  setItem: PropTypes.func,
  selectedItem: PropTypes.string,
  items: PropTypes.array,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
};

RadioGroup.defaultProps = {
  setItem: () => {},
  selectedItem: null,
  items: null,
  isLoading: null,
  isError: null,
  label: '',
  required: false,
};

export default RadioGroup;
