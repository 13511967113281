import React from 'react';
import PropTypes from 'prop-types';

import WarningIcon from '../../../assets/icons/warning';

import './atencaoMensagem.scss';

const AtencaoMensagem = ({ mensagem }) => (
  <div className="atencao-mensagem__container">
    <div className="atencao-mensagem__container__icon">
      <WarningIcon
        width="24px"
        height="24px"
        color="#BF8900"
      />
    </div>
    <div className="atencao-mensagem__container__mensagem">
      {mensagem}
    </div>
  </div>
);

AtencaoMensagem.propTypes = {
  mensagem: PropTypes.string.isRequired,
};

export default AtencaoMensagem;
