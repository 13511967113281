import * as React from "react";

const RamIcon = (props) => {
	return (
		<svg focusable="false" aria-hidden="true" width="26" height="4" {...props}>
			<path d="M0 0C0.409878 0 0.819755 0 1.2332 0C2.5056 0 3.778 0 5.04684 0C5.36762 0 5.68126 0.0530806 5.98065 0.181991C6.07332 0.223697 6.16242 0.269194 6.2444 0.329858C6.54022 0.534597 6.69705 0.82654 6.71843 1.1981C6.72556 1.35355 6.73625 1.50521 6.69705 1.66066C6.61507 1.97915 6.4333 2.21422 6.18381 2.39621C6.1054 2.45308 6.01629 2.50237 5.93432 2.55166C5.90937 2.56682 5.88086 2.58199 5.84165 2.60474C6.22658 3.05972 6.60794 3.50711 7 3.96588C6.95367 3.96967 6.92872 3.96967 6.9002 3.96967C5.99491 3.96967 5.09318 3.96967 4.18788 3.96967C4.12016 3.96967 4.08096 3.94692 4.04175 3.89005C3.80295 3.54123 3.56059 3.19621 3.32179 2.85118C3.28971 2.8019 3.25407 2.77915 3.19705 2.78294C3.05092 2.78673 2.90835 2.78294 2.75153 2.78294C2.75153 2.82085 2.74796 2.85877 2.74796 2.89289C2.74796 3.23033 2.74796 3.56777 2.74796 3.90142C2.74796 3.93554 2.7444 3.96588 2.74083 4C1.82841 4 0.915988 4 0.00356415 4C0 2.66919 0 1.3346 0 0ZM2.72658 1.79716C2.74084 1.80095 2.75153 1.80474 2.76222 1.80474C2.96181 1.80474 3.16141 1.80853 3.361 1.80474C3.46436 1.80095 3.56059 1.76303 3.64613 1.69858C3.76375 1.61137 3.80295 1.49005 3.78157 1.34218C3.76018 1.2019 3.68534 1.1109 3.56059 1.07299C3.51069 1.05782 3.45367 1.04645 3.40377 1.04645C3.20418 1.04265 3.00458 1.04265 2.80499 1.04265C2.78004 1.04265 2.75509 1.04645 2.72658 1.04645C2.72658 1.29668 2.72658 1.54692 2.72658 1.79716Z" fill="white" />
			<path d="M21.502 1.2419C21.5198 1.20381 21.534 1.17714 21.5483 1.15048C21.7157 0.796191 21.8866 0.438095 22.0505 0.08C22.079 0.0190476 22.111 0 22.1751 0C23.4181 0.00380952 24.6576 0 25.9006 0C25.9291 0 25.9576 0 25.9826 0C26.0004 0.072381 26.0075 3.88191 25.9897 3.99238C25.0672 3.99238 24.1447 3.99238 23.2116 3.99238C23.2116 3.87048 23.2116 3.75238 23.208 3.63048C23.208 3.50857 23.208 3.38667 23.208 3.26476C23.208 3.14286 23.208 3.02095 23.208 2.89905C23.208 2.77714 23.208 2.65524 23.208 2.53333C23.208 2.41143 23.208 2.28952 23.208 2.16762C23.208 2.04571 23.208 1.92762 23.208 1.80571C23.2009 1.80571 23.1973 1.8019 23.1902 1.8019C23.176 1.82857 23.1617 1.85905 23.1475 1.88571C22.8233 2.56 22.5028 3.23429 22.1822 3.91238C22.1502 3.98095 22.1146 4 22.0469 4C21.6836 3.99619 21.3168 4 20.9535 4C20.925 4 20.8965 4 20.8644 4C20.5118 3.26476 20.1628 2.53333 19.8137 1.8019C19.8066 1.8019 19.803 1.8019 19.7959 1.80571C19.7888 1.92381 19.7959 2.04571 19.7924 2.16381C19.7924 2.28571 19.7924 2.40762 19.7924 2.52952C19.7924 2.65143 19.7924 2.77333 19.7924 2.89524C19.7924 3.01714 19.7924 3.13905 19.7924 3.26095C19.7924 3.38286 19.7924 3.50476 19.7924 3.62667C19.7924 3.74857 19.7924 3.87048 19.7924 3.99238C18.8556 3.99238 17.9332 3.99238 17 3.99238C17 2.66286 17 1.33714 17 0C17.0321 0 17.0641 0 17.0962 0C18.3392 0 19.5787 0 20.8217 0C20.8858 0 20.9214 0.0190476 20.9535 0.0838095C21.1173 0.438095 21.2847 0.792381 21.4521 1.14667C21.4663 1.17333 21.4806 1.20381 21.502 1.2419Z" fill="white" />
			<path d="M16 4C15.0608 4 14.1388 4 13.2065 4C13.1071 3.73971 13.0077 3.47943 12.9083 3.21531C12.2982 3.21531 11.6915 3.21531 11.078 3.21531C10.9751 3.4756 10.8723 3.73971 10.7695 4C9.84747 4 8.93231 4 8 4C8.63068 2.66029 9.2545 1.33206 9.88175 0C11.2494 0 12.6135 0 13.9846 0C14.6495 1.3244 15.3213 2.65646 16 4ZM11.479 2.17033C11.8252 2.17033 12.1611 2.17033 12.497 2.17033C12.473 2.0555 12.024 0.941627 11.9863 0.891866C11.8149 1.31675 11.647 1.7378 11.479 2.17033Z" fill="white" />
		</svg>
	);
}

export default RamIcon;
