import React from 'react';
import PropTypes from 'prop-types';

import './listHeader.scss';

const ListHeader = ({
  openSidebar, gridTemplate,
}) => (
  <div className={`list-header ${gridTemplate?.container}`}>
    <div className="list-header__id">ID</div>
    <div className="list-header__produto">Produto</div>
    {!openSidebar && <div className="list-header__aprovacao">Aprovação</div>}
    <div className="list-header__valor">Limite total aprovado</div>
    <div className="list-header__vencimento">Vencimento</div>
    {!openSidebar && <div className="list-header__status">Status</div>}
  </div>
);

ListHeader.propTypes = {
  openSidebar: PropTypes.bool,
  gridTemplate: PropTypes.object,
};

ListHeader.defaultProps = {
  openSidebar: false,
  gridTemplate: {},
};

export default ListHeader;
