import { connect } from 'react-redux';

import Filtros from './filters';
import selectors from '../../redux/selector';
import operations from '../../redux/operations';

const mapStateToProps = ({ dealerStore }) => ({
  showBrandsSelector: selectors.getQuantidadeBrands(dealerStore),
  brandList: selectors.getBrands(dealerStore),
});

const mapDispatchToProps = (dispatch) => ({
  setBrand: (value) => dispatch(operations.setFilter('brand', value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filtros);
