import styled, { css } from 'styled-components';
import { Button } from 'features/common';
import colors from 'assets/styles/colors';

export const Container = styled.div`
  margin-top: auto;
  margin-left: auto;
  height: auto;
`;

export const baseButton = (props) => !props.isLoading && css`
  :hover {
    background-color: ${colors.primary_color_600};
  }
  :active {
    background-color: ${colors.primary_color_700};
    box-shadow: none;
  }
`;

export const ModalButton = styled(Button)`
  width: 100%;
  height: 38px;
  border-top: 12px;
  padding: 11px 11px;
  font-size: 14px;
  ${(props) => props.isOpen && css`
    background-color: ${colors.primary_color_700} !important;
    box-shadow: none !important;
  `};
  ${baseButton}
`;
