import styled from 'styled-components';
import colors from 'assets/styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  position: relative;
  margin:0 24px 0 24px;
`;

export const Header = styled.div`
  padding-top: 24px;
  padding-bottom: 48px;
  font-size: 14px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const TipoDuplicata = styled.span`
  color: ${colors.text_midnight_500};
  font-weight: bold;
`;

export const CardIcon = styled.div`
  height: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
`;
export const Icon = styled.svg`
  display: flex;
  align-items: center;
  justify-content: center;
`;
