import React from 'react';
import PropTypes from 'prop-types';

import Snackbar from './snackbar';

import './snackbarList.scss';

const SnackbarList = ({ messages }) => (
  <div className="snackbar__itens-container">
    {(messages || []).map((message) => (
      <Snackbar
        message={message}
        key={message.id}
      />
    ))}
  </div>
);

SnackbarList.propTypes = {
  messages: PropTypes.array,
};

SnackbarList.defaultProps = {
  messages: [],
};

export default SnackbarList;
