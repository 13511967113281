import { connect } from 'react-redux';

import DadosDestinoModal from './dadosDestinoModal';

const mapStateToProps = ({ limiteCreditoStore }) => ({
  codigoSap: limiteCreditoStore.transferenciaLimite.dadosDestino.codigoSap,
  selectedDealerId: limiteCreditoStore.transferenciaLimite.dadosDestino.selectedDealerId,
  selectedProduct: limiteCreditoStore.transferenciaLimite.dadosDestino.produto,
});

export default connect(mapStateToProps)(DadosDestinoModal);
