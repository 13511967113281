import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import FormInput from 'features/common-staff/controls/input/formInput';

const useStyles = makeStyles({
  input: {
    width: '400px',
    marginLeft: '8px',
  },
});

const InputValor = ({
  valor, setValor,
}) => {
  const classes = useStyles();
  return (
    <div
      className={classes.input}
      data-cy="TransferenciaLimiteDadosSolicitacao"
    >
      <FormInput
        label="Valor da transferência"
        type="currency"
        value={valor}
        setValue={(value) => {
          setValor(value);
        }}
        placeholder="Ex: R$ 100.000"
        disabled={false}
      />
    </div>
  );
};

InputValor.propTypes = {
  valor: PropTypes.string,
  setValor: PropTypes.func,
};

InputValor.defaultProps = {
  valor: '',
  setValor: () => {},
};

export default InputValor;
