import React from 'react';
import PropTypes from 'prop-types';

import ConnectionErrorIcon from 'assets/icons/connection-error';
import { LoadMoreButton, ErroConexao } from '../../../common';

import './iofErrorBox.scss';

const IofErrorBox = ({
  className, loading, dealerGroup, getInvoicesWithBilling, getFinancialValues,
}) => (
  <div className={className}>
    <ErroConexao
      title="Algo deu errado."
      message="Não foi possível carregar suas informações."
    >
      <ConnectionErrorIcon className="erro-conexao-image-bigger" />
    </ErroConexao>
    <LoadMoreButton
      white={false}
      message="Tentar novamente"
      loading={loading}
      onClick={() => { getInvoicesWithBilling(dealerGroup, 0); getFinancialValues(dealerGroup); }}
      type="button"
    />
  </div>
);

IofErrorBox.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  dealerGroup: PropTypes.object,

  getInvoicesWithBilling: PropTypes.func.isRequired,
  getFinancialValues: PropTypes.func.isRequired,
};

IofErrorBox.defaultProps = {
  className: '',
  dealerGroup: undefined,
};

export default IofErrorBox;
