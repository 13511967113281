import { connect } from 'react-redux';

import EmissaoDateRange from './emissaoDateRange';

import operations from '../../../redux/operations';

const mapStateToProps = ({ gestaoPagamentos }) => ({
  isLoading: gestaoPagamentos.carteira.page.requestStatus.isLoading,
  startDate: gestaoPagamentos.carteira.page.filters.emissaoInicio,
  endDate: gestaoPagamentos.carteira.page.filters.emissaoFim,
});

const mapDispatchToProps = (dispatch) => ({
  setEndDate: (value) => dispatch(operations.setFilter('emissaoFim', value)),
  setStartDate: (value) => dispatch(operations.setFilter('emissaoInicio', value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmissaoDateRange);
