const PREFIX_GET_PEDIDOS = 'testDrive/GET_PEDIDOS';

export const types = {
  PREFIX_GET_PEDIDOS,
  GET_PEDIDOS_START: `${PREFIX_GET_PEDIDOS}/START`,
  GET_PEDIDOS_SUCCESS: `${PREFIX_GET_PEDIDOS}/SUCCESS`,
  GET_PEDIDOS_ERROR: `${PREFIX_GET_PEDIDOS}/ERROR`,

  RESET_STORE: 'testDrive/RESET_STORE',
  SET_SELECTED_STATUS: 'testDrive/SET_STATUS',
  SET_TEXTO: 'testDrive/SET_TEXTO',
  SET_PAGINA: 'testDrive/SET_PAGINA',
  SET_DEALER_STATUS_FILTER: 'testDrive/SET_DEALER_STATUS_FILTER',
  SET_IPP: 'testDrive/SET_IPP',
};

export const resetStore = () => ({
  type: types.RESET_STORE,
});

export const getPedidosStart = () => ({
  type: types.GET_PEDIDOS_START,
});

export const getPedidosError = () => ({
  type: types.GET_PEDIDOS_ERROR,
});

export const getPedidosSuccess = (pedidos) => ({
  type: types.GET_PEDIDOS_SUCCESS,
  payload: { pedidos },
});

export const setSelectedStatus = (selectedStatus) => ({
  type: types.SET_SELECTED_STATUS,
  payload: { selectedStatus },
});

export const setTexto = (texto) => ({
  type: types.SET_TEXTO,
  payload: { texto },
});

export const setPage = (page) => ({
  type: types.SET_PAGINA,
  payload: { page },
});

export const setDealerStatusFilter = (status) => ({
  type: types.SET_DEALER_STATUS_FILTER,
  payload: { status },
});

export const setIpp = (ipp) => ({
  type: types.SET_IPP,
  payload: { ipp },
});
