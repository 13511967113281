import {
  SET_DEALER_GROUP, SET_INVOICES, CONCAT_INVOICES, START_REQUEST, FINISH_REQUEST,
  SET_SELECTED_VALUE, ADD_TO_SELECTED_VALUE, SET_MOV_ACC_VALUE, RESET_STORE, SET_FINANCIAL_VALUES,
  SET_LOAD_ERROR, SET_END_DATE, SET_START_DATE, SET_IS_UPDATED,
} from './iofSimulatorActions';

const INITIAL_STATE = {
  dealerGroup: null,

  invoices: [],
  page: 0,
  loading: false,
  hasMoreItens: true,

  transmissionValue: undefined,
  paymentValue: undefined,
  lastUpdate: undefined,
  selectedValue: undefined,
  movementAccountPaymentsValue: 0.0,

  filters: {
    startDate: null,
    endDate: null,
  },

  isLoadError: false,
  isUpdated: true,
};

export default (state = INITIAL_STATE, action = { type: 'default' }) => {
  switch (action.type) {
    case SET_DEALER_GROUP:
      return {
        ...state,
        dealerGroup: action.payload.dealerGroup,
      };
    case SET_IS_UPDATED:
      return {
        ...state,
        isUpdated: action.payload,
      };
    case SET_INVOICES:
      return {
        ...state,
        invoices: action.payload.invoices,
        hasMoreItens: action.payload.hasMoreItens,
        page: 0,
      };
    case SET_FINANCIAL_VALUES:
      return {
        ...state,
        transmissionValue: action.payload.transmission,
        paymentValue: action.payload.payments,
        lastUpdate: action.payload.lastUpdate,
      };
    case CONCAT_INVOICES:
      return {
        ...state,
        invoices: [...state.invoices, ...action.payload.invoices],
        hasMoreItens: action.payload.hasMoreItens,
        page: action.payload.hasMoreItens ? action.payload.newPage : 0,
      };
    case START_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FINISH_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case SET_SELECTED_VALUE:
      return {
        ...state,
        selectedValue: action.payload,
      };
    case ADD_TO_SELECTED_VALUE:
      return {
        ...state,
        selectedValue: (state.selectedValue || 0.0) + action.payload,
      };
    case SET_MOV_ACC_VALUE:
      return {
        ...state,
        movementAccountPaymentsValue: action.payload,
      };
    case SET_LOAD_ERROR:
      return {
        ...state,
        isLoadError: action.payload,
      };

    case SET_START_DATE:
      return {
        ...state,
        filters: {
          ...state.filters,
          startDate: action.payload,
        },
      };
    case SET_END_DATE:
      return {
        ...state,
        filters: {
          ...state.filters,
          endDate: action.payload,
        },
      };
    case RESET_STORE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
