import { loadDealers, loadSelectedFPlanDealerId } from '../dealers';

import { SET_DEALERS, SET_SELECTED_FPLAN_DEALER } from './dealerActions';

export const INITIAL_STATE = {
  dealers: loadDealers(),
  selectedFPlanDealerId: loadSelectedFPlanDealerId(),
};

export default (state = INITIAL_STATE, action = { type: 'default' }) => {
  switch (action.type) {
    case SET_DEALERS:
      return {
        ...state,
        dealers: action.payload,
      };
    case SET_SELECTED_FPLAN_DEALER:
      return {
        ...state,
        selectedFPlanDealerId: action.payload,
      };
    default:
      return state;
  }
};
