import React from 'react';
import PropTypes from 'prop-types';

import { DealerNumber, CnpjFormat, CapitalizeText } from '../../../common';

import './dealerOption.scss';

const DealerOption = ({ className, dealer }) => (
  <div className={`dealer-selector__option ${className}`}>
    <div className="dealer-selector__option__title">
      <CapitalizeText text={dealer.dealerName} />
    </div>
    <div className="dealer-selector__option__text">
      <CnpjFormat value={dealer.cnpj} />
    </div>
    <div className="dealer-selector__option__text">
      <span>
        {`${dealer.brand} | `}
        <DealerNumber dealerCode={dealer.dealerCorr} />
      </span>
    </div>
  </div>
);

DealerOption.propTypes = {
  className: PropTypes.string,
  dealer: PropTypes.object.isRequired,
};

DealerOption.defaultProps = {
  className: '',
};

export default DealerOption;
