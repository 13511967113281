import React from 'react';

import BreadCrumb from 'features/common-staff/layout/breadCrumb';

const BreadCrumbJurosCarencia = () => (
  <BreadCrumb
    labels={[{
      label: 'FIDC',
      path: '/carteira-fidc',
    }, {
      label: 'Resgates',
      path: '/carteira-fidc/resgates',
    }, {
      label: 'Juros carência',
      path: '/juros-carencia',
    }]}
    format={false}
  />
);

export default BreadCrumbJurosCarencia;
