import { connect } from 'react-redux';

import CardsSection from './cardsSection';

const mapStateToProps = ({ comunicados }) => ({
  documentosList: comunicados.page.documentos,
  isLoading: comunicados.page.requestStatus.isLoading,
  isError: comunicados.page.requestStatus.isError,
});

export default connect(mapStateToProps, null)(CardsSection);
