import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import ExpandMore from '../../../assets/icons/expand-more';

const useStyles = makeStyles(() => ({
  margin: {
    width: '100%',
    marginTop: '-8px',
    borderRadius: '4px',
  },
}));

const StaffSelect = ({
  className, onSelect, value, items, label, placeholder, disabled, customInput, border,
}) => {
  const classes = useStyles();
  return (
    <div className={className}>
      <FormControl className={classes.margin}>
        {label
          ? <InputLabelStaff id="demo-customized-select-label">{label}</InputLabelStaff>
          : null}

        <SelectStaff
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={value === null || value === undefined ? '_default' : value}
          onChange={(event) => onSelect(event.target.value)}
          IconComponent={ExpandMore}
          input={customInput ?? <Input border={border} />}
          disabled={disabled}
          data-cy="Select"
        >
          {placeholder ? (
            <MenuItem disabled value="_default">
              {placeholder}
            </MenuItem>
          ) : null}
          {
          (items || []).map((item, index) => (
            <MenuItem
              key={index}
              value={item.value}
            >
              {item.text}
            </MenuItem>
          ))
        }
        </SelectStaff>
      </FormControl>
    </div>
  );
};

const InputLabelStaff = withStyles(() => ({
  root: {
    top: '6px',
    left: '12px',
    fontSize: 14,
    lineHeight: '1.75',
    color: '#8f9bb3 !important',
  },
}))(InputLabel);

const Input = withStyles(() => ({
  root: {
    height: '40px',
    'label + &': {
      marginTop: '28px',
    },
  },
  input: (props) => ({
    marginLeft: '6px',
    borderRadius: '4px',
    position: 'relative',
    backgroundColor: 'rgba(228, 233, 242, 0.24)',
    fontSize: 14,
    border: props.border ? 'solid 1px rgba(85, 87, 112, 1)' : '',
    lineHeight: '1.75',
    color: '#8f9bb3',
    padding: '8px 8px',
    '&:focus': {
      borderRadius: 4,
      backgroundColor: 'rgba(228, 233, 242, 0.24)',
    },
  }),
}
))(InputBase);

const SelectStaff = withStyles(() => ({
  icon: {
    height: '21px',
    width: '25px',
    fill: '#8f9bb3',
  },
  iconOpen: {
    height: '21px',
    width: '25px',
    fill: '#8f9bb3',
  },
  disabled: {
    backgroundColor: 'white',
    color: '#555770',
    opacity: 0,
  },
}))(Select);

StaffSelect.propTypes = {
  className: PropTypes.string,
  onSelect: PropTypes.func,
  value: PropTypes.any,
  items: PropTypes.array,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  customInput: PropTypes.node,
  border: PropTypes.bool,
};

StaffSelect.defaultProps = {
  className: '',
  onSelect: null,
  value: '',
  label: '',
  items: [],
  placeholder: null,
  disabled: false,
  customInput: null,
  border: false,
};

export default StaffSelect;
