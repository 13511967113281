import React from 'react';
import PropTypes from 'prop-types';

import { PATH_PAYMENTS } from '../../../../routes/paths';
import { Tracking } from '../../../../modules';

import { Button } from '../../../common';

import './paymentButton.scss';

export class PaymentButton extends React.Component {
  componentDidMount() {
    const { requestLoadStatus } = this.props;
    requestLoadStatus();

    if (this.intervalHandle) {
      clearInterval(this.intervalHandle);
    }
    this.intervalHandle = setInterval(() => requestLoadStatus(), 5000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalHandle);
  }

  render() {
    const {
      pathname, navTo, paymentAvailable, userMessage,
    } = this.props;

    return (
      <div className="menu__payment-button__container">
        <div className="menu__payment-button__btn">
          <Button
            className="menu__payment-button__btn-btn"
            disabled={!paymentAvailable || pathname === PATH_PAYMENTS}
            onClick={() => {
              Tracking.Mixpanel.trackMenuClick('Pagamentos');
              navTo(PATH_PAYMENTS);
            }}
          >
            Pagar
          </Button>
        </div>
        { userMessage ? (
          <div className="menu__payment-button__text-container">
            <div className="menu__payment-button__text">
              {userMessage}
            </div>
          </div>
        ) : null }
      </div>
    );
  }
}

PaymentButton.propTypes = {
  pathname: PropTypes.string.isRequired,
  paymentAvailable: PropTypes.bool.isRequired,
  userMessage: PropTypes.string,

  navTo: PropTypes.func,
  requestLoadStatus: PropTypes.func,
};

PaymentButton.defaultProps = {
  userMessage: null,
  requestLoadStatus: () => {},
  navTo: () => {},
};

export default PaymentButton;
