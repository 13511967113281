import React from 'react';
import PropTypes from 'prop-types';
import ErrorOutline from '../../_assets/icons/error-outline';
import CheckCircleFilled from '../../_assets/icons/check-circle-filled';

import './snackbar.scss';

const Snackbar = (
  {
    onClose, message, id, timeout, type,
  },
) => {
  if (timeout && timeout > 0) {
    setTimeout(() => onClose(id), timeout);
  }

  return (
    <div className="snackbar-items__container" data-cy="SnackbarItemsContainer">
      {
    type === 'success'
      ? (
        <div className="snackbar-succes__item-container" onClick={() => onClose(id)} role="button" tabIndex="0">
          <div className="snackbar__content-block">
            <CheckCircleFilled className="snackbar-succes__image" />
            <span className="snackbar__text">{message}</span>
          </div>
        </div>
      ) : (
        <div className="snackbar-error__item-container" onClick={() => onClose(id)} role="button" tabIndex="0">
          <div className="snackbar__content-block">
            <ErrorOutline className="snackbar-error__image" />
            <span className="snackbar__text">{message}</span>
          </div>
        </div>
      )
  }

    </div>
  );
};

Snackbar.propTypes = {
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  type: PropTypes.string,
  timeout: PropTypes.number,
};

Snackbar.defaultProps = {
  timeout: null,
  type: 'error',
};

export default Snackbar;
