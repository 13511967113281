import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../common/button';
import MessageIcon from '../../../../assets/icons/message';

import { content } from '../../redux/content';
import UserFeedbackPopover from '../popover';

import './dialogButton.scss';

class DialogButton extends React.Component {
  componentDidMount() {
    const { fulfillOpenContentRequest } = this.props;

    setTimeout(() => {
      // delay puramente estético
      fulfillOpenContentRequest();
    }, 1000);
  }

  componentDidUpdate() {
    const {
      sessionExpired, setIsOpen, setContent, resetErrorFeedback, resetTestDriveFeedback,
    } = this.props;

    if (sessionExpired) {
      setIsOpen(false);
      setContent(content.main);
      resetErrorFeedback();
      resetTestDriveFeedback();
    }
  }

  render() {
    const { className, setIsOpen, isOpen } = this.props;

    return (
      <>
        <UserFeedbackPopover
          className={`popover-content-main__container ${className}`}
        />

        <Button
          className="dialog-button__button"
          onClick={() => setIsOpen(!isOpen)}
        >
          <MessageIcon className="dialog-button__icon" />
        </Button>
      </>
    );
  }
}

DialogButton.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  sessionExpired: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setContent: PropTypes.func.isRequired,
  resetErrorFeedback: PropTypes.func.isRequired,
  resetTestDriveFeedback: PropTypes.func.isRequired,

  fulfillOpenContentRequest: PropTypes.func.isRequired,
};

DialogButton.defaultProps = {
  className: '',
};

export default DialogButton;
