import { connect } from 'react-redux';

import DownloadNotaFiscalButton from './downloadNotaFiscalButton';
import selectors from '../../redux/selectors';

const mapStateToProps = ({ testDrive }) => ({
  urlFatura: testDrive.acompanhamento.detalhe.modal.detalhePedido?.urlFatura,
  canDownloadNotaFiscal: selectors.canDownloadNotaFiscal(testDrive),
  isAVista: testDrive.acompanhamento.detalhe.modal?.detalhePedido?.isAVista,
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadNotaFiscalButton);
