import actions from './actions';

const INITIAL_STATE = {
  isOpen: false,
  data: [],
  isLoading: false,
  isError: false,
};

export default (state = INITIAL_STATE, action = { type: '@@modalHistoricoDuplicata/INIT' }) => {
  switch (action.type) {
    case actions.types.SET_MODAL_OPEN:
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    case actions.types.GET_HISTORICO_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
        data: null,
      };
    case actions.types.GET_HISTORICO_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        data: null,
      };
    case actions.types.GET_HISTORICO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload.data,
      };
    case actions.types.RESET_STORE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
