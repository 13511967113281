import React from 'react';
import PropTypes from 'prop-types';

import { camelFormat } from 'utils/format';

import './cardBody.scss';

const CardBody = ({
  solicitanteEmail, label, concessionaria, produto, brand,
}) => (
  <>
    {solicitanteEmail ? (
      <div
        className="acompanhamento__detalhe__card-body__content-row solicitante"
      >
        <span className="acompanhamento__detalhe__card-body__content-prop">
          Solicitante
        </span>
        <span className="acompanhamento__detalhe__card-body__content-value">
          {solicitanteEmail}
        </span>
      </div>
    ) : null}
    {label ? (
      <span className="acompanhamento__detalhe__card-body__content-label">
        {label}
      </span>
    ) : null}
    <div className="acompanhamento__detalhe__card-body__content-row">
      <span className="acompanhamento__detalhe__card-body__content-prop">
        Concessionária
      </span>
      <span className="acompanhamento__detalhe__card-body__content-value">
        {camelFormat(concessionaria?.nome, 2)}
      </span>
    </div>
    <div className="acompanhamento__detalhe__card-body__content-row">
      <span className="acompanhamento__detalhe__card-body__content-prop">
        CNPJ
      </span>
      <span className="acompanhamento__detalhe__card-body__content-value">
        {concessionaria?.cnpj}
      </span>
    </div>
    <div className="acompanhamento__detalhe__card-body__content-row">
      <span className="acompanhamento__detalhe__card-body__content-prop">
        Código SAP
      </span>
      <span className="acompanhamento__detalhe__card-body__content-value">
        {concessionaria?.codSap}
      </span>
    </div>
    <div className="acompanhamento__detalhe__card-body__content-row">
      <span className="acompanhamento__detalhe__card-body__content-prop">
        Produto
      </span>
      <span className="acompanhamento__detalhe__card-body__content-value">
        {produto}
      </span>
    </div>
    <div className="acompanhamento__detalhe__card-body__content-row">
      <span className="acompanhamento__detalhe__card-body__content-prop">
        Brand
      </span>
      <span className="acompanhamento__detalhe__card-body__content-value">
        {brand}
      </span>
    </div>
  </>
);

CardBody.propTypes = {
  solicitanteEmail: PropTypes.string,
  label: PropTypes.string,
  concessionaria: PropTypes.object,
  produto: PropTypes.string,
  brand: PropTypes.string,
};

CardBody.defaultProps = {
  solicitanteEmail: null,
  label: null,
  concessionaria: null,
  produto: null,
  brand: null,
};

export default CardBody;
