import React from 'react';
import PropTypes from 'prop-types';

import MarcaBadge from 'features/common-staff/views/marcaBadge';

import CnpjFormat from '../cnpjFormat';
import DealerNumber from '../dealerNumber';

const DealerOption = ({
  brand, dealerName, dealerId, cnpj,
}) => (
  <div
    className="solicitacao-limite__autocomplete-item-container"
  >
    <div className="solicitacao-limite__autocomplete-item-icon">
      <MarcaBadge
        marca={(brand)}
      />
    </div>
    <div className="solicitacao-limite__autocomplete-item-name">
      {dealerName}
    </div>
    <div className="solicitacao-limite__autocomplete-item-description">
      <CnpjFormat value={cnpj} />
      <div className="solicitacao-limite__autocomplete-item-divider" />
      <DealerNumber dealerCode={dealerId} />
    </div>
  </div>
);

DealerOption.propTypes = {
  brand: PropTypes.string,
  dealerName: PropTypes.string,
  dealerId: PropTypes.number,
  cnpj: PropTypes.string,
};

DealerOption.defaultProps = {
  brand: '',
  dealerName: '',
  dealerId: null,
  cnpj: '',
};
export default DealerOption;
