import { connect } from 'react-redux';

import ProdutoPage from './produtoPage';
import * as PageOperations from '../redux/operations';

const mapStateToProps = ({ testDrive }) => ({
  isLoading: testDrive.main.products.isLoading,
  isError: testDrive.main.products.isError,
});

const mapDispatchToProps = (dispatch) => ({
  resetSelections: () => {
    dispatch(PageOperations.resetSelections(null));
  },
  getProducts: (products) => {
    dispatch(PageOperations.loadProducts(products));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProdutoPage);
