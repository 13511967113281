export const filterConcessionarias = (dealerStore) => {
  const { dealers } = dealerStore;
  const groups = [];

  dealers.forEach((dealer) => {
    if (dealer.matrizId !== undefined && dealer.matrizId !== null && dealer.matrizId !== 0) {
      if (dealer.matrizId === dealer.dealerId) {
        const dealerMatriz = dealer.dealerCorr.toString();
        const isGroupPresent = groups.some((group) => (
          group.matrizId === dealerMatriz && group.brand === dealer.brand
        ));

        if (!isGroupPresent) {
          groups.push(dealer);
        }
      }
    }
  });

  return groups;
};
