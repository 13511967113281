import React from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';
import colors from 'assets/styles/colors';

const useStyles = makeStyles({
  container: {
    color: colors.primary_color_800,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '32px',
  },
});

const ChartLoading = ({ size }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <CircularProgress
        color="inherit"
        size={`${size}px`}
      />
    </div>
  );
};

ChartLoading.propTypes = {
  size: PropTypes.number,
};
ChartLoading.defaultProps = {
  size: 60,
};

export default ChartLoading;
