import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import WarningRedIcon from '../../../../assets/icons/warning-red';
import CheckCircleIcon from '../../../../assets/icons/check-circle';

import Date from '../../../common/date';

import './notificationsRow.scss';

const dateFormat = (date) => {
  const today = moment();
  if (moment(date).isSame(today, 'day')) return 'HH:mm';
  return 'DD/MM';
};

const getIcon = (notification) => {
  const isAprovado = notification?.detalheTipo?.includes('aprovad') || notification?.descricao?.includes('aprovad');
  if (isAprovado) {
    return (
      <div className="notifications-row-aproved-icon-container">
        <CheckCircleIcon className="notifications-row-aproved-icon" />
      </div>
    );
  }
  return (
    <div className="notifications-row-reproved-icon-container">
      <WarningRedIcon className="notifications-row-reproved-icon" />
    </div>
  );
};

const NotificationRow = (props) => {
  const { className, notification } = props;

  return (
    <div className={`notifications-row ${className}`}>
      <div className="notifications-row-icon">
        {getIcon(notification)}
      </div>
      <div className="notifications-row-info">
        <div className="notifications-row-info-title">
          {notification.detalheTipo}
        </div>

        <Date
          className="notifications-row-info-date"
          format={dateFormat(notification.dataHora)}
          date={notification.dataHora}
        />

        <div className="notifications-row-info-descricao">
          {notification.descricao}
        </div>
      </div>
    </div>
  );
};

NotificationRow.propTypes = {
  className: PropTypes.string,
  notification: PropTypes.shape({

    dataHora: PropTypes.string,
    tipo: PropTypes.string,
    detalheTipo: PropTypes.string,
    lido: PropTypes.bool,
    itemId: PropTypes.number,
    descricao: PropTypes.string,

  }).isRequired,
};

NotificationRow.defaultProps = {
  className: '',
};

export default NotificationRow;
