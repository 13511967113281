import logger from 'utils/logger';

import service from './service';
import actions from './actions';

const getSolicitacoes = () => async (dispatch, getState) => {
  try {
    dispatch(actions.getSolicitacoesStart());
    const { ipp, page } = getState().limiteCreditoStore.acompanhamento.pageParams;
    const { dealers } = getState().dealerStore;

    const response = await service.getSolicitacoes({
      dealers, ipp, page,
    });

    const pageParams = {
      page: response.pagina,
      ipp: response.itensPorPagina,
      totalItems: response.itensTotal,
    };

    dispatch(actions.getSolicitacoesSuccess(response.solicitacoes, pageParams));
  } catch (e) {
    logger.error(e);
    dispatch(actions.getSolicitacoesError());
  }
};

const setOpen = (value) => (dispatch) => {
  dispatch(actions.setOpen(value));
};

const getDetalhes = (idSolicitacao) => async (dispatch) => {
  try {
    dispatch(setOpen(true));
    dispatch(actions.getDetalhesStart());
    const response = await service.getDetalhes(idSolicitacao);
    dispatch(actions.getDetalhesSuccess(response));
  } catch (error) {
    logger.error(error);
    dispatch(actions.getDetalhesError());
  }
};

const setPageParams = (propertyName, value) => async (dispatch) => {
  dispatch(actions.setPageParams(propertyName, value));
  dispatch(getSolicitacoes());
};

const resetStore = () => (dispatch) => {
  dispatch(actions.resetStore());
};

export default {
  getSolicitacoes,
  setPageParams,
  resetStore,
  getDetalhes,
  setOpen,
};
