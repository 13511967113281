import { connect } from 'react-redux';

import FilterButton from 'features/common-staff/controls/buttonFilter';
import * as operations from '../../acompanhamentoList/redux/operations';

const mapStateToProps = ({ testDrive }) => ({
  isFilterSelected: testDrive?.acompanhamento?.list?.isFilterSelected,
  isLoading: testDrive?.acompanhamento?.list?.pedidosList?.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  onClick: () => dispatch(operations.getPedidos()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterButton);
