import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Divider } from '@mui/material';
import SummaryPage from 'features/common/summaryPage';
import ModalLimitePropostaDetailSection from '../modalLimitePropostaDetailSection/modalLimitePropostaDetailSection';
import './infoModal.scss';

const InfoModal = ({
  itensConcessionaria, itensProduto,
  condicao, title,
}) => (
  <SummaryPage title={title}>
    <div className="modal-limite-proposta__content__sections">
      <ModalLimitePropostaDetailSection items={itensConcessionaria} />
      <Divider />
      <ModalLimitePropostaDetailSection items={itensProduto} />
    </div>
    <div className="modal-limite-proposta__content__text-editor">
      <div className="modal-limite-proposta__content__text-editor-title">
        Condições
      </div>
      <div className="modal-limite-proposta__content__text">{condicao && parse(condicao)}</div>
    </div>
  </SummaryPage>
);
InfoModal.propTypes = {
  itensConcessionaria: PropTypes.array,
  itensProduto: PropTypes.array,
  condicao: PropTypes.string,
  title: PropTypes.string,
};

InfoModal.defaultProps = {
  itensConcessionaria: [],
  itensProduto: [],
  condicao: '',
  title: '',
};

export default InfoModal;
