import { connect } from 'react-redux';

import StatementList from './statementList';
import selector from '../../redux/selectors';

const mapStateToProps = ({ statementStore }) => ({
  movimentacoes: selector.listMovimentacoes(statementStore),
  isLoading: statementStore?.requestStatus?.isLoading,
  isError: statementStore?.requestStatus?.isError,
  order: statementStore?.filters?.order,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(StatementList);
