import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { getSimulatedValue } from '../../testDriveSimulation';

import TestDriveConditionCard from '../testDriveConditionCard';
import AVistaConditionCard from '../aVistaConditionCard';
import AlertAVista from '../alertAVista';

import './testDriveConditionsSelector.scss';

function TestDriveConditionsSelector({
  aVistaConditions, installmentConditions, selectedConditionId,
  simulationBaseValue, setSimulationBaseValue,
  onChange, className, offers, selectedOfferId, selectedOffer,
}) {
  const offer = offers.find((o) => o.id === selectedOfferId) || {};
  const isAVistaSelected = (aVistaConditions || []).some((c) => c.id === selectedConditionId);

  useEffect(
    () => {
      setSimulationBaseValue(0);
    },
    [selectedOfferId, setSimulationBaseValue, offer.price],
  );

  return (
    <>
      <div className={`${className} td-container-conditions`}>
        { installmentConditions
          .map((condition, index) => (
            <TestDriveConditionCard
              key={condition.id}
              title={`Condição ${index + 1} `}
              discount={condition.percentual}
              dueDate={condition.daysToFirstDueDate}
              installmentsNumber={condition.installments}
              tax={condition.monthFee}
              coefficient={condition.coefficient}
              simulatedValue={getSimulatedValue(simulationBaseValue, condition, selectedOffer)}
              isSelected={condition.id === selectedConditionId}
              onClick={() => {
                onChange(condition.id);
              }}
            />
          ))}
        { aVistaConditions
          .map((condition) => (
            <AVistaConditionCard
              key={condition.id}
              title={condition.name || 'À Vista'}
              discount={selectedOffer.percentualAVista}
              value={getSimulatedValue(simulationBaseValue, condition, selectedOffer)}
              isSelected={(selectedConditionId === condition.id)}
              isRow={installmentConditions.length % 3 === 0}
              onClick={() => onChange(condition.id)}
            />
          ))}
      </div>

      { isAVistaSelected ? <AlertAVista /> : null}
    </>
  );
}

TestDriveConditionsSelector.propTypes = {
  className: PropTypes.string,

  aVistaConditions: PropTypes.array.isRequired,
  installmentConditions: PropTypes.array.isRequired,
  selectedConditionId: PropTypes.number,
  onChange: PropTypes.func,

  simulationBaseValue: PropTypes.number,
  setSimulationBaseValue: PropTypes.func.isRequired,

  offers: PropTypes.array.isRequired,
  selectedOfferId: PropTypes.number.isRequired,
  selectedOffer: PropTypes.object.isRequired,
};

TestDriveConditionsSelector.defaultProps = {
  className: '',
  simulationBaseValue: 0,
  selectedConditionId: null,
  onChange: () => { },
};

export default TestDriveConditionsSelector;
