import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import NewMultipleSelectComponent from 'features/common-staff/controls/newMultipleSelectComponent/newMultipleSelectComponent';

const dictionary = () => ({
  singular: 'concessionária',
  plural: 'concessionárias',
  type: 'a',
});

const DealerSelect = ({
  items, selectedDealers, onSelect, dealerIsLoading,
}) => {
  useEffect(() => {
    if (items.length > 0 && selectedDealers.length === 0) {
      onSelect(items);
    }
  }, [items]);

  return (
    <NewMultipleSelectComponent
      className="relatorios_select-dealer"
      dataCy="relatorios_select-dealer"
      options={items}
      setOption={onSelect}
      selectedOption={selectedDealers}
      label="Concessionária"
      dictionary={dictionary()}
      disabled={dealerIsLoading}
      minWidth={200}
    />
  );
};

DealerSelect.propTypes = {
  selectedDealers: PropTypes.array,
  onSelect: PropTypes.func,
  dealerIsLoading: PropTypes.bool,
  items: PropTypes.array,
};

DealerSelect.defaultProps = {
  selectedDealers: [],
  onSelect: () => {},
  dealerIsLoading: false,
  items: [],
};

export default DealerSelect;
