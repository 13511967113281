import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import {
  PAGE_ACOMPANHAMENTO, PAGE_SOLICITACAO, PAGE_PRODUTO,
} from './redux/operations';
import AcompanhamentoPage from './acompanhamentoPage';
import ProdutoPage from './produtoPage';
import SolicitacaoPage from './solicitacaoPage';

import Snackbars from './snackbars';

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
  },
});

const TestDrivePage = ({
  page, loadProducts,
}) => {
  useEffect(() => {
    loadProducts();
  }, [loadProducts]);
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        { (page === PAGE_ACOMPANHAMENTO) ? <AcompanhamentoPage /> : null }
        { (page === PAGE_PRODUTO) ? <ProdutoPage /> : null }
        { (page === PAGE_SOLICITACAO) ? <SolicitacaoPage /> : null }
      </div>
      <Snackbars />
    </>
  );
};

TestDrivePage.propTypes = {
  page: PropTypes.string,
  loadProducts: PropTypes.func.isRequired,
};

TestDrivePage.defaultProps = {
  page: null,
};

export default TestDrivePage;
