import React from 'react';
import PropTypes from 'prop-types';

import SelectedList from './list';
import Footer from './footer';

import './paymentModal.scss';

const PaymentModal = ({ invoices, totalValue }) => (
  <div
    className="modal-carteira__payment-modal__container"
    data-cy="modal-pagamento-container"
  >
    <div className="modal-carteira__payment-modal__container__header">
      Fila de pagamento
      <span className="modal-carteira__payment-modal__container__subtitle">
        {invoices.length > 1
          ? `${invoices.length} duplicatas selecionadas`
          : `${invoices.length} duplicata selecionada`}
      </span>
    </div>
    <div className="modal-carteira__payment-modal__container__body">
      <SelectedList invoices={invoices} />
    </div>
    <div className="modal-carteira__payment-modal__container__footer">
      <Footer totalValue={totalValue} invoices={invoices} />
    </div>
  </div>
);

PaymentModal.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.object),
  totalValue: PropTypes.number,
};

PaymentModal.defaultProps = {
  invoices: null,
  totalValue: 0,
};

export default PaymentModal;
