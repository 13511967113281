/* eslint-disable class-methods-use-this */
import axios from 'axios';
import { formatDateForUrl } from 'utils/axios';

const createBodyDownload = (relatorios, tipoRelatorio) => {
  const selected = relatorios.filter((e) => e.selected === true);
  const dealers = selected.map((e) => e.dealerId).filter((v, i, a) => a.indexOf(v) === i);
  return dealers.map((e) => ({
    dealerId: e,
    tipoRelatorio,
    nomeDocumento: selected.filter((i) => i.dealerId === e).map((i) => i.nomeDocumento),
  }));
};
class RelatorioService {
  async getRelatorio(pageParams, filtros) {
    const response = await axios.post(
      `${window.env.REACT_APP_API_URL}/relatorios-iof`,
      {
        dealers: filtros.dealers?.map((e) => e.value),
        tipoRelatorio: filtros.tipoRelatorio === '_default' ? null : filtros.tipoRelatorio,
        dataInicio: formatDateForUrl(filtros.dataInicio),
        dataFim: formatDateForUrl(filtros.dataFim),
        page: pageParams.page,
        itensPorPage: pageParams.ipp,
      },
    );
    if (response.status !== 200 || !response.data) {
      throw new Error('Erro ao listar relatorios');
    }
    return {
      ...response.data,
      relatorios: response.data.relatorios.map((e) => ({
        ...e,
        data: new Date(e.data),
        selected: false,
      })),
    };
  }
  async download(relatorios, tipoDocumento) {
    const body = createBodyDownload(relatorios, tipoDocumento);
    const isUnique = relatorios.filter((e) => e.selected === true).length === 1;
    const response = await axios.post(`${window.env.REACT_APP_API_URL}/relatorios-iof/download?isUnique=${isUnique}`, {
      relatorios: body,
    }, { responseType: 'arraybuffer' });
    if (response.status !== 200 || !response.data) {
      throw new Error('Erro ao fazer download de relatorios');
    }
    return response;
  }
}

export default new RelatorioService();

// [710301, 919654]
