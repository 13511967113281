import React from 'react';
import { PropTypes } from 'prop-types';
import { withNamespaces } from 'react-i18next';

import ButtonTooltipIcon from 'features/common/buttonTooltipIcon';
import { Tracking } from '../../../modules';
import { EyeIcon, EyeOffIcon } from '../../../assets/icons';
import { Date } from '../../common';

import ValorSaldo from './valorSaldo';

import './saldo.scss';

class Saldo extends React.Component {
  componentDidMount() {
    const { getBalance } = this.props;
    getBalance();
  }

  componentDidUpdate(prevProps) {
    const {
      dealer, getBalance,
    } = this.props;
    if (dealer !== prevProps.dealer) {
      getBalance();
    }
  }

  onEyeClicked() {
    const { toggleVisibility, visible } = this.props;
    toggleVisibility();
    Tracking.Mixpanel.trackHideBalance(visible);
  }

  render() {
    const {
      t, visible, atualizando, creditoBloqueado, creditoRemanescente, ultimaAtualizacao,
    } = this.props;

    return (
      <div
        className="balance-container"
        data-cy="BalanceContainer"
      >
        <div
          className="balance-header"
          data-cy="BalanceHeader"
        >
          <span>
            {t('Recursos Disponíveis')}
          </span>
          {
            visible
              ? (
                <ButtonTooltipIcon title="Ver informações" buttonAction={() => this.onEyeClicked()}>
                  <EyeOffIcon id="balance-eye" />
                </ButtonTooltipIcon>
              )
              : (
                <ButtonTooltipIcon title="Ocultar informações" buttonAction={() => this.onEyeClicked()}>
                  <EyeIcon id="balance-eye" />
                </ButtonTooltipIcon>
              )

          }
        </div>

        <ValorSaldo
          creditoRemanescente={creditoRemanescente}
          creditoBloqueado={creditoBloqueado}
          visible={visible && !atualizando}
        />

        <div
          className="balance-last-update"
          data-cy="BalanceLastUpdate"
        >
          <span>
            {
              (atualizando) ? (
                t('Saldo em atualização')
              ) : (
                <>
                  {t('Atualizado em ')}
                  {
                    (ultimaAtualizacao && visible)
                      ? <Date date={ultimaAtualizacao} format="DD-MM-YYYY HH:mm" />
                      : null
                  }
                </>
              )
            }
          </span>
        </div>
      </div>
    );
  }
}

Saldo.propTypes = {
  dealer: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  atualizando: PropTypes.bool.isRequired,
  creditoBloqueado: PropTypes.number,
  creditoRemanescente: PropTypes.number,
  ultimaAtualizacao: PropTypes.string,
  getBalance: PropTypes.func.isRequired,
  toggleVisibility: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

Saldo.defaultProps = {
  creditoBloqueado: null,
  creditoRemanescente: null,
  ultimaAtualizacao: null,
};

export default withNamespaces('balance')(Saldo);
