import { connect } from 'react-redux';

import VencimentoDateRange from './vencimentoDateRange';

import operations from '../../../redux/operations';

const mapStateToProps = ({ gestaoPagamentos }) => ({
  isLoading: gestaoPagamentos.carteira.page.requestStatus.isLoading,
  startDate: gestaoPagamentos.carteira.page.filters.vencimentoInicio,
  endDate: gestaoPagamentos.carteira.page.filters.vencimentoFim,
});

const mapDispatchToProps = (dispatch) => ({
  setEndDate: (value) => dispatch(operations.setFilter('vencimentoFim', value)),
  setStartDate: (value) => dispatch(operations.setFilter('vencimentoInicio', value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VencimentoDateRange);
