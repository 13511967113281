/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-tabs */
/* eslint-disable import/extensions */
import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import PropTypes from 'prop-types';
import { reactLocalStorage } from 'reactjs-localstorage';
import cookies from 'react-cookie';

import Relatorios from 'features/gestaoRelatorios';
import rcStorage from '../features/menu/view/menuAside/atualizarCadastroButton/rcStorage';

import history from './history';
import {
  PATH_LOGIN, PATH_STATEMENT, PATH_INVOICES, PATH_PAYMENTS,
  PATH_IOF_SIMULATOR, PATH_PRODUCTS, PATH_SOLICITACAO_CREDITO,
  PATH_CARTEIRA, PATH_COMPROVANTE_PAGAMENTO, PATH_RELATORIOS_IOF,
  PATH_PAGAMENTO, PATH_STATEMENT_B, PATH_DASHBOARD, PATH_CENTRAL,
  PATH_CARTEIRA_FIDC,
} from './paths';

import {
  COOKIE_AUTH_TOKEN, COOKIE_FIDIS_USER_ID,
  COOKIE_FIDIS_USER_EMAIL,
} from '../modules/auth/cookies';

import { permissoes } from '../modules/auth/permissoes';

import TrackRoutes from './trackRoutes';
import PrivateRoute from './privateRoute';
import PublicRoute from './publicRoute';

import MenuAside from '../features/menu';
import StatementPage from '../features/statement';
import SignInPage from '../features/auth/views/signInPage';
import PaymentPage from '../features/payment/views/paymentPage';
import IofSimulatorPage from '../features/iofSimulator/views/iofSimulatorPage';
import TestDrivePage from '../features/testDrive';
import SolicitacaoLimite from '../features/solicitacaoLimite';
import CarteiraPage from '../features/gestaoPagamentos/views/carteira/carteiraPage';
import CarteiraFidcPage from '../features/carteiraFidc/carteiraFidc';
import ComprovantePage from '../features/gestaoPagamentos/views/comprovante';
import PagamentoPage from '../features/gestaoPagamentos/views/pagamento';
import Dashboard from '../features/dashboard';
import ComunicadosPage from '../features/communicationCenter/pagina';
import { BRAND_FIAT } from '../utils/variables';
import { loadDealers } from '../modules/dealers/dealers';

import './routes.css';

const handleAtualizacaoCadastral = () => {
  rcStorage.clearCookies();
  rcStorage.saveInfo({
    token: reactLocalStorage.getObject(COOKIE_AUTH_TOKEN, null, true),
    userId: cookies.load(COOKIE_FIDIS_USER_ID),
    userEmail: cookies.load(COOKIE_FIDIS_USER_EMAIL),
    fidisDealers: loadDealers(),
  });
};

// TODO: passar checagem de se tem permissão X para dentro do módulo auth
// ex: Auth.checkPermission(permissions, requiredPerm)

const defaultRoute = (permissions) => {
  if (!permissions) return PATH_LOGIN;
  if (permissions.visualizarDuplicatas === true) return PATH_INVOICES;
  if (permissions.visualizarDuplicatasB === true) return PATH_CARTEIRA;
  if (permissions.visualizarMovimentacoes === true) return PATH_STATEMENT;
  if (permissions.duplicatasFidc === true
    || permissions.resgatesJurosCarencia === true) return PATH_CARTEIRA_FIDC;
  if (permissions.visualizarMovimentacoesB === true) return PATH_STATEMENT_B;
  if (permissions.simularIof === true) return PATH_IOF_SIMULATOR;
  if (permissions.solicitarTestDrive === true) return PATH_PRODUCTS;
  if (permissions.alteracaoCredito === true
   || permissions.transferenciaCredito === true
   || permissions.liberacaoLimite === true) return PATH_SOLICITACAO_CREDITO;
  if (permissions.dashboardFloorPlan === true) return PATH_DASHBOARD;
  if (permissions.centralComunicacao === true) return PATH_CENTRAL;
  if (permissions.relatoriosIof === true) return PATH_RELATORIOS_IOF;
  if (permissions.rcAcessarRenovacao === true) {
    handleAtualizacaoCadastral();
    document.location.replace(window.env.REACT_APP_RC_URL);
  }
  return '/default';
};

const handleQueryParams = (requestOpenFeedbackTestDrive) => {
  const params = window.location.search;
  const isString = (String(params) === params);

  if (isString && params.includes('?feedback=td1')) {
    if (typeof requestOpenFeedbackTestDrive === 'function') {
      requestOpenFeedbackTestDrive();
    }
  }
};

// eslint-disable-next-line arrow-body-style
const Routes = ({ permissions, requestOpenFeedbackTestDrive }) => {
  handleQueryParams(requestOpenFeedbackTestDrive);

  return (
    <ConnectedRouter history={history}>
      <TrackRoutes>
        <Switch>
          <PrivateRoute
            exact
            path={PATH_STATEMENT}
            requiredAuthorizations={[permissoes.movimentacoes]}
          >
            <section className="app-container app-container--sub-menu">
              <MenuAside />
            </section>
          </PrivateRoute>

          <PrivateRoute
            exact
            path={PATH_STATEMENT_B}
            requiredAuthorizations={[permissoes.movimentacoesB]}
          >
            <section className="app-container app-container--sub-menu">
              <MenuAside />
              <StatementPage className="app-content" />
            </section>
          </PrivateRoute>

          <PrivateRoute
            exact
            path={PATH_CARTEIRA}
            requiredAuthorizations={[permissoes.duplicatasB]}
          >
            <section className="app-container app-container--sub-menu">
              <MenuAside />
              <CarteiraPage className="app-content" />
            </section>
          </PrivateRoute>

          <PrivateRoute
            path={PATH_CARTEIRA_FIDC}
            requiredAuthorizations={[permissoes.fidc.duplicatasFidc]}
            requiredBrands={[BRAND_FIAT]}
          >
            <section className="app-container app-container--sub-menu">
              <MenuAside />
              <CarteiraFidcPage />
            </section>
          </PrivateRoute>

          <PrivateRoute
            exact
            path={PATH_INVOICES}
            requiredAuthorizations={[permissoes.duplicatas]}
          >
            <section className="app-container app-container--sub-menu">
              <MenuAside />
            </section>
          </PrivateRoute>

          <PrivateRoute
            path={PATH_COMPROVANTE_PAGAMENTO}
            requiredAuthorizations={[permissoes.duplicatasB]}
          >
            <section className="app-container app-container--sub-menu">
              <MenuAside />
              <ComprovantePage className="app-content" />
            </section>
          </PrivateRoute>

          <PrivateRoute
            path={PATH_PAGAMENTO}
            requiredAuthorizations={[permissoes.efetuarPagamentoB]}
          >
            <section className="app-container app-container--sub-menu">
              <MenuAside />
              <PagamentoPage className="app-content" />
            </section>
          </PrivateRoute>

          <PrivateRoute
            exact
            path={PATH_PAYMENTS}
            requiredAuthorizations={[permissoes.efetuarPagamento]}
          >
            <section className="app-container app-container--sub-menu">
              <MenuAside />
              <PaymentPage className="app-content" />
            </section>
          </PrivateRoute>

          <PrivateRoute
            exact
            path={PATH_IOF_SIMULATOR}
            requiredAuthorizations={[permissoes.iof]}
          >
            <section className="app-container app-container--sub-menu">
              <MenuAside />
              <IofSimulatorPage className="app-content" />
            </section>
          </PrivateRoute>

          <PrivateRoute
            exact
            path={PATH_PRODUCTS}
            requiredAuthorizations={[permissoes.testDrive]}
          >
            <section className="app-container app-container--sub-menu">
              <MenuAside />
              <TestDrivePage className="app-content" />
            </section>
          </PrivateRoute>

          <PrivateRoute
            path={PATH_SOLICITACAO_CREDITO}
            requiredAuthorizations={[...Object.values(permissoes.limite)]}
          >
            <section className="app-container app-container--sub-menu">
              <MenuAside />
              <SolicitacaoLimite className="app-content" />
            </section>
          </PrivateRoute>

          <PrivateRoute
            path={PATH_DASHBOARD}
            requiredAuthorizations={[permissoes.dashboardFloorPlan]}
          >
            <section className="app-container app-container--sub-menu">
              <MenuAside />
              <Dashboard className="app-content" />
            </section>
          </PrivateRoute>
					<PrivateRoute
						path={PATH_CENTRAL}
						requiredAuthorizations={[permissoes.centralComunicacao]}
					>
						<section className="app-container app-container--sub-menu">
						<MenuAside />
						<ComunicadosPage />
						</section>
					</PrivateRoute>

					<PrivateRoute
						path={PATH_RELATORIOS_IOF}
						requiredAuthorizations={[permissoes.relatoriosIof]}
					>
						<section className="app-container app-container--sub-menu">
						<MenuAside />
						<Relatorios />
						</section>
					</PrivateRoute>

          <PublicRoute
            exact
            path={PATH_LOGIN}
            component={SignInPage}
          />

          <Redirect
            path="*"
            to={defaultRoute(permissions)}
          />
        </Switch>
      </TrackRoutes>
    </ConnectedRouter>
  );
};

Routes.propTypes = {
  permissions: PropTypes.object,
  requestOpenFeedbackTestDrive: PropTypes.func.isRequired,
};

Routes.defaultProps = {
  permissions: null,
};

export default Routes;
