import { connect } from 'react-redux';

import * as SolicitacaoOperations from '../../redux/testDriveOperations';
import * as PageSelectors from '../../../redux/selectors';

import TestDriveOfferSelector from './testDriveOfferSelector';

const mapStateToProps = ({ testDrive }) => ({
  offers: testDrive.pedido.offers,
  selectedOfferId: testDrive.pedido.selectedOfferId,
  produto: PageSelectors.finalProduct(testDrive),
  isOffersLoading: testDrive.pedido.isOffersLoading,
  showOffersLoadError: testDrive.pedido.showOffersLoadError,
});

const mapDispatchToProps = (dispatch) => ({
  getOffers: (marcas, produto) => dispatch(SolicitacaoOperations.getOffers(marcas, produto)),
  getMotivosTestDriveExcecao: () => {
    dispatch(SolicitacaoOperations.getMotivosTestDriveExcecao());
  },
  setSelectedCondition: (id) => dispatch(SolicitacaoOperations.setSelectedCommercialCondition(id)),
  setSelectedDealerId: (id) => dispatch(SolicitacaoOperations.setSelectedDealerId(id)),
  setSelectedOffer: (id) => dispatch(SolicitacaoOperations.setSelectedOffer(id)),
  getCommercialConditions: (modelo, versao, serie, modelYear, marca, produto) => dispatch(
    SolicitacaoOperations.getCommercialConditions(modelo, versao, serie, modelYear, marca, produto),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(TestDriveOfferSelector);
