import { React } from 'react';
import PropTypes from 'prop-types';

import { SendRounded } from '@material-ui/icons';
import { LoadingButton } from '@mui/lab';

const SendButton = ({ loading, color }) => (
  <LoadingButton
    type="submit"
    color={color}
    variant="contained"
    loading={loading}
    disableElevation
    sx={{
      padding: '10px 12px',
      minWidth: 38,
      height: 38,
    }}
  >
    <SendRounded style={{ fontSize: '18px' }} />
  </LoadingButton>
);

SendButton.propTypes = {
  loading: PropTypes.bool,
  color: PropTypes.string,
};

SendButton.defaultProps = {
  loading: false,
  color: 'primary500',
};

export default SendButton;
