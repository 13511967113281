/* eslint-disable class-methods-use-this */

import axios from 'axios';

class TransferenciaLimiteService {
  URL_API_LC = window.env.REACT_APP_API_URL;

  makeSolicitacaoDto(user, dadosSolicitacao, dealers) {
    const { valor, dadosOrigem, dadosDestino } = dadosSolicitacao;
    const dealerOrigem = dealers.find((d) => d.dealerId === dadosOrigem.selectedDealerId);
    const dealerDestino = dealers.find((d) => d.dealerId === dadosDestino.selectedDealerId);
    return {
      tipo: 'transferencia_limite',
      valor,
      marcaOrigem: dadosOrigem.brand,
      marcaDestino: dadosDestino.brand,
      dadosOrigem: {
        produtoId: dadosOrigem.produto,
        codigoSap: dadosOrigem.codigoSap,
        concessionaria: {
          codigo: dealerOrigem.dealerId,
          nome: dealerOrigem.dealerName,
          marca: dealerOrigem.brand,
          cnpj: dealerOrigem.cnpj,
        },
        regiaoCodigoSecurity: dealerOrigem.regionId,
        regiaoNome: dealerOrigem.region,
      },
      dadosDestino: {
        produtoId: dadosDestino.produto,
        codigoSap: dadosDestino.codigoSap,
        concessionaria: {
          codigo: dealerDestino.dealerId,
          nome: dealerDestino.dealerName,
          marca: dealerDestino.brand,
          cnpj: dealerDestino.cnpj,
        },
        regiaoCodigoSecurity: dealerDestino.regionId,
        regiaoNome: dealerDestino.region,
      },
      usuarioEmail: user.email,
      usuarioNome: user.name,
    };
  }

  async postPedidoAnaliseCredito(dto) {
    const response = await axios.post(`${this.URL_API_LC}/solicitacoes`, dto);
    if (response.status !== 200) {
      throw new Error('Erro ao inserir a solicitação!');
    }
    return response.data;
  }

  async getProdutos() {
    const response = await axios.get(`${this.URL_API_LC}/solicitacoes/produtos`);
    if (response.status !== 200 || !response.data) {
      throw new Error('Error getting produtos');
    }

    const produtos = response.data.map((p) => ({
      label: p.nome,
      value: p.idProduto,
    }));

    return produtos;
  }
}
export default new TransferenciaLimiteService();
