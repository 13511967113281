import { connect } from 'react-redux';

import FilterButton from 'features/common-staff/controls/buttonFilter';
import operations from '../../../redux/operations';

const mapStateToProps = ({ comunicados }) => ({
  isFilterSelected: comunicados?.page?.isFilterSelected,
  isLoading: comunicados?.page?.requestStatus?.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  onClick: () => dispatch(operations.setPageParams('page', 0)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterButton);
