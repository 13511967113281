import { connect } from 'react-redux';

import DuplicatasVencidasArea from './duplicatasVencidasArea';

const mapStateToProps = ({ dashboard }) => ({
  itens: dashboard.painelFloorPlan.cardsVencimento.data,
  isLoading: dashboard.painelFloorPlan.cardsVencimento.isLoading,
  isError: dashboard.painelFloorPlan.cardsVencimento.isError,
});

export default connect(mapStateToProps, null)(DuplicatasVencidasArea);
