import { combineReducers } from 'redux';

import limiteAlteracaoReducer from '../views/alteracaoLimitePage/redux/reducer';
import limiteTransferenciaReducer from '../views/transferenciaLimitePage/redux/reducer';
import AcompanhamentoReducer from '../views/acompanhamentoPage/redux/reducer';

// TODO:MUDAR NOME pra limite e alteracao
export default combineReducers({
  alteracaoLimite: limiteAlteracaoReducer,
  transferenciaLimite: limiteTransferenciaReducer,
  acompanhamento: AcompanhamentoReducer,
});
