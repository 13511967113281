import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';
import _ from 'lodash';

import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '../textField';

const useStyles = makeStyles(() => ({
  input: {
    '&::placeholder': {
      opacity: '25%',
      color: '#555770',
    },
  },
  root: {
    height: '40px',
  },
  container: {
    marginTop: '1px',
  },
}));

const TextFilterDebounce = ({
  value, setValue, onChange, label, placeholder,
  showSearchIcon, disabled,
}) => {
  const debouncedOnChange = useCallback(debounce(500, () => {
    onChange();
  }), []);
  const classes = useStyles();
  return (
    <TextField
      className={classes.container}
      InputProps={{
        startAdornment:
        showSearchIcon
          ? <SearchIcon style={{ fill: '#8f9bb3', color: '#8f9bb3', marginRight: '8px' }} />
          : null,
        classes: { input: classes.input, root: classes.root },
      }}
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={(text) => {
        setValue(_.isEmpty(text) ? null : text);
        debouncedOnChange();
      }}
      disabled={disabled}
    />
  );
};

TextFilterDebounce.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  showSearchIcon: PropTypes.bool,
  disabled: PropTypes.bool,
};

TextFilterDebounce.defaultProps = {
  value: '',
  setValue: () => {},
  onChange: () => {},
  label: null,
  placeholder: null,
  showSearchIcon: false,
  disabled: false,
};

export default TextFilterDebounce;
