/* eslint-disable class-methods-use-this */

import axios from 'axios';

class AlteracaoLimiteService {
  URL_API_LC = window.env.REACT_APP_API_URL;

  makeSolicitacaoDto(user, dadosSolicitacao, dealers) {
    const {
      motivo, valor, isPositivo, selectedDealerId, produto, codigoSap, brand,
    } = dadosSolicitacao;
    const dealer = dealers.find((d) => d.dealerId === selectedDealerId);
    return {
      tipo: isPositivo ? 'alteracao_aumento' : 'alteracao_reducao',
      valor,
      motivo,
      marcaOrigem: brand,
      marcaDestino: brand,
      dadosOrigem: {
        produtoId: produto,
        codigoSap,
        concessionaria: {
          codigo: dealer.dealerId,
          nome: dealer.dealerName,
          marca: dealer.brand,
          cnpj: dealer.cnpj,
        },
        regiaoCodigoSecurity: dealer.regionId,
        regiaoNome: dealer.region,
      },
      dadosDestino: {
        produtoId: produto,
        codigoSap,
        concessionaria: {
          codigo: dealer.dealerId,
          nome: dealer.dealerName,
          marca: dealer.brand,
          cnpj: dealer.cnpj,
        },
        regiaoCodigoSecurity: dealer.regionId,
        regiaoNome: dealer.region,
      },
      usuarioEmail: user.email,
      usuarioNome: user.name,
    };
  }

  async postPedidoAnaliseCredito(dto) {
    const response = await axios.post(`${this.URL_API_LC}/solicitacoes`, dto);
    if (response.status !== 200) {
      throw new Error('Erro ao inserir a solicitação!');
    }
    return response.data;
  }

  async getProdutos() {
    const response = await axios.get(`${this.URL_API_LC}/solicitacoes/produtos`);
    if (response.status !== 200 || !response.data) {
      throw new Error('Error getting produtos');
    }

    const produtos = response.data.map((p) => ({
      label: p.nome,
      value: p.idProduto,
    }));

    return produtos;
  }
}
export default new AlteracaoLimiteService();
