import React from 'react';
import PropTypes from 'prop-types';

import './userInfo.scss';

const getInitials = (nome) => {
  const firstName = nome.split(' ')[0];
  const secondName = nome.split(' ')[1];
  if (firstName && secondName) return (`${firstName.substring(0, 1)}${secondName.substring(0, 1)}`);
  return null;
};

const UserInfo = ({ nomeUsuario, emailUsuario }) => (
  <div className="user_data">
    <div
      className="user_data__icon"
      data-cy="UserDataIcon"
    >
      <div className="user_data__icon-initials">
        <span className="user_data__icon-initials-text">{getInitials(nomeUsuario)}</span>
      </div>
    </div>
    <div
      className="user_data__user"
      data-cy="UserData"
    >
      <div className="user_data__user_name">
        {nomeUsuario}
      </div>
      <div className="user_data__user_email">
        {emailUsuario}
      </div>
    </div>
  </div>
);

UserInfo.propTypes = {
  nomeUsuario: PropTypes.string,
  emailUsuario: PropTypes.string,
};

UserInfo.defaultProps = {
  nomeUsuario: null,
  emailUsuario: null,
};

export default UserInfo;
