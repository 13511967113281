export const deleteSelectedFile = (selectedFiles, fileName) => {
  const files = selectedFiles.filter((f) => f.name !== fileName);
  return [...files];
};

export const setFileUploadProgress = (selectedFiles, fileName, value) => {
  const files = ({}, selectedFiles);
  const index = selectedFiles.findIndex((f) => f.name === fileName);
  files[index].progress = value;
  return [...files];
};

export const setFileUploadError = (selectedFiles, fileName) => {
  const files = ({}, selectedFiles);
  const index = selectedFiles.findIndex((f) => f.name === fileName);
  files[index].isError = true;
  return [...files];
};

export const updateFileInfo = (selectedFiles, fileName, fileInfo, progress) => {
  const files = ({}, selectedFiles);
  const index = selectedFiles.findIndex((f) => f.name === fileName);

  files[index].progress = progress;
  files[index].isError = false;
  files[index].urlUpload = fileInfo.urlUpload;
  files[index].urlPreview = fileInfo.urlPreview;
  files[index].key = fileInfo.key;

  return [...files];
};

export const addNewFile = (selectedFiles, newFile) => {
  const file = newFile;
  file.progress = 0;
  file.isError = false;

  const files = ({}, selectedFiles);
  files.push(file);
  return [...files];
};

export const addFiles = (selectedFiles, filesToSend) => {
  const returnArray = [...selectedFiles, ...filesToSend];
  return returnArray;
};
