import { connect } from 'react-redux';
import operations from '../redux/operations';
import operationsPage from '../../limitesAprovadosPage/redux/operations';
import ButtonsFooter from './buttonsFooter';
import selector from '../redux/selector';

const mapStateToProps = ({ limitesAprovadosCadastro }) => ({
  documentosCadastro: selector.documentosCadastro(limitesAprovadosCadastro),
  documentosJuridico: selector.documentosJuridico(limitesAprovadosCadastro),
  documentosFormalizar: selector.documentosFormalizar(limitesAprovadosCadastro),
});

const mapDispatchToProps = (dispatch) => ({
  enviarProposta: async (idLimite, status) => {
    const result = await dispatch(operations.updateAndSaveStatus(idLimite, status));
    if (result) dispatch(operationsPage.setCadastroPage(false));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ButtonsFooter);
