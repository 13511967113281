import React from 'react';

import './page.scss';

export const PageHeader = ({
  // eslint-disable-next-line react/prop-types
  children, className,
}) => (
  <div
    className={`common__page-header ${className}`}
    data-cy="PageHeader"
  >
    {children}
  </div>
);

export const PageTitle = ({
  // eslint-disable-next-line react/prop-types
  children,
}) => (
  <div
    className="common__page-header__title"
    data-cy="PageTitle"
  >
    {children}
  </div>
);
export const PageSubTitle = ({
  // eslint-disable-next-line react/prop-types
  children,
}) => (
  <div
    className="common__page-header__subtitle"
    data-cy="PageSubTitle"
  >
    {children}
  </div>
);

export const PageContent = ({
  // eslint-disable-next-line react/prop-types
  children,
}) => (
  <div
    className="common__page-content"
    data-cy="PageContent"
  >
    {children}
  </div>
);

export const PageFooter = ({
  // eslint-disable-next-line react/prop-types
  children,
}) => (
  <div
    className="common__page-footer"
    data-cy="PageFooter"
  >
    {children}
  </div>
);
