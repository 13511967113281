import { createSelector } from 'reselect';
import _ from 'lodash';
import {
  camelFormat, formatNomeConcessionaria, formatCodigoConcessionaria,
} from 'utils/format';
import { Sistemas } from './enum';

const concessionariaList = createSelector(
  (state) => state.dealers,
  (state) => state.selectedFPlanDealerId,
  (concessionarias, selectedDealer) => {
    const raizCnpj = concessionarias.find((c) => c.dealerId === selectedDealer)
      .cnpj.substring(0, 8);
    const filtedConcessionarias = concessionarias
      .filter((c) => c.cnpj.substring(0, 8) === raizCnpj);
    return (filtedConcessionarias || []).map((c) => ({
      value: c.dealerId,
      text: `${formatNomeConcessionaria(c.dealerName.trim())} - ${formatCodigoConcessionaria(c.dealerId)}`,
    }));
  },
);

const statusList = createSelector(
  (state) => state.carteira.page.selectors.status,
  (status) => (status || []).map((s) => ({
    value: s?.id,
    text: s?.descricao.toLowerCase() === 'quitada' ? 'Liquidada' : camelFormat(s?.descricao),
  })),
);

const docList = createSelector(
  (state) => state.carteira.page.selectors.docs,
  (docs) => (docs || []).map((d) => ({
    value: d.toUpperCase(),
    text: d,
  })),
);

const produtoList = createSelector(
  (state) => state.carteira.page.selectors.produto,
  (produto) => (produto || []).map((p) => ({
    value: p?.id,
    text: camelFormat(p?.descricao, 2),
  })),
);

const carenciaList = createSelector(
  (state) => state.carteira.page.selectors.carencia,
  (carencia) => (carencia || []).map((c) => ({
    value: c.id,
    text: c.description,
  })),
);

const selectedFilter = createSelector(
  (state) => state.carteira.page.filters.status,
  (state) => state.carteira.page.filters.carencia,
  (state) => state.carteira.page.filters.produto,
  (state) => state.carteira.page.filters.docs,
  (state) => state.carteira.page.filters.modelo,
  (state) => state.carteira.page.filters.versao,
  (state) => state.carteira.page.filters.vencimentoFim,
  (state) => state.carteira.page.filters.vencimentoInicio,
  (state) => state.carteira.page.filters.emissaoFim,
  (state) => state.carteira.page.filters.emissaoInicio,
  (state) => state.carteira.page.filters.valor,
  (
    status,
    carencia,
    produto,
    doc,
    modelo,
    versao,
    vencimentoFim,
    vencimentoInicio,
    emissaoFim,
    emissaoInicio,
    valor,
  ) => (!_.isNull(status) || !_.isNull(carencia)
|| !_.isNull(produto) || !_.isNull(doc) || !_.isNull(modelo) || !_.isNull(versao)
|| !_.isNull(vencimentoFim) || !_.isNull(vencimentoInicio)
|| !_.isNull(emissaoInicio) || !_.isNull(emissaoFim) || !_.isNull(valor[0]) || !_.isNull(valor[1])),
);

const selectAllInvoices = createSelector(
  (state) => state.carteira.page.invoices,
  (state) => state.carteira.page.selectAll,
  (invoices, selectedAll) => {
    if (selectedAll) {
      return (invoices || []).map((i) => {
        if (i.status !== 'paid') {
          return i;
        }
        return null;
      });
    }
    return [];
  },
);

const selectSystem = createSelector(
  (state) => state.selectedFPlanDealerId,
  (state) => state.dealers,
  (selected, dealers) => {
    const brand = dealers.find((m) => m.dealerId === selected).brand.toLowerCase().trim();
    if (brand === 'fiat' || brand === 'iveco') {
      return Sistemas.pla;
    }
    if (brand === 'jeep' || brand === 'chrysler' || brand === 'dodge' || brand === 'ram') {
      return Sistemas.chr;
    }
    return null;
  },
);

export default {
  concessionariaList,
  statusList,
  docList,
  produtoList,
  carenciaList,
  selectedFilter,
  selectAllInvoices,
  selectSystem,
};
