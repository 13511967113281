import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from 'utils/format';
import CardBody from '../cardBody';

import './transferenciaCard.scss';

const TransferenciaCard = ({
  solicitacao, valor, solicitanteEmail, concessionariaOrigem,
  concessionariaDestino, produtoOrigem, produtoDestino, marcaOrigem,
  marcaDestino,
}) => (
  <div className="acompanhamento__detalhe__card-transferencia">
    <div className="acompanhamento__detalhe__card-transferencia__header">
      <div className="acompanhamento__detalhe__card-transferencia__header-title">
        {solicitacao}
      </div>
      <div className="acompanhamento__detalhe__card-transferencia__header-valor">
        {formatCurrency(valor)}
      </div>
    </div>
    <div className="acompanhamento__detalhe__card-transferencia__content-origem">
      <CardBody
        solicitanteEmail={solicitanteEmail}
        label="Concessionária Origem"
        concessionaria={concessionariaOrigem}
        produto={produtoOrigem}
        brand={marcaOrigem}
      />
    </div>
    <div className="acompanhamento__detalhe__card-transferencia__content-destino">
      <CardBody
        label="Concessionária Destino"
        concessionaria={concessionariaDestino}
        produto={produtoDestino}
        brand={marcaDestino}
      />
    </div>
  </div>
);

TransferenciaCard.propTypes = {
  solicitacao: PropTypes.string,
  valor: PropTypes.number,
  solicitanteEmail: PropTypes.string,
  concessionariaOrigem: PropTypes.object,
  concessionariaDestino: PropTypes.object,
  produtoOrigem: PropTypes.string,
  produtoDestino: PropTypes.string,
  marcaOrigem: PropTypes.string,
  marcaDestino: PropTypes.string,
};

TransferenciaCard.defaultProps = {
  solicitacao: '',
  valor: 0,
  solicitanteEmail: '',
  concessionariaOrigem: null,
  concessionariaDestino: null,
  produtoOrigem: '',
  produtoDestino: '',
  marcaOrigem: '',
  marcaDestino: '',
};

export default TransferenciaCard;
