import React from 'react';
import PropTypes from 'prop-types';

import { formatCodigoConcessionaria } from 'utils/format';
import FormInput from 'features/common-staff/controls/input/formInput/';

const InputConcessionariaCodigo = ({
  concessionariaCodigo,
}) => (
  <FormInput
    type="number"
    label="Cód. Anagráfico"
    value={concessionariaCodigo.toString()}
    format={formatCodigoConcessionaria}
  />
);

InputConcessionariaCodigo.propTypes = {
  concessionariaCodigo: PropTypes.number,
};

InputConcessionariaCodigo.defaultProps = {
  concessionariaCodigo: null,
};

export default InputConcessionariaCodigo;
