import React from 'react';

import './alertAVista.scss';

const alertTextB = 'Vimos que você está solicitando um test-drive à vista. Informamos que o Banco Stellantis possui condições especiais de financiamento e você pode escolher uma delas através do nosso Hub.';
const alertTextF = 'Se o seu desejo realmente é o faturamento à vista, ao enviar sua solicitação, ela será direcionada para o seu escritório Regional Stellantis, onde você poderá acompanhar diretamente o seu pedido.';

const AlertAVista = () => (
  <div className="avista-alerta-container">
    <div className="avista-alerta-content">
      <span className="avista-alerta-text">{alertTextB}</span>
      <span className="avista-alerta-text">{alertTextF}</span>
    </div>
  </div>
);

export default AlertAVista;
