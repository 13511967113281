import React from 'react';
import PropTypes from 'prop-types';

import {
  safeConcat, formatDate, formatCnpj, formatCodigoConcessionaria, formatDescVeiculo,
} from 'utils/format';
import ArquivosExcecao from './arquivosExcecaoList';

import './cardResumo.scss';

const DataRow = ({
  // eslint-disable-next-line react/prop-types
  label, value,
}) => (
  <div className="pedidos__card-resumo__data-row">
    <div className="pedidos__card-resumo__data-row-label">
      {label}
    </div>
    <div className="pedidos__card-resumo__data-row-value">
      {value}
    </div>
  </div>
);

const Card = ({
  // eslint-disable-next-line react/prop-types
  modeloDescricao, modelYear, corretorCodigo, cnpj, solicitante, pedidoId,
}) => (
  <div className="pedidos__card-resumo__card">
    <div className="pedidos__card-resumo__model">
      <div className="pedidos__card-resumo__model-desc">
        {modeloDescricao}
      </div>
      <div className="pedidos__card-resumo__model-year">
        {safeConcat('Model Year ', modelYear)}
      </div>
    </div>
    <div className="pedidos__card-resumo__card-lower">
      <DataRow
        label="Número do Pedido"
        value={safeConcat('#', pedidoId)}
      />
      <DataRow
        label="Concessionária"
        value={formatCodigoConcessionaria(corretorCodigo)}
      />
      <DataRow
        label="CNPJ"
        value={formatCnpj(cnpj)}
      />
      <DataRow
        label="Solicitante"
        value={solicitante}
      />
    </div>
  </div>
);

const CardResumo = ({
  isLoading, cnpj, solicitante, corretorCodigo,
  modeloDescricao, modelYear, produto, data, urlModelo, arquivosExcecao, pedidoId,
}) => (
  <div className="pedidos__card-resumo__container">
    <div className="pedidos__card-resumo__header">
      <div className="pedidos__card-resumo__header-produto">
        {safeConcat('Pedido de ', produto)}
      </div>
      <div className="pedidos__card-resumo__header-data">
        {safeConcat('Realizado em ', formatDate(data, 'DD [de] MMM YYYY [às] HH:mm'))}
      </div>
    </div>

    <div className="pedidos__card-resumo__img">
      {urlModelo ? (
        <img src={urlModelo} alt="Modelo" />
      ) : null}
    </div>

    {!isLoading ? (
      <Card
        modeloDescricao={formatDescVeiculo(modeloDescricao)}
        modelYear={modelYear}
        corretorCodigo={corretorCodigo}
        solicitante={solicitante}
        cnpj={cnpj}
        pedidoId={pedidoId}
      />
    ) : null}

    {arquivosExcecao?.length > 0
      ? (
        <div className="pedidos__card-resumo__excecao">
          <ArquivosExcecao arquivosExcecao={arquivosExcecao} />
        </div>
      )
      : null}
  </div>
);

CardResumo.propTypes = {
  isLoading: PropTypes.bool,
  cnpj: PropTypes.string,
  corretorCodigo: PropTypes.string,
  modeloDescricao: PropTypes.string,
  modelYear: PropTypes.string,
  solicitante: PropTypes.string,
  produto: PropTypes.string,
  data: PropTypes.object,
  urlModelo: PropTypes.string,
  arquivosExcecao: PropTypes.array,
  pedidoId: PropTypes.number,
};

CardResumo.defaultProps = {
  isLoading: null,
  cnpj: null,
  solicitante: null,
  corretorCodigo: null,
  modeloDescricao: null,
  modelYear: null,
  produto: null,
  data: null,
  urlModelo: null,
  arquivosExcecao: null,
  pedidoId: null,
};

export default CardResumo;
