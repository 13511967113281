import React from 'react';
import PropTypes from 'prop-types';

import { formatCurrency } from 'utils/format';

import './valor.scss';

const defineClass = (status) => {
  if (status.toLowerCase().trim() === 'quitada') {
    return 'carteira-modal__valor-paid';
  }
  if (status.toLowerCase().trim() === 'em atraso') {
    return 'carteira-modal__valor-delayed';
  }
  return 'carteira-modal__valor-opened';
};

const Valor = ({ valor, status }) => (
  <div className={defineClass(status)}>
    {formatCurrency(valor)}
  </div>
);

Valor.propTypes = {
  valor: PropTypes.number,
  status: PropTypes.string,
};

Valor.defaultProps = {
  valor: 0,
  status: '',
};

export default Valor;
