import React from 'react';
import PropTypes from 'prop-types';

import Currency from '../currency';
import ClockIcon from '../../../assets/icons/clock';

import './duplicataCurrency.scss';

const getClassName = (clock, red) => {
  if (clock) {
    return 'duplicataCurrency__container--grey';
  }
  if (red) {
    return 'duplicataCurrency__container--red';
  }
  return '';
};

export const DuplicataCurrency = ({
  className, value, red, clock, lineThrough,
}) => (
  <div className={`${className} duplicataCurrency__container ${getClassName(clock, red)}`}>
    {
        clock ? <ClockIcon /> : null
    }
    <Currency
      className={lineThrough ? 'duplicataCurrency__value--lineThrough' : ''}
      value={value}
      noColor
    />
  </div>
);

DuplicataCurrency.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,

  red: PropTypes.bool,
  clock: PropTypes.bool,
  lineThrough: PropTypes.bool,
};

DuplicataCurrency.defaultProps = {
  className: null,
  value: null,

  red: false,
  clock: false,
  lineThrough: false,
};

export default DuplicataCurrency;
