/* eslint-disable */
import * as React from "react";

function NewsIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M23.0826 11.6776L21.2226 9.55759L21.4826 6.74759C21.5326 6.24759 21.1926 5.78759 20.7126 5.67759L17.9526 5.04759L16.5126 2.61759C16.2526 2.18759 15.7226 2.00759 15.2626 2.20759L12.6626 3.33759L10.0726 2.22759C9.61262 2.02759 9.07262 2.20759 8.82262 2.63759L7.37262 5.05759L4.62262 5.67759C4.13262 5.78759 3.79262 6.23759 3.84262 6.74759L4.10262 9.54759L2.24262 11.6776C1.91262 12.0576 1.91262 12.6176 2.24262 12.9976L4.10262 15.1176L3.84262 17.9376C3.79262 18.4376 4.13262 18.8976 4.61262 19.0076L7.37262 19.6376L8.81262 22.0576C9.07262 22.4876 9.60262 22.6676 10.0726 22.4676L12.6626 21.3376L15.2526 22.4476C15.7126 22.6476 16.2526 22.4676 16.5026 22.0376L17.9426 19.6076L20.7026 18.9776C21.1926 18.8676 21.5226 18.4076 21.4726 17.9076L21.2126 15.0976L23.0726 12.9776C23.4126 12.6176 23.4126 12.0576 23.0826 11.6776ZM11.6626 17.3376V15.3376H13.6626V17.3376H11.6626ZM11.6626 12.3376C11.6626 12.8876 12.1126 13.3376 12.6626 13.3376C13.2126 13.3376 13.6626 12.8876 13.6626 12.3376V8.33759C13.6626 7.78759 13.2126 7.33759 12.6626 7.33759C12.1126 7.33759 11.6626 7.78759 11.6626 8.33759V12.3376Z" fill="#EF7D00"/>
    </svg>
	);
}

export default NewsIcon;
