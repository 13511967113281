import React, { useEffect, useState } from 'react';
import {
  saveIdFidcAceites,
  loadIdFidcAceites,
} from 'modules/resgates/resgatesAceites';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import _ from 'lodash';
import Modal from 'features/common-staff/layout/modal';
import { useResgateContext } from 'features/carteiraFidc/contexts/resgateContext';
import resgateService from 'features/carteiraFidc/services/resgateService';
import { useOnboardingContext } from 'features/carteiraFidc/contexts/onboardingContext';
import { useDuplicataContext } from 'features/carteiraFidc/contexts/duplicataContext';
import JurosCarenciaMessage from '../jurosCarenciaMessage/jurosCarenciaMessage';
import { ModalContent } from './jurosCarenciaModal.style';

const JurosCarenciaModal = () => {
  const [modalVisualizado, setModalVisualizado] = useState(false);
  const resgateContext = useResgateContext();
  const {
    state, addSnackbar, getResgate, setResgate,
  } = resgateContext;
  const { jurosCarencia, requestStatus } = state;
  const { success } = requestStatus;

  const stateOnboarding = useOnboardingContext().state;
  const {
    onboarding,
    duplicatas: duplicatasReady,
    requestStatus: requestStatusOnboarding,
  } = stateOnboarding;
  const successOnboarding = requestStatusOnboarding.success;
  const historicoDuplicata = onboarding?.HISTORICO_DUPLICATA;
  const menuResgate = onboarding?.MENU_RESGATE;

  const duplicataContext = useDuplicataContext();
  const {
    success: successDuplicata,
    loading: loadingDuplicata,
  } = duplicataContext.state.requestStatus;

  const { pathname } = useLocation();
  const resgatePage = pathname.includes('/resgates/');

  const [ocultar, setOcultar] = useState(jurosCarencia?.ocultarMensagem);
  useEffect(() => {
    if (success) {
      setOcultar(jurosCarencia?.ocultarMensagem);
    }
  }, [success, jurosCarencia]);

  const aceitesLocalStorage = loadIdFidcAceites();
  if (aceitesLocalStorage && aceitesLocalStorage.length > 0 && jurosCarencia) {
    const existAceiteLocalStorage = aceitesLocalStorage.includes(jurosCarencia.id);
    if (existAceiteLocalStorage && !modalVisualizado) setModalVisualizado(true);
    if (modalVisualizado && !existAceiteLocalStorage) setModalVisualizado(false);
  }

  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    setOpenModal(
      success && !resgatePage && !modalVisualizado
      && jurosCarencia && !jurosCarencia?.ocultarMensagem
      && successOnboarding
      && (successDuplicata || !loadingDuplicata)
      && !((duplicatasReady && historicoDuplicata?.active)
        || menuResgate?.active),
    );
  }, [modalVisualizado, success, resgatePage, jurosCarencia,
    stateOnboarding, successDuplicata, loadingDuplicata]);

  const closeModal = (updateOcultar = true) => {
    const loadItensLocalStorage = loadIdFidcAceites();
    let array = [];
    if (loadItensLocalStorage && loadItensLocalStorage !== 0) {
      array = array.concat(loadItensLocalStorage);
    }
    array.push(jurosCarencia.id);
    array = array.filter((item, index) => array.indexOf(item) === index);
    saveIdFidcAceites(array);
    setOpenModal(false);
    if (updateOcultar && jurosCarencia?.ocultarMensagem !== ocultar) {
      resgateService.updateAceiteConcessionaria(ocultar, jurosCarencia?.aceiteResgate);
      setResgate(['ocultarMensagem'], [ocultar]);
    }
  };

  const data = _.isNull(jurosCarencia) || _.isEmpty(jurosCarencia) ? undefined : jurosCarencia;
  return (
    openModal
      ? (
        <Modal
          width="806px"
          height="409px"
          closeModal={closeModal}
          showCloseText={false}
          dataCy="ModalResgateJurosCarencia"
        >
          <ModalContent>
            <JurosCarenciaMessage
              isModal
              jurosCarencia={data}
              addSnackbar={addSnackbar}
              getResgate={getResgate}
              closeModal={closeModal}
              ocultar={ocultar}
              setOcultar={setOcultar}
            />
          </ModalContent>
        </Modal>
      ) : null
  );
};

export default JurosCarenciaModal;
