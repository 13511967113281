import { connect } from 'react-redux';

import { DealerSelectors } from '../../../modules/dealers/redux';

import Breadcrumb from './breadcrumb';

const mapStateToProps = ({ dealerStore }) => ({
  dealer: DealerSelectors.getSelectedFPlanDealer(dealerStore),
});

export default connect(mapStateToProps)(Breadcrumb);
