import colors from 'assets/styles/colors';

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      marginTop: '10px',
      overflow: 'hidden',
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  variant: 'menu',
};

export const SelectStyle = {
  FormControl: {
    m: 1,
    minWidth: 252,
    width: '100%',
    height: 40,
    backgroundColor: 'transparent',
    margin: 0,
  },
  menuItem: {
    whiteSpace: 'unset',
    borderBottom: `1px solid ${colors.silver_100}`,
    display: 'flex !important',
    justifyContent: 'left !important',
    padding: '8px 16px !important',
    gap: '9px !important',
    '&:hover': {
      backgroundColor: colors.cyan_100,
      borderBottom: `1px solid ${colors.accent_steel_200}`,
      '.all-item': {
        color: colors.text_midnight_900,
      },
    },
    '&.Mui-disabled': {
      opacity: 0.8,
    },
  },
  menuItemSelected: {
    whiteSpace: 'unset',
    display: 'flex !important',
    justifyContent: 'left !important',
    padding: '8px 16px !important',
    gap: '9px !important',
    backgroundColor: colors.primary_color_100_36,
    borderBottom: `1px solid ${colors.primary_color_100_56}`,
    '.all-item': {
      color: colors.text_midnight_900,
    },
    '&:hover': {
      backgroundColor: colors.secundary_color_100_48,
      borderBottom: `1px solid ${colors.secundary_color_100_56}`,
    },
  },
  selectedAll: {
    backgroundColor: colors.primary_color_100_36,
    borderBottom: `1px solid ${colors.primary_color_100_56}`,
    '&.Mui-disabled': {
      opacity: 0.8,
    },
    display: 'flex !important',
    justifyContent: 'left !important',
    padding: '8px 16px !important',
    gap: '9px !important',
    '.all-item': {
      color: colors.text_midnight_900,
    },
    '&:hover': {
      backgroundColor: colors.secundary_color_100_48,
      borderBottom: `1px solid ${colors.secundary_color_100_56}`,
    },
    '&:focus': {
      backgroundColor: colors.primary_color_100_36,
      borderBottom: `1px solid ${colors.primary_color_100_56}`,
    },
  },
  selectOptions: {
    height: 40,
    padding: 0,
    paddingLeft: '12px',
    boxShadow: 'none',
    backgroundColor: colors.silver_100_24,
    '.MuiOutlinedInput-input': {
      padding: 0,
      '&:focus': {
        background: 'transparent',
      },
    },
    '.MuiOutlinedInput-notchedOutline': {
      border: 'none',
      background: 'transparent',
    },
  },
};

export const TextFieldStyle = {
  '& .MuiOutlinedInput-root': {
    backgroundColor: colors.silver_100_24,
    '& fieldset': {
      border: 'none',
    },
    '&:hover fieldset': {
      borderColor: colors.accent_steel_200,
    },
    '&.Mui-focused fieldset': {
      borderColor: colors.teal_mint_500,
      backgroundColor: colors.text_midnight_200_08,
    },
  },
  '& .MuiOutlinedInput-input': {
    fontFamily: 'CircularStd, sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '24px',
    color: colors.text_midnight_500,
  },
};
