import React from 'react';

import { useHistory, useRouteMatch } from 'react-router-dom';
import ListIcon from '@material-ui/icons/List';
import LimiteIcon from 'assets/icons/limite';
import LimiteAprovadoIcon from 'assets/icons/limite-aprovado';
import TransferenciaIcon from 'assets/icons/transferencia';
import RenderIfPermission from 'features/menu/view/renderIfPermission';

import { Tracking } from 'modules';

import { permissoes } from 'modules/auth/permissoes';

import { PATH_SOLICITACAO_CREDITO } from 'routes/paths';

import MenuItem from 'features/common-staff/controls/menuItem';

import './menuSolicitacaoLimite.scss';

const MenuSolicitacaoLimite = () => {
  const { url } = useRouteMatch();
  const history = useHistory();

  const navTo = (itemName) => {
    history.replace(`${url}/${itemName}`);
  };
  return (
    <div className="solicitacao-limite__menu__container">
      <div
        className="solicitacao-limite__menu__container__content"
        data-cy="SolicitacaoLimiteMenuContainerContent"
      >
        <RenderIfPermission requireAll={[permissoes.limite.alteracaoCredito]}>
          <div className="solicitacao-limite__menu__item">
            <MenuItem
              Icon={LimiteIcon}
              title="Aumento/reduç. limite"
              isActive={!!useRouteMatch(`${PATH_SOLICITACAO_CREDITO}/alteracao-limite`)}
              onClick={() => {
                Tracking.Mixpanel.trackMenuClick('Aumento/reduç. limite');
                navTo('alteracao-limite');
              }}
            />
          </div>
        </RenderIfPermission>
        <RenderIfPermission requireAll={[permissoes.limite.transferenciaCredito]}>
          <div className="solicitacao-limite__menu__item">
            <MenuItem
              Icon={TransferenciaIcon}
              title="Transferência de limite"
              isActive={!!useRouteMatch(`${PATH_SOLICITACAO_CREDITO}/transferencia-limite`)}
              onClick={() => {
                Tracking.Mixpanel.trackMenuClick('Transferência de limite');
                navTo('transferencia-limite');
              }}
            />
          </div>
        </RenderIfPermission>
        <RenderIfPermission requireAll={[...Object.values(permissoes.limite)]}>
          <div className="solicitacao-limite__menu__item">
            <MenuItem
              Icon={ListIcon}
              title="Minhas solicitações"
              isActive={!!useRouteMatch(`${PATH_SOLICITACAO_CREDITO}/listagem`)}
              onClick={() => {
                Tracking.Mixpanel.trackMenuClick('Minhas solicitações');
                navTo('listagem');
              }}
            />
          </div>
        </RenderIfPermission>
        <RenderIfPermission requireAll={[permissoes.liberacaoLimite]}>
          <div className="solicitacao-limite__menu__item">
            <MenuItem
              Icon={LimiteAprovadoIcon}
              iconLimiteAprovado
              title="Limites aprovados"
              isActive={!!useRouteMatch(`${PATH_SOLICITACAO_CREDITO}/aprovacoes/limites-aprovados`)}
              onClick={() => {
                Tracking.Mixpanel.trackMenuClick('Limites aprovados');
                navTo('aprovacoes/limites-aprovados');
              }}
            />
          </div>
        </RenderIfPermission>
      </div>
    </div>
  );
};

MenuSolicitacaoLimite.propTypes = {
};

MenuSolicitacaoLimite.defaultProps = {
};

export default MenuSolicitacaoLimite;
