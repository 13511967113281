import { connect } from 'react-redux';

import ValorCarteiraProduto from './valorCarteiraProduto';
import selector from '../../redux/selectors';

const mapStateToProps = ({ dashboard }) => ({
  isLoading: dashboard.painelFloorPlan.carteiraProduto.isLoading,
  isError: dashboard.painelFloorPlan.carteiraProduto.isError,
  isEmpty: selector.isEmptyCarteiraProduto(dashboard),
});

export default connect(mapStateToProps, null)(ValorCarteiraProduto);
