import { connect } from 'react-redux';

import InputBusca from './inputBusca';

import operations from '../../../redux/operations';

const mapStateToProps = ({ gestaoPagamentos }) => ({
  texto: gestaoPagamentos.carteira.page.filters?.texto,
  isLoading: gestaoPagamentos?.carteira?.page?.requestStatus?.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setTexto: (value) => dispatch(operations.setFilter('texto', value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InputBusca);
