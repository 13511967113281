import { connect } from 'react-redux';

import * as operations from './redux/operations';

import TestDrivePage from './testDrivePage';

const mapStateToProps = ({ testDrive }) => ({
  page: testDrive.main.page,
});

const mapDispatchToProps = (dispatch) => ({
  loadProducts: () => dispatch(operations.loadProducts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TestDrivePage);
