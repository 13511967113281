import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../../common-staff/controls/button';

const ExtratoButton = ({ onClick, isLoading }) => (
  <Button
    onClick={() => onClick()}
    isLoading={isLoading}
    fullWidth
  >
    Exportar
  </Button>
);

ExtratoButton.propTypes = {
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
};

ExtratoButton.defaultProps = {
  onClick: () => {},
  isLoading: false,
};

export default ExtratoButton;
