import { connect } from 'react-redux';

import IofSimulatorPage from './iofSimulatorPage';
import { IofSimulatorActions, IofSimulatorOperations } from '../../redux';

const mapDispatchToProps = (dispatch) => ({
  getInvoicesWithBilling: (dealerGroup, page) => (
    dispatch(IofSimulatorOperations.getInvoicesWithBilling(dealerGroup, page))
  ),
  resetStore: () => (
    dispatch(IofSimulatorActions.resetStore())
  ),
  getFinancialValues: (dealerGroup) => {
    dispatch(IofSimulatorOperations.getFinancialValues(dealerGroup));
  },
  setStartDate: (value) => dispatch(IofSimulatorOperations.setStartDate(value)),
  setEndDate: (value) => dispatch(IofSimulatorOperations.setEndDate(value)),
});

const mapStateToProps = ({ iofSimulatorStore }) => ({
  dealerGroup: iofSimulatorStore.dealerGroup,
  isLoadError: iofSimulatorStore.isLoadError,
  transmissionValue: iofSimulatorStore.transmissionValue,
  loading: iofSimulatorStore.loading,
  startDate: iofSimulatorStore.filters.startDate,
  endDate: iofSimulatorStore.filters.endDate,
  isUpdated: iofSimulatorStore.isUpdated,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IofSimulatorPage);
