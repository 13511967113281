import { applyProperty } from 'utils/object';
import actions from './actions';

const INITIAL_STATE = {
  isOpen: false,

  taxas: [],

  requestStatus: {
    isLoading: false,
    isError: false,
  },

  filters: {
    mes: new Date().getMonth() + 1,
    ano: new Date().getFullYear(),
    brand: null,
  },

  selectors: {
    mes: [],
    ano: [],
  },

  filterButton: false,
};

export default (state = INITIAL_STATE, action = { type: '@@modalTaxasFloorPlan/INIT' }) => {
  switch (action.type) {
    case actions.types.GET_TAXAS_START:
      return {
        ...state,
        requestStatus: {
          isLoading: true,
          isError: false,
        },
      };
    case actions.types.GET_TAXAS_ERROR:
      return {
        ...state,
        requestStatus: {
          isLoading: false,
          isError: true,
        },
      };
    case actions.types.GET_TAXAS_SUCESS:
      return {
        ...state,
        requestStatus: {
          isLoading: false,
          isError: false,
        },
        taxas: action.payload.data,
        selectors: action.payload.filters,
        filterButton: false,
      };
    case actions.types.SET_MODAL_OPEN:
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    case actions.types.SET_FILTER:
      return {
        ...state,
        filters: applyProperty(
          { ...state.filters },
          action.payload.paramName,
          action.payload.value,
        ),
        filterButton: action.payload.filterButton,
      };
    case actions.types.RESET_STORE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
