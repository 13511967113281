import i18n from 'i18next';
import backend from 'i18next-xhr-backend';
import { reactI18nextModule } from 'react-i18next';

// translations are already at
// '../public/locales/en/translation.json'
// which is the default for the xhr backend to load from

i18n
  .use(backend)
  .use(reactI18nextModule)
  .init({
    lng: 'pt-br',
    fallbackLng: 'pt-br',
    ns: ['common', 'invoicePage', 'balance', 'statementPage', 'payment'],
    defaultNS: 'common',
    returnObjects: true,

    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
