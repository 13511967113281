import React from 'react';
import PropTypes from 'prop-types';

import Timeline from '../../../../../../common-staff/views/timeline';

const TimelineSolicitacoes = ({
  solicitacaoComentarios, isLoading,
}) => (
  <Timeline
    gruposComentarios={solicitacaoComentarios}
    isLoading={isLoading}
  />
);

TimelineSolicitacoes.propTypes = {
  solicitacaoComentarios: PropTypes.array,
  isLoading: PropTypes.bool,
};

TimelineSolicitacoes.defaultProps = {
  solicitacaoComentarios: null,
  isLoading: false,
};

export default TimelineSolicitacoes;
