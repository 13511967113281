/* eslint-disable class-methods-use-this */
import axios from 'axios';

const createBodyDownload = (filters) => ({
  dealerID: filters.dealerId,
  tipoListagem: 'fplan',
  pagina: filters.page,
  itensPorPagina: filters.ipp,
  concessionarias: filters.concessionaria !== null ? [filters.concessionaria] : null,
  dataInicioVencimento: filters.vencimentoInicio,
  dataFimVencimento: filters.vencimentoFim,
  dataInicioEmissao: filters.emissaoIncio,
  dataFimEmissao: filters.emissaoFim,
  status: filters.status !== null ? [filters.status] : null,
  carencia: null,
  produto: filters.produto !== null ? [filters.produto] : null,
  tipoDocumento: filters.docs,
  codigoModelo: filters.modelo,
  codigoVersao: filters.versao,
  inicioRengePreco: filters.valor[0],
  fimRengePreco: filters.valor[1],
  filtroBusca: filters.texto,
  periodo: filters.periodo,
});
class CarteiraService {
  async getInvoices(filters) {
    const response = await axios.post(`${window.env.REACT_APP_API_URL}/invoices`, {
      dealerID: filters.dealerId,
      tipoListagem: 'fplan',
      pagina: filters.page,
      itensPorPagina: filters.ipp,
      concessionarias: filters.concessionaria !== null ? [filters.concessionaria] : null,
      dataInicioVencimento: filters.vencimentoInicio,
      dataFimVencimento: filters.vencimentoFim,
      dataInicioEmissao: filters.emissaoInicio,
      dataFimEmissao: filters.emissaoFim,
      status: filters.status !== null ? [filters.status] : null,
      produto: filters.produto !== null ? [filters.produto] : null,
      tipoDocumento: filters.docs,
      codigoModelo: filters.modelo,
      codigoVersao: filters.versao,
      inicioRengePreco: filters.valor[0],
      fimRengePreco: filters.valor[1],
      filtroBusca: filters.texto,
      periodo: filters.periodo,
    });

    if (response.status !== 200 || !response.data) {
      throw new Error('Erro ao buscar invoices');
    }

    return response.data;
  }

  async downloadCarteira(filters, isCsv) {
    const body = createBodyDownload(filters);
    return axios.post(
      `${window.env.REACT_APP_API_URL}/invoices/export?isCsv=${isCsv}`,
      body,
      { responseType: 'arraybuffer' },
    );
  }
}
export default new CarteiraService();
