import React from 'react';
import PropTypes from 'prop-types';

import { PageTitle, Breadcrumb } from '../../../common';

import './paymentHeader.scss';

const PaymentHeader = ({ className }) => (
  <div
    className={className}
  >
    <Breadcrumb />

    <div className="payment-header-title">
      <PageTitle title="Pagar" />
    </div>
  </div>
);

PaymentHeader.propTypes = {
  className: PropTypes.string,
};

PaymentHeader.defaultProps = {
  className: null,
};

export default PaymentHeader;
