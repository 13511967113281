import { connect } from 'react-redux';

import ExtratoButton from './extratoButton';
import operations from '../../redux/operations';

const mapStateToProps = ({ gestaoPagamentos }) => ({
  isLoading: gestaoPagamentos.comprovante.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  onClick: () => dispatch(operations.getExtrato()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExtratoButton);
