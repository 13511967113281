import { connect } from 'react-redux';
import * as operations from '../../../redux/operations';

import InputValor from './inputValor';

const mapStateToProps = ({ limiteCreditoStore }) => ({
  valor: limiteCreditoStore.transferenciaLimite.valor,
});

const mapDispatchToProps = (dispatch) => ({
  setValor: (valor) => dispatch(
    operations.setValor(valor),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(InputValor);
