import { connect } from 'react-redux';

import BrandSelector from './brandSelector';
import operations from '../../../redux/operations';
import selector from '../../../redux/selector';

const mapStateToProps = ({ gestaoPagamentos, dealerStore }) => ({
  brand: gestaoPagamentos?.carteira?.modalTaxas?.filters?.brand,
  brandList: selector.getListBrands(dealerStore),
  isLoading: gestaoPagamentos?.carteira?.modalTaxas?.requestStatus?.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setBrand: (value, activeButton = true) => dispatch(operations.setFilters('brand', value, activeButton)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandSelector);
