import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import './cardResumoMovimentacao.scss';

import Logo from '../../../../logo';

const DataRow = ({
  // eslint-disable-next-line react/prop-types
  label, value,
}) => (
  <div className="detalhe-movimentacao__card__data-row">
    <div
      className="detalhe-movimentacao__card__data-row-label"
      data-cy="DetalheMovimentacaoCardLabelDuplicata"
    >
      {label}
    </div>
    <div
      className="detalhe-movimentacao__card__data-row-value"
      data-cy="DetalheMovimentacaoCardLabelDuplicataValue"
    >
      {value}
    </div>
  </div>
);

const Card = ({
  // eslint-disable-next-line react/prop-types
  valorEstorno, creditoAtual, duplicata,
}) => (
  <div className="detalhe-movimentacao__card-resumo__card">
    <div className="detalhe-movimentacao__card-resumo">
      <div className="detalhe-movimentacao__card-resumo__valor-estorno">
        {valorEstorno}
      </div>
      <div className="detalhe-movimentacao__card-resumo__credito">
        Crédito atual
        {' '}
        {creditoAtual}
      </div>
    </div>
    <div className="detalhe-movimentacao__card-resumo__card-lower">
      <DataRow
        label="Duplicata:"
        value={duplicata}
      />
    </div>
  </div>
);

const CardResumoMovimentacao = ({
  valorEstorno, creditoAtual, dataPagamento, duplicata, header,
}) => (
  <div className="detalhe-movimentacao__card__container">
    <div className="detalhe-movimentacao__card__header">
      <div className="detalhe-movimentacao__card__header-movimentacao">
        {_.isEmpty(header) ? 'Estorno' : header}
      </div>
    </div>

    <div
      className="detalhe-movimentacao__card__img"
      data-cy="DetalheMovimentacaoCardImg"
    >
      <Logo tipoOperacao="estorno" isModal="true" />
    </div>

    <Card
      valorEstorno={valorEstorno}
      creditoAtual={creditoAtual}
      dataPagamento={dataPagamento}
      duplicata={duplicata}
    />
  </div>
);

CardResumoMovimentacao.propTypes = {
  valorEstorno: PropTypes.number,
  creditoAtual: PropTypes.number,
  dataPagamento: PropTypes.any,
  duplicata: PropTypes.number,
  header: PropTypes.string,
};

CardResumoMovimentacao.defaultProps = {
  valorEstorno: null,
  creditoAtual: null,
  dataPagamento: null,
  duplicata: null,
  header: '',
};

export default CardResumoMovimentacao;
