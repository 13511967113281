import React from 'react';
import PropTypes from 'prop-types';

import { CardVencimentoType } from '../../redux/enum';
import CardVencimento from './cardVencimento';
import BaseArea from '../baseArea';

import CardBase from '../cardBase';
import './duplicatasVencidasArea.scss';

const DuplicatasVencidasArea = ({
  itens, isLoading, isError, className,
}) => (
  <BaseArea
    title="Visão por faixa de vencimento"
    subtitle="Visão geral de duplicatas e valor, vencidas e a vencer."
    className={className}
  >
    <div className="dashboard-floor-plan__duplicatas-vencidas__content-cards">
      <div className="card-vencidas">
        <CardBase
          isLoading={isLoading}
          isError={isError}
          minWidth="200px"
          minHeight="200px"
        >
          <CardVencimento item={itens.find((i) => i.tipo === CardVencimentoType.vencidas)} />
        </CardBase>
      </div>
      <div className="card-today">
        <CardBase
          isLoading={isLoading}
          isError={isError}
          minWidth="200px"
          minHeight="200px"
        >
          <CardVencimento item={itens.find((i) => i.tipo === CardVencimentoType.actual)} />
        </CardBase>
      </div>
      <div className="card-dez-dias">
        <CardBase
          isLoading={isLoading}
          isError={isError}
          minWidth="200px"
          minHeight="200px"
        >
          <CardVencimento item={itens.find((i) => i.tipo === CardVencimentoType.dez_dias)} />
        </CardBase>
      </div>
      <div className="card-vinte-dias">
        <CardBase
          isLoading={isLoading}
          isError={isError}
          minWidth="200px"
          minHeight="200px"
        >
          <CardVencimento item={itens.find((i) => i.tipo === CardVencimentoType.vinte_dias)} />
        </CardBase>
      </div>
    </div>
  </BaseArea>
);

DuplicatasVencidasArea.propTypes = {
  itens: PropTypes.array,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  className: PropTypes.string,
};

DuplicatasVencidasArea.defaultProps = {
  itens: [],
  isLoading: false,
  isError: false,
  className: '',
};

export default DuplicatasVencidasArea;
