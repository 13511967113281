import service from './service';
import actions from './actions';

const getTaxas = () => async (dispatch, getState) => {
  try {
    dispatch(actions.getTaxasStart());
    const { filters } = getState().gestaoPagamentos.carteira.modalTaxas;
    const response = await service.getTaxas(filters);
    const filtros = { ano: response.anos, mes: response.meses };
    const taxas = response.taxas.map((t) => ({
      ...t,
      inicioVigencia: new Date(t.inicioVigencia),
      fimVigencia: new Date(t.fimVigencia),
    }));
    dispatch(actions.getTaxasSucesso(taxas, filtros, filters.brand));
  } catch (error) {
    dispatch(actions.getTaxasError());
  }
};

const setModalOpen = () => async (dispatch) => {
  dispatch(actions.setModalOpen());
};

const setFilters = (paramName, value, filterButton = true) => async (dispatch) => {
  dispatch(actions.setFilter(paramName, value, filterButton));
};

const resetStore = () => async (dispatch) => {
  dispatch(actions.resetStore());
};

export default {
  setModalOpen,
  resetStore,
  setFilters,
  getTaxas,
};
