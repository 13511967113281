import {
  SET_OFFERS,
  SET_SELECTED_OFFER,
  SET_OFFERS_LOADING,
  SET_OFFERS_LOAD_ERROR,
  SET_CONDITIONS,
  SET_SELECTED_CONDITION,
  SET_CONDITIONS_LOADING,
  SET_CONDITIONS_LOAD_ERROR,
  SET_SELECTED_DEALER_ID,
  SET_INDUSTRIAL_ORDER,
  SET_CHASSIS,
  SET_COLOR,
  SET_OBSERVATION,
  SET_IS_SUBMITTING,
  SET_SUBMIT_ERROR,
  SET_SUBMIT_SUCCESS,
  SET_SIMULATION_BASE_VALUE,
  SET_MOTIVOS_TEST_DRIVE_EXCECAO,
  SET_SELECTED_MOTIVO,
  SET_OPEN_MODAL_UPLOAD,
  DELETE_SELECTED_FILE,
  DELETE_FILES_TO_SEND,
  CLEAR_SELECTED_FILES,
  SET_FILE_UPLOAD_PROGRESS,
  SET_FILE_UPLOAD_ERROR,
  UPDATE_FILE_INFO,
  SEND_FILES_TO_FORM,
  SET_FILES_UPLOADING,
  ADD_NEW_FILE,
  CLEAR_FORM,
} from './testDriveActions';

import {
  deleteSelectedFile, setFileUploadProgress, setFileUploadError, updateFileInfo,
  addNewFile, addFiles,
} from './testDriveReducerUtility';

const INITIAL_STATE = {
  offers: [],
  selectedOfferId: null,
  isOffersLoading: false,
  showOffersLoadError: false,

  conditions: [],
  selectedConditionId: null,
  isConditionsLoading: false,
  showConditionsLoadError: false,

  selectedDealerId: null,
  industrialOrder: null,
  chassis: null,
  color: null,
  observation: null,

  isSubmitting: false,
  showSubmitError: false,
  showSubmitSuccess: false,

  simulationBaseValue: null,

  motivos: [],
  selectedMotivoId: null,
  isModalUploadOpen: false,
  selectedFiles: [],
  filesToSend: [],
  isUploading: false,
};

export default (state = INITIAL_STATE, action = { type: 'default' }) => {
  switch (action.type) {
    case SET_OFFERS:
      return {
        ...state,
        offers: action.payload,
      };
    case SET_SELECTED_OFFER:
      return {
        ...state,
        selectedOfferId: action.payload,
      };
    case SET_OFFERS_LOADING:
      return {
        ...state,
        isOffersLoading: action.payload,
      };
    case SET_OFFERS_LOAD_ERROR:
      return {
        ...state,
        showOffersLoadError: action.payload,
      };
    case SET_CONDITIONS:
      return {
        ...state,
        conditions: action.payload,
      };
    case SET_SELECTED_CONDITION:
      return {
        ...state,
        selectedConditionId: action.payload,
      };
    case SET_CONDITIONS_LOADING:
      return {
        ...state,
        isConditionsLoading: action.payload,
      };
    case SET_CONDITIONS_LOAD_ERROR:
      return {
        ...state,
        showConditionsLoadError: action.payload,
      };
    case SET_SELECTED_DEALER_ID:
      return {
        ...state,
        selectedDealerId: action.payload,
      };
    case SET_INDUSTRIAL_ORDER:
      return {
        ...state,
        industrialOrder: action.payload,
      };
    case SET_CHASSIS:
      return {
        ...state,
        chassis: action.payload,
      };
    case SET_COLOR:
      return {
        ...state,
        color: action.payload,
      };
    case SET_OBSERVATION:
      return {
        ...state,
        observation: action.payload,
      };
    case SET_IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.payload,
      };
    case SET_SUBMIT_ERROR:
      return {
        ...state,
        showSubmitError: action.payload,
      };
    case SET_SUBMIT_SUCCESS:
      return {
        ...state,
        showSubmitSuccess: action.payload,
      };
    case SET_SIMULATION_BASE_VALUE:
      return {
        ...state,
        simulationBaseValue: action.payload,
      };
    case SET_MOTIVOS_TEST_DRIVE_EXCECAO:
      return {
        ...state,
        motivos: action.payload,
      };
    case SET_SELECTED_MOTIVO:
      return {
        ...state,
        selectedMotivoId: action.payload,
      };
    case SET_OPEN_MODAL_UPLOAD:
      return {
        ...state,
        isModalUploadOpen: action.payload,
      };
    case DELETE_SELECTED_FILE:
      return {
        ...state,
        selectedFiles: deleteSelectedFile(state.selectedFiles, action.payload),
      };
    case DELETE_FILES_TO_SEND:
      return {
        ...state,
        filesToSend: deleteSelectedFile(state.filesToSend, action.payload),
      };
    case CLEAR_SELECTED_FILES:
      return {
        ...state,
        selectedFiles: [],
      };
    case SET_FILE_UPLOAD_PROGRESS:
      return {
        ...state,
        selectedFiles: setFileUploadProgress(
          state.selectedFiles,
          action.payload.fileName,
          action.payload.value,
        ),
      };
    case SET_FILE_UPLOAD_ERROR:
      return {
        ...state,
        selectedFiles: setFileUploadError(
          state.selectedFiles,
          action.payload,
        ),
      };
    case UPDATE_FILE_INFO:
      return {
        ...state,
        selectedFiles: updateFileInfo(
          state.selectedFiles,
          action.payload.fileName,
          action.payload.fileInfo,
          action.payload.progress,
        ),
      };
    case SEND_FILES_TO_FORM:
      return {
        ...state,
        filesToSend: addFiles(state.selectedFiles, state.filesToSend),
      };
    case SET_FILES_UPLOADING:
      return {
        ...state,
        isUploading: action.payload,
      };
    case ADD_NEW_FILE:
      return {
        ...state,
        selectedFiles: addNewFile(
          state.selectedFiles,
          action.payload,
        ),
      };
    case CLEAR_FORM:
      return {
        ...INITIAL_STATE,
        selectedFiles: [],
        showSubmitError: state.showSubmitError,
        showSubmitSuccess: state.showSubmitSuccess,
      };
    default:
      return state;
  }
};
