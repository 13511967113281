import React from 'react';
import PropTypes from 'prop-types';

import MarcaBadge from '../../../common-staff/views/marcaBadge';

import CnpjFormat from '../../../common/cnpjFormat';
import DealerNumber from '../../../common/dealerNumber';

import './iofDealerGroupDetail.scss';

const IofDealerGroupDetail = ({
  className, dealerGroup,
}) => (
  <div
    className={`${className} iof-detail__container`}
    data-cy="IofDetailContainer"
  >
    <div className="iof-detail-icon">
      <MarcaBadge
        marca={dealerGroup?.brand}
      />
    </div>
    <div className="iof-detail-name">
      {dealerGroup?.name}
    </div>
    <div className="iof-detail-description-container">
      <CnpjFormat value={dealerGroup?.cnpjRoot} />
      <div className="iof-detail-item-divider" />
      <DealerNumber dealerCode={dealerGroup?.matrizId} />
    </div>
  </div>
);

IofDealerGroupDetail.propTypes = {
  className: PropTypes.string,
  dealerGroup: PropTypes.object,
};

IofDealerGroupDetail.defaultProps = {
  className: '',
  dealerGroup: null,
};

export default IofDealerGroupDetail;
