import * as React from 'react';

const ChryslerIcon = (props) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="32" height="32" rx="12" fill="#3F4554" />
    <g clipPath="url(#clip0)">
      <path d="M30 19.8842C29.3426 19.8107 28.6821 19.8604 28.024 19.8582C27.7805 19.8574 27.6537 19.7817 27.5565 19.5701C27.292 18.9947 26.9847 18.4364 26.731 17.8565C26.5887 17.5298 26.325 17.5929 26.0846 17.6182C25.8201 17.6456 25.9453 17.8728 25.9414 18.008C25.9266 18.5262 25.929 19.0452 25.9383 19.5642C25.9422 19.7572 25.9033 19.8619 25.6653 19.856C25.1471 19.8426 24.6282 19.8441 24.1101 19.856C23.8915 19.8612 23.8589 19.7669 23.8589 19.5879C23.8635 17.5372 23.8635 15.4873 23.8589 13.4366C23.8581 13.2584 23.8884 13.1633 24.1086 13.1685C25.2739 13.1938 26.4424 13.1106 27.6047 13.2153C28.5343 13.2992 29.3263 13.6474 29.6414 14.5674C29.9409 15.4412 29.8522 16.2735 29.1629 16.9826C29.1544 16.9915 29.1427 16.9989 29.1357 17.0093C29.0322 17.1727 28.7117 17.1764 28.7327 17.3924C28.7498 17.5758 28.8938 17.7511 28.9933 17.9241C29.3387 18.5232 29.5783 19.1751 29.9992 19.7357C30 19.7854 30 19.8352 30 19.8842ZM25.936 15.4494C25.936 15.6714 25.9383 15.8934 25.9352 16.1147C25.9329 16.2431 25.9624 16.3292 26.1266 16.3211C26.423 16.3062 26.724 16.3292 27.015 16.2839C27.4405 16.2171 27.663 15.9483 27.6942 15.5452C27.7354 15.0039 27.3837 14.6453 26.7878 14.6223C25.936 14.5896 25.936 14.5896 25.936 15.3388C25.9352 15.3759 25.9352 15.4123 25.936 15.4494Z" fill="white" />
      <path d="M12.9388 19.9562C14.0824 19.7305 14.8113 18.939 14.83 17.8283C14.8541 16.3604 14.8339 14.8918 14.8416 13.4232C14.8424 13.2309 14.7841 13.1633 14.5795 13.1693C14.1267 13.1811 13.6724 13.1923 13.2212 13.1663C12.8898 13.147 12.7785 13.2264 12.7847 13.5665C12.8081 14.8369 12.7964 16.108 12.7917 17.3791C12.791 17.5625 12.7762 17.7496 12.7373 17.9292C12.6704 18.2388 12.4409 18.3792 12.1235 18.3948C11.736 18.4133 11.4568 18.2129 11.3657 17.7963C11.3245 17.607 11.2561 17.558 11.0693 17.5625C10.5909 17.5721 10.1109 17.5743 9.63246 17.5617C9.41853 17.5565 9.37885 17.6367 9.38274 17.8231C9.39986 18.691 9.98954 19.4914 10.8577 19.824C10.857 19.8233 11.5213 20.1062 12.9388 19.9562Z" fill="white" />
      <path d="M16.3361 16.5111C16.3361 15.4984 16.3384 14.4857 16.3337 13.473C16.333 13.2955 16.3376 13.1641 16.5912 13.1678C17.6788 13.1856 18.7703 13.144 19.8547 13.2086C21.5157 13.3081 22.5107 14.2548 22.6756 15.8348C22.7612 16.6507 22.723 17.4645 22.3636 18.2329C21.8191 19.3964 20.7976 19.8188 19.5451 19.8456C18.5735 19.8664 17.6018 19.8441 16.6301 19.856C16.3789 19.8589 16.3306 19.7632 16.3322 19.5493C16.3407 18.5373 16.3361 17.5246 16.3361 16.5111ZM18.4093 16.4748C18.4093 16.993 18.407 17.5112 18.4116 18.0302C18.4124 18.1178 18.3665 18.224 18.5066 18.279C18.9274 18.4453 19.9395 18.2121 20.2196 17.8194C20.7509 17.0739 20.7517 16.252 20.3861 15.4576C20.0625 14.7537 19.3631 14.7166 18.6621 14.7337C18.4521 14.7389 18.4008 14.8094 18.4046 14.9943C18.4163 15.4873 18.4093 15.981 18.4093 16.4748Z" fill="white" />
      <path d="M2.19115 17.8306C2.19115 17.8306 1.78973 16.8275 2.15303 15.3314C2.43465 14.1724 3.20404 13.4225 4.42542 13.1114C4.42542 13.1114 5.25861 12.8783 6.29328 13.0832C6.80751 13.1849 7.2805 13.4277 7.66636 13.7678C8.20393 14.2407 8.61391 14.7271 8.70493 15.4769C8.72982 15.6841 8.68003 15.771 8.44665 15.7583C8.1627 15.7428 7.87719 15.7428 7.59324 15.7576C7.12724 15.7814 6.7865 15.7843 6.56167 15.2134C6.24427 14.4071 5.01433 14.3781 4.48377 15.0872C3.96565 15.7799 3.96877 17.2344 4.48766 17.9375C4.7895 18.3459 5.21037 18.4743 5.70982 18.409C6.19293 18.3459 6.55312 18.08 6.65425 17.6442C6.74294 17.2648 6.94443 17.2277 7.26961 17.244C7.6438 17.2626 8.02111 17.2648 8.39453 17.2433C8.69015 17.2262 8.73215 17.3413 8.70648 17.5893C8.59368 18.6941 7.73093 19.5984 6.47688 19.9206C5.3683 20.0877 4.3873 19.9021 4.3873 19.9021C3.24761 19.6021 2.54434 18.8886 2.19115 17.8306C2.19115 17.8306 1.92898 16.1281 2.15303 15.3314C2.47588 14.1828 3.20404 13.4225 4.42542 13.1114" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="28" height="7" fill="white" transform="translate(2 13)" />
      </clipPath>
    </defs>
  </svg>
);

export default ChryslerIcon;
