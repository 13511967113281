import * as React from "react";

function SvgHaha(props) {
	return (
		<svg width="1em" height="1em" viewBox="0 0 40 40" {...props}>
			<defs>
				<linearGradient
					id="haha_svg__a"
					x1="50%"
					x2="50%"
					y1="10.25%"
					y2="100%"
				>
					<stop offset="0%" stopColor="#FEEA70" />
					<stop offset="100%" stopColor="#F69B30" />
				</linearGradient>
				<linearGradient id="haha_svg__b" x1="50%" x2="50%" y1="0%" y2="100%">
					<stop offset="0%" stopColor="#472315" />
					<stop offset="100%" stopColor="#8B3A0E" />
				</linearGradient>
				<linearGradient id="haha_svg__c" x1="50%" x2="50%" y1="0%" y2="81.9%">
					<stop offset="0%" stopColor="#FC607C" />
					<stop offset="100%" stopColor="#D91F3A" />
				</linearGradient>
			</defs>
			<g fill="none">
				<path
					fill="url(#haha_svg__a)"
					d="M40 20a19.998 19.998 0 01-20 20 20 20 0 1120-20"
				/>
				<path
					fill="url(#haha_svg__b)"
					d="M7.5 20.02C7.5 25.058 10.015 35 20 35c9.983 0 12.5-9.942 12.5-14.98 0-.398-4.025-2.52-12.5-2.52S7.5 19.622 7.5 20.02z"
				/>
				<path
					fill="url(#haha_svg__c)"
					d="M11.353 31.25c2.01 2.487 4.767 3.75 8.672 3.75 3.908 0 6.638-1.263 8.648-3.75-1.378-1.47-3.998-3.75-8.648-3.75-4.65 0-7.292 2.28-8.672 3.75z"
				/>
				<path
					fill="#2A3755"
					d="M15.532 10.36c.658.47 1.255 1.137 1.025 1.97-.177.634-.485.922-1.055.927-1.95.027-4.27.637-6.265 1.53-.162.072-.492.22-.83.212-.31-.007-.627-.145-.817-.592-.168-.393-.183-.97.69-1.495 1.362-.825 3.142-1.2 4.772-1.51a17.687 17.687 0 00-3.287-1.92c-1.068-.485-.95-1.143-.808-1.5.318-.793 1.523-.49 2.695.065a22.483 22.483 0 013.88 2.312zm8.943 0a22.32 22.32 0 013.875-2.313c1.175-.555 2.375-.858 2.695-.065.142.357.26 1.015-.808 1.5-1.16.52-2.26 1.163-3.282 1.92 1.625.307 3.408.685 4.768 1.51.872.525.855 1.1.69 1.495-.193.45-.508.585-.818.592-.337.008-.668-.14-.83-.212-1.992-.893-4.313-1.5-6.26-1.53-.57-.005-.878-.293-1.055-.925-.228-.833.367-1.5 1.025-1.97v-.003z"
				/>
			</g>
		</svg>
	);
}

export default SvgHaha;

