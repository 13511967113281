import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import NotificationsIcon from '@mui/icons-material/Notifications';
import moment from 'moment';
import 'moment/locale/pt-br';
import SummaryPage from 'features/common/summaryPage';
import HistoricoObservacoes from 'features/common-staff/controls/historicoObservacoes';

const ObservacoesAprovacao = ({
  getObservacoes,
  limite,
  insertObservacao,
  observacoesList,
  isLoadingObservacoes,
  insertControleObservacao,
  visualizado,
  isDrawer,
  resetStore,
  openDrawer,
  uploadArquivoTemporario,
  open,
  handleSummaryDrawer,
  getDocumentoDownload,
}) => {
  const iconStyle = {
    display: 'inline-block',
    borderRadius: '50%',
    backgroundColor: '#C31E10',
    color: '#FFFFFF',
    textAlign: 'center',
    lineHeight: '24px',
    padding: '4px',
  };

  const [showIcon, setShowIcon] = useState(false);

  useEffect(() => () => resetStore(), [resetStore]);

  const handleObservacaoSubmit = async (observacao) => {
    await insertObservacao(observacao, limite.idLimite);
    getObservacoes(limite?.idLimite);
  };

  useEffect(() => {
    if (limite?.idLimite) {
      getObservacoes(limite.idLimite);
    }
  }, [limite?.idLimite, openDrawer]);

  const handleChangeSummary = (isOpen) => {
    if (isOpen && !visualizado) {
      setShowIcon(false);
      insertControleObservacao(limite?.idLimite, moment().format('YYYY-MM-DDTHH:mm:ss'));
    }
  };

  useEffect(() => {
    const shouldShowIcon = !visualizado && (openDrawer || !isDrawer);
    setShowIcon(shouldShowIcon);
    if (isDrawer && openDrawer && !visualizado && limite?.idLimite) {
      (insertControleObservacao(limite.idLimite, moment().format('YYYY-MM-DDTHH:mm:ss')));
    }
  }, [isDrawer, openDrawer, visualizado, limite?.idLimite]);

  const handleUploadArquivoTemporario = async (file) => uploadArquivoTemporario(file);

  const handleDownloadDocumento = async (guidDocumento) => getDocumentoDownload(guidDocumento);

  return (
    <SummaryPage
      title="Observações da aprovação"
      level={isDrawer ? 3 : 0}
      open={open}
      handleOpenChange={!isDrawer ? handleChangeSummary : handleSummaryDrawer}
      IconTitle={showIcon && !isLoadingObservacoes ? (
        <NotificationsIcon
          style={{
            ...iconStyle,
            width: isDrawer ? 12 : 14,
            height: isDrawer ? 12 : 14,
          }}
        />
      ) : null}
    >
      {!isLoadingObservacoes && (
        <HistoricoObservacoes
          observacoes={observacoesList}
          insertObservacao={handleObservacaoSubmit}
          isDealer
          isDrawer={isDrawer}
          uploadArquivoTemporario={handleUploadArquivoTemporario}
          getDocumentoDownload={handleDownloadDocumento}
        />
      )}
    </SummaryPage>
  );
};

ObservacoesAprovacao.propTypes = {
  getObservacoes: PropTypes.func,
  insertObservacao: PropTypes.func,
  limite: PropTypes.any,
  observacoesList: PropTypes.array,
  isLoadingObservacoes: PropTypes.bool,
  insertControleObservacao: PropTypes.func,
  visualizado: PropTypes.bool,
  isDrawer: PropTypes.bool,
  resetStore: PropTypes.func,
  openDrawer: PropTypes.bool,
  uploadArquivoTemporario: PropTypes.func,
  open: PropTypes.bool,
  handleSummaryDrawer: PropTypes.func,
  getDocumentoDownload: PropTypes.func,
};

ObservacoesAprovacao.defaultProps = {
  getObservacoes: () => { },
  insertObservacao: () => { },
  limite: {},
  observacoesList: [],
  isLoadingObservacoes: false,
  insertControleObservacao: () => { },
  visualizado: true,
  isDrawer: false,
  resetStore: () => { },
  openDrawer: false,
  uploadArquivoTemporario: () => { },
  open: false,
  handleSummaryDrawer: () => { },
  getDocumentoDownload: () => {},
};

export default ObservacoesAprovacao;
