import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import HelpRoundedIcon from '@material-ui/icons/HelpRounded';

import { Currency, Date } from '../../../common';

import IofDealerGroupSelector from '../iofDealerGroupSelector';
import IofDealerGroupDetail from '../iofDealerGroupDetail';
import StandardCurrencyFormat from '../../../common/standardCurrencyFormat';

import './iofSimulatorMenu.scss';

const IofSimulatorMenu = ({
  paymentValue, transmissionValue, selectedValue, lastUpdate, movementAccountPaymentsValue,
  setMovementAccountPaymentsValue, dealerGroup,
}) => {
  const [inputValue, setInputValue] = useState('');

  return (
    <div
      className="iof-sim-menu-main"
      data-cy="MenuDealerSelector"
    >
      <div>
        <IofDealerGroupSelector />
        <IofDealerGroupDetail dealerGroup={dealerGroup} />
      </div>

      <div
        className="iof-sim-menu-block"
        data-cy="IofMenuBlock"
      >
        <div className="iof-sim-menu-title">
          <span>Valor Transmitido</span>
          <Tooltip title="Valor transmitido de duplicatas floor plan no dia de hoje.">
            <HelpRoundedIcon className="iof-sim-menu-tooltip-icon" />
          </Tooltip>
        </div>
        <div
          className="iof-sim-menu-number"
          data-cy="IofSimMenuNumberValorTransmitido"
        >
          <Currency className="iof-sim-menu-default-value" noColor explicitSign={false} value={transmissionValue !== 0 ? transmissionValue : null} />
        </div>
        <div className="iof-sim-menu-update">
          <span>
            Atualizado em:
            {' '}
            {
              lastUpdate ? (
                <Date date={lastUpdate} format="DD/MM/YYYY HH:mm" />
              ) : null
            }
          </span>
        </div>
      </div>

      <div className="iof-sim-menu-block-divider" />

      <div className="iof-sim-menu-block iof-sim-menu-block-commom">
        <div
          className="iof-sim-menu-title"
          data-cy="IofSimMenuTitlePgtoRecursoBancoFidis"
        >
          Pgto recursos Banco Stellantis
        </div>
        <div
          className="iof-sim-menu-number"
          data-cy="IofSimMenuNumberRecursoBanco"
        >
          <Currency
            className="iof-sim-negative-value"
            noColor
            explicitSign
            value={((!paymentValue && paymentValue !== 0) || transmissionValue === 0)
              ? null : -paymentValue}
          />
        </div>
      </div>

      <div className="iof-sim-menu-block iof-sim-menu-block-commom">
        <div
          className="iof-sim-menu-title"
          data-cy="IofSimMenuTitleDuplicatas"
        >
          Duplicatas selecionadas
        </div>
        <div
          className="iof-sim-menu-number"
          data-cy="IofSimMenuNumberDuplicataSelecionada"
        >
          <Currency
            className="iof-sim-negative-value"
            noColor
            explicitSign
            value={((!selectedValue && selectedValue !== 0) || transmissionValue === 0)
              ? null : -selectedValue}
          />
        </div>
      </div>

      <div
        className="iof-sim-menu-block iof-sim-menu-block-commom iof-sim-menu-block-input"
        data-cy="IofSimMenuBlockCommonInput"
      >
        <div className="iof-sim-menu-title">
          <span>pgto conta movimento Stellantis</span>
          <Tooltip title="Campo aberto. Informar recursos utilizados da conta movimento para pagamento de duplicatas floor plan para data de hoje.">
            <HelpRoundedIcon className="iof-sim-menu-tooltip-icon" />
          </Tooltip>
        </div>
        <TextField
          className="iof-sim-menu-mov-input"
          data-cy="IofSimMenuMovInput"
          placeholder="Ex: R$ 20.000,00"
          disabled={transmissionValue === 0}
          value={inputValue}
          onChange={
            (e) => {
              setInputValue(e.target.value);
              setMovementAccountPaymentsValue(
                Number(parseFloat(e.target.value || 0.0).toFixed(2)),
              );
            }
          }
          color="secondary"
          id="formatted-numberformat-input"
          InputProps={{
            inputComponent: StandardCurrencyFormat,
          }}
        />

      </div>

      <div className="iof-sim-menu-block-divider" />

      <div className="iof-sim-menu-block iof-sim-menu-block-result">
        <div
          className="iof-sim-menu-title"
          data-cy="IofSimMenuBlockResultSimuladoTitle"
        >
          Total simulado
        </div>
        <div
          className="iof-sim-menu-number"
          data-cy="IofSimMenuNumberTotalSimulado"
        >
          <Currency className="iof-sim-menu-default-value" noColor explicitSign={false} value={transmissionValue !== 0 ? (Math.max(0, (movementAccountPaymentsValue + selectedValue + paymentValue))) : null} />
        </div>
      </div>

      <div className="iof-sim-menu-block iof-sim-menu-block-result">
        <div
          className="iof-sim-menu-title"
          data-cy="IofSimMenuBlockResultCalculoTitle"
        >
          Base de cálculo
        </div>
        <div
          className="iof-sim-menu-number"
          data-cy="IofSimMenuNumberBaseCalculo"
        >
          <Currency className="iof-sim-menu-default-value" noColor explicitSign={false} value={transmissionValue !== 0 ? (Math.max(0, (transmissionValue - (movementAccountPaymentsValue + selectedValue + paymentValue)))) : null} />
        </div>
      </div>

      <div className="iof-sim-menu-block iof-sim-menu-block-result">
        <div
          className="iof-sim-menu-title"
          data-cy="IofSimMenuTitleIofAprox"
        >
          IOF aproximado (Alíquota 0,38%)
        </div>
        <div
          className="iof-sim-menu-number"
          data-cy="IofSimMenuNumberIofAproximado"
        >
          <Currency className="iof-sim-negative-value iof-sim-negative-bigger" noColor explicitSign={false} value={transmissionValue !== 0 ? (Math.max(0, (0.0038 * (transmissionValue - (movementAccountPaymentsValue + selectedValue + paymentValue))))) : null} />
        </div>
      </div>

      <div className="iof-sim-menu-block-divider-footer" />
    </div>
  );
};

IofSimulatorMenu.propTypes = {
  transmissionValue: PropTypes.number,
  paymentValue: PropTypes.number,
  selectedValue: PropTypes.number,
  lastUpdate: PropTypes.string,
  movementAccountPaymentsValue: PropTypes.number.isRequired,
  setMovementAccountPaymentsValue: PropTypes.func.isRequired,
  dealerGroup: PropTypes.object.isRequired,
};

IofSimulatorMenu.defaultProps = {
  transmissionValue: undefined,
  paymentValue: undefined,
  selectedValue: undefined,
  lastUpdate: undefined,
};

export default IofSimulatorMenu;
