import styled from 'styled-components';

export const PageAreas = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto 1fr;
  grid-template-areas: 
  "description"
  "filters"
  "list";
`;

export const PageDescription = styled.div`
  grid-area: description;
  font-style: normal;
  font-weight: 450;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #7A7C9A;
  padding: 12px 0px 20px 48px;
`;

export const PageFilters = styled.div`
  grid-area: filters;
  padding: 0px 0px 20px 48px;
`;

export const PageList = styled.div`
  grid-area: list;
  height: 100%;
  min-width: ${(props) => (props.isDrawerOpen ? '648px' : '880px')};
`;

export const AlertContainer = styled.div`
  grid-area: content;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 650px;
  margin-right: 5px;
`;
