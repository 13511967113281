import styled from 'styled-components';

const getRotate = (props) => {
  if(props?.isActive) {
    return props?.isRotate ? 'rotate(180deg)' : 'rotate(0deg)';
  }

  return '0deg';
}

export const Svg = styled.svg`
  width: 12px;
  height: 8px;
  margin-left: 98px;
  fill: none;
  transform: ${getRotate}
`;