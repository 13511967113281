import { connect } from 'react-redux';

import operations from '../../../redux/operations';
import RelatorioSelect from './relatorioSelect';

const mapStateToProps = ({ relatoriosIof }) => ({
  relatorio: relatoriosIof.filtros?.tipoRelatorio,
  isLoading: relatoriosIof.requestStatus.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setRelatorio: (value) => {
    dispatch(operations.setFilter(value, 'tipoRelatorio'));
    dispatch(operations.setIsRelatorioSelected(true));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RelatorioSelect);
