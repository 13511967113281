import * as React from "react";

function EmptyStateStaffIcon(props) {
	return (
		<svg
			width="1em"
			height="1em"
			viewBox="0 0 75 95"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M74.111 93.68l-5.865-81.992L50.296.885H.89L7.642 93.68h66.47z"
				fill="#EDF1F7"
			/>
			<path
				d="M74.111 94.565H7.642c-.533 0-.888-.354-.888-.708L0 .885c0-.177 0-.53.178-.708C.355.177.71 0 .888 0h49.408c.178 0 .356 0 .533.177L68.78 10.98c.178.177.356.354.356.708L75 93.68c0 .177 0 .531-.178.708-.178 0-.355.177-.71.177zm-65.758-1.77h64.87l-5.865-80.576-17.24-10.448H1.778l6.575 91.023z"
				fill="#555770"
			/>
			<path
				d="M68.246 11.688L50.296.885l6.576 14.168 11.374-3.365z"
				fill="#EDF1F7"
			/>
			<path
				d="M56.872 15.938c-.355 0-.71-.177-.71-.531L49.584 1.063c-.177-.355 0-.709.178-1.063.178 0 .711 0 .889.177l17.95 10.803c.355.177.533.53.355.885 0 .354-.355.531-.533.708L57.05 16.115c0-.177-.178-.177-.178-.177zM52.252 3.01l5.153 11.157 8.886-2.656-14.04-8.5zM28.791 42.678c.178 2.125-1.421 3.72-3.554 3.72s-3.91-1.595-4.088-3.72c-.178-2.125 1.422-3.718 3.555-3.718 2.132 0 3.91 1.593 4.087 3.718zM53.14 42.678c.178 2.125-1.422 3.72-3.555 3.72-2.132 0-3.91-1.595-4.087-3.72-.178-2.125 1.421-3.718 3.554-3.718s3.91 1.593 4.088 3.718zM60.071 66.94c-.533 0-.889-.355-.889-.709-.355-6.02-9.952-10.98-21.149-10.98-7.464 0-14.04 2.126-17.417 5.667-1.422 1.594-2.133 3.365-1.955 5.136 0 .531-.355.885-.71.885-.534 0-.89-.354-.89-.708-.177-2.302.712-4.427 2.489-6.375 3.554-3.896 10.841-6.375 18.66-6.375 12.264 0 22.394 5.49 22.928 12.573-.178.531-.534.885-1.067.885zM14.929 35.063c-.178 0-.356 0-.533-.177a.854.854 0 010-1.24l3.732-4.072a.861.861 0 011.244 0 .854.854 0 010 1.24l-3.732 4.072c-.356.177-.533.177-.711.177zM58.294 35.063c-.178 0-.355 0-.533-.177l-4.443-4.073a.854.854 0 010-1.24.861.861 0 011.244 0l4.443 4.074a.854.854 0 010 1.24c-.356.176-.533.176-.711.176z"
				fill="#555770"
			/>
		</svg>
	);
}

export default EmptyStateStaffIcon;

