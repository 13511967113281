import { connect } from 'react-redux';

import { IofSimulatorActions, IofSimulatorOperations } from '../../redux';

import IofInvoiceList from './iofInvoiceList';

const mapDispatchToProps = (dispatch) => ({
  loadNewPage: (dealerGroup, page) => (
    dispatch(IofSimulatorOperations.loadNewPage(dealerGroup, page))
  ),
  addToSelectedValue: (value) => (
    dispatch(IofSimulatorActions.addToSelectedValue(value))
  ),
});

const mapStateToProps = ({ iofSimulatorStore }) => ({
  dealerGroup: iofSimulatorStore.dealerGroup,
  invoiceList: iofSimulatorStore.invoices,
  page: iofSimulatorStore.page,
  hasMoreItems: iofSimulatorStore.hasMoreItens,
  loading: iofSimulatorStore.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(IofInvoiceList);
