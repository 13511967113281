import { connect } from 'react-redux';

import PaymentButton from './paymentButton';
import operationsPage from '../../../../../carteiraPage/redux/operations';
import operations from '../../../../redux/operations';

const mapDispatchToProps = (dispatch) => ({
  onClick: (invoice) => {
    dispatch(operationsPage.setSelectToPayment(invoice.duplicataId));
    dispatch(operations.setInvoice(invoice));
  },
  closeModal: () => dispatch(operations.closeModalInfo()),
});

export default connect(null, mapDispatchToProps)(PaymentButton);
