import React from 'react';
import PropTypes from 'prop-types';

import MultSelect from 'features/common/multSelect/multSelect';

const dictionary = () => ({
  singular: 'mensagem',
  plural: 'mensagens',
  type: 'a',
});

const perfilArray = [
  { label: 'usuarioNome', text: 'Apenas minhas' },
  { label: 'financiamento', text: 'Financiamento a rede' },
  { label: 'cadastro', text: 'Cadastro' },
];

const SelectFilterPerfil = ({
  perfil, setPerfil, isLoading, drawerWidth, isDrawer,
}) => (
  <MultSelect
    dictionary={dictionary()}
    dataCy="seletor-perfil"
    selectedOption={perfil || []}
    setOption={setPerfil}
    options={perfilArray}
    disabled={isLoading}
    minWidth={drawerWidth || 275}
    isDrawer={isDrawer}
  />
);

SelectFilterPerfil.propTypes = {
  perfil: PropTypes.array,
  setPerfil: PropTypes.func,
  isLoading: PropTypes.bool,
  drawerWidth: PropTypes.number,
  isDrawer: PropTypes.bool,
};

SelectFilterPerfil.defaultProps = {
  perfil: [],
  setPerfil: () => {},
  isLoading: false,
  drawerWidth: 275,
  isDrawer: false,
};

export default SelectFilterPerfil;
