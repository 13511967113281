import { connect } from 'react-redux';

import { AuthSelectors } from 'modules/auth/redux';
import PermissionRoute from './permissionRoute';

const mapStateToProps = ({ authStore }) => ({
  permissions: AuthSelectors.getAuthorizations(authStore),
});

export default connect(mapStateToProps)(PermissionRoute);
