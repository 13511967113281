const PREFIX = 'painelFloorPlan';
const PREFIX_GET_PRODUTOS = `${PREFIX}/GET_PRODUTOS`;
const PREFIX_GET_CARDS_RESUMO = `${PREFIX}/GET_CARDS_RESUMO`;
const PREFIX_GET_CARDS_VENCIMENTOS = `${PREFIX}/PREFIX_GET_CARDS_VENCIMENTOS`;
const PREFIX_GET_VALORES_CHART = `${PREFIX}/PREFIX_GET_VALORES_CHART`;
const PREFIX_GET_DUPLICATAS_CHART = `${PREFIX}/PREFIX_GET_DUPLICATAS_CHART`;
const PREFIX_GET_CARTEIRA_PRODUTO = `${PREFIX}/PREFIX_GET_CARTEIRA_PRODUTO`;
const PREFIX_GET_CARTEIRA_BRAND = `${PREFIX}/PREFIX_GET_CARTEIRA_BRAND`;

const SET_FILTER = `${PREFIX}/SET_FILTER`;
const SET_FILTER_TYPE = `${PREFIX}/SET_FILTER_TYPE`;
const DISABLE_FILTER_BUTTON = `${PREFIX}/DISABLE_FILTER_BUTTON`;
const RESET_STORE = `${PREFIX}/RESET_STORE`;

const GET_PRODUTOS_START = `${PREFIX_GET_PRODUTOS}/GET_PRODUTOS_START`;
const GET_PRODUTOS_SUCCESS = `${PREFIX_GET_PRODUTOS}/GET_PRODUTOS_SUCCESS`;
const GET_PRODUTOS_ERROR = `${PREFIX_GET_PRODUTOS}/GET_PRDUTOS_ERROR`;

const GET_CARDS_RESUMO_START = `${PREFIX_GET_CARDS_RESUMO}/GET_CARDS_RESUMO_START`;
const GET_CARDS_RESUMO_SUCCESS = `${PREFIX_GET_CARDS_RESUMO}/GET_CARDS_RESUMO_SUCCESS`;
const GET_CARDS_RESUMO_ERROR = `${PREFIX_GET_CARDS_RESUMO}/GET_CARDS_RESUMO_ERROR`;

const GET_CARDS_VENCIMENTO_START = `${PREFIX_GET_CARDS_VENCIMENTOS}/GET_CARDS_VENCIMENTO_START`;
const GET_CARDS_VENCIMENTO_SUCCESS = `${PREFIX_GET_CARDS_VENCIMENTOS}/GET_CARDS_VENCIMENTO_SUCCESS`;
const GET_CARDS_VENCIMENTO_ERROR = `${PREFIX_GET_CARDS_VENCIMENTOS}/GET_CARDS_VENCIMENTO_ERROR`;

const GET_VALORES_CHART_START = `${PREFIX_GET_VALORES_CHART}/GET_VALORES_CHART_START`;
const GET_VALORES_CHART_SUCCESS = `${PREFIX_GET_VALORES_CHART}/GET_VALORES_CHART_SUCCESS`;
const GET_VALORES_CHART_ERROR = `${PREFIX_GET_VALORES_CHART}/GET_VALORES_CHART_ERROR`;

const GET_DUPLICATAS_CHART_START = `${PREFIX_GET_DUPLICATAS_CHART}/GET_DUPLICATAS_CHART_START`;
const GET_DUPLICATAS_CHART_SUCCESS = `${PREFIX_GET_DUPLICATAS_CHART}/GET_DUPLICATAS_CHART_SUCCESS`;
const GET_DUPLICATAS_CHART_ERROR = `${PREFIX_GET_DUPLICATAS_CHART}/GET_DUPLICATAS_CHART_ERROR`;

const GET_CARTEIRA_PRODUTO_START = `${PREFIX_GET_CARTEIRA_PRODUTO}/GET_CARTEIRA_PRODUTO_START`;
const GET_CARTEIRA_PRODUTO_ERROR = `${PREFIX_GET_CARTEIRA_PRODUTO}/GET_CARTEIRA_PRODUTO_ERROR`;
const GET_CARTEIRA_PRODUTO_SUCCESS = `${PREFIX_GET_CARTEIRA_PRODUTO}/GET_CARTEIRA_PRODUTO_SUCCESS`;

const GET_CARTEIRA_BRAND_START = `${PREFIX_GET_CARTEIRA_BRAND}/GET_CARTEIRA_BRAND_START`;
const GET_CARTEIRA_BRAND_ERROR = `${PREFIX_GET_CARTEIRA_BRAND}/GET_CARTEIRA_BRAND_ERROR`;
const GET_CARTEIRA_BRAND_SUCCESS = `${PREFIX_GET_CARTEIRA_BRAND}/GET_CARTEIRA_BRAND_SUCCESS`;

const SET_IS_LOADING = `${PREFIX}/SET_IS_LOADING`;
const SET_IS_UPDATED = `${PREFIX}/SET_IS_UPDATED`;

const disableFilterButton = () => ({
  type: DISABLE_FILTER_BUTTON,
});

const setFilter = (paramName, value) => ({
  type: SET_FILTER,
  payload: { paramName, value },
});

const setFilterType = (type) => ({
  type: SET_FILTER_TYPE,
  payload: { type },
});
const getProdutosStart = () => ({
  type: GET_PRODUTOS_START,
});

const getProdutosSuccess = (produtos) => ({
  type: GET_PRODUTOS_SUCCESS,
  payload: { produtos },
});

const getProdutosError = () => ({
  type: GET_PRODUTOS_ERROR,
});

const getCardsResumoStart = () => ({
  type: GET_CARDS_RESUMO_START,
});

const getCardsResumoSuccess = (resumo) => ({
  type: GET_CARDS_RESUMO_SUCCESS,
  payload: { resumo },
});

const getCardsResumoError = () => ({
  type: GET_CARDS_RESUMO_ERROR,
});

const resetStore = () => ({
  type: RESET_STORE,
});

const getCardsVencimentoStart = () => ({
  type: GET_CARDS_VENCIMENTO_START,
});

const getCardsVencimentoSuccess = (cards) => ({
  type: GET_CARDS_VENCIMENTO_SUCCESS,
  payload: { cards },
});

const getCardsVencimentoError = () => ({
  type: GET_CARDS_VENCIMENTO_ERROR,
});

const getValoresChartStart = () => ({
  type: GET_VALORES_CHART_START,
});

const getValoresChartSuccess = (data) => ({
  type: GET_VALORES_CHART_SUCCESS,
  payload: { data },
});

const getValoresChartError = () => ({
  type: GET_VALORES_CHART_ERROR,
});

const getDuplicatasChartStart = () => ({
  type: GET_DUPLICATAS_CHART_START,
});

const getDuplicatasChartSuccess = (data) => ({
  type: GET_DUPLICATAS_CHART_SUCCESS,
  payload: { data },
});

const getDuplicatasChartError = () => ({
  type: GET_DUPLICATAS_CHART_ERROR,
});

const getCarteiraProdutoStart = () => ({
  type: GET_CARTEIRA_PRODUTO_START,
});

const getCarteiraProdutoError = () => ({
  type: GET_CARTEIRA_PRODUTO_ERROR,
});

const getCarteiraProdutoSuccess = (data) => ({
  type: GET_CARTEIRA_PRODUTO_SUCCESS,
  payload: { data },
});

const getCarteiraBrandStart = () => ({
  type: GET_CARTEIRA_BRAND_START,
});

const getCarteiraBrandError = () => ({
  type: GET_CARTEIRA_BRAND_ERROR,
});

const getCarteiraBrandSuccess = (data) => ({
  type: GET_CARTEIRA_BRAND_SUCCESS,
  payload: { data },
});

const setIsUpdated = (data) => ({
  type: SET_IS_UPDATED,
  payload: { data },
});

const setIsLoading = () => ({
  type: SET_IS_LOADING,
});

export default {
  types: {
    SET_FILTER,
    SET_FILTER_TYPE,
    DISABLE_FILTER_BUTTON,
    RESET_STORE,

    PREFIX_GET_PRODUTOS,
    GET_PRODUTOS_ERROR,
    GET_PRODUTOS_START,
    GET_PRODUTOS_SUCCESS,

    PREFIX_GET_CARDS_RESUMO,
    GET_CARDS_RESUMO_START,
    GET_CARDS_RESUMO_SUCCESS,
    GET_CARDS_RESUMO_ERROR,

    PREFIX_GET_CARDS_VENCIMENTOS,
    GET_CARDS_VENCIMENTO_ERROR,
    GET_CARDS_VENCIMENTO_START,
    GET_CARDS_VENCIMENTO_SUCCESS,

    PREFIX_GET_VALORES_CHART,
    GET_VALORES_CHART_START,
    GET_VALORES_CHART_SUCCESS,
    GET_VALORES_CHART_ERROR,

    PREFIX_GET_DUPLICATAS_CHART,
    GET_DUPLICATAS_CHART_START,
    GET_DUPLICATAS_CHART_SUCCESS,
    GET_DUPLICATAS_CHART_ERROR,

    PREFIX_GET_CARTEIRA_PRODUTO,
    GET_CARTEIRA_PRODUTO_ERROR,
    GET_CARTEIRA_PRODUTO_START,
    GET_CARTEIRA_PRODUTO_SUCCESS,

    PREFIX_GET_CARTEIRA_BRAND,
    GET_CARTEIRA_BRAND_ERROR,
    GET_CARTEIRA_BRAND_START,
    GET_CARTEIRA_BRAND_SUCCESS,

    SET_IS_UPDATED,
    SET_IS_LOADING,
  },

  setFilter,
  disableFilterButton,
  setFilterType,
  getCardsResumoStart,
  getCardsResumoSuccess,
  getCardsResumoError,
  resetStore,
  getProdutosError,
  getProdutosStart,
  getProdutosSuccess,
  getCardsVencimentoError,
  getCardsVencimentoStart,
  getCardsVencimentoSuccess,
  getValoresChartStart,
  getValoresChartSuccess,
  getValoresChartError,
  getDuplicatasChartStart,
  getDuplicatasChartSuccess,
  getDuplicatasChartError,
  getCarteiraProdutoError,
  getCarteiraProdutoStart,
  getCarteiraProdutoSuccess,
  getCarteiraBrandError,
  getCarteiraBrandStart,
  getCarteiraBrandSuccess,
  setIsUpdated,
  setIsLoading,
};
