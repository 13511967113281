import React from 'react';

import MesSelector from './mesSelector';
import AnoSelector from './anoSelector';
import BrandSelector from './brandSelector';
import FilterButton from './filterButton';

import './filters.scss';

const Filters = () => (
  <div className="modal-taxas__filters-container">
    <div className="modal-taxas__filters-container__filtro" data-cy="ModalTaxasBrandSelect">
      <BrandSelector />
    </div>
    <div className="modal-taxas__filters-container__filtro" data-cy="ModalTaxasMesSelect">
      <MesSelector />
    </div>
    <div className="modal-taxas__filters-container__filtro" data-cy="ModalTaxasAnoSelect">
      <AnoSelector />
    </div>
    <div className="modal-taxas__filters-container__button" data-cy="ModalTaxasFilterButton">
      <FilterButton />
    </div>
  </div>
);

export default Filters;
