import React from 'react';
import { PageErrorBoundary } from 'features/common';
import {
  Page, PageContent, PageHeader, PageSubTitle, PageTitle,
} from 'features/common-staff/layout/page';
import BreadCrumbJurosCarencia from './breadCrumbJurosCarencia/breadCrumbJurosCarencia';
import JurosCarenciaPageContent from './jurosCarenciaPageContent/jurosCarenciaPageContent';

const JurosCarenciaPage = () => (
  <PageErrorBoundary>
    <Page minWidth="fit-content">
      <PageHeader>
        <PageSubTitle>
          <BreadCrumbJurosCarencia />
        </PageSubTitle>
        <PageTitle data-cy="PageTitle">Juros carência</PageTitle>
      </PageHeader>
      <PageContent>
        <JurosCarenciaPageContent />
      </PageContent>
    </Page>
  </PageErrorBoundary>
);

export default JurosCarenciaPage;
