import { connect } from 'react-redux';

import UploadFiles from './uploadFiles';

import { TestDriveOperations } from '../../../redux';

const mapDispatchToProps = (dispatch) => ({
  openModalUpload: () => dispatch(TestDriveOperations.openModalUpload()),
});

export default connect(null, mapDispatchToProps)(UploadFiles);
