import React from 'react';
import { PageErrorBoundary } from 'features/common';
import {
  Page, PageContent, PageHeader, PageSubTitle, PageTitle,
} from 'features/common-staff/layout/page';
import { makeStyles } from '@material-ui/styles';
import SplitView from 'features/common-staff/layout/splitView';
import { useHistoricoResgateContext } from 'features/carteiraFidc/contexts/historicoResgateContext';
import AlertaMensagem from 'features/common/alertaMensagem';
import { Box } from '@mui/material';
import BreadCrumbHistoricoResgates from './breadCrumbHistoricoResgates/breadCrumbHistoricoResgates';
import HistoricoResgatesFilter from './historicoResgatesFilter/historicoResgatesFilter';
import HistoricoResgatesList from './historicoResgatesList';
import DetalheResgate from './detalheResgates/detalheResgate';

const useStyles = makeStyles({
  headerContainer: {
    padding: '12px 32px',
  },
  root: {
    overflowX: 'hidden',
    display: 'grid',
    height: '100%',
    gridTemplateRows: 'auto 1fr',
    minWidth: '920px',
  },
  filters: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const HistoricoResgatesPage = () => {
  const {
    state, closeDrawer, transitionDrawer,
  } = useHistoricoResgateContext();
  const { resgateDrawer, isUpdated } = state;
  const { isOpen, resgateDetalhe, transition } = resgateDrawer;
  const classes = useStyles();
  return (
    <PageErrorBoundary>
      <SplitView
        open={isOpen}
        sideSheetProps={{
          onClickClose: () => {
            closeDrawer();
          },
          width: 400,
          onTransitionEnd: () => {
            if (transition) transitionDrawer();
          },
        }}
        renderSideSheetContent={() => (
          <DetalheResgate
            resgate={resgateDetalhe}
            isOpen={isOpen}
            closeDrawer={closeDrawer}
          />
        )}
      >
        <Page minWidth="fit-content" hideFooter>
          <PageHeader className={classes.headerContainer}>
            <PageSubTitle>
              <BreadCrumbHistoricoResgates />
            </PageSubTitle>
            <PageTitle data-cy="PageTitle">Histórico de resgates</PageTitle>
          </PageHeader>
          {
            isUpdated
              ? (
                <PageContent>
                  <Box className={classes.root}>
                    <Box className={classes.filters}>
                      <HistoricoResgatesFilter />
                    </Box>
                    <Box>
                      <HistoricoResgatesList />
                    </Box>
                  </Box>
                </PageContent>
              )
              : (
                <div>
                  <AlertaMensagem
                    breakLine
                    mensagem={'A carteira FIDC está indisponível no momento.\nPor favor, tente novamente mais tarde.'}
                  />
                </div>
              )
          }
        </Page>
      </SplitView>
    </PageErrorBoundary>
  );
};

export default HistoricoResgatesPage;
