const PREFIX = 'carteiraModal';

const SET_MODAL_INFO = `${PREFIX}/SET_MODAL_INFO`;
const SET_MODAL_INFO_OPEN = `${PREFIX}/SET_MODAL_INFO_OPEN`;
const REMOVE_INVOICE = `${PREFIX}/REMOVE_INVOICE`;
const SET_INVOICE = `${PREFIX}/SET_INVOICE`;
const RESET_STORE = `${PREFIX}/RESET_STORE`;
const SELECT_ALL = `${PREFIX}/SELECT_ALL`;

const setModalInfo = (data) => ({
  type: SET_MODAL_INFO,
  payload: { data },
});

const setModalInfoOpen = (value) => ({
  type: SET_MODAL_INFO_OPEN,
  payload: { value },
});

const removeInvoice = (invoiceId) => ({
  type: REMOVE_INVOICE,
  payload: { invoiceId },
});

const setInvoice = (invoice) => ({
  type: SET_INVOICE,
  payload: { invoice },
});

const resetStore = () => ({
  type: RESET_STORE,
});

const selectALL = (invoices) => ({
  type: SELECT_ALL,
  payload: { invoices },
});

export default {
  types: {
    RESET_STORE,
    SET_MODAL_INFO,
    SET_MODAL_INFO_OPEN,
    SET_INVOICE,
    REMOVE_INVOICE,
    SELECT_ALL,
  },

  resetStore,
  setModalInfo,
  setModalInfoOpen,
  removeInvoice,
  setInvoice,
  selectALL,
};
