import { React, useMemo } from 'react';
import PropTypes from 'prop-types';
import './historicoLimite.scss';
import TimelineDropdown from 'features/common-staff/controls/timelineDropdown';
import { deburr } from 'lodash';
import moment from 'moment';

const HistoricoLimite = ({ historico }) => {
  const timelineItems = useMemo(() => historico?.slice(0).reverse().map((item) => {
    const datetime = moment(item?.dataHora);
    const date = datetime.format('DD/MM/YYYY');
    const time = datetime.format('HH:mm');
    const search = deburr(item?.descricao?.toLowerCase());

    return {
      date,
      time,
      text: item?.descricao,
      search: [search, date].join(' '),
    };
  }), [historico]);

  return (
    <div className="historico">
      <TimelineDropdown
        label="Histórico da aprovação"
        items={timelineItems}
        defaultItem={{ text: 'Aguardando liberação do financiamento rede' }}
      />
    </div>
  );
};

HistoricoLimite.propTypes = {
  historico: PropTypes.array,
};
HistoricoLimite.defaultProps = {
  historico: [],
};
export default HistoricoLimite;
