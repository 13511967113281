/* eslint-disable no-tabs */
import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import {
  PageHeader, PageTitle, PageSubTitle,
} from 'features/common-staff/layout/page';
import AlertaMensagem from 'features/common/alertaMensagem';
import EmptyStateIcon from 'assets/icons/empty-state';
import colors from 'assets/styles/colors';
import { PageErrorBoundary, ErroConexao } from '../../../common';

import IofSimulatorMenu from '../iofSimulatorMenu';
import IofInvoiceList from '../iofInvoiceList';
import IofErrorBox from '../iofErrorBox';
import BreadCrumbIof from '../breadCrumbIof';
import DateFilter from '../dateFilter';

import './iofSimulatorPage.scss';

const CircularProgressHub = withStyles({
  root: {
    '& svg': {
      color: colors.primary_color_500
      ,
    },
  },
})(CircularProgress);

class IofSimulatorPage extends React.Component {
  componentDidMount() {
    const { getInvoicesWithBilling, dealerGroup, getFinancialValues } = this.props;
    if (dealerGroup) {
      getInvoicesWithBilling(dealerGroup, 0);
      getFinancialValues(dealerGroup);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      getInvoicesWithBilling, dealerGroup, getFinancialValues, startDate, endDate,
      setStartDate, setEndDate,
    } = this.props;
    if (dealerGroup && prevProps.dealerGroup !== dealerGroup) {
      setStartDate(null);
      setEndDate(null);
      getInvoicesWithBilling(dealerGroup, 0);
      getFinancialValues(dealerGroup);
    }
    if (startDate !== prevProps.startDate
			|| endDate !== prevProps.endDate) {
      getInvoicesWithBilling(dealerGroup, 0);
      getFinancialValues(dealerGroup);
    }
  }

  componentWillUnmount() {
    const { resetStore } = this.props;
    resetStore();
  }

  getContent = (isLoadError, transmissionValue, isUpdated) => {
    if (isLoadError) {
      return (
        <div className="iof__error">
          <IofErrorBox />
        </div>
      );
    }
    if (!isUpdated) {
      return (
        <div className="iof__alerta-mensagem">
          <AlertaMensagem
            mensagem="Desculpe, estamos atualizando as informações."
            subMensagem="Tente novamente mais tarde."
          />
        </div>
      );
    }
    if (transmissionValue === 0) {
      return (
        <ErroConexao
          title="Nenhuma transmissão identificada."
          message="Entre novamente mais tarde para verificar se existe uma transmissão."
        >
          <EmptyStateIcon className="erro-conexao-image" />
        </ErroConexao>
      );
    }
    if (transmissionValue !== 0 && transmissionValue) {
      return (
        <IofInvoiceList className="iof__list" />
      );
    }
    return '';
  };

  render() {
    const {
      className, isLoadError, transmissionValue, loading, isUpdated,
    } = this.props;

    return (
      <PageErrorBoundary>
        <div className={`iof__page ${className}`}>
          {
            (!isLoadError && isUpdated) ? (
              <section className="iof__menu-container">
                <IofSimulatorMenu />
              </section>
            ) : <section className="iof__menu-container" />
          }
          <PageErrorBoundary>
            <PageHeader className="iof__page-title">
              <PageSubTitle>
                <BreadCrumbIof />
              </PageSubTitle>
              <PageTitle>Simulador de Pagamento</PageTitle>
            </PageHeader>
            <>
              {(transmissionValue === 0) ? null
                : (
                  <>
                    <div
                      className="iof__legal-text"
                      data-cy="IofLegalText"
                    >
                      Este simulador possui caráter meramente ilustrativo e visa possibilitar
                      aos concessionários uma melhor gestão de seu fluxo de caixa, considerando as
                      transmissões de duplicatas Floor Plan realizadas nesta data e horário,
                      pela montadora ao Banco Stellantis.
                      Transmissão do dia e horário sujeitas a alteração. Os pagamentos efetuados
                      neste dia e horário não estão contemplados na simulação.
                    </div>
                    <div className="iof__filter__content">
                      <DateFilter />
                    </div>
                  </>
                ) }
              {
              (loading) ? (
                <div className="iof__page-spinner">
                  <CircularProgressHub />
                </div>
              ) : (
                <>

                  {this.getContent(isLoadError, transmissionValue, isUpdated)}
                </>
              )
            }
            </>
          </PageErrorBoundary>
        </div>
      </PageErrorBoundary>
    );
  }
}

IofSimulatorPage.propTypes = {
  className: PropTypes.string,
  dealerGroup: PropTypes.object,
  isLoadError: PropTypes.bool.isRequired,
  transmissionValue: PropTypes.number,
  loading: PropTypes.bool.isRequired,

  getInvoicesWithBilling: PropTypes.func.isRequired,
  resetStore: PropTypes.func.isRequired,
  getFinancialValues: PropTypes.func.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  isUpdated: PropTypes.bool,
};

IofSimulatorPage.defaultProps = {
  transmissionValue: null,
  className: '',
  dealerGroup: null,
  startDate: null,
  endDate: null,
  setStartDate: () => {},
  setEndDate: () => {},
  isUpdated: true,
};

export default withNamespaces('invoicePage')(IofSimulatorPage);
