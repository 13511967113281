import React from 'react';

import BreadCrumb from 'features/common-staff/layout/breadCrumb';

const BreadCrumbIof = () => (
  <BreadCrumb
    labels={[{
      label: 'Simulador de Pagamento',
      path: ' ',
    }]}
  />
);

export default BreadCrumbIof;
