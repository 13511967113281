import React from 'react';
import PropTypes from 'prop-types';
import './modalLimitePropostaDetailSection.scss';

const ModalLimitePropostaDetailSection = ({ items }) => (
  <div className="modal-limite-proposta__dados">
    <div className="modal-limite-proposta__items">
      {items?.map((item) => (
        <div className="modal-limite-proposta__item" key={item.chave}>
          <div className="modal-limite-proposta__item-chave">
            {item.chave}
          </div>
          <div className="modal-limite-proposta__item-valor">
            {item.valor}
          </div>
        </div>
      ))}
    </div>
  </div>
);

ModalLimitePropostaDetailSection.propTypes = {
  items: PropTypes.array,
};

ModalLimitePropostaDetailSection.defaultProps = {
  items: [],
};

export default ModalLimitePropostaDetailSection;
