import React from "react";

const DirectionsCarIcon = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			d="M10.613 1.34a.996.996 0 00-.946-.673H2.333c-.44 0-.806.28-.946.673L.073 5.12C.027 5.26 0 5.407 0 5.56v4.773c0 .554.447 1 1 1 .553 0 1-.446 1-1V10h8v.333c0 .547.447 1 1 1 .547 0 1-.446 1-1V5.56c0-.147-.027-.3-.073-.44l-1.314-3.78zM2.333 8c-.553 0-1-.447-1-1 0-.553.447-1 1-1 .554 0 1 .447 1 1 0 .553-.446 1-1 1zm7.334 0c-.554 0-1-.447-1-1 0-.553.446-1 1-1 .553 0 1 .447 1 1 0 .553-.447 1-1 1zM1.333 4.667L2.18 2.12a.676.676 0 01.633-.453h6.374c.286 0 .54.186.633.453l.847 2.547H1.333z"
			fill={props.fill ? `${props.fill}` : "currentColor"}
			fillRule="evenodd"
		/>
	</svg>
);

export default DirectionsCarIcon;

