import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

import './button.scss';

const selectColorClass = (color) => {
  switch (color) {
    case 'white':
      return 'common__btn__white';
    case 'darkGray':
      return 'common__btn__dark-gray';
    case 'darkGrayBorder':
      return 'common__btn__dark-gray-border';
    case 'light':
      return 'common__btn__light';
    case 'red':
      return 'common__btn__red';
    case 'blue':
      return 'common__btn__blue';
    case 'gray':
    case 'grey':
      return 'common__btn__gray';
    default:
      return 'common__btn__new-blue';
  }
};

const Button = ({
  className, onClick, fullWidth, disabled, children, isLoading, color, dataCy,
}) => (
  <button
    className={
      `common__btn
      ${selectColorClass(color)}
      ${fullWidth ? 'common__btn--full-width' : ''}
      ${className}`
    }
    type="button"
    disabled={disabled || isLoading}
    onClick={onClick}
    data-cy={dataCy}
  >
    <div className={
        `common__btn__content
        ${isLoading ? 'common__btn__content--loading' : ''}`
      }
    >
      {children}
    </div>

    {isLoading ? (
      <div className="common__btn__loading">
        <div>
          <CircularProgress className="common__btn__loading" color="inherit" size="18px" />
        </div>
      </div>
    ) : null}
  </button>
);

Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  color: PropTypes.string,
  dataCy: PropTypes.string,
  fullWidth: PropTypes.bool,
};

Button.defaultProps = {
  className: '',
  onClick: null,
  children: null,
  disabled: false,
  color: '',
  dataCy: '',
  isLoading: false,
  fullWidth: false,
};

export default Button;
