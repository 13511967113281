import React from 'react';
import PropTypes from 'prop-types';

import { Tracking } from 'modules';
import NewMultipleSelectComponent from 'features/common-staff/controls/newMultipleSelectComponent/newMultipleSelectComponent';

const ProdutoSelector = ({
  produtos, selectedProdutos, setFilter,
  isLoading,
}) => {
  const dictionary = () => ({
    singular: 'produto',
    plural: 'produtos',
    type: 'o',
  });

  return (
    <NewMultipleSelectComponent
      options={produtos}
      setOption={(b) => {
        Tracking.Mixpanel.dashboard.filtros.trackFiltrosProduto();
        setFilter(b);
      }}
      dictionary={dictionary()}
      startWithAllSelected
      selectedOption={selectedProdutos}
      label="Produto"
      disabled={isLoading}
      dataCy="produtos"
    />
  );
};

ProdutoSelector.propTypes = {
  setFilter: PropTypes.func,
  selectedProdutos: PropTypes.array,
  produtos: PropTypes.array,
  isLoading: PropTypes.bool,
};

ProdutoSelector.defaultProps = {
  setFilter: () => { },
  selectedProdutos: null,
  produtos: [],
  isLoading: false,
};

export default ProdutoSelector;
