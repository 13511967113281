import React from 'react';
import TextField from 'features/common-staff/controls/input/textField';

const TextFieldValor = ({ ...params }) => (
  <TextField
    {...params}
    InputProps={{
      ...params.InputProps,
    }}
    fullWidth
    autoFocus
  />
);

export default TextFieldValor;
