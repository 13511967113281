import axios from 'axios';
import _ from 'lodash';

import { formatDateForUrl } from 'utils/axios';

const getParamsInvoicesSimulator = (dealerGroup, page, itensPerPage, filters) => {
  const params = new URLSearchParams();
  params.append('p', page);
  params.append('ipp', itensPerPage);
  if (!_.isNull(filters.startDate) && !_.isUndefined(filters.startDate)) {
    params.append('startDate', formatDateForUrl(filters.startDate));
  }
  if (!_.isNull(filters.endDate) && !_.isUndefined(filters.endDate)) {
    params.append('endDate', formatDateForUrl(filters.endDate));
  }
  if (dealerGroup.brand.toLowerCase() !== 'fiat') {
    params.append('b', 'JEEP');
    params.append('b', 'CHRYSLER');
    params.append('b', 'DODGE');
    params.append('b', 'RAM');
  } else {
    params.append('b', dealerGroup.brand);
  }
  return params;
};
class IofSimulatorService {
  constructor() {
    this.URL_API = window.env.REACT_APP_API_URL;
  }

  async getFinancialValues(dealerGroup) {
    if (!dealerGroup || !dealerGroup.matrizId || !dealerGroup.brand) {
      throw new Error('Error getting Invoices. Invalid dealer group');
    }
    const params = new URLSearchParams();
    if (dealerGroup.brand.toLowerCase() !== 'fiat') {
      params.append('b', 'JEEP');
      params.append('b', 'CHRYSLER');
      params.append('b', 'DODGE');
      params.append('b', 'RAM');
    } else {
      params.append('b', dealerGroup.brand);
    }
    return axios.get(`${this.URL_API}/iof`, {
      headers: {
        'fidis-dealer-matriz-id': dealerGroup.matrizId,
      },
      params,
    });
  }

  async getInvoicesWithBilling(dealerGroup, page, itensPerPage, filters) {
    if (!dealerGroup || !dealerGroup.matrizId || !dealerGroup.brand) {
      throw new Error('Error getting Invoices. Invalid dealer group');
    }
    const params = getParamsInvoicesSimulator(dealerGroup, page, itensPerPage, filters);
    return axios.get(`${this.URL_API}/invoices/simulator`, {
      headers: {
        'fidis-matriz-id': dealerGroup.matrizId,
      },
      params,
    });
  }
}

export default new IofSimulatorService();
