import { createSelector } from 'reselect';

const isProduto = (state) => state?.alteracaoLimite?.dados?.produto;
const isCodigoSap = (state) => state?.alteracaoLimite?.dados?.codigoSap;
const isValor = (state) => state?.alteracaoLimite?.dados?.valor;
const isBrand = (state) => state?.alteracaoLimite?.dados?.brand;
const isConcesisonaria = (state) => state?.alteracaoLimite?.dados?.selectedDealerId;

const isValidSap = createSelector(
  isCodigoSap,
  (codigoSap) => (codigoSap?.length < 6),
);

const isSendDisabled = createSelector(
  isProduto,
  isCodigoSap,
  isValor,
  isConcesisonaria,
  isValidSap,
  isBrand,
  (produto, codigoSap, valor, concessionaria, validSap, brand) => (produto == null
    || codigoSap?.length !== 6 || valor == null || concessionaria == null
    || validSap || brand == null),
);

export default {
  isSendDisabled,
  isValidSap,
};
