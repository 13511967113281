import { connect } from 'react-redux';

import OrderByButton from './orderByButton';

import operations from '../../../../../../redux/operations';

const mapStateToProps = ({ statementStore }) => ({
  order: statementStore.filters?.order,
});

const mapDispatchToProps = (dispatch) => ({
  onClick: (order) => dispatch(operations.setFilter('order', order === 'ASC' ? 'DESC' : 'ASC')),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderByButton);
