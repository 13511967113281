import { connect } from 'react-redux';
import { Tracking } from 'modules';

import InputDocumento from './inputDocumento';

import operations from '../../../redux/operations';

const mapStateToProps = ({ comunicados }) => ({
  documento: comunicados.page.filtros?.documento,
  isLoading: comunicados?.page?.requestStatus?.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setDocumento: (value) => {
    Tracking.Mixpanel.centralComunicados.filtros.trackFiltroBusca();
    dispatch(operations.setFilter('documento', value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InputDocumento);
