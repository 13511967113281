import React from 'react';
import './headerHistorico.scss';

const HeaderHistorico = () => (
  <div className="header-historico__header">
    <div className="header-historico__header__processamento">
      PROCESSAMENTO
    </div>
    <div className="header-historico__header__pagamento">
      PAGAMENTO
    </div>
    <div className="header-historico__header__historico ">
      HISTÓRICO
    </div>
    <div className="header-historico__header__documento">
      DOCUMENTO
    </div>
    <div className="header-historico__header__valor-documento">
      VALOR DOCUMENTO
    </div>
    <div className="header-historico__header__cheque">
      CHEQUE
    </div>
  </div>
);

export default HeaderHistorico;
