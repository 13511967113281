import { connect } from 'react-redux';

import Header from './header';

const mapStateToProps = ({ limiteCreditoStore }) => ({
  tipo: limiteCreditoStore.acompanhamento.detalhes.solicitacao?.tipo,
  date: limiteCreditoStore.acompanhamento.detalhes.solicitacao?.data,
});

export default connect(mapStateToProps, null)(Header);
