import React from 'react';
import PropTypes from 'prop-types';

import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core';
import colors from 'assets/styles/colors';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex !important',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
    marginRight: '12px ',
    borderRadius: '4px',
    marginLeft: '0px',
    padding: 0,

    '& .MuiRadio-root': {
      color: `${colors.secundary_color_600}`,
    },
  },
  labelPlacementStart: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
    marginRight: '16px',
    marginLeft: '0px',
  },
  radioChecked: {
    color: 'inherit !important',
  },
  label: {
    marginRight: 'auto',
    marginLeft: '16px',
    color: 'inherit',
    opacity: '1',
  },
}));

const RadioGroupItem = ({ item, selectedItem }) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      key={item?.value}
      classes={{ labelPlacementStart: classes.labelPlacementStart, label: classes.label }}
      className={`${classes.root}  ${item?.value.toString() === selectedItem ? 'radio-group-item-checked' : 'radio-group-item'}`}
      labelPlacement="start"
      value={item?.value}
      control={(
        <Radio
          classes={{ checked: classes.radioChecked }}
          checked={item?.value.toString() === selectedItem}
        />
    )}
      label={item?.label}
    />
  );
};

RadioGroupItem.propTypes = {
  item: PropTypes.object,
  selectedItem: PropTypes.string,
};

RadioGroupItem.defaultProps = {
  item: null,
  selectedItem: '',
};

export default RadioGroupItem;
