import { connect } from 'react-redux';

import PaymentButton from './paymentButton';
import operations from '../../redux/operations';

const mapStateToProps = ({ gestaoPagamentos }) => ({
  isLoading: gestaoPagamentos.pagamento.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  onClick: () => dispatch(operations.postPayment()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentButton);
