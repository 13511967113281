import axios from 'axios';

class CarteiraService {
  constructor() {
    this.URL_API = window.env.REACT_APP_API_URL;
  }

  async getExtrato(duplicata) {
    const response = await axios.get(`${this.URL_API}/transactions/extrato/${duplicata}`, {
      responseType: 'arraybuffer',
    });

    if (!response || response.status !== 200) {
      throw new Error('Error downloading extrato estorno xlsx');
    }

    return response.data;
  }
}

export default new CarteiraService();
