import React from 'react';

import PaginationFooter from 'features/common-staff/layout/paginationFooter/paginationFooter';
import { useDuplicataContext } from 'features/carteiraFidc/contexts/duplicataContext';
import useToggleEffect from 'hooks/useToggleEffect';

const DuplicataFooter = () => {
  const duplicataContext = useDuplicataContext();
  const { setPagination, state, getDuplicatas } = duplicataContext;
  const { isOpen } = state.duplicataDrawer;
  const { loading } = state.requestStatus;
  const { ipp, page, total } = state.pageParams;

  const [toggleFetch] = useToggleEffect(getDuplicatas, [ipp, page]);

  const setPageFetch = (value) => {
    setPagination('page', value);
    toggleFetch();
  };

  const setIpp = (value) => setPagination('ipp', value);

  return (
    <PaginationFooter
      ipp={ipp}
      page={page}
      totalItems={total}
      loading={loading}
      setIpp={setIpp}
      setPageFetch={setPageFetch}
      isDrawerOpen={isOpen}
    />
  );
};

export default DuplicataFooter;
