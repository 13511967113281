import React from 'react';
import PropTypes from 'prop-types';

import Tracking from '../../../../modules/tracking';
import { RenderIfNotFidis } from '../../../../modules/auth/guards';

import MainContentHeader from './mainContentHeader';
import CardUserFeedback from './cardUserFeedback';
import CardReportError from './cardReportError';
import { content } from '../../redux/content';

import './popoverContentMain.scss';

const PopoverContentMain = ({
  className, setIsOpen, setContent, permissions,
}) => (
  <div className={`chat-popover__popover-content-main__container ${className} ${permissions.solicitarTestDrive === false ? 'chat-popover__popover-content-main__popover__upper-background-full-height' : ''}`}>
    <div className="chat-popover__popover-content-main__background">
      <div className="chat-popover__popover-content-main__popover__upper-background" />
    </div>
    <div className="chat-popover__popover-content-main__content">
      <MainContentHeader
        className="chat-popover__popover-content-main__header__container"
        onClose={() => setIsOpen(false)}
      />
      {permissions.solicitarTestDrive === true
        ? (
          <CardUserFeedback
            className="chat-popover__popover-content-main__popover-search__container"
            onClick={() => setContent(content.testDriveFeedback)}
          />
        )
        : null}
      <div className="chat-popover__popover-content-main__divider" />

      <RenderIfNotFidis>
        <CardReportError
          className="chat-popover__popover-content-main__error-card-container"
          onClick={() => {
            setContent(content.errorFeedback);
            Tracking.Mixpanel.feedback.error.trackBegin();
          }}
        />
      </RenderIfNotFidis>
    </div>
  </div>
);

PopoverContentMain.propTypes = {
  className: PropTypes.string,
  permissions: PropTypes.object.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setContent: PropTypes.func.isRequired,
};

PopoverContentMain.defaultProps = {
  className: '',
};

export default PopoverContentMain;
