import { combineReducers } from 'redux';

import CarteiraPageReducer from '../carteiraPage/redux/reducer';
import CarteiraModalReducer from '../carteiraModal/redux/reducer';
import CarteiraModalTaxasReducer from '../modalTaxasFloorPlan/redux/reducer';
import CarteiraModalHistoricoDuplicata from '../modalHistoricoDuplicata/redux/reducer';

export default combineReducers({
  page: CarteiraPageReducer,
  modal: CarteiraModalReducer,
  modalTaxas: CarteiraModalTaxasReducer,
  modalHistoricoDuplicata: CarteiraModalHistoricoDuplicata,
});
