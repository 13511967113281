import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import List, { ListContent } from '../../../common-staff/layout/list';
import RelatoriosListHeader from './listagemHeader';
import RelatorioListRow from './listRow';

import './relatorioList.scss';

const RelatorioList = ({ documentos, isLoading, isError }) => (
  <div className="relatorios-page__list__container">
    <RelatoriosListHeader />
    <List
      isLoading={isLoading}
      isError={isError}
      isEmpty={_.isEmpty(documentos)}
    >
      <ListContent data-cy="RelatoriosListContent">
        {(Array.isArray(documentos) ? documentos : []).map((d, i) => (
          <RelatorioListRow
            documento={d}
            key={i}
          />
        ))}
      </ListContent>

      <ListContent type="empty">
        Nenhum relatório foi encontrado.
      </ListContent>

      <ListContent type="error">
        Ocorreu um erro ao carregar a listagem de relatórios.
      </ListContent>
    </List>
  </div>
);

RelatorioList.propTypes = {
  documentos: PropTypes.array,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
};

RelatorioList.defaultProps = {
  documentos: null,
  isLoading: false,
  isError: false,
};

export default RelatorioList;
