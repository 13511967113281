import React from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import ButtonTooltipIcon from 'features/common/buttonTooltipIcon';

import ButtonIcon from '../../../../../../../common-staff/controls/buttonIcon';

const EditButton = ({ state, onClick, invoiceId }) => (

  <ButtonIcon
    onClick={() => onClick(invoiceId)}
  >
    {state
      ? (
        <ButtonTooltipIcon title="Editar">
          <EditIcon sx={{ fontSize: 14 }} />
        </ButtonTooltipIcon>
      )
      : (
        <ButtonTooltipIcon title="Salvar">
          <CheckIcon sx={{ fontSize: 14 }} />
        </ButtonTooltipIcon>
      )}
  </ButtonIcon>
);

EditButton.propTypes = {
  state: PropTypes.bool,
  onClick: PropTypes.func,
  invoiceId: PropTypes.number,
};

EditButton.defaultProps = {
  state: true,
  onClick: () => {},
  invoiceId: null,
};

export default EditButton;
