import { connect } from 'react-redux';
import { compose } from 'redux';
import { push } from 'react-router-redux';
import { withRouter } from 'react-router-dom';

import BackButton from './backButton';
import {
  PATH_CARTEIRA,
} from '../../../../../../routes/paths';

const mapDispatchToProps = (dispatch) => ({
  onClick: () => dispatch(push(PATH_CARTEIRA)),
});

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(BackButton);
