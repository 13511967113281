import React from 'react';
import PropTypes from 'prop-types';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import { formatCurrency } from 'utils/format';
import colors from 'assets/styles/colors';

const xAxisStyles = {
  fontSize: '10px',
  lineHeight: '12px',
  fontWeight: '450',
};

const yAxisStyles = {
  fontSize: '10px',
  lineHeight: '16px',
  fontWeight: '450',
};

const ChartValorDuplicatas = ({ data }) => (
  <ResponsiveContainer
    width="100%"
    height={500}
  >
    <BarChart
      data={data}
      margin={{
        top: 20,
        left: 24,
        bottom: 4,
      }}
    >
      <CartesianGrid vertical={false} />
      <XAxis type="category" dataKey="category" style={xAxisStyles} stroke="#7A7C9A" />
      <YAxis type="number" style={yAxisStyles} stroke="#7A7C9A" tickFormatter={formatCurrency} />
      <Bar dataKey="fundao" barSize={48} fill={colors.alert_color_200_80} stackId={0} />
      <Bar dataKey="floorPlan" barSize={48} fill={colors.primary_color_600} stackId={0} />
    </BarChart>
  </ResponsiveContainer>
);

ChartValorDuplicatas.propTypes = {
  data: PropTypes.object,
};

ChartValorDuplicatas.defaultProps = {
  data: null,
};

export default ChartValorDuplicatas;
