import { applyProperty } from 'utils/object';
import { applyFilter } from './reduxUtils';
import actions from './actions';
import { DateRange } from './enums';

const INITIAL_STATE = {
  filters: {
    text: null,
    startDate: null,
    endDate: null,
    filterType: DateRange.all,
    order: 'DESC',
    tipo: 'Todas',
  },

  isFilterSelected: false,

  snackbarErrors: [],
  snackbarId: 0,

  requestStatus: {
    isLoading: false,
    isError: false,
    isLoadingFile: false,
  },

  movimentacoes: [],

  pageParams: {
    page: 0,
    ipp: 25,
    itemsTotais: 0,
  },

  modal: {
    tipo: null,
    status: null,
    produto: null,
    index: null,
    detalhes: {},
    selectedId: null,
    isOpen: false,
    isLoading: false,
    header: '',
  },

  modalComprovante: {
    isOpen: false,
    isLoading: false,
  },

  isUpdated: true,

};

const reduceGetMovimentacao = (state, action) => {
  switch (action.type) {
    case actions.types.GET_MOVIMENTACOES_START:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          isError: false,
          isLoading: true,
        },
      };
    case actions.types.GET_MOVIMENTACOES_SUCCESS:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          isLoading: false,
          isError: false,
        },
        isFilterSelected: false,
        movimentacoes: action.payload.movimentacoes,
        pageParams: action.payload.pageParams,
        isUpdated: action.payload.isUpdated,
      };
    case actions.types.GET_MOVIMENTACOES_ERROR:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          isLoading: false,
          isError: true,
        },
        pageParams: {
          ...state.pageParams,
          page: 0,
          itemsTotais: 0,
        },
      };
    default:
      return state;
  }
};

const reduceGetDetalheMovimentacao = (state, action) => {
  switch (action.type) {
    case actions.types.GET_DETALHE_MOVIMENTACAO_START:
      return {
        ...state,
        modal: {
          ...state.modal,
          isLoading: true,
          isError: false,
          detalhes: null,
        },
      };
    case actions.types.GET_DETALHE_MOVIMENTACAO_SUCCESS:
      return {
        ...state,
        modal: {
          ...state.modal,
          isLoading: false,
          isError: false,
          detalhes: action.payload.detalhes,
        },
      };
    case actions.types.GET_DETALHE_MOVIMENTACAO_ERROR:
      return {
        ...state,
        modal: {
          ...state.modal,
          isLoading: false,
          isError: true,
          detalhes: {},
        },
      };
    default:
      return state;
  }
};

const reduceGetFile = (state, action) => {
  switch (action.type) {
    case actions.types.GET_FILE_START:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          isLoadingFile: true,
        },
      };
    case actions.types.GET_FILE_SUCCESS:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          isLoadingFile: false,
        },
      };
    case actions.types.GET_FILE_ERROR:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          isLoadingFile: false,
        },
        snackbarErrors: [
          ...state.snackbarErrors,
          {
            id: state.snackbarId + 1,
            message: 'Error ao exportar documento',
            type: 'error',
          },
        ],
        snackbarId: state.snackbarId + 1,
      };
    default:
      return state;
  }
};

export default (state = INITIAL_STATE, action = { type: '@@statementPage/INIT' }) => {
  if (action.type.startsWith(actions.types.PREFIX_GET_MOVIMENTACAO)) {
    return reduceGetMovimentacao(state, action);
  }

  if (action.type.startsWith(actions.types.PREFIX_GET_DETALHE_MOVIMENTACAO)) {
    return reduceGetDetalheMovimentacao(state, action);
  }

  if (action.type.startsWith(actions.types.PREFIX_GET_FILE)) {
    return reduceGetFile(state, action);
  }

  switch (action.type) {
    case actions.types.SET_FILTER:
      return {
        ...state,
        filters: applyProperty(
          state.filters,
          action.payload.propertyName,
          action.payload.value,
        ),
        isFilterSelected: true,
      };
    case actions.types.SET_PAGE_PARAMS:
      return {
        ...state,
        pageParams: applyProperty(
          state.pageParams,
          action.payload.propertyName,
          action.payload.value,
        ),
      };
    case actions.types.SET_MODAL_TIPO:
      return {
        ...state,
        modal: {
          ...state.modal,
          tipo: action.payload.tipo,
          status: action.payload.status,
          produto: action.payload.produto,
          index: action.payload.index,
          header: action.payload.header,
        },
      };
    case actions.types.SET_FILTER_DATE_TYPE:
      return {
        ...state,
        filters: applyFilter(state.filters, action.payload.value),
        isFilterSelected: true,
      };
    case actions.types.SET_FILTER_TRANSACTION_TYPE:
      return {
        ...state,
        filters: {
          ...state.filters,
          tipo: action.payload.value,
        },
        isFilterSelected: true,
      };
    case actions.types.SET_MODAL_OPEN:
      return {
        ...state,
        modal: {
          ...state.modal,
          isOpen: action.payload.value,
          selectedId: action.payload.selectedId,
        },
      };
    case actions.types.SET_MODAL_COMPROVANTE_OPEN:
      return {
        ...state,
        modalComprovante: {
          ...state.modalComprovante,
          isOpen: action.payload.value,
        },
      };
    case actions.types.DISMISS_SNACKBAR:
      return {
        ...state,
        snackbarErrors: state.snackbarErrors.filter((item) => item.id !== action.payload.id),
      };
    case actions.types.RESET_STORE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
