import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { formatDate } from 'utils/format';
import TableTaxa from '../tableTaxa';

import './taxas.scss';

const Taxas = ({ taxa }) => {
  const isBrandName = (brand) => {
    if (brand === 'FIAT_COM_FUNDO' || brand === 'FIAT_SEM_FUNDO') {
      return 'FIAT';
    }
    return 'CJDR';
  };
  const renderTableTaxa = (t, i) => {
    const showFirst = _.isNull(t.firstRowValue) && t.header !== 'TEST DRIVE'
      ? !t.showFirstRow
      : t.showFirstRow;
    const showSecond = _.isNull(t.secondRowValue) && (t.header === 'NOVOS' || t.header === 'USADOS')
      ? !t.showSecondRow
      : t.showSecondRow;

    if (_.isNull(t.firstRowValue) && _.isNull(t.secondRowValue) && t.header !== 'TEST DRIVE') {
      return null;
    }

    return (
      <TableTaxa
        header={t.header}
        firstRowName={t.firstRowName}
        secondRowName={t.secondRowName}
        firstRowValue={t.firstRowValue}
        secondRowValue={t.secondRowValue}
        firstRowPraticada={t.firstRowPraticada}
        secondRowPraticada={t.secondRowPraticada}
        showFirstRow={showFirst}
        showSecondRow={showSecond}
        key={i}
      />
    );
  };

  return (
    <div className="taxa_container">
      <div className="taxa_periodo">
        {`PERÍODO DE VIGÊNCIA ${isBrandName(taxa.brand)}:
      ${formatDate(taxa.inicioVigencia, 'DD/MM/YYYY')} À ${formatDate(taxa.fimVigencia, 'DD/MM/YYYY')}`}
      </div>
      <div className="taxas_tabelas">
        {taxa.taxas.map((t, i) => (
          renderTableTaxa(t, i)
        ))}
      </div>
    </div>
  );
};

Taxas.propTypes = {
  taxa: PropTypes.object.isRequired,
};

export default Taxas;
