import { connect } from 'react-redux';

import LimitesAprovadosForm from './limitesAprovadosForm';

const mapStateToProps = ({ limitesAprovados }) => ({
  limitesAprovadosList: limitesAprovados.limitesAprovadosList.limites,
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(LimitesAprovadosForm);
