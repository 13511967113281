import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../../../../../common-staff/controls/button';

import './paymentButton.scss';

const PaymentButton = ({
  invoice, onClick, closeModal, isDisabled,
}) => (
  <Button
    onClick={() => { onClick(invoice); closeModal(); }}
    disabled={isDisabled}
    className="modal-carteira__card-info__container__header__payment-btn"
  >
    Pagar duplicata
  </Button>
);

PaymentButton.propTypes = {
  invoice: PropTypes.object,
  onClick: PropTypes.func,
  closeModal: PropTypes.func,
  isDisabled: PropTypes.bool,
};

PaymentButton.defaultProps = {
  invoice: null,
  onClick: () => {},
  closeModal: () => {},
  isDisabled: false,
};

export default PaymentButton;
