import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PagamentosList from './PagamentosList';
import operations from '../../../../../../../redux/operations';

import { PATH_CARTEIRA } from '../../../../../../../../../routes/paths';

const mapStateToProps = ({ statementStore }) => ({
  listaPagamentos: statementStore.modal.detalhes?.pagamentos,
});

const mapDispatchToProps = (dispatch) => ({
  showDuplicata: (invoiceNumber) => { dispatch(operations.showDuplicata(invoiceNumber)); },
  setCarteiraFilters: (type) => dispatch(operations.setCarteiraFilters(type)),
  navTo: () => dispatch(push(PATH_CARTEIRA)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PagamentosList);
