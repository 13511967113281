import actions from './actions';

const INITIAL_STATE = {
  docs: [],
  isLoading: false,
  snackbar: [],
  lastSnackbarErrorId: 0,
  status: null,
};

export default (state = INITIAL_STATE, action = { type: '@@pagamentoPage/init' }) => {
  switch (action.type) {
    case actions.types.SET_DOCUMENTOS_PAGAMENTO:
      return {
        ...state,
        docs: (action.payload.invoices || []).map((i) => ({
          ...i,
          toPay: i?.saldoDevedor,
        })),
      };
    case actions.types.SET_STATUS:
      return {
        ...state,
        status: action.payload.status,
      };
    case actions.types.SET_VALOR_PAGAMENTO:
      return {
        ...state,
        docs: state.docs.map((m) => {
          if (m.duplicataId === action.payload.invoiceId) {
            return {
              ...m,
              toPay: action.payload.value,
            };
          }
          return m;
        }),
      };
    case actions.types.TOGGLE_EDIT:
      return {
        ...state,
        docs: state.docs.map((m) => {
          if (m.duplicataId === action.payload.invoiceId) {
            return {
              ...m,
              selected: !m.selected,
            };
          }
          return m;
        }),
      };
    case actions.types.PAYMENT_START:
      return {
        ...state,
        isLoading: true,
      };
    case actions.types.PAYMENT_ERROR:
      return {
        ...state,
        isLoading: false,
        snackbar: [...state.snackbar,
          {
            id: state.lastSnackbarErrorId,
            message: action.payload.error,
            type: 'error',
          },
        ],
        lastSnackbarErrorId: state.lastSnackbarErrorId + 1,
      };
    case actions.types.DISMISS_SNACKBAR:
      return {
        ...state,
        snackbar: state.snackbar.filter((i) => i.id !== action.payload.id),
      };
    case actions.types.RESET_STORE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
