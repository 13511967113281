import { connect } from 'react-redux';

import carteiraListHeader from './relatorioListHeader';
import operations from '../../../redux/operations';

const mapStateToProps = ({ relatoriosIof }) => ({
  selectedAll: relatoriosIof.selectedAll,
  isLoading: relatoriosIof.requestStatus.isLoading,
  isError: relatoriosIof.requestStatus.isError,
});

const mapDispatchToProps = (dispatch) => ({
  onCheck: () => {
    dispatch(operations.selectAll());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(carteiraListHeader);
