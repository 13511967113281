import { connect } from 'react-redux';

import { PaymentOperations } from '../../../redux';
import PaymentReceiptBody from './paymentReceiptBody';

const mapDispatchToProps = (dispatch) => ({
  getPaymentInformation: (id) => (
    dispatch(PaymentOperations.getPaymentInformation(id))
  ),
});

const mapStateToProps = ({ paymentStore }) => ({
  transactions: paymentStore.paymentReceipt.transactions,
  userInfo: paymentStore.paymentReceipt.userInfo,
  dealerInfo: paymentStore.paymentReceipt.dealerInfo,
  transactionInfo: paymentStore.paymentReceipt.transactionInfo,
  loading: paymentStore.paymentReceipt.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentReceiptBody);
