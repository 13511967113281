import React from 'react';
import PropTypes from 'prop-types';

import { StyledTextFieldHub } from './textFieldHub.style';

const TextFieldHub = (props) => {
  const { noBorder, ...other } = props;

  return (
    <StyledTextFieldHub
      {...other}
      noBorder={noBorder}
    />
  );
};

TextFieldHub.propTypes = {
  noBorder: PropTypes.bool,
};

TextFieldHub.defaultProps = {
  noBorder: false,
};

export default TextFieldHub;
