import { connect } from 'react-redux';

import { operations } from '../../redux/operations';
import { operations as errorFeedbackOperations } from '../errorFeedback/redux/operations';
import { operations as userFeedbackOperations } from '../userFeedback/redux/operations';

import DialogButton from './dialogButton';

const mapStateToProps = ({ feedback, authStore }) => ({
  isOpen: feedback.chatPopover.isOpen,
  sessionExpired: authStore.sessionExpired,
});

const mapDispatchToProps = (dispatch) => ({
  setIsOpen: (isOpen) => dispatch(operations.setIsOpen(isOpen)),
  setContent: (content) => dispatch(operations.setContent(content)),
  fulfillOpenContentRequest: () => dispatch(operations.fulfillOpenContentRequest()),
  resetErrorFeedback: () => dispatch(errorFeedbackOperations.resetStore()),
  resetTestDriveFeedback: () => dispatch(userFeedbackOperations.resetStore()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DialogButton);
