import { connect } from 'react-redux';

import { Tracking } from 'modules';
import CarteiraPage from './carteiraPage';
import operationsModal from '../carteiraModal/redux/operations';
import operations from './redux/operations';
import operationsModalTaxas from '../modalTaxasFloorPlan/redux/operations';
import selectorModal from '../carteiraModal/redux/selector';

const mapStateToProps = ({ authStore, gestaoPagamentos, dealerStore }) => ({
  permissions: authStore.permissions,
  isModalInfoOpen: gestaoPagamentos.carteira.modal.modalInfo.isOpen,
  isModalOpen: selectorModal.isModalPaymentOpen(gestaoPagamentos),
  selectedDealer: dealerStore.selectedFPlanDealerId,
  snackbarErrors: gestaoPagamentos.carteira.page.snackbars,
  isRedirect: gestaoPagamentos.carteira.page.isRedirect,
  isUpdated: gestaoPagamentos.carteira.page.isUpdated,
  isModalTaxasOpen: gestaoPagamentos?.carteira?.modalTaxas?.isOpen,
  isModalHistoricoOpen: gestaoPagamentos?.carteira?.modalHistoricoDuplicata?.isOpen,
  exportPopperOpen: gestaoPagamentos.carteira.page.exportPopperOpen,
});

const mapDispatchToProps = (dispatch) => ({
  closeModalInfo: () => dispatch(operationsModal.closeModalInfo()),
  closeModal: () => {
    dispatch(operationsModal.resetStore());
    dispatch(operations.setSelectedAllFalse());
    dispatch(operations.removeAll());
  },
  getCarteira: () => dispatch(operations.getCarteira()),
  resetStore: () => {
    dispatch(operations.resetStore());
    dispatch(operationsModal.resetStore());
  },
  onCloseSnackBar: (id) => dispatch(operations.dismissSnackbar(id)),
  setConcessionaria: () => dispatch(operations.setFilter('concessionaria', null)),
  setModalTaxaOpen: () => {
    Tracking.Mixpanel.carteira.carteiraFilters.trackTaxas();
    dispatch(operationsModalTaxas.setModalOpen());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CarteiraPage);
