const GET_STATUS_INIT = 'paymentBtn/GET_STATUS_INIT';
const GET_STATUS_SUCCESS = 'paymentBtn/GET_STATUS_SUCCESS';
const GET_STATUS_ERROR = 'paymentBtn/GET_STATUS_ERROR';

const getStatusInit = () => ({
  type: GET_STATUS_INIT,
  payload: null,
});

const getStatusSuccess = (status, timeOfRequest) => ({
  type: GET_STATUS_SUCCESS,
  payload: { status, timeOfRequest },
});

const getStatusError = (timeOfRequest) => ({
  type: GET_STATUS_ERROR,
  payload: { timeOfRequest },
});

export const actions = {
  types: {
    GET_STATUS_INIT, GET_STATUS_SUCCESS, GET_STATUS_ERROR,
  },

  getStatusInit,
  getStatusSuccess,
  getStatusError,
};
