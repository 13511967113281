import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import List, { ListContent } from '../../../../../../common-staff/layout/list';
import CarteiraListHeader from './carteiraListHeader';
import CarteiraListRow from './carteiraListRow';

import './carteiraList.scss';

const CarteiraList = ({ invoices, isLoading, isError }) => (
  <div className="carteira-page__list__container">
    <CarteiraListHeader />
    <List
      isLoading={isLoading}
      isError={isError}
      isEmpty={_.isEmpty(invoices)}
    >
      <ListContent>
        {(Array.isArray(invoices) ? invoices : []).map((d, i) => (
          <CarteiraListRow
            invoice={d}
            key={i}
            infoModalOpen
            isModalOpen
          />
        ))}
      </ListContent>

      <ListContent type="empty">
        Nenhuma duplicata foi encontrada.
      </ListContent>

      <ListContent type="error">
        Ocorreu um erro ao carregar as duplicatas.
      </ListContent>
    </List>
  </div>
);

CarteiraList.propTypes = {
  invoices: PropTypes.array,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
};

CarteiraList.defaultProps = {
  invoices: null,
  isLoading: false,
  isError: false,
};

export default CarteiraList;
