import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import BrandsFilters from './brands';
import InputDocumento from './inputDocumento';
import Dates from './date';
import FilterButton from './filterButton';
import './filters.scss';

const useStyles = makeStyles({
  container: {
    display: 'grid',
    justifyContent: 'center',
    gridTemplateAreas: (showBrandsSelector) => {
      if (showBrandsSelector > 1) return "' documento data brand button'";
      return "' documento data button'";
    },
    gridTemplateColumns: (showBrandsSelector) => {
      if (showBrandsSelector > 1) return '1fr auto minmax(0px, 320px) auto';
      return '1fr auto auto';
    },
  },
});

const Filters = ({ showBrandsSelector, brandList, setBrand }) => {
  useEffect(() => {
    setBrand(brandList);
  }, [setBrand, brandList]);

  const classes = useStyles(showBrandsSelector);
  return (
    <div className={`comunicados__filtros__container ${classes.container}`}>
      <div
        className="comunicados__filtros__container__documento"
        data-cy="InputText"
      >
        <InputDocumento />
      </div>
      <div
        className="comunicados__filtros__container__date"
      >
        <Dates />
      </div>
      { showBrandsSelector > 1
        ? (
          <div
            className="comunicados__filtros__container__brand"
            data-cy="BrandsFilters"
          >
            <BrandsFilters />
          </div>
        ) : null}
      <div
        className="comunicados__filtros__container__button"
        data-cy="BotaoFiltrar"
      >
        <FilterButton />
      </div>
    </div>
  );
};

Filters.propTypes = {
  showBrandsSelector: PropTypes.number,
  brandList: PropTypes.arrayOf(PropTypes.object),
  setBrand: PropTypes.func,
};

Filters.defaultProps = {
  showBrandsSelector: 0,
  brandList: [],
  setBrand: () => {},
};

export default Filters;
