import React, { useEffect, useState } from 'react';
import { loadDealers, loadSelectedFPlanDealerId } from 'modules/dealers/dealers';
import { useHistoricoResgateContext } from 'features/carteiraFidc/contexts/historicoResgateContext';
import { formatCodigoConcessionaria, formatNomeConcessionaria } from 'utils/format';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@mui/material';
import Button from 'features/common-staff/controls/button';
import NewBasicSelect from 'features/common/newBasicSelect/newBasicSelect';
import NewMultipleSelectComponent from 'features/common-staff/controls/newMultipleSelectComponent/newMultipleSelectComponent';
import ButtonFilter from 'features/common-staff/controls/buttonFilter';
import { getCnpjRaiz } from 'utils/cnpjRaiz';
import { arrayStatus } from '../helper';

const useStyles = makeStyles({
  containerFirstRow: {
    padding: '0 24px 0 32px',
    display: 'flex',
    gap: '16px',
  },
  containerSecondRow: {
    padding: '0 24px 24px 32px',
    display: 'flex',
    gap: '16px',
  },
  select: {
    width: '244px',
  },
  filterButton: {
    width: '86px',
    height: '40px',
    marginTop: '20px',
  },
  clearButton: {
    height: '40px',
    marginTop: '20px',
  },
  exportButton: {
    width: '162px',
    height: '40px',
    marginTop: '20px',
  },
});

const HistoricoResgatesFilter = () => {
  const classes = useStyles();
  const {
    state, loading, setFilters, fetchHistorico, historicoDataLoading,
    fetchExportHistorico, exportHistoricoLoading,
  } = useHistoricoResgateContext();
  const {
    filters, periodoList,
  } = state;
  const {
    status, concessionaria,
  } = filters;

  const [localStatus, setLocalStatus] = useState(status);
  const [localConcessionaria, setLocalConcessionaria] = useState(concessionaria);
  const [localPeriodo, setLocalPeriodo] = useState([]);
  const [isTouched, setIsTouched] = useState(false);

  const concessionariaList = loadDealers();
  const selectedDealerId = loadSelectedFPlanDealerId();

  useEffect(() => {
    setLocalConcessionaria('all');
    setLocalStatus('all');
    setLocalPeriodo([]);
  }, [selectedDealerId]);

  const selectedDealer = concessionariaList.find((c) => c.dealerId === selectedDealerId);
  const raizCnpj = getCnpjRaiz(selectedDealer.cnpj);
  const filtedConcessionarias = concessionariaList
    .filter((c) => getCnpjRaiz(c.cnpj) === raizCnpj);
  const options = filtedConcessionarias
    .map((c) => ({
      value: c.cnpj,
      label: `${formatNomeConcessionaria(c.dealerName.trim())} - ${formatCodigoConcessionaria(c.dealerId)}`,
    }));

  const dictionary = () => ({
    singular: 'período',
    plural: 'períodos',
    type: 'o',
  });

  return (
    <Box>
      <Box className={classes.containerFirstRow}>
        <Box className={classes.select}>
          <NewMultipleSelectComponent
            label="Período"
            dictionary={dictionary()}
            startWithAllSelected
            showSearchInput={false}
            options={periodoList}
            selectedOption={localPeriodo}
            setOption={(item) => {
              setLocalPeriodo(item);
              setIsTouched(true);
            }}
            dataCy="periodo"
            minWidth={200}
            height={162}
          />
        </Box>
        <Box className={classes.select}>
          <NewBasicSelect
            selectFirstOptionDefault={false}
            dataCy="FilterHistoricoConcessionaria"
            nameLabel="Concessionária"
            labelAll="Todas as concessionárias"
            options={options}
            selectedOption={localConcessionaria}
            setOption={(item) => {
              setLocalConcessionaria(item);
              setIsTouched(true);
            }}
            isLoading={loading}
          />
        </Box>
        <Box className={classes.select}>
          <NewBasicSelect
            selectFirstOptionDefault={false}
            dataCy="historicoStatus"
            nameLabel="Status"
            labelAll="Todos os status"
            options={arrayStatus}
            selectedOption={localStatus}
            setOption={(item) => {
              setLocalStatus(item);
              setIsTouched(true);
            }}
            isLoading={loading}
          />
        </Box>
        <Box className={classes.filterButton}>
          <ButtonFilter
            dataCy="FilterApplyButton"
            textApply="Filtrar"
            textSelect="Filtrar"
            onClick={() => {
              setFilters([
                'periodo', 'concessionaria', 'status',
              ], [
                localPeriodo, localConcessionaria, localStatus,
              ]);
              fetchHistorico(
                {
                  periodo: localPeriodo, concessionaria: localConcessionaria, status: localStatus,
                },
                {
                  page: 0, ipp: 25,
                },
              );
              setIsTouched(false);
            }}
            isLoading={historicoDataLoading}
            isFilterSelected={isTouched}
          />
        </Box>
      </Box>
      <Box>
        <Box className={classes.containerSecondRow}>
          <Button
            className={classes.exportButton}
            onClick={fetchExportHistorico}
            isLoading={exportHistoricoLoading}
          >
            Exportar histórico
          </Button>
        </Box>

      </Box>
    </Box>
  );
};

export default HistoricoResgatesFilter;
