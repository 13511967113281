import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Scrollbars } from 'react-custom-scrollbars';
import SnackbarList from 'features/common-staff/snackbarList';
import {
  Page, PageHeader, PageTitle, PageSubTitle, PageContent,
} from 'features/common-staff/layout/page';

import PageErrorBoundary from '../../../common/errorBoundary/pageErrorBoundary';

import BreadCrumbAlteracao from './views/breadCrumbAlteracao';
import AlteracaoLimiteForm from './views/alteracaoLimiteForm';

const AlteracaoLimitePage = ({
  snackbarErrors, onSnackbarClose, getProdutos, resetStore,
}) => {
  useEffect(() => {
    getProdutos();
    return () => { resetStore(); };
  }, [getProdutos, resetStore]);
  return (
    <PageErrorBoundary>
      <Scrollbars
        autoHeight
        autoHeightMax="100vh"
        autoHeightMin="100vh"
      >
        <Page>
          <PageHeader>
            <PageSubTitle>
              <BreadCrumbAlteracao />
            </PageSubTitle>
            <PageTitle>Solicitação de aumento ou redução de limite</PageTitle>
          </PageHeader>
          <PageContent>
            <AlteracaoLimiteForm />
          </PageContent>
        </Page>
      </Scrollbars>
      <SnackbarList
        snackbarErrors={snackbarErrors}
        onClose={(id) => onSnackbarClose(id)}
      />
    </PageErrorBoundary>
  );
};
AlteracaoLimitePage.propTypes = {
  snackbarErrors: PropTypes.array,
  onSnackbarClose: PropTypes.func,
  getProdutos: PropTypes.func,
  resetStore: PropTypes.func,
};

AlteracaoLimitePage.defaultProps = {
  snackbarErrors: [],
  onSnackbarClose: () => {},
  getProdutos: () => {},
  resetStore: () => {},
};

export default AlteracaoLimitePage;
