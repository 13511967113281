import axios from 'axios';
import { getBody } from './helper';

class DuplicataService {
  constructor() {
    this.URL_API = window.env.REACT_APP_API_URL;
  }

  async getDuplicatas(filters, pageParams) {
    const body = getBody(filters, pageParams);

    const response = await axios.post(`${this.URL_API}/fidc/duplicatas`, body);

    if (!response || response.status !== 200) {
      throw new Error(`Error get duplicatas ${body}`);
    }

    return response.data;
  }

  async exportDuplicatas(state, isCsv) {
    const { filters } = state;
    const body = getBody(filters);

    const response = await axios.post(
      `${this.URL_API}/fidc/duplicatas/export?isCsv=${isCsv}`,
      body,
      { responseType: 'arraybuffer' },
    );

    const fileName = response.headers['content-disposition'].match(/filename=(?<filename>[^,;]+);/)[1];
    return { fileContent: response.data, fileName };
  }
}

export default new DuplicataService();
