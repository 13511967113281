import React from 'react';
import PropTypes from 'prop-types';

import HelpIcon from '@material-ui/icons/Help';
import { Tooltip } from '@material-ui/core';

import colors from 'assets/styles/colors';
import CardIcons from '../cardIcons/cardIcons';

import './cardResumo.scss';

const CardResumo = ({
  valor, valorTitulo, tipo, tooltipMessage, messenge,
}) => (
  <div className="dashboard__card-resumo">
    <div className="dashboard__card-resumo__header">
      <div className="dashboard__card-resumo__header_value">
        {valor}
      </div>
      <div className="dashboard__card-resumo__header_icon">
        <CardIcons etapa={tipo} />
      </div>
    </div>
    <div className="dashboard__card-resumo__content">
      {valorTitulo}
      <Tooltip
        title={tooltipMessage}
      >
        <HelpIcon style={{ fill: colors.secundary_color_700, marginLeft: '4px', fontSize: 'medium' }} />
      </Tooltip>

    </div>
    <div className="dashboard__card-resumo__content__messenge">
      {messenge}
    </div>
  </div>
);

CardResumo.propTypes = {
  valor: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  valorTitulo: PropTypes.string,
  tipo: PropTypes.string,
  tooltipMessage: PropTypes.string,
  messenge: PropTypes.string,
};

CardResumo.defaultProps = {
  valor: null,
  valorTitulo: null,
  tipo: null,
  tooltipMessage: '',
  messenge: '',
};

export default CardResumo;
