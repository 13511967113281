import { connect } from 'react-redux';

import UploadDocumentoJuridico from './uploadDocumentoJuridico';

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocumentoJuridico);
