import { connect } from 'react-redux';

import DadosOrigemModal from './dadosOrigemModal';

const mapStateToProps = ({ limiteCreditoStore }) => ({
  codigoSap: limiteCreditoStore.transferenciaLimite.dadosOrigem.codigoSap,
  selectedDealerId: limiteCreditoStore.transferenciaLimite.dadosOrigem.selectedDealerId,
  selectedProduct: limiteCreditoStore.transferenciaLimite.dadosOrigem.produto,
});

export default connect(mapStateToProps)(DadosOrigemModal);
