import { createSelector } from 'reselect';

export const getDealerGroups = createSelector(
  (state) => state?.dealers,
  (dealers) => (dealers || []).map((d) => ({
    dealerId: d.dealerId,
    brand: d.brand,
    cnpjRoot: (d.cnpj || '').substring(0, 8),
  })),
);
