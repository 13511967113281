/* eslint-disable no-use-before-define */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import colors from 'assets/styles/colors';

import Checkbox from 'features/common/checkbox/checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '../input/textField';

import { multipleSelect } from './multipleSelectStyles';

const useStyles = makeStyles(multipleSelect);

const MultipleSelect = ({
  items, value, onSelect, placeholder, label,
  allItemsText, isActive, showAllItems, showSearchIcon, className,
  allTagsText, disabled,
}) => {
  const classes = useStyles({ isActive });
  const allItemsObject = { value: null, text: allItemsText };

  const renderTags = (tags) => {
    if (tags === 1) return value[0]?.text;
    if (tags === items.length) return allItemsObject.text;
    return `${tags} ${allTagsText}`;
  };

  return (
    <Autocomplete
      data-cy="Select"
      className={className}
      value={!_.isEmpty(value) ? items?.filter((i) => value
        .some((v) => v.value?.toString().toLowerCase() === i.value?.toString().toLowerCase())) : []}
      multiple
      classes={{
        root: classes.root,
        listbox: classes.listBox,
        hasClearIcon: classes.clearIcon,
        hasPopupIcon: classes.popupIcon,
        inputRoot: classes.input,
        tag: classes.tag,
      }}
      limitTags={0}
      disableClearable
      disabled={disabled}
      options={showAllItems ? [allItemsObject, ...items] : items}
      disableCloseOnSelect
      popupIcon={<KeyboardArrowDownIcon />}
      getLimitTagsText={renderTags}
      onChange={(e, selectedItems) => {
        const isAllSelected = selectedItems?.filter((i) => i.value === null).length > 0;
        if (isAllSelected && value.length === items.length) onSelect([]);
        else if (isAllSelected) onSelect(items?.filter((i) => i.value !== null));
        else onSelect(selectedItems);
      }}
      getOptionLabel={(option) => option.text}
      renderOption={(props, option, state) => {
        if (value.length === items.length) {
          // eslint-disable-next-line no-param-reassign
          state.selected = true;
        }
        return (
          <li {...props}>
            <div className={classes.container}>
              <div className={classes.content}>
                <Checkbox
                  checked={state.selected}
                  color={colors.secundary_color_500}
                  checkedColor={colors.primary_color_600}
                  padding="5px 0px 5px 0px"
                  size="medium"
                  width={32}
                  height={32}
                />
                <div className={classes.text} style={{ marginLeft: 12 }}>
                  {option.text}
                </div>
              </div>
              {option.value === null ? <div className={classes.line} /> : null}
            </div>
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={value.length === 0 ? placeholder : ''}
          InputProps={{
            ...params.InputProps,
            startAdornment:
  <>
    {showSearchIcon ? <SearchIcon style={{ fill: '#8f9bb3', color: '#8f9bb3' }} /> : null}
    {params.InputProps.startAdornment}
  </>,
          }}
        />
      )}
    />
  );
};

MultipleSelect.propTypes = {
  className: PropTypes.string,
  onSelect: PropTypes.func,
  value: PropTypes.any,
  items: PropTypes.array,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  allItemsText: PropTypes.string,
  isActive: PropTypes.bool,
  showAllItems: PropTypes.bool,
  showSearchIcon: PropTypes.bool,
  allTagsText: PropTypes.string,
  disabled: PropTypes.bool,
};

MultipleSelect.defaultProps = {
  className: '',
  onSelect: null,
  value: '',
  items: [],
  placeholder: 'Selecione um filtro',
  label: '',
  allItemsText: 'Todos as filtros',
  isActive: false,
  showAllItems: false,
  showSearchIcon: false,
  allTagsText: 'Filtros selecionados',
  disabled: false,
};

export default MultipleSelect;
