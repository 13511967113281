import { connect } from 'react-redux';
import { Tracking } from 'modules';

import operations from '../../../redux/operations';

import DatesSelect from './dateSelector';

const mapStateToProps = ({ comunicados }) => ({
  startDate: comunicados?.page?.filtros?.startDate,
  endDate: comunicados?.page?.filtros?.endDate,
  isLoading: comunicados?.page?.requestStatus?.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setStartDate: (value) => {
    dispatch(operations.setFilter('startDate', value));
    Tracking.Mixpanel.centralComunicados.filtros.trackFiltroData();
  },
  setEndDate: (value) => { dispatch(operations.setFilter('endDate', value)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(DatesSelect);
