import React from 'react';

import './legendaValorDuplicatas.scss';

const LegendaValorDuplicatas = () => (
  <div className="dashboard-floor-plan__legenda-valorDuplicata">
    <div className="dashboard-floor-plan__legenda-valorDuplicata__item">
      <span className="azul" />
      <span>Floor Plan</span>
    </div>
    <div className="dashboard-floor-plan__legenda-valorDuplicata__item">
      <span className="amarelo" />
      <span>Fundão</span>
    </div>
  </div>
);

export default LegendaValorDuplicatas;
