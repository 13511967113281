import React from 'react';
import PropTypes from 'prop-types';

import EmojiAngry from '../../../../../assets/emoji/emoji-angry';
import EmojiFunny from '../../../../../assets/emoji/emoji-funny';
import EmojiHeart from '../../../../../assets/emoji/emoji-heart';
import EmojiSad from '../../../../../assets/emoji/emoji-sad';
import EmojiSmile from '../../../../../assets/emoji/emoji-smile';

import './emojiButtons.scss';

const getEmoji = (value) => {
  switch ((value || '').toString().toLowerCase()) {
    case 'angry':
      return <EmojiAngry />;
    case 'funny':
      return <EmojiFunny />;
    case 'heart':
      return <EmojiHeart />;
    case 'sad':
      return <EmojiSad />;
    case 'smile':
      return <EmojiSmile />;
    default:
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        throw new Error(`[EmojiButton] Invalid value: ${value}`);
      } else {
        return (<div>{value}</div>);
      }
  }
};

const EmojiButtonActive = ({
  selected, value, onClick,
}) => (
  selected ? (
    <div className="chat__emoji-btn-active__emoji">
      {getEmoji(value)}
    </div>
  ) : (
    <div
      className="chat__emoji-btn-active__emoji"
      onClick={onClick}
      role="button"
      tabIndex="0"
    >
      {getEmoji(value)}
    </div>
  )
);

const EmojiButtonAnswered = ({ selected, value }) => (
  <div className={`chat__emoji-btn-answered__emoji${selected ? '--selected' : '--not-selected'}`}>
    {getEmoji(value)}
  </div>
);

EmojiButtonActive.propTypes = {
  selected: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

EmojiButtonAnswered.propTypes = {
  selected: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

export { EmojiButtonActive, EmojiButtonAnswered };
