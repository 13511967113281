import React from 'react';
import InputValor from './inputValor';

import './dadosSolicitacaoModal.scss';

const DadosSolicitacaoModal = () => (
  <div className="transferencia-limite__dados-solicitacao__container">
    <div
      className="transferencia-limite__dados-solicitacao__container_title"
      data-cy="TransfLimiteDadosSolicitacaoTitleModal"
    >
      Dados da Solicitação
    </div>
    <div
      className="transferencia-limite__dados-solicitacao__container_valor"
      data-cy="TransfLimiteDadosSolicitacaoContainerValor"
    >
      <InputValor />
    </div>
  </div>

);

export default DadosSolicitacaoModal;
