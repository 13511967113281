import BreadCrumb from 'features/common-staff/layout/breadCrumb';
import React from 'react';

import { PATH_SOLICITACAO_CREDITO } from 'routes/paths';

const BreadCrumbCadastro = () => (
  <BreadCrumb
    labels={[{
      label: 'Gestão de Limites',
      path: ' ',
    },
    {
      label: 'Limites aprovados',
      path: `${PATH_SOLICITACAO_CREDITO}/aprovacoes/limites-aprovados`,
    },
    {
      label: 'Lista de documentos',
      path: true,
    }]}
  />
);

export default BreadCrumbCadastro;
