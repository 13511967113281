import React from 'react';
import PropTypes from 'prop-types';
import { Tracking } from 'modules';

import Button from '../../../../../common-staff/controls/button';

const PaymentButton = ({ onClick, isLoading, isDisabled }) => (
  <Button
    onClick={() => {
      Tracking.Mixpanel.carteira.pagamento.trackTypeConfirmPaymentButton();
      onClick();
    }}
    isLoading={isLoading}
    disabled={isDisabled}
    fullWidth
  >
    Confirmar Pagamento
  </Button>
);

PaymentButton.propTypes = {
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

PaymentButton.defaultProps = {
  onClick: () => {},
  isLoading: false,
  isDisabled: false,
};

export default PaymentButton;
