import logger from 'utils/logger';
import actions from './actions';
import service from './service';

const getMensagensRede = () => async (dispatch, getState) => {
  try {
    const { isModalOpen } = getState().mensagensRedeStore;
    const { dealers } = getState().dealerStore;
    if (!isModalOpen) {
      dispatch(actions.getMensagensRedeStart());
      const mensagensRede = await service.GetMensagensRede(dealers);
      dispatch(actions.getMensagensRedeSuccess(mensagensRede));
    }
  } catch (e) {
    logger.error(e);
    dispatch(actions.getMensagensRedeError());
  }
};

const markAsRead = () => async (dispatch, getState) => {
  try {
    const { mensagens } = getState().mensagensRedeStore;
    const mensagensId = mensagens.map((m) => m.id);
    await service.MarkAsRead(mensagensId);
  } catch (e) {
    logger.error(e);
  }
};

const closeModal = () => async (dispatch) => {
  await dispatch(markAsRead());
  dispatch(actions.setModalOpen(false));
  dispatch(actions.resetStore());
};

export default {
  getMensagensRede,
  closeModal,
};
