import React from "react";

const EyeIcon = props => (
	<svg width="1em" height="1em" viewBox="0 0 16 10" {...props}>
		<path
			d="M8 0C4.667 0 1.82 2.073.667 5c1.153 2.927 4 5 7.333 5s6.18-2.073 7.333-5c-1.153-2.927-4-5-7.333-5zm0 8.333a3.335 3.335 0 010-6.666 3.335 3.335 0 010 6.666zM8 3c-1.107 0-2 .893-2 2s.893 2 2 2 2-.893 2-2-.893-2-2-2z"
			fill="#000"
			fillRule="evenodd"
		/>
	</svg>
);

export default EyeIcon;

