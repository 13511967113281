import React from 'react';
import PropTypes from 'prop-types';

import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core';

import { ButtonIcon } from 'features/common';
import PreviewIcon from 'assets/icons/preview';
import CloseIcon from 'assets/icons/close';
import ReloadIcon from 'assets/icons/reload';

import './listFiles.scss';
import ButtonTooltipIcon from 'features/common/buttonTooltipIcon';
import colors from 'assets/styles/colors';

const useStyles = makeStyles({
  root: {
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: '#C4C4C4',
    },
    height: '2px',
    width: '40%',
  },
  color: {
    color: ({ color }) => color,
  },
});

const colorSelector = (isError, isSuccess) => {
  if (isError) {
    return colors.error_color_500;
  }
  if (isSuccess) {
    return colors.primary_color_600;
  }
  return 'rgba(0, 0, 0, 0.6)';
};

const ListFiles = ({ file, deleteFile, reUploadFile }) => {
  const isSuccess = file.progress === 100;
  const color = colorSelector(file.isError, isSuccess);
  const classes = useStyles({ color });
  const showUploadingBar = (!isSuccess && !file.isError);
  return (
    <div className="test-drive__modal__container__list_files">
      <div className={`test-drive__modal__container__list_files_name ${classes.color}`}>
        {file.name}
        {showUploadingBar
          ? <LinearProgress variant="determinate" value={file.progress} className={classes.root} />
          : null}
      </div>
      <div className="test-drive__modal__container__list_files_preview">
        <ButtonTooltipIcon title="Baixar arquivo">
          <ButtonIcon
            disabled={file.isError || file.progress !== 100}
            onClick={() => { window.open(file.urlPreview); }}
          >
            <PreviewIcon />
          </ButtonIcon>
        </ButtonTooltipIcon>
      </div>
      <div className="test-drive__modal__container__list_files_delete">
        <ButtonTooltipIcon title="Excluir arquivo">
          <ButtonIcon
            disabled={file.progress !== 100 && !file.isError}
            onClick={() => deleteFile(file.name, file.key)}
          >
            <CloseIcon />
          </ButtonIcon>
        </ButtonTooltipIcon>
      </div>
      <div className="test-drive__modal__container__list_files_reload">
        { file.isError ? (
          <ButtonIcon
            disabled={(file.progress !== 100 && !file.isError) || file.progress === 100}
            onClick={() => { reUploadFile(file); }}
          >
            <ReloadIcon />
          </ButtonIcon>
        ) : null}
      </div>
    </div>
  );
};

ListFiles.propTypes = {
  file: PropTypes.object,
  deleteFile: PropTypes.func,
  reUploadFile: PropTypes.func,
};

ListFiles.defaultProps = {
  file: null,
  deleteFile: () => {},
  reUploadFile: () => {},
};

export default ListFiles;
