import * as React from "react";

function PaperPlaneIcon(props) {
	return (
		<svg width="36" height="36" viewBox="0 0 36 36" {...props}>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M2.50452 12.0842L2.32958 12.1571C1.2183 12.7003 1.20851 14.3452 2.39084 14.8707L15.363 20.6355L21.1293 33.6092C21.6811 34.8506 23.467 34.7778 23.9158 33.4955L34.4048 3.52692C34.4212 3.48332 34.4354 3.43911 34.4475 3.39445C34.5999 2.84497 34.4257 2.31468 34.0762 1.95509C34.0711 1.94981 34.0659 1.94456 34.0607 1.93933C34.0554 1.93406 34.0501 1.92883 34.0448 1.92365C33.6851 1.57426 33.1547 1.40007 32.6052 1.55263C32.5607 1.56472 32.5167 1.5789 32.4732 1.59516L2.50452 12.0842ZM27.2938 6.58494L7.06505 13.665L16.1679 17.7109L27.2938 6.58494ZM18.2891 19.8322L22.3335 28.9335L29.412 8.70937L18.2891 19.8322Z" fill="#243782"/>
		</svg>
	);
}

export default PaperPlaneIcon;

