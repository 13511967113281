import React from 'react';
import PropTypes from 'prop-types';

import InfoModal from './views/infoModal';
import PaymentModal from './views/paymentModal';

import './carteiraModal.scss';

const CarteiraModal = ({
  isOpenModalInfo,
}) => (
  <div className="carteira__modal__sidebar">
    {isOpenModalInfo ? <InfoModal /> : <PaymentModal /> }
  </div>
);

CarteiraModal.propTypes = {
  isOpenModalInfo: PropTypes.bool,
};

CarteiraModal.defaultProps = {
  isOpenModalInfo: false,
};

export default CarteiraModal;
