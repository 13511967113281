import styled from 'styled-components';
import colors from 'assets/styles/colors';

export const Container = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    background-color: ${colors.primary_color_100_24};
    border-radius: 18px;
    cursor: pointer;
  }
`;

export const IconStyle = (isActive, isHover) => {
  const iconColor = isHover ? '#7A7C9A' : '#A6A8BB';
  return ({
    fontSize: '14px',
    color: isActive ? `${colors.primary_color_600}` : iconColor,
  });
};
