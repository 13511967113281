import React from 'react';
import PropTypes from 'prop-types';
import PaymentReceiptInfoBox from '../paymentReceiptInfoBox';
import {
  Date, Currency,
} from '../../../../common';

import './paymentReceiptTransactionInfo.scss';

const PaymentReceiptTransactionInfo = ({
  className, transactionInfo,
}) => (
  <div className={className}>
    <div className="payment-receipt-body-info-block">
      <div className="payment-receipt-body-info-tittle">DETALHES DA SOLICITAÇÃO</div>
      <PaymentReceiptInfoBox
        subtittle="VALOR TOTAL PAGO"
      >
        <Currency
          value={transactionInfo ? transactionInfo.valorTotal : null}
          noColor
          className="payment-receipt-body-info-values"
        />
      </PaymentReceiptInfoBox>

      <PaymentReceiptInfoBox
        subtittle="DATA"
      >
        <Date
          className="payment-receipt-body-info-values"
          date={transactionInfo ? transactionInfo.data : null}
        />
      </PaymentReceiptInfoBox>
    </div>
  </div>
);

PaymentReceiptTransactionInfo.propTypes = {
  className: PropTypes.string,
  transactionInfo: PropTypes.shape({
    data: PropTypes.string,
    valorTotal: PropTypes.number,
  }).isRequired,
};

PaymentReceiptTransactionInfo.defaultProps = {
  className: '',
};

export default PaymentReceiptTransactionInfo;
