import React from 'react';
import PropTypes from 'prop-types';

import FormInput from 'features/common-staff/controls/input/formInput/';

const InputRegiaoCodigo = ({
  regiaoCodigo,
}) => (
  <FormInput
    type="number"
    label="Cód. Regional"
    value={regiaoCodigo.toString()}
  />
);

InputRegiaoCodigo.propTypes = {
  regiaoCodigo: PropTypes.number,
};

InputRegiaoCodigo.defaultProps = {
  regiaoCodigo: null,

};

export default InputRegiaoCodigo;
