import { connect } from 'react-redux';
import ModalDetalhe from './modalDetalhe';

const mapStateToProps = ({ statementStore }) => ({
  tipo: statementStore.modal.tipo,
  isLoading: statementStore.modal.isLoading,
  isError: statementStore.modal.isError,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ModalDetalhe);
