import { applyProperty } from 'utils/object';
import actions from './actions';

const INITIAL_STATE = {
  list: {
    isLoading: false,
    isError: false,
    solicitacoes: null,
  },

  pageParams: {
    page: null,
    ipp: 25,
    totalItems: null,
  },

  detalhes: {
    isOpen: false,
    isLoading: false,
    isError: false,
    solicitacao: null,
  },
};

const reduceGetSolicitacoes = (state, action) => {
  switch (action.type) {
    case actions.types.GET_SOLICITACOES_START:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: true,
          isError: false,
        },
      };
    case actions.types.GET_SOLICITACOES_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          isError: false,
          solicitacoes: action.payload.solicitacoes,
        },
        pageParams: action.payload.pageParams,
      };
    case actions.types.GET_SOLICITACOES_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          isError: true,
          solicitacoes: [],
        },
      };
    default:
      return state;
  }
};

const reduceGetDetalhes = (state, action) => {
  switch (action.type) {
    case actions.types.GET_DETALHES_START:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          isLoading: true,
          isError: false,
        },
      };
    case actions.types.GET_DETALHES_SUCCESS:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          isLoading: false,
          isError: false,
          solicitacao: action.payload.solicitacao,
        },
      };
    case actions.types.GET_DETALHES_ERROR:
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          isLoading: false,
          isError: true,
          solicitacao: null,
        },
      };
    default:
      return state;
  }
};

const reduceDetalhes = (state, action) => {
  switch (action.type) {
    case actions.types.SET_OPEN: {
      return {
        ...state,
        detalhes: {
          ...state.detalhes,
          isOpen: action.payload.value,
        },
      };
    }
    default:
      return state;
  }
};

export default (state = INITIAL_STATE, action = { type: '@@acompanhamentoLimitePage/INIT' }) => {
  if (action.type.startsWith(actions.types.PREFIX_GET_SOLICITACOES)) {
    return reduceGetSolicitacoes(state, action);
  }
  if (action.type.startsWith(actions.types.PREFIX_GET_DETALHES)) {
    return reduceGetDetalhes(state, action);
  }
  if (action.type.startsWith(actions.types.PREFIX_DETALHES)) {
    return reduceDetalhes(state, action);
  }

  switch (action.type) {
    case actions.types.SET_PAGE_PARAMS: {
      return {
        ...state,
        paginationControl: {
          ...applyProperty(
            state.pageParams,
            action.payload.paramName,
            action.payload.value,
          ),
        },
      };
    }
    case actions.types.RESET_STORE: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};
