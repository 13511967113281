import React from 'react';
import PropTypes from 'prop-types';

import SnackbarList from 'features/common/snackbarList';
import SnackbarMessage from 'features/common/snackbarList/snackbarMessage';

const Snackbars = ({
  showConditionsLoadError, dismissShowConditionsLoadError,
  showSubmitError, dismissSubmitError,
  showSubmitSuccess, dismissSubmitSuccess,
}) => {
  const messages = [];

  if (showConditionsLoadError) {
    messages.push(new SnackbarMessage(
      SnackbarMessage.TYPE_ERROR,
      'Não foi possível obter uma condição comercial. Tente novamente.',
      dismissShowConditionsLoadError,
    ));
  }

  if (showSubmitError) {
    messages.push(new SnackbarMessage(
      SnackbarMessage.TYPE_ERROR,
      'Erro ao enviar o Formulário de Test Drive. Tente enviá-lo novamente.',
      dismissSubmitError,
    ));
  }

  if (showSubmitSuccess) {
    messages.push(new SnackbarMessage(
      SnackbarMessage.TYPE_SUCCESS,
      'Solicitação enviada com sucesso. Por favor, aguarde retorno.',
      dismissSubmitSuccess,
    ));
  }

  return (
    <SnackbarList messages={messages} />
  );
};

Snackbars.propTypes = {
  showConditionsLoadError: PropTypes.bool,
  showSubmitError: PropTypes.bool,
  showSubmitSuccess: PropTypes.bool,
  dismissShowConditionsLoadError: PropTypes.func.isRequired,
  dismissSubmitError: PropTypes.func.isRequired,
  dismissSubmitSuccess: PropTypes.func.isRequired,
};

Snackbars.defaultProps = {
  showConditionsLoadError: true,
  showSubmitError: true,
  showSubmitSuccess: true,
};

export default Snackbars;
