import React from "react";

const SimuladorIcon = props => (
	<svg width="1em" height="1em" viewBox="0 0 13 20" {...props}>
			<g fill="#FFF" fillRule="evenodd">
				<rect width={12.928} height={5.525} rx={1.105} />
				<circle cx={1.602} cy={8.674} r={1.602} />
				<circle cx={1.602} cy={13.536} r={1.602} />
				<circle cx={6.464} cy={8.674} r={1.602} />
				<circle cx={6.464} cy={13.536} r={1.602} />
				<circle cx={11.326} cy={8.674} r={1.602} />
				<circle cx={11.326} cy={13.536} r={1.602} />
				<circle cx={11.326} cy={18.398} r={1.602} />
				<rect y={16.796} width={8.066} height={3.204} rx={1.602} />
			</g>
		</svg>
);


export default SimuladorIcon;

