import { connect } from 'react-redux';

import operations from '../../../redux/operations';
import selectors from '../../../redux/selectors';
import StatusSelect from './statusSelect';

const mapStateToProps = ({ gestaoPagamentos }) => ({
  status: gestaoPagamentos.carteira.page.filters?.status,
  statusList: selectors.statusList(gestaoPagamentos),
  isLoading: gestaoPagamentos?.carteira?.page?.requestStatus?.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setStatus: (value) => dispatch(operations.setFilter('status', value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StatusSelect);
