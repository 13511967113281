import React from 'react';
import PropTypes from 'prop-types';
import DateRangePicker from '../../../../../common-staff/controls/dateRangePicker';

import './dateSelector.scss';

const DateSelector = ({
  startDate, endDate, setStartDate, setEndDate, isLoading,
}) => (
  <div className="comunicados__page__filtros__dataRange">
    <DateRangePicker
      title="Data da divulgação"
      startDate={startDate}
      endDate={endDate}
      setStartDate={setStartDate}
      setEndDate={setEndDate}
      numberOfMonths={2}
      disabled={isLoading}
    />
  </div>
);

DateSelector.propTypes = {
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  isLoading: PropTypes.bool,
};

DateSelector.defaultProps = {
  setStartDate: () => {},
  setEndDate: () => {},
  startDate: null,
  endDate: null,
  isLoading: false,
};
export default DateSelector;
