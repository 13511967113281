export const formatAsBRL = (number, positiveChar) => {
  if (!number && number !== 0) {
    return null;
  }

  const parts = Math.abs(number).toFixed(2).split('.');
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  const centsPart = parts[1];
  const sign = number < 0 ? '-' : positiveChar;

  return `${sign || ''} R$ ${integerPart},${centsPart}`;
};

export const getValuesResources = (list, field) => list
  .reduce((total, item) => total + (item[field]), 0);

export default null;
