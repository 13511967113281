import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../common/button';
import PaymentReceiptBody from './paymentReceiptBody';
import PaymentReceiptHeader from './paymentReceiptHeader';

import './paymentReceipt.scss';

class PaymentReceipt extends React.Component {
  resetPayment = () => {
    const { resetPaymentStore } = this.props;
    resetPaymentStore();
  };

  dowloandReport = () => {
    const { downloadPaymentReport, paymentId } = this.props;
    downloadPaymentReport(paymentId);
  };

  render() {
    const {
      className, paymentId, isTransactionError, downloading, downloadError,
    } = this.props;
    return (
      <div className={`payment-receipt-page ${className}`}>
        {!isTransactionError
          ? (
            <div className="payment-receipt-page-inner">
              <PaymentReceiptHeader
                className="payment-receipt-header"
              />
              <PaymentReceiptBody className="payment-receipt-body" paymentId={paymentId} />

              <div className="payment-receipt-footer">
                <div className="payment-receipt-footer-button-dowloand-container">
                  <Button
                    className="payment-receipt-footer-button-dowloand"
                    onClick={this.dowloandReport}
                    isLoading={downloading}
                  >
                    Exportar comprovante
                  </Button>
                </div>
                <Button
                  className="payment-receipt-footer-button-close"
                  onClick={this.resetPayment}
                >
                  Fechar
                </Button>
              </div>
            </div>
          )
          : (
            <>
              <PaymentReceiptHeader
                className="payment-receipt-header-centralized"
              />
              <div>
                <div className="payment-receipt-footer-button-dowloand-centralized-container">
                  <Button
                    className="payment-receipt-footer-button-dowloand-centralized"
                    onClick={this.dowloandReport}
                    isLoading={downloading}
                  >
                    Exportar comprovante
                  </Button>
                </div>

                <Button
                  className="payment-receipt-footer-button-close-centralized"
                  onClick={this.resetPayment}
                >
                  Fechar
                </Button>
                {downloadError === true
                  ? <div className="payment-receipt-footer-error-span-centralized">Algo deu errado, tente novamente</div> : null}
              </div>
            </>
          )}
      </div>
    );
  }
}

PaymentReceipt.propTypes = {
  className: PropTypes.string,

  resetPaymentStore: PropTypes.func.isRequired,

  paymentId: PropTypes.number.isRequired,
  isTransactionError: PropTypes.bool.isRequired,

  downloadPaymentReport: PropTypes.func.isRequired,
  downloading: PropTypes.bool,
  downloadError: PropTypes.bool,
};

PaymentReceipt.defaultProps = {
  className: '',
  downloading: false,
  downloadError: false,
};

export default PaymentReceipt;
