import React from 'react';

import LocalPrintshopIcon from '@material-ui/icons/LocalPrintshop';
import Button from 'features/common-staff/controls/button';

import './printButton.scss';

const PrintButton = () => (
  <Button
    className="comprovante-page__print-button"
    onClick={() => window.print()}
  >
    <LocalPrintshopIcon style={{ fill: '#000000' }} />
  </Button>
);

export default PrintButton;
