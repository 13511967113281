import * as React from "react";

const FiatIcon = (props) => {
	return (
		<svg focusable="false" aria-hidden="true" width="18" height="14" {...props}>
			<path d="M0 13.4151L2.04567 13.4286L2.06228 7.3547H3.5654V5.7973H2.06228V1.5574H4V0H0.00276829L0 13.4151ZM6.57143 13.4259L4.57143 13.4286L4.59017 0.0053768L6.57143 0V13.4259ZM7.72521 0.0026884L7.71429 13.4259H9.55572L9.56938 7.36353H11.6185C11.8015 8.47116 11.8206 13.0414 11.8206 13.399V13.4286H13.7003C13.7577 10.017 13.7276 3.67236 11.7878 0.0026884H7.72521ZM9.42857 6V1.71429H10.4024H10.4244C10.7931 2.46995 11.0352 3.56297 11.1865 4.37531C11.346 5.25783 11.4286 6 11.4286 6H9.42857ZM13.1429 1.56102V0.0107471L18 0V1.56102H16.5514L16.5568 13.4286H14.578L14.5727 1.56102H13.1429Z" fill="white">
			</path>
		</svg>
	);
}

export default FiatIcon;

