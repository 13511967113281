import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import HTMLReactParser from 'html-react-parser';
import { permissoes } from 'modules/auth';
import { hasPermission, getCurrentUser, isFidisUser } from 'modules/auth/auth';
import { PATH_CENTRAL } from 'routes/paths';
import resgateService from 'features/carteiraFidc/services/resgateService';
import useCustomFetch from 'hooks/useFetch';
import CircleAlertYellowIcon from 'assets/icons/circle-alert-yellow';
import moment from 'moment';
import PopperComponent from 'features/common/popperComponent/popperComponent';
import { Tracking } from 'modules';
import {
  AcceptButton, Aceite, Actions, ButtonRow, CloseButton, Container,
  DeclineButton, Divider, Footer, Message, OcultarMensagem, Title,
  AlertLink, LinkRedirect, AlertCheckbox, Reverter, AlertReverter,
  RevertButtonContainer, AlertCardDecline, AlertContainer,
} from './jurosCarenciaMessage.style';
import CustomFormOption from '../customFormOption/customFormOption';

const JurosCarenciaMessage = ({
  jurosCarencia, isModal, closeModal, addSnackbar, getResgate, ocultar, setOcultar,
}) => {
  const [declaracao, setDeclaracao] = useState(!jurosCarencia?.aceiteResgate);
  const [alert, setAlert] = useState(null);

  const [resDecline, resgateDecline] = useCustomFetch(
    () => resgateService.updateAceiteConcessionaria(true, false),
    [],
    false,
  );

  const [resAccept, resgateAccept] = useCustomFetch(
    () => resgateService.updateAceiteConcessionaria(true, true),
    [],
    false,
  );

  const handleNaoAceito = (event) => {
    if (!declaracao) {
      setAlert(alert || event.currentTarget);
    } else {
      resgateDecline();
    }
  };

  useEffect(() => {
    if (declaracao) {
      setAlert(null);
    }
  }, [declaracao]);

  const user = getCurrentUser();
  const isFidis = isFidisUser(user);
  const hasPermissionCentralComunicacao = hasPermission(user, permissoes.centralComunicacao);

  const { loading: loadingDecline, success: successDecline, error: errorDecline } = resDecline;
  const { loading: loadingAccept, success: successAccept, error: errorAccept } = resAccept;

  useEffect(() => {
    if (!!errorDecline || !!errorAccept) {
      if (isModal) closeModal(false);
      addSnackbar('Erro ao realizar operação.', 'error');
    }
  }, [addSnackbar, isModal, closeModal, errorDecline, errorAccept]);

  useEffect(() => {
    if (successDecline) {
      addSnackbar('Valor negado com sucesso.', 'success');
      if (isModal) closeModal(false);
      getResgate();
    }
  }, [getResgate, successDecline, addSnackbar, closeModal, isModal]);

  useEffect(() => {
    if (successAccept) {
      addSnackbar('Ação revertida com sucesso.', 'success');
      getResgate();
    }
  }, [getResgate, successAccept, addSnackbar]);

  const renderRevertButton = () => (
    <RevertButtonContainer>
      <AcceptButton
        onClick={() => {
          resgateAccept();
          Tracking.Mixpanel.fidc.trackButtons('fidc_resgate_juros_carencia_botao_reverter_escolha');
        }}
        isLoading={loadingAccept}
        disabled={isFidis}
        dataCy="ButtonReverterResgate"
      >
        Reverter
      </AcceptButton>
    </RevertButtonContainer>
  );

  const revertText = () => `Esse resgate foi negado em
    ${jurosCarencia?.ultimoRegistro ? moment(jurosCarencia.ultimoRegistro).format('DD/MM/YYYY') : '--/--/----'},
    deseja reverter a ação e receber o valor em conta corrente?`;

  return (
    <Container isModal={isModal}>
      <Title title={jurosCarencia?.titulo} isModal={isModal}>
        {jurosCarencia?.titulo}
      </Title>
      <Message>
        {HTMLReactParser(jurosCarencia?.mensagem ?? '')}
      </Message>
      <Aceite>
        <AlertContainer showAlert={alert}>
          <CustomFormOption
            type="radio"
            value={declaracao}
            label="Declaro que li e estou ciente das condições do resgate."
            setValue={setDeclaracao}
            disabled={isFidis || !jurosCarencia?.aceiteResgate}
            dataCy="RadioResgateDeclaracao"
          />
          {alert && <CircleAlertYellowIcon width="15" height="15" />}
        </AlertContainer>
      </Aceite>
      {isModal && <Divider />}
      <Footer isModal={isModal}>
        <OcultarMensagem>
          <AlertCheckbox>
            {isModal && (
            <CustomFormOption
              type="checkbox"
              value={ocultar}
              label="Não me mostre essa mensagem novamente."
              setValue={setOcultar}
              disabled={isFidis}
              dataCy="CheckboxResgateNaoMostrar"
            />
            )}
          </AlertCheckbox>
          <AlertLink isModal={!isModal}>
            {hasPermissionCentralComunicacao && (
            <LinkRedirect
              href={PATH_CENTRAL}
              target="_blank"
              rel="noreferrer"
            >
              Acesse a central de comunicados
            </LinkRedirect>
            )}
          </AlertLink>
        </OcultarMensagem>
        <Actions>
          <ButtonRow>
            {isModal && (
              <CloseButton
                onClick={() => {
                  closeModal();
                  Tracking.Mixpanel.fidc.trackButtons('fidc_resgate_juros_carencia_botao_fechar_modal');
                }}
                disabled={loadingAccept || loadingDecline}
                dataCy="ButtonFecharModalResgate"
              >
                Fechar
              </CloseButton>
            )}
            <DeclineButton
              onClick={() => {
                handleNaoAceito();
                Tracking.Mixpanel.fidc.trackButtons('fidc_resgate_juros_carencia_botao_nao_receber_valor');
              }}
              isLoading={loadingDecline}
              disabled={isFidis || !jurosCarencia?.aceiteResgate}
              dataCy="ButtonNegarResgate"
            >
              Não aceito receber o valor
            </DeclineButton>
            <PopperComponent anchorEl={alert} y={8}>
              <AlertCardDecline
                icone={<CircleAlertYellowIcon width="15" height="15" />}
                width="238px"
                title="As condições não foram aceitas."
                subtitle="Para aceitar proposta é necessário dar aceite nas condições descritas acima."
                colorBase="#FFF9EB"
                padding="8px 9px"
                alertCardContent="0px"
              />
            </PopperComponent>
          </ButtonRow>
        </Actions>
      </Footer>
      {(!jurosCarencia?.aceiteResgate && !isModal) && (
        <Reverter>
          <AlertReverter
            width="100%"
            alertCardContent="23px 15px"
            icone={<CircleAlertYellowIcon width="20" height="20" />}
            afterTitle={renderRevertButton()}
            title={revertText()}
            colorBase="#FFF9EB"
          />
        </Reverter>
      )}
    </Container>
  );
};

JurosCarenciaMessage.propTypes = {
  jurosCarencia: PropTypes.object,
  isModal: PropTypes.bool,
  closeModal: PropTypes.func,
  addSnackbar: PropTypes.func,
  getResgate: PropTypes.func,
  ocultar: PropTypes.bool,
  setOcultar: PropTypes.func,
};

JurosCarenciaMessage.defaultProps = {
  jurosCarencia: {
    valorResgate: 0.0,
    aceiteResgate: false,
    ocultarMensagem: false,
    titulo: '',
    mensagem: '',
  },
  isModal: false,
  closeModal: () => {},
  addSnackbar: () => {},
  getResgate: () => {},
  ocultar: false,
  setOcultar: () => {},
};

export default JurosCarenciaMessage;
