/* eslint-disable class-methods-use-this */
import axios from 'axios';
import qs from 'qs';

const getBrands = (dealers) => {
  const brands = [];

  (dealers || []).forEach((dealer) => {
    if (!dealer.brand) { return; }
    const brand = dealer.brand.toLowerCase();

    if (!brands.includes(brand)) {
      brands.push(brand);
    }
  });

  return brands;
};

class MensagemRedeService {
  async GetMensagensRede(dealers) {
    const response = await axios.get(
      `${window.env.REACT_APP_API_URL}/mensagem-rede`,
      {
        params: {
          brands: getBrands(dealers),
        },
        paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
      },
    );
    if (response.status !== 200) {
      throw new Error('Erro ao buscar as mensagens da Rede!');
    }
    return response.data;
  }

  async MarkAsRead(mensagensId) {
    const response = await axios.post(`${window.env.REACT_APP_API_URL}/mensagem-rede/leitura`, { mensagensId });

    if (response.status !== 200 || !response.data) {
      throw new Error('Erro ao tentar efetuar a leitura das mensagens da rede! ');
    }

    return response.data;
  }
}
export default new MensagemRedeService();
