import { connect } from 'react-redux';

import operations from '../../../redux/operations';
import DataSelector from './dataSelector';

const mapStateToProps = ({ dashboard }) => ({
  valor: dashboard.painelFloorPlan.filters.atualizadoOntem,
});

const mapDispatchToProps = (dispatch) => ({
  setValor: (value) => dispatch(operations.setFilter('atualizadoOntem', value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataSelector);
