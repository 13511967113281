import React from 'react';

import BreadCrumb from 'features/common-staff/layout/breadCrumb';

const BreadCrumbTransferencia = () => (
  <BreadCrumb
    labels={[{
      label: 'Limites',
      path: ' ',
    },
    {
      label: 'Solicitação de transferência de limite',
      path: '/transferencia-limite',
    }]}
  />
);

export default BreadCrumbTransferencia;
