import { connect } from 'react-redux';

import DateFilter from './dateFilter';
import { setStartDate, setEndDate } from '../../redux/iofSimulatorOperations';

const mapStateToProps = ({ iofSimulatorStore }) => ({
  startDate: iofSimulatorStore.filters?.startDate,
  endDate: iofSimulatorStore.filters?.endDate,
  isLoading: iofSimulatorStore.loading,
});

const mapDispatchToProps = (dispatch) => ({
  setStartDate: (startDate) => dispatch(setStartDate(startDate)),
  setEndDate: (endDate) => dispatch(setEndDate(endDate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DateFilter);
