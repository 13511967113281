import axios from 'axios';

class ComprovanteService {
  constructor() {
    this.URL_API = window.env.REACT_APP_API_URL;
  }
  async getExtrato(pagamentos) {
    const params = new URLSearchParams();
    pagamentos.map((t) => params.append('transacaoIdList', t));
    const response = await axios.get(`${this.URL_API}/transactions/extrato?`, {
      params,
      responseType: 'arraybuffer',
    });

    if (!response || response.status !== 200) {
      throw new Error('Error downloading extrato xlsx');
    }

    return response.data;
  }

  async getComprovante(id) {
    const params = new URLSearchParams();
    id.map((t) => params.append('movimentacaoId', t));

    const response = await axios.get(`${this.URL_API}/balance/pagamento`, {
      params,
    });

    if (!response || response.status !== 200) {
      throw new Error(`Error get comprovante ${id}`);
    }

    return response.data;
  }
}

export default new ComprovanteService();
