import _ from 'lodash';
import { createSelector } from 'reselect';
import { camelFormat, formatDate } from 'utils/format';
import { DateRange } from './enum';

const isLoading = createSelector(
  (state) => state.painelFloorPlan.cardsResumo.isLoading,
  (state) => state.painelFloorPlan.cardsVencimento.isLoading,
  (cardsResumo, cardsVencimento) => (cardsResumo || cardsVencimento),
);

const getBrands = createSelector(
  (state) => state.dealers,
  (dealers) => dealers.map((item) => item.brand)
    .filter((value, index, self) => self.indexOf(value) === index),
);

const selectPontosVenda = createSelector(
  (state) => state.dealers,
  (dealers) => dealers.map((d) => ({
    value: d.dealerId,
    text: (`${d.dealerCorr} - ${camelFormat(d.dealerName, 2)}`).trim(),
  })),
);

const formattedDateFilter = createSelector(
  (state) => state.painelFloorPlan.filters.filterType,
  (state) => state.painelFloorPlan.filters.startDate,
  (state) => state.painelFloorPlan.filters.endDate,
  (filterType, startDate, endDate) => {
    if (filterType === DateRange.today || filterType === DateRange.yesterday) {
      return formatDate(startDate, 'MMMM YYYY');
    }

    if (filterType === DateRange.all) {
      return 'Total';
    }

    const start = formatDate(startDate, 'DD [de] MMM YYYY');
    const end = formatDate(endDate, 'DD [de] MMM YYYY');
    return `${start} - ${end}`;
  },
);

const getDonutData = createSelector(
  (state) => state.painelFloorPlan.cardsResumo?.data?.valorFloorPlan,
  (state) => state.painelFloorPlan.cardsResumo?.data?.valorFundao,
  (valorFloorPlan, valorFundao) => {
    const percentageFP = ((valorFloorPlan * 100) / (valorFloorPlan + valorFundao)).toFixed(2);
    const percentageFundao = ((valorFundao * 100) / (valorFloorPlan + valorFundao)).toFixed(2);

    return ({
      dataset: [{
        name: 'Floor Plan',
        value: valorFloorPlan,
        percentage: Number(percentageFP),
      },
      {
        name: 'Fundão',
        value: valorFundao,
        percentage: Number(percentageFundao),
      },
      ],
    }
    );
  },
);

const isEmptyCarteiraProduto = createSelector(
  (state) => state.painelFloorPlan.carteiraProduto.data,
  (carteiraProduto) => _.isEmpty(carteiraProduto),
);

const thereIsASelectedDealer = createSelector(
  (state) => state.painelFloorPlan.filters.selectedPonto,
  (selectedPonto) => !(selectedPonto.length > 0),
);

const isEmptyCarteiraBrand = createSelector(
  (state) => state.painelFloorPlan.carteiraBrand.data,
  (carteiraBrand) => _.isEmpty(carteiraBrand),
);

const selectorDuplicatasValorData = createSelector(
  (state) => state.painelFloorPlan.valoresDuplicatas.data,
  (data) => [
    {
      category: 'Vencido',
      fundao: data.find((f) => f.produto.toUpperCase() === 'FUNDAO').vencidas,
      floorPlan: data.find((f) => f.produto.toUpperCase() === 'FLOORPLAN').vencidas,
    },
    {
      category: 'A vencer',
      fundao: data.find((f) => f.produto.toUpperCase() === 'FUNDAO').emAberto,
      floorPlan: data.find((f) => f.produto.toUpperCase() === 'FLOORPLAN').emAberto,
    },
  ],
);

const selectorDuplicatasAbsolutoData = createSelector(
  (state) => state.painelFloorPlan.quantidadeDuplicatas.data,
  (data) => [
    {
      category: 'Vencido',
      fundao: data.find((f) => f.produto.toUpperCase() === 'FUNDAO').vencidas,
      floorPlan: data.find((f) => f.produto.toUpperCase() === 'FLOORPLAN').vencidas,
    },
    {
      category: 'A vencer',
      fundao: data.find((f) => f.produto.toUpperCase() === 'FUNDAO').emAberto,
      floorPlan: data.find((f) => f.produto.toUpperCase() === 'FLOORPLAN').emAberto,
    },
  ],
);

const selectDuplicatasValorTable = createSelector(
  (state) => state.painelFloorPlan.valoresDuplicatas.data,
  (data) => ({
    floorPlan: {
      aVencer: data.find((f) => f.produto.toUpperCase() === 'FLOORPLAN').emAberto,
      vencido: data.find((f) => f.produto.toUpperCase() === 'FLOORPLAN').vencidas,
      total: data.find((f) => f.produto.toUpperCase() === 'FLOORPLAN').emAberto + data.find((f) => f.produto.toUpperCase() === 'FLOORPLAN').vencidas,
    },
    fundao: {
      aVencer: data.find((f) => f.produto.toUpperCase() === 'FUNDAO').emAberto,
      vencido: data.find((f) => f.produto.toUpperCase() === 'FUNDAO').vencidas,
      total: data.find((f) => f.produto.toUpperCase() === 'FUNDAO').emAberto + data.find((f) => f.produto.toUpperCase() === 'FUNDAO').vencidas,
    },
  }),
);

const selectDuplicatasAbsolutoTable = createSelector(
  (state) => state.painelFloorPlan.quantidadeDuplicatas.data,
  (data) => ({
    floorPlan: {
      aVencer: data.find((f) => f.produto.toUpperCase() === 'FLOORPLAN').emAberto,
      vencido: data.find((f) => f.produto.toUpperCase() === 'FLOORPLAN').vencidas,
      total: data.find((f) => f.produto.toUpperCase() === 'FLOORPLAN').emAberto + data.find((f) => f.produto.toUpperCase() === 'FLOORPLAN').vencidas,
    },
    fundao: {
      aVencer: data.find((f) => f.produto.toUpperCase() === 'FUNDAO').emAberto,
      vencido: data.find((f) => f.produto.toUpperCase() === 'FUNDAO').vencidas,
      total: data.find((f) => f.produto.toUpperCase() === 'FUNDAO').emAberto + data.find((f) => f.produto.toUpperCase() === 'FUNDAO').vencidas,
    },
  }),
);

const isEmptyValorDuplicata = createSelector(
  (state) => state.painelFloorPlan.valoresDuplicatas.data,
  (data) => _.isEmpty(data),
);

const isEmptyQuantidadeDuplicata = createSelector(
  (state) => state.painelFloorPlan.quantidadeDuplicatas.data,
  (data) => _.isEmpty(data),
);

export default {
  isLoading,
  getBrands,
  selectPontosVenda,
  formattedDateFilter,
  getDonutData,
  isEmptyCarteiraProduto,
  thereIsASelectedDealer,
  isEmptyCarteiraBrand,
  selectorDuplicatasValorData,
  selectDuplicatasValorTable,
  isEmptyValorDuplicata,
  selectorDuplicatasAbsolutoData,
  selectDuplicatasAbsolutoTable,
  isEmptyQuantidadeDuplicata,
};
