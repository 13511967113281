import axios from 'axios';

class OnboradingService {
  constructor() {
    this.URL_API = window.env.REACT_APP_API_URL;
  }

  async getControleUsuario() {
    const response = await axios.get(`${this.URL_API}/fidc/onboarding/controle-usuario`);
    if (!response || response.status !== 200) {
      throw new Error('Error Get Controle Usuário Onboarding');
    }

    return response.data;
  }

  async updateControleUsuario(tipoFuncionalidade) {
    const response = await axios.post(`${this.URL_API}/fidc/onboarding/controle-usuario`, { tipoFuncionalidade });
    if (!response || response.status !== 200) {
      throw new Error('Error Update Controle Usuário Onboarding');
    }

    return response.data;
  }
}
export default new OnboradingService();
