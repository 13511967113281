import React from 'react';

import BotaoDownload from './botaoDownload';
import BotaoFiltrar from './botaoFiltrar';
import DateRange from './dateRange';
import RelatoriosSelect from './relatorioSelect';
import DealerSelect from './dealerSelect';

import './filtros.scss';

const Filtros = () => (
  <div
    className="relatorios__filtros__container"
    data-cy="RelatoriosFiltros"
  >
    <div
      className="relatorios__filtros__container__concessionarias"
      data-cy="RelatoriosDealerSelect"
    >
      <DealerSelect />
    </div>
    <div
      className="relatorios__filtros__container__documento"
      data-cy="RelatoriosSelect"
    >
      <RelatoriosSelect />
    </div>
    <div
      className="relatorios__filtros__container__data"
      data-cy="RelatoriosDateSelect"
    >
      <DateRange />
    </div>
    <div
      className="relatorios__filtros__container__botao-filtrar"
      data-cy="RelatoriosBotaoFiltrar"
    >
      <BotaoFiltrar textApply="Filtrar" textSelect="Filtrar" />
    </div>
    <div
      className="relatorios__filtros__container__botao-download"
      data-cy="RelatoriosBotaoDownload"
    >
      <BotaoDownload />
    </div>
  </div>
);

export default Filtros;
