import { connect } from 'react-redux';

import ModalTaxasFloorPlan from './modalTaxasFloorPlan';
import operations from './redux/operations';
import selector from './redux/selector';

const mapStateToProps = ({ gestaoPagamentos }) => ({
  isLoading: gestaoPagamentos.carteira?.modalTaxas?.requestStatus?.isLoading,
  isError: gestaoPagamentos.carteira?.modalTaxas?.requestStatus?.isError,
  taxas: selector.getTaxas(gestaoPagamentos),
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(operations.setModalOpen()),
  resetStore: () => dispatch(operations.resetStore()),
  getTaxa: () => dispatch(operations.getTaxas()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalTaxasFloorPlan);
