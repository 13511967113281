import { connect } from 'react-redux';

import ChartAbsolutosDuplicatas from './chartAbsolutosDuplicatas';
import selector from '../../../redux/selectors';

const mapStateToProps = ({ dashboard }) => ({
  data: selector.selectorDuplicatasAbsolutoData(dashboard),
});

export default connect(mapStateToProps)(ChartAbsolutosDuplicatas);
