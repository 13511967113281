import { connect } from 'react-redux';

import RelatorioPage from './relatoriosPage';
import operations from './redux/operations';

const mapStateToProps = ({ relatoriosIof }) => ({
  snackbarErrors: relatoriosIof.snackbarErrors,
  wasFilted: relatoriosIof.wasFilted,
});

const mapDispatchToProps = (dispatch) => ({
  setFilter: (value) => dispatch(operations.setFilter(value, 'dealers', false)),
  resetStore: () => dispatch(operations.resetStore()),
  onCloseSnackBar: (id) => dispatch(operations.dismissSnackbar(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RelatorioPage);
