import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import Loading from 'features/common-staff/layout/loading';
import SummaryPage from 'features/common/summaryPage';
import { Stack, Typography } from '@mui/material';
import colors from 'assets/styles/colors';
import InfoDocumentosComplementares from './infoDocumentosComplementares';
import InfoDocumentosFormalizar from './infoDocumentosFormalizar';
import RenderIconStatus from '../renderIconStatus/renderIconStatus';

const DocumentosJuridico = ({
  listaDocumentosComplementares, listaDocumentosFormalizar, pendenciaDocumentosFormalizar,
  isLoading,
}) => {
  const renderDocumentosComplementares = !_.isEmpty(listaDocumentosComplementares);
  const renderDocumentosFormalizar = !_.isEmpty(listaDocumentosFormalizar);
  return (
    isLoading
      ? (
        <div style={{ padding: '24px' }}>
          <Loading />
        </div>
      )
      : (renderDocumentosComplementares || renderDocumentosFormalizar)
        && (
          <SummaryPage title="Documentos do Jurídico">
            { renderDocumentosComplementares && (
              <SummaryPage title="Documentos Complementares" level={1} open>
                {listaDocumentosComplementares?.map((item, index) => (
                  <InfoDocumentosComplementares
                    key={index}
                    indexPessoa={index}
                    pessoaDocumentacao={item}
                  />
                ))}
              </SummaryPage>
            )}
            { renderDocumentosFormalizar && (
              <SummaryPage
                title="Documentos para formalizar"
                level={1}
                open
                IconTitle={<RenderIconStatus pendencia={pendenciaDocumentosFormalizar} />}
              >
                <Stack gap="16px" direction="column" margin="4px">
                  <Stack gap="8px">
                    <Typography
                      fontSize="14px"
                      fontWeight="700"
                      color={colors.secundary_color_800}
                      marginLeft="8px"
                      marginTop="8px"
                      lineHeight="24px"
                    >
                      Lista de documentos
                    </Typography>
                    <Typography
                      component="span"
                      fontSize="12px"
                      lineHeight="16px"
                      color={colors.secundary_color_700}
                      marginLeft="8px"
                    >
                      Insira os documentos referentes a atualização do cadastro da aprovação
                      e em seguida clique em enviar para cadastro.
                    </Typography>
                  </Stack>
                  {listaDocumentosFormalizar?.map((item, index) => (
                    <InfoDocumentosFormalizar
                      key={index}
                      documento={item}
                      indexList={index}
                    />
                  ))}
                </Stack>
              </SummaryPage>
            )}
          </SummaryPage>
        )
  );
};

DocumentosJuridico.propTypes = {
  listaDocumentosComplementares: PropTypes.array,
  listaDocumentosFormalizar: PropTypes.array,
  pendenciaDocumentosFormalizar: PropTypes.bool,
  isLoading: PropTypes.bool,
};

DocumentosJuridico.defaultProps = {
  listaDocumentosComplementares: [],
  listaDocumentosFormalizar: [],
  pendenciaDocumentosFormalizar: false,
  isLoading: false,
};

export default DocumentosJuridico;
