import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { ConcessionariaSelector } from 'features/common/';

const useStyles = makeStyles({
  input: {
    marginBottom: '15px',
  },
});

const SelectConcessionaria = ({
  setConcessionariaSelecionada, concessionarias, selectedDealerId,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.input}>
      <ConcessionariaSelector
        concessionarias={concessionarias}
        concessionariaSelecionada={concessionarias.find((c) => c.dealerId === selectedDealerId)}
        setConcessionariaSelecionada={setConcessionariaSelecionada}
      />
    </div>
  );
};

SelectConcessionaria.propTypes = {
  setConcessionariaSelecionada: PropTypes.func,
  concessionarias: PropTypes.array,
  selectedDealerId: PropTypes.number,
};

SelectConcessionaria.defaultProps = {
  concessionarias: [],
  setConcessionariaSelecionada: () => {},
  selectedDealerId: null,
};

export default SelectConcessionaria;
