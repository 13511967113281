import React from 'react';
import PropTypes from 'prop-types';

import FormSectionModal from '../formSectionModal';

const DadosOrigemModal = ({ codigoSap, selectedDealerId, selectedProduct }) => (
  <FormSectionModal
    title="Concessionária origem"
    codigoSap={codigoSap}
    selectedDealerId={selectedDealerId}
    selectedProduct={selectedProduct}
  />
);

DadosOrigemModal.propTypes = {
  codigoSap: PropTypes.any,
  selectedDealerId: PropTypes.number,
  selectedProduct: PropTypes.string,
};

DadosOrigemModal.defaultProps = {
  codigoSap: '',
  selectedDealerId: null,
  selectedProduct: null,
};

export default DadosOrigemModal;
