import React from 'react';

import BreadCrumb from 'features/common-staff/layout/breadCrumb';

const BreadCrumbSolicitacoes = () => (
  <BreadCrumb
    labels={[{
      label: 'Solicitar veículo',
      path: '/products',
    },
    {
      label: 'minhas solicitações',
      path: ' ',
    }]}
  />
);

export default BreadCrumbSolicitacoes;
