import React from 'react';
import PropTypes from 'prop-types';

import List, { ListContent } from '../../../../../../../common-staff/layout/list';
import ListRow from './listRow';

import './selectedList.scss';

const SelectedList = ({ invoices }) => (
  <div className="carteira-modal__list__container">
    <List
      autoHeight
      autoHeightMax="440px"
      autoHeightMin=""
    >
      <ListContent>
        {(Array.isArray(invoices) ? invoices : []).map((d, i) => (
          <ListRow
            invoice={d}
            key={i}
            infoModalOpen
            isModalOpen
          />
        ))}
      </ListContent>
    </List>
  </div>
);

SelectedList.propTypes = {
  invoices: PropTypes.array,
};

SelectedList.defaultProps = {
  invoices: null,
};

export default SelectedList;
