export const SET_DEALER_GROUP = 'iof-sim/SET_CNPJ_ROOT';
export const START_REQUEST = 'iof-sim/START_REQUEST';
export const FINISH_REQUEST = 'iof-sim/FINISH_REQUEST';
export const SET_INVOICES = 'iof-sim/SET_INVOICES';
export const CONCAT_INVOICES = 'iof-sim/CONCAT_INVOICES';
export const SET_SELECTED_VALUE = 'iof-sim/SET_SELECTED_VALUE';
export const ADD_TO_SELECTED_VALUE = 'iof-sim/ADD_TO_SELECTED_VALUE';
export const SET_MOV_ACC_VALUE = 'iof-sim/SET_MOV_ACC_VALUE';
export const SET_LOAD_ERROR = 'iof-sim/SET_LOAD_ERROR';
export const RESET_STORE = 'iof-sim/RESET_STORE';
export const SET_FINANCIAL_VALUES = 'iof-sim/SET_FINANCIAL_VALUES';
export const SET_FILTER = 'iof-sim/SET_FILTER';
export const SET_START_DATE = 'iof-sim/SET_START_DATE';
export const SET_END_DATE = 'iof-sim/SET_END_DATE';
export const SET_IS_UPDATED = 'iof-sim/SET_IS_UPDATED';

export const setDealerGroup = (dealerGroup) => ({
  type: SET_DEALER_GROUP,
  payload: {
    dealerGroup,
  },
});

export const setInvoices = (invoices, hasMoreItens) => ({
  type: SET_INVOICES,
  payload: {
    invoices,
    hasMoreItens,
  },
});

export const concatInvoices = (invoices, hasMoreItens, newPage) => ({
  type: CONCAT_INVOICES,
  payload: {
    invoices,
    hasMoreItens,
    newPage,
  },
});

export const setFinancialValues = (transmission, payments, lastUpdate) => ({
  type: SET_FINANCIAL_VALUES,
  payload: {
    transmission,
    payments,
    lastUpdate,
  },
});

export const startRequest = () => ({
  type: START_REQUEST,
});

export const finishRequest = () => ({
  type: FINISH_REQUEST,
});

export const setSelectedValue = (value) => ({
  type: SET_SELECTED_VALUE,
  payload: value,
});

export const setIsUpdated = (value) => ({
  type: SET_IS_UPDATED,
  payload: value,
});

export const addToSelectedValue = (value) => ({
  type: ADD_TO_SELECTED_VALUE,
  payload: value,
});

export const setMovementAccountPaymentsValue = (value) => ({
  type: SET_MOV_ACC_VALUE,
  payload: value,
});

export const setLoadError = (isError) => ({
  type: SET_LOAD_ERROR,
  payload: isError,
});

export const resetStore = () => ({
  type: RESET_STORE,
});

export const setStartDate = (startDate) => ({
  type: SET_START_DATE,
  payload: startDate,
});

export const setEndDate = (endDate) => ({
  type: SET_END_DATE,
  payload: endDate,
});
