import { connect } from 'react-redux';

import operations from '../../../../redux/operations';
import TipoTransacao from './tipoTransacao';

const mapStateToProps = ({ statementStore }) => ({
  tipo: statementStore.filters?.tipo,
  isLoading: statementStore?.requestStatus?.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setTipoTransacao: (value) => dispatch(operations.setFilterTransactionType(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TipoTransacao);
