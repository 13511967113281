import React from 'react';
import PropTypes from 'prop-types';
import { Print } from '@material-ui/icons';
import Modal from 'features/common-staff/layout/modal';
import Button from 'features/common/button';
import { Tracking } from 'modules';
import ResumoPagamento from '../cardResumo/resumoPagamento';
import PagamentosList from './views/pagamentosList';
import AlertaPagamento from './views/alertaPagamento';
import './comprovantePagamento.scss';

const styleForIcon = {
  fontSize: '20px',
  color: '#555770',
};

const ComprovantePagamento = ({
  closeModal, isLoading, exportarComprovante, listaPagamentos,
}) => {
  const transacaoIdList = [];
  // eslint-disable-next-line max-len
  (Array.isArray(listaPagamentos) ? listaPagamentos : []).map((p) => transacaoIdList.push(p.idTransacao));

  return (
    <Modal
      disableCloseButton={isLoading}
      closeModal={closeModal}
    >
      <div
        className="comprovante__modal__container"
        data-cy="ComprovanteModalContainer"
      >
        <div className="comprovante__modal__header no-print">
          Comprovante de Pagamento
        </div>
        <div className="comprovante__modal__content">
          <div className="comprovante__modal__content_list no-print">
            <div
              className="comprovante__modal__content_list_subtitle"
              data-cy="ComprovanteModalContentListSubtitle"
            >
              Abaixo você tem acesso a todas as transações necessárias para
              efetuar o pagamento das duplicatas selecionadas.
            </div>
            <PagamentosList />
            {(Array.isArray(listaPagamentos) ? listaPagamentos : []).some((p) => p.status === 'PAGAMENTO_REPROVADO')
              ? (<AlertaPagamento />) : null}

          </div>
          <div className="comprovante__modal__content_aside">
            <div className="comprovante__modal__card-resumo__card">
              <div
                className="comprovante__modal__card-resumo__title"
                data-cy="ComprovanteModalCardResumoTitle"
              >
                Resumo da solicitação de pagamento
              </div>
              <ResumoPagamento />
            </div>
            <div className="comprovante__modal__content_buttons">
              <div
                className="comprovante__modal__content_botao_exportar no-print"
                data-cy="ComprovanteModalBotaoExportar"
              >
                <Button
                  className="fidis-btn-fill-width"
                  onClick={() => exportarComprovante()}
                >
                  Exportar
                </Button>
              </div>
              <div
                className="comprovante__modal__content_botao_imprimir no-print"
                data-cy="ComprovanteModalBotaoImprimir"
              >
                <Button
                  className="fidis-btn-fill-width-light"
                  onClick={() => {
                    Tracking.Mixpanel.movimentacoes.trackPaymentPrint();
                    window.print();
                  }}
                >
                  <Print style={styleForIcon} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Modal>
  );
};

ComprovantePagamento.propTypes = {
  listaPagamentos: PropTypes.array,
  closeModal: PropTypes.func,
  exportarComprovante: PropTypes.func,
  isLoading: PropTypes.bool,
};

ComprovantePagamento.defaultProps = {
  closeModal: () => {},
  exportarComprovante: () => {},
  isLoading: false,
  listaPagamentos: [],
};

export default ComprovantePagamento;
