import { actions } from './actions';
import { etapas } from './etapas';

import Tracking from '../../../../../modules/tracking';

import ErrorFeedbackService from '../errorFeedbackService';

const setCategoriasDisponiveis = (categorias) => (dispatch) => {
  dispatch(actions.setCategoriasDisponiveis(categorias));
};

const setUserInfo = (userInfo) => (dispatch) => {
  dispatch(actions.setUserInfo(userInfo));
};

const goFromSentimosMuitoToSelecionarCategoria = () => (dispatch, getState) => {
  if (getState().feedback.errorFeedback.etapaAtual === etapas.sentimosMuito) {
    dispatch(actions.goFromSentimosMuitoToSelecionarCategoria());
  }
};

const selecionarCategoria = (categoria) => (dispatch) => {
  dispatch(actions.selecionarCategoria(categoria));
};

const descreverProblema = (descricao) => (dispatch, getState) => {
  dispatch(actions.descreverProblema(descricao));

  const { respostaAtual, userInfo } = getState().feedback.errorFeedback;
  const dto = {
    UsuarioId: userInfo.usuarioId,
    UsuarioEmail: userInfo.usuarioEmail,
    UsuarioNome: userInfo.usuarioNome,
    ConcessionariaId: userInfo.concessionariaId,
    ConcessionariaNome: userInfo.concessionariaNome,
    Regional: userInfo.regional,
    RegionalCodigo: userInfo.regionalCodigo,
    Categoria: respostaAtual.categoria,
    Descricao: respostaAtual.descricao,
  };

  ErrorFeedbackService.postAnswer(dto);
  Tracking.Mixpanel.feedback.error.trackSend();
};

const reportarOutro = (querReportarOutro) => (dispatch) => {
  dispatch(actions.reportarOutro(querReportarOutro));
};

const resetStore = () => (dispatch) => {
  dispatch(actions.resetStore());
};

export const operations = {
  setUserInfo,
  setCategoriasDisponiveis,
  goFromSentimosMuitoToSelecionarCategoria,
  selecionarCategoria,
  descreverProblema,
  reportarOutro,
  resetStore,
};
