import { connect } from 'react-redux';

import { PaymentOperations, PaymentSelectors, PaymentActions } from '../../redux';
import PaymentListing from './paymentListing';
import { operations as BalanceOperations } from '../../../balance/redux';

const mapDispatchToProps = (dispatch) => ({
  loadNewPage: (page, itemsPerPage, startDate, endDate, searchText) => (
    dispatch(PaymentOperations.loadNewPage(page, itemsPerPage, startDate, endDate, searchText))
  ),
  setPaymentStep: (step) => (
    dispatch(PaymentOperations.setPaymentStep(step))
  ),
  selectInvoice: (invoice, selectedInvoices) => {
    dispatch(PaymentOperations.selectInvoice(invoice, selectedInvoices));
  },
  deselectInvoice: (invoice, selectedInvoices) => {
    dispatch(PaymentOperations.deselectInvoice(invoice, selectedInvoices));
  },
  updateSelectedInvoices: (invoices) => {
    dispatch(PaymentOperations.updateSelectedInvoices(invoices));
  },
  getBalance: () => dispatch(BalanceOperations.getBalanceInfo()),

  getInvoices: (page, startDate, endDate, searchText) => (
    dispatch(PaymentOperations.getInvoices(page, startDate, endDate, searchText))
  ),
  setStartDate: (startDate) => (
    dispatch(PaymentOperations.setStartDate(startDate))
  ),
  setEndDate: (endDate) => (
    dispatch(PaymentOperations.setEndDate(endDate))
  ),
  setFilterSearchText: (searchText) => (
    dispatch(PaymentActions.setFilterSearchText(searchText))
  ),
  setSearchTextValue: (value) => (
    dispatch(PaymentActions.setSearchTextValue(value))
  ),
});

const mapStateToProps = ({ paymentStore, balanceStore }) => ({
  hasMoreItens: paymentStore.hasMoreItens,
  invoices: paymentStore.invoices,
  page: paymentStore.page,
  selectedInvoices: paymentStore.selectedInvoices,
  totalValue: PaymentSelectors.getTotalRemainingValue(paymentStore.selectedInvoices),
  remainingBalance: balanceStore.creditoRemanescente,
  loading: paymentStore.loading,
  startDate: paymentStore.startDate,
  endDate: paymentStore.endDate,
  searchText: paymentStore.searchText,
  isSearchActive: paymentStore.isSearchActive,
  searchTextValue: paymentStore.searchTextValue,
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentListing);
