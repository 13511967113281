import { connect } from 'react-redux';

import * as AcompanhamentoOperations from '../../acompanhamentoList/redux/operations';

import TextFilter from './textFilter';

const mapStateToProps = ({ testDrive }) => ({
  texto: testDrive.acompanhamento.list.filters.texto,
  isLoading: testDrive?.acompanhamento?.list?.pedidosList?.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setTexto: (texto) => dispatch(AcompanhamentoOperations.setTexto(texto)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TextFilter);
