import React from 'react';
import cookies from 'react-cookie';
import PropTypes from 'prop-types';
import { reactLocalStorage } from 'reactjs-localstorage';
import { Tracking } from '../../../../../modules';

import {
  COOKIE_AUTH_TOKEN, COOKIE_FIDIS_USER_ID,
  COOKIE_FIDIS_USER_EMAIL,
} from '../../../../../modules/auth/cookies';

import rcStorage from './rcStorage';
import { loadDealers } from '../../../../../modules/dealers/dealers';

import MenuAsideButton from '../menuAsideButton';

import AtualizarCadastroIcon from '../../../../../assets/icons/atualizar-cadastro';

const setAtualizacaoCadastroCookies = () => {
  rcStorage.clearCookies();
  rcStorage.saveInfo({
    token: reactLocalStorage.getObject(COOKIE_AUTH_TOKEN, null, true),
    userId: cookies.load(COOKIE_FIDIS_USER_ID),
    userEmail: cookies.load(COOKIE_FIDIS_USER_EMAIL),
    fidisDealers: loadDealers(),
  });
};

const AtualizarCadastroButton = ({ permissions }) => (
  <MenuAsideButton
    onClick={() => {
      Tracking.Mixpanel.trackMenuClick('RC Atualização Cadastral');
      setAtualizacaoCadastroCookies();
      window.open(window.env.REACT_APP_RC_URL);
    }}
    icon={<AtualizarCadastroIcon />}
    visible={permissions.rcAcessarRenovacao === true}
    label="Atualizar Cadastro"
    active={false}
  />
);

AtualizarCadastroButton.propTypes = {
  permissions: PropTypes.object.isRequired,
};

export default AtualizarCadastroButton;
