import { React, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Box, Divider, Stack, Typography,
} from '@mui/material';
import colors from 'assets/styles/colors';
import FileButton from 'features/common/fileButton/fileButton';
import UploadDocumentoJuridico from '../../uploadDocumentoJuridico';
import { getFilesFromType } from './documentosFormalizarUtils';
import './infoDocumentosFormalizar.scss';

const InfoDocumentosFormalizar = ({
  documento,
  indexList,
  appendAnexo,
  downloadAnexo,
  deleteAnexo,
  uploadAnexo,
}) => {
  const isAnexoDealer = documento?.tipoEnvioDocumento?.some((tipo) => tipo === 'anexo_do_dealer');
  const isDocusign = documento?.tipoEnvioDocumento?.some((tipo) => tipo === 'docsign');
  const isDocumentoOriginal = documento?.tipoEnvioDocumento?.some((tipo) => tipo === 'enviar_doc_original');

  const listaArquivos = documento?.listaArquivos;
  const arquivoJuridico = getFilesFromType(listaArquivos, 'juridico');
  const arquivosDealer = getFilesFromType(listaArquivos, 'dealer');
  const fileExtensions = '.jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .csv';

  useEffect(() => {
    if (_.isEmpty(arquivosDealer)) appendAnexo(indexList);
  }, [listaArquivos]);

  const renderAlert = (text) => (
    <Typography
      fontSize="14px"
      fontWeight="400"
      color={colors.secundary_color_800}
      marginLeft="8px"
      lineHeight="24px"
    >
      {text}
      {' '}
      <span style={{ color: colors.error_color_300 }}>*</span>
    </Typography>
  );

  return (
    <Stack
      border={`1px solid ${colors.secundary_color_100}`}
      borderRadius="4px"
      padding="4px"
      gap="16px"
    >
      <Stack direction="row" gap="12px" marginLeft="4px" marginTop="4px">
        <Stack direction="column">
          <Typography
            textAlign="left"
            color={colors.secundary_color_700}
            fontSize="12px"
            lineHeight="16px"
            paddingBottom="4px"
            paddingLeft="8px"
          >
            Nome do documento
          </Typography>
          <Box
            display="flex"
            padding="8px 12px"
            borderRadius="4px"
            backgroundColor={colors.secundary_color_100_24}
            fontSize="14px"
            lineHeight="24px"
            color={colors.secundary_color_700}
            width="462px"
          >
            {documento?.nome}
          </Box>
        </Stack>
        {(_.isEmpty(arquivoJuridico) ? null : arquivoJuridico[0])?.nomeGuid && (
          <Stack width="486px">
            <FileButton
              label="Arquivo jurídico"
              nome={arquivoJuridico[0]?.nomeOriginal}
              tamanho={arquivoJuridico[0]?.tamanho}
              downloadFile={() => downloadAnexo(arquivoJuridico[0]?.nomeGuid)}
              showDeleteButton={false}
            />
          </Stack>
        )}
      </Stack>
      {isDocusign && renderAlert('Este documento está em processo de assinatura via Docusign')}
      {isDocumentoOriginal && renderAlert('É necessário o envio do documento original')}
      <Divider />
      { isAnexoDealer && (
        <Stack direction="row" gap="16px">
          <Stack direction="column">
            <Typography
              textAlign="left"
              color={colors.secundary_color_700}
              fontSize="12px"
              lineHeight="16px"
              paddingBottom="4px"
              paddingLeft="12px"
            >
              Arquivo do dealer
            </Typography>
            <Stack direction="column" gap="8px">
              { arquivosDealer?.map((item, index) => (
                <Stack direction="row" gap="16px" width="486px">
                  <UploadDocumentoJuridico
                    label=""
                    documento={{
                      nome: item.nomeOriginal,
                      tamanho: item.tamanho,
                    }}
                    statusLimite={!item?.nomeGuid && index === 0 ? 'pendencia_anexo' : 'anexado'}
                    errorStatusList={['pendencia_anexo']}
                    showDeleteButton={item?.validado === 0}
                    downloadDocumento={() => downloadAnexo(item?.nomeGuid)}
                    deleteDocumento={() => deleteAnexo(item?.nomeGuid, indexList)}
                    uploadDocumento={(file) => uploadAnexo(file, documento?.id, indexList)}
                    fileExtensions={fileExtensions}
                    validateAllTypes
                  />
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>

  );
};

InfoDocumentosFormalizar.propTypes = {
  documento: PropTypes.object.isRequired,
  indexList: PropTypes.number.isRequired,
  appendAnexo: PropTypes.func,
  downloadAnexo: PropTypes.func,
  deleteAnexo: PropTypes.func,
  uploadAnexo: PropTypes.func,
};

InfoDocumentosFormalizar.defaultProps = {
  appendAnexo: () => {},
  downloadAnexo: () => {},
  deleteAnexo: () => {},
  uploadAnexo: () => {},
};

export default InfoDocumentosFormalizar;
