import { connect } from 'react-redux';

import { TestDriveOperations, TestDriveSelectors } from '../../redux';
import TestDriveConditionsSelector from './testDriveConditionsSelector';

const mapStateToProps = ({ testDrive }) => ({
  simulationBaseValue: testDrive.pedido.simulationBaseValue,
  aVistaConditions: TestDriveSelectors.getAVistaConditions(testDrive.pedido),
  installmentConditions: TestDriveSelectors.getInstallmentConditions(testDrive.pedido),
  selectedOffer: TestDriveSelectors.getSelectedOffer(testDrive.pedido),

  conditions: testDrive.pedido.conditions,
  selectedConditionId: testDrive.pedido.selectedConditionId,

  offers: testDrive.pedido.offers,
  selectedOfferId: testDrive.pedido.selectedOfferId,
});

const mapDispatchToProps = (dispatch) => ({
  setSimulationBaseValue: (value) => dispatch(TestDriveOperations.setSimulationBaseValue(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TestDriveConditionsSelector);
