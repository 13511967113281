import { connect } from 'react-redux';

import Button from './confirmarCancelamentoButton';

import operations from '../../../redux/operations';

const mapStateToProps = ({ testDrive }) => ({
  isLoadingCancel: testDrive.acompanhamento.detalhe.cancelPedido.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  cancelPedido: () => { dispatch(operations.cancelPedido()); },
});

export default connect(mapStateToProps, mapDispatchToProps)(Button);
