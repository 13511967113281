import React from 'react';
import PropTypes from 'prop-types';
import BrandFidisImg from '../../../../../assets/imgs/brand-fidis.png';

import './paymentReceiptHeader.scss';

const PaymentReceiptHeader = ({ className }) => (
  <div className={className}>
    <div className="payment-receipt-header-image">
      <img alt="fidis-logo" src={BrandFidisImg} />
    </div>
    <div className="payment-receipt-header-partition" />
    <span className="payment-receipt-header-span">Solicitação de pagamento enviada com sucesso!</span>
  </div>
);

PaymentReceiptHeader.propTypes = {
  className: PropTypes.string,
};

PaymentReceiptHeader.defaultProps = {
  className: '',
};

export default PaymentReceiptHeader;
