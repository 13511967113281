import { connect } from 'react-redux';

import { Auth } from '../../../../modules';

import { PaymentOperations } from '../../redux';
import PaymentConfirmation from './paymentConfirmation';
import { operations as BalanceOperations } from '../../../balance/redux';
import { getTotalRemainingValue, getTotalEditedValue } from '../../redux/paymentSelectors';

const mapDispatchToProps = (dispatch) => ({
  setPaymentStep: (step) => (
    dispatch(PaymentOperations.setPaymentStep(step))
  ),
  submitPayment: (invoices, user, onSuccess, onError) => {
    dispatch(PaymentOperations.submitPayment(invoices, user, onSuccess, onError));
  },
  resetPaymentStore: () => dispatch(PaymentOperations.resetPaymentStore()),
  getBalance: () => dispatch(BalanceOperations.getBalanceInfo()),

});

const mapStateToProps = ({ paymentStore, balanceStore }) => ({
  invoices: paymentStore.selectedInvoices,
  totalValue: getTotalRemainingValue(paymentStore.selectedInvoices),
  paymentValue: getTotalEditedValue(paymentStore.selectedInvoices),
  remainingBalance: balanceStore.creditoRemanescente,
  isSubmitting: paymentStore.isSubmitting,
  user: Auth.getCurrentUser(),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentConfirmation);
