/* eslint-disable class-methods-use-this */
import axios from 'axios';

class PaymentService {
  async postPayment(invoices, user) {
    const response = await axios.post(`${window.env.REACT_APP_API_URL}/invoices/pay/multi-dealer`, {
      userId: `${user.id}`,
      userName: user.name,
      userEmail: user.email,
      invoicesToPay: (invoices || []).map(({ duplicataId, toPay, codigoSistema }) => ({
        id: duplicataId,
        value: toPay,
        sistema: codigoSistema,
      })),
    });

    if (response.status !== 200 || !response.data) {
      throw new Error('Erro ao buscar invoices');
    }

    return response.data;
  }

  async getStatus() {
    const response = await axios.get(`${window.env.REACT_APP_API_URL}/payments/status`);

    if (response.status !== 200 || !response.data) {
      throw new Error('Error ao buscar status');
    }

    return response.data;
  }
}
export default new PaymentService();
