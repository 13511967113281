// TODO: Deixar os valores como estão para ser exibido para  o client
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { operations } from 'features/balance/redux';
import { Date } from 'features/common';
import { EyeIcon, EyeOffIcon } from '../../../../assets/icons';
import { formatAsBRL } from '../../helpers/format';
import {
  BalanceContainer,
  Title,
  ResourcesContainer,
  NamesContainer,
  Values,
  SkeletonContainer,
  UpdatedBalance,
  UpdateDate,
  Line,
  ButtonTooltip,
  ListContainer,
} from './saldoAtualizado.style';

const SaldoAtualizado = ({
  visible, toggleVisibility, loading, data,
}) => {
  const {
    valorPrincipal, juros, multa, mora, saldoTotal, atualizadoEm,
  } = data;

  const saldoAtualizadoUsuario = [
    { key: 1, title: 'Valor Principal', value: formatAsBRL(valorPrincipal) },
    { key: 2, title: 'Juros', value: formatAsBRL(juros) },
    { key: 3, title: 'Multa', value: formatAsBRL(multa) },
    { key: 4, title: 'Mora', value: formatAsBRL(mora) },
  ];

  return (
    <BalanceContainer>
      <ResourcesContainer gridTemplateColumn="186px auto">
        <Title color="#7A7C9A">Saldo atualizado</Title>
        {!visible ? (
          <ButtonTooltip
            title="Ver informações"
            buttonAction={() => toggleVisibility()}
          >
            <EyeOffIcon width={16} height={16} id="balance-eye" />
          </ButtonTooltip>
        ) : (
          <ButtonTooltip
            title="Ocultar informações"
            buttonAction={() => toggleVisibility()}
          >
            <EyeIcon width={16} height={16} id="balance-eye" />
          </ButtonTooltip>
        )}
      </ResourcesContainer>
      <ResourcesContainer bottom="16px" top="7px">
        {saldoAtualizadoUsuario.map((resources) => (
          <ListContainer key={resources.key}>
            {visible && !loading ? (
              <>
                <ResourcesContainer
                  key={resources.value}
                  gridTemplateColumn="80px 122px"
                  bottom="3px"
                  top="3px"
                >
                  <NamesContainer data-cy="TextoMenuLateralDuplicatas">
                    {resources.title}
                  </NamesContainer>
                  <Values data-cy="ConteudoMenuLateralDuplicatas">
                    {resources.value}
                  </Values>
                </ResourcesContainer>
                <Line />
              </>
            ) : (
              <SkeletonContainer
                data-cy="SkeletonContainerSaldoAtualizadoDuplicatas"
                height="23px"
                marginTop="4px"
              >
                <Skeleton duration={-1} height={22} />
              </SkeletonContainer>
            )}
          </ListContainer>
        ))}
      </ResourcesContainer>
      <Title data-cy="TituloTotalDuplicatas">Saldo Total</Title>
      { visible && !loading ? (
        <UpdatedBalance data-cy="SaldoTotalDuplicatas">
          {formatAsBRL(saldoTotal)}
        </UpdatedBalance>
      ) : (
        <SkeletonContainer marginTop="6px" height="24px">
          <Skeleton duration={-1} height={22} />
        </SkeletonContainer>
      )}
      <ResourcesContainer gridTemplateColumn="74px 120px" bottom="16px">
        <UpdateDate data-cy="UltimaAtualizacaoDuplicatas">Atualizado em:</UpdateDate>
        <UpdateDate data-cy="DataUltimaAtualizacaoDuplicatas" fontWeight="500">
          {visible ? (
            <>
              {atualizadoEm ? <Date date={atualizadoEm} format="DD/MM/YYYY" /> : '--/--/----'}
              <span>  |  </span>
              {atualizadoEm ? <Date date={atualizadoEm} format="HH:mm" /> : '--:--'}
            </>

          ) : (
            <SkeletonContainer
              width="86px"
              data-cy="SkeletonContainerDuplicatasUpdate"
            >
              <Skeleton duration={-1} height={10} />
            </SkeletonContainer>
          )}
        </UpdateDate>
      </ResourcesContainer>
      <Line />
    </BalanceContainer>
  );
};

SaldoAtualizado.propTypes = {
  visible: PropTypes.bool.isRequired,
  toggleVisibility: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.object,
};

SaldoAtualizado.defaultProps = {
  data: {
    valorPrincipal: 0,
    juros: 0,
    mora: 0,
    multa: 0,
    saldoTotal: 0,
    atualizadoEm: null,
  },
};

const mapStateToProps = ({ balanceStore }) => ({
  visible: balanceStore.visible,
});

const mapDispatchToProps = (dispatch) => ({
  toggleVisibility: () => dispatch(operations.toggleVisibility()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SaldoAtualizado);
