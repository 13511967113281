import * as actions from './actions';

const INITIAL_STATE = {
  filters: {
    texto: null,
    status: [],
    selectedStatus: [],
  },
  isFilterSelected: false,
  pedidosList: {
    isLoading: false,
    isError: false,
    page: 0,
    ipp: 25,
    totalItems: null,
    pedidos: null,
  },
};

const reduceGetPedidos = (state, action) => {
  switch (action.type) {
    case actions.types.GET_PEDIDOS_START:
      return {
        ...state,
        pedidosList: {
          ...state.pedidosList,
          isLoading: true,
          isError: false,
          pedidos: null,
        },
      };
    case actions.types.GET_PEDIDOS_SUCCESS:
      return {
        ...state,
        pedidosList: {
          ...state.pedidosList,
          isLoading: false,
          isError: false,
          pedidos: action.payload.pedidos.pedidos,
          page: action.payload.pedidos.pagina,
          totalItems: action.payload.pedidos.itensTotal,
          ipp: action.payload.pedidos.itensPorPagina,
        },
        isFilterSelected: false,
      };
    case actions.types.GET_PEDIDOS_ERROR:
      return {
        ...state,
        pedidosList: {
          ...state.pedidosList,
          isLoading: false,
          isError: true,
          pedidos: null,
          page: null,
          totalItems: null,
        },
      };
    default:
      return state;
  }
};

const reducer = (state = INITIAL_STATE, action = { type: 'default' }) => {
  if (action.type.startsWith(actions.types.PREFIX_GET_PEDIDOS)) {
    return reduceGetPedidos(state, action);
  }

  switch (action.type) {
    case actions.types.SET_SELECTED_STATUS:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedStatus: action.payload.selectedStatus,
        },
        isFilterSelected: true,
        pedidosList: {
          ...state.pedidosList,
          page: 0,
        },
      };
    case actions.types.SET_TEXTO:
      return {
        ...state,
        filters: {
          ...state.filters,
          texto: action.payload.texto,
        },
        isFilterSelected: true,
        pedidosList: {
          ...state.pedidosList,
          page: 0,
        },
      };
    case actions.types.SET_PAGINA:
      return {
        ...state,
        pedidosList: {
          ...state.pedidosList,
          page: action.payload.page,
        },
      };

    case actions.types.SET_DEALER_STATUS_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          status: action.payload.status,
        },
      };
    case actions.types.SET_IPP:
      return {
        ...state,
        pedidosList: {
          ...state.pedidosList,
          ipp: action.payload.ipp,
          page: 0,
        },
      };
    case actions.types.RESET_STORE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
