import React from 'react';
import PropTypes from 'prop-types';

import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';
import { FormControl } from '@material-ui/core';

import IofDealerGroupDetail from '../iofDealerGroupDetail';

import { TextFieldHub } from '../../../common';

import './iofDealerGroupSelector.scss';

const styles = {
  root: {
    '& .MuiFormControl-root': {
      display: 'block',
      verticalAlign: 'middle',
      width: 235,
      height: 48,
      marginLeft: '-16px',
    },
  },

  formcontrol: {
    display: 'block',
  },

  paper: {
    borderRadius: '10px',
    width: '208',
    boxShadow: '0 5px 10px 0 rgba(138, 149, 158, 0.2)',
  },

  option: {
    padding: '0px 0px 0px 0px',
    backgroundColor: '#F7F9FC',
    '&[data-focus="true"]': { backgroundColor: '#e9eaf0' },
  },
};

class IofDealerGroupSelector extends React.Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.listRef = React.createRef();
    const { dealerGroups, setDealerGroup } = this.props;
    setDealerGroup(dealerGroups[0]);
  }

  onSelectChange = (_event, newDealerGroup) => {
    const { setDealerGroup } = this.props;
    setDealerGroup(newDealerGroup);
  };

  render() {
    const {
      classes, className, dealerGroups, dealerGroup, titleSelectAll,
    } = this.props;

    const filterOptions = createFilterOptions({
      matchFrom: 'any',
      stringify: (option) => option.matrizId + option.name,
    });

    return (
      <div className={`group-selector ${className}`}>
        <div className="group-selector-area">
          <FormControl
            classes={{
              // eslint-disable-next-line react/destructuring-assignment
              root: this.props.classes.formcontrol,
            }}
          >
            <div className="group-select">
              <Autocomplete
                options={dealerGroups}
                onChange={this.onSelectChange}
                disableClearable
                noOptionsText={titleSelectAll}
                value={dealerGroups.find((gp) => gp.matrizId === dealerGroup?.matrizId) || null}
                key={dealerGroup}
                getOptionLabel={(option) => option.matrizId || ''}
                filterOptions={filterOptions}
                classes={{
                  root: classes.root,
                  input: classes.input,
                  paper: classes.paper,
                  option: classes.option,
                }}
                renderOption={(option) => (
                  <IofDealerGroupDetail
                    dealerGroup={option}
                  />
                )}
                renderInput={(params) => (
                  <TextFieldHub
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                    }}
                    label="Matriz"
                    fullWidth
                  />
                )}
              />
            </div>
          </FormControl>
        </div>
      </div>
    );
  }
}

IofDealerGroupSelector.propTypes = {
  classes: PropTypes.object.isRequired,

  dealerGroup: PropTypes.object,
  className: PropTypes.string,
  dealerGroups: PropTypes.array.isRequired,
  setDealerGroup: PropTypes.func.isRequired,
  titleSelectAll: PropTypes.string,
};

IofDealerGroupSelector.defaultProps = {
  className: '',
  dealerGroup: PropTypes.object,
  titleSelectAll: 'Matriz não encontrada.',
};

export default withStyles(styles)(IofDealerGroupSelector);
