import { React, useState } from 'react';
import './buttonsFooter.scss';
import { useHistory } from 'react-router-dom';
import NewButton from 'features/common/newButton/newButton';
import PropTypes from 'prop-types';
import AlertFilledIcon from 'assets/icons/alert-filled';
import colors from 'assets/styles/colors';
import AlertModal from 'features/common/alertModal';

const configuracaoPadrao = () => ({
  textButton: '',
  textAlertButton: '',
  statusButton: '',
  titleModal: '',
  textModal: '',
  statusModal: '',
});

const configuracaoDealer = (documentos) => ({
  ...documentos,
  textButton: 'Enviar para Cadastro',
  textAlertButton: 'É necessário inserir um documento para enviar ao cadastro',
  statusButton: 'documentos_atualizados_dealer',
  titleModal: 'Há campos não preenchidos na lista.',
  textModal: 'Ainda existem documentos á serem anexados nesta lista de cadastro. Deseja enviar a lista com documentos pendentes?\nVocê poderá adicionar os documentos restantes posteriormente.',
  statusModal: 'documentos_enviados_parcialmente',
});

const configuracaoJuridico = (documentos) => ({
  ...documentos,
  textButton: 'Enviar para financiamento rede',
  textAlertButton: 'É necessário inserir um documento para enviar ao financiamento rede',
  statusButton: 'docs_juridico_atualizado_dealer',
  titleModal: 'Deseja enviar a lista com documentos pendentes?',
  textModal: 'Ainda existem documentos à serem anexados nesta lista. Você poderá adicionar os documentos complementares restantes posteriormente.',
  statusModal: 'docs_juridico_enviados_parcialmente',
});

const configuracaoFormalizar = (documentos) => ({
  ...documentos,
  textButton: 'Enviar para financiamento rede',
  textAlertButton: 'É necessário inserir um documento para enviar ao financiamento rede',
  statusButton: 'docs_formalizar_atualizado_dealer',
  titleModal: 'Deseja enviar a lista com documentos pendentes?',
  textModal: 'Ainda existem documentos à serem anexados nesta lista. Você poderá adicionar os documentos para formalizar restantes posteriormente.',
  statusModal: 'docs_formalizar_enviados_parcialmente',
});

const getConfiguracao = (
  statusLimite,
  documentosCadastro,
  documentosJuridico,
  documentosFormalizar,
) => {
  switch (statusLimite) {
    case 'pendente_documentos':
    case 'documentos_enviados_parcialmente':
    case 'documentos_atualizados_dealer':
      return configuracaoDealer(documentosCadastro);
    case 'pendente_docs_juridico_dealer':
    case 'docs_juridico_enviados_parcialmente':
    case 'docs_juridico_atualizado_dealer':
      return configuracaoJuridico(documentosJuridico);
    case 'pendente_docs_formalizar_dealer':
    case 'docs_formalizar_enviados_parcialmente':
    case 'docs_formalizar_atualizado_dealer':
      return configuracaoFormalizar(documentosFormalizar);
    default:
      return configuracaoPadrao();
  }
};

const ButtonsFooter = ({
  limite, enviarProposta, documentosCadastro, documentosJuridico, documentosFormalizar,
}) => {
  const history = useHistory();
  const actions = () => {
    history.replace('/solicitacao-credito/aprovacoes/limites-aprovados');
  };

  const [openModal, setOpenModal] = useState(false);

  const config = getConfiguracao(
    limite?.statusLimite,
    documentosCadastro,
    documentosJuridico,
    documentosFormalizar,
  );

  const handleButtonClick = () => {
    if (config.preenchido) {
      enviarProposta(limite?.idLimite, config.statusButton);
    } else if (config.parcial) {
      setOpenModal(true);
    }
  };

  return limite && (
    <>
      <div className="buttons-footer-documentos">
        <NewButton
          className="dark_gray_border"
          onClick={actions}
        >
          <span>Cancelar</span>
        </NewButton>
        { config.textButton && (
          <NewButton
            className="dark_green"
            openPopper={!config.parcial}
            onClick={handleButtonClick}
            alertCardTitle={config.textAlertButton}
            icone={<AlertFilledIcon width="8" height="8" />}
            colorBase={colors.error_color_400}
          >
            <span>{config.textButton}</span>
          </NewButton>
        )}
      </div>
      <AlertModal
        buttonAction={() => enviarProposta(limite?.idLimite, config.statusModal)}
        title={config.titleModal}
        subtitle={config.textModal}
        textRedButton="Enviar parcialmente"
        textGrayButton="Cancelar"
        openModal={openModal}
        setOpen={setOpenModal}
      />
    </>
  );
};

ButtonsFooter.propTypes = {
  limite: PropTypes.object,
  enviarProposta: PropTypes.func,
  documentosCadastro: PropTypes.object,
  documentosJuridico: PropTypes.object,
  documentosFormalizar: PropTypes.object,
};

ButtonsFooter.defaultProps = {
  limite: null,
  enviarProposta: () => {},
  documentosCadastro: { parcial: false, preenchido: false },
  documentosJuridico: { parcial: false, preenchido: false },
  documentosFormalizar: { parcial: false, preenchido: false },
};

export default ButtonsFooter;
