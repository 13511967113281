import React from 'react';
import PropTypes from 'prop-types';

import List, { ListContent } from '../../../common-staff/layout/list';
import DetalheEstorno from './views/detalheEstorno';
import DetalhePagamento from './views/detalhePagamento';

import './modalDetalhe.scss';

const ModalDetalhe = ({ tipo, isLoading, isError }) => (
  <div className="statement__list__container">
    <List
      className="statement_inner_list"
      isLoading={isLoading}
      isError={isError}
    >

      <ListContent>
        {tipo === 'ESTORNO' ? (
          <DetalheEstorno />
        ) : <DetalhePagamento /> }
      </ListContent>

      <ListContent type="empty">
        Nenhuma movimentação foi encontrada.
      </ListContent>
      <ListContent type="error">
        Erro ao buscar movimentações.
      </ListContent>
    </List>
  </div>
);

ModalDetalhe.propTypes = {
  tipo: PropTypes.string,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
};

ModalDetalhe.defaultProps = {
  tipo: null,
  isLoading: false,
  isError: false,
};

export default ModalDetalhe;
