import { connect } from 'react-redux';
import * as operations from '../../redux/operations';
import selectors from '../../redux/selectors';

import EnviarButton from './enviarButton';

const mapStateToProps = ({ limiteCreditoStore }) => ({
  isLoading: limiteCreditoStore.transferenciaLimite.isLoading,
  isSendDisabled: selectors.isSendDisabled(limiteCreditoStore),
});

const mapDispatchToProps = (dispatch) => ({
  openModal: () => dispatch(operations.openModal()),
});
export default connect(mapStateToProps, mapDispatchToProps)(EnviarButton);
