import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import Date from '../date';
import CheckIcon from '../../../assets/icons/check';
import './duplicataDate.scss';
import MoreHorizontalIcon from '../../../assets/icons/more-horizontal';
import PriorityHighIcon from '../../../assets/icons/priority-high';

const DuplicataDate = (
  {
    t, className, status, paymentDate, dueDate, showLabel,
  },
) => (
  <div className={`i-page-section-date ${className}`}>
    <div className="i-page-section-date-desc">
      {
        status === 'paid' && showLabel
          ? t('Data de pagamento')
          : null
      }
      {
        showLabel && (status === 'delayed' || status === 'opened')
          ? t('Data de vencimento')
          : null
      }
    </div>

    {
      status === 'paid'
        ? (
          <div className="i-page-section-date-value-container">
            <Date
              className="i-page-section-date-value"
              date={paymentDate}
              format="DD MMM YYYY"
            />
            <div className="i-page-section-date-icon i-page-section-date-icon-green">
              <CheckIcon />
            </div>
          </div>
        )
        : null
    }

    {
      status === 'delayed'
        ? (
          <div className="i-page-section-date-value-container">
            <Date
              className="i-page-section-date-value"
              date={dueDate}
              format="DD MMM YYYY"
            />
            <div className="i-page-section-date-icon i-page-section-date-icon-red">
              <PriorityHighIcon />
            </div>
          </div>
        )
        : null
    }

    {
      status === 'opened'
        ? (
          <div className="i-page-section-date-value-container">
            <Date
              className="i-page-section-date-value"
              date={dueDate}
              format="DD MMM YYYY"
            />
            <div className="i-page-section-date-icon i-page-section-date-icon-gray">
              <MoreHorizontalIcon />
            </div>
          </div>
        )
        : null
    }
  </div>
);

DuplicataDate.propTypes = {
  status: PropTypes.oneOf(['canceled', 'duplicated', 'paid', 'opened', 'delayed']).isRequired,
  paymentDate: PropTypes.string,
  dueDate: PropTypes.string,
  showLabel: PropTypes.bool,
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
};

DuplicataDate.defaultProps = {
  paymentDate: '',
  className: '',
  showLabel: true,
  dueDate: '',
};

export default withNamespaces('invoicePage')(DuplicataDate);
