import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import MarcaBadge from 'features/common-staff/views/marcaBadge';

import CnpjFormat from '../cnpjFormat';
import DealerNumber from '../dealerNumber';

import TextFieldValor from './textFieldValor';
import DealerOption from './dealerOption';

import './concessionariaSelector.scss';

class ConcessionariaSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    const { setConcessionariaSelecionada, concessionarias } = this.props;
    setConcessionariaSelecionada(concessionarias[0]?.dealerId);
  }

  componentDidUpdate() {
    const { concessionariaSelecionada } = this.props;
    if (concessionariaSelecionada == null) {
      const { setConcessionariaSelecionada, concessionarias } = this.props;
      setConcessionariaSelecionada(concessionarias[0]?.dealerId);
    }
  }

  onSelectChange = (_event, value) => {
    const { setConcessionariaSelecionada } = this.props;
    setConcessionariaSelecionada(value?.dealerId);
    this.setState({ isOpen: false });
  };

  render() {
    const { concessionariaSelecionada, concessionarias } = this.props;
    const { isOpen } = this.state;
    return (
      <div data-cy="SelectConcessionaria">
        <div
          className="solicitacao-page__autocomplete-subtittle"
          data-cy="Concessionaria"
        >
          Concessionária
        </div>
        <div
          className="solicitacao-limite__autocomplete-container"
          onClick={() => this.setState({
            isOpen: !isOpen,
          })}
          role="button"
          tabIndex="0"
        >
          {isOpen === true
            ? (
              <Autocomplete
                options={concessionarias}
                onChange={this.onSelectChange}
                open
                noOptionsText="Nenhuma concessionária encontrada"
                getOptionLabel={
                  (option) => option.dealerName
                }
                key={concessionariaSelecionada}
                onClose={() => this.setState({
                  isOpen: false,
                })}
                blurOnSelect="mouse"
                renderInput={(params) => (
                  <TextFieldValor
                    {...params}
                  />
                )}
                renderOption={(option) => (
                  <DealerOption
                    brand={option.brand}
                    dealerName={option.dealerName}
                    dealerId={option.dealerCorr}
                    cnpj={option.cnpj}
                  />
                )}
              />
            )
            : (
              <div
                className="solicitacao-limite__autocomplete-item-container"
              >
                <div
                  className="solicitacao-limite__autocomplete-item-icon"
                  data-cy="SolicitacaoLimiteIcon"
                >
                  <MarcaBadge
                    marca={(concessionariaSelecionada?.brand)}
                  />
                </div>
                <div
                  className="solicitacao-limite__autocomplete-item-name"
                  data-cy="SolicitacaoLimiteName"
                >
                  {concessionariaSelecionada?.dealerName}
                  <NavigateBeforeIcon className="solicitacao-limite__autocomplete-item-icon-navigate" />
                </div>
                <div
                  className="solicitacao-limite__autocomplete-item-description"
                  data-cy="SolicitacaoLimiteDescription"
                >
                  <CnpjFormat value={concessionariaSelecionada?.cnpj} />
                  <div
                    className="solicitacao-limite__autocomplete-item-divider"
                  />
                  <DealerNumber dealerCode={concessionariaSelecionada?.dealerCorr} />
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }
}

ConcessionariaSelector.propTypes = {
  concessionariaSelecionada: PropTypes.object,
  setConcessionariaSelecionada: PropTypes.func,
  concessionarias: PropTypes.array,
};

ConcessionariaSelector.defaultProps = {
  concessionariaSelecionada: null,
  setConcessionariaSelecionada: () => {},
  concessionarias: [],
};

export default ConcessionariaSelector;
