import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Radio, RadioGroup, FormControlLabel, withStyles,
} from '@material-ui/core';

import OutsideClickHandler from 'react-outside-click-handler';
import colors from 'assets/styles/colors';
import PopperComponent from 'features/common/popperComponent/popperComponent';
import Button from '../../../../../../../common/button';
import './exportarCarteira.scss';

const BlueRadio = withStyles(() => ({
  root: {
    color: colors.primary_color_500,
    '&:hover': {
      backgroundColor: 'transparent',
      color: colors.primary_color_700,
    },
    '&$checked': {
      color: colors.primary_color_700,
    },
  },
  checked: {},
}))(Radio);

const ExportarCarteira = ({
  setExportPeriodo, download, isLoading, periodo, setExportPopperOpen,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openModal = (event) => {
    setExportPopperOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const closeModal = () => {
    setExportPopperOpen(false);
    setAnchorEl(null);
  };

  const changeRadioSelect = (event) => {
    event.persist();
    setExportPeriodo('periodo', event.target.value);
  };

  const downloadCSV = () => {
    download(true);
    closeModal();
  };

  const downloadXLSX = () => {
    download(false);
    closeModal();
  };

  return (
    <div
      className="carteira-page__inv-page-export-container"
    >
      <Button
        className="carteira-page__inv-page-export-button"
        onClick={openModal}
        isLoading={isLoading}
      >
        Exportar Carteira
      </Button>
      <PopperComponent
        className="carteira-page__inv-page-popper-container"
        placement="bottom-start"
        anchorEl={anchorEl}
      >
        <OutsideClickHandler onOutsideClick={() => closeModal()}>
          <div className="carteira-page__inv-page-popover-content">
            <div className="carteira-page__inv-page-export-square" />
            <div className="carteira-page__inv-page-export-text-container">
              <span
                className="carteira-page__inv-page-popover-text"
              >
                Exportar Relatório de Carteira Floor Plan
              </span>
            </div>
            <div className="carteira-page__inv-page-export-legal-text-container">
              <span
                className="carteira-page__inv-page-export-legal-text"
              >
                Ao exportar sua carteira, serão exibidas as duplicatas em aberto
                de acordo com os filtros selecionados. É possível personalizar de
                acordo com a data de vencimento, emissão, tipo de duplicata, etc.
                Para exportar a carteira completa, lembre-se de remover os filtros
                caso tenha algum aplicado.
              </span>
            </div>
            <div className="carteira-page__inv-page-export-radio">
              <span
                className="carteira-page__inv-page-export-legal-text"
              >
                Para exportar as duplicatas quitadas, selecione a quantidade
                de dias da quitação integral.
              </span>
              <RadioGroup row value={periodo} onChange={changeRadioSelect}>
                <FormControlLabel
                  defaultChecked
                  value="30"
                  control={<BlueRadio color="default" />}
                  label="30 dias"
                />

                <FormControlLabel
                  value="60"
                  control={<BlueRadio color="default" />}
                  label="60 dias"
                />

                <FormControlLabel
                  value="90"
                  control={<BlueRadio color="default" />}
                  label="90 dias"
                />

                <FormControlLabel
                  value="120"
                  control={<BlueRadio color="default" />}
                  label="120 dias"
                />
              </RadioGroup>
            </div>
            <div className="carteira-page__inv-page-export-buttons">
              <Button
                className="carteira-page__inv-page-export-button-cancel"
                onClick={closeModal}
                white
              >
                Cancelar
              </Button>
              <Button
                className="carteira-page__inv-page-export-button-download-CSV"
                onClick={downloadCSV}
              >
                Exportar CSV
              </Button>
              <Button
                className="carteira-page__inv-page-export-button-download-XLSX"
                onClick={downloadXLSX}
              >
                Exportar XLSX
              </Button>
            </div>
          </div>
        </OutsideClickHandler>
      </PopperComponent>
    </div>
  );
};

ExportarCarteira.propTypes = {
  download: PropTypes.func,
  setExportPeriodo: PropTypes.func,
  isLoading: PropTypes.bool,
  periodo: PropTypes.number,
  setExportPopperOpen: PropTypes.func,
};

ExportarCarteira.defaultProps = {
  download: () => {},
  setExportPeriodo: () => {},
  isLoading: false,
  periodo: 90,
  setExportPopperOpen: () => {},
};

export default ExportarCarteira;
