import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './dateRange.scss';
import NewDatepicker from 'features/common/newDatePicker/newDatePicker';
import { Tracking } from 'modules';

const DateRange = ({
  isLoading,
  startDate,
  endDate,
  setEndDate,
  setStartDate,
}) => {
  const [invalidDate, setInvalidDate] = useState(false);

  return (
    <div className="relatorios_filters_date-range">
      <NewDatepicker
        initialStartDate={startDate}
        initialEndDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        disabled={isLoading}
        numberOfMonths={2}
        title="Período"
        setInvalidDate={setInvalidDate}
        invalidDateProp={invalidDate}
        tracking={() => Tracking.Mixpanel.relatoriosIof.filtros.trackFiltroData()}
      />
    </div>
  );
};

DateRange.propTypes = {
  isLoading: PropTypes.bool,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  setEndDate: PropTypes.func,
  setStartDate: PropTypes.func,
};

DateRange.defaultProps = {
  isLoading: false,
  startDate: null,
  endDate: null,
  setEndDate: () => {},
  setStartDate: () => {},
};

export default DateRange;
