import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import { ICON_AFTER_POSITION, START_DATE } from 'react-dates/lib/constants';
import 'moment/locale/pt-br';

import CalendarIcon from '../../../assets/icons/calendar';
import XIcon from '../../../assets/icons/x';
import './datePicker.css';

export const ID_INPUT_START = 'start_date_input';
export const ID_INPUT_END = 'end_date_input';

export class DatePickerDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focusedInput: null,
      hover: false,
    };
  }

  onDatesChange = ({ startDate, endDate }) => {
    const { setEndDate, setStartDate } = this.props;
    this.setState({
      hover: false,
    });

    if (startDate !== null) {
      setStartDate(startDate);
    }
    if (endDate !== null) {
      setEndDate(endDate);
    }
  };

  clear = () => {
    const { setEndDate, setStartDate } = this.props;
    this.setState({
      focusedInput: null,
      hover: false,
    });
    setEndDate(null);
    setStartDate(null);
  };

  markActive = (className) => {
    const {
      hover, focusedInput,
    } = this.state;
    const { startDate, endDate } = this.props;
    if (hover || focusedInput || (startDate && endDate)) {
      return `${className} ${className}_active`;
    }

    return `${className}`;
  };

  onInputClicked = () => {
    const { startDate, endDate } = this.props;

    if (!startDate && !endDate) {
      this.setState({ focusedInput: START_DATE });
    }
  };

  datePicker = () => {
    const { state } = this;
    const { startDate, endDate } = this.props;

    return (
      <DateRangePicker
        small
        noBorder
        hideKeyboardShortcutsPanel
        numberOfMonths={1}
        inputIconPosition={ICON_AFTER_POSITION}
        customInputIcon={
          <CalendarIcon className={this.markActive('calendar-icon')} />
        }
        startDateId={ID_INPUT_START}
        startDatePlaceholderText="dd/mm/aaaa"
        endDateId={ID_INPUT_END}
        endDatePlaceholderText="dd/mm/aaaa"
        isOutsideRange={() => false}
        minimumNights={0}
        onDatesChange={this.onDatesChange}
        startDate={startDate}
        endDate={endDate}
        onFocusChange={(focusedInput) => { this.setState({ focusedInput }); }}
        focusedInput={state.focusedInput}
      />
    );
  };

  buttonClear = () => {
    const { startDate, endDate } = this.props;

    if (startDate || endDate) {
      return (
        <button className="button-clear" type="button" onClick={this.clear} aria-label="Botão de limpar data">
          <XIcon />
        </button>
      );
    }

    return null;
  };

  render() {
    const { t } = this.props;
    return (
      <div className="date-picker-container" aria-label="Seletor de datas">
        <div
          className={this.markActive('border')}
          onMouseEnter={() => { this.setState({ hover: true }); }}
          onMouseLeave={() => { this.setState({ hover: false }); }}
          onClick={this.onInputClicked}
          role="presentation"
          aria-label="Selecione um período"
        >
          <span className={this.markActive('header')} aria-label="Cabeçalho do seletor de datas">
            {t('Filtrar por período')}
          </span>
          <div className="date-picker-input-container" aria-label="Selecionar intervalo de datas">
            { this.datePicker() }
          </div>
        </div>
        { this.buttonClear() }
      </div>
    );
  }
}

DatePickerDialog.defaultProps = {
  setStartDate: () => {},
  setEndDate: () => {},
  startDate: null,
  endDate: null,
};

DatePickerDialog.propTypes = {
  t: PropTypes.func.isRequired,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
};

export default withNamespaces()(DatePickerDialog);
