/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import React from 'react';
import PropTypes from 'prop-types';

import { ChartEmpty, ChartError, ChartLoading } from './state';

import './baseArea.scss';

const defineBody = (isLoading, isError, isEmpty, children) => {
  if (isLoading) {
    return <ChartLoading />;
  }
  if (isError) {
    return <ChartError />;
  }
  if (isEmpty) {
    return <ChartEmpty iconSmall />;
  }
  return children;
};

const BaseArea = ({
  title, subtitle, legenda, children, className, isEmpty, isLoading, isError, isLegendaBottom,
}) => (
  <div className={`dashboard-floor-plan__area__content ${className}`}>
    <div className="dashboard-floor-plan__area__content-head">
      <div className="dashboard-floor-plan__title">
        {title}
        {subtitle !== null ? (
          <span>
            {subtitle}
          </span>
        ) : null}
      </div>
      {(!isEmpty && !isLoading && !isError && !isLegendaBottom)
        ? (
          <div className="dashboard-floor-plan__legenda">
            {legenda}
          </div>
        )
        : null}
    </div>
    <div className="dashboard-floor-plan__area__content-children">
      {defineBody(isLoading, isError, isEmpty, children)}
      {(!isEmpty && !isLoading && !isError && isLegendaBottom)
			  ? (
  <div className="dashboard-floor-plan__legenda">
    {legenda}
  </div>
			  )
			  : null}
    </div>
  </div>
);

BaseArea.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  legenda: PropTypes.element,
  children: PropTypes.element,
  className: PropTypes.string,
  isError: PropTypes.bool,
  isEmpty: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLegendaBottom: PropTypes.bool,
};

BaseArea.defaultProps = {
  title: null,
  subtitle: null,
  legenda: null,
  children: null,
  className: null,
  isError: false,
  isEmpty: false,
  isLoading: false,
  isLegendaBottom: false,
};

export default BaseArea;
