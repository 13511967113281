import { connect } from 'react-redux';

import CarteiraListRow from './carteiraListRow';

import operations from '../../../redux/operations';
import opertaionsModal from '../../../../carteiraModal/redux/operations';
import selectorModal from '../../../../carteiraModal/redux/selector';
import selector from '../../../redux/selectors';

const mapStateToProps = ({ gestaoPagamentos, dealerStore, authStore }) => ({
  infoModalOpen: gestaoPagamentos.carteira.modal.modalInfo.isOpen,
  isModalOpen: selectorModal.isModalPaymentOpen(gestaoPagamentos),
  selectedInfo: gestaoPagamentos.carteira.modal.modalInfo.data?.duplicataId,
  sistema: selector.selectSystem(dealerStore),
  temPermissaoPagamento: authStore.permissions?.efetuarPagamentoB,
});

const mapDispatchToProps = (dispatch) => ({
  onCheck: (invoice) => {
    dispatch(operations.setSelectToPayment(invoice.duplicataId));
    dispatch(opertaionsModal.setInvoice(invoice));
  },
  setEnableModal: (invoice) => dispatch(opertaionsModal.setModalInfo(invoice)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CarteiraListRow);
