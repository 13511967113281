import _ from 'lodash';
import { createSelector } from 'reselect';

export const selectedCategory = createSelector(
  (testDrive) => testDrive.main.products,
  (products) => products.data.find((p) => p.id === products.selectedCategoryId),
);

export const selectedProduct = createSelector(
  (testDrive) => testDrive.main.products,
  (products) => products.data.find((p) => p.id === products.selectedProductId),
);

export const finalProduct = createSelector(
  selectedCategory,
  selectedProduct,
  (_selectedCategory, _selectedProduct) => {
    if (!_.isObject(_selectedCategory) && !_.isObject(_selectedProduct)) {
      return null;
    }

    if (!_selectedCategory.parent) {
      return _selectedCategory;
    }

    return _.isObject(_selectedProduct) ? _selectedProduct : null;
  },
);

export const finalProductId = createSelector(
  finalProduct,
  (product) => (product ? product.id : null),
);
