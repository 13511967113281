import React from 'react';
import PropTypes from 'prop-types';
import { Download } from 'react-feather';
import { Tracking } from 'modules';
import Button from 'features/common-staff/controls/button';

import './botaoDownload.scss';

const BotaoDownload = ({
  disabled, onClick, isLoading,
}) => (
  <Button
    isLoading={isLoading}
    className="relatorios__botao-download"
    onClick={() => onClick()}
    disabled={disabled}
    onSelect={(r) => {
      Tracking.Mixpanel.relatoriosIof.botoes.trackFiltroTipoDownload(r);
    }}
  >
    <Download size={15} stroke="#FFFFFF" />
  </Button>
);

BotaoDownload.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,

};

BotaoDownload.defaultProps = {
  disabled: true,
  onClick: () => {},
  isLoading: false,
};

export default BotaoDownload;
