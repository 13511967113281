import styled from 'styled-components';

export const Container = styled.div`
  border-bottom: 1px solid rgba(85, 87, 112, 0.08);
  position: sticky;
  top: 0;
  background: white;
`;

export const Row = styled.div`
  height: 36px;
  > div:first-child {
    padding-left: 24px;
  };
  > div:last-child > div:last-child {
    padding-right: 24px;
  };
`;

export const Column = styled.div`
  width: 100%;
  grid-area: ${(props) => props.gridArea};
  display: grid;
  column-gap: 8px;
  grid-template-rows: auto;
  grid-template-areas: "action text";
  grid-template-columns: 18px auto;
  align-items: center;
  justify-content: ${(props) => (
    props.gridArea === 'duplicata' || props.gridArea === 'vencimento' || props.gridArea === 'status'
      ? ''
      : 'end'
  )};
`;

export const Action = styled.div`
  grid-area: action;
  display: flex;
`;

export const Text = styled.div`
  grid-area: text;
  color: #8F9BB3;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  display: flex;
`;
