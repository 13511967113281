import React from 'react';
import PropTypes from 'prop-types';

import { camelFormat } from 'utils/format';
import ButtonIcon from 'features/common-staff/controls/buttonIcon';
import TextSnippetIcon from 'assets/icons/text-snippet';
import colors from 'assets/styles/colors';
import ProdutoIcon from '../../../../../../common/produtoIcon';

import CardInfo from './cardInfo';
import DetalhamentoDuplicata from './detalhamentoDuplicata';

import './infoModal.scss';

const setClassStatus = (status) => {
  switch (status.toLowerCase().trim()) {
    case 'quitada':
      return 'icon-green';
    case 'em aberto':
      return 'icon-white';
    default:
      return 'icon-red';
  }
};

const InfoModal = ({
  invoice, sistema, setModalHistoricoOpen, companies,
}) => (
  <div
    className="modal-carteira__info"
    data-cy="modal-carteira-info"
  >
    <div className="modal-carteira__info__header">
      {camelFormat(invoice?.descricaoProduto, 2)}
    </div>
    <div className="modal-carteria__info__body">
      <div
        className={`modal-carteria__info__body__logo ${setClassStatus(invoice?.duplicataStatusDescricao)}`}
      >
        <ProdutoIcon
          product={invoice?.descricaoProduto}
          status={invoice?.duplicataStatusDescricao}
        />
      </div>
      <CardInfo
        invoice={invoice}
        sistema={sistema}
      />
    </div>
    <DetalhamentoDuplicata
      invoice={invoice}
    />
    {companies.includes('FIDIS') ? (
      <div className="modal-carteira__info__historico">
        <ButtonIcon
          className="modal-carteira__info__historico__botao"
          onClick={() => setModalHistoricoOpen()}
        >
          <div className="modal-carteira__info__historico__title">
            <TextSnippetIcon fill={colors.primary_color_600} />
            <span>Histórico de duplicata</span>
          </div>
        </ButtonIcon>
      </div>
    )
      : null}
  </div>
);

InfoModal.propTypes = {
  invoice: PropTypes.object,
  sistema: PropTypes.string,
  setModalHistoricoOpen: PropTypes.func,
  companies: PropTypes.arrayOf(PropTypes.string),
};

InfoModal.defaultProps = {
  invoice: null,
  sistema: null,
  setModalHistoricoOpen: () => {},
  companies: null,
};

export default InfoModal;
