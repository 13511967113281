import { connect } from 'react-redux';

import ListFiles from './listFiles';

import { TestDriveOperations } from '../../../../redux';

const mapStateToProps = ({ testDrive }) => ({
  selectedFiles: testDrive.pedido.selectedFiles,
});

const mapDispatchToProps = (dispatch) => ({
  deleteFile: (fileName, key) => dispatch(TestDriveOperations.deleteFile(fileName, key)),
  reUploadFile: (file) => dispatch(TestDriveOperations.reUploadFile(file)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListFiles);
