import * as React from "react";

function SvgAngry(props) {
	return (
		<svg width="1em" height="1em" viewBox="0 0 40 40" {...props}>
			<defs>
				<linearGradient
					id="angry_svg__a"
					x1="50%"
					x2="50%"
					y1="0%"
					y2="67.194%"
				>
					<stop offset="0%" stopColor="#E04300" />
					<stop offset="100%" stopColor="#FFA320" />
				</linearGradient>
				<linearGradient
					id="angry_svg__b"
					x1="49.996%"
					x2="49.996%"
					y1="13.534%"
					y2="100.007%"
				>
					<stop offset="0%" stopColor="#3D0D00" />
					<stop offset="100%" stopColor="#661C04" />
				</linearGradient>
				<linearGradient
					id="angry_svg__c"
					x1="50.004%"
					x2="50.004%"
					y1="-.015%"
					y2="99.985%"
				>
					<stop offset="0%" stopColor="#191A33" />
					<stop offset="87.2%" stopColor="#3B426A" />
				</linearGradient>
				<linearGradient
					id="angry_svg__d"
					x1="82.87%"
					x2="82.87%"
					y1="109.325%"
					y2="0%"
				>
					<stop offset="0%" stopColor="#9A2F00" />
					<stop offset="100%" stopColor="#D44800" />
				</linearGradient>
			</defs>
			<g fill="none">
				<path
					fill="url(#angry_svg__a)"
					d="M40 20a19.998 19.998 0 01-20 20 20 20 0 1120-20"
				/>
				<path
					fill="#000"
					d="M13 33.877c0 1.32 3.133 1.11 7 1.11 3.866 0 7 .21 7-1.11 0-1.59-3.134-2.627-7-2.627-3.867 0-7 1.038-7 2.627z"
				/>
				<path
					fill="url(#angry_svg__b)"
					d="M13 33.877c0 1.32 3.133 1.11 7 1.11 3.866 0 7 .21 7-1.11 0-1.59-3.134-2.627-7-2.627-3.867 0-7 1.038-7 2.627z"
				/>
				<path
					fill="url(#angry_svg__c)"
					d="M9 24.578c0-1.977 1.344-3.577 3-3.577 1.657 0 3 1.6 3 3.577 0 .823-.233 1.583-.63 2.186-.187.277-.47.474-.796.55-.375.09-.932.187-1.575.187a6.839 6.839 0 01-1.575-.188 1.305 1.305 0 01-.795-.55 3.974 3.974 0 01-.63-2.185zm16 0c0-1.977 1.342-3.577 3-3.577 1.654 0 3 1.6 3 3.577 0 .823-.236 1.583-.63 2.186a1.32 1.32 0 01-.796.55 6.845 6.845 0 01-1.575.187c-.642 0-1.2-.098-1.575-.188a1.32 1.32 0 01-.797-.55 3.985 3.985 0 01-.628-2.185z"
				/>
				<path
					fill="#000"
					d="M9 24.578c0-1.977 1.344-3.577 3-3.577 1.657 0 3 1.6 3 3.577 0 .823-.233 1.583-.63 2.186-.187.277-.47.474-.796.55-.375.09-.932.187-1.575.187a6.839 6.839 0 01-1.575-.188 1.305 1.305 0 01-.795-.55 3.974 3.974 0 01-.63-2.185zm16 0c0-1.977 1.342-3.577 3-3.577 1.654 0 3 1.6 3 3.577 0 .823-.236 1.583-.63 2.186a1.32 1.32 0 01-.796.55 6.845 6.845 0 01-1.575.187c-.642 0-1.2-.098-1.575-.188a1.32 1.32 0 01-.797-.55 3.985 3.985 0 01-.628-2.185z"
				/>
				<path
					fill="#4F4F67"
					d="M12.421 23.332a.871.871 0 01.018.177c0 .503-.44.915-.985.915-.543 0-.983-.412-.983-.915 0-.207.075-.4.2-.552.56.132 1.148.26 1.75.375zm14.815 1.092c-.527 0-.957-.382-.982-.87.647-.095 1.29-.212 1.915-.34a.86.86 0 01.05.298c0 .5-.438.912-.983.912z"
				/>
				<path
					fill="#000"
					d="M22.499 19c0-1.115.407-1.5 1.112-1.5.7 0 1.035.69 1.265 2.665 2.82 0 7.595-1.335 8.055-1.335.446 0 .693.213.793.668.088.394-.057.77-.552 1-1.553.717-6.108 2.337-9.96 2.337-.42 0-.713-.215-.713-.753V19zm-7.378 1.165c.23-1.975.565-2.665 1.265-2.665.705 0 1.113.385 1.113 1.5v3.082c0 .538-.293.753-.713.753-3.852 0-8.407-1.62-9.96-2.338-.495-.23-.64-.605-.552-1 .102-.454.35-.667.792-.667.46 0 5.235 1.335 8.055 1.335z"
				/>
				<path
					fill="url(#angry_svg__d)"
					d="M22.499 19c0-1.115.407-1.5 1.112-1.5.7 0 1.035.69 1.265 2.665 2.82 0 7.595-1.335 8.055-1.335.446 0 .693.213.793.668.088.394-.057.77-.552 1-1.553.717-6.108 2.337-9.96 2.337-.42 0-.713-.215-.713-.753V19zm-7.378 1.165c.23-1.975.565-2.665 1.265-2.665.705 0 1.113.385 1.113 1.5v3.082c0 .538-.293.753-.713.753-3.852 0-8.407-1.62-9.96-2.338-.495-.23-.64-.605-.552-1 .102-.454.35-.667.792-.667.46 0 5.235 1.335 8.055 1.335z"
				/>
			</g>
		</svg>
	);
}

export default SvgAngry;

