import logger from 'utils/logger';

import * as actions from './actions';
import service from './service';

import * as solicitacaoOperations from '../solicitacaoPage/redux/testDriveOperations';

export const PAGE_ACOMPANHAMENTO = 'PAGE_ACOMPANHAMENTO';
export const PAGE_PRODUTO = 'PAGE_PRODUTO';
export const PAGE_SOLICITACAO = 'PAGE_SOLICITACAO';

export const loadProducts = () => async (dispatch) => {
  try {
    dispatch(actions.loadProductsStart());

    const products = await service.getProducts();

    dispatch(actions.loadProductsSuccess(products));
  } catch (e) {
    logger.error(e);
    dispatch(actions.loadProductsError());
  }
};

export const setPage = (page) => (dispatch) => {
  dispatch(actions.setPage(page));
};

export const selectCategory = (id) => (dispatch) => {
  dispatch(actions.setSelectedCategory(id));
  dispatch(actions.setPage(PAGE_SOLICITACAO));
  dispatch(solicitacaoOperations.getOffers());
};

export const selectProduct = (id) => (dispatch) => {
  dispatch(actions.setSelectedProduct(id));
  dispatch(solicitacaoOperations.getOffers());
};

export const resetSelections = () => (dispatch) => {
  dispatch(actions.resetSelections());
};
