import React from 'react';
import PropTypes from 'prop-types';

import ClockIcon from 'assets/icons/clock';

import { formatCurrency } from 'utils/format';

import './comprovanteListRow.scss';

const ComprovanteListRow = ({ comprovante }) => (
  <div className="comprovante-page__list-row__container__outer">
    <div
      className="comprovante-page__list-row__container"
    >
      <div className="comprovante-page__list-row__item comprovante-page__list-row__identificador">
        {comprovante?.idTransacao}
      </div>
      <div className="comprovante-page__list-row__item comprovante-page__list-row__origem">
        {`Dup. ${comprovante?.duplicataOrigem ? comprovante?.duplicataOrigem : '-'}`}
        {comprovante?.chassiOrigem
          ? (
            <span className="comprovante-page__list-row_chassi">
              {`Chassi ${comprovante?.chassiOrigem.toUpperCase()}`}
            </span>
          ) : null}
      </div>
      <div
        className="comprovante-page__list-row__item comprovante-page__list-row__destino"
      >
        {`Dup. ${comprovante?.duplicataDestino ? comprovante?.duplicataDestino : '-'}`}
        {comprovante?.chassiDestino
          ? (
            <span className="comprovante-page__list-row_chassi">
              {`Chassi ${comprovante?.chassiDestino.toUpperCase()}`}
            </span>
          ) : null}
      </div>
      <div
        className="comprovante-page__list-row__item comprovante-page__list-row__pagamento"
      >
        {formatCurrency(comprovante?.valorBaixa)}
      </div>
      <div
        className="comprovante-page__list-row__item comprovante-page__list-row__icon"
      >
        <ClockIcon />
      </div>
    </div>
  </div>
);
ComprovanteListRow.propTypes = {
  comprovante: PropTypes.object,
};

ComprovanteListRow.defaultProps = {
  comprovante: null,
};

export default ComprovanteListRow;
