import React from 'react';

import BreadCrumb from 'features/common-staff/layout/breadCrumb';

const BreadCrumbLimitesAprovados = () => (
  <BreadCrumb
    labels={[{
      label: 'Limites',
      path: ' ',
    },
    {
      label: 'Limites aprovados',
      path: '/aprovacoes/limites-aprovados',
    }]}
  />
);

export default BreadCrumbLimitesAprovados;
