import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { permissoes } from 'modules/auth/permissoes';
import { PATH_CARTEIRA_FIDC } from 'routes/paths';
import RenderIfPermission from 'features/menu/view/renderIfPermission';
import MenuItem from 'features/common-staff/controls/menuItem';
import RenderIfBrandPermission from 'features/common/renderIfBrandPermission';
import { BRAND_FIAT } from 'utils/variables';
import { Tracking } from 'modules';
import { Container, Content, MenuPadding } from './menuPageList.style';
import MenuResgates from '../menuResgates/menuResgates';

const MenuPageList = ({ children }) => {
  const { url } = useRouteMatch();
  const history = useHistory();

  const navTo = (itemName) => {
    history.replace(`${url}/${itemName}`);
  };

  return (
    <Container>
      <Content data-cy="CarteiraFidcMenuContainerContent">
        <RenderIfPermission requireAll={[permissoes.fidc.duplicatasFidc]}>
          <MenuPadding>
            <MenuItem
              Icon={FileCopyIcon}
              title="Duplicatas"
              isActive={!!useRouteMatch(`${PATH_CARTEIRA_FIDC}/duplicatas`)}
              onClick={() => {
                navTo('duplicatas');
                Tracking.Mixpanel.fidc.trackButtons('fidc_duplicatas_submenu_duplicatas');
              }}
              dataCy="MenuItemDuplicatas"
            />
          </MenuPadding>
        </RenderIfPermission>
        {children}
        <RenderIfPermission requireAll={[permissoes.fidc.resgatesJurosCarencia]}>
          <RenderIfBrandPermission
            requiredBrand={BRAND_FIAT}
          >
            <MenuResgates />
          </RenderIfBrandPermission>
        </RenderIfPermission>
      </Content>
    </Container>
  );
};

MenuPageList.propTypes = {
  children: PropTypes.element,
};

MenuPageList.defaultProps = {
  children: null,
};

export default MenuPageList;
