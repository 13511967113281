import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStytles = makeStyles({
  container: {
    width: '151px',
    height: '151px',
    borderRadius: '50%',
    background: 'rgba(0, 175, 173, 0.16)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const MensagemCardIcon = ({ urlImagem }) => {
  const classes = useStytles();
  return (
    <div className={classes.container}>
      <img src={urlImagem} width="84" height="84" alt="Icone Mensagem" />
    </div>
  );
};

MensagemCardIcon.propTypes = {
  urlImagem: PropTypes.string.isRequired,
};

export default MensagemCardIcon;
