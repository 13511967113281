import crypto from 'crypto-browserify';
import cookies from 'react-cookie';
import JSEncrypt from 'jsencrypt';
import { reactLocalStorage } from 'reactjs-localstorage';
import { minutesFromNow } from 'utils/time';
import ResgateAceites from 'modules/resgates';
import rcStorage from '../../features/menu/view/menuAside/atualizarCadastroButton/rcStorage';

import { permissoes } from './permissoes';
import Dealers from '../dealers';

import AuthService from './authService';

import {
  COOKIE_AUTH_TOKEN, COOKIE_AUTH_TOKEN_VALIDATE, COOKIE_FIDIS_USER_ID,
  COOKIE_FIDIS_USER_EMAIL, COOKIE_FIDIS_USER_NAME, COOKIE_FIDIS_USER_PERMISSIONS, LS_FIDIS_USER,
} from './cookies';

export const isFidisUser = (user) => {
  if (user && user.companies && user.companies.length > 0) {
    return user.companies.some(
      (c) => (c.companyId || '').toLowerCase() === 'fidis',
    );
  }

  return false;
};

const checkPermission = (entities, requiredPermission) => {
  if (!Array.isArray(entities) || entities.length === 0) return false;

  for (let i = 0; i < entities.length; i += 1) {
    const { permissions } = entities[i];
    const permission = permissions.filter((p) => p.permissionId === requiredPermission);
    if (permission && permission.length > 0) return true;
  }

  return false;
};

export const hasPermission = (user, requiredPermission) => {
  if (!user) return false;
  if (isFidisUser(user)) return checkPermission(user.companies, requiredPermission);
  return checkPermission(user.dealers, requiredPermission);
};

const checkProfile = (entities, requiredProfile) => {
  if (!Array.isArray(entities) || entities.length === 0) return false;

  for (let i = 0; i < entities.length; i += 1) {
    const { profiles } = entities[i];
    const profile = profiles.filter((p) => p.profileId === requiredProfile);
    if (profile && profile.length > 0) return true;
  }

  return false;
};

const hasProfile = (user, requiredProfile) => {
  if (!user) return false;
  if (isFidisUser(user)) return checkProfile(user.companies, requiredProfile);
  return checkProfile(user.dealers, requiredProfile);
};

export const definePermissions = (user) => ({
  visualizarDuplicatas: hasPermission(user, permissoes.duplicatas),
  visualizarDuplicatasB: hasPermission(user, permissoes.duplicatasB),
  visualizarMovimentacoes: hasPermission(user, permissoes.movimentacoes),
  visualizarMovimentacoesB: hasPermission(user, permissoes.movimentacoesB),
  efetuarPagamento: hasPermission(user, permissoes.efetuarPagamento),
  efetuarPagamentoB: hasPermission(user, permissoes.efetuarPagamentoB),
  simularIof: hasPermission(user, permissoes.iof),
  solicitarTestDrive: hasPermission(user, permissoes.testDrive),

  rcAcessarRenovacao: hasPermission(user, permissoes.rcAcessarRenovacao),

  alteracaoCredito: hasPermission(user, permissoes.limite.alteracaoCredito),
  transferenciaCredito: hasPermission(user, permissoes.limite.transferenciaCredito),
  duplicatasFidc: hasPermission(user, permissoes.fidc.duplicatasFidc),
  resgatesJurosCarencia: hasPermission(user, permissoes.fidc.resgatesJurosCarencia),
  resgatesHistorico: hasPermission(user, permissoes.fidc.resgatesHistorico),
  liberacaoLimite: hasPermission(user, permissoes.liberacaoLimite),

  usuarioBeta: hasProfile(user, permissoes.beta),
  relatoriosFinanceiros: hasPermission(user, permissoes.relatoriosFinanceiros),
  dashboardFloorPlan: hasPermission(user, permissoes.dashboardFloorPlan),
  centralComunicacao: hasPermission(user, permissoes.centralComunicacao),
  relatoriosIof: hasPermission(user, permissoes.relatoriosIof),
  visualizarTaxas: hasPermission(user, permissoes.taxas),
});

export const savePermissionsInfo = (permissions) => {
  cookies.save(COOKIE_FIDIS_USER_PERMISSIONS, permissions, { path: '/', secure: true });
};

export const isAuthenticated = () => (
  Boolean(cookies.load('token'))
);

export const getPermissions = () => (
  cookies.load(COOKIE_FIDIS_USER_PERMISSIONS)
);

export const getCurrentUser = () => (
  reactLocalStorage.getObject(LS_FIDIS_USER)
);

export const getCurrentUserId = () => (
  parseInt(cookies.load(COOKIE_FIDIS_USER_ID), 10)
);

export const clearAllCookies = () => {
  rcStorage.clearCookies();

  reactLocalStorage.remove(COOKIE_AUTH_TOKEN);

  cookies.remove(COOKIE_AUTH_TOKEN_VALIDATE, { path: '/' });

  reactLocalStorage.remove(LS_FIDIS_USER);
  cookies.remove(COOKIE_FIDIS_USER_ID, { path: '/' });
  cookies.remove(COOKIE_FIDIS_USER_NAME, { path: '/' });
  cookies.remove(COOKIE_FIDIS_USER_EMAIL, { path: '/' });
  cookies.remove(COOKIE_FIDIS_USER_PERMISSIONS, { path: '/' });
};

export const saveLoginInfo = (user, token) => {
  clearAllCookies();

  reactLocalStorage.setObject(COOKIE_AUTH_TOKEN, token);

  cookies.save(COOKIE_AUTH_TOKEN_VALIDATE, minutesFromNow(55).getTime(), { path: '/', secure: true });
  reactLocalStorage.setObject(LS_FIDIS_USER, user);
  cookies.save(COOKIE_FIDIS_USER_ID, user.id, { path: '/', secure: true });
  cookies.save(COOKIE_FIDIS_USER_NAME, user.name, { path: '/', secure: true });
  cookies.save(COOKIE_FIDIS_USER_EMAIL, user.email, { path: '/', secure: true });
};

const publicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAs3BkpnJ2cuQx+vtu5v12
/UVrNRRuvjIuO29c/MrtJzFJ3QxutHn549mTmLYAT40emIMXZFb/bXAH0+43WiDl
H1h3OSl+eZHGsBaDb3i53FQCSfh1t77tVzT+9Emvvjznw29q+dkp1TA6PH5griRD
IRpKS2htK5fSYxoKZQv3slngQncigKWTIjktmjeDhTF6eh1BfLrZ/czzdZMMOGKn
4QBeIEX2WfYN00E9FC+jSdCectTA6B1X7Fp6o22gqE+U9MbI0eKWGELovXaJZNxW
Ec6ltaus3Es1+GTVRGm9YLwcQDhx0kT04kH/I/qgHb1ry7GBs2hPUJWR/oH5nbUb
PQIDAQAB
-----END PUBLIC KEY-----`;

const key = 'e94426986ef91ec19befbaabb0a7fd46';
const iv = 'c601304ee39f1c15';

const encryptInAES = (body) => {
  const cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(key), iv);
  let encrypted = cipher.update(JSON.stringify(body), 'utf-8', 'base64');
  encrypted += cipher.final('base64');
  return encrypted;
};

export const getEncryptedBody = (body) => {
  if (!body) {
    return null;
  }
  let firstEncrypted = encryptInAES(body);
  firstEncrypted = firstEncrypted.match(/.{1,214}/g);
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);
  const encryptedBody = firstEncrypted.map((code) => encrypt.encrypt(code));
  return { encryptedBody };
};

export const logout = () => {
  AuthService.Logoff();
  clearAllCookies();
  Dealers.clearAllStorage();
  ResgateAceites.clearAllStorage();
  window.location.reload();
};
