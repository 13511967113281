import { connect } from 'react-redux';
import StatementPage from './statementPage';

import { DealerSelectors } from '../../modules/dealers/redux';
import operations from './redux/operations';

const mapStateToProps = ({ statementStore, dealerStore }) => ({
  isModalOpen: statementStore.modal.isOpen,
  dealerId: DealerSelectors.getSelectedFPlanDealer(dealerStore),
  snackbarErrors: statementStore.snackbarErrors,
  isComprovanteOpen: statementStore.modalComprovante.isOpen,
  isUpdated: statementStore.isUpdated,
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(operations.setModalClose()),
  getMovimentacoes: () => dispatch(operations.getMovimentacoes()),
  resetStore: () => dispatch(operations.resetStore()),
  dismissSnackbar: (id) => dispatch(operations.dismissSnackbar(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StatementPage);
