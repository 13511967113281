import React from 'react';
import PropTypes from 'prop-types';

import Button from 'features/common-staff/controls/button';
import { Tracking } from 'modules';

const VoltarButton = ({ navBack }) => (
  <Button
    onClick={() => {
      Tracking.Mixpanel.acompanhamentoTd.trackNewOrder();
      navBack();
    }}
    fullWidth
  >
    Nova Solicitação de Veículo
  </Button>
);

VoltarButton.propTypes = {
  navBack: PropTypes.func.isRequired,
};

export default VoltarButton;
