import { connect } from 'react-redux';

import CardResumo from './cardResumo';

const mapStateToProps = ({ testDrive }) => ({
  isLoading: testDrive.acompanhamento.detalhe.modal.isLoading,
  cnpj: testDrive.acompanhamento.detalhe.modal?.detalhePedido?.concessionariaCnpj,
  concessionariaNome: testDrive.acompanhamento.detalhe.modal?.detalhePedido?.concessionariaNome,
  corretorCodigo: testDrive.acompanhamento.detalhe.modal?.detalhePedido?.corretorId,
  modeloDescricao: testDrive.acompanhamento.detalhe.modal?.detalhePedido?.descricao,
  modelYear: testDrive.acompanhamento.detalhe.modal?.detalhePedido?.modelYear,
  produto: testDrive.acompanhamento.detalhe.modal?.detalhePedido?.produto,
  data: testDrive.acompanhamento.detalhe.modal?.detalhePedido?.data,
  urlModelo: testDrive.acompanhamento.detalhe.modal?.detalhePedido?.urlImagemModelo,
  arquivosExcecao: testDrive.acompanhamento.detalhe.modal?.detalhePedido?.arquivosExcecao,
  solicitante: testDrive.acompanhamento.detalhe.modal?.detalhePedido?.usuarioNome,
  pedidoId: testDrive.acompanhamento.detalhe.modal?.detalhePedido?.pedidoId,
});

export default connect(mapStateToProps)(CardResumo);
