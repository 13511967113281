/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
import axios from 'axios';

class LimitesAprovadosCadastroService {
  async insertTipoRelacionamento(tipoRelacionamento) {
    const response = await axios.post(`${window.env.REACT_APP_API_URL}/formalizacao/documentos/tipo-relacionamento`, tipoRelacionamento);
    if (!response) {
      throw new Error('Falha ao inserir o tipo relacionamento!');
    }
    return response.data;
  }

  async insertTipoDocumento(tipoDocumento) {
    const response = await axios.post(`${window.env.REACT_APP_API_URL}/formalizacao/documentos/tipo-documento`, tipoDocumento);
    if (!response) {
      throw new Error('Falha ao inserir o tipo documento!');
    }
    return response.data;
  }

  async insertPessoaDocumentacao(pessoaDocumentacao) {
    const response = await axios.patch(`${window.env.REACT_APP_API_URL}/formalizacao/documentos/pessoa-documento`, pessoaDocumentacao);
    if (!response) {
      throw new Error('Falha ao inserir pessoa documentação!');
    }
    return response.data;
  }

  async getPessoaDocumentacao(idLimite) {
    const result = await axios.get(`${window.env.REACT_APP_API_URL}/formalizacao/documentos/pessoa-documento/${idLimite}`);
    if (!result) throw new Error('Não foi possível buscar a lista de pessoas cadastradas');
    if (result.status === 200) {
      return result.data;
    }
  }
  async getListTipoRelacionamento() {
    const result = await axios.get(`${window.env.REACT_APP_API_URL}/formalizacao/documentos/tipo-relacionamento`);
    if (!result.data) throw new Error('Não foi possível buscar a lista de tipos relacionamento');
    if (result.status === 200) {
      return result.data;
    }
  }
  async getListTipoDocumento() {
    const result = await axios.get(`${window.env.REACT_APP_API_URL}/formalizacao/documentos/tipo-documento`);
    if (!result.data) throw new Error('Não foi possível buscar a lista de tipos documento');
    if (result.status === 200) {
      return result.data;
    }
  }
  async deletePessoaDocumentacao(idPessoaDocumentacao) {
    const config = {
      data: {
        value: idPessoaDocumentacao,
      },
    };
    const response = await axios.delete(`${window.env.REACT_APP_API_URL}/formalizacao/documentos/pessoa-documentacao`, config);
    if (!response || response.status !== 200) {
      throw new Error('Falha ao deletar.');
    }
  }

  async getUrlUploadDocumento(file, idPessoaDocumento, idTipoDocumento) {
    const fileBody = {
      Type: file.type,
      NomeOriginal: file.name,
      TamanhoEmBytes: file.size,
      IdTipoDocumento: idTipoDocumento,
      IdPessoaDocumento: idPessoaDocumento,
    };
    const response = await axios.post(`${window.env.REACT_APP_API_URL}/formalizacao/documentos/pessoa-documento/documento`, fileBody);
    if (!response.data || response.status !== 200) {
      throw new Error('Falha ao tentar buscar a url de upload do documento!');
    }
    return response.data;
  }

  async uploadDocumento(urlUpload, file) {
    const instance = axios.create();
    const response = await instance.put(urlUpload, file, {
      headers: {
        'Content-Type': file.type,
        'x-amz-acl': 'public-read',
        'Content-Disposition': `attachment; filename=${file.name};`,
      },
    });
    if (response.status !== 200) {
      throw new Error('Falha ao tentar fazer upload do documento!');
    }

    return response.data;
  }

  async getUrlDownloadDocumento(idDocumento) {
    const fileBody = {
      value: idDocumento,
    };
    const response = await axios.post(`${window.env.REACT_APP_API_URL}/formalizacao/documentos/pessoa-documento/documento/url`, fileBody);
    if (!response.data || response.status !== 200) {
      throw new Error('Falha ao baixar documento');
    }
    return response.data;
  }

  async deleteDocumento(idPessoaDocumento, idTipoDocumento) {
    const fileBody = {
      idPessoaDocumentacao: idPessoaDocumento,
      idTipoDocumento,
      Observacao: {},
    };
    const obj = { data: fileBody };
    const response = await axios.delete(`${window.env.REACT_APP_API_URL}/formalizacao/documentos/pessoa-documento`, obj);
    if (!response.data || response.status !== 200) {
      throw new Error('Falha ao tentar deletar arquivo');
    }
    return response.data;
  }

  async getDocumentosComplementares(idLimite) {
    const body = {
      value: idLimite,
    };
    const response = await axios.post(`${window.env.REACT_APP_API_URL}/formalizacao/gestao-juridico/pessoa`, body);
    if (!response.data || response.status !== 200) {
      throw new Error('Não foi possível buscar a lista de pessoas cadastradas');
    }
    return response.data;
  }

  async getUrlUploadTemp(file) {
    const body = {
      nomeOriginal: file?.name,
      tamanhoEmBytes: file?.size,
      type: file?.type,
    };
    const response = await axios.post(`${window.env.REACT_APP_API_URL}/formalizacao/gestao-limites/upload-temp`, body);

    if (!response || response.status !== 200) {
      throw new Error('Falha ao gerar URL de upload do arquivo');
    }

    return response.data;
  }

  async insertDocumentoComplementar(file, idPessoaTipoDocumentoJuridico) {
    const body = {
      NomeGuid: file.nomeGuid,
      NomeOriginal: file.nomeOriginal,
      Tamanho: file.tamanhoEmMb,
      idPessoaTipoDocumentoJuridico,
    };
    const response = await axios.post(`${window.env.REACT_APP_API_URL}/formalizacao/gestao-juridico/pessoa/documento-complementar`, body);
    if (!response.data || response.status !== 200) {
      throw new Error('Falha ao baixar documento');
    }
    return response.data;
  }

  async getDocumentoComplementarUrl(idDocumento) {
    const fileBody = {
      value: idDocumento,
    };
    const response = await axios.post(`${window.env.REACT_APP_API_URL}/formalizacao/gestao-juridico/pessoa/documento-complementar/url`, fileBody);
    if (!response.data || response.status !== 200) {
      throw new Error('Falha ao baixar documento');
    }
    return response.data;
  }

  async deleteDocumentoComplementar(idDocumento) {
    const config = {
      data: {
        idDocumento,
        Observacao: {},
      },
    };
    const response = await axios.delete(`${window.env.REACT_APP_API_URL}/formalizacao/gestao-juridico/pessoa/documento-complementar`, config);
    if (!response.data || response.status !== 200) {
      throw new Error('Falha ao tentar deletar arquivo');
    }
    return response.data;
  }

  async updateObservacaoDealer(idPessoa, observacaoDealer) {
    const body = {
      idPessoa, observacaoDealer,
    };
    const response = await axios.patch(`${window.env.REACT_APP_API_URL}/formalizacao/gestao-juridico/pessoa/documento/observacao-dealer`, body);
    if (!response || response.status !== 200) {
      throw new Error('Falha ao atualizar observação dealer');
    }
    return response;
  }

  async updateObservacaoDealerCadastro(id, observacoesDealer) {
    const body = {
      id, observacoesDealer,
    };
    const response = await axios.patch(`${window.env.REACT_APP_API_URL}/formalizacao/documentos/pessoa-documento/observacoes-dealer`, body);
    if (!response || response.status !== 200) {
      throw new Error('Falha ao atualizar observação dealer');
    }
    return response;
  }

  async getDocumentosFormalizar(idLimite) {
    const response = await axios.get(`${window.env.REACT_APP_API_URL}/formalizacao/gestao-juridico/documento-formalizar/${idLimite}`);
    if (!response || response.status !== 200) {
      throw new Error('Não foi possível buscar a lista de Documentos Formalizar');
    }
    return response.data;
  }

  async updateDocumentosFormalizar(observacoesDealer, dados) {
    const { idLimitesAprovadosHub, ...documentoFormalizar } = dados;
    const body = {
      idLimitesAprovadosHub,
      itemsToDelete: [],
      filesToDelete: [],
      items: [{
        ...documentoFormalizar, observacoesDealer, listaArquivos: null,
      }],
    };

    const response = await axios.put(`${window.env.REACT_APP_API_URL}/formalizacao/gestao-juridico/documento-formalizar/`, body);
    if (!response || response.status !== 200) {
      throw new Error('Falha ao fazer update documentos formalizar');
    }
    return response.data;
  }

  async getAnexoDocumentoFormalizar(nomeGuid) {
    const body = {
      Value: nomeGuid,
    };
    const response = await axios.post(`${window.env.REACT_APP_API_URL}/formalizacao/gestao-juridico/documento-formalizar/url`, body);
    if (!response || response.status !== 200) {
      throw new Error('Falha ao gerar a url de download do arquivo');
    }
    return response.data;
  }

  async insertAnexoDocumentoFormalizar(documento, idDocumentoFormalizarJuridico) {
    const { tamanhoEmMb, ...dados } = documento;

    const body = {
      ...dados,
      tamanho: tamanhoEmMb,
      idDocumentoFormalizarJuridico,
      tipo: 'dealer',
    };
    const response = await axios.post(`${window.env.REACT_APP_API_URL}/formalizacao/gestao-juridico/documento-formalizar/anexo`, body);
    if (!response || response.status !== 200) {
      throw new Error('Falha ao inserir anexo');
    }
    return response.data;
  }

  async deleteAnexoDocumentoFormalizar(nomeGuid) {
    const config = {
      data: {
        nomeGuid,
        Observacao: {},
      },
    };
    const response = await axios.delete(`${window.env.REACT_APP_API_URL}/formalizacao/gestao-juridico/documento-formalizar/anexo`, config);
    if (!response || response.status !== 200) {
      throw new Error('Falha ao deletar anexo');
    }
    return response.data;
  }
}

export default new LimitesAprovadosCadastroService();
